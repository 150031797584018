import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'filters-classifications-auto-popup-model-info',
    templateUrl: './filters-classifications-auto-popup-model-info.component.html',
})
export default class FiltersClassificationsAutoPopupModelInfoComponent {
    @Input()
    private model;

    constructor(
        private modal: NgbActiveModal,
    ) { }

    close() {
        this.modal.close();
    }
}
