/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
export const addValuesToHorizontalBarCharts = {
    id: 'addValuesToHorizontalBarCharts',
    afterDatasetsDraw(chart, args, options) {
        if (chart.config._config.type !== 'bar') {
            return;
        }

        const { ctx } = chart;

        ctx.textBaseline = 'middle';

        ctx.font = 'bold  12px \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif';
        const unit = chart.config.options.scales.x.ticks.percent ? '%' : '';

        const lastBars = {};

        // affichage des valeurs dans les barres
        // eslint-disable-next-line @typescript-eslint/dot-notation
        chart['_metasets'].forEach((metaset, i) => {
            const dataset = metaset._dataset;
            const meta = chart.getDatasetMeta(i);

            // affichage des valeurs à l'intérieur des barres
            meta.data.forEach((bar, index) => {
                if (options.tones) {
                    if (bar.options.backgroundColor === '#CD3C14' || bar.options.backgroundColor === '#999999') {
                        ctx.fillStyle = '#FFF';
                    } else {
                        ctx.fillStyle = '#000';
                    }

                    const text = dataset.data[index].raw + unit;
                    const textWidth = ctx.measureText(text).width;

                    // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
                    const base = bar['base'];
                    const barWidth = bar.x - base;
                    if (textWidth <= barWidth) {
                        ctx.textAlign = 'center';
                        ctx.fillText(text, (bar.x + base) / 2, bar.y + 2);
                    }

                    if (!lastBars[bar.y] || lastBars[bar.y].maxX < bar.x) {
                        lastBars[bar.y] = {
                            maxX: bar.x,
                            maxY: bar.y,
                            total: dataset.data[index].total,
                        };
                    }
                } else {
                    const text = (dataset.data[index].value || 0) + unit;
                    const textWidth = ctx.measureText(text).width;

                    if (bar.x + 10 + textWidth > ctx.canvas.clientWidth) {
                        ctx.textAlign = 'right';
                        ctx.fillText(text, ctx.canvas.clientWidth - 10 - textWidth, bar.y + 2);
                    } else {
                        ctx.textAlign = 'left';
                        ctx.fillText(text, bar.x + 10, bar.y + 2);
                    }
                }
            });
        });

        // affichage du total à droite de la dernière barre
        ctx.textAlign = 'left';
        ctx.fillStyle = '#000';
        Object.values(lastBars).forEach((value: any) => {
            ctx.fillText(value.total + unit, value.maxX + 10, value.maxY + 2);
        });
    },
};
