import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export default class CookiesComponent implements OnInit {
    lang: string = 'fr';

    constructor(
        public modalRef: NgbActiveModal,
        public translate: TranslateService,
    ) { }

    ngOnInit(): void {
        this.lang = this.translate.currentLang ? this.translate.currentLang.toLowerCase() : 'fr';
    }

    close() {
        this.modalRef.close();
    }
}
