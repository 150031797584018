// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-name {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: bold;
}

.no-data button {
  background-color: transparent;
}
.no-data button:hover {
  background-color: black;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
