/* eslint-disable max-classes-per-file */
/**
 * Corpus name
 *
 * @export
 * @class SetName
 */
export class SetName {
    static readonly type = '[NEW_CORPUS] set corpus name and description';

    constructor(public payload: any) {}
}

/**
 * Corpus infos
 *
 * @export
 * @class SetName
 */
export class SetNewCorpus {
    static readonly type = '[NEW_CORPUS] set corpus';

    constructor(public payload: any) {}
}

/**
 * Fist corpus file
 *
 * @export
 * @class AddFile
 */
export class AddFile {
    static readonly type = '[NEW_CORPUS] add file';

    constructor(public payload: any) {}
}

/**
 * Add msurvey file
 *
 * @export
 * @class AddFile
 */
export class AddMSurveyFile {
    static readonly type = '[NEW_CORPUS] add msurvey file';

    constructor(public payload: any) {}
}

/**
 * New corpus stage state
 *
 * @export
 * @class SetEtapes
 */
export class SetEtapes {
    static readonly type = '[NEW_CORPUS] Etapes change';

    constructor(public payload: any) {}
}

/**
 * Verbatim col
 *
 * @export
 * @class SetColMessage
 */
export class SetColMessage {
    static readonly type = '[NEW_CORPUS] Column message change';

    constructor(public payload: any) {}
}

/**
 * Date col
 *
 * @export
 * @class SetColDate
 */
export class SetColDate {
    static readonly type = '[NEW_CORPUS] Column date change';

    constructor(public payload: any) {}
}

/**
 * Score col
 *
 * @export
 * @class SetColScore
 */
export class SetColScore {
    static readonly type = '[NEW_CORPUS] Column score change';

    constructor(public payload: any) {}
}

/**
 * Filters cols
 *
 * @export
 * @class SetFilters
 */
export class SetFilters {
    static readonly type = '[NEW_CORPUS] Filters change';

    constructor(public payload: any) {}
}

/**
 * First line of file
 *
 * @export
 * @class SetFileFirstLines
 */
export class SetFileFirstLines {
    static readonly type = '[NEW_CORPUS] Save file first lines';

    constructor(public payload: any) {}
}

/**
 * File col type
 *
 * @export
 * @class SetFileColsTypes
 */
export class SetFileColsTypes {
    static readonly type = '[NEW_CORPUS] Save file columns types';

    constructor(public payload: any) {}
}

/**
 * clean new corpus store
 *
 * @export
 * @class ResetNewCorpus
 */
export class ResetNewCorpus {
    static readonly type = '[NEW_CORPUS] Reset';
}

/**
 * Is finish
 *
 * @export
 * @class FinishNewCorpus
 */
export class FinishNewCorpus {
    static readonly type = '[NEW_CORPUS] Finish';

    constructor(public payload: any) {}
}

/**
 * Set IndexStopwords
 *
 * @export
 * @class SetIndexStopwords
 */
export class SetIndexStopwords {
    static readonly type = '[NEW_CORPUS] Set indexStopwords is disabled';

    constructor(public payload: boolean) {}
}
