import {
    Component,
    OnDestroy, OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { MailContact } from 'app/api/models/mailcontact';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';
import AddColumnsService from 'app/utils/services/add-columns.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseComponent } from 'app/base.component';
import { GetCorpus } from '../../../../api/models';
import { CorpusService, FileService } from '../../../../api/services';
import CorpusState from '../../../../stores/state/corpus/corpus.state';

/**
   * Corpus add filters settings
   *
   * @export
   * @class CorpusSettingsAddFiltersComponent
   * @implements {OnInit}
   */
@Component({
    selector: 'app-corpus-settings-add-filters',
    templateUrl: './corpus-settings-add-filters.component.html',
    styleUrls: ['./corpus-settings-add-filters.component.scss'],
})
export default class CorpusSettingsAddFiltersComponent extends BaseComponent implements OnInit, OnDestroy {
    columns: Array<string> = [];

    rows: any = [];

    next = false;

    selectVerbatimCol: number;

    selectDateCol: number;

    filename: string;

    modalRef: NgbModalRef;

    storeDatas: any;

    selectedFilters: Array<Object> = [];

    selectedFiltersColsInit: Array<number> = [];

    selectedFiltersCols: Array<number> = [];

    modalIndex: number;

    filterData;

    filters: Array<any> = [];

    filtersBefore: Array<any> = [];

    isCollapsed = true;

    colsTypes: any;

    isNumeric: boolean = false;

    userDefinedType: string = '';

    private id;

    // multiverbatim
    selectedVerbatimCol: Array<number> = [];

    selectedColumn: Array<number> = [];

    arrayDisabled: Array<number> = [];

    confirmDisabled: boolean = true;

    corpusState$: Observable<GetCorpus>;

    @ViewChild('template')
    private defaultTabButtonsTpl: TemplateRef<any>;

    settings$: Observable<SettingsModel>;

    private mailContact: MailContact = {
        mailto: '',
        object: '',
    };

    constructor(
        private ngModalService: NgbModal,
        private addFilters: AddColumnsService,
        private fileService: FileService,
        private corpusService: CorpusService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store,
    ) {
        super();
    }

    ngOnInit() {
        this.corpusState$ = this.store.select(CorpusState);
        this.subs.sink = this.corpusState$.subscribe((state) => {
            this.id = state.corp_id;
            this.storeDatas = state;
            if (!state.status_flags.canChangeColumn) {
                this.router.navigate(['project', this.id, 'settings', 'columns']);
            }
            // Récupère le dernier fichier qui n'est pas en erreur
            const filesNotError = state.files.filter((file) => !file.file_error);
            this.storeDatas.file = filesNotError[filesNotError.length - 1];
            this.filename = this.storeDatas.file.file_name;
            this.colsTypes = this.storeDatas.file.file_fields_type;
            this.storeDatas.filters = state.corp_fields;
            this.filters = state.corp_fields;
            this.filtersBefore = this.filters.map((filter) => filter);
            this.fileService
                .getV1FileFilenameIdFileidStartDelimiter({
                    filename: this.storeDatas.file.file_name,
                    delimiter: this.storeDatas.file.file_delimiter,
                    fileid: this.storeDatas.file.file_id,
                })
                .subscribe((fileFirstLines) => {
                    // eslint-disable-next-line prefer-destructuring
                    this.storeDatas.fileFirstLines = fileFirstLines[0];

                    this.getFirstLine();
                });
        });

        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.mailContact = settings.vcrm.mail_contact || this.mailContact;
        });
    }

    getFirstLine() {
        const colsItems: Array<string> = [];
        const tableData = <Array<any>> this.storeDatas.fileFirstLines.content || [];
        const rows = <Array<any>> this.storeDatas.file.file_fields;

        rows.forEach((v, k) => {
            colsItems.push(v);
            this.storeDatas.corp_verbatim_fields.forEach((item) => {
                if (v === item.name) {
                    this.selectedVerbatimCol.push(k);
                }
            });

            if (v === this.storeDatas.corp_date_field.name) {
                this.selectDateCol = k;
            }
        });
        this.arrayDisabled = this.selectedVerbatimCol.concat(this.selectDateCol);

        this.columns = colsItems;
        this.columns = [...this.columns];
        this.storeDatas.filters.forEach((filter) => {
            const index = this.columns.indexOf(filter.inputName);
            this.selectedFiltersColsInit.push(index);
            this.selectedFilters.push({
                index,
                columns: filter.inputName,
            });
        });

        this.arrayDisabled = this.arrayDisabled.concat(this.selectedFiltersColsInit);

        const rowsList = [];
        tableData.forEach((row) => {
            const cellList = [];
            Object.entries(row).forEach(([, v]) => {
                cellList.push(v);
            });
            rowsList.push(cellList);
        });

        this.rows = rowsList;
    }

    selectFiltre(index) {
        // vg debug d_313098

        // si on clique sur une colonne désactivée, rien ne se passe
        if (this.arrayDisabled.includes(index)) {
            return;
        }

        this.corpusService
            .getV1CorpusCorpIdFormat({
                corpId: this.id,
                fieldname: this.columns[index],
            })
            .subscribe((results: any) => {
                this.userDefinedType = results.type;
                this.isNumeric = this.colsTypes[this.columns[index]] === 'n';
                this.displayModal(index);
            });
    }

    displayModal(index) {
        if (!this.arrayDisabled.includes(index)) {
            this.filterData = new UntypedFormGroup({
                analysis: new UntypedFormControl(true),
                colName: new UntypedFormControl(''),
                multiple: new UntypedFormControl(false),
                multipleDelim: new UntypedFormControl(''),
                numeric: new UntypedFormControl(false),
            });

            if (!this.isSelected(index)) {
                this.selectedFilters.push({
                    index,
                    columns: this.columns[index],
                });
                this.selectedFiltersCols.push(index);
            }
            this.openModal(this.defaultTabButtonsTpl, index);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    isSelected(index) {
        return _.includes(this.selectedFiltersCols, index);
    }

    openModal(template: TemplateRef<any>, index) {
        this.isCollapsed = true;
        this.modalIndex = index;

        this.modalRef = this.ngModalService.open(template, { backdrop: 'static' });
    }

    closeModal() {
        this.modalIndex = null;
        if (this.modalRef) {
            this.modalRef.close();
        }
    }

    cancel(index) {
        this.selectedFiltersCols = this.selectedFiltersCols.filter(
            (v) => v !== index,
        );
        this.closeModal();
    }

    removeFilter(index) {
        if (!this.arrayDisabled.includes(index)) {
            const filtersIndex = this.filters.findIndex(
                (i) => i.inputName === this.columns[index],
            );

            if (filtersIndex > -1) {
                this.filters.splice(filtersIndex, 1);
            }
            this.closeModal();
        }

        // vérification des modifications pour activer (ou non ) le bouton de validation
        this.checkUpdate();
    }

    onSubmit(filter) {
        const filterData: any = {};
        filterData.inputName = this.columns[this.modalIndex];
        if (!_.isEmpty(filter.colName)) {
            filterData.displayName = filter.colName;
        } else {
            filterData.displayName = filterData.inputName;
        }
        filterData.search = true;
        filterData.analyseFilter = filter.analysis;
        filterData.toneFilter = true;
        filterData.separator = filter.multipleDelim;
        filterData.multi = filter.multipleDelim !== '';
        filterData.digitalFilter = filter.numeric;

        const filtersIndex = this.filters.findIndex(
            (i) => i.inputName === filterData.inputName,
        );

        if (filtersIndex > -1) {
            this.filters[filtersIndex] = filterData;
        } else {
            this.filters.push(filterData);
        }
        this.closeModal();

        // vérification des modifications pour activer (ou non ) le bouton de validation
        this.checkUpdate();
    }

    close() {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }

    save() {
        this.router.navigate([`/project/${this.id}/settings/columns`]);
        this.addFilters.changeFilters(this.filters);
    }

    checkUpdate() {
        // on active le bouton de validation si on constate une différence entre la liste au chargement du composant et la liste mise à jour
        this.confirmDisabled = _.isEmpty(diff(this.filters, this.filtersBefore));
    }
}
