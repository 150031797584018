import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-error500',
    templateUrl: './error500.component.html',
})
export default class Error500Component {
    constructor(public bsModalRef: BsModalRef) {}
}
