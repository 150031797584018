/* eslint-disable import/no-import-module-exports */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from 'app/base.component';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import UserState from 'app/stores/state/user/user.state';
import { Observable } from 'rxjs';
import { User } from 'app/api/models';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import AppTranslateService from 'app/shared/services/translate.service';

/**
 * User component
 */
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    moduleId: module.id.toString(),
    providers: [NGXLogger],
    animations: [fadeInAnimation],
})
export default class UserComponent extends BaseComponent implements OnInit, AfterViewInit {
    public user: User;

    public columns = [];

    public authorizations = [];

    public currentLanguage: string;

    user$: Observable<User>;

    @ViewChild('roleNameTpl')
        roleNameTpl: TemplateRef<any>;

    @ViewChild('roleDescTpl')
        roleDescTpl: TemplateRef<any>;

    constructor(
        private appTranslateService: AppTranslateService,
        private translate: TranslateService,
        private cd: ChangeDetectorRef,
        private store: Store,
    ) {
        super();
    }

    ngOnInit() {
        // MAJ des infos utilisateurs
        this.user$ = this.store.select(UserState);
        this.subs.sink = this.user$.subscribe((state: User) => {
            this.user = state;
            this.currentLanguage = state.preferred_language.toLowerCase();
            this.authorizations = [];
            this.user.roles.forEach((role) => {
                this.authorizations.push({
                    role: `translations.user.roles.${role}.name`,
                    desc: `translations.user.roles.${role}.desc`,
                });
            });
        });

        // Manage language changes
        this.subs.sink = this.translate.onLangChange.subscribe(() => {
            this.currentLanguage = this.translate.currentLang;
        });
    }

    /**
     * Initialisation du tableau des autorisations
     */
    ngAfterViewInit() {
        this.columns = [
            {
                prop: 'role',
                title: 'translations.user.table.role',
                cellTemplate: this.roleNameTpl,
                style: {
                    width: '40%',
                },
            },
            {
                prop: 'desc',
                title: 'translations.user.table.description',
                cellTemplate: this.roleDescTpl,
                style: {
                    width: '60%',
                },
            },
        ];
        this.cd.detectChanges();
    }

    /**
     * Evenement déclenché lorsque l'utilisateur change de langue
     */
    onSwitchLanguage() {
        this.appTranslateService.initChangeLangue(this.currentLanguage);
    }
}
