// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  margin: 3px;
  top: 36px;
  bottom: 10px;
}
.legend.columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 200px;
  flex-wrap: wrap;
}
.legend.columns .legend-item {
  border-radius: 16px;
  padding: 5px 10px 5px 5px;
}
.legend.columns .legend-item .legend-color-dot {
  margin-right: 6px;
}
.legend .legend-item {
  font-weight: bold;
  display: inline-flex;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-bottom: 5px;
  margin-right: 5px;
  cursor: pointer;
  align-items: center;
  max-width: 100%;
  padding: 1px 5px 2px 2px;
}
.legend .legend-item .legend-color-dot {
  flex: 0 0 auto;
  height: 15px;
  width: 15px;
  margin-left: 2px;
  margin-right: 2px;
  transition: all 0.3s;
  border-radius: 50%;
  opacity: 1;
  border-width: 2px;
  border-style: solid;
}
.legend .legend-item.disabled {
  cursor: default !important;
  color: #ccc;
}
.legend .legend-item.disabled .legend-color-dot {
  border: 2px solid #ccc !important;
  background-color: white !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
