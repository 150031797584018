/**
 * Fonction permettant de trier un tableau d'objet par rapport à une propriété donnée et un mode (asc ou desc)
 */
function sortArrayByProp(array:Array<any>, prop:string, mode:boolean = false) {
    return array.sort((itemA, itemB) => {
        let a = itemA[prop];
        let b = itemB[prop];

        if (a === undefined) {
            a = '';
        }

        if (b === undefined) {
            b = '';
        }

        if (a.toString().toUpperCase() < b.toString().toUpperCase()) {
            return (mode) ? -1 : 1;
        }
        if (a.toString().toUpperCase() > b.toString().toUpperCase()) {
            return (mode) ? 1 : -1;
        }
        return 0;
    });
}

export default sortArrayByProp;
