/* eslint-disable import/no-import-module-exports */
import {
    AfterViewInit,
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngxs/store';
import CorpusService from 'app/utils/services/corpus.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { GetCorpus } from '../../../../api/models';
import { CorpusService as CorpusApi, UserService } from '../../../../api/services';
import CorpusState from '../../../../stores/state/corpus/corpus.state';

/**
 * Corpus users settings
 *
 * @export
 * @class CorpusSettingsUsersComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus-settings-users',
    templateUrl: './corpus-settings-users.component.html',
    styleUrls: ['./corpus-settings-users.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})
export default class CorpusSettingsUsersComponent implements OnInit, AfterViewInit {
    corpusState$: Observable<GetCorpus>;

    columns = [];

    users: any = [];

    user: string = '';

    messageOnAddUser: any;

    @ViewChild('form')
        form: NgForm;

    private id;

    @ViewChild('delRowTpl')
        delRowTpl: TemplateRef<any>;

    @ViewChild('messagesRowTpl')
        messagesRowTpl: TemplateRef<any>;

    @ViewChild('guestmessagesRowTpl')
        guestmessagesRowTpl: TemplateRef<any>;

    @ViewChild('adminRowTpl')
        adminRowTpl: TemplateRef<any>;

    @ViewChild('readerRowTpl')
        readerRowTpl: TemplateRef<any>;

    @ViewChild('colTpl')
        colTpl: TemplateRef<any>;

    @ViewChild('userTpl')
        userTpl: TemplateRef<any>;

    constructor(private store: Store, private userService: UserService, private corpusApi: CorpusApi, private corpusService: CorpusService, private permissionsService: NgxPermissionsService) {
        this.corpusState$ = this.store.select(CorpusState);
        this.corpusState$.subscribe((corpus) => {
            this.users = this.defaultSort(
                corpus.users.map((u) => {
                    const user: any = { ...u };
                    if (Number.parseInt(user.role_id, 10) === 1) {
                        user.admin = true;
                    } else if (Number.parseInt(user.role_id, 10) === 2) {
                        user.admin = false;
                    } else {
                        user.admin = false;
                    }
                    return user;
                }),
            );
            this.id = corpus.corp_id;
        });
    }

    ngOnInit(): void {
        this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                this.setColumns();
            }
        });
    }

    get adminCount() {
        return this.users.filter((user) => user.admin).length;
    }

    setColumns() {
        this.columns = [
            {
                prop: 'surname',
                title: 'translations.corpus.settings.users.username',
                cellTemplate: this.userTpl,
                style: {
                    width: '300px',
                },
            },
            {
                prop: 'role_id',
                title: 'translations.corpus.settings.users.admin',
                cellTemplate: this.adminRowTpl,
                style: {
                    width: '140px',
                },
            },
        ];

        if (this.permissionsService.getPermissions().corpusAdmin) {
            this.columns.push({
                title: '',
                headerClass: 'text-end',
                cellClass: 'text-end',
                maxWidth: 86,
                cellTemplate: this.delRowTpl,
                style: { 'text-align': 'right', width: '60px' },
            });
            this.columns.push({
                title: '',
                headerClass: 'text-end',
                cellClass: 'text-end',
                cellTemplate: this.messagesRowTpl,
                style: { 'font-size': '14px' },
            });
        } else {
            this.columns.push({
                title: '',
                headerClass: 'text-end',
                cellClass: 'text-end',
                cellTemplate: this.guestmessagesRowTpl,
                style: { 'font-size': '14px' },
            });
        }
    }

    ngAfterViewInit(): void {
        this.setColumns();
    }

    isCorpusOwner(user): boolean {
        return this.store.snapshot().corpus.corp_create_fk_username === user.username;
    }

    isConnectedUser(user): boolean {
        return this.store.snapshot().user.username === user.username;
    }

    isDeleteAllowed(user): boolean {
        return !this.isConnectedUser(user) && !this.isCorpusOwner(user);
    }

    // eslint-disable-next-line class-methods-use-this
    defaultSort(datas) {
        if (!datas) {
            return [];
        }
        return JSON.parse(
            JSON.stringify(
                datas.sort((rowA, rowB) => {
                    const a = `${rowA.surname} ${rowA.givenname}`;
                    const b = `${rowB.surname} ${rowB.givenname}`;

                    if (a.toString().toUpperCase() < b.toString().toUpperCase()) {
                        return -1;
                    }
                    if (a.toString().toUpperCase() > b.toString().toUpperCase()) {
                        return 1;
                    }
                    return 0;
                }),
            ),
        );
    }

    addUser(e) {
        e.preventDefault();
        if (this.form.valid) {
            this.userService.getV1UserEmailExist(this.user).subscribe({
                next: (cuid) => {
                    this.corpusApi
                        .postV1CorpusCorpIdRole({
                            username: { username: cuid.username, role_id: 2 },
                            corpId: this.id,
                        })
                        .subscribe({
                            next: () => {
                                this.messageOnAddUser = { type: 'success', msg: 'translations.corpus.settings.users.toast.added', user: this.user };
                                this.corpusService.loadCorpus(this.id);
                                this.user = '';
                            },
                            error: () => {
                                this.messageOnAddUser = { type: 'danger', msg: 'translations.corpus.settings.users.toast.notadded', user: this.user };
                            },
                        });
                },
                error: () => {
                    this.messageOnAddUser = { type: 'danger', msg: 'translations.corpus.settings.users.toast.notfound', user: this.user };
                },
            });
        } else {
            this.messageOnAddUser = { type: 'danger' };
        }
    }

    selectUser(name) {
        this.corpusApi
            .deleteV1CorpusCorpIdRole({
                username: { username: name },
                corpId: this.id,
            })
            .subscribe(() => {
                this.corpusService.loadCorpus(this.id);
            });
    }

    onChange(item, user) {
        const users = this.users.map((u) => {
            if (u.username === user) {
                u[item] = !u[item];
                if (u.admin) {
                    u.role_id = 1;
                } else {
                    u.role_id = 2;
                }
                this.corpusApi
                    .putV1CorpusCorpIdRole({
                        username: { username: user, role_id: u.role_id },
                        corpId: this.id,
                    })
                    .subscribe(() => {
                        this.corpusService.loadCorpus(this.id);
                    });
            }
            return u;
        });
        this.users = [...users];
    }
}
