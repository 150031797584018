import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { enGbLocale, frLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import SharedModule from 'app/shared/shared.module';
import AuthGuardService from 'app/utils/services/authguard.service';
import GdprGuardService from 'app/utils/services/gdprguard.service';
import ThematicEditorComponent from './components/thematic-editor/thematic-editor.component';

defineLocale('fr', frLocale);
defineLocale('en', enGbLocale);

@NgModule({
    imports: [
        SharedModule,
        TooltipModule,
        CollapseModule,
        BsDropdownModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        ModalModule,
        BsDatepickerModule.forRoot(),
    ],
    exports: [
        ModalModule,
        TooltipModule,
        CollapseModule,
        BsDropdownModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [ThematicEditorComponent],
    providers: [
        AuthGuardService,
        GdprGuardService,
    ],
})
export default class ThematicsModule {}
