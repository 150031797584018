import CorpusFlag from './corpusflag';
import { Descriptionmodel } from './descriptionmodel';
import { Field } from './field';
import { Fieldname } from './fieldname';
import { Fieldnameanddbname } from './fieldnameanddbname';
import { File } from './file';
import { Links } from './links';
import { Models } from './models';
import { Treatments } from './treatments';
import { Usersincorpus } from './usersincorpus';

export enum CorpusType {
    FILE = 1,
    MSURVEY_ACCOUNT = 2,
    MSURVEY_URL = 3,
    CONVERSATION = 4,
}

export interface GetCorpus {
    corp_date_field?: Fieldnameanddbname;
    corp_id?: number;
    corp_type?:number;
    corp_csv_separated_val?: string;
    corp_path?: string;
    corp_date_format?: string;
    corp_encoding?: string;
    corp_description?: string;
    corp_verbatim_fields?: Array<Fieldname>;
    corp_fields_score?: Array<Field>;
    corp_is_public?: boolean;
    corp_file_max_lines?: number;

    /**
   *  SOFT SAM UPDATE 29-11-2019
   * add corp_date_format_sample to display new info date_sample from /v1/corpus/{corpId API service about a corpus
  */
    corp_date_format_sample?: string;
    /**
   * Update Date Time in RFC3339 format
   */
    corp_creation_date?: string;

    /**
   * Update Date Time in RFC3339 format
   */
    corp_update_date?: string;
    corp_fields?: Array<Field>;
    corp_fields_not_in_file?: Array<Field>; // IB
    corp_name?: string;
    corp_is_tone?: number;
    corp_create_fk_user_id?: number;
    corp_create_fk_username?: string;
    corp_lang?: string;

    /**
   * Update Date Time in RFC3339 format
   */
    corp_min_date?: string;

    /**
   * Update Date Time in RFC3339 format
   */
    corp_max_date?: string;
    indexStopwords?: boolean;
    links?: Links;
    users?: Array<Usersincorpus>;

    /**
   * Filename of the attachment
   */
    attachment?: string;
    files?: Array<File>;

    /**
   * Label of the model
   */
    model?: string;
    cat_fields?: Array<Descriptionmodel>;

    /**
   * Nb of levels for the model of this corpus
   */
    cat_nb_levels?: number;

    /**
   * Label of the models for multiverbatim
   */

    at_least_one_classification?: boolean;
    at_least_one_clustering?: boolean;
    models?:Array<Models>;

    /**
   * Treatments for clouds words
   */
    treatments?:Treatments;

    status_flags?: CorpusFlag;

    verbatim_for_tone?: Array<VerbatimForTone>;

    has_copc?: boolean;
}

interface VerbatimForTone {
    displayName: string;
    DBName: string;
    name: string;
    lang: string;
}
