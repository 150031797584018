import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Folder } from 'app/api/models/folder';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ConfigurationRow from '../configuration-row';

@Component({
    selector: 'aiconfig-configuration-row-detail-template',
    templateUrl: './aiconfig-configuration-row-detail-template.component.html',
    styleUrl: './aiconfig-configuration-row-detail-template.component.scss',
})
/** Détail d'une configuration à afficher sous une ligne du tableau */
export default class AiconfigConfigurationRowDetailTemplateComponent {
    @Input()
        row: ConfigurationRow;

    @Input()
        folder: boolean = false;

    constructor(private aiconfigService: AiConfigService, private modalService: NgbModal, private translateService: TranslateService) {}

    removeFolder(folder: Folder) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.aiconfig.delete_configuration_modal_title';
        modal.componentInstance.content = this.translateService
            .instant('translations.analysisDashboard.aiconfig.delete_configuration_from_folder_modal_text', { name: this.row.configuration.config_name });
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                this.aiconfigService.removeConfigurationFromFolder(folder, this.row.configuration);

                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer dossier d\'une configuration IA',
                    track_cible: 'supprimer',
                });
            } else {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer dossier d\'une configuration IA',
                    track_cible: 'annuler',
                });
            }
        });
    }

    removeShareAll() {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.aiconfig.cancel_share_modal_title';
        modal.componentInstance.content = this.translateService
            .instant('translations.analysisDashboard.aiconfig.cancel_share_modal_text', { name: this.translateService.instant('translations.analysisDashboard.aiconfig.shared_to_all') });
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer partage d\'une configuration IA',
                    track_cible: 'supprimer',
                });

                await this.aiconfigService.unshareAll(this.row.configuration);
            } else {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer partage d\'une configuration IA',
                    track_cible: 'annuler',
                });
            }
        });
    }

    removeShareUser(user) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.aiconfig.cancel_share_modal_title';
        modal.componentInstance.content = this.translateService
            .instant('translations.analysisDashboard.aiconfig.cancel_share_modal_text', { name: user.mail });

        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer partage d\'une configuration IA',
                    track_cible: 'supprimer',
                });

                await this.aiconfigService.unshareUser(this.row.configuration, user.id_user);
            } else {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer partage d\'une configuration IA',
                    track_cible: 'annuler',
                });
            }
        });
    }

    get selectOnlyOne() {
        return this.aiconfigService.selectOnlyOne;
    }
}
