import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export default class NewsModalService {
    public open = new Subject<void>();

    openModal() {
        this.open.next();
    }
}
