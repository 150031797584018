import {
    ChangeDetectorRef, Component, Input, OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataCounted } from 'app/api/models';
import { Yaxis } from 'app/api/models/histoparams';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { DistributionDetails, DistributionDetailsItem } from './model';

@Component({
    selector: 'app-distribution-details-popup',
    templateUrl: './distribution-details-popup.component.html',
    styleUrls: ['./distribution-details-popup.component.scss'],
})
export default class DistributionDetailsPopupComponent implements OnInit {
    constructor(
        public modal: NgbActiveModal,
        private changeDetectorRef: ChangeDetectorRef,

    ) { }

    data: DistributionDetails;

    selectedValue : DistributionDetailsItem;

    chartTooltips: ChartTooltipsService;

    types = ['deltasat', 'nps', 'deltatone'];

    typeData = [];

    @Input()
        unit: Yaxis;

    @Input()
        configToValues;

    get modalTitle() {
        return [Yaxis.occur].includes(this.unit) ? 'count' : 'percent';
    }

    get modalTitleValue() {
        return Math.round(parseFloat(this.selectedValue[this.configToValues[this.unit]]) * 10) / 10;
    }

    ngOnInit(): void {
        // eslint-disable-next-line prefer-destructuring
        this.onValueChange(this.data.values[0]);
    }

    onValueChange(value) {
        this.selectedValue = value;

        this.typeData = this.types.map((type) => this.getGraphData(type));

        this.changeDetectorRef.detectChanges();
    }

    getGraphData(type) {
        if (type === this.types[0]) {
            return this.getDelatasat();
        } if (type === this.types[1]) {
            return this.getNps();
        } if (type === this.types[2]) {
            return this.getDeltatone();
        }
        return null;
    }

    getDelatasat() {
        return {
            average: this.selectedValue.sat_avg,
            values: this.selectedValue.sat_distrib,
        };
    }

    getNps() {
        return {
            average: this.selectedValue.nps_avg,
            values: this.selectedValue.nps_distrib,
        };
    }

    getDeltatone() {
        return {
            average: this.selectedValue.sentiment_avg,
            values: this.selectedValue.sentiment,
        };
    }
}
