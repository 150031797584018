import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import RouterTraceService from 'app/utils/services/router-trace.service';
import AuthGuardService from 'app/utils/services/authguard.service';
import HomeComponent from './home.component';

const HOMEROUTES: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [RouterTraceService, AuthGuardService],
        data: {
            meta: {
                title: 'VisualCRM home',
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(HOMEROUTES)],
    providers: [AuthGuardService, RouterTraceService],
    exports: [RouterModule],
})
export default class HomeRoutingModule {}
