import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import State from './cookies-consent-state';

@Injectable({
    providedIn: 'root',
})
export default class CookiesConsentService implements OnInit {
    public state: BehaviorSubject<State> = new BehaviorSubject(State.unknown);

    constructor(
        private cookieService: CookieService,
        private logger: NGXLogger,
    ) {
        this.ngOnInit();
    }

    // eslint-disable-next-line @angular-eslint/contextual-lifecycle
    ngOnInit(): void {
        const isCookieSet = this.cookieService.check('cookieconsent_status');
        this.logger.debug('CookiesConsentService', isCookieSet);
        this.state.next(isCookieSet ? State.accepted : State.not_accepted_yet);
    }

    public accept() {
        this.cookieService.set('cookieconsent_status', 'true', 365, '/');
        this.state.next(State.accepted);
    }
}
