// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-grid-container {
  display: grid;
  grid-template-columns: 700px auto;
  grid-template-rows: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-areas: "title title" "project-params ." "project-form ." "project-action .";
  background-color: white;
}

.css-project-title {
  grid-area: title;
}

.css-project-params {
  grid-area: project-params;
  margin-bottom: 10px;
}

.css-project-form {
  grid-area: project-form;
}

.css-project-actions {
  grid-area: project-action;
  padding-top: 15px;
}
.css-project-actions .delete {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #ccc;
}

.mb-10 {
  margin-bottom: 10px;
}

.d-flex-ellipsis {
  display: flex;
  min-width: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
