import {
    Component, Input, EventEmitter, Output, ViewChild, ElementRef, HostBinding, HostListener, OnDestroy, OnChanges, SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CloudData, SentimentKey } from '../../word-cloud.interfaces';
import analytics from '../../words.analytics';

@Component({
    selector: 'app-one-word',
    templateUrl: './one-word.component.html',
    styleUrls: ['./one-word.component.scss'],
})
export default class OneWordComponent implements OnDestroy, OnChanges {
    constructor(
        private self:ElementRef,
        private translate: TranslateService,
        private sanitizer: DomSanitizer,
    ) {
        this.currentLanguage = this.translate.currentLang;
        this.sanitizer.bypassSecurityTrustStyle('var(--info-right)');
    }

    @HostBinding('style.top.px')
    get top(): number {
        return this.word.top;
    }

    @HostBinding('style.left.px')
    get left(): number {
        return this.word.left;
    }

    @HostBinding('style.height.px')
    get height(): number {
        return this.word.height;
    }

    @HostBinding('style.font-size')
    get fontSize(): string {
        return `${this.word.fontSize}px`;
    }

    @HostBinding('style.color')
    get color(): string {
        return this.word.color;
    }

    @HostListener('mouseover') mouseOver() {
        if (this.closeTooltipTimer) {
            clearTimeout(this.closeTooltipTimer);
            this.closeTooltipTimer = 0;
        }

        this.openTooltipTimer = setTimeout(() => {
            this.over.emit(this.wordInternal);
        }, 0);
    }

    @HostListener('mouseout') mouseOut() {
        if (this.openTooltipTimer) {
            clearTimeout(this.openTooltipTimer);
            this.openTooltipTimer = 0;
        }

        this.closeTooltipTimer = setTimeout(() => {
            this.out.emit(this.wordInternal);
        }, 150);
    }

    computeTooltipArrowPosition() {
    // calcul de la position de la fleche de la tooltip
        const tooltipWidth = this.wordTooltip.nativeElement.offsetWidth;
        this.tooltipLeft = (this.wordInternal.width - tooltipWidth) / 2;

        if (this.wordInternal.left + this.tooltipLeft < 0) {
            this.tooltipLeft = -this.wordInternal.left;
        }

        const parentWidth = this.self.nativeElement.offsetParent.clientWidth;
        if (this.wordInternal.left + this.tooltipLeft + tooltipWidth > parentWidth) {
            this.tooltipLeft = parentWidth - tooltipWidth - this.wordInternal.left;
        }

        this.arrowLeft = -this.tooltipLeft + this.wordInternal.width / 2;
    }

    wordOver() {
        this.computeTooltipArrowPosition();

        this.displayToolTip = true;
    }

    wordOut() {
        this.displayToolTip = false;
    }

    ngOnDestroy(): void {
        this.mouseOut();
    }

    @ViewChild('wordTooltip')
        wordTooltip: ElementRef;

    @Output()
        wordSelected: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Output()
        over: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Output()
        out: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Output()
        hide: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Input()
        wordHovered: CloudData;

    wordingsAnalytics :any = analytics;

    @Input()
        category:string;

    @Input()
        view :string;

    @Input()
        clickable;

    @Input()
        canHide = true;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.wordTooltip && changes.wordHovered) {
            if (!changes.wordHovered.currentValue || changes.wordHovered.currentValue.key !== this.word.key) {
                this.wordOut();
            } else {
                this.wordOver();
            }

            this.overlay = changes.wordHovered.currentValue && changes.wordHovered.currentValue.key !== this.word.key;
        }
    }

    infoButtonRight: number;

    public currentLanguage;

    wordInternal: CloudData;

    pos: number = 0;

    neg: number = 0;

    mit: number = 0;

    neu: number = 0;

    displayToolTip: boolean = false;

    overlay: boolean = false;

    tooltipTranslation: string = '-50%';

    tooltipLeft:number = 0;

    arrowLeft: number;

    /**
   * Ce timer permet à l'utilisateur de passer du mot à la tooltip sans que la tooltip ne se ferme automatiquement immédiatement
   */
    closeTooltipTimer: any;

    /**
   * Ce timer permet d'ajouter un petit délai entre le focus d'un mot et l'apparition de la tooltip pour fluidifier la navigation
   */
    openTooltipTimer: any;

    infoTooltipVisible: boolean = false;

    @Input()
        useDeltaTone: boolean;

    @Input() set word(value: CloudData) {
        this.wordInternal = value;

        if (value.top_sentiment) {
            value.top_sentiment.forEach((sentiment) => {
                if (sentiment.key === SentimentKey.POS) {
                    this.pos = sentiment.percent;
                } else if (sentiment.key === SentimentKey.NEU) {
                    this.neu = sentiment.percent;
                } else if (sentiment.key === SentimentKey.MIT) {
                    this.mit = sentiment.percent;
                } else if (sentiment.key === SentimentKey.NEG) {
                    this.neg = sentiment.percent;
                }
            });
        }
    }

    get word(): CloudData {
        return this.wordInternal;
    }

    getSentimentValue(value: string) {
        return this.word.top_sentiment.filter((sentiment) => sentiment.key === value)[0].key;
    }
}
