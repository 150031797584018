// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  animation: rotate 2s linear infinite;
  height: 20px;
  left: 3px;
  position: absolute;
  top: 3px;
  transform-origin: center center;
  width: 20px;
  z-index: 1;
}

.spinner .path {
  animation: dash 1.5s ease-in-out infinite;
  stroke: rgb(255, 102, 0);
}

.file-state {
  list-style-type: none;
  margin-bottom: 30px;
  padding-left: 0;
}
.file-state li {
  align-items: center;
  color: #666;
  display: flex;
  font-weight: bold;
  padding: 6px 5px;
  position: relative;
}
.file-state li .spinner,
.file-state li .icon-tick,
.file-state li .icon-error-severe {
  display: none;
}
.file-state li::before {
  background-color: #ccc;
  border-radius: 50%;
  color: #ccc;
  content: " ";
  display: inline-block;
  height: 16px;
  margin-right: 10px;
  width: 16px;
}
.file-state li::after {
  border-left: 2px solid #ccc;
  content: " ";
  height: 30px;
  left: 12px;
  position: absolute;
  top: 20px;
}
.file-state li.success {
  color: #000;
}
.file-state li.success::before {
  background-color: #32c832;
}
.file-state li.success::after {
  border-color: #32c832;
}
.file-state li:last-child.success {
  padding-left: 1px;
  padding-top: 2px;
}
.file-state li:last-child.success::before {
  height: 24px;
  margin-right: 7px;
  width: 24px;
}
.file-state li:last-child.success .icon-tick {
  color: #fff;
  display: inline;
  font-size: 18px;
  left: 4px;
  position: absolute;
}
.file-state li:last-child::after {
  display: none;
}
.file-state li.inprogress {
  color: #000;
}
.file-state li.inprogress .spinner {
  display: block;
}
.file-state li.inprogress::before {
  background-color: #fff;
}
.file-state li.danger {
  color: #000;
}
.file-state li.danger::before {
  background-color: #fff;
}
.file-state li.danger .icon-error-severe {
  color: #cd3c14;
  display: inline;
  font-size: 28px;
  left: -1px;
  position: absolute;
  top: 0;
  z-index: 1;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
