import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Dashboardforuser, Dashboardlist, DashboardType } from 'app/api/models';

@Component({
    selector: 'app-select-dashboard-list',
    templateUrl: './select-dashboard-list.component.html',
    styleUrls: ['./select-dashboard-list.component.scss'],
})
export default class SelectDashboardListComponent {
    @Input()
        dashs: Dashboardlist;

    @Input()
        dashboardType: DashboardType;

    @Input()
        selectedDashboard: Dashboardforuser;

    @Input()
        placeholder: string;

    @Input()
        isPlaceholderSelectable: boolean;

    @Output()
        selectDashboard: EventEmitter<Dashboardforuser> = new EventEmitter();

    onSelectDashboard(dash: Dashboardforuser) {
        this.selectDashboard.emit(dash);
    }
}
