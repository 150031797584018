/* eslint-disable import/no-import-module-exports */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import AddColumnsService from 'app/utils/services/add-columns.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { BaseComponent } from 'app/base.component';
import { Field } from '../../../../api/models/field';
import { CorpusService as CorpusApi } from '../../../../api/services';
import CorpusService from '../../../../utils/services/corpus.service';
import { SetCorpus } from '../../../../stores/actions/corpus/corpus.actions';
import ManageDashboardService from '../../corpus-manage-dashboard.service';

/**
 * Corpus filters settings
 *
 * @export
 * @class CorpusSettingsFiltersComponent
 */

const DEFAULT_ARRAY: Array<Field> = [{
    inputName: 'defaultInputName',
    displayName: 'defaultDisplayName',
}];

@Component({
    selector: 'app-corpus-settings-filters',
    templateUrl: './corpus-settings-filters.component.html',
    styleUrls: ['./corpus-settings-filters.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})
export default class CorpusSettingsFiltersComponent extends BaseComponent {
    public corpus: any;

    public isDisabled: boolean = true;

    private initFields: any;

    public showFilters = false;

    navData: Array<any> = [];

    constructor(
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private addFilters: AddColumnsService,
        private manageDashboardService: ManageDashboardService,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super();
        this.subs.sink = this.addFilters.currentFilters.subscribe((navData) => {
            this.navData = navData;
        });
        this.updateForm();
    }

    updateForm() {
        this.corpusApi
            .getV1CorpusCorpId(this.store.snapshot().settings.currentCorpus)
            .subscribe((corpus) => {
                this.store.dispatch(new SetCorpus(corpus));
                const corpusData = JSON.parse(JSON.stringify(corpus));
                // Sort fields by inputName
                corpusData.corp_fields = corpusData.corp_fields.sort((a, b) => {
                    if (a.inputName.toUpperCase() > b.inputName.toUpperCase()) {
                        return 1;
                    }
                    if (a.inputName.toUpperCase() < b.inputName.toUpperCase()) {
                        return -1;
                    }
                    return 0;
                });

                this.initFields = JSON.parse(JSON.stringify(corpusData.corp_fields));
                if (this.navData !== undefined && JSON.parse(JSON.stringify(this.navData))[0].inputName !== 'defaultInputName') {
                    corpusData.corp_fields = JSON.parse(JSON.stringify(this.navData)).sort(
                        (a, b) => {
                            if (a.inputName.toUpperCase() > b.inputName.toUpperCase()) {
                                return 1;
                            }
                            if (a.inputName.toUpperCase() < b.inputName.toUpperCase()) {
                                return -1;
                            }
                            return 0;
                        },
                    );
                    this.isDisabled = false;
                }
                this.corpus = corpusData;
                this.addFilters.changeFilters(DEFAULT_ARRAY);
                this.showFilters = true;
                this.checkIsValidOrModified();
            });
    }

    checkIsValidOrModified() {
        if (this.corpus.corp_fields.filter(((value) => value.displayName.trim() === '')).length !== 0) {
            this.isDisabled = true;
            return;
        }

        const normalizeSeparators = function (fields) {
            const clone = JSON.parse(JSON.stringify(fields));

            clone.forEach((f) => {
                // si le champ n'est pas multi valué, on met une valeur en dur sur le séparateur pour ne pas en tenir compte dans le calcul de la différence
                if (!f.multi) {
                    f.separator = 'N/A';
                }
            });
            return clone;
        };

        const delta = diff(normalizeSeparators(this.initFields), normalizeSeparators(this.corpus.corp_fields));
        this.isDisabled = _.isEmpty(delta);
    }

    removeFilter(index) {
        this.corpus.corp_fields.splice(index, 1);
        this.checkIsValidOrModified();
    }

    changeProp(i, value, prop) {
        this.corpus.corp_fields[i][prop] = value;
        this.checkIsValidOrModified();
    }

    hasNumericData() {
    // renvoyer un tableau contenant tous les elements de corp_fields qui remplisset la condition  element.digitalFilter = true et qui ne sont pas des colonnes de satisfaction ou reco
        return this.corpus.corp_fields.filter((element) => element.digitalFilter && !element.satField && !element.recoField).length > 0;
    }

    hasNonNumericData() {
        return this.corpus.corp_fields.filter((element) => !element.digitalFilter && !element.satField && !element.recoField).length > 0;
    }

    validate() {
        this.save();
    }

    cancel() {
        this.updateForm();
    }

    updateCorpusAndGoToFileSettings() {
        this.corpusApi
            .getV1CorpusCorpId(this.corpus.corp_id)
            .subscribe((data) => {
                // On met à jour les informations modifiées du corpus dans le corpus service (ici les fields)
                this.corpusService.currentCorpus.value.corp_fields = data.corp_fields;
                this.corpusService.currentCorpus.value.corp_verbatim_fields = data.corp_verbatim_fields;
                // On met à jour le corpus dans le store
                this.store.dispatch(new SetCorpus(data)).subscribe(() => {
                    this.router.navigate(['../', 'files'], { relativeTo: this.route });
                });
            });
    }

    save() {
        this.corpusApi
            .patchV1CorpusCorpId({
                corpId: this.corpus.corp_id,
                body: { fields: this.corpus.corp_fields },
            })
            .subscribe({
                next: () => {
                    this.updateCorpusAndGoToFileSettings();
                },
                error: (err) => {
                    this.manageDashboardService.firstDashboardService.displayServerError(err.error);
                },
            });
    }
}
