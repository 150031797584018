import {
    Component, Input, OnInit, AfterViewInit, ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'app/base.component';
import { Choice } from 'app/api/models/choice';

@Component({
    selector: 'app-data-choice-list',
    templateUrl: './data-choice-list.component.html',
    styleUrls: ['./data-choice-list.component.scss'],
})
export default class DataChoiceListComponent extends BaseComponent implements OnInit, AfterViewInit {
    checkBoxForm: UntypedFormGroup;

    masterBarSelected: boolean;

    masterCurveSelected: boolean;

    masterBarEmpty: boolean;

    masterCurveEmpty: boolean;

    @Input()
        userChoices: Choice[];

    // the message to retrun to the parent
    constructor(
        public modalRef: NgbActiveModal,
        private fb: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    get isValid() {
        return this.userChoices !== null && this.userChoices.some((choice) => choice.bar) && this.userChoices.some((choice) => choice.curve);
    }

    ngOnInit() {
        this.getCheckedMastersItems();

        this.checkBoxForm = this.fb.group({
            barChecked: this.fb.array([]),
            curvesChecked: this.fb.array([]),
        });
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    close() {
        this.modalRef.close(false);
    }

    validate() {
        this.modalRef.close(this.userChoices);
    }

    /**
   * triggered when a user check or unchek a chekbox from the list to display bar choices
   * @param selectedItem
   * @param isChecked
   */
    onBarChange(selectedItem: Choice, isChecked: boolean) {
        selectedItem.bar = isChecked;
        this.userChoices.find((o) => o.db_name === selectedItem.db_name).bar = isChecked;
        this.areAllBarChecked();
    }

    /**
   * triggered when a user check or unchek a chekbox from the list to display curve choices
   * @param selectedItem
   * @param isChecked
   */
    onCurvesChange(selectedItem: Choice, isChecked: boolean) {
        selectedItem.curve = isChecked;
        this.userChoices.find((o) => o.db_name === selectedItem.db_name).curve = isChecked;
        // check if all curves are checked
        this.areAllCurveChecked();
    }

    /**
   *  triggered when user wants to check or uncheck ALL checboxes form the barlist choices
   */

    onAllBarChange(isChecked: boolean) {
        this.userChoices.forEach((o) => {
            o.bar = isChecked;
        });
        this.cd.detectChanges();
    }

    /**
   *  triggered when user wants to check or uncheck ALL checboxes form the barlist choices
   */

    onAllCurveChange(isChecked: boolean) {
        this.userChoices.forEach((o) => {
            o.curve = isChecked;
        });
        this.cd.detectChanges();
    }

    areAllCurveChecked() {
        this.masterCurveSelected = (this.userChoices.every((o) => o.curve === true));
        return this.masterCurveSelected;
    }

    areAllCurveEmpty() {
        this.masterCurveEmpty = this.userChoices.every((o) => o.curve === false);
        return this.masterCurveEmpty;
    }

    areAllBarChecked() {
        this.masterBarSelected = this.userChoices.every((o) => o.bar === true);
        return this.masterBarSelected;
    }

    areAllBarsEmpty() {
        this.masterBarEmpty = this.userChoices.every((o) => o.bar === false);
        return this.masterBarEmpty;
    }

    getCheckedMastersItems() {
        this.areAllBarChecked();
        this.areAllCurveChecked();
    }
}
