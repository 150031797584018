/* eslint-disable no-underscore-dangle */
import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Yaxis } from 'app/api/models/histoparams';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { LineChartParamsConfig, GraphParamsConfig } from 'app/api/models/dashboard-config';
import CorpusService from 'app/utils/services/corpus.service';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import { HasSatisfaction } from 'app/api/models/fieldname';
import { diff } from 'deep-object-diff';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { MergedDistributionData } from 'app/api/models/paramsformerge';
import { addDashboardNumberToLineChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-line-chart';
import { addDashboardNumberToAxis } from 'app/utils/chartjs-plugins/add-dashboard-number-to-axis';
import LineChartSettingsPopupComponent from '../../../graphs-generic-components/line-chart-settings-popup/line-chart-settings-popup.component';
import { LegendItem } from '../../../graphs-generic-components/graph-checkbox-legend/graph-checkbox-legend.component';
import DistributionDetailsPopupComponent from '../../../graphs-generic-components/distribution-details-popup/distribution-details-popup.component';
import { GraphConf, settingsPopupAnalytics } from '../../distribution-graphs.component';
import DistributionDetailsmergedPopupComponent from '../../../graphs-generic-components/distribution-details-popup/distribution-details-merged-popup/distribution-details-merged-popup.component';
import { DistributionDetails } from '../../../graphs-generic-components/distribution-details-popup/model';
import AbstractLineChartComponent from '../../../graphs-generic-components/abstract-line-chart-component';

export enum ConfigToValues {
    occur = 'occur',
    percent = 'percent',
    deltasat = 'sat_avg',
    deltatone = 'sentiment_avg',
    nps = 'nps_avg',
}

@Component({
    selector: 'app-distribution-line-chart',
    templateUrl: './distribution-line-chart.component.html',
})
export default class LineGraphComponent extends AbstractLineChartComponent implements OnChanges {
    constructor(
        private graphService: GraphService,
        private modalService: NgbModal,
        translateService: TranslateService,
        private corpusService: CorpusService,
        chartTooltips: ChartTooltipsService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super(chartTooltips, translateService);
    }

    chartData = { labels: [], datasets: [] };

    legendItems: Array<LegendItem> = [];

    selectedMergedItem;

    plugins = [addDashboardNumberToLineChart, addDashboardNumberToAxis];

    @Input()
        conf: GraphConf;

    @ViewChild('chart')
        chart: BaseChartDirective;

    options = {};

    @Input()
        data: MergedDistributionData;

    private hasSatisfactionInfos: HasSatisfaction;

    @Output()
        configChanged: EventEmitter<GraphParamsConfig> = new EventEmitter();

    // cet input n'a de valeur que sur les composants non fusionnés
    @Input()
        dashboardService: DashboardService;

    get isMerged() {
        return !this.dashboardService;
    }

    get currentConfig() {
        return this.dashboardService
            ? this.dashboardService.currentConfig
            : this.manageDashboardService.currentDashboardComparisonConfig;
    }

    get params() {
        return this.currentConfig.getValue()[this.conf.type];
    }

    getLineChartConfig() {
        return this.params.distri;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            this.hasSatisfactionInfos = this.corpusService.currentCorpus.getValue().corp_verbatim_fields.find(
                (verbatim) => verbatim.DBName === this.manageDashboardService.getVerbatimDbName(),
            ).has_satisfaction;
            this.updateData(changes.data.currentValue);
        }
    }

    getGraphService() {
        return this.graphService;
    }

    getDashboardService() {
        return this.dashboardService;
    }

    updateData(data: MergedDistributionData) {
        if (!data) {
            return;
        }

        this.prepareGraphLabels(data);
        this.options = this.getGraphOptions();

        this.chartData = {
            labels: this.labels,
            datasets: [],
        };

        this.legendItems = [];

        if (data.distribution.values) {
            data.distribution.values.forEach((countPeriodMerge) => {
                const name = this.conf.display_name(countPeriodMerge.name);

                countPeriodMerge.merged_values.forEach((mergedValue, dashboardNumber) => {
                    if (mergedValue.distribution) {
                        let color = this.getColor(name);

                        if (this.isMerged) {
                            if (dashboardNumber === 0) {
                                color = '#000';
                            } else if (dashboardNumber === 1) {
                                color = '#ccc';
                            } else if (dashboardNumber === 2) {
                                color = '#666';
                            }
                        }

                        const selectedBars = this.params.legend_distri;
                        const disabled = mergedValue.distribution.every((d) => (d[ConfigToValues[this.params.distri.yaxis]] === undefined || d[ConfigToValues[this.params.distri.yaxis]] === null));
                        let selected = this.isMerged ? countPeriodMerge.name === this.selectedMergedItem : !selectedBars.includes(countPeriodMerge.name);

                        if (!this.legendItems.find((item) => item.inputName === countPeriodMerge.name)) {
                            this.legendItems.push({
                                color,
                                displayName: name,
                                inputName: countPeriodMerge.name,
                                selected,
                                disabled,
                            });
                        }

                        this.selectedMergedItem = this.params.legend_distri_merged || this.legendItems[0]?.inputName;

                        const hidden = this.isMerged ? (countPeriodMerge.name !== this.selectedMergedItem) : selectedBars.includes(countPeriodMerge.name);

                        this.chartData.datasets.push({
                            dashboardNumber,
                            xAxisID: this.axis[`dashboard_${dashboardNumber}`],
                            label: name,
                            borderColor: color,
                            pointBackgroundColor: color,
                            pointRadius: 5,
                            tension: 0.4,
                            data: mergedValue.distribution,
                            fill: false,
                            hidden,
                        });

                        if (!this.isMerged && disabled && selected) {
                            selected = false;
                            // je dois mettre un settimeout, car la référence est remplacée avant la fin du chargement, et les modifications sont annulées
                            // ainsi la modification a lieu après, dans la nouvelle référence de la configuration
                            setTimeout(() => {
                                this.params.legend_distri.push(countPeriodMerge.name);
                                this.params.legend_distri.push(countPeriodMerge.name);
                            });
                        }
                    }
                });
            });

            if (![Yaxis.percent, Yaxis.occur].includes(this.params.distri.yaxis) && !this.hasSatisfactionInfos[this.params.distri.yaxis]) {
                this.params.distri.yaxis = Yaxis.percent;
                this.options = this.getGraphOptions();
            }
        }

        this.chartData.datasets = this.chartData.datasets.filter((dataset) => dataset.xAxisID);
    }

    getColor(displayName): string {
        return this.manageDashboardService.getDataColor(this.conf.type, displayName);
    }

    getDataType() {
        return this.translateService.instant(`translations.analysisDashboard.charts.settings_top_type_${this.conf.type}`).toLowerCase();
    }

    // eslint-disable-next-line class-methods-use-this
    openSentimentModal(items) {
        if (items.length > 0) {
            gtmClick({
                track_category: settingsPopupAnalytics[this.conf.type].track_category,
                track_name: `vue temporelle ${settingsPopupAnalytics[this.conf.type].partial_track_name} tooltip`,
            });

            const popupData: DistributionDetails = {
                key: this.chartData.labels[items[0].index],
                values: null,
            };

            const { label } = this.chartData.datasets[items[0].datasetIndex];
            const datasets = this.chartData.datasets.filter((dataset) => dataset.label === label);

            popupData.values = datasets.map((dataset) => {
                const data = dataset.data[items[0].index];
                if (data) {
                    data.label = dataset.label;
                    data.dashboardNumber = dataset.dashboardNumber;
                    return data;
                }
                return null;
            }).filter((data) => data);

            const modal = this.modalService.open(this.isMerged ? DistributionDetailsmergedPopupComponent : DistributionDetailsPopupComponent);
            modal.componentInstance.data = popupData;
            modal.componentInstance.configToValues = ConfigToValues;
            modal.componentInstance.unit = this.params.distri.yaxis;
            modal.componentInstance.chartTooltips = this.chartTooltips;
        }
    }

    getGraphOptions() {
        const { yaxis } = this.params.distri;
        const thisInternal = this;
        const options = {
            onHover(event, items, chart) {
                chart.canvas.style.cursor = (items.length > 0 ? 'pointer' : 'default');
            },
            scales: {

                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        min: 0,
                        color: '#000',
                        callback(value) {
                            return value + (yaxis === Yaxis.occur ? '' : '%');
                        },
                    },
                    title: {
                        display: true,
                        text: this.translateService.instant(`translations.analysisDashboard.distribution.Yaxis_title.${this.params.distri.yaxis}`),
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 2,
            animation: false,
            plugins: {
                addDashboardNumberToLineChart: thisInternal.isMerged,
                addDashboardNumberToAxis: thisInternal.isMerged,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.customTooltips(),
                },
            },
            parsing: {
                xAxisKey: ConfigToValues[this.params.distri.yaxis],
                yAxisKey: ConfigToValues[this.params.distri.yaxis],
            },
        };

        for (let dashboardNumber = 0; dashboardNumber < this.manageDashboardService.dashboardServices.length; dashboardNumber += 1) {
            const axis = this.axis[`dashboard_${dashboardNumber}`];
            if (axis && !options.scales[axis]) {
                options.scales[axis] = {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        source: 'labels',
                        color: '#000',
                        callback(value) {
                            return thisInternal.labels[value][`dashboard_${dashboardNumber}`];
                        },
                    },
                };
            }
        }

        return options;
    }

    prepareGraphLabels(data: MergedDistributionData) {
        this.distribution2Axis(data.distribution);
    }

    toggle(index: number) {
        if (!this.legendItems[index]) {
            return;
        }

        if (this.isMerged) {
            this.chartData.datasets.forEach((dataset) => {
                dataset.hidden = (dataset.label !== `${this.legendItems[index].displayName}`);
            });
            this.chart.chart.update();

            this.params.legend_distri_merged = this.legendItems[index].inputName;
            this.selectedMergedItem = this.legendItems[index].inputName;
        } else {
            const dataset = this.chartData.datasets[index];
            dataset.hidden = !dataset.hidden;
            this.chart.chart.update();

            const { inputName } = this.legendItems[index];

            if (dataset.hidden) {
                this.params.legend_distri.push(inputName);
            } else {
                this.params.legend_distri = this.params.legend_distri.filter((legend) => legend !== inputName);
            }

            this.params.legend_distri.sort();
        }

        if (this.manageDashboardService.isDashboardComparison) {
            this.manageDashboardService.setComparisonDashbardConfig(this.conf.type, this.params);
        } else {
            this.dashboardService.setDistributionConfig(this.conf.type, this.params);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    isSelected(item) {
        return !item._meta[Object.keys(item._meta)[0]].hidden;
    }

    openSettings() {
        const modal = this.modalService.open(LineChartSettingsPopupComponent);
        modal.componentInstance.initialParams = this.params.distri;
        modal.componentInstance.graphService = this.graphService;
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.conf = this.conf;
        modal.componentInstance.hasSatisfactionInfos = this.hasSatisfactionInfos;
        modal.componentInstance.gtmTrackCategory = settingsPopupAnalytics[this.conf.type].track_category;
        modal.componentInstance.partialGtmTrackName = settingsPopupAnalytics[this.conf.type].partial_track_name;
        modal.componentInstance.save.subscribe((params: LineChartParamsConfig) => {
            const diffParams: Partial<LineChartParamsConfig> = diff(this.params.distri, params);
            const { yaxis, ...otherParams } = diffParams;
            this.params.distri = params;

            if (this.isMerged) {
                this.manageDashboardService.setComparisonDashbardConfig(this.conf.type, this.params);
            } else {
                this.dashboardService.setDistributionConfig(this.conf.type, this.params);
            }

            // Si il y a des différences sur period ou top_activated ou nb_values_chart (seulement lorsqu'il est activé), on emet un event pour recharger les données via l'api
            if (otherParams.period || otherParams.top_activated !== undefined || (otherParams.nb_values_chart && this.params.distri.top_activated === true)) {
                this.configChanged.emit(this.params);
            } else if (yaxis) {
                // sinon si on a changé yaxis seuelement, on met à jour le graph tout seul car on a déjà les données
                this.updateData(this.data);
            }
        });
    }

    exportGraphImage(chart) {
        gtmClick({
            track_category: settingsPopupAnalytics[this.conf.type].track_category,
            track_name: `vue temporelle ${settingsPopupAnalytics[this.conf.type].partial_track_name} export png`,
        });
        const graphTitle = this.translateService.instant(this.conf.evolution_title, { model: (this.manageDashboardService.getCurrentModel()?.model ?? '') });
        this.graphService.exportChartToPng(chart, graphTitle);
    }

    customTooltips() {
        const thisInt = this;
        // eslint-disable-next-line func-names

        return function (context) {
            return (thisInt.isMerged ? thisInt.customTooltipsMerged : thisInt.customTooltipsSideBySyde)(context, thisInt);
        };
    }

    // eslint-disable-next-line class-methods-use-this
    isPointClickable(): boolean {
        return true;
    }

    getPointValue(data) {
        return (Math.round(data[ConfigToValues[this.getLineChartConfig().yaxis]] * 10) / 10) + (this.getLineChartConfig().yaxis === 'occur' ? '' : '%');
    }

    closeResult = '';
}
