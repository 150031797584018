// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.vcrm-form-triangle-down::before {
  font-size: 2rem;
}

.vcrm-form-triangle-up::before {
  font-size: 2rem;
}

.my-tr {
  min-width: 644px;
}

.my-label {
  font-weight: normal !important;
}

.mw {
  max-width: 550px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
