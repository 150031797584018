import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngxs/store';
import NewsState from 'app/stores/state/news/news.state';
import { Observable } from 'rxjs';
import { Newscategory, News } from 'app/api/models';
import { NewsService } from 'app/api/services';
import { SetNewsCategories } from 'app/stores/actions/news/news.actions';
import { TranslateService } from '@ngx-translate/core';
import { NewsStateModel } from 'app/stores/models/news/news-state.model';
import { BaseComponent } from 'app/base.component';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
})
export default class NewsComponent extends BaseComponent implements OnInit {
    newsState$: Observable<NewsStateModel>;

    categories: Newscategory[];

    currentLang;

    selectedNews: News = null;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private translate: TranslateService,
        private store: Store,
        private newsService: NewsService,
    ) {
        super();
    }

    /** **************** */
    /* LIFECYCLE HOOKS */
    /** **************** */
    ngOnInit() {
        this.currentLang = this.translate.currentLang.toLowerCase();

        this.newsState$ = this.store.select(NewsState);
        // Loading News State
        this.subs.sink = this.newsState$.subscribe((state) => {
            if (state.newsCategories.length <= 1) {
                this.newsService.getV1Newscategory().subscribe((result) => {
                    // Update state
                    this.store.dispatch(new SetNewsCategories(result.newscategories));
                    // Update component value
                    this.categories = this.store.snapshot().news.newsCategories;
                });
            } else {
                this.categories = this.store.snapshot().news.newsCategories;
            }
        });
    }

    /** ******* */
    /* GETTER */
    /** ******* */

    get sortCategories() {
        if (this.categories !== undefined) {
            const sortCategories = [...this.categories];

            sortCategories.push(sortCategories.shift());

            return sortCategories;
        }
        return null;
    }

    onActivate(elemRef) {
        if (elemRef.newsset !== undefined) {
            elemRef.newsset.subscribe((evt) => {
                this.selectedNews = evt;
                this.changeDetectorRef.detectChanges();
            });
        }
    }
}
