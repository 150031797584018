import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { User } from '../../api/models/user';
import { SetGdprRedirect } from '../../stores/actions/settings/settings.actions';

/**
 * Check user gdpr valid for this route
 *
 * @export
 * @class GdprGuardService
 * @implements {CanActivate}
 */
@Injectable()
export default class GdprGuardService implements CanActivate {
    /**
   * Creates an instance of GdprGuardService.
   * @param {Router} router
   * @param {Store} store
   * @memberof GdprGuardService
   */
    constructor(
        private router: Router,
        private store: Store,
        private logger: NGXLogger,
    ) {}

    /**
   * Check user gdpr valid for this route
   *
   * @returns {boolean}
   * @memberof GdprGuardService
   */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        this.logger.info('Check user gdpr valid for this route');
        const userProfil: User = this.store.snapshot().user;

        this.logger.debug(route);

        if (state.url === '/new-project') {
            userProfil.is_corpus_admin = true;
        }

        if (!userProfil.gdpr.isValid && userProfil.is_corpus_admin) {
            this.logger.info('User as no valid gdpr');
            this.store.dispatch(new SetGdprRedirect(state.url));
            this.logger.info('GDPR save redirect url', state.url);
            this.logger.info('redirect to GDPR page');
            this.router.navigate(['gdpr']);
            return false;
        }
        this.logger.info('User as valid gdpr');
        return true;
    }
}
