// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
:host .content-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 13px;
  width: 100%;
}
:host .chapter, :host .conversation-item {
  position: relative;
}
:host .chapter.disable, :host .conversation-item.disable {
  opacity: 0.3;
}
:host .chapter.disable::before, :host .conversation-item.disable::before {
  content: " ";
  position: absolute;
  inset: 0;
  z-index: 1;
}
:host .chapter-title {
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}
:host .chapter-title .chapter-hr {
  margin: 0;
  height: 1px;
  border-color: #ccc;
  flex-grow: 1;
}
:host .edited-tag {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 0;
}
:host .edited-tag .btn .vcrm-form-cross::before {
  font-size: 16px;
}
:host .selected-tag.active {
  z-index: 0;
}
:host .speaker-name {
  font-weight: bold;
}
:host .speaker-name .speaker-color {
  height: 12px;
  width: 12px;
  margin-right: 10px;
  display: inline-block;
}
:host .conversation-item.left.conversation-sentiment.conversation-sentiment-neg .conversation-content {
  border-left-color: #CD3C14 !important;
}
:host .conversation-item.left.conversation-sentiment.conversation-sentiment-mit .conversation-content {
  border-left-color: #FFCC00 !important;
}
:host .conversation-item.left.conversation-sentiment.conversation-sentiment-neu .conversation-content {
  border-left-color: #999 !important;
}
:host .conversation-item.left.conversation-sentiment.conversation-sentiment-pos .conversation-content {
  border-left-color: #32C832 !important;
}
:host .conversation-item.left.conversation-sentiment .conversation-content {
  border-left-width: 10px;
}
:host .conversation-item.left.conversation-sentiment .conversation-content .conversation-color {
  left: -17px;
}
:host .conversation-item.left .check-for-tag {
  margin-right: 5px;
}
:host .conversation-item.left .conversation-content {
  margin-left: 7px;
  border-radius: 0 5px 5px 0;
  border-right-color: #ccc !important;
}
:host .conversation-item.left .conversation-content .conversation-color {
  left: -7px;
  border-left: 7px solid transparent;
}
:host .conversation-item.left .conversation-timestamp {
  margin-left: 8px;
}
:host .conversation-item.left.selector .conversation-timestamp {
  margin-left: 29px;
}
:host .conversation-item.right {
  margin-left: 50px;
}
:host .conversation-item.right.conversation-sentiment.conversation-sentiment-neg .conversation-content {
  border-right-color: #CD3C14 !important;
}
:host .conversation-item.right.conversation-sentiment.conversation-sentiment-mit .conversation-content {
  border-right-color: #FFCC00 !important;
}
:host .conversation-item.right.conversation-sentiment.conversation-sentiment-neu .conversation-content {
  border-right-color: #999 !important;
}
:host .conversation-item.right.conversation-sentiment.conversation-sentiment-pos .conversation-content {
  border-right-color: #32C832 !important;
}
:host .conversation-item.right.conversation-sentiment .conversation-content {
  border-right-width: 10px;
}
:host .conversation-item.right.conversation-sentiment .conversation-content .conversation-color {
  right: -17px;
}
:host .conversation-item.right .conversation-timestamp {
  text-align: right;
  margin-right: 11px;
}
:host .conversation-item.right .check-for-tag {
  order: 2;
  margin-left: 5px;
}
:host .conversation-item.right .conversation-content {
  margin-right: 7px;
  border-radius: 5px 0 0 5px;
  border-left-color: #ccc !important;
}
:host .conversation-item.right .conversation-content .conversation-color {
  right: -7px;
  border-right: 7px solid transparent;
}
:host .conversation-item.right.selector .conversation-timestamp {
  margin-right: 29px;
}
:host .conversation-item.conversation-sentiment.conversation-sentiment-neg .conversation-content .conversation-color {
  border-top-color: #CD3C14 !important;
}
:host .conversation-item.conversation-sentiment.conversation-sentiment-mit .conversation-content .conversation-color {
  border-top-color: #FFCC00 !important;
}
:host .conversation-item.conversation-sentiment.conversation-sentiment-neu .conversation-content .conversation-color {
  border-top-color: #999 !important;
}
:host .conversation-item.conversation-sentiment.conversation-sentiment-pos .conversation-content .conversation-color {
  border-top-color: #32C832 !important;
}
:host .conversation-item .conversation-content {
  padding: 10px;
  border: 1px solid;
  border-top-color: #ccc !important;
  border-bottom-color: #ccc !important;
  position: relative;
}
:host .conversation-item .conversation-content .conversation-color {
  position: absolute;
  top: -1px;
  height: 0;
  width: 0;
  border-top: 8px solid;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
