import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import { ToGraphTypeParams } from 'app/api/models/tographtypeparams';
import { Choice } from 'app/api/models/choice';

@Component({
    selector: 'chronological-chart-settings',
    templateUrl: './chronological-chart-settings-popup.component.html',
    styleUrls: ['./chronological-chart-settings-popup.component.scss'],
})
export default class AssociatedDataChronologicalChartSettingsPopupComponent extends BaseComponent implements OnInit {
    forAssociatedData: boolean;

    @Input()
        initParams: ToGraphTypeParams;

    @Input()
        graphDefaultValues;

    @Output() newUserChoicesObject: EventEmitter<ToGraphTypeParams> = new EventEmitter();

    graphService: GraphService;

    dashboardService: DashboardService;

    currentVerbatimColumns;

    graphParams: ToGraphTypeParams = {};

    dbName: string;

    private actualMaxValues;

    constructor(public modalRef: NgbActiveModal) {
        super();
    }

    ngOnInit(): void {
        this.graphParams = _.cloneDeep(this.initParams);

        this.subs.sink = this.currentVerbatimColumns.subscribe((columns) => {
            this.actualMaxValues = columns[this.dbName].values.length;
        });
    }

    get graphMaxValues() {
        return this.actualMaxValues < this.graphDefaultValues ? this.actualMaxValues : this.graphDefaultValues;
    }

    isEnabled(period: string): boolean {
        if (period === 'day') {
            return this.graphService.isDayAllowedForGraph(this.dashboardService);
        }
        if (period === 'week') {
            return this.graphService.isWeekAllowedForGraph(this.dashboardService);
        }
        return true;
    }

    get hasChanged(): boolean {
        const changes = diff(this.graphParams, this.initParams);
        return !_.isEmpty(changes);
    }

    setNbValues(value: number) {
        this.graphParams.nb_values_chart = value;
    }

    logAnalytics() {
        gtmClick({
            track_category: 'données associées',
            track_name: 'vue temporelle nbre valeurs affichées',
            track_cible: `${this.graphParams.nb_values_chart}`,
        });
    }

    close() {
        this.modalRef.close(false);
    }

    validate() {
        this.newUserChoicesObject.emit(this.graphParams);
        this.modalRef.close();
    }
}
