import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Inputnamevalues2levels } from 'app/api/models';
import { CorpusService as CorpusApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import CorpusClassModel from 'app/utils/models/corpus-class.model';
import CorpusService from 'app/utils/services/corpus.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import accentless from '../../../../../../../../utils/functions/accentless';

@Component({
    selector: 'filters-classifications-auto-popup-simple',
    templateUrl: './filters-classifications-auto-popup-simple.component.html',
    styleUrls: ['./filters-classifications-auto-popup-simple.component.scss'],
})
export default class FiltersClassificationsAutoPopupSimpleComponent extends BaseComponent implements OnInit {
    @Output()
    private onSave: EventEmitter<boolean> = new EventEmitter();

    private dashboardService: DashboardService;

    modelNameReference: string = '';

    confirmButtonDisabled: boolean = true;

    private suppressButtonDisabled: boolean = true;

    public cats: Array<CorpusClassModel> = [];

    catsInitialState: Array<CorpusClassModel> = [];

    public filtersList: Array<Inputnamevalues2levels> = [];

    public showClassCharts: boolean = true;

    public horsClassSelected: boolean = false;

    public horsClassSelectedInitialValue: boolean;

    public horsClassCount: number = 0;

    displayColumns: Array<CorpusClassModel> = [];

    public allSubSelected: boolean = false;

    public numCheckable: number = 0;

    public numChecked: number = 0;

    constructor(
        private currentModal: NgbActiveModal,
        private corpusService: CorpusService,
        private corpusApi: CorpusApi,
    ) {
        super();
    }

    get modelName() {
        const { models } = this.corpusService.currentCorpus.getValue();
        return models[this.verbatimDBName].model;
    }

    get verbatimDBName() {
        return this.dashboardService.currentFilters.value.verbatim_dbname;
    }

    ngOnInit() {
        this.confirmButtonDisabled = true;

        this.subs.sink = this.dashboardService.currentFilters.subscribe((filters) => {
            if (filters === null) {
                return;
            }

            this.corpusApi
                .getV1CorpusCorpIdClassData(this.corpusService.currentCorpus.getValue().corp_id, this.verbatimDBName)
                .subscribe((data) => {
                    const selected = filters.filters_class;

                    const cats = JSON.parse(JSON.stringify(data.class_count)) as Array<CorpusClassModel>;
                    this.numCheckable = 0;
                    this.numChecked = 0;
                    cats.forEach((cat) => {
                        if (cat.inputName !== '__hors_classe__') {
                            this.numCheckable += 1;
                        }
                        selected.forEach((s) => {
                            if (s.inputName === '__hors_classe__') {
                                this.horsClassSelected = true;
                                return;
                            }
                            if (s.inputName === cat.inputName) {
                                cat.checked = true;
                                this.numChecked += 1;
                            }
                        });
                        if (_.isEmpty(cat.values)) {
                            cat.values = [{ key: cat.inputName, doc_count: 0 }];
                        }
                        if (cat.inputName === '__hors_classe__') {
                            this.horsClassCount = cat.values[0].response;
                        }
                    });
                    this.allSubSelected = (this.numChecked === this.numCheckable);

                    this.cats = cats;
                    this.displayColumns = this.cats;
                    this.setUndefinedCheckBoxStateToFalse(this.cats);
                    this.catsInitialState = JSON.parse(JSON.stringify(cats));
                    this.suppressActiveOrNot();
                    this.horsClassSelectedInitialValue = this.horsClassSelected;
                });
        });
    }

    selectSelf(e, input) {
        if (this.allSubSelected) {
            this.allSubSelected = false;
        }
        this.cats.forEach((cat) => {
            if (cat.inputName === input) {
                cat.checked = e.currentTarget.checked;
            }
        });
        this.updateConfirmButtonState();
        this.updateList();
    }

    selectClass() {
        this.allSubSelected = !this.allSubSelected;
        if (this.allSubSelected) {
            this.cats.forEach((cat) => {
                if (cat.inputName !== '__hors_classe__') {
                    cat.checked = true;
                }
            });
        } else {
            this.cats.forEach((cat) => {
                if (cat.inputName !== '__hors_classe__') {
                    cat.checked = false;
                }
            });
        }
        this.updateList();
    }

    selectHorsClass() {
        this.horsClassSelected = !this.horsClassSelected;
        this.updateList();
        this.updateConfirmButtonState();
    }

    private updateConfirmButtonState() {
        this.confirmButtonDisabled = _.isEmpty(diff(this.cats, this.catsInitialState))
                                 && this.horsClassSelected === this.horsClassSelectedInitialValue;
    }

    private updateList() {
        this.filtersList = [];
        this.numChecked = 0;
        this.cats.forEach((cat) => {
            if (cat.checked) {
                this.numChecked += 1;
                this.filtersList.push({
                    inputName: cat.inputName,
                });
            }
        });
        this.allSubSelected = (this.numChecked === this.numCheckable);
        if (this.horsClassSelected) {
            this.filtersList.push({
                inputName: '__hors_classe__',
            });
        }
        this.suppressActiveOrNot();
        this.updateConfirmButtonState();
    }

    suppressActiveOrNot() {
        this.suppressButtonDisabled = true;
        this.cats.forEach((cat) => {
            if (cat.checked) {
                this.suppressButtonDisabled = false;
            }
        });
        if (this.horsClassSelected) {
            this.suppressButtonDisabled = false;
        }
    }

    /**
     * Methode appelé lorsque l'on utilise le champ de recherche d'une classification.
     * Permet d'afficher uniquement les classes qui contiennent la recherche demandée
     */
    onFreeSearch(search) {
        this.displayColumns = [];
        const findR = new RegExp(accentless(search), 'gimu');
        this.cats.forEach((col) => {
            if (col.displayName && findR.test(accentless(col.displayName))) {
                this.displayColumns.push(col);
            }
        });
    }

    suppressAll() {
        this.cats.forEach((cat) => {
            cat.checked = false;
        });
        this.horsClassSelected = false;
        this.allSubSelected = false;
        this.updateList();
    }

    close() {
        this.currentModal.close();
    }

    save() {
        this.updateList();
        this.dashboardService.updateFilterClasses(this.filtersList);
        this.onSave.emit(true);
        this.close();
    }

    // eslint-disable-next-line class-methods-use-this
    setUndefinedCheckBoxStateToFalse(corpusClassModels: Array<CorpusClassModel>) {
        corpusClassModels.forEach((corpusClassModel) => {
            if (typeof corpusClassModel.checked === 'undefined') {
                corpusClassModel.checked = false;
            }
        });
    }
}
