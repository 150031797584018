import { Directive, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

/*
  Used to factorize default behaviours between components.
*/
@Directive()
// eslint-disable-next-line import/prefer-default-export
export abstract class BaseComponent implements OnDestroy {
    /**
     * Used to clear subscriptions.
     * Concurrent to the old way with takeUntil.
     * I prefer this approach cause it works in all cases et is less error prone.
     */
    protected subs = new SubSink();

    /**
     * Classic angular callback called when the component is destroyed.
     * Clean all subscriptions.
     */
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
