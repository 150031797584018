/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HeatmapParams, Method } from 'app/api/models/graphparams';
import { PossibleField, ResponseHeatmap } from 'app/api/models/responseheatmap';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { map } from 'rxjs/internal/operators/map';
import * as _ from 'lodash';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { DashboardConfig } from 'app/api/models/dashboard-config';
import { diff } from 'deep-object-diff';
import { HeatmapDetailsConfiguration } from 'app/api/models/heatmap';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import DashboardTabs from 'app/utils/models/dashboard-tabs';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import HeatmapSettingsPopupComponent from './components/heatmap-settings-popup/heatmap-settings-popup.component';
import HeatmapDetailsPopupComponent from './components/heatmap-details-popup/heatmap-details-popup.component';

enum Fields {
    class = 'class', thematic = 'thematic', ton = 'ton',
}

@Component({
    selector: 'app-heatmap',
    templateUrl: './heatmap.component.html',
    styleUrls: ['./heatmap.component.scss'],
})
// eslint-disable-next-line import/prefer-default-export
export default class HeatmapComponent extends BaseComponent implements OnInit {
    @ViewChild('chart')
        chart: BaseChartDirective;

    private params: HeatmapParams;

    private possibleFields: Array<PossibleField>;

    private minCount: number = 0;

    private maxCount: number = 0;

    type = 'matrix';

    colors = {
        occur: ['#f9e8e3', '#f9d6cb', '#f7c3b3', '#f4b19c', '#f09e85', '#eb8c6e', '#e57958', '#de6642', '#d6522c', '#cd3c14'],
        khi2: ['#527edb', '#7d9ee4', '#a9bfed', '#d4dff6', '#fff', '#f3cfc5', '#e69e8a', '#da6d4f', '#cd3c14'],
        none: '#eee',
    };

    options;

    // pour stocker
    heatmapDetailsConfiguration: HeatmapDetailsConfiguration = null;

    private currentConfig: DashboardConfig = null;

    get hasData() {
        return this.dashboardService.dashboardData.getValue()?.hits.total !== 0;
    }

    constructor(
        private dashboardService: DashboardService,
        private dashboardApi: DashboardApi,
        private modalService: NgbModal,
        private graphService: GraphService,
        private translateService: TranslateService,
        protected manageDashboardService: ManageDashboardService,
        private elementRef:ElementRef,
    ) {
        super();
        this.options = this.getOptions();
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.currentConfig.subscribe((config) => {
            if (config !== null) {
                this.params = _.cloneDeep(config.heatmap);
                if (this.heatmapDetailsConfiguration !== null) {
                    if (this.currentConfig.verbatim.cloud_max_size !== config.verbatim.cloud_max_size) {
                        this.heatmapDetailsConfiguration.selectedSize = config.verbatim.cloud_max_size;
                    }

                    if (this.currentConfig.verbatim.extended_table !== config.verbatim.extended_table) {
                        this.heatmapDetailsConfiguration.isZoomIn = config.verbatim.extended_table;
                    }

                    if (this.currentConfig.verbatim.word_tab !== config.verbatim.word_tab) {
                        this.heatmapDetailsConfiguration.selectedTab = config.verbatim.word_tab;
                    }

                    if (this.currentConfig.verbatim.cloud_category !== config.verbatim.cloud_category) {
                        this.heatmapDetailsConfiguration.category = config.verbatim.cloud_category;
                    }

                    if (this.currentConfig.verbatim.cloud_sub_category !== config.verbatim.cloud_sub_category) {
                        this.heatmapDetailsConfiguration.subCategory = config.verbatim.cloud_sub_category;
                    }

                    if (this.currentConfig.verbatim.pert_is_checked !== config.verbatim.pert_is_checked) {
                        this.heatmapDetailsConfiguration.pertinenceIsChecked = config.verbatim.pert_is_checked;
                    }

                    if (!_.isEmpty(diff(this.currentConfig.verbatim.selected_columns, config.verbatim.selected_columns))) {
                        this.heatmapDetailsConfiguration.selectedColumns = _.cloneDeep(config.verbatim.selected_columns);
                    }

                    this.currentConfig = _.cloneDeep(config);
                }
            }
        });

        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard) {
                this.currentConfig = _.cloneDeep(dashboard.dash_json_config);

                this.heatmapDetailsConfiguration = {
                    selectedSize: this.currentConfig.verbatim.cloud_max_size,
                    isZoomIn: this.currentConfig.verbatim.extended_table,
                    selectedTab: this.currentConfig.verbatim.word_tab,
                    category: this.currentConfig.verbatim.cloud_category,
                    subCategory: this.currentConfig.verbatim.cloud_sub_category,
                    pertinenceIsChecked: this.currentConfig.verbatim.pert_is_checked,
                    selectedColumns: _.cloneDeep(this.currentConfig.verbatim.selected_columns),
                };
            }
        });

        this.subs.sink = this.manageDashboardService.lazyLoadRequestEvent.subscribe(async (elementId) => {
            if (elementId === DashboardTabs.HEATMAP && this.elementRef.nativeElement.classList.contains('is-loading')) {
                await this.loadHeatmapData();
                this.elementRef.nativeElement.classList.remove('is-loading');
            }
        });
    }

    private isAllowedField(fieldName: string) {
        return this.getField(fieldName) !== undefined;
    }

    private getField(fieldName: string): PossibleField {
        return this.possibleFields.find((field) => field.key === fieldName);
    }

    get yDisplayName() {
        return this.possibleFields && this.getField(this.params.fieldy) ? this.getField(this.params.fieldy).displayName : '';
    }

    get xDisplayName() {
        return this.possibleFields && this.getField(this.params.fieldx) ? this.getField(this.params.fieldx).displayName : '';
    }

    get oneSelectedFieldMissing() {
        return !this.noPossibleFields && (this.xDisplayName === '' || this.yDisplayName === '');
    }

    get noPossibleFields() {
        return !this.possibleFields || this.possibleFields.length === 0;
    }

    get showHeatMap(): boolean {
        return !this.noPossibleFields && !this.oneSelectedFieldMissing;
    }

    async loadHeatmapData(reloadFields: boolean = true) {
        if (reloadFields) {
            this.possibleFields = await this.getPossibleFields();
        }

        const dashboardId = this.dashboardService.currentDashboard.getValue().dash_id;
        const searchparams = this.dashboardService.lastAppliedFilters.getValue();
        // Avant de demander la heatmap, il faut vérifier que les champs que l'on va demander son autorisés
        if (!this.isAllowedField(this.params.fieldx) || !this.isAllowedField(this.params.fieldy)) {
            // valeur par défaut 1: Tonalités x Classes
            this.params.fieldx = Fields.class;
            this.params.fieldy = Fields.ton;
        }

        if (!this.isAllowedField(this.params.fieldx) || !this.isAllowedField(this.params.fieldy)) {
            // valeur par défaut 2: Tonalités x Thématiques
            this.params.fieldx = Fields.thematic;
            this.params.fieldy = Fields.ton;
        }

        if (this.isAllowedField(this.params.fieldx) && this.isAllowedField(this.params.fieldy)) {
            const response = await firstValueFrom(this.dashboardApi.getHeatmap(dashboardId, {
                searchparams,
                graphparams: this.getGraphParams(),
            }));
            this.setData(response);
        } else {
            this.params.fieldx = '';
            this.params.fieldy = '';
        }
    }

    getGraphParams() {
        const graphParams = _.cloneDeep(this.params);
        if (!graphParams.top_activated) {
            graphParams.nb_values_chart = 50;
        }
        delete graphParams.top_activated;
        return graphParams;
    }

    setData(response: ResponseHeatmap) {
        const data = [];
        this.minCount = 0;
        this.maxCount = 0;
        response.values.forEach((values, x) => {
            values.forEach((value, y) => {
                const count = value.count === null ? null : value.count * 1;

                if (count !== null && count < this.minCount) {
                    this.minCount = count;
                }

                if (count !== null && count > this.maxCount) {
                    this.maxCount = count;
                }

                data.push({
                    x: response.legendX.values[x],
                    y: response.legendY.values[y],
                    xDisplayName: response.legendX.displayName,
                    xInputName: response.legendX.inputName,
                    yDisplayName: response.legendY.displayName,
                    yInputName: response.legendY.inputName,
                    xValueInputName: response.legendX.valuesinputname[x],
                    yValueInputName: response.legendY.valuesinputname[y],
                    decile: value.decile * 1,
                    count: value.count * 1,
                });
            });
        });

        const backgroundColor = this.getBackgroundColor.bind(this);

        this.datasets = [{
            data,
            backgroundColor,
            hoverBackgroundColor: backgroundColor,
            borderColor: '#cccccc',
            hoverBorderColor: '#000000',
            hoverBorderWidth: 2,
            borderWidth: 1,
            width: ({ chart }) => (chart.chartArea || {}).width / response.legendX.values.length - 1,
            height: ({ chart }) => (chart.chartArea || {}).height / response.legendY.values.length - 1,
        }];

        this.options = this.getOptions(response);
    }

    getMethodColors() {
        return this.colors[this.params.method];
    }

    getBackgroundColor(context) {
        if (!context.dataset.data[context.dataIndex] || context.dataset.data[context.dataIndex].decile === null) {
            return this.colors.none;
        }

        if (this.params.method === Method.occur && context.dataset.data[context.dataIndex].decile === 0) {
            return this.colors.none;
        }

        return this.getMethodColors()[context.dataset.data[context.dataIndex].decile - 1];
    }

    openSettings() {
        const modal = this.modalService.open(HeatmapSettingsPopupComponent, { modalDialogClass: 'heatmap-modal' });
        modal.componentInstance.initialParams = this.params;
        modal.componentInstance.possibleFields = this.possibleFields;
        modal.componentInstance.save.subscribe((params: HeatmapParams) => {
            this.dashboardService.setHeatmapConfig(params);
            this.loadHeatmapData(false);
        });
    }

    openDetailsPopup(object) {
        if (object.active.length > 0) {
            const item = this.datasets[0].data[object.active[0].index];

            // dans une heatmap de cooccurence, une valeur à 0 signifie qu'il n'y a aucun résultat, donc on n'affiche pas de détails
            if (item.count === 0 && this.params.method === Method.occur) {
                return;
            }

            gtmClick({
                track_category: 'heatmap',
                track_name: `heatmap popup détail ${this.params.method === Method.occur ? 'cooccurrence' : 'corrélation'}`,
            });

            const modal = this.modalService.open(HeatmapDetailsPopupComponent, { size: 'full-width' });
            modal.componentInstance.item = item;
            modal.componentInstance.legendX = this.xDisplayName;
            modal.componentInstance.legendY = this.yDisplayName;
            modal.componentInstance.dashboardService = this.dashboardService;
            modal.componentInstance.translate = this.translateService;
            modal.componentInstance.method = this.params.method;
            modal.componentInstance.heatmapDetailsConfiguration = this.heatmapDetailsConfiguration;
        }
    }

    async getPossibleFields(): Promise<Array<PossibleField>> {
        const dashboardId = this.dashboardService.currentDashboard.getValue().dash_id;
        const searchParams = this.dashboardService.lastAppliedFilters.getValue();
        return this.dashboardApi.getHeatmapFields(dashboardId, {
            searchparams: searchParams,
            graphparams: this.params,
        }).pipe(map((response) => response.possiblefields.map((field) => (field.key.startsWith(Fields.class) ? { key: Fields.class, displayName: field.displayName } : field)))).toPromise();
    }

    datasets = [];

    getOptions(response: ResponseHeatmap = null) {
        const thisInternal = this;
        return {
            responsive: true,
            spanGaps: false,
            animation: false,
            onHover(event, items, chart) {
                // dans une heatmap de cooccurence, une valeur à 0 signifie qu'il n'y a aucun résultat, donc on n'affiche pas de détails
                if (items.length === 0 || (thisInternal.datasets[0].data[items[0].index].count === 0 && thisInternal.params.method === Method.occur)) {
                    chart.canvas.style.cursor = ('default');
                } else {
                    chart.canvas.style.cursor = ('pointer');
                }
            },
            plugins: {
                legend: { display: false },
                tooltip: {
                    callbacks: {
                        title() {
                            return '';
                        },
                        label(context) {
                            const data = context.dataset.data[context.dataIndex];

                            const lines = [];

                            if (thisInternal.params.method === Method.occur && data.decile === 0) {
                                lines.push(`${thisInternal.translateService.instant('translations.analysisDashboard.heatmap.legend.occur_none')}`);
                            } else if (thisInternal.params.method !== Method.occur && data.decile === 5) {
                                lines.push(`${thisInternal.translateService.instant('translations.analysisDashboard.heatmap.legend.khi2_equal')} = ${data.count}`);
                            } else {
                                lines.push(`${thisInternal.translateService.instant(`translations.analysisDashboard.heatmap.${thisInternal.params.method}`)} = ${data.count}`);
                            }

                            lines.push(`${thisInternal.xDisplayName}: ${thisInternal.graphService.labelEllipsisCallback(data.x, 40)}`);
                            lines.push(`${thisInternal.yDisplayName}: ${thisInternal.graphService.labelEllipsisCallback(data.y, 40)}`);

                            if (data.count !== 0 || thisInternal.params.method !== Method.occur) {
                                lines.push(thisInternal.translateService.instant('translations.analysisDashboard.heatmap.details.click'));
                            }

                            return lines;
                        },
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: thisInternal.graphService.labelEllipsisCallback(thisInternal.xDisplayName, 80),
                    },
                    type: 'category',
                    labels: response === null || !response.legendX ? [] : response.legendX.values,
                    ticks: {
                        color: '#000',
                        display: true,
                        callback(val) {
                            if (response === null || !response.legendX || !response.legendX.values || response.legendX.values.length === 0) {
                                return '';
                            }
                            return thisInternal.graphService.labelEllipsisCallback(this.getLabelForValue(val));
                        },
                    },
                    grid: {
                        display: false,
                    },
                    max: 500,
                },
                y: {
                    title: {
                        display: true,
                        text: thisInternal.graphService.labelEllipsisCallback(thisInternal.yDisplayName, 80),
                    },
                    type: 'category',
                    labels: response === null || !response.legendY ? [] : response.legendY.values,
                    offset: true,
                    ticks: {
                        color: '#000',
                        display: true,
                        callback(val) {
                            return thisInternal.graphService.labelEllipsisCallback(this.getLabelForValue(val));
                        },
                    },
                    grid: {
                        display: false,
                    },
                },
            },
        };
    }
}
