import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';

/**
 * Check if user is logged and if it's an admin user
 *
 * @export
 * @class AuthAdminService
 * @implements {CanActivate}
 */
@Injectable()
export default class AuthAdminService implements CanActivate {
    /**
   *Creates an instance of AuthAdminService.
   * @param {Router} router
   * @param {Store} store
   * @memberof AuthAdminService
   */
    constructor(private router: Router, private store: Store) {}

    /**
   * Check if user is admin
   *
   * @returns {boolean}
   * @memberof AuthAdminService
   */
    canActivate(): boolean {
        if (!this.store.snapshot().user.is_corpus_admin) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
