import {
    Component, Input, OnInit, Output,
    EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Cgu } from 'app/api/models/cgu';
import { Router } from '@angular/router';

@Component({
    selector: 'app-term-of-use',
    templateUrl: './term-of-use.component.html',
    styleUrls: ['./term-of-use.component.scss'],
})
export default class TermOfUseComponent implements OnInit {
    private lang: string;

    @Input()
    public cgu: Cgu;

    @Output()
        accepted: EventEmitter<Cgu> = new EventEmitter();

    private cguChecked: boolean = false;

    constructor(
        public modalRef: NgbActiveModal,
        public translate: TranslateService,
        private store: Store,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.lang = this.translate.currentLang ? this.translate.currentLang.toLowerCase() : 'fr';
    }

    close() {
        this.modalRef.close();
    }

    accept() {
        this.cgu.isValid = this.cguChecked;

        this.accepted.emit(this.cgu);
    }

    refuse() {
        this.modalRef.close();
        this.router.navigate(['/auth/logout', { referer: this.router.url }]);
    }
}
