import {
    AfterViewInit, Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { CorpusService } from '../../../../api/services';

@Component({
    selector: 'app-corpus-settings-history',
    templateUrl: './corpus-settings-history.component.html',
    styleUrls: ['./corpus-settings-history.component.scss'],
})
export default class CorpusSettingsHistoryComponent implements OnInit, AfterViewInit {
    public history: any = [];

    public columns: any = [];

    @ViewChild('levelTpl')
        levelTpl: TemplateRef<any>;

    public config = {
        style: {
            height: 'calc(100vh - 375px)',
        },
        pagination: true,
        itemPerPage: 10,
    };

    constructor(
        private corpus: CorpusService,
        private store: Store,
    ) {}

    ngAfterViewInit(): void {
        this.columns = [
            {
                prop: 'level',
                title: 'Level',
                cellTemplate: this.levelTpl,
                style: {
                    width: '120px',
                },
            },
            {
                prop: 'type',
                title: 'Type',
                style: {
                    width: '70px',
                },
            },
            {
                prop: 'action',
                title: 'Action',
                style: {
                    width: '180px',
                },
            },
            {
                prop: 'text',
                title: 'Text',
                wordWrap: true,
            },
            {
                prop: 'date',
                title: 'Date',
                style: {
                    width: '200px',
                },
            },
            {
                prop: 'username',
                title: 'User',
                style: {
                    width: '100px',
                },
            },
        ];
    }

    ngOnInit() {
        this.getHistory();
    }

    getHistory() {
        this.corpus
            .getV1CorpusCorpIdHistory(this.store.snapshot().settings.currentCorpus)
            .subscribe((history) => {
                this.history = history;
            });
    }
}
