import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HistoParams } from 'app/api/models/histoparams';
import * as _ from 'lodash';
import { diff } from 'deep-object-diff';

@Component({
    selector: 'app-satisfaction-bar-chart-settings-popup',
    templateUrl: './satisfaction-bar-chart-settings-popup.component.html',
})
export default class SatisfactionBarChartSettingsPopupComponent implements OnInit {
    constructor(
        public modal: NgbActiveModal,
    ) { }

    type: string;

    params: HistoParams;

    initialParams: HistoParams;

    @Output()
    private save: EventEmitter<HistoParams> = new EventEmitter();

    ngOnInit(): void {
        this.initialParams = _.cloneDeep(this.params);
        this.params = _.cloneDeep(this.initialParams);
    }

    validate() {
        this.save.emit(this.params);
        this.modal.close();
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }
}
