import {
    Component, Input, OnChanges, SimpleChanges, Output, EventEmitter,
} from '@angular/core';
import * as _ from 'lodash';
import analytics from '../words.analytics';

@Component({
    selector: 'app-word-groups-select',
    templateUrl: './word-groups-select.component.html',
    styleUrls: ['./word-groups-select.component.scss'],
})
export default class WordGroupsSelectComponent implements OnChanges {
    @Input()
        data: any;

    @Input()
        view :string;

    @Input()
        disabled: boolean = false;

    @Input()
        overrideGtmTrackName = null;

    @Input()
        overrideGtmTrackCategory = null;

    categoriesInternal = ['top_keywords_ec', 'top_keywords_mono', 'top_keywords_multi', 'top_ote', 'top_keywords_hashtag', 'top_keywords_tweet'];

    dataTypes = ['_noun', '_adj', '_verb', '_num'];

    wordingsAnalytics :any = analytics;

    @Input()
        category: string = this.categoriesInternal[0];

    @Input()
        subCategory: string = this.categoriesInternal[0];

    @Output()
        categoryChange: EventEmitter<{ category:string, subCategory:string, userInteraction:boolean }> = new EventEmitter();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.data = changes.data.currentValue;
            if (this.subCategory) {
                this.updateCategories();
            }
        }
    }

    select(subCategory:string) {
        const category = this.categoriesInternal.find((c) => subCategory.startsWith(c));
        // on notifie le composant parent du changement
        this.categoryChange.emit({ category, subCategory, userInteraction: true });
    }

    get categories() {
        return this.categoriesInternal.filter((category) => !_.isEmpty(this.data[category]));
    }

    getTrackCategory() {
        return this.overrideGtmTrackCategory !== null ? this.overrideGtmTrackCategory : this.wordingsAnalytics.track_category;
    }

    getTrackName(c) {
        return this.overrideGtmTrackName !== null ? this.overrideGtmTrackName : this.wordingsAnalytics.group[this.view][c].track_name;
    }

    getSubCategories(category:string) : string[] {
        const subCategories : string[] = [];
        this.dataTypes.forEach((subCategoryType) => {
            const subCategory = category + subCategoryType;
            if (!_.isEmpty(this.data[subCategory])) {
                subCategories.push(subCategory);
            }
        });

        return subCategories;
    }

    hasSubCategory(category:string) {
        return this.getSubCategories(category).length > 0;
    }

    /**
     * Met à jour le sélecteur catégorie lorsque les données sont mises à jour.
     */
    updateCategories() {
        let newSelection = { category: this.category, subCategory: this.subCategory };
        if (this.categories.length > 0) {
            // On récupère les sous-catégories par rapport à la catégorie précédemment sélectionnée
            const subCategories = this.getSubCategories(newSelection.category);
            // si il existe des sous-catégories et que la sous-catégorie précédemment sélectionnée n'existe plus
            if (subCategories.length > 0 && (!subCategories.includes(newSelection.subCategory) && this.category !== newSelection.subCategory)) {
                // On sélectionne la 1ère sous-catégorie de disponible
                const [firstSubCategory] = this.categories;
                newSelection.subCategory = firstSubCategory;
            }
            // Si la catégorie principale précédemment sélectionnée n'existe plus
            if (!this.categories.includes(newSelection.category)) {
                // On sélectionne la 1ere catégorie disponible
                const [firstCategory] = this.categories;
                newSelection = { category: firstCategory, subCategory: firstCategory };
            }
        }
        // Si on détecte une différence sur la categorie ou la sous-catégorie, on met à jour la sélection
        if (this.category !== newSelection.category || this.subCategory !== newSelection.subCategory) {
            // on notifie le composant parent du changement
            this.categoryChange.emit({ ...newSelection, userInteraction: false });
        }
    }
}
