import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import RouterTraceService from 'app/utils/services/router-trace.service';
import GdprComponent from './gdpr/gdpr.component';

const routes: Routes = [
    {
        path: 'gdpr',
        component: GdprComponent,
        canActivate: [RouterTraceService],
        data: {
            meta: {
                title: 'VisualCRM GDPR',
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [RouterTraceService],
})
export default class GdprRoutingModule {}
