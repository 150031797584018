import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import AuthGuardService from 'app/utils/services/authguard.service';
import RouterTraceService from 'app/utils/services/router-trace.service';
import GdprGuardService from 'app/utils/services/gdprguard.service';
import QuitDbAnalysisService from 'app/utils/services/quit-db-analysis.service';
import NewsComponent from './news.component';
import NewsListComponent from './news-list/news-list.component';
import NewsDetailsComponent from './news-details/news-details.component';

const NEWSROUTES: Routes = [
    {
        path: 'news',
        component: NewsComponent,
        canActivate: [
            RouterTraceService,
            AuthGuardService,
            GdprGuardService,
        ],
        children: [
            {
                path: '',
                component: NewsListComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM News',
                    },
                },
            },
            {
                path: 'categories/:id',
                component: NewsListComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM News',
                    },
                },
            },
            {
                path: 'categories',
                component: NewsListComponent,
                canActivate: [RouterTraceService],
                redirectTo: '',
                data: {
                    meta: {
                        title: 'VisualCRM News',
                    },
                },
            },
            {
                path: ':id',
                component: NewsDetailsComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM News',
                    },
                },
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(NEWSROUTES),
    ],
    providers: [
        AuthGuardService,
        RouterTraceService,
        GdprGuardService,
        QuitDbAnalysisService,
    ],
    exports: [RouterModule],
})
export default class NewsRoutingModule { }
