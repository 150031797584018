import {
    Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { OutputTypeGraph } from 'app/api/models/associateddata';
import { Yaxis } from 'app/api/models/histoparams';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import SettingsState from 'app/stores/state/settings/settings.state';
import { addDashboardNumberToAxis } from 'app/utils/chartjs-plugins/add-dashboard-number-to-axis';
import { addDashboardNumberToLineChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-line-chart';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import * as _ from 'lodash';
import AbstractLineChartComponent from '../../graphs-generic-components/abstract-line-chart-component';
import AssociatedDataChronologicalChartSettingsPopupComponent from '../chronological-chart-settings-popup/chronological-chart-settings-popup.component';
import ExtendedViewPopupComponent from '../extended-view-popup/extended-view-popup.component';

@Component({
    selector: 'associated-data-line-chart',
    templateUrl: './associated-data-line-chart.component.html',
})
export default class AssociatedDataLineChartComponent extends AbstractLineChartComponent implements OnInit {
    bsModalRef: NgbModalRef;

    private graphDefaultValues;

    private graphMaxValues;

    @Input()
        dashboardService: DashboardService;

    @Input()
        graphService: GraphService;

    @Input()
        manageDashboardService: ManageDashboardService;

    @Input()
        showToolbar = true;

    @Input()
        dbName;

    @ViewChild('chart')
        chart: BaseChartDirective;

    options;

    chartData;

    title;

    legendItems = [];

    selectedMergedItem;

    params;

    plugins = [addDashboardNumberToLineChart, addDashboardNumberToAxis];

    @Output()
    public configChanged = new EventEmitter<{ type: string, dbName: string, curve_params: OutputTypeGraph }>();

    @Input()
    set data(data) {
        if (data) {
            this.loadConfig();
            this.legendItems = [];

            this.title = data.displayName;

            this.distribution2Axis(data.distribution);
            this.options = this.getOptions();

            this.chartData = {
                labels: this.labels,
                datasets: this.getDatasets(data.distribution),
            };
        }
    }

    constructor(
        private modalService: NgbModal,
        private store: Store,
        private translate: TranslateService,
        chartTooltips: ChartTooltipsService,
    ) {
        super(chartTooltips, translate);
    }

    getGraphService() {
        return this.graphService;
    }

    getDashboardService() {
        return this.dashboardService;
    }

    ngOnInit(): void {
        this.subs.sink = this.store.select(SettingsState).subscribe((settings) => {
            this.graphDefaultValues = settings.vcrm['graph-values-default'];
            this.graphMaxValues = settings.vcrm['graph-values-max'];
        });
    }

    loadConfig() {
        this.params = _.cloneDeep(this.getUserChoices().find((choice) => choice.db_name === this.dbName).curve_params);
    }

    get maxNbValuesChart() {
        const actualMaxValues = this.manageDashboardService.getCurrentVerbatimColumns()?.getValue()[this.dbName].values.length;
        return actualMaxValues < this.graphMaxValues ? actualMaxValues : this.graphMaxValues;
    }

    openExtendedViewModal() {
        this.bsModalRef = this.modalService.open(ExtendedViewPopupComponent, { size: 'full-width' });

        this.bsModalRef.componentInstance.graphService = this.graphService;
        this.bsModalRef.componentInstance.manageDashboardService = this.manageDashboardService;
        this.bsModalRef.componentInstance.dashboardService = this.dashboardService;

        this.bsModalRef.componentInstance.dbName = this.dbName;
        this.bsModalRef.componentInstance.type = 'line';
        this.bsModalRef.componentInstance.params = this.params;
        this.bsModalRef.componentInstance.title = this.title;
        this.bsModalRef.componentInstance.isMerged = this.isMerged;
        this.bsModalRef.componentInstance.maxNbValuesChart = this.maxNbValuesChart;
        this.bsModalRef.componentInstance.partialTrackName = 'temporelle';
    }

    openSettings(dbName, forAssociatedData) {
        this.bsModalRef = this.modalService.open(AssociatedDataChronologicalChartSettingsPopupComponent, {});
        this.bsModalRef.componentInstance.initParams = _.cloneDeep(this.params);
        this.bsModalRef.componentInstance.forAssociatedData = forAssociatedData;
        this.bsModalRef.componentInstance.graphService = this.graphService;
        this.bsModalRef.componentInstance.dashboardService = this.dashboardService;
        this.bsModalRef.componentInstance.currentVerbatimColumns = this.manageDashboardService.getCurrentVerbatimColumns();
        this.bsModalRef.componentInstance.dbName = dbName;
        this.bsModalRef.componentInstance.graphDefaultValues = this.graphDefaultValues;

        this.bsModalRef.componentInstance.newUserChoicesObject.subscribe((receivedEntry) => {
            const userChoices = _.cloneDeep(this.getUserChoices());
            const index = userChoices.findIndex((element) => element.db_name === dbName);
            userChoices[index].curve_params = receivedEntry;

            if (this.isMerged) {
                this.manageDashboardService.setComparisonDashbardConfig('userChoices', userChoices);
            } else {
                this.dashboardService.setUserChoices(userChoices);
            }

            this.configChanged.emit({
                type: 'distri',
                dbName,
                curve_params: receivedEntry,
            });
        });
    }

    get isMerged() {
        return !this.dashboardService;
    }

    customTooltips() {
        const thisInt = this;
        // eslint-disable-next-line func-names
        return function (context) {
            return (thisInt.isMerged ? thisInt.customTooltipsMerged : thisInt.customTooltipsSideBySyde)(context, thisInt);
        };
    }

    getOptions() {
        const thisInternal = this;
        const { yaxis } = this.params;

        // struct to store graphicals elements
        const options = {
            animation: false,
            responsive: true,
            ratio: 2,
            maintainAspectRatio: thisInternal.showToolbar,
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: '',
            },
            scales: {
                y: {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        color: '#000',
                        callback: (value) => ((Math.round(value * 10) / 10) + (yaxis === Yaxis.percent ? '%' : '')),
                    },
                },
            },
            plugins: {
                addDashboardNumberToLineChart: thisInternal.isMerged,
                addDashboardNumberToAxis: thisInternal.isMerged,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.customTooltips(),
                },
            },
        };

        for (let dashboardNumber = 0; dashboardNumber < this.manageDashboardService.dashboardServices.length; dashboardNumber += 1) {
            const xaxis = this.axis[`dashboard_${dashboardNumber}`];
            if (xaxis && !options.scales[xaxis]) {
                options.scales[xaxis] = {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        source: 'labels',
                        color: '#000',
                        callback(value) {
                            return thisInternal.labels[value][`dashboard_${dashboardNumber}`];
                        },
                    },
                };
            }
        }

        return options;
    }

    getUserChoices() {
        const config = this.dashboardService
            ? this.dashboardService.currentConfig.getValue()
            : this.manageDashboardService.currentDashboardComparisonConfig.getValue();

        return config.userChoices;
    }

    onDatasetChange(value) {
        const config = this.getUserChoices().find((choice) => choice.db_name === this.dbName);
        config.legend_distri_merged = value;
        this.manageDashboardService.setComparisonDashbardConfig('userChoices', this.getUserChoices());

        this.chartData.datasets.forEach((dataset) => {
            dataset.hidden = (dataset.label !== value);
        });
        this.chart.chart.update();
    }

    getDatasets(distribution) {
        const datasets = [];

        const config = this.getUserChoices().find((choice) => choice.db_name === this.dbName);

        if (!config.legend_distri_merged || !distribution.values.find((value) => `${value.name}` === `${config.legend_distri_merged}`)) {
            this.selectedMergedItem = `${distribution.values[0]?.name}`;
        } else {
            this.selectedMergedItem = `${config.legend_distri_merged}`;
        }

        distribution.values.forEach((list) => {
            const displayName = (list.name === '' ? this.translate.instant('translations.utils.empty') : `${list.name}`);

            list.merged_values.forEach((dashboardValue, dashboardNumber) => {
                if (dashboardValue) {
                    const scope = `associated-data-${this.dbName}`;
                    let color = this.manageDashboardService.getDataColor(scope, displayName);
                    if (this.isMerged) {
                        if (dashboardNumber === 0) {
                            color = '#000';
                        } else if (dashboardNumber === 1) {
                            color = '#ccc';
                        } else if (dashboardNumber === 2) {
                            color = '#666';
                        }
                    }

                    const hidden = this.isMerged ? `${list.name}` !== this.selectedMergedItem : false;

                    const dataset = {
                        dashboardNumber,
                        xAxisID: this.axis[`dashboard_${dashboardNumber}`],
                        label: `${displayName}`,
                        borderColor: color,
                        pointBackgroundColor: color,
                        pointRadius: 5,
                        fill: false,
                        data: dashboardValue.distribution.map((value, dataIndex) => {
                            const x = dataIndex;
                            let y = value.doc_count !== null ? Math.round(value.doc_count * 10) / 10 : null;
                            y = (Object.is(y, -0)) ? 0 : y;
                            return { x, y };
                        }),
                        tension: 0.4,
                        hidden,
                    };

                    datasets.push(dataset);

                    if (!this.legendItems.includes(list.name)) {
                        this.legendItems.push(list.name);
                    }
                }
            });
        });
        return datasets;
    }

    getLineChartConfig() {
        return this.params;
    }

    // eslint-disable-next-line class-methods-use-this
    isPointClickable(): boolean {
        return false;
    }

    getPointValue(data) {
        return `${data.y + (this.getLineChartConfig().yaxis === 'occur' ? '' : '%')}`;
    }

    get hasMaxValuesAlert(): boolean {
        if (this.manageDashboardService.getCurrentVerbatimColumns()?.getValue() && this.manageDashboardService.getCurrentVerbatimColumns()?.getValue()[this.dbName]) {
            const maxMaxValues = this.manageDashboardService.getCurrentVerbatimColumns().getValue()[this.dbName].values.length;
            return this.maxNbChart < maxMaxValues;
        }
        return false;
    }

    get maxNbChart() {
        return this.params?.nb_values_chart;
    }
}
