import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import ExportType from 'app/modules/CommonExportTypeEnum';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { settingsPopupAnalytics } from '../../corpus-analyses/components/distribution-graphs/distribution-graphs.component';
import { GraphTypeEnum } from '../../corpus-graph.service';

@Component({
    selector: 'app-modal-graph-export',
    templateUrl: './modal-graph-export.component.html',
})
export default class ModalGraphExportComponent {
    protected selectedType: ExportType;

    public exportTypeEnum = ExportType;

    @Input()
    public type: GraphTypeEnum;

    @Input()
    public titleToTranslate:string;

    @Input()
    public trackNameStart = 'vue histogramme';

    @Output()
    private export: EventEmitter<ExportType> = new EventEmitter();

    constructor(
        public modal: NgbActiveModal,
        private translate: TranslateService,
    ) {}

    /**
     * Retourne le titre de la modal en fonction du type
     */
    get modalTitle(): string {
        return this.translate.instant(this.titleToTranslate);
    }

    /**
     * Evenement lors du clic sur le bouton "Exporter".
     * Envoi un event avec le type sélectionné et ferme la modal
     */
    onExport() {
        gtmClick({
            track_category: settingsPopupAnalytics[this.type].track_category,
            track_name: `${this.trackNameStart} ${settingsPopupAnalytics[this.type].partial_track_name} export`,
            track_cible: this.selectedType,
        });
        this.export.emit(this.selectedType);
        this.modal.close();
    }
}
