import {
    Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import DashboardTabs from 'app/utils/models/dashboard-tabs';
import { fromEvent, throttleTime } from 'rxjs';

@Directive({
    selector: '[dashboardScroll]',
})
export default class DashboardScrollDirective extends BaseComponent implements OnInit {
    @Output()
    public scrollInDashboard: EventEmitter<DashboardTabs> = new EventEmitter();

    private selectedElementId: DashboardTabs;

    get dashboardScrollElemTop() {
        return this.dashboardScrollElem && this.dashboardScrollElem.nativeElement ? this.dashboardScrollElem.nativeElement.getBoundingClientRect().top : 0;
    }

    get dashboardScrollElemBottom() {
        return this.dashboardScrollElem && this.dashboardScrollElem.nativeElement ? this.dashboardScrollElem.nativeElement.getBoundingClientRect().bottom : 0;
    }

    // Pour savoir si le dashboard courant a des classifications, on regarde uniquement le 1er dashboard (car les suivants auront la même info)
    get hasClassification() {
        if (this.manageDashboardService.firstDashboardService) {
            return !!this.manageDashboardService.firstDashboardService.getCurrentModel();
        }
        return false;
    }

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private dashboardScrollElem: ElementRef,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnInit(): void {
        // Quand on change de page, on scroll automatiquement la fenêtre en haut
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.scrollToTop();
            }
        });

        // Ecoute le chargement d'un dashboard
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(() => {
            this.resetLazyloadElements();
        });

        this.subs.sink = this.manageDashboardService.dashboardListChanged.subscribe(() => {
            this.resetLazyloadElements();
        });

        // Ecoute le chargement des nouveaux filtres
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe((dashIndex) => {
            this.resetLazyloadElements(dashIndex);
            this.verifyLazyloadElement();
        });

        // Ecoute le scroll de l'élément dashboardScrollElem
        // Appelle la méthode onDashboardScroll seulement 1 fois toutes les 150ms
        this.subs.sink = fromEvent(this.dashboardScrollElem.nativeElement, 'scroll').pipe(throttleTime(150)).subscribe(() => {
            this.onDashboardScroll();
        });
    }

    /**
     * Remet à jour les elements en lazyloading pour qu'ils soient de nouveau charger quand on scrollera dessus
     * @param dashIndex : index des dashboard à réinitialiser (si tableau vide on les réinitialise tous)
     */
    resetLazyloadElements(dashIndex?: number[]) {
        document.querySelectorAll('[data-lazyload-on-scroll][data-need-request="false"]').forEach((element) => {
            for (let i = 0; i < element.children.length; i += 1) {
                if (!dashIndex || dashIndex.includes(parseInt(element.children[i].getAttribute('data-dash-index'), 10))) {
                    this.renderer.addClass(element.children[i], 'is-loading');
                }
            }

            this.renderer.setAttribute(element, 'data-need-request', 'true');
        });

        document.querySelectorAll('[data-lazyload-on-scroll]:not([data-need-request])').forEach((element) => {
            this.renderer.addClass(element, 'is-loading');
        });
    }

    /**
     * Evènement lorsque l'utilisateur scrolle dans le dashboard.
     * Met à jour l'onglet sélectionné en fonction de l'élément visible dans le dashboard.
     */
    onDashboardScroll() {
        this.scrollInDashboard.emit(this.getElementTitleNearVisible());
        this.verifyLazyloadElement();
        const chartjsTooltip = document.getElementById('chartjs-tooltip');
        if (chartjsTooltip) {
            chartjsTooltip.remove();
        }
    }

    /**
     * Détermine si le composant sélectionné doit se (re)charger.
     */
    verifyLazyloadElement() {
        const selectedTabElement = document.getElementById(this.selectedElementId);
        if (selectedTabElement.getAttribute('data-need-request') === 'true') {
            this.renderer.setAttribute(selectedTabElement, 'data-need-request', 'false');
            this.manageDashboardService.lazyLoadRequestEvent.emit(this.selectedElementId);
        }
    }

    /**
     * Le titre est sélectionné quand il est au-dessus de la moitié de la fenêtre, quand le titre précédent n'est plus visible.
     */
    getElementTitleNearVisible(): DashboardTabs {
        const elementIds = [
            DashboardTabs.EXPLORE_VERBATIM,
            this.hasClassification ? DashboardTabs.CLASSIFICATION_AUTO : DashboardTabs.EVOLUTION_TEMPORELLE,
            DashboardTabs.THEMATICS_DATA,
            DashboardTabs.SATISFACTION,
            DashboardTabs.LABLLM,
            DashboardTabs.HEATMAP,
            DashboardTabs.ASSOCIATED_DATA,
        ];
        let titleNearVisible = elementIds[0];

        elementIds.forEach((element, index) => {
            const previousTitleNearVisible = index === 0 ? null : elementIds[index - 1];
            const titleEl = document.getElementById(`${element}Title`);
            if (titleEl) {
                const previousElt = previousTitleNearVisible === null ? null : document.getElementById(`${previousTitleNearVisible}Title`);
                const currentTop = titleEl.getBoundingClientRect().top;

                const ispreviousVisible = previousElt !== null && previousElt.getBoundingClientRect().bottom > this.dashboardScrollElemTop;
                const isHalfTop = currentTop < (this.dashboardScrollElemTop + this.dashboardScrollElemBottom) / 2;

                if (isHalfTop && !ispreviousVisible) {
                    titleNearVisible = element;
                }
            }
        });
        this.selectedElementId = titleNearVisible;
        return this.selectedElementId;
    }

    // Scroll en haut du bloc dashboardScroll
    scrollToTop() {
        if (this.dashboardScrollElem && this.dashboardScrollElem.nativeElement) {
            this.dashboardScrollElem.nativeElement.scrollTo(0, 0);
            this.scrollInDashboard.emit(this.getElementTitleNearVisible());
        }
    }

    // Scroll sur un élément spécifique à l'intérieur du bloc dashboardScroll
    scrollToElement(elementName: string) {
        if (this.dashboardScrollElem && this.dashboardScrollElem.nativeElement) {
            this.dashboardScrollElem.nativeElement.scrollTo(0, document.getElementById(elementName).offsetTop);
            this.scrollInDashboard.emit(this.getElementTitleNearVisible());
        }
    }
}
