import {
    Component, Input,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
    selector: 'modal-component',
    styleUrls: ['./modal.component.scss'],
    templateUrl: './modal.component.html',
})
export default class ModalComponent {
    @Input()
    private btnHeaderClose: boolean = true;

    @Input()
    private alertTypeVariant: string;

    @Input()
    private title: string;

    @Input()
    private titleToTranslate: string;

    @Input()
    private content: string;

    @Input()
    private contentToTranslate: string;

    @Input()
    private btnCloseToTranslate: string;

    @Input()
    private hasBtnClose = true;

    @Input()
    private btnValidToTranslate: string;

    @Input()
    private btnValidVariant: string;

    @Input()
    private btnCloseVariant: string = 'outline-secondary';

    status: Subject<boolean> = new Subject<boolean>();

    constructor(public modal: NgbActiveModal) {
    }

    valid() {
        this.modal.close();
        this.status.next(true);
    }

    close() {
        this.modal.close();
        this.status.next(false);
    }
}
