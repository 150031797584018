/* eslint-disable @typescript-eslint/dot-notation */
import { Time } from '@angular/common';
import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Column, Inputnamevalues } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';

export const CONVERSATION_COLUMNS = ['speaker', 'locuteur', 'conversation_id', 'tag', 'chapter'];
export const CONVERSATION_TURN_COLUMNS = ['duration', 'timestamp_start', 'timestamp_end', 'iturn_fromstart', 'iturn_fromend'];

enum TurnChoice {
    DURATION = 'duration',
    TIMESTAMP = 'timestamp',
    TURN_FROM_START = 'iturn_from_start',
    TURN_FROM_END = 'iturn_from_end',
}

interface Timestamp {
    hours: number,
    minutes: number,
    seconds: number,
}

interface TurnValues {
    duration: { start: number, end: number, max:number },
    timestamp: { start: Timestamp, end: Timestamp, max: Timestamp },
    iturn_from_start: { start: number, end: number, max:number },
    iturn_from_end: { start: number, end: number, max:number },
}

@Component({
    selector: 'filters-conversation-popup',
    templateUrl: './filters-conversation-popup.component.html',
    styleUrls: ['./filters-conversation-popup.component.scss'],
})
export default class FiltersConversationPopupComponent extends BaseComponent implements OnInit {
    @Input()
    private dashboardService: DashboardService;

    @Output()
    private save: EventEmitter<boolean> = new EventEmitter();

    public columns: Array<Column> = [];

    public applyFilterModalButtonDisabled: boolean = true;

    protected suppressButtonDisabled: boolean = true;

    public collapses: Object = {};

    public numSubDatasToSelect: Object = {};

    public numSubDatasSelected: Object = {};

    public numSubDatasSelectedForMore100: Object = {};

    public allSubDatasSelected: Object = {};

    public noMore: boolean = false;

    public arrayOfMore100ValuesSave: Array<number> = [];

    public displayColumns: Array<Column> = [];

    public currentPageArray: Array<number> = [];

    public currentPageArraySave: Array<number> = [];

    public arrayOfBoolean: Array<Boolean> = [];

    public arrayOfMore100Values: Array<number> = [];

    public makeColumns: Column = {};

    public columnsMore100Array: Array<string> = [];

    public modalFilters: Array<Inputnamevalues> = [];

    public activeFilters: Array<string> = [];

    private activeFiltersInitialState: Array<string> = [];

    public searchModel: string = '';

    public cleanSearchBoolean: boolean = false;

    public dashboardType: string;

    protected turnChoice: TurnChoice = TurnChoice.DURATION;

    private zeroTimestamp: Timestamp = {
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    protected turnValues: TurnValues = {
        duration: { start: 0, end: -1, max: 0 },
        timestamp: { start: { hours: 0, minutes: 0, seconds: 0 }, end: { hours: -1, minutes: -1, seconds: -1 }, max: { hours: 0, minutes: 0, seconds: 0 } },
        iturn_from_start: { start: 0, end: -1, max: 0 },
        iturn_from_end: { start: 0, end: -1, max: 0 },
    };

    constructor(
        public modal: NgbActiveModal,
    ) {
        super();
    }

    get verbatimDBName() {
        return this.dashboardService.currentFilters.getValue().verbatim_dbname;
    }

    initTurnValues() {
        const durationFilter = this.modalFilters.find((filter) => filter.inputName === (CONVERSATION_TURN_COLUMNS[0]));
        if (durationFilter && durationFilter.gte) {
            this.turnValues.duration.start = Number(durationFilter.gte);
            this.turnChoice = TurnChoice.DURATION;
        }
        if (durationFilter && durationFilter.lte) {
            this.turnValues.duration.end = Number(durationFilter.lte);
            this.turnChoice = TurnChoice.DURATION;
        }

        const timestampFromStartFilter = this.modalFilters.find((filter) => filter.inputName === (CONVERSATION_TURN_COLUMNS[1]));
        if (timestampFromStartFilter && timestampFromStartFilter.gte) {
            this.turnValues.timestamp.start = this.parseTimestamp(timestampFromStartFilter.gte);
            this.turnChoice = TurnChoice.TIMESTAMP;
        }

        const timestampFromEndFilter = this.modalFilters.find((filter) => filter.inputName === (CONVERSATION_TURN_COLUMNS[2]));
        if (timestampFromEndFilter && timestampFromEndFilter.lte) {
            this.turnValues.timestamp.end = this.parseTimestamp(timestampFromEndFilter.lte);
            this.turnChoice = TurnChoice.TIMESTAMP;
        }

        const iturnFromStartFilter = this.modalFilters.find((filter) => filter.inputName === (CONVERSATION_TURN_COLUMNS[3]));
        if (iturnFromStartFilter && iturnFromStartFilter.gte) {
            this.turnValues.iturn_from_start.start = Number(iturnFromStartFilter.gte);
            this.turnChoice = TurnChoice.TURN_FROM_START;
        }
        if (iturnFromStartFilter && iturnFromStartFilter.lte) {
            this.turnValues.iturn_from_start.end = Number(iturnFromStartFilter.lte);
            this.turnChoice = TurnChoice.TURN_FROM_START;
        }

        const iturnFromEndFilter = this.modalFilters.find((filter) => filter.inputName === (CONVERSATION_TURN_COLUMNS[4]));
        if (iturnFromEndFilter && iturnFromEndFilter.gte) {
            this.turnValues.iturn_from_end.start = Number(iturnFromEndFilter.gte);
            this.turnChoice = TurnChoice.TURN_FROM_END;
        }
        if (iturnFromEndFilter && iturnFromEndFilter.lte) {
            this.turnValues.iturn_from_end.end = Number(iturnFromEndFilter.lte);
            this.turnChoice = TurnChoice.TURN_FROM_END;
        }

        this.subs.sink = this.dashboardService.currentVerbatimColumns.subscribe((cols) => {
            const timestampEnd = Object.values(cols).find((c) => c.inputName === 'timestamp_end');
            if (timestampEnd) {
                const sortedValues = _.sortBy(timestampEnd.values, 'key');
                this.turnValues.timestamp.max = this.parseTimestamp(sortedValues[timestampEnd.values.length - 1].key_as_string);

                if (this.turnValues.timestamp.end.hours === -1) {
                    this.turnValues.timestamp.end.hours = this.turnValues.timestamp.max.hours;
                    this.turnValues.timestamp.end.minutes = this.turnValues.timestamp.max.minutes;
                    this.turnValues.timestamp.end.seconds = this.turnValues.timestamp.max.seconds;
                }
            }
            const iturnFromEnd = Object.values(cols).find((c) => c.inputName === 'iturn_fromend');
            if (iturnFromEnd) {
                const sortedValues = _.sortBy(iturnFromEnd.values, 'key');
                this.turnValues.iturn_from_end.max = sortedValues[iturnFromEnd.values.length - 1].key;
                if (this.turnValues.iturn_from_end.end === -1) {
                    this.turnValues.iturn_from_end.end = this.turnValues.iturn_from_end.max;
                }
            }
            const iturnFromStart = Object.values(cols).find((c) => c.inputName === 'iturn_fromstart');
            if (iturnFromStart) {
                const sortedValues = _.sortBy(iturnFromStart.values, 'key');
                this.turnValues.iturn_from_start.max = sortedValues[iturnFromStart.values.length - 1].key;
                if (this.turnValues.iturn_from_start.end === -1) {
                    this.turnValues.iturn_from_start.end = this.turnValues.iturn_from_start.max;
                }
            }
            const durationEnd = Object.values(cols).find((c) => c.inputName === 'duration');
            if (durationEnd) {
                const sortedValues = _.sortBy(durationEnd.values, 'key');
                this.turnValues.duration.max = sortedValues[durationEnd.values.length - 1].key;
                if (this.turnValues.duration.end === -1) {
                    this.turnValues.duration.end = this.turnValues.duration.max;
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        await this.dashboardService.onGetVerbatimColumns();

        this.subs.sink = this.dashboardService.currentFilters.subscribe((params) => {
            if (params != null) {
                this.activeFilters = [];
                params.filters.forEach((f) => {
                    if (f.values) {
                        f.values.forEach((val) => {
                            this.activeFilters.push(`${f.inputName}|${val}`);
                        });
                    }
                    if (f.gte) {
                        this.activeFilters.push(`${f.inputName}|gte|${f.gte}`);
                    }
                    if (f.gt) {
                        this.activeFilters.push(`${f.inputName}|gt|${f.gt}`);
                    }
                    if (f.lte) {
                        this.activeFilters.push(`${f.inputName}|lte|${f.lte}`);
                    }
                    if (f.lt) {
                        this.activeFilters.push(`${f.inputName}|lt|${f.lt}`);
                    }
                });

                this.activeFiltersInitialState = { ...(this.activeFilters.sort()) };
            }
        });

        this.dashboardType = this.dashboardService.currentDashboardType;
        this.modalFilters = _.cloneDeep(this.dashboardService.currentFilters.value.filters);
        this.initTurnValues();

        this.subs.sink = this.dashboardService.currentVerbatimColumns.subscribe((cols) => {
            this.columns = [];
            this.displayColumns = [];
            this.currentPageArray = []; // this array fill in the same time that displayColumns has the same index, and tell the currentPage of pagination
            this.arrayOfBoolean = []; // this array fill in the same time that displayColumns has the same index and tell if the div of values should be show or not
            this.arrayOfMore100Values = []; // this array fill in the same time that displayColumns has the same index and tell if the columns have more of 100 values or not
            this.columnsMore100Array = [];
            const columnstmp = cols as object;
            Object.keys(columnstmp).forEach((col) => {
                if (CONVERSATION_COLUMNS.includes(columnstmp[col].inputName)) {
                    const values = columnstmp[col].values.map((v) => v.key);
                    columnstmp[col].search = [columnstmp[col].displayName, values.join(' | ')].join(' | ');
                    this.columns.push(columnstmp[col]);
                    this.displayColumns.push(columnstmp[col]);
                    this.currentPageArray.push(1);
                    this.arrayOfBoolean.push(false);
                    this.arrayOfMore100Values.push(columnstmp[col].distinct_values_count);
                    if (columnstmp[col].distinct_values_count > 100) {
                        this.columnsMore100Array.push(columnstmp[col].inputName);
                    }
                }
            });
        });
        this.displayColumns.forEach((cols) => {
            this.numSubDatasToSelect[cols.inputName] = cols.values.length;
            this.numSubDatasSelected[cols.inputName] = 0;
            this.allSubDatasSelected[cols.inputName] = false;
            this.collapses[cols.inputName] = true;
        });
        // Ajout de l'état initial de l'accordéon pour les tours de parole
        this.numSubDatasToSelect['turn'] = 3;
        this.numSubDatasSelected['turn'] = 0;
        this.allSubDatasSelected['turn'] = false;
        this.collapses['turn'] = true;

        this.currentPageArraySave = { ...(this.currentPageArray) };
        this.arrayOfMore100ValuesSave = this.arrayOfMore100Values;
        this.updateNumberOfDatasSelected();

        this.updateTurnChoices();
    }

    // eslint-disable-next-line class-methods-use-this
    parseTimestamp(value: string): Timestamp {
        const spliited = value.split(':');
        return {
            seconds: parseInt(spliited[2], 10),
            minutes: parseInt(spliited[1], 10),
            hours: parseInt(spliited[0], 10),
        };
    }

    // eslint-disable-next-line class-methods-use-this
    equalsTimestamp(t1: Timestamp, t2: Timestamp) {
        return t1.hours === t2.hours && t1.minutes === t2.minutes && t1.seconds === t2.seconds;
    }

    // eslint-disable-next-line class-methods-use-this
    formatTimestam(t: Timestamp) {
        return `${t.hours < 10 ? '0' : ''}${t.hours}:${t.minutes < 10 ? '0' : ''}${t.minutes}:${t.seconds < 10 ? '0' : ''}${t.seconds}`;
    }

    updateTurnChoices() {
        // on efface les filtres de tour de parol existant
        this.modalFilters = this.modalFilters.filter((filter) => CONVERSATION_TURN_COLUMNS.indexOf(filter.inputName) < 0);
        this.activeFilters = this.activeFilters.filter((v) => !v.startsWith('duration') && !v.startsWith('timestamp') && !v.startsWith('iturn_from'));
        this.numSubDatasSelected['turn'] = 0;

        // on ajoute le filtre en fonction de la valeur de la radio
        if (this.turnChoice === TurnChoice.DURATION) {
            const durationFilter: Inputnamevalues = {
                inputName: CONVERSATION_TURN_COLUMNS[0],
                values: [],
            };
            if (this.turnValues.duration.end !== this.turnValues.duration.max) {
                durationFilter.lte = `${this.turnValues.duration.end}`;
                this.activeFilters.push(`duration|lte|${this.turnValues.duration.end}`);
                this.numSubDatasSelected['turn'] += 1;
            }

            if (this.turnValues.duration.start !== 0) {
                durationFilter.gte = `${this.turnValues.duration.start}`;
                this.activeFilters.push(`duration|gte|${this.turnValues.duration.start}`);
                this.numSubDatasSelected['turn'] += 1;
            }

            if (durationFilter.gte || durationFilter.lte) {
                this.modalFilters.push(durationFilter);
            }
        } else if (this.turnChoice === TurnChoice.TIMESTAMP) {
            const startTimestampFilter: Inputnamevalues = {
                inputName: CONVERSATION_TURN_COLUMNS[1],
                values: [],
            };

            if (!this.equalsTimestamp(this.turnValues.timestamp.start, this.zeroTimestamp)) {
                startTimestampFilter.gte = this.formatTimestam(this.turnValues.timestamp.start);
                this.activeFilters.push(`timestamp|gte|${startTimestampFilter.gte}`);
                this.numSubDatasSelected['turn'] += 1;
                this.modalFilters.push(startTimestampFilter);
            }

            const endTimestampFilter: Inputnamevalues = {
                inputName: CONVERSATION_TURN_COLUMNS[2],
                values: [],
            };

            if (!this.equalsTimestamp(this.turnValues.timestamp.end, this.turnValues.timestamp.max)) {
                endTimestampFilter.lte = this.formatTimestam(this.turnValues.timestamp.end);
                this.activeFilters.push(`timestamp|lte|${endTimestampFilter.lte}`);
                this.numSubDatasSelected['turn'] += 1;
                this.modalFilters.push(endTimestampFilter);
            }
        } else if (this.turnChoice === TurnChoice.TURN_FROM_START) {
            const iturnFromStartFilter: Inputnamevalues = {
                inputName: CONVERSATION_TURN_COLUMNS[3],
                values: [],
            };
            if (this.turnValues.iturn_from_start.start !== 0) {
                iturnFromStartFilter.gte = `${this.turnValues.iturn_from_start.start}`;
                this.activeFilters.push(`iturn_from_start|gte|${this.turnValues.iturn_from_start.start}`);
                this.numSubDatasSelected['turn'] += 1;
            }
            if (this.turnValues.iturn_from_start.end !== this.turnValues.iturn_from_start.max) {
                iturnFromStartFilter.lte = `${this.turnValues.iturn_from_start.end}`;
                this.activeFilters.push(`iturn_from_start|lte|${this.turnValues.iturn_from_start.end}`);
                this.numSubDatasSelected['turn'] += 1;
            }

            if (iturnFromStartFilter.lte || iturnFromStartFilter.gte) {
                this.modalFilters.push(iturnFromStartFilter);
            }
        } else if (this.turnChoice === TurnChoice.TURN_FROM_END) {
            const iturnFromEndFilter: Inputnamevalues = {
                inputName: CONVERSATION_TURN_COLUMNS[4],
                values: [],
            };
            if (this.turnValues.iturn_from_end.start !== 0) {
                iturnFromEndFilter.gte = `${this.turnValues.iturn_from_end.start}`;
                this.activeFilters.push(`iturn_from_end|gte|${this.turnValues.iturn_from_end.start}`);
                this.numSubDatasSelected['turn'] += 1;
            }
            if (this.turnValues.iturn_from_end.end !== this.turnValues.iturn_from_end.max) {
                iturnFromEndFilter.lte = `${this.turnValues.iturn_from_end.end}`;
                this.activeFilters.push(`iturn_from_end|lte|${this.turnValues.iturn_from_end.end}`);
                this.numSubDatasSelected['turn'] += 1;
            }

            if (iturnFromEndFilter.lte || iturnFromEndFilter.gte) {
                this.modalFilters.push(iturnFromEndFilter);
            }
        }
        this.activeFilters.sort();
        this.updateNumberOfDatasSelected();
    }

    onFilterChange(f, value, event): void {
        if (event.target.checked) {
            this.onFilterChangeByComp(f, value, true);
        } else this.onFilterChangeByComp(f, value, false);
    }

    onFilterChangeByComp(f, value, event): void {
        const selectedFilters = _.map(this.modalFilters, 'inputName');

        if (event) {
            if (_.includes(selectedFilters, f)) {
                if (!_.includes(this.modalFilters[selectedFilters.indexOf(f)].values, value)) {
                    this.modalFilters[selectedFilters.indexOf(f)].values.push(value);
                }
            } else {
                this.modalFilters.push({ values: [value], inputName: f });
            }
            if (!this.activeFilters.includes(`${f}|${value}`)) {
                this.activeFilters.push(`${f}|${value}`);
            }
        } else {
            this.modalFilters[selectedFilters.indexOf(f)].values.splice(this.modalFilters[selectedFilters.indexOf(f)].values.indexOf(value), 1);
            if (this.modalFilters[selectedFilters.indexOf(f)].values.length === 0) {
                this.modalFilters.splice(selectedFilters.indexOf(f), 1);
            }
            this.activeFilters = this.activeFilters.filter((v) => v !== `${f}|${value}`);
        }
        this.activeFilters.sort();
        this.updateNumberOfDatasSelected();
    }

    isFilterChecked(column, value):boolean {
        return this.activeFilters.indexOf(
            `${column.inputName}|${value.key}`,
        ) > -1;
    }

    toggleCollapse(input) {
        if (this.collapses[input] === undefined) {
            this.collapses[input] = true;
        }
        this.collapses[input] = !this.collapses[input];
    }

    updateNumberOfDatasSelected() {
        if (this.modalFilters.length === 1) {
            this.suppressButtonDisabled = true;
            this.displayColumns.forEach((cols) => {
                this.allSubDatasSelected[cols.inputName] = false;
                this.numSubDatasSelected[cols.inputName] = 0;
            });
            this.applyFilterModalButtonDisabled = _.isEmpty(diff(this.activeFilters, this.activeFiltersInitialState));
        } else {
            this.suppressButtonDisabled = false;
            this.modalFilters.forEach((cols) => {
                this.numSubDatasSelected[cols.inputName] = 0;
                if (cols.values) {
                    cols.values.forEach(() => {
                        if (cols.values.length > 0) {
                            this.numSubDatasSelected[cols.inputName] += 1;
                        }
                    });
                } else if (cols.gt || cols.gte || cols.lt || cols.lte) {
                    this.numSubDatasSelected[cols.inputName] += 1;
                } else {
                    this.numSubDatasSelected[cols.inputName] = 0;
                }
                if (_.includes(this.columnsMore100Array, cols.inputName)) {
                    const colsForIndex = _.map(this.displayColumns, 'inputName');
                    this.updateNumberOfDatasSelectedForMore100(colsForIndex.indexOf(cols.inputName), cols.inputName);
                } else {
                    this.allSubDatasSelected[cols.inputName] = (this.numSubDatasSelected[cols.inputName] === this.numSubDatasToSelect[cols.inputName]);
                }
            });
            if (this.activeFilters.length > 201) {
                this.applyFilterModalButtonDisabled = true;
            } else {
                this.applyFilterModalButtonDisabled = _.isEmpty(diff(this.activeFilters, this.activeFiltersInitialState));
            }
        }
    }

    selectAllSubDatas(column, event) {
        if (event.target.checked) {
            this.displayColumns.forEach((cols) => {
                if (cols.inputName === column) {
                    cols.values.forEach((data) => {
                        this.onFilterChangeByComp(cols.inputName, data.key, true);
                    });
                }
            });
        } else {
            this.displayColumns.forEach((cols) => {
                if (cols.inputName === column) {
                    this.numSubDatasSelected[cols.inputName] = 0;
                    cols.values.forEach((data) => {
                        this.onFilterChangeByComp(cols.inputName, data.key, false);
                    });
                }
            });
        }
    }

    suppressAll() {
        this.modalFilters.splice(1); // efface tout sauf premier élément presverbatim
        const nbOfFilters = this.activeFilters.length;
        this.activeFilters.splice(0, (nbOfFilters - 1)); // efface tout sauf dernier élément presverbatim
        this.updateNumberOfDatasSelected();

        this.turnValues = {
            duration: { start: 0, end: -1, max: 0 },
            timestamp: { start: { hours: 0, minutes: 0, seconds: 0 }, end: { hours: -1, minutes: -1, seconds: -1 }, max: { hours: 0, minutes: 0, seconds: 0 } },
            iturn_from_start: { start: 0, end: -1, max: 0 },
            iturn_from_end: { start: 0, end: -1, max: 0 },
        };
        this.initTurnValues();
        this.updateTurnChoices();
    }

    onSearchClean() {
        this.noMore = false;
        this.searchModel = '';
        this.cleanSearchBoolean = true;
        this.displayColumns.forEach((cols) => {
            this.collapses[cols.inputName] = true;
        });

        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'supprimer tous les filtres données associées',
        });
    }

    onFreeSearch(e) {
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'rechercher dans les filtres données de la conversation',
        });

        this.displayColumns = e;
        this.displayColumns.forEach((cols) => {
            this.collapses[cols.inputName] = false;
        });
        this.cleanSearchBoolean = false;
    }

    onNewCurrentPage(e) {
        let i = 0;
        let indexOfCol = 0;
        this.currentPageArray = e;
        this.currentPageArray.forEach((element) => {
            if (element !== this.currentPageArraySave[i]) {
                indexOfCol = i;
            } else i += 1;
        });
        this.currentPageArraySave = { ...(this.currentPageArray) };
        const colName = this.displayColumns[indexOfCol].inputName;
        this.updateNumberOfDatasSelectedForMore100(indexOfCol, colName);
    }

    updateNumberOfDatasSelectedForMore100(indexOfCol, colName) {
        if (this.modalFilters.length === 1) {
            this.suppressButtonDisabled = true;
            this.displayColumns.forEach((cols) => {
                this.allSubDatasSelected[cols.inputName] = false;
                this.numSubDatasSelected[cols.inputName] = 0;
                this.numSubDatasSelectedForMore100[colName] = 0;
            });
        } else {
            const selectedFilters = _.map(this.modalFilters, 'inputName');

            this.numSubDatasSelectedForMore100[colName] = 0;
            this.numSubDatasToSelect[colName] = this.displayColumns[indexOfCol].values.length;
            this.displayColumns[indexOfCol].values.forEach((value) => {
                if (_.includes(this.modalFilters[selectedFilters.indexOf(colName)].values, value.key)) { // si modalFilter contient la valeur alors la valeur est cochée
                    this.numSubDatasSelectedForMore100[colName] += 1;
                }
            });
            this.allSubDatasSelected[colName] = (this.numSubDatasSelectedForMore100[colName] === this.numSubDatasToSelect[colName]);
        }
    }

    onSaveFilters() {
        // TODO: convert this.turnValues in modalFilters
        this.dashboardService.updateFilterList(this.modalFilters);
        this.save.emit(true);
        this.modal.close();
    }
}
