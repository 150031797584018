import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Period } from 'app/api/models/distriparams';
import { HasSatisfaction } from 'app/api/models/fieldname';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { LineChartParamsConfig } from 'app/api/models/dashboard-config';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import { GraphConf } from '../../distribution-graphs/distribution-graphs.component';

@Component({
    selector: 'app-line-chart-settings-popup',
    templateUrl: './line-chart-settings-popup.component.html',
})
export default class LineChartSettingsPopupComponent implements OnInit {
    @Output()
    private save: EventEmitter<LineChartParamsConfig> = new EventEmitter();

    params: LineChartParamsConfig;

    initialParams: LineChartParamsConfig;

    conf: GraphConf;

    graphService: GraphService;

    dashboardService: DashboardService;

    hasSatisfactionInfos : HasSatisfaction;

    public gtmTrackCategory: string;

    public partialGtmTrackName: string;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {
        this.params = _.cloneDeep(this.initialParams);
    }

    has(property) {
        return Object.prototype.hasOwnProperty.call(this.params, property);
    }

    isEnabled(period:string): boolean {
        if (period === 'day') {
            return this.graphService.isDayAllowedForGraph(this.dashboardService);
        } if (period === 'week') {
            return this.graphService.isWeekAllowedForGraph(this.dashboardService);
        }
        return true;
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }

    launchGaEventNbValues(): void {
        gtmClick({
            track_category: this.gtmTrackCategory,
            track_name: `vue temporelle ${this.partialGtmTrackName} nbre de classes affichées`,
            track_cible: `${this.params.nb_values_chart}`,
        });
    }

    validate() {
        if (!this.initialParams.top_activated && this.params.top_activated && this.initialParams.nb_values_chart === this.params.nb_values_chart) {
            this.launchGaEventNbValues();
        }
        this.save.emit(this.params);
        this.modal.close();
    }

    get forcedPeriod(): Period {
        return this.graphService.getDefaultPeriod(this.params.period, this.dashboardService);
    }

    set forcedPeriod(period: Period) {
        this.params.period = period;
    }
}
