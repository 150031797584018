import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Dashboardforuser, Dashboardlist } from 'app/api/models';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { SetDashModified } from 'app/stores/actions/settings/settings.actions';
import ModalComponent from 'app/shared/components/modal/modal.component';

@Component({
    selector: 'app-dashboard-name-bar',
    templateUrl: './dashboard-name-bar.component.html',
    styleUrls: ['./dashboard-name-bar.component.scss'],
})
export default class DashboardNameBarComponent {
    @Output()
        addDashboard: EventEmitter<Dashboardforuser> = new EventEmitter<Dashboardforuser>();

    @Output()
        removeDashboard: EventEmitter<number> = new EventEmitter<number>();

    // Retourne la classe à mettre sur l'input (pour définir la taille max)
    get nbDashboardClassName():string {
        switch (this.manageDashboardService.dashboardServices.length) {
            case 2: return 'two-dash';
            case 3: return 'three-dash';
            default: return '';
        }
    }

    // Retourne la liste des dashboards utilisés pour la comparaison
    get dashboardsComparisons() {
        return this.manageDashboardService.dashboardServices.map((dashServ) => dashServ.currentDashboard.getValue());
    }

    // Retourne les dashboards ayant la même colonne de verbatim que le 1er dashboard (de la comparaison)
    // Utiliser pour le bouton "Ajouter" un dashboard à la comparaison
    get dashboardsWithSameVerbatim(): Dashboardlist {
        return this.manageDashboardService.getDashboardsWithSameVerbatim(this.manageDashboardService.dashboardServices[0].currentDashboard.getValue());
    }

    constructor(
        protected manageDashboardService: ManageDashboardService,
        private store: Store,
        private modalService: NgbModal,
    ) {
    }

    /**
     * Retourne la classe à mettre sur le carré qui affiche le numéro du dashboard
     */
    // eslint-disable-next-line class-methods-use-this
    public getNumberClassName(index: number):string {
        switch (index) {
            case 0: return 'one';
            case 1: return 'two';
            case 2: return 'three';
            default: return '';
        }
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Ajouter" un dashboard à la comparaison en cours
     */
    onAddDashboard(dash: Dashboardforuser) {
        this.addDashboard.emit(this.manageDashboardService.copyDashForComparison(dash));
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Supprimer" un dashboard à la comparaison en cours
     */
    onRemoveDashboard(index: number) {
        this.removeDashboard.emit(index);
    }

    /**
     * Evènement lorsque qu'on change le nom d'un dashboard enfant pour signaler une modification de dashboard
     */
    onNameChanged(dashboardId: number) {
        this.store.dispatch(new SetDashModified({ dashboardId, isDashModified: true }));
    }

    /**
     * Ouvre la modal de confirmation avant la suppression
     */
    openDeleteModal(index: number) {
        const modal = this.modalService.open(ModalComponent, {
            backdrop: 'static',
        });
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.modals.confirmdeleteComparison.title';
        modal.componentInstance.btnHeaderClose = false;
        modal.componentInstance.contentToTranslate = 'translations.analysisDashboard.modals.confirmdeleteComparison.text';
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.analysisDashboard.modals.confirmdeleteComparison.validBtn';
        modal.componentInstance.btnValidVariant = 'danger';
        modal.componentInstance.status.subscribe((s: boolean) => {
            if (s) {
                this.onRemoveDashboard(index);
            }
        });
    }
}
