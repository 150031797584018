import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Inputnamevalues } from 'app/api/models';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { CorpusService as CorpusApi } from 'app/api/services';
import CorpusService from 'app/utils/services/corpus.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';

@Component({
    selector: 'app-filters-sentiment',
    templateUrl: './filters-sentiment.component.html',
    styleUrls: ['./filters-sentiment.component.scss'],
})
export default class FiltersSentimentComponent extends BaseVerticalFilterComponent implements OnInit {
    constructor(
        private dashboardService: DashboardService,
        private translate: TranslateService,
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super(dashboardService);
    }

    get count(): any {
        return this.filter === null || this.filter.values === null ? 0 : this.filter.values.length;
    }

    negField: string = '';

    posField: string = '';

    mitField: string = '';

    neuField: string = '';

    counts: any = {};

    async ngOnInit() {
        super.ngOnInit();

        await this.translate.get('translations.corpus.filters.sentiment.neg').toPromise().then();
        this.negField = this.translate.instant('translations.corpus.filters.sentiment.neg');
        this.posField = this.translate.instant('translations.corpus.filters.sentiment.pos');
        this.mitField = this.translate.instant('translations.corpus.filters.sentiment.mit');
        this.neuField = this.translate.instant('translations.corpus.filters.sentiment.neu');

        [this.negField, this.posField, this.mitField, this.neuField].forEach((col) => {
            this.counts[col] = 0;
        });

        this.subs.sink = this.dashboardService.currentVerbatimColumns.subscribe((columns: any) => {
            if (this.hasTones() && columns) {
                columns[this.SENTIMENT_FIELD_NAME].values.forEach((value) => {
                    this.counts[value.key] = value.doc_count;
                });
            }
        });
    }

    SENTIMENT_FIELD_NAME = 'sentiment';

    get neg(): boolean {
        return this.getValue(this.negField);
    }

    set neg(value: boolean) {
        this.setValue(this.negField, value);
    }

    get pos(): boolean {
        return this.getValue(this.posField);
    }

    set pos(value: boolean) {
        this.setValue(this.posField, value);
    }

    get mit(): boolean {
        return this.getValue(this.mitField);
    }

    set mit(value: boolean) {
        this.setValue(this.mitField, value);
    }

    get neu(): boolean {
        return this.getValue(this.neuField);
    }

    set neu(value: boolean) {
        this.setValue(this.neuField, value);
    }

    get negCount() {
        return this.counts[this.negField];
    }

    get posCount() {
        return this.counts[this.posField];
    }

    get mitCount() {
        return this.counts[this.mitField];
    }

    get neuCount() {
        return this.counts[this.neuField];
    }

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    hasTones() {
        return this.corpusService.hasTones();
    }

    getValue(name: string): boolean {
        if (!this.searchParams) {
            return null;
        }

        if (this.filter === null) {
            return null;
        }
        return this.filter.values.includes(name);
    }

    get filter(): Inputnamevalues {
        if (!this.searchParams) {
            return null;
        }
        const filterPresenceVerbatim = this.searchParams.filters.filter(
            (filter) => filter.inputName === this.SENTIMENT_FIELD_NAME,
        );
        if (filterPresenceVerbatim.length === 0) {
            return null;
        }
        return filterPresenceVerbatim[0];
    }

    setValue(name: string, value: boolean) {
        if (this.filter === null) {
            this.searchParams.filters.push({
                inputName: this.SENTIMENT_FIELD_NAME,
                values: [],
            });
        }

        if (value && !this.filter.values.includes(name)) {
            this.filter.values.push(name);
        } else if (!value && this.filter.values.includes(name)) {
            this.filter.values = this.filter.values.filter((v) => v !== name);
        }

        // si le filtre ne contient aucune valeur, on le supprime des search params
        if (this.filter.values.length === 0) {
            this.searchParams.filters = this.searchParams.filters.filter(
                (filter) => filter.inputName !== this.SENTIMENT_FIELD_NAME,
            );
        } else {
            this.filter.values = this.filter.values.sort();
        }

        this.dashboardService.updateFilterList(this.searchParams.filters);
    }
}
