/* eslint-disable no-underscore-dangle */
import {
    Component, Input,
} from '@angular/core';
import { Yaxis } from 'app/api/models/histoparams';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';

@Component({
    selector: 'app-distribution-details-popup-graph',
    templateUrl: './distribution-details-popup-graph.component.html',
    styleUrls: ['./distribution-details-popup-graph.component.scss'],
})
export default class DistributionDetailsPopupGraphComponent {
    @Input()
        chartTooltips: ChartTooltipsService;

    @Input()
        type: string;

    @Input()
        unit: Yaxis;

    @Input()
        data;
}
