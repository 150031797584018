import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { News } from 'app/api/models';
import NewsState from 'app/stores/state/news/news.state';
import { Observable } from 'rxjs';
import { NewsStateModel } from 'app/stores/models/news/news-state.model';
import { SetNews } from 'app/stores/actions/news/news.actions';
import * as _ from 'lodash';
import NewsModalService from 'app/modules/news/news-modal/news-modal.service';
import { Loginwithmeta } from '../../../api/models/loginwithmeta';
import { UserService, NewsService } from '../../../api/services';
import { Login } from '../../../api/models/login';
import { SetUser, ResetUser } from '../../../stores/actions/user/user.actions';

/**
 * Login user
 *
 * @export
 * @class LoginComponent
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export default class LoginComponent implements OnInit {
    public isLoading: boolean;

    public username: string;

    public password: string;

    private auth: Login = { username: null, password: null };

    public error: boolean = false;

    public errorMsg: string = 'translations.auth.login.error';

    public noGassi: boolean = false;

    private authGassi: Loginwithmeta = {
        ftusermail: null, sm_authtype: null, sm_universalid: null, ftusergivenname: null, ftapplicationroles: null, ftusertelephonenumber: null, ftusersn: null, ftusercredentials: null,
    };

    private redirectUrl: string = '/';

    public news$: Observable<NewsStateModel>;

    public news: News[] = [];

    constructor(
        private store: Store,
        private userService: UserService,
        private newsService: NewsService,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private metaService: Meta,
        private logger: NGXLogger,
        private translate: TranslateService,
        private newsModalService: NewsModalService,
        private route: ActivatedRoute,
    ) {
        this.store.dispatch(new ResetUser());
        this.isLoading = false;
    }

    ngOnInit() {
        this.redirectUrl = this.route.snapshot.queryParams.returnUrl || '/';
        this.news$ = this.store.select(NewsState);

        if (this.metaService.getTag('name= "gassi"')) {
            // console.log('*****gassi');
            this.noGassi = false;
            // -------------Gassi
            this.isLoading = true;
            this.error = false;

            if (this.metaService.getTag('name= "gassi"')) {
                const metaEl: HTMLMetaElement = this.metaService.getTag('name= "gassi"');
                const jsonb64: string = metaEl.content;
                const jsonstr = atob(jsonb64);
                const jsonarr = JSON.parse(jsonstr);
                if (jsonarr.sm_universalid != null) {
                    this.authGassi.sm_universalid = jsonarr.sm_universalid;
                }
                if (jsonarr.ftusermail != null) {
                    this.authGassi.ftusermail = jsonarr.ftusermail;
                }
                if (jsonarr.sm_authtype != null) {
                    this.authGassi.sm_authtype = jsonarr.sm_authtype;
                }
                if (jsonarr.ftapplicationroles != null) {
                    this.authGassi.ftapplicationroles = jsonarr.ftapplicationroles;
                }
                if (jsonarr.ftusertelephonenumber != null) {
                    this.authGassi.ftusertelephonenumber = jsonarr.ftusertelephonenumber;
                }
                if (jsonarr.ftusersn != null) {
                    this.authGassi.ftusersn = jsonarr.ftusersn;
                }
                if (jsonarr.ftusercredentials != null) {
                    this.authGassi.ftusercredentials = jsonarr.ftusercredentials;
                }

                this.userService.postV1AuthLoginMeta(this.authGassi).subscribe({
                    next: () => {
                        this.userService.getV1UserUsername(this.authGassi.sm_universalid).subscribe({
                            next: (profil) => {
                                this.permissionsService.loadPermissions(profil.roles);
                                this.logger.info(profil.roles);
                                this.store.dispatch(new SetUser(profil));
                                this.logger.info(profil);
                                this.translate.use(profil.preferred_language.toLowerCase());
                                const newsRecent = profil.newslist.filter((item) => item.isRecent);
                                if (newsRecent.length) {
                                    this.newsModalService.openModal();
                                }
                                this.router.navigate(['/']);
                            },
                            error: (error) => {
                                this.onError(error);
                            },
                        });
                    },
                    error: (error) => {
                        this.onError(error);
                    },
                });
            }
            // ---------Gassi
        } else {
            this.noGassi = true;

            this.news$.subscribe((state) => {
                if (_.isEmpty(state.newsList)) {
                    this.loadNews();
                } else {
                    this.setNews();
                }
            });
        }
    }

    /**
   * Manage error on back-end request
   * @param error : error returned by the back-end
   */
    onError(error) {
        this.isLoading = false;
        this.errorMsg = (error?.error?.error) ? `translations.httpErrors.${error.error.error}` : 'translations.auth.login.error';
        this.error = true;
    }

    setNews() {
        this.news = this.store.snapshot().news.newsList;
    }

    /**
     * Return news filtered to display in the news modal
     */
    get filteredNews() {
        return this.news.filter((item) => item.isRecent && !item.isHeadline);
    }

    /**
     * Return news headline to display in the news modal
     */
    get headlineNews() {
        return this.news.filter((item) => item.isHeadline && item.isRecent);
    }

    loadNews() {
        this.newsService.getV1News().subscribe((result) => {
            this.store.dispatch(new SetNews(result.newslist));
            this.news = this.store.snapshot().news.newsList;
        });
    }

    onSubmit() {
        this.auth.username = this.username;
        this.auth.password = this.password;
        this.isLoading = true;
        this.error = false;

        this.userService.postV1AuthLogin(this.auth).subscribe({
            next: () => {
                this.userService.getV1UserUsername(this.auth.username).subscribe({
                    next: (profil) => {
                        this.permissionsService.loadPermissions(profil.roles);
                        this.store.dispatch(new SetUser(profil));
                        this.translate.use(profil.preferred_language.toLowerCase());
                        const newsRecent = profil.newslist.filter((item) => item.isRecent);
                        if (newsRecent.length) {
                            this.newsModalService.openModal();
                        }
                        this.router.navigateByUrl(this.redirectUrl);
                    },
                    error: (error) => {
                        this.onError(error);
                    },
                });
            },
            error: (error) => {
                this.onError(error);
            },
        });
    }
}
