import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import SettingsState from '../../../stores/state/settings/settings.state';
import { SettingsModel } from '../../../stores/models/settings/settings.model';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export default class BannerComponent implements OnInit {
    public title: string;

    public body: string;

    settings$: Observable<SettingsModel>;

    constructor(
        private translation: TranslateService,
        private store: Store,
    ) {
        this.title = this.store.snapshot().settings.vcrm[
            `title_homepage_${this.translation.currentLang.toLowerCase()}`
        ];
        this.body = this.store.snapshot().settings.vcrm[
            `text_homepage_${this.translation.currentLang.toLowerCase()}`
        ];
    }

    ngOnInit() {
        this.translation.onLangChange.subscribe(() => {
            this.title = this.store.snapshot().settings.vcrm[
                `title_homepage_${this.translation.currentLang.toLowerCase()}`
            ];
            this.body = this.store.snapshot().settings.vcrm[
                `text_homepage_${this.translation.currentLang.toLowerCase()}`
            ];
        });

        this.settings$ = this.store.select(SettingsState);
        this.settings$.subscribe((settings) => {
            this.title = settings.vcrm[
                `title_homepage_${this.translation.currentLang.toLowerCase()}`
            ];
            this.body = settings.vcrm[
                `text_homepage_${this.translation.currentLang.toLowerCase()}`
            ];
        });
    }
}
