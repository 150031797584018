import {
    Component,
    OnInit,
    Output,
    Input,
    EventEmitter,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import * as _ from 'lodash';

/**
 * Custom table for corpus
 *
 * @export
 * @class CorpusTableSelectComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus-table-select',
    templateUrl: './corpus-table-select.component.html',
    styleUrls: ['./corpus-table-select.component.scss'],
})
export default class CorpusTableSelectComponent implements OnInit, OnDestroy {
    @Input()
        header;

    @Input()
        rows;

    @Input()
        selected = [];

    @Input()
        disabled = [];

    @Input()
        associated = [];

    @Input()
        multiSelect = false;

    @Input()
        isEnterPressable: boolean = true;

    @Input()
        tooltipMessage;

    @Input()
        tooltipIcon;

    @Input()
        keyPressActive: boolean = true;

    @Output()
        select = new EventEmitter<number>();

    @Output()
        remove = new EventEmitter<number>();

    @Output()
        enterpress = new EventEmitter();

    @Output()
        escapepress = new EventEmitter();

    @Output()
        overCol = new EventEmitter<number>();

    @Output()
        leaveCol = new EventEmitter();

    hoverCol: number;

    lastSelectedCol: number;

    isKeyboardNavigating: boolean;

    private colsWidth = [];

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        document.addEventListener('keydown', this.onKey);
        this.isKeyboardNavigating = false;
    }

    ngOnDestroy() {
        document.removeEventListener('keydown', this.onKey);
    }

    unselectCol(index) {
        this.removeIndex(index);
        this.remove.emit(index);
    }

    selectedCol(index) {
        if (!this.isDisabled(index)) {
            if (this.isSelected(index) && this.multiSelect) {
                this.unselectCol(index);
            } else {
                if (this.multiSelect) {
                    this.selected.push(index);
                } else {
                    this.selected = [index];
                }
                this.lastSelectedCol = index;
                this.select.emit(index);
            }
        }
    }

    enter(index) {
        this.overCol.emit(index);
        if (!this.isDisabled(index) && !this.isSelected(index) && !this.isKeyboardNavigating) {
            this.hoverCol = index;
        }
    }

    leave() {
        if (!this.isKeyboardNavigating) {
            this.hoverCol = null;
            this.leaveCol.emit();
        }
    }

    isSelected(index) {
        return _.includes(this.selected, index);
    }

    isDisabled(index) {
        return _.includes(this.disabled, index);
    }

    /**
   * Determine if index of associted column is active or not
   */
    isAssociated(index) {
        return _.includes(this.associated, index);
    }

    removeIndex(index) {
        const arrayIndex = this.selected.indexOf(index);
        if (arrayIndex > -1) {
            this.selected.splice(arrayIndex, 1);
        }
    }

    onKey = (e) => {
        if (this.keyPressActive) {
            switch (e.key) {
                case 'ArrowLeft': {
                    this.activeKeyboardNavigating();
                    this.addToHoverCol(-1);
                    break;
                }
                case 'ArrowRight': {
                    this.activeKeyboardNavigating();
                    this.addToHoverCol(1);
                    break;
                }
                case 'Enter': {
                    if (this.isEnterPressable && this.hoverCol !== null) {
                        this.selectedCol(this.hoverCol);
                        this.unactiveKeyboardNavigating();
                        this.enterpress.emit();
                    }
                    break;
                }
                case 'Escape': {
                    if (this.isEnterPressable === false) {
                        this.activeKeyboardNavigating();
                    }
                    if (this.lastSelectedCol !== null) {
                        this.selectedCol(this.lastSelectedCol);
                        this.lastSelectedCol = null;
                    }
                    this.escapepress.emit();
                    break;
                }
                default:
                    // none
            }
        }
    };

    onMousemove = () => {
        this.isKeyboardNavigating = false;
        this.elementRef.nativeElement.removeEventListener('mousemove', this.onMousemove);
    };

    addToHoverCol(num: number) {
        if (this.hoverCol == null) {
            this.setHoverColToVisibleCol();
        } else {
            if ((num > 0 && this.hoverCol + num <= this.header.length - 1) || (num < 0 && this.hoverCol + num >= 0)) {
                this.hoverCol += num;
            }
            if (this.isDisabled(this.hoverCol) || this.isSelected(this.hoverCol)) {
                this.addToHoverCol(num > 0 ? 1 : -1);
            }
            this.scrollTable();
        }
    }

    activeKeyboardNavigating() {
        this.isKeyboardNavigating = true;
        this.elementRef.nativeElement.addEventListener('mousemove', this.onMousemove);
    }

    unactiveKeyboardNavigating() {
        this.isKeyboardNavigating = false;
        this.elementRef.nativeElement.removeEventListener('mousemove', this.onMousemove);
    }

    /**
   * Scroll the table relatively to the current hover table column
   */
    scrollTable() {
        if (this.colsWidth.length !== this.header.length) {
            document.querySelectorAll('th').forEach((elem) => {
                this.colsWidth.push(elem.clientWidth);
            });
        }
        const scrollView = this.elementRef.nativeElement.querySelector('.scrollview');
        const shift = this.hoverCol > 0 ? -1 : 0;
        const scrollX = this.colsWidth.slice(0, this.hoverCol + shift).reduce(((accumulator, currentValue) => accumulator + currentValue), 0);
        scrollView.scrollLeft = scrollX;
    }

    /**
   * Set the hoverCol attribute to the number of the first visible column (from the left of the screen)
   */
    setHoverColToVisibleCol() {
        const scrollView = this.elementRef.nativeElement.querySelector('.scrollview');
        const scrollX = scrollView.scrollLeft;
        let firstVisibleElem = 0;
        let sumElem = 0;
        while (sumElem < scrollX || this.isDisabled(firstVisibleElem) || this.isSelected(firstVisibleElem)) {
            sumElem += this.colsWidth[firstVisibleElem];
            firstVisibleElem += 1;
        }
        this.hoverCol = firstVisibleElem;
        this.scrollTable();
    }
}
