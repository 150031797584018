// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-light-gray {
  background-color: #eee;
}

.number {
  width: 30px;
  height: 30px;
  font-size: 18px;
  font-weight: bold;
  padding-top: 4px;
  text-align: center;
  margin-left: 20px;
}
.number.bg-third {
  background-color: #666;
  color: #fff;
}

.disabled {
  position: relative;
}
.disabled ::after {
  position: absolute;
  content: " ";
  inset: 0;
  background-color: rgba(255, 255, 255, 0.3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
