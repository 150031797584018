import { HttpClient } from '@angular/common/http';
import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Files, GetCorpus } from 'app/api/models';
import { MSurveysAccount } from 'app/api/models/msurvey';
import { CorpusService } from 'app/api/services';
import CorpusState from 'app/stores/state/corpus/corpus.state';
import MsurveyService from 'app/utils/services/msurvey.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-msurvey-account',
    templateUrl: './msurvey-account.component.html',
    styleUrls: ['./msurvey-account.component.scss'],
})
export default class MsurveyAccountComponent {
    public msurveyId;

    public corpusId;

    public modalRef;

    public corpusType;

    msurveyLoginError: boolean = false;

    msurveyUpdateErrorMessage : string;

    @Input()
        fileUploadError: boolean = false;

    @Input()
        uploadEnable: boolean = false;

    corpusState$: Observable<GetCorpus>;

    @Output() msurveyLoginIsOk: EventEmitter<void> = new EventEmitter<void>();

    @Output() updateFileError: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private msurveyService: MsurveyService,
        private corpusService: CorpusService,
        private http: HttpClient,
        private modalService: NgbModal,
        private store: Store,
    ) {
        this.corpusState$ = this.store.select(CorpusState);
        this.corpusState$.subscribe((state) => {
            this.msurveyId = state.files[0].file_json_config.surveyId;
            this.corpusId = state.corp_id;
            // account vs url c'est dans le corpusType
            this.corpusType = state.corp_type;
        });
    }

    /**
   * Handeling Login modal
   * @param values
   */
    openLoginModal(e, content) {
        e.preventDefault();
        this.modalRef = this.modalService.open(content);
    }

    closeModal() {
        this.modalRef.close();
    }

    /**
   * Fired when MSurvey form is submitted
   * @param values : msurvey form values
   */
    async onLoginMsurvey(values: MSurveysAccount) {
        this.msurveyLoginError = false;
        this.updateFileError.emit(false);
        try {
            // on supprimer le fichier existant
            await this.msurveyService.deleteExistingFile().toPromise();

            // on demande le téléchargement du nouveau fichier
            await this.msurveyService.createFromId(this.msurveyId, values).toPromise();

            // on récupère le nom du fichier
            const files = await this.http.get('/api/v1/file', { withCredentials: true }).toPromise();
            const fileName = (files as Files).files[0].file_name;

            // on injecte le nouveau fichier dans le corpus
            await this.corpusService.patchV1CorpusCorpId({ corpId: this.corpusId, body: { filename: fileName } }).toPromise();

            this.msurveyLoginIsOk.emit();

            // on ferme la modale
            this.closeModal();
        } catch (error) {
            if (error?.error?.error === '400063') {
                this.msurveyLoginError = true;
            } else {
                this.closeModal();
                this.updateFileError.emit(true);
                this.msurveyUpdateErrorMessage = `translations.httpErrors.${error?.error?.error}`;
            }
        }
    }
}
