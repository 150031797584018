/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Period } from 'app/api/models/distriparams';
import accentless from 'app/utils/functions/accentless';
import download from 'app/utils/functions/download';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import ExportType from '../CommonExportTypeEnum';
import ManageDashboardService from './corpus-manage-dashboard.service';
import DashboardService from './corpus-dashboard.service';

export enum GraphTypeEnum {
    thematics = 'thematics',
    classification = 'classification',
}

@Injectable()
export default class GraphService {
    constructor(
        private translate: TranslateService,
        private chartTooltips: ChartTooltipsService,
        private manageDashboardService: ManageDashboardService,
    ) { }

    /**
     * Retourne le format à afficher en fonction de la période donnée
     */
    getPeriodFormat(period: Period): string {
        if (period === Period.week) {
            return `YYYY-MM [${this.translate.currentLang === 'fr' ? 's' : 'w'}]WW`;
        } if (period === Period.day) {
            return 'YYYY-MM-DD';
        }
        return 'YYYY-MM';
    }

    private getBounds(dashboardService: DashboardService) {
        let minBeginDate;
        let maxEndDate;

        if (!dashboardService) {
            minBeginDate = _.min(this.manageDashboardService.dashboardServices.map((service) => service.lastAppliedFilters.getValue().date_begin));
            maxEndDate = _.maxBy(this.manageDashboardService.dashboardServices.map((service) => service.lastAppliedFilters.getValue().date_end));
        } else {
            minBeginDate = dashboardService.lastAppliedFilters.getValue().date_begin;
            maxEndDate = dashboardService.lastAppliedFilters.getValue().date_end;
        }

        return { min: minBeginDate, max: maxEndDate };
    }

    /**
     * la période hebdomadaire pour les dashboard avec plus de 52 semaines est interdite
     */
    public isWeekAllowedForGraph(dashboardService: DashboardService) {
        const bounds = this.getBounds(dashboardService);
        return moment(bounds.max).diff(bounds.min, 'weeks') <= 52;
    }

    /**
     * la période quotidienne pour les dashboard avec plus de 62 jours est interdire
     */
    public isDayAllowedForGraph(dashboardService) {
        const bounds = this.getBounds(dashboardService);
        return moment(bounds.max).diff(bounds.min, 'days') <= 62;
    }

    getDefaultPeriod(initialPeriod = Period.day, dashboardService: DashboardService): Period {
        let period = initialPeriod;
        if (period === Period.day && !this.isDayAllowedForGraph(dashboardService)) {
            period = Period.week;
        }
        if (period === Period.week && !this.isWeekAllowedForGraph(dashboardService)) {
            period = Period.month;
        }
        return period;
    }

    /**
     * Retourne le nom du fichier correctement formatté (échappé avec des _ à la place des espaces)
     */
    // eslint-disable-next-line class-methods-use-this
    getFileName(name, extension = 'png'): string {
        return `${accentless(name).replace(/\s+/g, '_').toLowerCase()}.${extension}`;
    }

    /**
     * Export chart to PNG file
     */
    exportChartToPng(chart, title = 'image'): void {
        download(chart.chart.toBase64Image(), this.getFileName(title, ExportType.PNG), '_blank');
    }

    exportCanvasToPng(canvas, title = 'image'): void {
        download(canvas.toDataURL(), this.getFileName(title, ExportType.PNG), '_blank');
    }

    /**
     * Export data to CSV file
     */
    exportDataToCsv(data, headerList, title = 'export-results'): void {
        const csvData = this.convertToCSV(data, headerList);
        const blob = new Blob([`\ufeff${csvData}`], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        download(url, this.getFileName(title, ExportType.CSV));
    }

    // eslint-disable-next-line class-methods-use-this
    convertToCSV(array, headerList): string {
        let str = `${headerList.join(';')}\r\n`;
        array.forEach((lineObj) => {
            str
                += `${headerList
                    .map((header) => lineObj[header])
                    .join(';')}\r\n`;
        });
        return str;
    }

    /**
     * Truncate displayed labels ont graph axis
     * To be used in scales.xAxes.ticks.callback of chart options.
     */
    // eslint-disable-next-line class-methods-use-this
    labelEllipsisCallback(value, maxLength: number = 14) {
        if (value === '') {
            return this.translate.instant('translations.utils.empty');
        }

        return (`${value}`).length <= maxLength ? value : `${(`${value}`).substring(0, maxLength)}...`;
    }

    /**
     * Génère des tooltips personnalisés.
     * Ressemble aux tooltips normal mais permet d'attacher le tootlip sur le body (au lieu d'être dans le canvas)
     */
    customTooltips(unit = '') {
        const thisInt = this;
        return function (context) {
            let innerHtml = '';
            const tooltipModel = context.tooltip;
            if (tooltipModel.body) {
                const titleLines = tooltipModel.title;
                if (titleLines.length > 0) {
                    innerHtml += '<div class=\'tooltip-title\'>';
                    titleLines.forEach((title) => {
                        innerHtml += title;
                    });
                    innerHtml += '</div>';
                }
                tooltipModel.body.forEach((body, i) => {
                    innerHtml += `<div class='text-truncate'><span class='tooltip-legend' style='background:${tooltipModel.labelColors[i].backgroundColor}'></span>${body.lines[0]}${unit}</div>`;
                });
            }
            // eslint-disable-next-line no-underscore-dangle
            thisInt.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
        };
    }
}
