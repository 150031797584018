import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';

@Component({
    selector: 'app-toggle-all-filters',
    templateUrl: './toggle-all-filters.component.html',
    styleUrls: ['./toggle-all-filters.component.scss'],
})
export default class ToggleAllFiltersComponent {
    @Input()
        isFiltersOpened: boolean;

    @Output()
        toggle : EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        protected manageDashboardService: ManageDashboardService,
    ) {
    }

    /**
     * Evènement lorsque l'on clique sur le bouton.
     * Si au moins un filtre est ouvert (isFiltersOpened = true) alors on ferme tous les filtres
     * Si aucun filtre n'est ouvert (isFiltersOpened = false) alors on ouvre tous les filtres
     */
    public onToggleClick() {
        gtmClick({
            track_category: 'dashboard comparaison',
            track_name: 'panneaux des filtres',
            track_cible: this.isFiltersOpened ? 'réduire' : 'développer',
        });
        this.toggle.emit(!this.isFiltersOpened);
    }
}
