import { Component } from '@angular/core';
import DashboardService from '../corpus-dashboard.service';

@Component({
    selector: 'app-corpus-settings',
    templateUrl: './corpus-settings.component.html',
    styleUrls: ['./corpus-settings.component.scss'],
    providers: [DashboardService],
})
export default class CorpusSettingsComponent {
}
