// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  background-color: #eee;
}

.filter {
  display: block;
  background-color: white;
  margin-bottom: 10px;
  padding: 0 10px;
  border: solid transparent;
  border-width: 0 10px;
}
.filter > * {
  margin-bottom: 10px;
  padding: 0 15px;
}
.filter ::ng-deep .diff-content {
  display: none;
}

.filter-border {
  width: 59px;
  max-width: 345px;
}
.filter-border.open {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.filter-border.open .padder {
  height: 90px;
  background-color: #eee;
}
.filter-border.filter-diff .filter {
  position: relative;
  border-width: 20px 10px 10px;
  border-color: #ddd;
}
.filter-border.filter-diff .filter ::ng-deep .diff-content {
  position: absolute;
  inset: -20px 0 0;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  display: block;
}
.filter-border.filter-diff.blue .filter {
  border-color: #b5e8f7;
}
.filter-border.filter-diff.purple .filter {
  border-color: #d9c2f0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
