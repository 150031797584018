// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.word-container {
  position: relative;
  flex-grow: 1;
}

.sizer {
  color: white;
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
  top: 200px;
  left: 200px;
}

.frise {
  position: relative;
  height: 30px;
  z-index: 500;
  margin-top: 10px;
}

.deleted {
  margin: 2px;
  cursor: pointer;
}

.neg,
.neu {
  color: white;
}

.line-pointer {
  display: block;
  border-left-width: 2px;
  border-left-style: solid;
  width: 0;
  position: absolute;
  bottom: -20px;
  z-index: 56;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
