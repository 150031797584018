import {
    Component, ElementRef, HostBinding, Input, OnInit, ViewChild,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import FiltersToggleComponent from './components/filters-toggle/filters-toggle.component';

@Component({
    selector: 'app-vertical-filters',
    templateUrl: './vertical-filters.component.html',
    styleUrls: ['./vertical-filters.component.scss'],
})
export default class VerticalFiltersComponent extends BaseComponent implements OnInit {
    @HostBinding('attr.class')
    get className(): string {
        return this.isOpen ? 'open' : 'closed';
    }

    @Input()
        activatedFilters: string[] = [];

    @Input()
        dashboardIndex: number;

    @Input()
        isOpen: boolean = true;

    @ViewChild('filterToggle') public filterToggle: FiltersToggleComponent;

    constructor(
        private dashboardService: DashboardService,
        public elementRef: ElementRef,
    ) {
        super();
    }

    ngOnInit() {
        this.subs.sink = this.dashboardService.hasFilterChangesNotApplied.subscribe((hasFilterChangesNotApplied) => {
            if (hasFilterChangesNotApplied) {
                // Si les filtres changent, on ouvre systématiquement le bandeau des filtres
                this.isOpen = true;
            }
        });
    }

    isActivated(filterName: string): boolean {
        return this.activatedFilters.filter((f) => f === filterName).length > 0;
    }
}
