export interface HistoParams {
    yaxis?: Yaxis;
    xaxis_sort?:XaxisSort;
}

export enum Yaxis {
    percent = 'percent',
    occur = 'occur',
    deltasat = 'deltasat',
    deltatone = 'deltatone',
    nps = 'nps',
}

export enum XaxisSort {
    values_desc = 'values_desc', values_asc = 'values_asc', occur_desc = 'occur_desc', occur_asc = 'occur_asc',
}
