// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
}

.header {
  background-color: #eee;
  padding: 20px 15px;
}
.header .vcrm-avatar::before {
  font-size: 70px;
}
.header .user-name {
  font-size: 20px;
  font-weight: bold;
}
.header .user-email {
  font-size: 18px;
}

.content {
  padding: 25px 15px;
}
.content .title {
  font-size: 18px;
  font-weight: bold;
}
.content .authorization {
  margin-top: 30px;
}
.content .table {
  display: block;
  max-width: 1000px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
