import {
    Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output,
} from '@angular/core';
import { DashboardType } from 'app/api/models/dashboardforuser';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-filters-toggle',
    templateUrl: './filters-toggle.component.html',
    styleUrls: ['./filters-toggle.component.scss'],
})
export default class FiltersToggleComponent extends BaseComponent implements OnInit {
    totalFilter: number;

    all : number;

    filtersCount: number = 0;

    hasFilterOnLastSearch: boolean = false;

    isFilterNotApplied: boolean = false;

    @Input()
        isOpen: boolean = false;

    @Output()
        toggle : EventEmitter<boolean> = new EventEmitter<boolean>();

    @HostBinding('attr.class')
    get className(): string {
        return this.isOpen ? 'open' : 'closed';
    }

    // Récupère le numéro du dashboard
    get dashboardNumber():number {
        if (this.manageDashboardService.dashboardServices.length > 1) {
            return this.manageDashboardService.dashboardServices.indexOf(this.dashboardService) + 1;
        }
        return undefined;
    }

    constructor(
        private manageDashboardService: ManageDashboardService,
        private dashboardService:DashboardService,
        public element: ElementRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.hasFilterChangesNotApplied.subscribe((value) => {
            this.isFilterNotApplied = value;
        });
        this.subs.sink = this.dashboardService.filtersCount.subscribe((count) => {
            this.filtersCount = count;
        });

        this.subs.sink = this.dashboardService.dashboardData.subscribe((data) => {
            if (data !== null) {
                this.hasFilterOnLastSearch = this.dashboardService.filtersCount.getValue() > 0;
                if (this.dashboardService.currentDashboardType === DashboardType.ANALYSE) {
                    this.totalFilter = data.hits.total;
                    this.all = data.count_all_verbatim;
                } else {
                    this.totalFilter = data.general.count_verbatim;
                    this.all = data.general.count_all_verbatim;
                }
            }
        });
    }

    onToggle(value:boolean) {
        this.isOpen = value;
        this.toggle.emit(value);
    }
}
