import { BaseComponent } from 'app/base.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Yaxis } from 'app/api/models/histoparams';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { TranslateService } from '@ngx-translate/core';

export default abstract class AbstractLineChartComponent extends BaseComponent {
    constructor(chartTooltips: ChartTooltipsService, translateService: TranslateService) {
        super();
        this.chartTooltips = chartTooltips;
        this.translateService = translateService;
    }

    protected axis = {};

    protected labels = [];

    protected chartTooltips: ChartTooltipsService;

    protected translateService: TranslateService;

    protected distribution2Axis(distribution) {
        if (!distribution.values || distribution.values.length === 0) {
            return;
        }

        this.labels = [];
        this.axis = {};

        distribution.values.forEach((data) => {
            if (data.merged_values) {
                data.merged_values.forEach((mergedValue, dashboardNumber) => {
                    if (mergedValue && mergedValue.distribution.some((value) => value.doc_count !== null)) {
                        mergedValue.distribution.forEach((value, xIndex) => {
                            if (xIndex > this.labels.length - 1) {
                                this.labels.push({});
                            }
                            const period = distribution.config ? distribution.config.period : this.getLineChartConfig().period;
                            this.labels[xIndex][`dashboard_${dashboardNumber}`] = this.timeToLabel(value.key, period);
                        });
                    }
                });
            }
        });

        // on créée des labels vide là où il n'y a pas de données pour éviter les bugs d'affichage
        this.labels.forEach((label) => {
            [0, 1, 2].forEach((dashboardNumber) => {
                if (!Object.prototype.hasOwnProperty.call(label, `dashboard_${dashboardNumber}`)) {
                    label[`dashboard_${dashboardNumber}`] = '';
                }
            });
        });

        if (this.labels[0]) {
            // identification des axes uniques en fonction de la première valeur
            const dateByAxeIndex = _.uniq([0, 1, 2]
                .map((dashboardNumber) => this.labels[0][`dashboard_${dashboardNumber}`])
                .filter((value) => value));

            [0, 1, 2].forEach((dashboardNumber) => {
                if (Object.prototype.hasOwnProperty.call(this.labels[0], `dashboard_${dashboardNumber}`)) {
                    const axisIndex = _.indexOf(dateByAxeIndex, this.labels[0][`dashboard_${dashboardNumber}`]);
                    if (axisIndex >= 0) {
                        this.axis[`dashboard_${dashboardNumber}`] = `x${axisIndex}`;
                    }
                }
            });
        }

        // dashboards classés par axe
        const dashboardsByAxis = [0, 1, 2].map((axisIndex) => Object.keys(this.axis).filter((dashboard) => this.axis[dashboard] === `x${axisIndex}`));

        // on comble les trous en fusionnant les axes identiques qui ne sont pas forcément de même taille
        this.labels.forEach((label) => {
            dashboardsByAxis.forEach((dashboards) => {
                const uniqueValue = dashboards.map((dashboard) => label[dashboard]).filter((value) => value)[0] || '';
                dashboards.forEach((dashboard) => {
                    label[dashboard] = uniqueValue;
                });
            });
        });
    }

    abstract getGraphService();

    abstract getDashboardService();

    protected timeToLabel(key, period): string {
        const format = this.getGraphService().getPeriodFormat(period, this.getDashboardService());
        return moment.unix(parseInt(key, 10) / 1000).format(format);
    }

    abstract getLineChartConfig();

    // eslint-disable-next-line class-methods-use-this
    customTooltipsSideBySyde(context, thisInt) {
        let innerHtml = '';
        const tooltipModel = context.tooltip;

        if (tooltipModel.body) {
            const dataPoint = tooltipModel.dataPoints[0];
            innerHtml += `<div class='tooltip-title'> ${thisInt.labels[dataPoint.dataIndex].dashboard_0} </div>`;

            tooltipModel.dataPoints.forEach((data) => {
                innerHtml += `<div class='d-flex align-items-center'>
                                <div class='tooltip-legend flex-shrink-0' style='background:${data.dataset.pointBackgroundColor};'></div>
                                <div class='text-truncate'>${data.dataset.label}</div>
                                <div class='text-nowrap'>&nbsp;: ${(Math.round(data.parsed.y * 10) / 10)}${(thisInt.getLineChartConfig().yaxis || Yaxis.occur) !== Yaxis.occur ? '%' : ''}</div>
                             </div>`;
            });

            if (thisInt.isPointClickable()) {
                innerHtml += `<div class='mt-1'>${thisInt.translateService.instant('translations.analysisDashboard.charts.tooltip_evolution_chart_footer_info')}</div>`;
            }
        }

        thisInt.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
    }

    abstract isPointClickable(): boolean;

    abstract getPointValue(data): string;

    // eslint-disable-next-line class-methods-use-this
    customTooltipsMerged(context, thisInt) {
        let innerHtml = '';
        const tooltipModel = context.tooltip;

        if (tooltipModel.body) {
            // eslint-disable-next-line no-underscore-dangle
            const items = context.tooltip._tooltipItems;
            if (items.length > 0) {
                innerHtml += '<div class=\'tooltip-title\'>';
                innerHtml += context.tooltip.dataPoints[0].dataset.label;

                innerHtml += '</div>';

                const { dataIndex } = items[0];
                const datasets = thisInt.chartData.datasets.filter((dataset) => dataset.label === items[0].dataset.label);

                datasets.forEach((dataset) => {
                    const label = thisInt.labels[dataIndex][`dashboard_${dataset.dashboardNumber}`];
                    if (dataset.data.length > dataIndex && dataset.data[dataIndex] !== undefined && dataset.data[dataIndex] !== null) {
                        const value = thisInt.getPointValue(dataset.data[dataIndex]);
                        if (value && value !== 'null%') {
                            innerHtml += `<div class='d-flex align-items-center'>
                                    <div class='tooltip-legend tooltip-legend-merged flex-shrink-0' style='background:${dataset.pointBackgroundColor};color:${dataset.dashboardNumber === 1 ? 'black' : 'white'}'>${dataset.dashboardNumber + 1}</div>
                                    <div class='text-truncate'>${label}${thisInt.translateService.instant('translations.utils.colon')}&nbsp;${value}</div>
                                </div>`;
                        }
                    }
                });
                if (thisInt.isPointClickable()) {
                    innerHtml += `<div class='mt-1'>${thisInt.translateService.instant('translations.analysisDashboard.charts.tooltip_evolution_chart_footer_info')}</div>`;
                }
            }
        }

        thisInt.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
    }
}
