// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.words {
  margin-bottom: 30px;
  height: 100%;
}

.wordcloud {
  height: calc(100vh - 344px);
  overflow: hidden;
  padding: 15px 0;
  border-bottom: 1px solid #bbb;
}

.cloud-list-switch .btn {
  width: 30px;
  height: 30px;
}
.cloud-list-switch i::before {
  font-size: 24px;
}

select {
  height: 30px;
}

:host {
  height: 100%;
  width: 100%;
  display: inline-block;
}
:host .scrolltable {
  padding-top: 0 !important;
}
:host .wordcloud {
  overflow: visible !important;
}
:host ::ng-deep .tab-content {
  border-style: hidden !important;
  padding: 0;
  height: 100%;
}

.panel-title {
  height: 40px;
  position: relative;
}

.tab-buttons-container {
  position: relative;
}

.modal-dialog .modal-content .modal-body {
  padding: 0;
  overflow-y: hidden;
}

.size-select {
  width: unset;
}

.word-button-bar {
  display: flex;
  flex-wrap: wrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
