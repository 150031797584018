import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../api/services';

/**
 * Access request
 *
 * @export
 * @class RequestaccessComponent
 */
@Component({
    selector: 'app-requestaccess',
    templateUrl: './requestaccess.component.html',
})
export default class RequestaccessComponent {
    public isLoading: boolean = false;

    public emailValue: string;

    public success: boolean = false;

    public formError: boolean = true;

    @ViewChild('form')
        form: NgForm;

    constructor(private user: UserService) {
    }

    /**
   * Called when user submit form to send access
   */
    onSubmit(e) {
        e.preventDefault();
        if (this.form.valid) {
            this.isLoading = true;
            this.user.getV1UserEmailRequestaccount(this.emailValue).subscribe(() => {
                this.success = true;
            });
        } else {
            this.formError = true;
        }
    }
}
