import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import State from 'app/shared/components/footer/cookies-consent/cookies-consent-state';
import {
    ArcElement,
    BarController,
    BarElement,
    BubbleController,
    CategoryScale, Chart, DoughnutController, Legend, LinearScale, LineController, LineElement, PointElement, RadarController, RadialLinearScale, Tooltip, Filler,
} from 'chart.js';
import * as _ from 'lodash';
import { Spinkit } from 'ng-http-loader';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, map } from 'rxjs/operators';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import annotationPlugin from 'chartjs-plugin-annotation';
import { environment } from '../environments/environment';
import { User } from './api/models';
import { SettingsService, UserService } from './api/services';
import { BaseComponent } from './base.component';
import CookiesConsentService from './shared/components/footer/cookies-consent/cookies-consent.service';
import { SetVcrmSettings } from './stores/actions/settings/settings.actions';
import { SetUser } from './stores/actions/user/user.actions';
import UserState from './stores/state/user/user.state';
import { addEmptyDataMessage } from './utils/chartjs-plugins/add-empty-data-message';

/**
 * VCRM APP
 *
 * @export
 * @class AppComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
    providers: [NGXLogger],
})
export default class AppComponent extends BaseComponent implements OnInit {
    public isLogged = false;

    public user: User;

    public mobileMenuOpened: boolean = false;

    private settingsReq = false;

    public adminUrl = `${environment.api}/auth/login`;

    public spinkit = Spinkit;

    public isLoading: boolean;

    public username: string;

    public password: string;

    public error: boolean = false;

    public externalUrl: string;

    constructor(
        private store: Store,
        private settingsService: SettingsService,
        private logger: NGXLogger,
        private userService: UserService,
        private permissionsService: NgxPermissionsService,
        private cookiesConsentService: CookiesConsentService,
        private route : ActivatedRoute,
        private http: HttpClient,
        private router: Router,
        private titleService: Title,
        private translate: TranslateService,
    ) {
        super();
        const perm = [];
        this.permissionsService.loadPermissions(perm);

        /** Register Chart Element to use-it on app */
        Chart.register(
            LineElement,
            BarElement,
            PointElement,
            BarController,
            LineController,
            BubbleController,
            CategoryScale,
            LinearScale,
            DoughnutController,
            ArcElement,
            MatrixController,
            MatrixElement,
            RadarController,
            RadialLinearScale,
            Legend,
            Tooltip,
            Filler,
            annotationPlugin,
            addEmptyDataMessage.with(translate),
        );
    }

    // eslint-disable-next-line class-methods-use-this
    gaInit(status) {
        if (
            status === 'allow'
      || (status === 'dismiss' && !document.getElementById('gtmScript'))
        ) {
            // eslint-disable-next-line func-names
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                const f = d.getElementsByTagName(s)[0];
                const j: any = d.createElement(s);
                const dl = l !== 'dataLayer' ? `&l=${l}` : '';
                j.async = true;
                j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
                j.id = 'gtmScript';
                // f.parentNode.insertBefore(j, f.nextSibling);
                // changed to place the script after dataLayer declarations
                f.parentNode.insertBefore(j, f.nextSibling.nextSibling);
                // })(window, document, 'script', 'dataLayer', 'GTM-PJLFS4H');
            }(window, document, 'script', 'dataLayer', environment.id_gtm));
        }
    }

    ngOnInit() {
    // Gestion des titles sur toutes les pages (remplace MetaGuard de ngx-meta)
        const appTitle = this.titleService.getTitle();
        this.router
            .events.pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let child = this.route.firstChild;
                    while (child.firstChild) {
                        child = child.firstChild;
                    }
                    if (child.snapshot.data?.meta?.title) {
                        return child.snapshot.data.meta.title;
                    }
                    return appTitle;
                }),
            ).subscribe((ttl: string) => {
                this.titleService.setTitle(ttl);
            });

        // Quand on change de page, on scrol automatiquement la fenêtre en haut
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                window.scrollTo({ top: 0 });
            }
        });

        this.subs.sink = this.cookiesConsentService.state.subscribe((state) => {
            if (state === State.accepted) {
                this.logger.debug('cookies accepted => ga initialisation');
                this.gaInit('allow');
            }
        });

        this.settingsService.getV1UtilsSettings().subscribe((settingsDatas) => {
            this.store.dispatch(new SetVcrmSettings(settingsDatas));
        });

        const user$ = this.store.select(UserState);
        this.subs.sink = user$.subscribe((state: User) => {
            this.logger.info('Get user state');
            this.user = state;
            this.isLogged = false;
            if (!_.isEmpty(state)) {
                //       this.translate.use(this.user.preferred_language.toLowerCase());
                this.permissionsService.loadPermissions(this.user.roles);

                this.logger.info('User is logged');
                if (this.settingsReq === false) {
                    this.route.queryParams.subscribe((values) => {
                        setTimeout(() => {
                            if (values.externalUrl) {
                                this.downloadFile(values.externalUrl, values.filename || 'unknown');
                            }
                        });
                    });

                    this.logger.info('Get VCRM settings');
                    this.settingsReq = true;

                    this.userService
                        .getV1UserUsername(this.store.snapshot().user.username)
                        .subscribe((profil) => {
                            this.store.dispatch(new SetUser(profil));
                        });
                }
                this.isLogged = true;
            }
        });

        this.translate.onLangChange.subscribe(() => {
            document.querySelector('html').lang = this.translate.currentLang;
        });
    }

    downloadFile(route: string, filename: string = null): void {
        const headers = new HttpHeaders();
        this.http.get(route, { headers, responseType: 'blob' as 'json' }).subscribe(
            (response: any) => {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                if (filename) { downloadLink.setAttribute('download', filename); }
                document.body.appendChild(downloadLink);
                downloadLink.click();
            },
        );
    }
}
