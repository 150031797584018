/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

import { TranslateService } from '@ngx-translate/core';

export const addEmptyDataMessage = {
    id: 'addEmptyDataMessage',
    afterDatasetsDraw(chart) {
        if (chart.data.datasets.length === 0
            || chart.data.datasets[0].data.every((item) => item === 0)
            || chart.data.datasets[0].data.every((item) => item.hidden)
            || chart.data.datasets.every((dataset) => dataset.hidden)) {
            const { ctx, width, height } = chart;

            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(this.translateService?.instant('translations.utils.noData'), width / 2, height / 2);
            ctx.restore();
        }
    },

    with(translateService: TranslateService) {
        this.translateService = translateService;
        return this;
    },

};
