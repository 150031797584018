/**
 * Fonction permettant de créer un lien pour télécharger un fichier.
 * Clique automatiquement dessus pour lancer le téléchargement.
 * Supprime le lien ensuite
 */
function download(href: string, downloadName: string, target?: string) {
    const dwldLink = document.createElement('a');
    if (target) {
        dwldLink.setAttribute('target', target);
    }
    dwldLink.setAttribute('href', href);
    if (downloadName) {
        dwldLink.setAttribute('download', downloadName);
    }
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);

    dwldLink.addEventListener('click', (e) => {
        e.stopPropagation(); // permet de bloquer la propagation de l'évèment click
    });
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

export default download;
