import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { News } from 'app/api/models';
import { NewsType } from 'app/api/models/news';

@Component({
    selector: 'app-news-headline',
    templateUrl: './news-headline.component.html',
})
export default class NewsHeadlineComponent {
    NewsType = NewsType;

    @Input() newsList: News[];

    @Input() displayMoreLink: boolean = true;

    @Input() redirectLinkTooltip: string = '';

    @Output() redirectEvent = new EventEmitter<any>();

    constructor(
        private domSanitizer: DomSanitizer,
        private translate: TranslateService,
    ) { }

    get currentLanguage(): string {
        return (this.translate.currentLang || this.translate.defaultLang).toLowerCase();
    }

    get newsHeadline(): News {
        const newsHeadline = this.newsList.find((item) => item.isHeadline);
        if (newsHeadline?.translations[this.currentLanguage]) {
            return newsHeadline;
        }
        return undefined;
    }

    get newsHeadlineDescription(): SafeHtml {
        if (this.newsHeadline?.translations[this.currentLanguage]) {
            return this.domSanitizer.bypassSecurityTrustHtml(
                this.newsHeadline.translations[this.currentLanguage].description.replace(/\r\n/g, '<br />'),
            ) as string;
        }
        return '';
    }

    onRedirect() {
        this.redirectEvent.emit(this.newsHeadline.id);
    }
}
