import {
    Component, EventEmitter, OnDestroy, OnInit, Output,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import State from './cookies-consent-state';
import CookiesConsentService from './cookies-consent.service';

@Component({
    selector: 'app-cookies-consent',
    templateUrl: './cookies-consent.component.html',
    styleUrls: ['./cookies-consent.component.scss'],
})
export default class CookiesConsentComponent extends BaseComponent implements OnInit, OnDestroy {
    @Output()
        moreAboutCookies = new EventEmitter<void>();

    cookiesNotAccepted: boolean = false;

    constructor(private cookieConsentService: CookiesConsentService) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.cookieConsentService.state.subscribe((state: State) => {
            this.cookiesNotAccepted = (state === State.not_accepted_yet);
        });
    }

    openCookiesModal(event) {
        event.preventDefault();
        this.moreAboutCookies.emit();
    }

    accept() {
        this.cookieConsentService.accept();
    }
}
