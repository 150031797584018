import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export default class FixedValidationBarService {
    public confirm: Subject<boolean> = new Subject<boolean>();

    public showConformationMessage() {
        this.confirm.next(true);
    }
}
