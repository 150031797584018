// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar.fixed-bottom {
  background-color: #333 !important;
  border-top: 1px solid #ccc;
}

.btn-primary {
  margin-left: 20px;
}

.icon-tick-circle {
  color: #32c832 !important;
  margin-left: 20px;
}

.message {
  color: white;
  margin-right: auto;
}

.alertmessage {
  display: flex;
  width: 100%;
}

.nav-hidden {
  bottom: -100px;
}

.nav-visible {
  bottom: 0;
}

nav > div {
  height: 40px;
}

.alertmessage > div {
  margin-top: auto;
  margin-bottom: auto;
}

nav {
  height: 71px;
  padding: 0;
}

.close-icon {
  margin-right: 20px;
}

.close-confirm {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  color: white !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
