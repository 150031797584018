export default function drawDashboardNumber(ctx, value: string, x, y) {
    let backgroundColor = '#000';
    let textColor = '#fff';

    if (value === '2') {
        backgroundColor = '#ccc';
        textColor = '#000';
    } else if (value === '3') {
        backgroundColor = '#666';
    }

    /// lets save current state as we make a lot of changes
    ctx.save();

    /// draw text from top - makes life easier at the moment
    ctx.textBaseline = 'top';

    /// color for background
    ctx.fillStyle = backgroundColor;

    const { width } = ctx.measureText(value);
    const size = parseInt(ctx.font.match(/\d+/), 10);

    const padding = 2;

    const rectX = x + 5;
    const rectY = y - size / 2 - padding;
    const rectW = size + 2 * padding;
    const rectH = size + 2 * padding;

    const textX = rectX + size / 2 - width / 4;
    const textY = rectY + 2 * padding;

    /// draw background rect assuming height of font
    ctx.fillRect(rectX, rectY, rectW, rectH);

    /// text color
    ctx.fillStyle = textColor;

    /// draw text on top
    ctx.fillText(value, textX, textY);
    /// restore original state
    ctx.restore();
}
