import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CopcData } from 'app/api/models/copcdata';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-copc',
    templateUrl: './copc.component.html',
    styleUrls: ['./copc.component.scss'],
})
export default class CopcComponent extends BaseComponent implements OnInit {
    @ViewChild('copcModal')
    private copcModal: TemplateRef<any>;

    private data: CopcData;

    private types = ['csat', 'dsat', 'tsat', 'ces', 'tr', 'fcr', 'reit'];

    private modal;

    constructor(
        private dashboardApi: DashboardApi,
        private dashboardService: DashboardService,
        private modalService: NgbModal,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard) {
                this.loadCopcData();
            }
        });

        this.subs.sink = this.dashboardService.newFiltersIsApplied.subscribe(() => {
            this.loadCopcData();
        });
    }

    loadCopcData(): void {
        this.subs.sink = this.dashboardApi.getCopcData(
            this.dashboardService.currentDashboard.getValue().dash_id,
            this.dashboardService.currentConfig.getValue().copc.period,
            this.dashboardService.lastAppliedFilters.getValue(),
        ).subscribe((data) => {
            this.data = data;
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modal = this.modalService.open(template);
    }
}
