// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
:host .title-page {
  padding: 5px 3px 5px 10px;
  margin-left: 10px;
  border-left: 1px solid #ccc;
}
:host .sep-icon::before {
  font-size: 22px;
  line-height: 1px;
  position: relative;
  top: 3px;
}
:host .project-list {
  margin: 0 5px 0 10px;
}
:host .project-list > .btn {
  min-width: 335px;
  max-width: 340px;
  justify-content: space-between;
}
:host .project-list.show > .btn {
  background-color: #000;
  color: #fff;
  border-color: #ccc;
}
:host .project-list .dropdown-menu {
  min-width: 335px;
  max-width: 580px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
:host .project-list .dropdown-menu .section {
  background-color: #ccc;
  color: #000;
}
:host .project-list .dropdown-menu .dropdown-item:not(.section) {
  font-weight: normal;
}
:host .project-list .dropdown-menu .dropdown-item:not(.section) .icon {
  display: inline-block;
  width: 25px;
  font-size: 20px;
  vertical-align: bottom;
  color: #ff7900;
}
:host .project-list .dropdown-menu .dropdown-item:not(.section).is-selected {
  text-decoration: none;
  background-color: #000;
  color: #fff;
}
:host .btn-icon {
  padding: 1px;
}
:host .btn-icon i::before {
  font-size: 24px;
  top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
