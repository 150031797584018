import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'app/base.component';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
})
export default class PrivacyPolicyComponent extends BaseComponent implements OnInit {
    protected lang: string = 'fr';

    constructor(
        public modalRef: NgbActiveModal,
        public translate: TranslateService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.lang = (this.translate.currentLang || this.translate.defaultLang).toLowerCase();
        this.subs.sink = this.translate.onLangChange.subscribe(() => {
            this.lang = this.translate.currentLang;
        });
    }

    close() {
        this.modalRef.close();
    }
}
