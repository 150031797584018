import {
    AfterViewInit, Component,
    EventEmitter, Input,
    OnChanges,
    OnInit,
    Output, QueryList,
    SimpleChanges,
    TemplateRef, ViewChild,
} from '@angular/core';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { ThematicService } from '../../../api/services';

@Component({
    selector: 'app-thematics-list',
    templateUrl: './thematics-list.component.html',
    styleUrls: ['./thematics-list.component.scss'],
})
export default class ThematicsListComponent implements OnInit, AfterViewInit, OnChanges {
    @Input()
        dataSource;

    @Input()
    public themesListCollapse: boolean = false;

    @Input()
    public scrollable = true;

    private count = 0;

    // eslint-disable-next-line class-methods-use-this
    public get config(): any {
        return {
            hasDetails: true,
            detailProp: 'them_definition',
            collapseAllDetailsTooltipToTranslate: 'translations.analysisDashboard.themes.details_tooltip',
            style: {
                maxHeight: this.scrollable ? '400px' : 'unset',
                'overflow-y': this.scrollable ? 'scroll' : 'unset',
            },
        };
    }

    public columns: Array<any> = [];

    get selecto() {
        return {
            prop: 'checked',
            cellTemplate: this.selectorRowTpl,
            headerTemplate: this.selectorColTpl,
            title: 'F',
            sort: true,
            style: {
                width: '60px',
            },
        };
    }

    getCount() {
        return this.dataSource.filter((thematic) => thematic.checked).length;
    }

    get favo() {
        return {
            prop: 'is_favorite',
            cellTemplate: this.favRowTpl,
            headerTemplate: this.favColTpl,
            title: 'F',
            sort: true,
            style: {
                width: '50px',
            },
        };
    }

    private name = {
        prop: 'them_name',
        title: 'translations.utils.name',
        sort: true,
        wordWrap: true,
        style: {
            'word-break': 'break-word',
        },
    };

    private user = {
        prop: 'usernameOwner',
        title: 'translations.analysisDashboard.themes.author',
        sort: true,
        style: {
            width: '30%',
        },
    };

    private nb_users = {
        prop: 'nb_users',
        title: 'translations.analysisDashboard.themes.users',
        sort: true,
        style: {
            width: '10%',
        },
        dataType: 'number',
    };

    private lang = {
        prop: 'them_lang',
        title: 'translations.thematics.language',
        sort: true,
        style: {
            width: '120px',
        },
    };

    private update = {
        prop: 'them_update_date',
        title: 'translations.analysisDashboard.themes.last_update',
        sort: true,
        style: {
            width: '180px',
        },
    };

    get actions() {
        return {
            title: '',
            cellTemplate: this.actionsRowTpl,
            style: {
                width: '150px',
            },
        };
    }

    @Input() type: string = '';

    get perso(): boolean {
        return this.type === 'perso';
    }

    @Input() admin: boolean = false;

    @Output() selected = new EventEmitter<any>();

    @Output() collapse = new EventEmitter<boolean>();

    @Output() deleteEmitter = new EventEmitter<any>();

    @Output() editEmitter = new EventEmitter<any>();

    @ViewChild('favRowTpl') favRowTpl: TemplateRef<any>;

    @ViewChild('favColTpl') favColTpl: TemplateRef<any>;

    @ViewChild('actionsRowTpl') actionsRowTpl: TemplateRef<any>;

    @ViewChild('selectorRowTpl', { read: TemplateRef }) selectorRowTpl: QueryList<any>;

    @ViewChild('selectorColTpl', { read: TemplateRef }) selectorColTpl;

    constructor(
        private thematicService: ThematicService,
    ) {}

    ngOnInit(): void {
        this.initColumns();
        this.count = this.getCount();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource) {
            this.count = this.getCount();
        }
    }

    ngAfterViewInit(): void {
        this.initColumns();
    }

    initColumns() {
        const columns = [this.favo, this.name, this.user, this.nb_users, this.lang, this.update];

        if (this.perso) {
            columns.splice(2, 1);
        }

        this.columns = (this.admin) ? [...columns, this.actions] : [this.selecto, ...columns];
    }

    select(row) {
        row.checked = !row.checked;
        this.count = this.getCount();
        this.selected.emit(row);
    }

    fav(id, i) {
        this.thematicService.postV1ThematicThemIdFavorite(id).subscribe(() => {
            this.dataSource[i].is_favorite = 1;
            this.dataSource = [...this.dataSource];
        });
    }

    unFav(id, i) {
        this.thematicService.deleteV1ThematicThemIdFavorite(id).subscribe(() => {
            this.dataSource[i].is_favorite = 0;
            this.dataSource = [...this.dataSource];
        });
    }

    shareAction(element, i) {
        if (element.them_shared === 1 || element.them_shared === true) {
            this.thematicService.patchV1ThematicThemIdUnshare(element.them_id).subscribe(() => {
                this.dataSource[i].them_shared = 0;
                this.dataSource = [...this.dataSource];
            });
            gtmClick({
                track_category: 'gestion thématique',
                track_name: 'stop partage thématique',
            });
            return;
        }
        if (element.them_shared === 0 || element.them_shared === false) {
            this.thematicService.patchV1ThematicThemIdShare(element.them_id).subscribe(() => {
                this.dataSource[i].them_shared = 1;
                this.dataSource = [...this.dataSource];
            });
            gtmClick({
                track_category: 'gestion thématique',
                track_name: 'partage thématique',
            });
        }
    }

    deleteAction(element) {
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'supprimer thématique',
        });
        this.deleteEmitter.emit(element);
    }

    editAction(element) {
        this.editEmitter.emit(element);
    }

    toggleCollapse() {
        this.themesListCollapse = !this.themesListCollapse;
        this.collapse.emit(this.themesListCollapse);
    }
}
