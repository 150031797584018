import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import {
    Column, ColumnSearchparams, Datasresults, SearchResults, Valuefrequency,
} from '../../../api/models';
import CorpusService from '../../../api/services/corpus.service';

@Component({
    selector: 'app-free-search',
    templateUrl: './free-search.component.html',
    styleUrls: ['./free-search.component.scss'],
})
export default class FreeSearchComponent {
    public search: string = '';

    @Input() placeholder: any;

    @Input() columns: Array<any> = [];

    @Input() verbatimDBName: string;

    @Input() appGtm_clean;

    ngModel: any = '';

    @Input() displayColumns: Array<any> = [];

    @Input() makeColumns: Column;

    @Input() findDisplayValues: Array<Valuefrequency> = [];

    @Input() columnsMore100Array: Array<string> = [];

    @Input() ArrayOfMore100ValuesSave: Array<number> = [];

    @Input() dashboardType: string = '';

    @Input() cleanSearchBoolean: boolean = false;

    @Output() searchEmitter = new EventEmitter<any>();

    @Output() searchFlag = new EventEmitter<any>(); // IB

    @Output() searchEmitterBoolean = new EventEmitter<any>(); // IB

    @Output() searchEmitterMore100Values = new EventEmitter<any>(); // IB

    @Output() searchEmitterCurrentPageArray = new EventEmitter<any>(); // IB

    @Output() searchEmitterClean = new EventEmitter<any>(); // IB

    @Output() customSearchEmitter = new EventEmitter<any>();

    public ArrayOfBoolean: Array<Boolean> = []; // Same index as displayColumns so different after search

    public ArrayOfMore100Values: Array<number> = []; // Same index as displayColumns so different after search

    public currentPageArray: Array<number> = []; // Same index as displayColumns so different after search

    public isResultsOfSearch: boolean = false;

    public limitByPage = 100;

    public fieldColumn: Array<string> = [];

    dataPage: ColumnSearchparams = {
        freesearch: '',
        output_columns: this.fieldColumn,
        dashboard_type: this.dashboardType,
    };

    dataFromSearch: SearchResults = {};

    arrayOfDatasResults: Array<Datasresults> = [];

    arrayOfDatasResults_inputName: Array<string> = []; // à remplir en même temps que celui en heut pour retrouver l'index de la colonne

    constructor(
        private modalService: BsModalService,
        private corpusService: CorpusService,
        private store: Store,
    ) {

    }

    inputValueChanged = _.debounce(() => {
        this.onGetDatasOfResultsForMore100Values(this.ngModel);
    }, 1000);

    onGetDatasOfResultsForMore100Values(searchModel) {
    // Récupération du résultat des search sur des colonnes de plus de 100 résultats
        //    console.log('searchModel: ', searchModel);
        // console.log('dans freesearch columns: ');
        // console.log(this.columns);
        // console.log('dans freesearch columnsMore100Array: ');
        // console.log(this.columnsMore100Array);
        if (searchModel.length > 0) { // si ma recherche n'est pas vide
            if (this.columnsMore100Array.length > 0) { // si le tableau contenant le nom des columns avec plus de 100 valeurs n'est pas vide
                this.dataFromSearch = {};
                this.arrayOfDatasResults = [];
                this.arrayOfDatasResults_inputName = [];
                this.dataPage = {
                    freesearch: searchModel,
                    output_columns: this.columnsMore100Array,
                    dashboard_type: this.dashboardType,
                };
                //    console.log(this.columnsMore100Array);
                // console.log('i am on search pagination: ' + this.dashboardType);
                let i = 0;
                this.corpusService
                    .postV1CorpusCorpIdColumnsSearch({
                        corpId: this.store.snapshot().corpus.corp_id,
                        verbatimDbName: this.verbatimDBName,
                        body: this.dataPage,
                    })
                    .subscribe((data) => {
                        this.dataFromSearch = data;
                        while (Object.keys(data.search_results)[i]) {
                            const index = Object.keys(this.dataFromSearch.search_results)[i];
                            // on rempli le tableau des résultats avec les réponses de l'api du search sur les columns de plus de 100 résultats
                            this.arrayOfDatasResults.push(this.dataFromSearch.search_results[index]);
                            this.arrayOfDatasResults_inputName.push(this.dataFromSearch.search_results[index].inputName);
                            i += 1;
                            // console.log('lenght of array: ', i);
                        }
                        //        console.log('defect414464');
                        //      console.log(this.arrayOfDatasResults);
                        // console.log('myArray2: ', this.arrayOfDatasResults_inputName[2]);
                        this.onSearch(this.ngModel);
                    });
            } else {
                this.onSearch(this.ngModel);
            }
        } else {
            this.onSearch(this.ngModel);
        }
    }

    onSearch(searchModel) {
    // ------------------------------------ IB ---------------------------
    //   console.log('searchModel dans onsearch de freesearch');
    //    console.log(searchModel);
    // console.log('dans freesearch onSearch arrayOfDatasResults: ');
    // console.log(this.arrayOfDatasResults);

        if (searchModel.length > 0) {
            const findDisplayColumns = [];
            const findR = new RegExp(searchModel, 'gimu');
            this.displayColumns = [];
            this.ArrayOfBoolean = [];
            this.ArrayOfMore100Values = [];
            this.currentPageArray = []; // this array fill in the same time that displayColumns has the same index, and tell the currentPage of pagination

            //------------------------------------------

            this.columns.forEach((col) => {
                // console.log('colonne is: ' + col.inputName);
                this.findDisplayValues = [];
                this.makeColumns = {};
                this.makeColumns.values = [];
                let find = false;
                findR.lastIndex = 0;
                // Pour savoir si la colonne est numérique (et donc pas de search dans elasticsearch)
                let searchByApi = false;

                this.store.snapshot().corpus.corp_fields.forEach((item) => {
                    if (item.inputName === col.inputName) {
                        // console.log(item.digitalFilter);
                        if (item.digitalFilter) {
                            searchByApi = false;
                        } else searchByApi = true;
                    } else {
                        this.store.snapshot().corpus.corp_fields_not_in_file.forEach((item2) => {
                            if (item2.inputName === col.inputName) {
                                // console.log(item.digitalFilter);
                                if (item2.digitalFilter) {
                                    searchByApi = false;
                                } else searchByApi = true;
                            }
                        });
                    }
                });
                // ------------------------------------------------------

                // console.log('est ce que this.columnsMore100Array.length > 0 : ');
                // console.log(this.columnsMore100Array.length > 0);
                // console.log('est ce que this.columnsMore100Array.includes(col.inputName) : ');
                // console.log(this.columnsMore100Array.includes(col.inputName));
                // console.log('searchByApi :');
                // console.log(searchByApi);

                // est-ce que le tableau des columns contenant plus de 100 values n'est pas vide et la colonne sur laquelle on est apparaît dans ce tableau, et la colonne n'est pas numérique
                if (this.columnsMore100Array.length > 0 && this.columnsMore100Array.includes(col.inputName) && searchByApi) {
                    // console.log('dans freesearch search quelle colonne ok avec les conditions: ');
                    // console.log(col.inputName);
                    if (findR.test(col.displayName)) { // search match with the title column
                        find = true; // column match the search
                    }
                    // console.log('col.inputName: ', col.inputName);
                    const indexOf = this.arrayOfDatasResults_inputName.indexOf(col.inputName);
                    // console.log('index of: ', this.arrayOfDatasResults_inputName.indexOf(col.inputName));
                    if (indexOf > -1) {
                        this.arrayOfDatasResults[indexOf].values.forEach((val) => {
                            this.findDisplayValues.push(val);
                        });
                    }

                    if (this.findDisplayValues.length > 0) { // on a des valeurs résultants du search, on recrée la nouvelle colonne avec uniquement ces valeurs
                        // console.log('this.findDisplayValues.length > 0');
                        if (indexOf > -1) {
                            this.makeColumns.inputName = this.arrayOfDatasResults[indexOf].inputName;
                            this.makeColumns.displayName = this.arrayOfDatasResults[indexOf].displayName;
                        }

                        this.findDisplayValues.forEach((item) => {
                            this.makeColumns.values.push(item);
                        });
                        // Si false il n'y a pas plus de 100 résultats, donc il faut savoir combien de values pour la valeur distinct_values_count de la nouvelle colonne
                        if (!this.arrayOfDatasResults[indexOf].more) {
                            // console.log(data.search_results[index].values.length);
                            this.makeColumns.distinct_values_count = this.arrayOfDatasResults[indexOf].values.length;
                            this.ArrayOfMore100Values.push(this.makeColumns.distinct_values_count); // il y a moins de 100 valeurs, on met le nombre exact
                        } else {
                            this.makeColumns.distinct_values_count = 101;
                            this.ArrayOfMore100Values.push(101); // il y a plus de 100 valeurs donc pagination
                        }
                        findDisplayColumns.push(this.makeColumns);
                        this.currentPageArray.push(1);
                        this.ArrayOfBoolean.push(true);
                        this.makeColumns = {};
                        this.makeColumns.values = [];
                        this.findDisplayValues = [];
                    } else if (find) { // only the title match
                        findDisplayColumns.push(col);
                        this.ArrayOfMore100Values.push(1);
                        this.currentPageArray.push(1);
                        this.ArrayOfBoolean.push(false);
                    }
                } else {
                    // console.log('colonne is dans else colonne moins de 100: ' + col.inputName);
                    if (findR.test(col.displayName)) { // search match with the title column
                        find = true; // column match the search
                        col.values.forEach((val) => {
                            findR.lastIndex = 0;
                            if (findR.test(val.key)) { // search match with the value key
                                this.findDisplayValues.push(val);
                            }
                        });
                    } else { // the title does not match the search
                        col.values.forEach((val) => {
                            findR.lastIndex = 0;
                            if (findR.test(val.key)) { // search match with the value key
                                this.findDisplayValues.push(val);
                            }
                        });
                    }
                    if (this.findDisplayValues.length > 0) { // there are values that match search
                        let countValues = 0; // remise à zéro pour chaque nouvelle column
                        this.makeColumns.inputName = col.inputName;
                        this.makeColumns.displayName = col.displayName;
                        this.findDisplayValues.forEach((item) => {
                            this.makeColumns.values.push(item);
                            countValues += 1; // donne le nombre de valeurs de la nouvelle colonne avec uniquement les valeurs répondant à la recherche
                        });
                        this.makeColumns.distinct_values_count = countValues;
                        this.ArrayOfMore100Values.push(this.makeColumns.distinct_values_count);
                        findDisplayColumns.push(this.makeColumns);
                        this.currentPageArray.push(1);
                        this.ArrayOfBoolean.push(true);
                        this.makeColumns = {};
                        this.makeColumns.values = [];
                        this.findDisplayValues = [];
                    } else if (find) { // only the title match
                        findDisplayColumns.push(col);
                        this.ArrayOfMore100Values.push(1);
                        this.currentPageArray.push(1);
                        this.ArrayOfBoolean.push(false);
                    }
                }
            });

            this.displayColumns = findDisplayColumns;
            this.isResultsOfSearch = true;

            // -------------------------------------IB----------------------------
        } else {
            //      console.log('je suis là on search free search');
            // this.ngModel = '';
            this.isResultsOfSearch = false;
            this.searchEmitterClean.emit(true);
            this.ArrayOfBoolean = [];
            for (let i = 0; i < this.columns.length; i += 1) {
                this.ArrayOfBoolean.push(false);
                this.currentPageArray.push(1);
            }
            this.displayColumns = this.columns;
            this.ArrayOfMore100Values = this.ArrayOfMore100ValuesSave;
        }

        this.searchEmitter.emit(this.displayColumns);
        this.searchFlag.emit(this.isResultsOfSearch);
        this.customSearchEmitter.emit(this.ngModel);
        this.searchEmitterBoolean.emit(this.ArrayOfBoolean);
        this.searchEmitterMore100Values.emit(this.ArrayOfMore100Values);
        this.searchEmitterCurrentPageArray.emit(this.currentPageArray);
    }

    cleanSearch() {
        if (this.appGtm_clean) {
            gtmClick(this.appGtm_clean);
        }

        //  console.log('i am here 2');
        this.ngModel = '';
        this.isResultsOfSearch = false;
        this.searchEmitterClean.emit(true);
        this.ArrayOfBoolean = [];
        for (let i = 0; i < this.columns.length; i += 1) {
            this.ArrayOfBoolean.push(false);
            this.currentPageArray.push(1);
        }
        this.displayColumns = this.columns;
        this.ArrayOfMore100Values = this.ArrayOfMore100ValuesSave;
        this.onSearch('');
    }
}
