import {
    ChangeDetectorRef,
    Component, ComponentRef, ElementRef, HostBinding, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { BaseComponent } from 'app/base.component';
import DashboardTabs from 'app/utils/models/dashboard-tabs';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import CorpusService from 'app/utils/services/corpus.service';
import DashboardScrollDirective from 'app/shared/directives/dashboard-scroll.directive';
import { DashboardSection, DashboardType, Dashboardforuser } from 'app/api/models';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { ResetDashModified, SetDashModified } from '../../../stores/actions/settings/settings.actions';
import GraphService from '../corpus-graph.service';
import ManageDashboardService from '../corpus-manage-dashboard.service';
import CorpusDashboardComponent from './components/corpus-dashboard.component';
import ChatService from '../corpus-chat.service';

/**
 * Corpus analysis component
 *
 * @export
 * @class CorpusAnalysesComponent
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-corpus-analyses',
    templateUrl: './corpus-analyses.component.html',
    styleUrls: ['./corpus-analyses.component.scss'],
    animations: [fadeInAnimation],
    providers: [GraphService, ChatService],
})
export default class CorpusAnalysesComponent extends BaseComponent implements OnDestroy, OnInit {
    @HostBinding('attr.class')
    get className(): string {
        return !this.activeBtnApplyDisabled ? 'validation-bar-open' : '';
    }

    @ViewChild('noThemeSelected') private noThemeSelected: TemplateRef<any>;

    @ViewChild(DashboardScrollDirective) private dashboardScrollDirective: DashboardScrollDirective;

    @ViewChild('verticalFiltersToggle') private verticalFiltersToggleEl: ElementRef;

    // élément qui va accueillir les dashboard et les filrtes et qui sera vidé pour être déversé dans les container horizontaux
    @ViewChild('dashboardsHost', { read: ViewContainerRef, static: true }) private dashboardsHostEl: ViewContainerRef;

    @ViewChild('verticalFiltersContent') public verticalFiltersContentEl: ElementRef;

    @ViewChild('analysesDashboard') private analysesDashboard: ElementRef;

    public activeBtnApplyDisabled: boolean = true;

    public dashboardActiveTab = DashboardTabs.EXPLORE_VERBATIM;

    private isVerbatimColumnChange = false;

    public dashboardHasNoData = false;

    dashboardLoaded = true;

    dashboardComponents: ComponentRef<CorpusDashboardComponent>[] = [];

    // Pour savoir si le dashboard courant a des classifications, on regarde uniquement le 1er dashboard (car les suivants auront la même info)
    get hasClassification() {
        if (this.manageDashboardService.firstDashboardService) {
            return !!this.manageDashboardService.getCurrentModel();
        }
        return false;
    }

    // Regarde si au moins 1 dashboard à des données ou si personne n'en a
    get dashboardHasData() {
        return this.manageDashboardService.dashboardServices.reduce((acc, service) => acc || service.dashboardData.getValue()?.hits.total !== 0, false);
    }

    // Détermine si au moins un filtre est ouvert ou non
    get isFiltersOpened() {
        return this.dashboardComponents.reduce((acc, dashComp) => acc || dashComp.instance.verticalFilters?.isOpen, false);
    }

    get currentModel() {
        return this.manageDashboardService.getCurrentModel()?.model ?? '';
    }

    constructor(
        private store: Store,
        private corpusService: CorpusService,
        private modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        public manageDashboardService: ManageDashboardService,
        private elementRef: ElementRef,
    ) {
        super();
    }

    ngOnDestroy(): void {
        document.body.style.overflow = 'auto';

        (document.getElementsByTagName('app-corpus')[0] as HTMLElement).style.overflow = 'hidden';
        this.store.dispatch(new ResetDashModified());

        this.dashboardComponents.forEach((component) => {
            component.destroy();
        });

        super.ngOnDestroy();
    }

    ngOnInit(): void {
        // pour éviter les doubles ascenseurs liés aux tooltips (sur cette page uniquement)
        document.body.style.overflow = 'hidden';

        this.store.dispatch(new ResetDashModified());
        (document.getElementsByTagName('app-corpus')[0] as HTMLElement).style.overflow = 'unset';

        // lorsqu'un corpus est chargé, on charge tous les dashboards associés
        this.subs.sink = this.corpusService.currentCorpus.subscribe(async (corpus) => {
            if (corpus !== null) {
                this.manageDashboardService.loadDashboardsAfterCorpus(DashboardType.ANALYSE);
            }
        });

        // lorsque l'on sélectionne un dashboard ou une comparaison de dashboard
        this.subs.sink = this.manageDashboardService.dashOrCompSelected.subscribe((dashboards) => {
            this.loadDashboardAnalyse(dashboards);
        });
    }

    /**
     * Charge une liste de dashboards analyse (un: mode analyse classique, plusieurs: mode comparaison)
     */
    public loadDashboardAnalyse(dashboards: Dashboardforuser[]) {
        this.dashboardLoaded = true;
        for (let i = this.dashboardComponents.length - 1; i >= 0; i -= 1) {
            this.removeDashboardToAnalyse(i);
        }

        for (let i = 0; i < dashboards.length; i += 1) {
            this.addDashboardToAnalyse(dashboards[i]);
        }

        this.manageDashboardService.manageMergedThematics();
        this.manageDashboardService.manageDifferenceBetweenFilters();
        this.manageDashboardService.dashboardLoaded.emit();
        this.dashboardScrollDirective?.scrollToTop();
        this.dashboardLoaded = false;
    }

    /**
     * Ajoute un dashboard à l'analyse en cours
     */
    public addDashboardToAnalyse(dashboard: Dashboardforuser) {
        const componentRef = this.dashboardsHostEl.createComponent(CorpusDashboardComponent);
        this.dashboardComponents.push(componentRef);
        const index = this.dashboardComponents.length - 1;
        componentRef.instance.getDashboardService().selectDashboardObject(dashboard);

        /** mise à jour du bouton pour appliquer les changement */
        this.subs.sink = this.dashboardComponents[index].instance.getDashboardService().hasFilterChangesNotApplied.subscribe(() => {
            this.activeBtnApplyDisabled = !this.dashboardComponents.some((component) => component.instance.getDashboardService().hasFilterChangesNotApplied.getValue());
        });

        this.changeDetectorRef.detectChanges();
        // Au chargement d'un dashboard, on ouvre les filtres si on est en mode classique sinon on les ferme si on est en comparaison
        componentRef.instance.verticalFilters.isOpen = !this.manageDashboardService.isDashboardComparison;
        // Déplace l'élément vertical-filters-toggle
        this.verticalFiltersToggleEl.nativeElement.appendChild(this.dashboardComponents[index].instance.verticalFilters.filterToggle.element.nativeElement);

        this.dashboardComponents[index].instance.verticalFilters.elementRef.nativeElement.querySelectorAll('.filter-element').forEach((element: HTMLElement) => {
            const targetElement = this.elementRef.nativeElement.querySelector(`#${element.getAttribute('data-target-id')}`);
            if (targetElement) {
                targetElement.appendChild(element);
            }
        });

        // Déplace les sous-composants du dashboard dans un div dont l'id est identique à l'attribut data-target-id
        this.dashboardComponents[index].instance.dashboardRef.nativeElement.querySelectorAll('.dashboard-element').forEach((element: HTMLElement) => {
            const targetElement = this.analysesDashboard.nativeElement.querySelector(`#${element.getAttribute('data-target-id')}`);
            if (targetElement) {
                element.classList.add('d-block', 'w-100', 'overflow-hidden');
                if (targetElement.getAttribute('data-lazyload-on-scroll')) {
                    element.classList.add('is-loading');
                    targetElement.setAttribute('data-need-request', 'true');
                }
                targetElement.appendChild(element);
            }
        });
    }

    /**
     * Supprime un dashboard de l'analyse en cours (en mode comparaison uniquement)
     */
    public removeDashboardToAnalyse(index: number) {
        // Suppression des éléments déplacés manuellement
        this.elementRef.nativeElement.querySelectorAll(`[data-dash-index='${index}']`).forEach((element) => {
            element.parentNode.removeChild(element);
        });

        this.verticalFiltersToggleEl.nativeElement.removeChild(this.dashboardComponents[index].instance.verticalFilters.filterToggle.element.nativeElement);

        const componentRef = this.dashboardComponents.splice(index, 1)[0];
        // Permet d'appeler le destroy du dashboardService
        componentRef.destroy();
        this.changeDetectorRef.detectChanges();
    }

    async apply() {
        const hasThematicsButNothingSelected = this.manageDashboardService.dashboardServices.reduce((acc, service) => {
            const currentFilters = service.currentFilters.getValue();
            return acc || (!currentFilters.selected_all && !currentFilters.selected_not && !currentFilters.thematics.find((f) => f.selected));
        }, false);
        if (hasThematicsButNothingSelected) {
            this.modalService.open(this.noThemeSelected, { ariaLabelledBy: 'modal-basic-title' });
        } else {
            // On sauvegarde l'info : est-ce que l'on a changé de colonne de verbatim dans les nouveaux filtres à appliquer
            const { firstDashboardService } = this.manageDashboardService;
            this.isVerbatimColumnChange = firstDashboardService.currentFilters.getValue().verbatim_dbname !== firstDashboardService.lastAppliedFilters.getValue()?.verbatim_dbname;

            const dashChangeIndex = [];
            // On applique les filtres
            await Promise.all(this.manageDashboardService.dashboardServices.map(async (service, index) => {
                if (service.checkIfFiltersHaveChangesNotApplied(service.currentFilters.getValue())) {
                    await service.applyNewFilters();
                    dashChangeIndex.push(index);
                }
            }));

            // Si on a changé de colonne de verbatim, on scrolle en haut de la page pour sélectionner l'onglet Verbatim
            if (this.isVerbatimColumnChange) {
                this.dashboardScrollDirective?.scrollToTop();
            }

            this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.emit(dashChangeIndex);
        }
    }

    cancel() {
        this.manageDashboardService.dashboardServices.forEach((service) => {
            service.setFilter(service.lastAppliedFilters.getValue());
        });
    }

    /**
     * Evènement lorsque l'on clique sur l'ouverture ou la fermeture de tous les filtres
     */
    onToggleAllFilter(toggle:boolean) {
        this.dashboardComponents.forEach((corpusDashboard) => {
            corpusDashboard.instance.verticalFilters.isOpen = toggle;
        });
    }

    /*
     * Evènement lorsque l'on clique sur un onglet du dashboard
     */
    onChangeTab(tab: string) {
        this.dashboardScrollDirective.scrollToElement(tab);
    }

    /**
     * Evènement lorsque l'on ajoute un dashboard à une comparaison en cours
     */
    onAddDashboardComparison(dash: Dashboardforuser) {
        this.dashboardLoaded = true;
        // Ajoute le dashboard à l'analyse en cours
        this.addDashboardToAnalyse(dash);
        // On ajoute l'ID du dashboard sélectionné aux enfants de la comparaison
        this.manageDashboardService.currentDashboardComparison.value.dash_childs.push(+dash.dash_id);
        this.manageDashboardService.manageMergedThematics();
        // On met à jour les différences entre filtres
        this.manageDashboardService.manageDifferenceBetweenFilters();
        this.dashboardLoaded = false;

        let trackCible = '';
        switch (dash.dash_section) {
            case DashboardSection.PERSO: trackCible += 'personnel'; break;
            case DashboardSection.COMPARISON: trackCible += 'comparaison'; break;
            case DashboardSection.SHARED: trackCible += 'partagé'; break;
            case DashboardSection.OTHER: trackCible += 'autre utilisateur'; break;
            default:
        }
        gtmClick({
            track_category: 'dashboard comparaison',
            track_name: 'ajout dashboard à la comparaison',
            track_cible: trackCible,
        });
        const comparisonDashboard = this.manageDashboardService.currentDashboardComparison.getValue();
        this.store.dispatch(new SetDashModified({ dashboardId: comparisonDashboard.dash_id, isDashModified: true }));

        this.manageDashboardService.dashboardListChanged.emit();
    }

    /**
     * Evènement lorsque l'on supprime un dashboard à une comparaison en cours
     */
    onRemoveDashboardComparison(index:number) {
        this.dashboardLoaded = true;
        this.removeDashboardToAnalyse(index);
        // On supprime l'ID du dashboard des enfants de la comparaison
        this.manageDashboardService.currentDashboardComparison.value.dash_childs.splice(index, 1);
        this.manageDashboardService.manageDifferenceBetweenFilters();
        this.dashboardLoaded = false;

        gtmClick({
            track_category: 'dashboard comparaison',
            track_name: 'supprimer dashboard de la comparaison',
        });
        const comparisonDashboard = this.manageDashboardService.currentDashboardComparison.getValue();
        this.store.dispatch(new SetDashModified({ dashboardId: comparisonDashboard.dash_id, isDashModified: true }));

        this.manageDashboardService.dashboardListChanged.emit();
    }
}
