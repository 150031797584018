/* eslint-disable import/no-import-module-exports */
import {
    Component, TemplateRef, ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ModalComponent from 'app/shared/components/modal/modal.component';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { PatchV1CorpusCorpIdParams } from 'app/api/services/corpus.service';
import { environment } from '../../../../../environments/environment';
import { GetCorpus } from '../../../../api/models/get-corpus';
import { CorpusService } from '../../../../api/services';
import { SetCorpus } from '../../../../stores/actions/corpus/corpus.actions';
import CorpusState from '../../../../stores/state/corpus/corpus.state';
import * as Resumable from '../../../../../libs/resumable';
import FixedValidationBarService from '../fixed-validation-bar/fixed-validation-bar.service';

/**
 * Corpus settings name, desc and attachement
 *
 * @export
 * @class CorpusSettingsDetailsComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus-settings-details',
    templateUrl: './corpus-settings-details.component.html',
    styleUrls: ['./corpus-settings-details.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})
export default class CorpusSettingsDetailsComponent {
    corpus: GetCorpus;

    old_corp_name: string;

    corp_name: string;

    old_corp_description: string;

    corp_description: string;

    r: any;

    file_changed: boolean = false;

    oldFileName: string;

    fileName: string;

    asAttachement: boolean = false;

    downloadUrl: string;

    showClose: boolean = false;

    removeAttachement: boolean = false;

    param: any;

    noFile: string = '';

    percent: number;

    indexStopwords: boolean;

    @ViewChild('deleteModal')
    private deleteModal: TemplateRef<any>;

    modalRef: BsModalRef;

    corpusState$: Observable<GetCorpus>;

    paramDelete: any = { projectName: '' };

    language: string;

    dateFormat: string;

    allowedExtensions;

    constructor(
        private store: Store,
        private corpusService: CorpusService,
        private spinner: SpinnerVisibilityService,
        private translate: TranslateService,
        private router: Router,
        private modalService: BsModalService,
        private ngModalService: NgbModal,
        private fixedValidationBarService: FixedValidationBarService,
    ) {
        this.allowedExtensions = this.store.snapshot().settings.vcrm['attachment-extensions'].map((extension) => `.${extension}`);

        moment.locale(this.translate.currentLang);
        this.corpusState$ = this.store.select(CorpusState);
        this.corpusState$.subscribe((state) => {
            this.language = state.corp_lang;
            this.dateFormat = state.corp_date_format;
            this.param = {
                id: state.corp_id,
                date: moment(state.corp_creation_date).format('LL'),
            };
            this.corpus = state;
            this.corp_name = state.corp_name;
            this.old_corp_name = this.corp_name;
            this.paramDelete.projectName = state.corp_name;
            this.corp_description = state.corp_description;
            this.old_corp_description = state.corp_description;
            this.indexStopwords = state.indexStopwords;
            if (state.attachment !== undefined) {
                this.asAttachement = true;
                this.showClose = true;
                this.fileName = state.attachment;
                this.oldFileName = this.fileName;
            } else {
                this.asAttachement = false;
                this.translate
                    .get('translations.newcorpus.stage2.noFile')
                    .subscribe((nofile) => {
                        this.fileName = nofile;
                        this.oldFileName = this.fileName;
                    });
            }
            this.translate
                .get('translations.newcorpus.stage2.noFile')
                .subscribe((nofile) => {
                    this.noFile = nofile;
                });
        });
        this.downloadUrl = `${environment.api}/api/v1/corpus/${
            this.corpus.corp_id
        }/attachment/download`;
    }

    get formatedExtensions() {
        return this.allowedExtensions.join(', ');
    }

    updateCorpus() {
        this.file_changed = false;
        this.removeAttachement = false;
        this.spinner.show();
        this.corpusService
            .getV1CorpusCorpId(this.corpus.corp_id)
            .subscribe((data) => {
                this.store.dispatch(new SetCorpus(data)).subscribe(() => {
                    this.spinner.hide();
                });
            });
    }

    deleteCorpus() {
        this.corpusService
            .deleteV1CorpusCorpId(this.corpus.corp_id)
            .subscribe({
                next: () => {
                    this.router.navigate(['/']);
                },

                error: (e) => {
                    const modal = this.ngModalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                    modal.componentInstance.contentToTranslate = `translations.httpErrors.${e?.error?.error || 'title'}`;
                    modal.componentInstance.alertTypeVariant = 'danger';
                },
            });
    }

    initFileUpload() {
        setTimeout(() => {
            this.r = new Resumable({
                target: `${environment.api}/api/v1/corpus/${
                    this.corpus.corp_id
                }/attachment`,
                withCredentials: true,
                method: 'multipart',
                testChunks: true,
                simultaneousUploads: 1,
                maxFiles: 1,
                fileType: this.allowedExtensions,
            });
            const fileInput = document.getElementById('fileImport');
            if (fileInput) {
                this.r.assignBrowse(fileInput, false);
            }

            this.r.on('fileAdded', (file) => {
                this.fileName = file.fileName;
                this.showClose = true;
                this.file_changed = true;
            });

            this.r.on('progress', () => {
                this.percent = Math.round(this.r.progress() * 100);
                FavIconX.config({
                    shape: 'doughnut',
                    borderWidth: 0,
                    fillColor: '#f16e00',
                }).setValue(this.percent);
            });

            this.r.on('fileSuccess', () => {
                this.spinner.hide();
                this.r.cancel();
                this.updateCorpus();
                FavIconX.reset();
            });

            this.r.on('fileError', (file, message) => {
                FavIconX.fail();
                this.showClose = false;
                this.spinner.hide();
                this.r.cancel();
                this.updateCorpus();
                const error = JSON.parse(message);
                // Display error message
                const modal = this.ngModalService.open(ModalComponent, {});
                modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                modal.componentInstance.content = error?.message;
                modal.componentInstance.alertTypeVariant = 'danger';
            });
        }, 500);
    }

    public isFormValid(): boolean {
    // le nom de doit pas êtr vide
        if (!this.corp_name || this.corp_name == null || this.corp_name.trim().length === 0) {
            return false;
        }

        // Au moins un champs doit avoir changé
        return this.corp_name.trim() !== this.old_corp_name.trim() || this.corp_description.trim() !== this.old_corp_description.trim() || this.file_changed;
    }

    save() {
        if (!this.isFormValid()) {
            return;
        }

        this.corp_name = this.corp_name.trim();
        this.corp_description = this.corp_description.trim();

        this.spinner.show();
        const params: PatchV1CorpusCorpIdParams = {
            corpId: this.corpus.corp_id,
            body: {
                name: this.corp_name,
                description: this.corp_description,
            },
        };
        this.corpusService.patchV1CorpusCorpId(params).subscribe(() => {
            this.corpusService
                .getV1CorpusCorpId(this.corpus.corp_id)
                .subscribe(() => {
                    if (this.r.files.length > 0) {
                        this.corpusService
                            .deleteV1CorpusCorpIdAttachment(this.corpus.corp_id)
                            .subscribe(() => {
                                this.r.upload();
                            });
                    } else if (this.removeAttachement) {
                        this.corpusService
                            .deleteV1CorpusCorpIdAttachment(this.corpus.corp_id)
                            .subscribe(() => {
                                this.spinner.hide();
                                this.updateCorpus();
                            });
                    } else {
                        this.spinner.hide();
                        this.updateCorpus();
                    }

                    this.fixedValidationBarService.showConformationMessage();
                });
        });
    }

    remove() {
        this.r.cancel();
        this.file_changed = true;
        this.fileName = this.noFile;
        this.file_changed = !(this.oldFileName === this.noFile);
        this.showClose = false;
        this.asAttachement = false;
        this.removeAttachement = true;
    }

    removeUpload() {
        this.r.cancel();
        this.showClose = false;
        this.file_changed = !(this.oldFileName === this.noFile);
        this.updateCorpus();
    }

    closeModal() {
        this.modalRef.hide();
    }

    openDeleteModal() {
        this.modalRef = this.modalService.show(this.deleteModal, {
            backdrop: 'static',
        });
    }

    validate() {
        this.save();
    }

    cancel() {
        this.showClose = false;
        this.updateCorpus();
    }
}
