// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordinate-scale-title {
  padding-top: 1.7em;
}

.ordinate-scale-radio-btns {
  font-size: 0.9em;
}

.button-period-selection-group {
  border: solid 2px lightgray;
}

.button-period-selection {
  border: 0;
}

.input-number {
  border: 0;
}

.input-nb-values-group {
  border: 2px solid lightgray;
  height: 35px;
}

.input-nb-values {
  height: 30px;
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
