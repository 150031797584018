/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable as __Observable } from 'rxjs';
import BaseService from '../base-service';
import ApiConfiguration from '../api-configuration';
import { ModelsCreationProcess } from '../models/modelsCreationProcess';

interface ModelsLang {
    models?: Array<ModelsCreationProcess>;
}

@Injectable({
    providedIn: 'root',
})
class ModelsService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
     * Get available models for Visualcrm users
     * @param lang user selected language
     */
    getV1Models(lang: string): __Observable<ModelsLang> {
        return this.http.get(`${this.rootUrl}/v1/models/${lang}`);
    }
}

export default ModelsService;
