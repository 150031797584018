import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Fieldnameanddbname, Inputnamevalues, DashboardType } from 'app/api/models';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ModalComponent from 'app/shared/components/modal/modal.component';
import CorpusService from 'app/utils/services/corpus.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';

@Component({
    selector: 'app-filters-verbatim',
    templateUrl: './filters-verbatim.component.html',
    styleUrls: ['./filters-verbatim.component.scss'],
})
export default class FiltersVerbatimComponent extends BaseVerticalFilterComponent implements OnInit {
    verbatimFields: Array<Fieldnameanddbname>;

    yes:string = '';

    no:string = '';

    PRES_VERBATIM_FIELD_NAME: string = 'pres_verbatim';

    yesCount: number = 0;

    noCount: number = 0;

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    constructor(
        private dashboardService: DashboardService,
        private corpusService: CorpusService,
        private translate: TranslateService,
        private modalService: NgbModal,
        protected manageDashboardService: ManageDashboardService,
    ) {
        super(dashboardService);
    }

    async ngOnInit() {
        super.ngOnInit();

        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                if (this.dashboardService.currentDashboardType === DashboardType.ANALYSE) {
                    this.verbatimFields = corpus.corp_verbatim_fields;
                } else {
                    this.verbatimFields = corpus.verbatim_for_tone;
                }
            }
        });

        this.subs.sink = this.dashboardService.currentVerbatimColumns.subscribe((columns: any) => {
            if (columns) {
                columns.pres_verbatim.values.forEach((value) => {
                    if (value.key === this.yes) {
                        this.yesCount = value.doc_count;
                    } else if (value.key === this.no) {
                        this.noCount = value.doc_count;
                    }
                });
            }
        });

        await this.translate.get('translations.corpus.filters.verbatim.presence.values.yes').toPromise().then();
        this.yes = this.translate.instant('translations.corpus.filters.verbatim.presence.values.yes');
        this.no = this.translate.instant('translations.corpus.filters.verbatim.presence.values.no');
    }

    get hasMinLength() {
        if(this.searchParams){
            return Object.prototype.hasOwnProperty.call(this.searchParams, 'verbatim_length_min');
        } else {
            return false;
        }
    }

    set hasMinLength(value: boolean) {
        if (value) {
            this.minLength = 0;
        } else {
            this.dashboardService.updateMinLength(-1);
        }
    }

    get hasMaxLength() {
        if(this.searchParams){
            return Object.prototype.hasOwnProperty.call(this.searchParams, 'verbatim_length_max');
        } else {
            return false;
        }
    }

    set hasMaxLength(value: boolean) {
        if (value) {
            this.maxLength = 278;
        } else {
            this.dashboardService.updateMaxLength(-1);
        }
    }

    get maxLength() {
        return this.hasMaxLength ? this.searchParams.verbatim_length_max : -1;
    }

    set maxLength(value:number) {
        this.dashboardService.updateMaxLength(value);
    }

    get minLength() {
        return this.hasMinLength ? this.searchParams.verbatim_length_min : -1;
    }

    set minLength(value:number) {
        this.dashboardService.updateMinLength(value);
    }

    /**
     * Détermine si on doit afficher les filtres "au moins" et "au plus" dans la section "Verbatim" des filtres
     */
    get isVerbatimLengthFieldsDisplayed(): boolean {
        return this.dashboardService.currentDashboardType === DashboardType.ANALYSE;
    }

    getModel(verbatimDbName: string) {
        const { models } = this.corpusService.currentCorpus.getValue();
        return models[verbatimDbName] ? models[verbatimDbName].model : '';
    }

    onVerbatimChange() {
        if ((this.dashboardService.getCurrentModel()?.model ?? '') !== this.getModel(this.selectedVerbatim) && this.dashboardService.currentDashboardType === DashboardType.ANALYSE) {
            const modal = this.modalService.open(ModalComponent, {});
            modal.componentInstance.titleToTranslate = 'translations.corpus.filters.verbatim.warning.title';
            modal.componentInstance.contentToTranslate = 'translations.corpus.filters.verbatim.warning.content';
            modal.componentInstance.btnValidVariant = 'danger';
            modal.componentInstance.btnValidToTranslate = 'translations.corpus.filters.verbatim.warning.confirm';
            modal.componentInstance.btnCloseToTranslate = 'translations.corpus.filters.verbatim.warning.cancel';
            modal.componentInstance.btnHeaderClose = false;
            modal.componentInstance.status.subscribe((s) => {
                if (s) {
                    this.dashboardService.updateVerbatimDbName(this.selectedVerbatim);
                } else {
                    this.selectedVerbatim = this.dashboardService.currentFilters.getValue().verbatim_dbname;
                }
            });
        } else {
            this.dashboardService.updateVerbatimDbName(this.selectedVerbatim);
        }
    }

    get presenceVerbatimFilter(): Inputnamevalues {
        if (!this.searchParams) {
            return null;
        }
        const filterPresenceVerbatim = this.searchParams.filters.filter(
            (filter) => filter.inputName === this.PRES_VERBATIM_FIELD_NAME,
        );
        if (filterPresenceVerbatim.length === 0) {
            return null;
        }
        return filterPresenceVerbatim[0];
    }

    get presenceVerbatim(): boolean {
        if (!this.searchParams) {
            return null;
        }

        if (this.presenceVerbatimFilter === null) {
            return null;
        } if (this.presenceVerbatimFilter.values[0] === this.yes) {
            return true;
        }
        return false;
    }

    set presenceVerbatim(value: boolean) {
        if (value === null) {
            this.searchParams.filters = this.searchParams.filters.filter(
                (filter) => filter.inputName !== this.PRES_VERBATIM_FIELD_NAME,
            );
        } else if (this.presenceVerbatimFilter === null) {
            this.searchParams.filters.push({
                inputName: 'pres_verbatim',
                values: [value ? this.yes : this.no],
            });
        } else {
            this.presenceVerbatimFilter.values[0] = value ? this.yes : this.no;
        }

        this.dashboardService.updateFilterList(this.searchParams.filters);
    }

    get count(): number {
        const isPresenceVerbatim = this.presenceVerbatim !== null ? 1 : 0;
        const hasLengthConfig = this.hasMaxLength || this.hasMinLength ? 1 : 0;
        return this.searchParams ? isPresenceVerbatim + hasLengthConfig : 0;
    }
}
