import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Thematic } from 'app/api/models';
import { SimilarityNeighboursList } from 'app/api/models/similarity';
import { CorpusService as CorpusApi, ThematicService } from 'app/api/services';
import { PostV1CorpusCorpIdVerbatimSimilarityParams } from 'app/api/services/corpus.service';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import ThematicEditorComponent from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';

@Component({
    selector: 'app-filters-search',
    templateUrl: './filters-search.component.html',
    styleUrls: ['./filters-search.component.scss'],
})
export default class FiltersSearchComponent extends BaseVerticalFilterComponent implements OnInit {
    public search: string = '';

    public lastSimilarityRequestParams: string = '';

    public isSearchValid = true;

    public searchError = '';

    public keywordsearch = [];

    public error = null;

    public loading = false;

    get showSimilarity(): boolean {
        if (this.dashboardService.currentFilters.getValue()) {
            const verbatimDbName = this.dashboardService.currentFilters.getValue().verbatim_dbname;
            const allTreatments = this.corpusService.currentCorpus.getValue().treatments;
            return (allTreatments[verbatimDbName]) ? this.includesSimilarity(allTreatments[verbatimDbName]) : false;
        }
        return false;
    }

    get isSimilarityDisabled(): boolean {
        return this.includesSimilarity(this.corpusService.currentCorpus.getValue().status_flags.asyncTreatmentInProgress);
    }

    // eslint-disable-next-line class-methods-use-this
    includesSimilarity(array) {
        return array.includes('Wordsimilarity') || array.includes('Wordsimilaritysc');
    }

    get isSimilarityChecked():boolean {
        return !this.isSimilarityDisabled && (this.dashboardService.currentConfig.getValue()?.filters?.similarity_is_checked || false);
    }

    set isSimilarityChecked(val: boolean) {
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'termes similaires',
            track_cible: val ? 'valider' : 'annuler',
        });
        this.dashboardService.setFiltersSimilarity(val);
        // on lance la requete des similarités si on active la checkbox et que la recherche est différente de la précédente requête des similarités
        if (val && this.lastSimilarityRequestParams !== this.search) {
            this.validAndLaunchSimilarity();
        }
    }

    public similarityResult: Array<SimilarityNeighboursList>;

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private dashboardService: DashboardService,
        private thematicService: ThematicService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super(dashboardService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.collapsed = false;

        this.subs.sink = this.dashboardService.currentFilters.subscribe((filters) => {
            if (filters === null) {
                return;
            }

            // si on a ajouté un mot clé, on ouvre systématiquement la section Recherche
            if (filters.keywordsearch.length > this.keywordsearch.length) {
                this.collapsed = false;
            }
            this.keywordsearch = [...filters.keywordsearch];
            this.search = filters?.freesearch || '';

            if (this.dashboardService.currentFilters.getValue() !== null && this.dashboardService.currentDashboard.getValue() !== null) {
            // Lorsqu'on reçoit les filtres, on remet à jour les keywordsearch sans la propriété "animate" pour ne pas refaire l'animation la prochaine fois
                const keywordsearchWithoutAnimation = (filters.keywordsearch.map(({ animate, ...keyword }) => keyword));
                this.dashboardService.currentFilters.getValue().keywordsearch = keywordsearchWithoutAnimation;
                this.dashboardService.currentDashboard.getValue().dash_json_params.keywordsearch = keywordsearchWithoutAnimation;
            }
        });

        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard) {
                if (this.isSimilarityChecked) {
                    this.launchSimilarity();
                }
            }
        });

        this.subs.sink = this.dashboardService.newFiltersIsApplied.subscribe(() => {
            if (!this.isSimilarityDisabled && this.isSimilarityChecked) {
                this.launchSimilarity();
            }
        });

        this.subs.sink = this.dashboardService.onSearchInvalid.subscribe((errorDetails) => {
            this.isSearchValid = false;
            this.searchError = errorDetails;
        });
    }

    get count() {
        let nb = 0;
        nb += (this.search.trim() !== '') ? 1 : 0;
        nb += (this.keywordsearch.length > 0) ? this.keywordsearch.length : 0;
        return nb;
    }

    /**
     * Lorsque l'utilisateur clique sur la croix pour effacer la recherche
     */
    onCleanSearch(): void {
        this.search = '';
        this.isSearchValid = true;
        this.dashboardService.setFreeSearch(this.search);
    }

    /**
     * Lorsque l'utilisateur modifie la recherche
     */
    onSearchChange(): void {
        this.isSearchValid = true;
        this.dashboardService.setFreeSearch(this.search.trim());
    }

    /**
     * Applique directement la recherche (sans passer par la barre et le bouton "Appliquer les filtres")
     */
    async onSearchKeyEnter(event: KeyboardEvent): Promise<void> {
        event.preventDefault();
        this.dashboardService.setFreeSearch(this.search.trim());
        await this.dashboardService.applyNewFilters();
        const dashChangeIndex = this.manageDashboardService.dashboardServices.indexOf(this.dashboardService);
        this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.emit([dashChangeIndex]);
        // Remet le scroll tout en haut lorsque l'on effectue une nouvelle recherche
        document.getElementById('dashboardScroll').scrollTo(0, 0);
    }

    /**
     * Quand on clique sur la croix d'un label de keyword
     */
    onDeleteKeyword(i) {
        this.dashboardService.setKeywordsSearch(this.keywordsearch.filter((keyword, index) => index !== i));
    }

    /**
     * Lorsque l'utilisateur clique sur le bouton "Supprimer toutes les capsules"
     */
    onRemoveAllCloudWord() {
        this.dashboardService.setKeywordsSearch([]);
    }

    /**
     * Vérifie que la recherche est valide et si oui, lance la recherche
     */
    async validAndLaunchSimilarity() {
        const isSearchValid = await this.dashboardService.freeSearchValidator();
        if (isSearchValid) {
            this.launchSimilarity();
        }
    }

    /**
     * Lance la recherche des termes similaires
     */
    launchSimilarity() {
        if (this.lastSimilarityRequestParams !== this.search) {
            this.error = null;
            this.similarityResult = null;
            this.loading = true;
            this.lastSimilarityRequestParams = this.search;
            this.corpusApi.postV1CorpusCorpIdVerbatimSimilarity(this.getSimilarityRequestParams()).subscribe({
                next: (similarities) => {
                    this.similarityResult = similarities.neighbours_list.filter((similarity) => similarity.neighbours.length !== 0);
                    this.loading = false;
                },
                error: (e) => {
                    this.loading = false;
                    this.error = e;
                },
            });
        }
    }

    /**
     * Evènement lorsque l'utilisateur clique sur le bouton de download des termes similaires
     */
    onDownloadSimilarity() {
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'termes similaires export',
        });
        this.corpusApi.getV1CorpusCorpIdVerbatimSimilarityDownload(this.getSimilarityRequestParams());
    }

    /**
     * Retourne les paramètres pour les requêtes de similarités (post et download)
     */
    getSimilarityRequestParams(): PostV1CorpusCorpIdVerbatimSimilarityParams {
        return {
            corpId: this.store.snapshot().corpus.corp_id,
            verbatimDbName: this.dashboardService.currentFilters.getValue().verbatim_dbname,
            body: {
                contains_word: false,
                request: this.lastSimilarityRequestParams,
            },
        };
    }

    /**
     * Evènement lorsque l'utilisateur clique sur le bouton de création d'une thématique
     * Prend en compte la recherche précédemment validée et non la recherche editée en cours
     */
    onCreateThematic() {
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'création thématique avec requête recherche',
        });
        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.gtmTrackName = 'enregistrer thématique avec requête recherche';
        modal.componentInstance.initValue = {
            name: '',
            definition: [this.search],
            lang: this.store.snapshot().corpus.corp_lang,
        };

        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            modal.close();
            this.thematicService.postV1Thematic_1(thematic).subscribe((thId) => {
                const newThematics = {
                    selected_all: false,
                    selected_not: false,
                    thematics: [{
                        them_name: thematic.name,
                        them_type: 'rule',
                        them_definition: thematic.definition,
                        lang: thematic.lang,
                        selected: true,
                        them_id: thId.id,
                        perso: true,
                        type_sharing: '0',
                    }],
                };

                this.dashboardService.currentFilters.getValue().selected_all = false;
                this.dashboardService.currentFilters.getValue().selected_not = false;
                this.dashboardService.currentFilters.getValue().thematics = newThematics.thematics.concat(this.dashboardService.currentFilters.getValue().thematics || []);
                this.dashboardService.updateThematicList(this.dashboardService.currentFilters.getValue());
            });
        });
    }

    /**
     * Evènement lorque l'utilisateur ajoute un terme similaire
     * @param word : mot référent
     * @param clickedWord : mot cliqué
     */
    onAddSimilarityWord(term: string, clickedWord:string) {
        const searchSplitted = this.search.split(/(OR|AND|NOT)/);
        const regex = new RegExp(`^${clickedWord}$`);
        // si le mot cliqué n'existe pas déjà dans la recherche
        if (!searchSplitted.some((e) => regex.test(e.trim().replace(/"|\(|\)/g, '')))) {
            const searchSplittedReplace = searchSplitted.map((word) => {
                let trimmedWord = word.trim();
                // si un mot et juste entouré de parenthèse, on les supprime
                if (trimmedWord.startsWith('(') && trimmedWord.endsWith(')')) {
                    trimmedWord = trimmedWord.substring(1, trimmedWord.length - 1);
                }
                // si on est sur le terme similaire au mot cliqué, on ajoute le mot cliqué
                if (trimmedWord.replace(/"|\(|\)/g, '') === term.replace(/"/g, '').trim()) {
                    let addedWord = clickedWord;
                    if (addedWord.includes(' ')) {
                        addedWord = `"${addedWord}"`;
                    }
                    if (trimmedWord.startsWith('(')) {
                        return `${trimmedWord} OR ${addedWord}`;
                    }
                    if (trimmedWord.endsWith(')')) {
                        return `${trimmedWord.replace(')', '')} OR ${addedWord})`;
                    }
                    return `(${trimmedWord} OR ${addedWord})`;
                }
                return trimmedWord;
            });
            this.search = searchSplittedReplace.join(' ');
            this.dashboardService.setFreeSearch(this.search);
        }
    }
}
