import {
    Component, OnInit, Input, Output, EventEmitter, OnChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { News } from 'app/api/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-news-displayer',
    templateUrl: './news-displayer.component.html',
})
export default class NewsDisplayerComponent implements OnInit, OnChanges {
    @Input() news: Array<News>;

    @Input() nbNewsMax: number;

    @Input() redirectLinksTooltip: string = '';

    @Input() displayMoreLinks: boolean = true;

    @Input() displayNoNews: boolean = true;

    @Output() redirectEvent = new EventEmitter<any>();

    public currentPage: number = 1;

    public currentLang: string; // Must be lower cased

    isThereNews: boolean = true;

    constructor(
        private store: Store,
        public translate: TranslateService,
    ) { }

    ngOnInit() {
    /* Gestion langue */
        if (this.translate.currentLang === undefined) {
            this.currentLang = this.translate.defaultLang.toLowerCase();
        } else {
            this.currentLang = this.translate.currentLang.toLowerCase();
        }

        /* Gestion news */
        if (this.filteredNews.length === 0) {
            this.isThereNews = false;
        }
    }

    ngOnChanges(): void {
        if (this.filteredNews.length === 0) {
            this.isThereNews = false;
        } else {
            this.isThereNews = true;
        }
    }

    get nbPage() {
        const nbPage = Math.floor(this.filteredNews.length / this.nbNewsMax);
        return this.filteredNews.length % this.nbNewsMax === 0 ? nbPage : nbPage + 1;
    }

    get currentNews() {
        const endNews = this.filteredNews.length < this.nbNewsMax ? this.filteredNews.length : this.nbNewsMax * this.currentPage;
        const startNews = (endNews - this.nbNewsMax) < 0 ? 0 : endNews - this.nbNewsMax;
        return this.filteredNews.slice(startNews, endNews);
    }

    get filteredNews() {
        const filteredNews = this.news.filter(
            (news) => this.currentLang in news.translations,
        );

        if (filteredNews.length === 0) {
            this.isThereNews = false;
        } else {
            this.isThereNews = true;
        }

        return filteredNews;
    }

    onRedirect(evt) {
        this.redirectEvent.emit(evt);
    }

    /* NAVIGATION */
    nextPage() {
        if (this.currentPage < this.nbPage) {
            this.currentPage += 1;
        }
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage -= 1;
        }
    }

    goToPage(pageNb: number) {
        if (pageNb > 0 && pageNb <= this.nbPage) {
            this.currentPage = pageNb;
        }
    }
}
