import { Injectable } from '@angular/core';
import { Dashboardforuser, DashboardType } from 'app/api/models';
import { NgxPermissionsService } from 'ngx-permissions';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root',
})
export default class DashPermissionsService {
    constructor(
        private permissionsService: NgxPermissionsService,
        private store: Store,
    ) {}

    public setPermissions(dash: Dashboardforuser) {
        let isAdmin = false;
        this.permissionsService.removePermission('dashAdmin');
        this.permissionsService.removePermission('dashPrivate');
        this.permissionsService.removePermission('dashReader');
        this.store.snapshot().corpus.users.forEach((user) => {
            if (
                user.username === this.store.snapshot().user.username
        && user.role_id * 1 === 1
            ) {
                isAdmin = true;
            }
        });
        if (dash.username_creator === this.store.snapshot().user.username && isAdmin) {
            this.permissionsService.addPermission('dashAdmin');
        } else if (dash.username_creator === this.store.snapshot().user.username && !isAdmin) {
            this.permissionsService.addPermission('dashPrivate');
        } else {
            this.permissionsService.addPermission('dashReader');
        }
        // Si on est sur un dashboard initial ou sur une nouvelle comparaison (non enregistré), on supprime les droits admin et private
        if (dash.dash_initial || (dash.dash_type === DashboardType.COMPARISON && dash.dash_name === '')) {
            this.permissionsService.removePermission('dashAdmin');
            this.permissionsService.removePermission('dashPrivate');
            this.permissionsService.addPermission('dashReader');
        }
    }
}
