import {
    Component, Input, OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MergedDistributionData, MergedResponseMatrix } from 'app/api/models/paramsformerge';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService, { GraphTypeEnum } from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { ComparisonMode, DashboardConfig, GraphParamsConfig } from 'app/api/models/dashboard-config';
import * as _ from 'lodash';
import CorpusService from 'app/utils/services/corpus.service';
import { Observable } from 'rxjs';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';

@Component({
    selector: 'app-distribution-graphs',
    templateUrl: './distribution-graphs.component.html',
    styleUrls: ['./distribution-graphs.component.scss'],
})
export default class DistributionGraphsComponent extends BaseComponent implements OnInit {
    @Input()
        type: GraphTypeEnum;

    // cet input n'a de valeur que sur les composants non fusionnés
    @Input()
        dashboardService: DashboardService;

    protected params;

    mergedData: MergedDistributionData;

    mergedMatrixData: MergedResponseMatrix;

    display: boolean = false;

    hasBarChartGraph: boolean = false;

    settings$: Observable<SettingsModel>;

    private backendGraphValuesMax : number;

    conf: GraphsConf = {
        classification: {
            id: 'classificationAutoTitle',
            title: 'translations.analysisDashboard.charts.classificationAuto',
            distribution_title: 'translations.analysisDashboard.charts.distribution',
            evolution_title: 'translations.analysisDashboard.charts.time',
            matrice_title: 'translations.analysisDashboard.charts.classMatriceLvl1',
            type: 'class',
            data_name: 'class_name',
            display_name: this.getClassDisplayName.bind(this),
        },
        thematics: {
            id: 'thematicsDataTitle',
            title: 'translations.analysisDashboard.themes.themes',
            distribution_title: 'translations.analysisDashboard.charts.distribution',
            evolution_title: 'translations.analysisDashboard.charts.time',
            matrice_title: 'translations.analysisDashboard.charts.matrice',
            type: 'thematics',
            data_name: 'them_name',
            display_name: this.getThemDisplayName.bind(this),
        },
    };

    get comparisonMode() {
        return this.dashboardService ? ComparisonMode.side_by_side : ComparisonMode.merged;
    }

    get hasData() {
        if (this.dashboardService) {
            return this.dashboardService.dashboardData.getValue()?.hits.total !== 0;
        }
        return this.manageDashboardService.dashboardServices.some((dashboardService) => dashboardService.dashboardData.getValue()?.hits.total !== 0);
    }

    get hasClassification() {
        return !!this.manageDashboardService.getCurrentModel();
    }

    get isComponentVisible() {
        return this.type === GraphTypeEnum.thematics || (this.type === GraphTypeEnum.classification && this.hasClassification);
    }

    constructor(
        private dashboardApi: DashboardApi,
        private store: Store,
        private translateService: TranslateService,
        private graphService: GraphService,
        public manageDashboardService: ManageDashboardService,
        private corpusService: CorpusService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.backendGraphValuesMax = settings.vcrm['graph-values-max'];
        });

        // Lorsque les dashboards sont chargés
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(() => {
            this.checkAndLoadConfigAndData();
        });
        this.subs.sink = this.manageDashboardService.dashboardListChanged.subscribe(() => {
            this.checkAndLoadConfigAndData();
        });
        // Lorsque les filtres sont mis à jour
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe(() => {
            this.checkAndLoadConfigAndData();
        });
        // Lorsque l'on change de mode d'affichage (pour la comparaison mode Cote à Cote ou Fusionné)
        this.subs.sink = this.manageDashboardService.comparisonSwitchMode.subscribe(({ section }) => {
            // Si la modification d'affichage correspond à la section de ce composant
            if (section === this.conf[this.type].type) {
                this.checkAndLoadConfigAndData();
            }
        });
    }

    /**
     * Vérifie d'abord puis charge les données de ce composant si c'est ok
     */
    checkAndLoadConfigAndData() {
        const comparisonConfig = this.manageDashboardService.currentDashboardComparisonConfig.getValue();
        // Si on N'est PAS en mode comparaison
        // OU
        // si la config de ce composant correspond à la config de la comparaison affichée
        if (this.isComponentVisible && (!comparisonConfig || comparisonConfig.comparison[this.conf[this.type].type] === this.comparisonMode)) {
            this.loadConfig();
            this.loadDistributionGraphs();
        }
    }

    loadConfig() {
        const config: DashboardConfig = this.dashboardService
            ? this.dashboardService.currentConfig.getValue()
            : this.manageDashboardService.currentDashboardComparisonConfig.getValue();

        const params = _.cloneDeep(config[this.conf[this.type].type]);
        params.distri.period = this.graphService.getDefaultPeriod(params.distri.period, this.dashboardService);
        // Ajoute l'info sur le nombre max des classes ou thématiques
        if (this.type === GraphTypeEnum.classification) {
            this.conf.classification.nb_max_values_chart = this.getMaxNbClass();
        } else if (this.type === GraphTypeEnum.thematics) {
            this.conf.thematics.nb_max_values_chart = this.getMaxNbThematics();
        }
        this.params = params;
    }

    getMaxNbClass() {
        const maxValue = this.manageDashboardService.getCurrentModel()?.cat_fields.length + 1; // +1 for Hors_Class;
        return maxValue <= this.backendGraphValuesMax ? maxValue : this.backendGraphValuesMax;
    }

    getMaxNbThematics() {
        const maxValue = this.manageDashboardService.getLastAppliedThematics().length + 1; // +1 for Hors_thematics
        return maxValue <= this.backendGraphValuesMax ? maxValue : this.backendGraphValuesMax;
    }

    loadDistributionGraphs() {
        if ((this.type === GraphTypeEnum.thematics && this.manageDashboardService.getLastAppliedThematics().length === 0)
            || (this.type === GraphTypeEnum.classification && !this.manageDashboardService.getCurrentModel())) {
            this.display = false;
        } else {
            this.display = true;
            this.loadData();
        }
    }

    /**
     * Charge les données des graphiques fusionnés
     */
    async loadData() {
        const childrenFilters = this.dashboardService
            ? [_.cloneDeep(this.dashboardService.lastAppliedFilters.getValue())]
            : this.manageDashboardService.dashboardServices.map((service) => _.cloneDeep(service.lastAppliedFilters.getValue()));
        const firstDashboard = this.manageDashboardService.dashboardServices[0].currentDashboard.getValue();

        // valeur du nmobre de courbes en mode comparaison
        if (this.manageDashboardService.dashboardServices.length > 1) {
            const elementCount = this.type === GraphTypeEnum.classification
                ? this.getMaxNbClass()
                : this.getMaxNbThematics();

            if (this.params.histo.nb_values_chart === -1) {
                if (this.comparisonMode === ComparisonMode.side_by_side) { // en mode cote à cote
                    // on limite à 20 courbes et histogrames
                    this.processDataLimit(elementCount, 20);
                } else if (this.comparisonMode === ComparisonMode.merged) { // en mode fusionné
                    if (this.manageDashboardService.dashboardServices.length === 2) {
                        // fusion 2 dashboards -> on limite à 12 courbes et histogrames
                        this.processDataLimit(elementCount, 12);
                    } else {
                        // fusion 3 dashboards -> on limite à 8 courbes et histogrames
                        this.processDataLimit(elementCount, 8);
                    }
                } else {
                    this.params.histo.nb_values_chart = 12;
                }
            }
        } else {
            if (this.params.histo.nb_values_chart === -1) {
                this.params.histo.nb_values_chart = 12;
            }
            if (this.params.distri.nb_values_chart === -1) {
                this.params.distri.nb_values_chart = 12;
            }
        }

        if (firstDashboard) {
            this.dashboardApi.getDistributionMergedData(
                firstDashboard.dash_id,
                this.conf[this.type].type,
                {
                    searchparams: childrenFilters,
                    graphparams: {
                        distri: this.params.distri,
                        histo: this.params.histo,
                    },
                },
            ).subscribe((mergedData) => {
                this.mergedData = mergedData;
                this.hasBarChartGraph = Object.prototype.hasOwnProperty.call(mergedData.histogram, 'values');
            });

            // Effectue l'appel API pour la matrice des classes/tonalités et top mots clés
            this.dashboardApi.getMergedMatrixData(
                firstDashboard.dash_id,
                this.conf[this.type].type,
                { searchparams: childrenFilters, nbkeywords: 4 },
            ).subscribe((response) => {
                this.mergedMatrixData = response;
            });
        }
    }

    processDataLimit(elementCount: number, limit: number) {
        if (elementCount < limit) {
            if (this.params.histo.nb_values_chart === -1) {
                this.params.histo.nb_values_chart = elementCount;
                this.params.histo.top_activated = false;
            }

            if (this.params.distri.nb_values_chart === -1) {
                this.params.distri.nb_values_chart = elementCount;
                this.params.distri.top_activated = false;
            }
        } else {
            if (this.params.histo.nb_values_chart === -1) {
                this.params.histo.nb_values_chart = limit;
                this.params.histo.top_activated = true;
            }

            if (this.params.distri.nb_values_chart === -1) {
                this.params.distri.nb_values_chart = limit;
                this.params.distri.top_activated = true;
            }
        }
    }

    getClassDisplayName(inputName: string) {
        if (inputName === '__hors_classe__') {
            return this.translateService.instant('translations.analysisDashboard.class.__hors_classe__');
        }
        if (inputName === 'all') {
            return this.translateService.instant('translations.analysisDashboard.themes.all');
        }
        const verbatimDbName = this.manageDashboardService.getVerbatimDbName();
        return this.store.snapshot().corpus.models[verbatimDbName].cat_fields.find((field) => field.inputName === inputName)?.displayName || inputName;
    }

    getThemDisplayName(inputName: string) {
        if (inputName === 'all') {
            return this.translateService.instant('translations.analysisDashboard.themes.all');
        }
        if (inputName === 'not') {
            return this.translateService.instant('translations.analysisDashboard.themes.not');
        }
        return inputName;
    }

    updateConfig(config: GraphParamsConfig, reloadData = true) {
        if (this.comparisonMode === ComparisonMode.side_by_side) {
            // Met à jour la config sur le dashboard
            this.dashboardService.setDistributionConfig(this.conf[this.type].type, config);
        } else {
            this.manageDashboardService.setComparisonDashbardConfig(this.conf[this.type].type, config);
        }
        this.loadConfig();

        if (reloadData) {
            this.loadData();
        }
    }
}

export interface GraphConf {
    id: string; // l'id html du panneau pour le scrolling auto
    title: string; // la clé du titre principal du composant à afficher dans le dashboard
    distribution_title?: string; // la clé du titre du graph de distribution
    evolution_title: string; // la clé du titre du graph d'évolution
    matrice_title: string; // la clé du titre du graph de la matrice
    type: 'class' | 'thematics'; // le type de graph
    data_name: string; // le nom de la variable contenant la valeur à considérer, renvoyée par le backend
    display_name: (string) => string; // la fonction utilisée pour traduire les labels à afficher dans la graph à partie de la clé
    nb_max_values_chart?: number; // Nombre maximum de classes ou thématiques à utiliser dans la popin de settings
}

export interface GraphsConf {
    classification: GraphConf;
    thematics: GraphConf;
}

export const settingsPopupAnalytics = {
    global: {
        track_category: 'evolution temporelle',
        partial_track_name: 'evolution temporelle',
    },
    class: {
        track_category: 'classification automatique',
        partial_track_name: 'classification',
    },
    thematics: {
        track_category: 'thématiques',
        partial_track_name: 'thématique',
    },
};
