import { XaxisSort } from './histoparams';

export interface HeatmapParams {
    // The values must be reco, sat, ton, class, thematic or the dbName of associated data
    fieldx?: string;
    // The values must be reco, sat, ton, class, thematic or the dbName of associated data
    fieldy?: string;
    // For the two axis : sort with values (asc or desc) or nb occurences (asc or desc)
    axis_sort?:XaxisSort;
    // Number of values for the chart
    nb_values_chart?:number;
    // Calculation method for point value : Number of occurrences or Correlation coefficient of chi 2
    method: Method;

    top_activated?:boolean;
}

export enum Method {
    occur = 'occur', khi2 = 'khi2',
}
