import {
    Component, EventEmitter, Input, OnChanges, Output, ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-login-msurvey',
    templateUrl: './login-msurvey.component.html',
})
export default class LoginMSurveyComponent implements OnChanges {
    showLoading: boolean = false;

    @ViewChild('msurveyLoginForm')
        msurveyLoginForm: NgForm;

    @Input() showCancelBtn: boolean = false;

    @Input() showServerError: boolean = false;

    @Output() cancelFormEvent = new EventEmitter<any>();

    @Output() closeServerErrorEvent = new EventEmitter<any>();

    @Output() loginFormEvent = new EventEmitter<any>();

    /**
   * Fired when every properties change
   */
    ngOnChanges(changes: any) {
        if (changes?.showServerError?.currentValue) {
            // Hide loading when error is displayed
            this.showLoading = false;
        }
    }

    /**
   * Fired when user click on login button.
   * Check form validity and if it is true, show loading and emit event
   */
    onSubmit(event) {
        event.preventDefault();
        // si le spinner est affiché, on empèche l'exécution du traitement
        if (this.msurveyLoginForm.valid && !this.showLoading) {
            this.showLoading = true;
            this.loginFormEvent.emit(this.msurveyLoginForm.value);
        }
    }
}
