// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input, button {
  border: 0;
}

button {
  position: absolute;
  right: 0;
  top: 0;
}

.clearable-input {
  border: var(--bs-border-width) solid var(--bs-border-color-subtle);
}
.clearable-input.input-focused {
  border-color: currentColor;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
