import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { ComparisonMode } from 'app/api/models/dashboard-config';
import { IndicatorsMerge } from 'app/api/models/satisfactiondata';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-satisfaction-indicator',
    templateUrl: './satisfaction-indicator.component.html',
    styleUrls: ['./satisfaction-indicator.component.scss'],
})
export default class SatisfactionIndicatorComponent {
    constructor(protected manageDashboardService: ManageDashboardService) { }

    @Input()
        type: string;

    @Input()
        data: IndicatorsMerge;

    @Output()
        configChanged: EventEmitter<void> = new EventEmitter<void>();

    @Input()
        dashboardService: DashboardService;

    onConfigChanged() {
        this.configChanged.emit();
    }

    get comparisonMode() {
        return this.dashboardService ? ComparisonMode.side_by_side : ComparisonMode.merged;
    }

    get isMerged() {
        return this.comparisonMode === ComparisonMode.merged;
    }
}
