import {
    AfterViewInit,
    Component, ElementRef, Input, ViewChild,
} from '@angular/core';
import { ComparisonMode } from 'app/api/models/dashboard-config';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-dashboard-section',
    templateUrl: './dashboard-section.component.html',
})
export default class DashboardSectionComponent implements AfterViewInit {
    @Input()
        id: string;

    @Input()
        title: string;

    @Input()
        section: string;

    @Input('data-lazyload-on-scroll')
        dataLazyloadOnScroll:string;

    @Input('data-need-request')
        dataNeedRequest: boolean;

    @Input()
        isSectionVisible = true;

    @ViewChild('analyseSection') private analyseSection: ElementRef<HTMLElement>;

    get isSideBySide(): boolean {
        const dashboardCompConfig = this.manageDashboardService.currentDashboardComparisonConfig.getValue();
        return dashboardCompConfig ? dashboardCompConfig.comparison[this.section] === ComparisonMode.side_by_side : true;
    }

    constructor(
        public manageDashboardService: ManageDashboardService,
    ) {

    }

    ngAfterViewInit() {
        if (this.dataLazyloadOnScroll) {
            this.analyseSection.nativeElement.setAttribute('data-lazyload-on-scroll', this.dataLazyloadOnScroll);
            this.analyseSection.nativeElement.setAttribute('data-need-request', `${this.dataNeedRequest}`);
        }
    }
}
