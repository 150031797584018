import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HeatmapParams } from 'app/api/models/graphparams';
import { PossibleField } from 'app/api/models/responseheatmap';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';

@Component({
    selector: 'heatmap-settings-popup',
    templateUrl: './heatmap-settings-popup.component.html',
    styleUrls: ['./heatmap-settings-popup.component.scss'],
})
export default class HeatmapSettingsPopupComponent implements OnInit {
    constructor(
        public modal: NgbActiveModal,
    ) { }

    params: HeatmapParams;

    initialParams: HeatmapParams;

    possibleFields: Array<PossibleField>;

    filterY = '';

    filterX = '';

    gtmTrackCible = {
        occur_asc: 'nbre occurrence croissant',
        occur_desc: 'nbre occurrence décroissant',
        values_asc: 'valeur croissante',
        values_desc: 'valeur décroissante',
        occur: 'nbre cooccurrences',
        khi2: 'coef corrélation',
    };

    @Output()
    private save: EventEmitter<HeatmapParams> = new EventEmitter();

    ngOnInit(): void {
        this.params = _.cloneDeep(this.initialParams);
        this.initialParams = _.cloneDeep(this.initialParams);
    }

    validate() {
        this.save.emit(this.params);
        this.modal.close();
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }

    get isValid(): boolean {
        return this.params.fieldx !== '' && this.params.fieldy !== '';
    }

    launchGaEventNbValues(): void {
        gtmClick({
            track_category: 'heatmap',
            track_name: 'vue heatmap nbre valeurs affichées',
            track_cible: `${this.params.nb_values_chart}`,
        });
    }

    getDiplayName(fieldKey): string {
        const field = this.possibleFields.find((f) => f.key === fieldKey);
        return field ? field.displayName : '';
    }
}
