// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select {
  height: 30px;
  font-size: 14px;
  padding: 0 30px 0 10px;
}

.form-control {
  font-size: 14px;
  padding-left: 40px;
}

.dates-row {
  gap: 10px;
}

.calendar-icon {
  left: 8px;
  top: 6px;
  font-size: 18px;
}

input {
  height: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
