/* eslint-disable max-classes-per-file */

import { IsDashModified } from 'app/stores/models/settings/settings.model';

/**
 * Set current language
 *
 * @export
 * @class SetLang
 */
export class SetLang {
    static readonly type = '[SETTINGS] set ui language';

    constructor(public payload: any) {}
}

/**
 * Set current corpus selected
 *
 * @export
 * @class SetCurrentCorpus
 */
export class SetCurrentCorpus {
    static readonly type = '[SETTINGS] set current corpus loaded';

    constructor(public payload: any) {}
}

/**
 * Set VCRM settings
 *
 * @export
 * @class SetVcrmSettings
 */
export class SetVcrmSettings {
    static readonly type = '[SETTINGS] set vcrm settings';

    constructor(public payload: any) {}
}

/**
 * Set GDPR redirect url when accept
 *
 * @export
 * @class SetVcrmSettings
 */
export class SetGdprRedirect {
    static readonly type = '[SETTINGS] Set GDPR redirect url when accept';

    constructor(public payload: any) {}
}

/**
 * Set GDPR redirect url when accept
 *
 * @export
 * @class SetDashModified
 */
export class SetDashModified {
    static readonly type = '[SETTINGS] Set dashboard is modified';

    constructor(public payload: IsDashModified) {}
}

export class ResetDashModified {
    static readonly type = '[SETTINGS] Reset dash modified';
}

/**
 * Set GDPR redirect url when accept
 *
 * @export
 * @class SetThematicsModified
 */
export class SetThematicsModified {
    static readonly type = '[SETTINGS] Set thematics are modified';

    constructor(public payload: boolean) {}
}

/**
 * Set is Chatbot button hover or not
 *
 * @export
 * @class SetIsChatbotBtnHover
 */
export class SetIsChatbotBtnHover {
    static readonly type = '[SETTINGS] Set is chatbot button hover or not';

    constructor(public payload: boolean) {}
}

/**
 * Set is Discovery is started or not
 *
 * @export
 * @class SetIsDiscoveryInProgress
 */
export class SetIsDiscoveryInProgress {
    static readonly type = '[SETTINGS] Set is discovery is started or not';

    constructor(public payload: boolean) {}
}
