import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Inputnamevalues2levels } from 'app/api/models';
import { CorpusService as CorpusApi } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';

import CorpusClassModel from 'app/utils/models/corpus-class.model';

import { diff } from 'deep-object-diff';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import CorpusService from 'app/utils/services/corpus.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import accentless from '../../../../../../../../utils/functions/accentless';

@Component({
    selector: 'filters-classifications-auto-popup-complex',
    templateUrl: './filters-classifications-auto-popup-complex.component.html',
    styleUrls: ['./filters-classifications-auto-popup-complex.component.scss'],
})
export default class FiltersClassificationsAutoPopupComplexComponent implements OnInit {
    @Output()
    private onSave: EventEmitter<boolean> = new EventEmitter();

    modelNameReference: string = '';

    private confirmButtonDisabled: boolean = true;

    private suppressButtonDisabled: boolean = true;

    public cats: Array<CorpusClassModel> = [];

    private catsInitialState: Array<CorpusClassModel> = [];

    public posIndeterminate: boolean = false;

    public posAllSelected: boolean = false;

    public posSubAllSelected: Object = {};

    public negIndeterminate: boolean = false;

    public negAllSelected: boolean = false;

    public negSubAllSelected: Object = {};

    public allSelected: boolean = false;

    public collapses: Object = {};

    public allSubSelected: Object = {};

    public allSubSelectedIndeterminate: Object = {};

    public numSubSelected: Object = {};

    public posSubIndeterminate: Object = {};

    public negSubIndeterminate: Object = {};

    public showClassCharts: boolean = true;

    public horsClassSelected: boolean = false;

    public horsClassSelectedInitialValue: boolean;

    public horsClassCount: any = 0;

    public numCheckable: number = 0;

    public numChecked: number = 0;

    private filtersList: Array<Inputnamevalues2levels> = [];

    private dashboardService: DashboardService;

    displayColumns: Array<CorpusClassModel> = [];

    constructor(
        public bsModalRef: BsModalRef,
        private store: Store,
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private currentModal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.confirmButtonDisabled = true;

        const cats = _.cloneDeep(this.models[this.verbatimDBName].cat_fields);

        this.corpusApi
            .getV1CorpusCorpIdClassData(this.store.snapshot().corpus.corp_id, this.verbatimDBName)
            .subscribe((data) => {
                const selected = this.dashboardService.currentFilters.getValue().filters_class;
                this.numCheckable = 0;
                this.numChecked = 0;
                let numPosChecked = 0;
                let numNegChecked = 0;
                cats.forEach((cat) => {
                    let numSubPosChecked = 0;
                    let numSubNegChecked = 0;
                    this.numSubSelected[cat.inputName] = 0;
                    cat.nbsubclass = (cat.subclass.length) * 2;
                    this.numCheckable += cat.nbsubclass;
                    cat.subclass.forEach((motif) => {
                        motif.pos = false;
                        motif.neg = false;
                        selected.forEach((c) => {
                            if (c.inputName === '__hors_classe__') {
                                this.horsClassSelected = true;
                                return;
                            }
                            if (c.subclass) {
                                c.subclass.forEach((m) => m.values.forEach((v) => {
                                    if (
                                        c.inputName === cat.inputName
                                        && m.inputName === motif.inputName
                                    ) {
                                        if (v === 'pos') {
                                            motif.pos = true;
                                            numSubPosChecked += 1;
                                        }
                                        if (v === 'neg') {
                                            motif.neg = true;
                                            numSubNegChecked += 1;
                                        }
                                    }
                                }));
                            }
                        });
                        data.class_count.forEach((d) => {
                            d.values.forEach((v) => {
                                if (d.inputName === cat.inputName && v.key === motif.inputName) {
                                    motif.count = v.doc_count;
                                }
                            });

                            if (d.inputName === '__hors_classe__') {
                                const count: any = d.values[0];
                                this.horsClassCount = count.response;
                            }
                        });
                    });
                    this.numSubSelected[cat.inputName] = (numSubPosChecked + numSubNegChecked);
                    this.allSubSelected[cat.inputName] = ((numSubPosChecked + numSubNegChecked) === cat.nbsubclass);
                    this.allSubSelectedIndeterminate[cat.inputName] = this.numSubSelected[cat.inputName] > 0 && this.numSubSelected[cat.inputName] < cat.nbsubclass;
                    this.posSubAllSelected[cat.inputName] = (numSubPosChecked === cat.subclass.length);
                    this.posSubIndeterminate[cat.inputName] = numSubPosChecked > 0 && numSubPosChecked < cat.subclass.length;
                    this.negSubAllSelected[cat.inputName] = (numSubNegChecked === cat.subclass.length);
                    this.negSubIndeterminate[cat.inputName] = numSubNegChecked > 0 && numSubNegChecked < cat.subclass.length;
                    this.numChecked += this.numSubSelected[cat.inputName];
                    numPosChecked += numSubPosChecked;
                    numNegChecked += numSubNegChecked;
                });
                this.allSelected = (this.numChecked === this.numCheckable);

                this.negIndeterminate = numNegChecked > 0 && numNegChecked < (this.numCheckable / 2);
                this.posIndeterminate = numPosChecked > 0 && numPosChecked < (this.numCheckable / 2);
                this.posAllSelected = numPosChecked === (this.numCheckable / 2);
                this.negAllSelected = numNegChecked === (this.numCheckable / 2);

                this.cats = cats;
                this.displayColumns = this.cats;
                this.catsInitialState = JSON.parse(JSON.stringify(cats));
                this.horsClassSelectedInitialValue = this.horsClassSelected;
                this.suppressActiveOrNot();
            });
    }

    get models() {
        return this.corpusService.currentCorpus.getValue().models;
    }

    get modelName() {
        const { models } = this.corpusService.currentCorpus.getValue();
        return models[this.verbatimDBName].model;
    }

    get verbatimDBName() {
        return this.dashboardService.currentFilters.value.verbatim_dbname;
    }

    toggleCollapse(input) {
        if (this.collapses[input] === undefined) {
            this.collapses[input] = false;
        }
        this.collapses[input] = !this.collapses[input];
    }

    selectAllTona(e, type) {
        this.cats.forEach((cat) => {
            cat.subclass.forEach((motif) => {
                motif[type] = e.currentTarget.checked;
            });
        });
        this.updateList();
    }

    selectAllTonaSub(e, c, type) {
        this.cats.forEach((cat) => {
            if (cat.inputName === c) {
                cat.subclass.forEach((motif) => {
                    motif[type] = e.currentTarget.checked;
                });
            }
        });
        this.updateList();
    }

    selectAllChecked(e) {
        this.allSelected = !this.allSelected;
        this.cats.forEach((cat) => {
            this.allSubSelected[cat.inputName] = e.currentTarget.checked;
            this.posSubAllSelected[cat.inputName] = e.currentTarget.checked;
            this.negSubAllSelected[cat.inputName] = e.currentTarget.checked;
            cat.subclass.forEach((motif) => {
                motif.pos = e.currentTarget.checked;
                motif.neg = e.currentTarget.checked;
            });
        });
        this.updateList();
    }

    selectSelf(e, input, c, type) {
        this.cats.forEach((cat) => {
            if (cat.inputName === c) {
                cat.subclass.forEach((motif) => {
                    if (motif.inputName === input) {
                        motif[type] = e.currentTarget.checked;
                    }
                });
            }
        });
        this.updateList();
    }

    selectMotif(e, input, c) {
        this.cats.forEach((cat) => {
            if (cat.inputName === c) {
                cat.subclass.forEach((motif) => {
                    if (motif.inputName === input) {
                        motif.pos = e.currentTarget.checked;
                        motif.neg = e.currentTarget.checked;
                    }
                });
            }
        });
        this.updateList();
    }

    selectClass(e, input) {
        this.allSubSelected[input] = !this.allSubSelected[input];
        this.posSubAllSelected[input] = !this.posSubAllSelected[input];
        this.negSubAllSelected[input] = !this.negSubAllSelected[input];
        if (this.allSubSelected[input]) {
            this.cats.forEach((cat) => {
                if (cat.inputName === input) {
                    cat.subclass.forEach((motif) => {
                        motif.pos = true;
                        motif.neg = true;
                    });
                }
            });
        } else {
            this.cats.forEach((cat) => {
                if (cat.inputName === input) {
                    cat.subclass.forEach((motif) => {
                        motif.pos = false;
                        motif.neg = false;
                    });
                }
            });
            this.allSelected = false;
        }
        this.updateList();
    }

    selectHorsClass() {
        this.horsClassSelected = !this.horsClassSelected;
        this.updateList();
    }

    private updateList() {
        this.filtersList = [];
        this.numChecked = 0;
        let numPosChecked = 0;
        let numNegChecked = 0;
        this.cats.forEach((cat) => {
            const motifList = [];
            let numSubPosChecked = 0;
            let numSubNegChecked = 0;
            cat.subclass.forEach((motif) => {
                if (motif.pos || motif.neg) {
                    const motifValues = [];
                    if (motif.pos) {
                        motifValues.push('pos');
                        numSubPosChecked += 1;
                    }
                    if (motif.neg) {
                        motifValues.push('neg');
                        numSubNegChecked += 1;
                    }
                    if (motifValues.length > 0) {
                        motifList.push({
                            inputName: motif.inputName,
                            values: motifValues,
                        });
                    }
                }
            });
            this.numSubSelected[cat.inputName] = (numSubPosChecked + numSubNegChecked);
            this.numChecked += this.numSubSelected[cat.inputName];
            this.allSubSelected[cat.inputName] = (this.numSubSelected[cat.inputName] === cat.nbsubclass);
            this.allSubSelectedIndeterminate[cat.inputName] = this.numSubSelected[cat.inputName] > 0 && this.numSubSelected[cat.inputName] < cat.nbsubclass;
            this.posSubAllSelected[cat.inputName] = (numSubPosChecked === cat.subclass.length);
            this.posSubIndeterminate[cat.inputName] = numSubPosChecked > 0 && numSubPosChecked < cat.subclass.length;
            this.negSubAllSelected[cat.inputName] = (numSubNegChecked === cat.subclass.length);
            this.negSubIndeterminate[cat.inputName] = numSubNegChecked > 0 && numSubNegChecked < cat.subclass.length;
            numPosChecked += numSubPosChecked;
            numNegChecked += numSubNegChecked;
            if (motifList.length > 0) {
                this.filtersList.push({
                    inputName: cat.inputName,
                    subclass: motifList,
                });
            }
        });
        this.allSelected = (this.numChecked === this.numCheckable);

        this.negIndeterminate = numNegChecked > 0 && numNegChecked < (this.numCheckable / 2);
        this.posIndeterminate = numPosChecked > 0 && numPosChecked < (this.numCheckable / 2);
        this.posAllSelected = numPosChecked === (this.numCheckable / 2);
        this.negAllSelected = numNegChecked === (this.numCheckable / 2);
        if (this.horsClassSelected) {
            this.filtersList.push({
                inputName: '__hors_classe__',
            });
        }
        this.suppressActiveOrNot();

        this.confirmButtonDisabled = _.isEmpty(diff(this.cats, this.catsInitialState)) && this.horsClassSelected === this.horsClassSelectedInitialValue;
    }

    suppressActiveOrNot() {
        this.suppressButtonDisabled = true;
        this.cats.forEach((cat) => {
            cat.subclass.forEach((motif) => {
                if (motif.pos || motif.neg) {
                    this.suppressButtonDisabled = false;
                }
            });
        });
        if (this.horsClassSelected) {
            this.suppressButtonDisabled = false;
        }
    }

    /**
     * Methode appelé lorsque l'on utilise le champ de recherche d'une classification.
     * Permet d'afficher uniquement les classes qui contiennent la recherche demandée
     */
    onFreeSearch(search) {
        this.displayColumns = [];
        const findR = new RegExp(accentless(search), 'gimu');
        this.cats.forEach((col) => {
            if (col.displayName && findR.test(accentless(col.displayName))) {
                this.displayColumns.push(col);
                this.collapses[col.inputName] = false;
            } else if (col.subclass.length > 0) {
                const subClassList = [];
                col.subclass.forEach((sub) => {
                    if (sub.displayName && findR.test(accentless(sub.displayName))) {
                        subClassList.push(sub);
                    }
                });
                if (subClassList.length > 0) {
                    this.displayColumns.push({
                        displayName: col.displayName,
                        inputName: col.inputName,
                        subclass: subClassList,
                    });
                    this.collapses[col.inputName] = false;
                }
            }
        });
    }

    suppressAll() {
        this.cats.forEach((cat) => {
            this.allSubSelected[cat.inputName] = false;
            this.posSubAllSelected[cat.inputName] = false;
            this.negSubAllSelected[cat.inputName] = false;
            cat.subclass.forEach((motif) => {
                motif.pos = false;
                motif.neg = false;
            });
        });
        this.horsClassSelected = false;
        this.allSelected = false;
        this.updateList();
    }

    close() {
        this.currentModal.close();
    }

    save() {
        this.updateList();
        this.dashboardService.updateFilterClasses(this.filtersList);
        this.onSave.emit(true);
        this.close();
    }
}
