import { NgxLoggerLevel } from "ngx-logger";

/**
 * GCP PREPROD environment
 *
 */
export const environment = {
  production: false,
  hmr: false,
  api: '/back',
  phase: 'qa',
  id_gtm: 'GTM-PJLFS4H',
  logLevel: NgxLoggerLevel.ERROR
};
