import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import SharedModule from 'app/shared/shared.module';
import AuthGuardService from 'app/utils/services/authguard.service';
import UserRoutingModule from './user-routing.module';
import UserComponent from './user.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        UserRoutingModule,
        TranslateModule.forChild(),
    ],
    declarations: [UserComponent],
    providers: [AuthGuardService],
    exports: [UserComponent],
})
export default class UserModule {}
