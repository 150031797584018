import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
    selector: 'app-filter-capsule',
    templateUrl: './filter-capsule.component.html',
    styleUrls: ['./filter-capsule.component.scss'],
})
export default class FilterCapsuleComponent {
    @Input()
        text: string;

    @Input()
        class:string = 'sentiment-neutral';

    @Input()
        closeBtn: boolean = true;

    @Output()
        onDelete : EventEmitter<string> = new EventEmitter<string>();

    delete() {
        if (this.closeBtn) {
            this.onDelete.emit(this.text);
        }
    }

    get isEmpty(): boolean {
        return (`${this.text}`).trim().length === 0;
    }
}
