import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Field } from 'app/api/models';

const DEFAULT_ARRAY : Array<Field> = [{
    inputName: 'defaultInputName',
    displayName: 'defaultDisplayName',
}];

@Injectable()
export default class AddColumnsService {
    private filtersSource = new BehaviorSubject<Array<Field>>(DEFAULT_ARRAY);

    currentFilters = this.filtersSource.asObservable();

    changeFilters(filters: Array<Field>) {
        this.filtersSource.next(filters);
    }
}
