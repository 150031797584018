import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LlmProfileConfig } from 'app/api/models/dashboard-config';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';

@Component({
    selector: 'llm-context-bar-chart-configuration-popup',
    templateUrl: './llm-context-bar-chart-configuration-popup.component.html',
    styleUrls: ['./llm-context-bar-chart-configuration-popup.component.scss'],
})
export default class LlmContextBarChartConfigurationPopupComponent implements OnInit {
    constructor(
        public modal: NgbActiveModal,
    ) { }

    params: LlmProfileConfig;

    initialParams: LlmProfileConfig;

    @Output()
    private save: EventEmitter<LlmProfileConfig> = new EventEmitter();

    ngOnInit(): void {
        this.initialParams = _.cloneDeep(this.params);
        this.params = _.cloneDeep(this.initialParams);
    }

    validate() {
        this.save.emit(this.params);
        this.modal.close();
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }

    launchGaEventNbValues(): void {
        gtmClick({
            track_category: 'découverte IA',
            track_name: 'vue histogramme nbre thèmes affichés',
            track_cible: `${this.params.nb_values_chart}`,
        });
    }
}
