// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .corpus-select-list {
  max-width: 590px;
  margin: auto;
}
:host .corpus-select-list .form-control-sm {
  border-color: #ccc !important;
  padding: 4px 40px 6px 12px;
  font-size: 14px;
}
:host .corpus-select-list .form-control-sm[aria-expanded=false] {
  background-color: transparent;
}
:host .corpus-select-list .form-control-sm::placeholder {
  color: #ccc;
}
:host .dropdown-item {
  font-weight: normal;
  padding: 6px 20px;
}
:host .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
:host .dropdown-item.disabled {
  background: #ccc;
  color: #000;
  font-size: 18px;
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
