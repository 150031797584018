import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Inputnamevalues, Searchparams } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import * as _ from 'lodash';

@Directive()
export default abstract class BaseVerticalFilterComponent
    extends BaseComponent
    implements OnInit, OnDestroy {
    searchParams: Searchparams;

    collapsed: boolean = true;

    constructor(private serviceDashboard: DashboardService) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.serviceDashboard.currentFilters.subscribe(
            (filters) => {
                if (filters === null) {
                    return;
                }
                this.searchParams = _.cloneDeep(filters);
            },
        );
    }

    get filters(): Array<Inputnamevalues> {
        return this.searchParams ? this.searchParams.filters : [];
    }

    set filters(filters: Array<Inputnamevalues>) {
        this.searchParams.filters = filters;
    }

    get selectedVerbatim():string {
        return this.searchParams ? this.searchParams.verbatim_dbname : '';
    }

    set selectedVerbatim(value:string) {
        this.searchParams.verbatim_dbname = value;
    }

    abstract get count();
}
