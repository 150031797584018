import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { UserService } from 'app/api/services';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import CorpusService from 'app/utils/services/corpus.service';
import * as _ from 'lodash';

@Component({
    selector: 'aiconfig-share-modal',
    templateUrl: './aiconfig-share-modal.component.html',
    styleUrl: './aiconfig-share-modal.component.scss',
})
export default class AiconfigShareModalComponent {
    shareToAll: boolean = false;

    shareInsideProject: boolean = false;

    shareOutsideProject: boolean = false;

    userSelected = '';

    userSelectedObject = null;

    usersInsideProject = [];

    usersOutsideProject = [];

    email: string = '';

    userNotFound : string = undefined;

    selfuser : boolean = false;

    userAlreadyShared: string = undefined;

    externalUserAdded: string = undefined;

    internalUserAdded: string = undefined;

    @Input()
        corpusService: CorpusService;

    @Input()
        userService: UserService;

    @Input()
        aiconfigService: AiConfigService;

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    private onSave = new EventEmitter();

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    private onCancel = new EventEmitter();

    constructor(private store: Store, public modal: NgbActiveModal) {}

    // Sélectionne un utilisateur dans la liste déroulante lors de la recherche d'un utilisateur
    selectUserFromProject(user) {
        this.userSelectedObject = user.item;
        this.userSelected = `${user.item.surname} ${user.item.givenname}`;
    }

    get usersFromProject() {
        return _.difference(this.corpusService.currentCorpus.getValue().users, this.usersInsideProject).filter((user) => user.username !== this.store.snapshot().user.username);
    }

    addUserInsideProject() {
        this.resetMessages();
        this.internalUserAdded = `${this.userSelectedObject.surname} ${this.userSelectedObject.givenname}`;
        this.usersInsideProject.push(this.userSelectedObject);
        this.userSelected = '';
    }

    get corpusName() {
        return this.corpusService.currentCorpus.getValue().corp_name;
    }

    addUserOutsideProject() {
        this.resetMessages();

        if (this.usersOutsideProject.find((user) => user.email === this.email)) {
            this.userAlreadyShared = this.email;
            return;
        }

        if (this.email === this.store.snapshot().user.mail) {
            this.selfuser = true;
            return;
        }

        this.userService.getV1UserEmailExist(this.email).subscribe({
            next: (userexists) => {
                this.usersOutsideProject.push({ username: userexists.username, email: this.email });
                this.externalUserAdded = this.email;
                this.email = '';
            },
            error: () => {
                this.userNotFound = this.email;
            },
        });
    }

    resetMessages() {
        this.externalUserAdded = undefined;
        this.internalUserAdded = undefined;
        this.userNotFound = undefined;
        this.selfuser = false;
        this.userAlreadyShared = undefined;
    }

    removeUserInsideProject(user) {
        this.resetMessages();
        this.usersInsideProject = this.usersInsideProject.filter((u) => u !== user);
    }

    removeUserOutsideProject(email) {
        this.resetMessages();
        this.usersOutsideProject = this.usersOutsideProject.filter((u) => u !== email);
    }

    // eslint-disable-next-line class-methods-use-this
    isValidEmail(): boolean {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailPattern.test(this.email);
    }

    close() {
        this.onCancel.emit();
        this.modal.close();
    }

    async save() {
        await this.aiconfigService.shareSelectedConfigurations(this.shareToAll, this.shareInsideProject, this.shareOutsideProject, this.usersInsideProject, this.usersOutsideProject);
        this.aiconfigService.closeAllConfigurationDetails.next();

        this.onSave.emit();
        this.modal.close();
    }

    getSaveTrackCible() {
        let value = '';
        if (this.shareToAll) {
            value = 'à tous du projet';

            if (this.shareOutsideProject) {
                value += ' et certains hors projet';
            }
        } else if (this.shareInsideProject) {
            value = 'à certains du projet';

            if (this.shareOutsideProject) {
                value += ' et hors projet';
            }
        } else if (this.shareOutsideProject) {
            value = 'à certains hors projet';
        }
        return value;
    }
}
