import {
    Component, Input, OnInit, ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'app/base.component';
import { Period } from 'app/api/models/distriparams';
import { DashboardService as DashboardApi } from 'app/api/services';
import * as _ from 'lodash';
import AssociatedDatasComponent from '../associated-datas.component';

@Component({
    selector: 'extended-view-popup',
    templateUrl: './extended-view-popup.component.html',
    styleUrls: ['./extended-view-popup.component.scss'],
})
export default class ExtendedViewPopupComponent extends BaseComponent implements OnInit {
    @Input()
        title;

    @Input()
        dbName;

    data;

    @Input()
        type;

    @Input()
        params;

    @Input()
        graphService;

    @Input()
        manageDashboardService;

    @Input()
        dashboardService;

    @Input()
        maxNbValuesChart;

    @Input()
        isMerged = false;

    @Input()
        partialTrackName = '';

    @ViewChild('barchart') barChart;

    @ViewChild('linechart') lineChart;

    newValuesChart;

    get maxNbValuesChartDisabled() {
        return this.type === 'line' && this.isMerged;
    }

    constructor(
        private dashboardApi: DashboardApi,
        public modalRef: NgbActiveModal,
    ) {
        super();
    }

    ngOnInit(): void {
        this.params = _.cloneDeep(this.params);
        this.params.nb_values_chart = this.preventMinMax(this.params.nb_values_chart);
        this.newValuesChart = this.params.nb_values_chart;

        this.updateGraphData();
    }

    /**
     * Détermine si la période est activée ou non
     */
    isEnabledPeriod(period: Period): boolean {
        if (period === Period.day) {
            return this.graphService.isDayAllowedForGraph();
        }
        if (period === Period.week) {
            return this.graphService.isWeekAllowedForGraph();
        }
        return true;
    }

    /**
     * Evenement au changement de la valeur saisie dans le champ max value.
     * Force la valeur entre 0 et maxValue
     */
    preventMinMax(nbValuesChart) {
        if (nbValuesChart <= 0) {
            return 1;
        } if (nbValuesChart > this.maxNbValuesChart) {
            return this.maxNbValuesChart;
        }
        return nbValuesChart;
    }

    /**
     * Evenement déclenché lors du changement du nombre d'éléments.
     * Selon le type de graphique, appel ou non de l'api
     */
    async onValidGraphValue() {
        this.newValuesChart = this.preventMinMax(this.newValuesChart);
        this.params.nb_values_chart = this.newValuesChart;
        // pour les autres (line), appel API et MAJ des datas
        this.updateGraphData();
    }

    /**
     * Evenement déclaché lors du clique sur les boutons Jour/Mois/Semaine.
     * Appel l'API pour récupérer les nouvelles données et met à jour le graphique
     */
    async onChangePeriod(period: Period) {
        if (this.isEnabledPeriod(period)) {
            this.params.period = period;
            this.updateGraphData();
        }
    }

    /**
     * Effectue un appel à l'API pour obtenir les nouvelles données en fonction des paramètres demandés.
     * Met à jour les données du graphique
     */
    async updateGraphData(): Promise<void> {
        const userChoices = {
            db_name: this.dbName,
            curve_params: {},
            bar_params: {},
        };

        if (this.type === 'line') {
            userChoices.curve_params = this.params;
            delete userChoices.bar_params;
        } else if (this.type === 'bar') {
            userChoices.bar_params = this.params;
            delete userChoices.curve_params;
        }

        const resp = await AssociatedDatasComponent.callApiToGetAllGraphicalDatas(
            this.manageDashboardService,
            this.dashboardService,
            this.dashboardApi,
            [userChoices],
        );

        this.data = resp.associated_data[this.dbName];
    }

    /**
     * Ferme la popin
     */
    close() {
        this.modalRef.close(false);
    }

    get chart() {
        return this[`${this.type}Chart`].chart;
    }
}
