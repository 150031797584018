import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Injectable } from '@angular/core';

@Directive()
@Injectable()
export default class ChartTooltipsService {
    constructor(
        @Inject(DOCUMENT) private the_document: Document,
    ) {}

    // eslint-disable-next-line class-methods-use-this
    draw(tooltipModel, chart, html: string): void {
        let tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div class="tooltips-content"></div>';
            document.body.appendChild(tooltipEl);
        }

        const tableRoot = tooltipEl.querySelector('.tooltips-content');
        tableRoot.innerHTML = html;

        tooltipEl.style.position = 'absolute';
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            tooltipEl.style.display = 'none';
            return;
        }
        tooltipEl.style.display = 'block';
        tooltipEl.style.opacity = '1';

        const position = chart.canvas.getBoundingClientRect();

        const tw = tooltipEl.offsetWidth;
        const th = tooltipEl.offsetHeight;

        if (tw / 2 > position.left + tooltipModel.caretX) {
            tooltipEl.className = 'left';
            tooltipEl.style.transform = `translate(
      ${position.left + tooltipModel.caretX + 10}px,
      ${position.top + tooltipModel.caretY + document.documentElement.scrollTop - th / 2}px)`;
        } else if (
            tw / 2 + (position.left + tooltipModel.caretX) > window.innerWidth) {
            tooltipEl.className = 'right';
            tooltipEl.style.transform = `translate(
      ${position.left + tooltipModel.caretX - tw - 10}px,
      ${position.top + tooltipModel.caretY + document.documentElement.scrollTop - th / 2}px)`;
        } else {
            tooltipEl.className = 'center';
            tooltipEl.style.transform = `translate(
      ${position.left + tooltipModel.caretX - tw / 2}px,
      ${position.top + tooltipModel.caretY + document.documentElement.scrollTop - th - 10}px)`;
        }
    }
}
