import { Newstranslation } from './newstranslation';

export enum NewsType {
    GENERAL = '0',
    IMPORTANT = '1',
    FAULT_REPORT = '2',
}

export interface News {
    id?: number;

    /**
   * News broadcast date in RFC3339 format
   */
    date?: string;

    /**
   * News category id
   */
    category?: number;
    type?: NewsType;
    lang?: string;
    isRecent?: boolean;
    isHeadline?: boolean;
    translations?: { [key: string]: Newstranslation };
}
