import {
    Component, Output, EventEmitter, OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { VerbatimConfigField } from 'app/api/models/verbatimconfig';
import { CloudData } from '../word-cloud.interfaces';
import analytics from '../words.analytics';
import BaseCloudComponent from '../BaseCloudComponent';

@Component({
    selector: 'app-extended-cloud',
    templateUrl: './extended-cloud.component.html',
    styleUrls: ['./extended-cloud.component.scss'],
})
export default class ExtendedCloudComponent extends BaseCloudComponent implements OnInit {
    @Output()
        hide: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Output()
        restore : EventEmitter<CloudData[]> = new EventEmitter<CloudData[]>();

    wordingsAnalytics :any = analytics;

    dashboardService: DashboardService;

    useDeltaTone: boolean;

    constructor(
        translate: TranslateService,
        public modalRef: NgbActiveModal,
    ) {
        super(translate);
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard !== null) {
                this.config = { ...this.dashboardService.currentConfig.getValue().verbatim };
                this.buildWordsList();
            }
        });
    }

    set selectedSize(size:number) {
        this.config.extended_cloud_max_size = size;
        this.dashboardService.setVerbatimConfig(VerbatimConfigField.extended_cloud_max_size, size);
    }

    get selectedSize() {
        return this.config.extended_cloud_max_size;
    }

    close() {
        this.modalRef.close();
    }

    hideWord(hidden: CloudData) {
        super.hideWord(hidden);
        this.hide.emit(hidden);
    }

    restoreWord(words: CloudData[]) {
        super.restoreWord(words);
        this.restore.emit(words);
    }

    /**
     * Méthode appelée lors du changement des category/sous-category du select
     */
    saveCategory({ category, subCategory, userInteraction }) {
        // Si c'est l'utilisateur qui a changé le select, on sauvegarde dans les configs
        if (userInteraction) {
            this.dashboardService.setCloudCategory(this.category, this.subCategory);
        }
        this.onChangeCategory({ category, subCategory });
    }
}
