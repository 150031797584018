/* eslint-disable no-constant-condition */
import {
    ChangeDetectorRef,
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MatrixPoint } from 'app/api/models/matrixdata';
import { BaseComponent } from 'app/base.component';
import ExportType from 'app/modules/CommonExportTypeEnum';
import ModalGraphExportComponent from 'app/modules/corpus/components/modal-graph-export/modal-graph-export.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { GraphParamsConfig, MatriceDeltaParam } from 'app/api/models/dashboard-config';
import { ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as _ from 'lodash';
import { MergedMatrixData, MergedMatrixPoint } from 'app/api/models/paramsformerge';
import addDashboardNumberToBubbleChart from 'app/utils/chartjs-plugins/add-dashboard-number-to-bubble-chart';
import { GraphConf } from '../../../distribution-graphs.component';
import { LegendItem } from '../../../../graphs-generic-components/graph-radio-legend/graph-radio-legend.component';

@Component({
    selector: 'app-distribution-bubble-chart',
    templateUrl: './distribution-bubble-chart.component.html',
    styleUrls: ['./distribution-bubble-chart.component.scss'],
})
export default class DistributionBubbleGraphComponent extends BaseComponent implements OnChanges {
    @Input()
        dashboardService: DashboardService;

    @Input()
        conf: GraphConf;

    @ViewChild('chart')
        chart: BaseChartDirective;

    @Input()
        data: MergedMatrixData;

    @Output()
        configChanged: EventEmitter<GraphParamsConfig> = new EventEmitter();

    chartData: ChartData;

    deltaSettings: MatriceDeltaParam;

    isZoomed = false;

    chartPlugins = [ChartDataLabels, zoomPlugin, addDashboardNumberToBubbleChart];

    options;

    legendItems: Array<LegendItem> = [];

    allAnnotations = {};

    bsModalRef: NgbModalRef;

    analyticsBubbleChart = {
        class: {
            track_category: 'classification automatique',
            partial_track_name: 'matrice verbatim classification',
        },
        thematics: {
            track_category: 'thématiques',
            partial_track_name: 'matrice verbatim thématique',
        },
    };

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    constructor(
        private graphService: GraphService,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: NgbModal,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // On met à jour le graphique à chaque fois que les données changent
        if (changes.data) {
            this.deltaSettings = this.currentConfig.getValue()[this.conf.type].matrice;
            this.loadChartData();
        }
    }

    get currentConfig() {
        return this.dashboardService
            ? this.dashboardService.currentConfig
            : this.manageDashboardService.currentDashboardComparisonConfig;
    }

    /**
     * Charge les données dans le graphique, définit les options et crée la légende
     */
    loadChartData() {
        const chartData: ChartData = { labels: [], datasets: [] };
        let settings: MatriceDeltaParam;
        this.allAnnotations = {};
        this.setOptions();
        this.changeDetectorRef.detectChanges();

        if (this.data.deltasat && this.data.deltasat.length) {
            const bubbleSatData = this.updateBubbleData(MatriceDeltaParam.SAT, this.data.deltasat);
            chartData.datasets.push(bubbleSatData.deltaData);
            this.allAnnotations = { ...this.allAnnotations, ...bubbleSatData.annotations };
            settings = MatriceDeltaParam.SAT;
        }
        if (this.data.deltatone && this.data.deltatone.length) {
            const bubbleToneData = this.updateBubbleData(MatriceDeltaParam.TONE, this.data.deltatone);
            chartData.datasets.push(bubbleToneData.deltaData);
            this.allAnnotations = { ...this.allAnnotations, ...bubbleToneData.annotations };
            settings = MatriceDeltaParam.TONE;
        }
        // On prend la config en priorité ou sinon on choisit en fonction de la réponse
        this.deltaSettings = this.deltaSettings ?? settings;
        // On met à jour les options du graphique
        this.setOptions();

        // Si on a les 2 delta, on masque le dataset qui n'est pas sélectionné (attention les données deltasat sont en index 0 et deltatone en index 1 )
        if (chartData.datasets.length === 2) {
            chartData.datasets[(this.deltaSettings === MatriceDeltaParam.TONE) ? 0 : 1].hidden = true;
        }

        // On met à jour les datas du graphique
        this.chartData = chartData;
        this.changeDetectorRef.detectChanges();

        if (chartData.datasets.length) {
            // Création de la legende
            this.createLegend();
        }
    }

    /**
     * Met à jour les options du graphique
     */
    setOptions() {
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            animation: false,
            plugins: {
                legend: {
                    display: false,
                },
                addDashboardNumberToBubbleChart: !this.dashboardService,
                annotation: {
                    clip: false,
                    annotations: this.allAnnotations,
                },
                tooltip: {
                    callbacks: {
                        title() {
                            return '';
                        },
                        label: (context) => {
                            const data = context.dataset.data[context.dataIndex];
                            const deltaWord = this.translateService.instant(`translations.analysisDashboard.charts.${(this.deltaSettings === MatriceDeltaParam.TONE) ? 'deltaTone' : 'deltaSat'}`);
                            const dashNumber = this.manageDashboardService.dashboardServices.length > 1 ? ` [${data.dashboardNumber}]` : '';
                            return ` ${data.label}${dashNumber} - ${this.translateService.instant('translations.analysisDashboard.charts.occurrences')} ${data.x}% - ${deltaWord} ${data.y}%`;
                        },
                    },
                },
                datalabels: {
                    formatter: (value:any, context) => {
                        const dataset = (context.dataset.data as unknown as MatrixPoint[]).map((d, index) => ({ ...d, index }));
                        const samePoints = dataset.filter((d, index) => (index !== context.dataIndex && d.x === value.x && d.y === value.y));
                        if (samePoints.length > 0) {
                            if (context.dataIndex < samePoints[0].index) {
                                samePoints.push(value);
                                context.values = samePoints.sort((a:any, b:any) => ((a.label === b.label && a.dashboardNumber < b.dashboardNumber ? -1 : 1)));
                                return samePoints.map((sp:any) => (this.legendItems.find((legend) => legend.inputName === sp.label)?.selected ? `${sp.label}` : ''));
                            }
                            return '';
                        }
                        context.values = [value];
                        return this.legendItems.find((legend) => legend.inputName === value.label)?.selected ? `${value.label}` : '';
                    },
                    color: 'black',
                    font: {
                        weight: 'bold',
                    },
                    offset: 15,
                    anchor: 'center',
                    align(context) {
                        const middleChart = context.chart.scales.x.min + ((context.chart.scales.x.max - context.chart.scales.x.min) / 2);
                        return +(context.dataset.data[context.dataIndex] as unknown as MatrixPoint).x < middleChart ? 'right' : 'left';
                    },
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'xy',
                        modifierKey: 'ctrl',
                        onPan: ({ chart }) => {
                            chart.canvas.style.cursor = 'move';
                        },
                        onPanComplete: ({ chart }) => {
                            this.isZoomed = true;
                            this.changeDetectorRef.detectChanges();
                            chart.canvas.style.cursor = 'default';
                        },
                    },
                    zoom: {
                        drag: {
                            enabled: true,
                        },
                        mode: 'xy',
                        onZoomComplete: () => {
                            this.isZoomed = true;
                            this.changeDetectorRef.detectChanges();
                        },
                    },
                    limits: {
                        y: { min: -110, max: 110, minRange: 5 },
                        x: { min: -110, max: 110, minRange: 5 },
                    },
                },
            },
            scales: {
                x: {
                    type: 'linear',
                    title: {
                        display: true,
                        text: () => this.translateService.instant(`translations.analysisDashboard.charts.${(this.conf.type === 'class') ? 'xMatrixClass' : 'xMatrixThematic'}`),
                    },
                    ticks: {
                        color: '#000',
                        callback(value: number) {
                            if (!Number.isInteger(value)) {
                                return '';
                            }
                            return `${value}%`;
                        },
                    },
                },
                y: {
                    type: 'linear',
                    title: {
                        display: true,
                        text: () => this.translateService.instant(`translations.analysisDashboard.charts.${this.deltaSettings === MatriceDeltaParam.TONE ? 'deltaTone' : 'deltaSat'}`),
                    },
                    ticks: {
                        color: '#000',
                        callback(value) {
                            if (!Number.isInteger(value)) {
                                return '';
                            }
                            return `${value}%`;
                        },
                    },
                },
            },
        };
    }

    /**
     * Met à jour les données reçues du backend pour les affichés dans les graphiques
     */
    updateBubbleData(type: MatriceDeltaParam, data: Array<MergedMatrixPoint>) {
        const deltaData = {
            label: type, data: [], backgroundColor: [], hoverBackgroundColor: [], hoverBorderColor: [], hoverRadius: 2,
        };
        const annotations = {};

        data.forEach((point) => {
            const color = this.manageDashboardService.getDataColor(this.conf.type, point.label);

            point.merged_values.forEach((value, index) => {
                const dashboardNumber = index + 1;
                if (value) {
                    deltaData.data.push({
                        x: value.x,
                        y: value.y,
                        r: 8,
                        label: point.label,
                        dashboardNumber,
                    });
                    deltaData.backgroundColor.push(color);

                    // On récupère la valeur du dashboard précédent ou celui encore d'avant si pas de valeur au précédent
                    let prevValue = point.merged_values[index - 1];
                    if (!prevValue) {
                        prevValue = point.merged_values[index - 2];
                    }
                    // On créé les lignes à partir du moment où on a au moins 2 points
                    // mais que leurs valeurs x ou y ne sont pas null
                    // et que leurs valeurs sont différentes
                    if (index > 0 && prevValue
                        && (`${value.x}` !== 'null' && `${value.y}` !== 'null' && `${prevValue.x}` !== 'null' && `${prevValue.y}` !== 'null')
                        && (parseFloat(value.x) !== parseFloat(prevValue.x) || parseFloat(value.y) !== parseFloat(prevValue.y))) {
                        annotations[`line_${type}_${point.label}_${index}`] = {
                            type: 'line',
                            data: {
                                label: point.label,
                            },
                            borderColor: color,
                            borderWidth: 2,
                            arrowHeads: {
                                end: {
                                    display: true,
                                    fill: true,
                                    length: 10,
                                },
                            },
                            xMin: prevValue.x,
                            xMax: value.x,
                            xScaleID: 'x',
                            yMin: prevValue.y,
                            yMax: value.y,
                            yScaleID: 'y',
                            display: (ctx) => ctx.id?.startsWith(`line_${this.deltaSettings}_`) && this.legendItems
                                .find((item) => item.inputName === this.allAnnotations[ctx.id]?.data.label)?.selected,
                        };
                    }
                }
            });
        });
        deltaData.hoverBorderColor = deltaData.backgroundColor;
        deltaData.hoverBackgroundColor = deltaData.backgroundColor;
        return { deltaData, annotations };
    }

    /**
     * Création de la légende avec la liste des labels des classes ou thématiques.
     * Récupère la configuration sauvegardée sur le dashboard et sélectionne ou non les labels
     */
    createLegend() {
        this.legendItems = [];
        // Récupère la configuration du dashboard
        const selectedBars = this.currentConfig.getValue()[this.conf.type].legend_matrix;
        // On parcourt les données qui sont affichées
        this.chartData.datasets.filter((dataset) => !dataset.hidden)[0].data.forEach((data:any, index) => {
            const color = this.manageDashboardService.getDataColor(this.conf.type, data.label);
            // Détermine si le label doit être affiché (si il est dans la liste de la config, alors il doit être caché)
            const selected = !selectedBars.includes(data.label);
            // Si on n'a pas déjà la légende dans la liste, on l'ajoute
            if (!this.legendItems.find((item) => item.inputName === data.label)) {
                this.legendItems.push({
                    color,
                    displayName: data.label,
                    inputName: data.label,
                    selected,
                });
            }
            // Si le label doit être masqué
            if (!selected) {
                // On cache le point dans les 2 datasets (deltatone et deltasat)

                // attention le detatone ou le deltasat peuvent être absent donc il faut tester la taille du datasets d'abord
                if (this.chartData.datasets.length > 0) {
                    this.chart.chart.hide(0, index);
                }
                if (this.chartData.datasets.length > 1) {
                    this.chart.chart.hide(1, index);
                }
            }
        });
        this.chart.chart.update();
    }

    /**
     * Méthode utilisée lors du changement de configuration du graphique DeltaTone ou DeltaSat.
     * On affiche le dataset correspondant et on masque l'autre.
     */
    onChangeDeltaSettings() {
        // Sauvegarde des infos sur le dashboard
        const params: GraphParamsConfig = this.currentConfig.getValue()[this.conf.type];
        params.matrice = this.deltaSettings;

        if (this.dashboardService) {
            this.dashboardService.setDistributionConfig(this.conf.type, params);
        } else {
            this.manageDashboardService.setComparisonDashbardConfig(this.conf.type, params);
        }

        let trackCible = '';
        // Mise à jour des données du graphique
        if (this.deltaSettings === MatriceDeltaParam.TONE) {
            this.chart.chart.setDatasetVisibility(1, true);
            this.chart.chart.setDatasetVisibility(0, false);
            trackCible = 'deltatone';
        } else {
            this.chart.chart.setDatasetVisibility(1, false);
            this.chart.chart.setDatasetVisibility(0, true);
            trackCible = 'deltasat';
        }
        gtmClick({
            track_category: this.analyticsBubbleChart[this.conf.type].track_category,
            track_name: `${this.analyticsBubbleChart[this.conf.type].partial_track_name} classement`,
            track_cible: trackCible,
        });
        this.onResetZoom();
        // MAJ du graph
        this.chart.chart.update();
    }

    /**
     * Méthode utilisée pour réinitialiser le zoom du graphique
     */
    onResetZoom() {
        this.chart.chart.resetZoom();
        this.isZoomed = false;
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Evenement lors du clic sur le bouton 'Exporter' du graphique en bar horizontale.
     * Ouvre la popin d'export et selon la sélection de l'utilisateur, exporte au bon format (PNG ou CSV)
     */
    onExportBarChart() {
        this.bsModalRef = this.modalService.open(ModalGraphExportComponent, {});
        this.bsModalRef.componentInstance.titleToTranslate = 'translations.analysisDashboard.charts.exportGraphModal.title.matrix';
        this.bsModalRef.componentInstance.type = this.conf.type;
        this.bsModalRef.componentInstance.trackNameStart = 'matrice verbatim';
        this.bsModalRef.componentInstance.export.subscribe((type: ExportType) => {
            const graphTitle = this.translateService.instant(this.conf.matrice_title, { model: (this.manageDashboardService.getCurrentModel()?.model ?? '') });
            if (type === ExportType.PNG) {
                this.graphService.exportChartToPng(this.chart, graphTitle);
            } else if (type === ExportType.CSV) {
                let csvHeader = ['label'];
                const csvData = _.uniqBy([
                    ...this.data.deltasat.map((value) => ({ label: value.label })),
                    ...this.data.deltatone.map((value) => ({ label: value.label })),
                ], 'label');
                if (this.dashboardService) {
                    csvHeader = [...csvHeader, ...['occurrence', 'deltasat', 'deltatone']];
                    csvData.forEach((csv) => {
                        const deltatone = this.data.deltatone.find((value) => value.label === csv.label);
                        const deltasat = this.data.deltasat.find((value) => value.label === csv.label);
                        csv.occurence = deltatone?.merged_values[0].x || deltasat?.merged_values[0].x;
                        csv.deltasat = deltasat ? deltasat.merged_values[0]?.y : '';
                        csv.deltatone = deltatone ? deltatone.merged_values[0]?.y : '';
                    });
                } else {
                    this.manageDashboardService.dashboardServices.forEach((s, i) => {
                        const prefix = `dashboard ${i + 1} - `;
                        csvHeader = [...csvHeader, ...[`${prefix}occurrence`, `${prefix}deltasat`, `${prefix}deltatone`]];
                        csvData.forEach((csv) => {
                            const deltatone = this.data.deltatone.find((value) => value.label === csv.label);
                            const deltasat = this.data.deltasat.find((value) => value.label === csv.label);
                            csv[`${prefix}occurence`] = deltatone?.merged_values[i]?.x || deltasat?.merged_values[i]?.x;
                            csv[`${prefix}deltasat`] = deltasat ? deltasat.merged_values[i]?.y : '';
                            csv[`${prefix}deltatone`] = deltatone ? deltatone.merged_values[i]?.y : '';
                        });
                    });
                }

                this.graphService.exportDataToCsv(csvData, csvHeader, graphTitle);
            }
        });
    }

    /**
     * Evènement lorsque l'on clique sur l'activation ou la désactivation d'un élément de la légende
     */
    onToggleLegend(toggleIndex: number) {
        if (!this.legendItems[toggleIndex]) {
            return;
        }

        const params: GraphParamsConfig = this.currentConfig.getValue()[this.conf.type];

        // Cache ou affiche le ou les points concernés par la légende
        const clickedLegend = this.legendItems[toggleIndex];
        const dataIndex = this.chartData.datasets.filter((dataset) => !dataset.hidden)[0].data
            .map((data:any, index) => ((data.label === clickedLegend.inputName ? index : null))).filter((e) => e !== null);

        dataIndex.forEach((dIndex) => {
            const toggleMethod = clickedLegend.selected ? 'show' : 'hide';
            this.chartData.datasets.forEach((dataset, datasetIndex) => {
                this.chart.chart[toggleMethod](datasetIndex, dIndex);
                // eslint-disable-next-line @typescript-eslint/dot-notation
                this.chartData.datasets[datasetIndex].data[dIndex]['hidden'] = !clickedLegend.selected;
            });
        });
        this.chart.chart.update();

        // Met à jour la configuration
        if (!clickedLegend.selected) {
            params.legend_matrix.push(clickedLegend.inputName);
        } else {
            params.legend_matrix = params.legend_matrix.filter((legend) => legend !== clickedLegend.inputName);
        }

        params.legend_matrix.sort();

        if (this.dashboardService) {
            this.dashboardService.setDistributionConfig(this.conf.type, params);
        } else {
            this.manageDashboardService.setComparisonDashbardConfig(this.conf.type, params);
        }
    }
}
