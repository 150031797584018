// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .cloud-label .capsule {
  font-weight: bold;
  border: 2px solid #ccc;
  margin-bottom: 4px;
}
:host ::ng-deep .cloud-label .capsule:hover {
  border-width: 2px;
}
:host ::ng-deep .cloud-label .capsule.new-capsule {
  animation: flash 0.3s ease-in-out 5;
}

@keyframes flash {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: #ff7900;
  }
}
.search {
  width: 100%;
  height: 165px;
}

.cloud-label {
  border-top: 1px solid #ccc;
  padding-top: 5px;
  margin-top: 15px;
  font-size: 14px;
}

.similarity {
  font-size: 14px;
}

.similarity-checkbox {
  padding-left: 0;
  display: flex;
  align-items: center;
}
.similarity-checkbox .form-check-input {
  margin: 0 10px;
  width: 40px;
  height: 20px;
  background-size: 10px, 18px;
  background-position: right 4px top 50%, 0 0;
}
.similarity-checkbox .form-check-input:checked {
  background-position: 4px 50%, 100% 0;
}
.similarity-checkbox .form-check-label.disabled {
  color: #ccc;
}

.btn-download,
.btn-reload,
.btn-thematics {
  padding: 0;
  width: 22px;
  height: 22px;
}

.icon-info {
  font-size: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
