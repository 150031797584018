// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:host ::ng-deep .table .scrollview {
  max-height: calc(-194px - 2rem + 100vh);
}

.css-project-title {
  grid-area: title;
}

.css-project-form {
  grid-area: project-form;
  max-width: 900px;
}

.css-project-form-2 {
  grid-area: project-form-2;
}

.css-project-actions {
  grid-area: project-action;
  padding-top: 15px;
  background: white;
}
.css-project-actions button {
  margin-left: 15px;
}

.pop-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  /* stylelint-disable-next-line selector-class-pattern */
}
.pop-status .icon {
  display: none;
}
.pop-status.text-color-success .vcrm-tick {
  display: block;
}
.pop-status.text-color-info .vcrm-clock-white {
  display: block;
}
.pop-status.text-color-danger .icon-Warningimportant {
  display: block;
}

.alert .alert-content {
  max-width: 600px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
