import { Component } from '@angular/core';

/**
 * Auth
 *
 * @export
 * @class AuthComponent
 */
@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export default class AuthComponent {}
