import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[regExp]',
})
export default class RegExpDirective {
    @Input()
        regExp: string;

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        // verification que la donnée contenu dans l'event correspond bien à la regexp demandée
        const reg = new RegExp(this.regExp);
        if (!reg.test(event.key)) {
            event.preventDefault();
        }
    }
}
