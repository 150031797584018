// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .tooltip-heatmap .tooltip.bottom,
::ng-deep .tooltip-inner {
  max-width: 400px;
}
::ng-deep .heatmap-legend {
  margin-left: 10px;
  font-weight: normal;
}
::ng-deep .heatmap-legend .heatmap-legend-item {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
}
::ng-deep .heatmap-legend .hl-4 {
  height: 120px;
}
::ng-deep .heatmap-legend .hl-5 {
  height: 150px;
}
::ng-deep .heatmap-legend .hl-1 {
  height: 30px;
}
::ng-deep .heatmap-legend .heatmap-legend-desc {
  margin-left: 10px;
}
::ng-deep .heatmap-legend .heatmap-legend-desc > div {
  padding-left: 10px;
  position: relative;
}
::ng-deep .heatmap-legend .vertical-line {
  border-left: 2px solid #ddd;
}
::ng-deep .heatmap-legend .no-vertical-line {
  border-left: 2px solid transparent;
}
::ng-deep .heatmap-legend .down {
  border-color: #527edb !important;
}
::ng-deep .heatmap-legend .up {
  border-color: #cd3c14 !important;
}
::ng-deep .heatmap-legend .arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  left: -5px;
}
::ng-deep .heatmap-legend .arrow.down {
  transform: rotate(45deg);
  bottom: 0;
}
::ng-deep .heatmap-legend .arrow.up {
  transform: rotate(-135deg);
  top: 0;
}
::ng-deep .heatmap-legend .tiret {
  border: solid black;
  display: inline-block;
  padding: 3px;
  position: absolute;
  width: 10px;
  left: -6px;
}
::ng-deep .heatmap-legend .tiret.down {
  top: 0;
  border-width: 2px 0 0;
}
::ng-deep .heatmap-legend .tiret.up {
  bottom: 0;
  border-width: 0 0 2px;
}
::ng-deep .heatmap-legend .tiret.none {
  border-color: #ddd !important;
}

.row-loading {
  height: 600px;
}

.placeholder-text {
  max-width: 700px;
  height: 600px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
