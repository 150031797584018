import {
    Component, ElementRef, HostListener, Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-burger-menu',
    templateUrl: './burger-menu.component.html',
    styleUrls: ['./burger-menu.component.scss'],
})
export default class BurgerMenuComponent {
    isOpen: boolean = false;

    @Input()
        hasTones: boolean = false;

    @HostListener('document:mousedown', ['$event'])
    onClickOutSide(e:Event): void {
        if (!this.element.nativeElement.contains(e.target) || (e.target as HTMLElement).id === 'dashboard-overlay-menu') {
            this.isOpen = false;
        }
    }

    constructor(
        private element: ElementRef,
        private route: ActivatedRoute,
    ) {
    }

    get isSettings() {
        return this.route.snapshot.url[0].path === 'settings';
    }
}
