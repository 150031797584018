// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}
:host .form-control-sm {
  padding: 4px 10px;
  font-size: 14px;
}
:host .chart {
  flex-grow: 1;
}
:host .btn-check[disabled] + .btn {
  pointer-events: initial;
  cursor: default;
}

.btn-download {
  padding: 8px;
}
.btn-download i::before {
  font-size: 20px;
}

.graph-container {
  height: 100%;
  display: block;
}

::ng-deep #chartjs-tooltip {
  z-index: 1060;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
