import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';
import { UserService } from 'app/api/services';
import { SetUser } from 'app/stores/actions/user/user.actions';
import { SetGdprRedirect } from 'app/stores/actions/settings/settings.actions';

@Component({
    selector: 'app-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss'],
})
export default class GdprComponent implements OnInit {
    gdprChecked = false;

    public check: string;

    public body: string;

    settings$: Observable<SettingsModel>;

    constructor(
        private userService: UserService,
        private store: Store,
        private router: Router,
        private translation: TranslateService,
    ) {
        if (this.store.snapshot().user.gdpr.isValid) {
            this.redirect();
        }

        this.translateGdpr(this.store.snapshot().settings);
    }

    ngOnInit() {
        this.settings$ = this.store.select(SettingsState);
        this.settings$.subscribe((settings) => {
            this.translateGdpr(settings);
        });

        this.translation.onLangChange.subscribe(() => {
            this.translateGdpr(this.store.snapshot().settings);
        });
    }

    private translateGdpr(settings) {
        this.check = settings.vcrm[
            `gdpr_casetext_${this.translation.currentLang.toLowerCase()}`
        ];
        this.body = settings.vcrm[
            `gdpr_maintext_${this.translation.currentLang.toLowerCase()}`
        ];
    }

    validGdpr() {
        this.gdprChecked = !this.gdprChecked;
    }

    sendGdpr() {
        if (this.store.snapshot().user.gdpr.gdpraccept_date === '') {
            this.userService.postV1UserGdpr().subscribe(() => {
                this.redirect();
            });
        } else {
            this.userService.putV1UserGdpr().subscribe(() => {
                this.redirect();
            });
        }
    }

    redirect() {
        this.userService
            .getV1UserUsername(this.store.snapshot().user.username)
            .subscribe((profil) => {
                this.store.dispatch(new SetUser(profil)).subscribe(() => {
                    const redirect = this.store.snapshot().settings.gdprRedirect;
                    this.store.dispatch(new SetGdprRedirect('/'));
                    this.router.navigate([redirect]);
                });
            });
    }
}
