import {
    Component, OnInit, OnDestroy, Output, EventEmitter, SecurityContext,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Store, Actions, ofActionSuccessful,
} from '@ngxs/store';
import { News } from 'app/api/models';
import { TranslateService } from '@ngx-translate/core';
import { NewsStateModel } from 'app/stores/models/news/news-state.model';
import * as _ from 'lodash';
import NewsState from 'app/stores/state/news/news.state';
import { DomSanitizer } from '@angular/platform-browser';
import { SetNews } from 'app/stores/actions/news/news.actions';
import { BaseComponent } from 'app/base.component';
import { NewsType } from 'app/api/models/news';

@Component({
    selector: 'app-news-details',
    templateUrl: './news-details.component.html',
})
export default class NewsDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
    NewsType = NewsType;

    @Output()
        newsset: EventEmitter<any> = new EventEmitter();

    newsState: Observable<NewsStateModel>;

    // Common variables
    newsId: number;

    news$: News = null;

    currentLang; // must be in lower case

    hasNewsStateDispatched: boolean = false;

    isTranslate: boolean = true;

    constructor(
        private actions$: Actions,
        private domSanitizer: DomSanitizer,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
    ) {
        super();
    }

    /** ******************** */
    /* LIFECYCLE FUNCTIONS */
    /** ******************** */

    ngOnInit() {
        this.currentLang = this.translate.currentLang.toLowerCase();
        if (this.news$ !== null) {
            this.isTranslate = this.currentLang in this.news$.translations;
        }

        this.subs.sink = this.route.paramMap.subscribe(
            (params) => {
                if (params.get('id')) {
                    this.newsId = parseInt(params.get('id'), 10);
                    this.setNewsInfos();
                } else {
                    this.router.navigate(['error/404']);
                }
            },
        );

        this.newsState = this.store.select(NewsState);
        this.subs.sink = this.newsState.subscribe((state) => {
            if (!_.isEmpty(state.newsList)) {
                this.hasNewsStateDispatched = true;
                this.setNewsInfos();
            }
        });

        this.subs.sink = this.actions$
            .pipe(ofActionSuccessful(SetNews))
            .subscribe(() => {
                this.hasNewsStateDispatched = true;
                this.setNewsInfos();
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.newsset.emit(null);
    }

    /** ****************** */
    /* GETTERS FUNCTIONS */
    /** ****************** */

    get title() {
        return this.currentLang in this.news$.translations ? this.news$.translations[this.currentLang].title : null;
    }

    get content() {
        return this.domSanitizer.sanitize(
            SecurityContext.HTML,
            this.currentLang in this.news$.translations ? this.news$.translations[this.currentLang].content : null,
        );
    }

    /** ***************** */
    /* COMMON FUNCTIONS */
    /** ***************** */

    private setNewsInfos() : void {
        if (this.newsId && this.hasNewsStateDispatched) {
            this.news$ = this.store.snapshot().news.newsList.find((item) => (item.id).toString() === this.newsId.toString());

            // Vérification que l'actualité est bien définie
            if (this.news$ === undefined) {
                this.router.navigate(['error/404']);
            } else {
                this.isTranslate = this.currentLang in this.news$.translations;
                this.emitNewsEvent();
            }
        }
    }

    private emitNewsEvent() {
        this.newsset.emit(this.news$);
    }
}
