import { Component, Input } from '@angular/core';
import { Descriptionmodel } from 'app/api/models';

@Component({
    selector: 'filters-classifications-auto-popup-model-info-field',
    templateUrl: './filters-classifications-auto-popup-model-info-field.component.html',
    styleUrls: ['./filters-classifications-auto-popup-model-info-field.component.scss'],
})
export default class FiltersClassificationsAutoPopupModelInfoFieldComponent {
    @Input()
    private field: Descriptionmodel;

    get isEmpty() {
        return !this.hasExplanations && !this.hasSubClasses;
    }

    get hasExplanations() {
        return this.field.explanation && this.field.explanation.trim().length !== 0;
    }

    get hasSubClasses() {
        return this.field.subclass.length > 0;
    }
}
