import { Period } from 'app/api/models/distriparams';
import { HeatmapParams } from 'app/api/models/graphparams';
import { GraphToSatisfaction } from 'app/api/models/graphtosatisfaction';
import { XaxisSort, Yaxis } from 'app/api/models/histoparams';
import { VerbatimConfig } from 'app/api/models/verbatimconfig';
import { Choice } from 'app/api/models/choice';
import { CopcConfig } from 'app/api/models/copcconfig';

export enum MatriceDeltaParam {
    SAT = 'sat',
    TONE = 'tone',
}

export enum AssociatedDataDisplay {
    histo = 'histo',
    distri = 'distri',
    both = 'both',
}

export interface DashboardConfig {
    userChoices?: Array<Choice>;
    associated_data_display?: AssociatedDataDisplay;
    satisfaction?: GraphToSatisfaction;
    verbatim?: VerbatimConfig;
    class?:GraphParamsConfig;
    thematics?: GraphParamsConfig;
    evoTemp?: LineChartParamsConfig;
    filters?: FilterParamsConfig;
    heatmap?: HeatmapParams;
    copc?: CopcConfig;
    comparison?: ComparisonConfig
    sort_anno?: boolean
    sort_favo?: boolean
    llmProfileConfig?: Array<LlmProfileConfig>
}

export enum KeywordExpression {
    KEYWORD = 'keyword',
    EXPRESSION = 'expression',
}

export interface TopKeywordExpressionConfig {
    top: KeywordExpression;
    max_value: number;
}

export interface LlmProfileConfig {
    id?: number;
    xaxis_sort?: XaxisSort;
    yaxis?: Yaxis;
    nb_values_chart?: number;
    hidden_profile?: boolean;
    hidden_themes_cards?:Array<string>;
    selected_theme_details?: string;
    hidden_themes_graph?: Array<string>;
}

export interface GraphParamsConfig {
    distri: LineChartParamsConfig,
    histo: BarChartParamsConfig;
    matrice?: MatriceDeltaParam;
    legend_distri?: Array<string>,
    legend_distri_merged?: string,
    legend_histo?: Array<string>,
    legend_matrix?: Array<string>,
    top_keyword_expression?: TopKeywordExpressionConfig;
}

export interface LineChartParamsConfig {
    period?: Period;
    yaxis?: Yaxis;
    top_activated?: boolean;
    nb_values_chart?: number;
}

export interface BarChartParamsConfig {
    yaxis?: Yaxis;
    xaxis_sort?: XaxisSort;
    tone?: boolean;
    nb_values_chart?: number;
    top_activated?: boolean;
}

export interface FilterParamsConfig {
    similarity_is_checked: boolean,
}

export interface ComparisonConfig {
    evoTemporel: ComparisonMode,
    class: ComparisonMode,
    thematics: ComparisonMode,
    satisfaction: ComparisonMode,
    associatedData: ComparisonMode,
}

export enum ComparisonMode {
    side_by_side = 'side_by_side', merged = 'merged',
}
