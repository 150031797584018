import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { BaseComponent } from 'app/base.component';
import { Store } from '@ngxs/store';
import SettingsState from 'app/stores/state/settings/settings.state';
import { Observable } from 'rxjs';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import { Choice, Field } from 'app/api/models';
import CorpusService from 'app/utils/services/corpus.service';
import * as _ from 'lodash';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { Period } from 'app/api/models/distriparams';
import { DashboardConfig } from 'app/api/models/dashboard-config';
import DataChoiceListComponent from './data-choice-list/data-choice-list.component';

@Component({
    selector: 'app-associated-datas-settings',
    templateUrl: './associated-datas-settings.component.html',
})
export default class AssociatedDatasSettingsComponent extends BaseComponent implements OnInit {
    display;

    modalRef: NgbModalRef;

    userChoices: Array<Choice> = [];

    settings$: Observable<SettingsModel>;

    private graphDefaultValues;

    constructor(
        public manageDashboardService: ManageDashboardService,
        private modalService: NgbModal,
        private store: Store,
        private corpusService: CorpusService,
        public graphService: GraphService,
    ) {
        super();
    }

    ngOnInit() {
        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.graphDefaultValues = settings.vcrm['graph-values-default'];
        });

        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(() => {
            this.generateChoiceList();

            this.subs.sink = this.manageDashboardService.firstDashboardService.currentConfig.subscribe((config) => {
                if (config) {
                    this.display = config.associated_data_display;
                }
            });
        });
    }

    getDefaultBarParams() {
        return {
            graphtype: 'histo',
            yaxis: 'percent',
            xaxis_sort: 'occur_desc',
            nb_values_chart: this.graphDefaultValues,
        };
    }

    getDefaultCurveParams(dashboardService: DashboardService) {
        return {
            graphtype: 'distri',
            yaxis: 'percent',
            xaxis_sort: 'occur_desc',
            nb_values_chart: this.graphDefaultValues,
            period: this.graphService.getDefaultPeriod(Period.day, dashboardService),
        };
    }

    public async generateChoiceList() {
        const fields = this.corpusService.currentCorpus.getValue().corp_fields.filter((f) => f.displayAsAssociatedData);
        const fieldsNotInFile = this.corpusService.currentCorpus.getValue().corp_fields_not_in_file.filter((f) => f.displayAsAssociatedData);

        // d'abord on corrige la liste des colonnes en fonction des colonnes qui ont été ajoutées ou supprimées
        // sur chaque dashboard
        this.manageDashboardService.dashboardServices.forEach((dashboardService) => {
            const config = dashboardService.currentConfig.getValue();
            config.userChoices = this.checkAndFixColumns(config, fields, fieldsNotInFile, dashboardService);
        });

        // puis sur le dashboard de comparaison
        if (this.manageDashboardService.isDashboardComparison) {
            const config = this.manageDashboardService.currentDashboardComparisonConfig.getValue();
            config.userChoices = this.checkAndFixColumns(config, fields, fieldsNotInFile);
        }

        // tous les dashboards d'une comparaison partagent la même configuration des colonnes sélectionnés (graphs bar ou line)
        // donc on uniformise cette configuration sur tous les dashboard présent en prenant le premier comme référence
        const reference = this.manageDashboardService.dashboardServices[0].currentConfig.getValue();
        this.propagateReferenceConfig(reference);
    }

    private propagateReferenceConfig(reference: DashboardConfig) {
        this.manageDashboardService.dashboardServices.forEach((dashboardService) => {
            const newTarget = _.cloneDeep(dashboardService.currentConfig.getValue());
            this.overwriteSelectedData(reference, newTarget);
            dashboardService.currentDashboard.getValue().dash_json_config.userChoices = _.cloneDeep(newTarget.userChoices);
            dashboardService.setUserChoices(_.cloneDeep(newTarget.userChoices));
        });

        // puis on fait la même chose avec la configuration du dashboard de comparaison
        if (this.manageDashboardService.isDashboardComparison) {
            const newTarget = _.cloneDeep(this.manageDashboardService.currentDashboardComparisonConfig.getValue());
            this.overwriteSelectedData(reference, newTarget);
            this.manageDashboardService.currentDashboardComparison.getValue().dash_json_config.userChoices = _.cloneDeep(newTarget.userChoices);
            this.manageDashboardService.setComparisonDashbardConfig('userChoices', _.cloneDeep(newTarget.userChoices));
        }

        // on sélectionne arbitrairement la configuration du premier dashboard, car c'est la référence, et la partie de la configuration
        // qui nous intéresse ici est commune à tous les dashboards
        this.userChoices = _.cloneDeep(reference.userChoices);
    }

    private checkAndFixColumns(config: DashboardConfig, fields: Field[], fieldsNotInFile: Field[], dashboardService?: DashboardService) {
        const dashboardUserChoices = [];
        const userChoicesConfig: Array<Choice> = _.cloneDeep(config.userChoices);
        // on parcours toutes les colonnes du corpus
        fields.concat(fieldsNotInFile).forEach((field) => {
            let choice = userChoicesConfig.find((u) => u.db_name === field.DBName);
            // Si on n'a pas de config de renseigner pour cette colonne, on ajoute la config par défaut pour cette colonne
            if (!choice) {
                choice = {
                    bar: true,
                    curve: true,
                    db_name: field.DBName,
                    curve_params: this.getDefaultCurveParams(dashboardService),
                    bar_params: this.getDefaultBarParams(),
                };
            }
            if (!choice.curve_params) {
                choice.curve_params = this.getDefaultCurveParams(dashboardService);
            }
            if (!choice.bar_params) {
                choice.bar_params = this.getDefaultBarParams();
            }
            if (!choice.display_name) {
                choice.display_name = field.displayName;
            }
            dashboardUserChoices.push(choice);
        });
        return dashboardUserChoices;
    }

    // écrase uniquement la configuration des données associées sélectionnées, mais pas leur configuration
    // eslint-disable-next-line class-methods-use-this
    overwriteSelectedData(source: DashboardConfig, target: DashboardConfig) {
        source.userChoices.forEach((choice, index) => {
            target.userChoices[index].bar = choice.bar;
            target.userChoices[index].curve = choice.curve;
        });
        target.associated_data_display = source.associated_data_display;
    }

    /**
     * this methode is used to diplay into a Modal window all filters available for the project
     * allowing users to choose wich data they want to see as bars graphics or curves graphics
     */
    openModalWithComponent() {
        this.modalRef = this.modalService.open(DataChoiceListComponent, { size: 'xl' });
        this.modalRef.componentInstance.userChoices = _.cloneDeep(this.userChoices);

        this.modalRef.result.then((validated) => {
            if (!validated) return;
            this.userChoices = _.cloneDeep(validated);

            this.manageDashboardService.dashboardServices.forEach((dashboardService) => {
                dashboardService.setUserChoices(_.cloneDeep(validated));
            });
            if (this.manageDashboardService.isDashboardComparison) {
                this.manageDashboardService.setComparisonDashbardConfig('userChoices', _.cloneDeep(validated));
            }
            this.manageDashboardService.associatedDataSettingsChanged.emit();
        });
    }

    /**
     * Change l'affichage des graphiques des données associées (only histo / only distri / both)
     */
    changeDisplay(value) {
        // Change l'affichage pour tous les services (c'est une config globale à tous les dashboards)
        this.manageDashboardService.dashboardServices.forEach((dashboardService) => {
            dashboardService.setAssociatedDataDisplay(value);
        });
        if (this.manageDashboardService.isDashboardComparison) {
            this.manageDashboardService.setComparisonDashbardConfig('associated_data_display', value);
        }

        this.manageDashboardService.associatedDataSettingsChanged.emit();
    }
}
