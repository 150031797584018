import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { HistoParams, Yaxis } from 'app/api/models/histoparams';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { addDashboardNumberToVerticalBarChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-vertical-bar-chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SatisfactionBarChartSettingsPopupComponent from '../satisfaction-bar-chart-settings-popup/satisfaction-bar-chart-settings-popup.component';
import BarChartColorsAndOrder from './distribution-colors';

@Component({
    selector: 'app-satisfaction-bar-chart',
    templateUrl: './satisfaction-bar-chart.component.html',
})
export default class SatisfactionBarChartComponent extends BaseComponent {
    constructor(
        private modalService: NgbModal,
        protected translateService: TranslateService,
        protected graphService: GraphService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    @Output()
        configChanged: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    set data(data) {
        if (data) {
            this.graphData = {
                labels: this.getOrderedXaxisLabels(data).map((label) => (label === '' ? this.nrLabel : label)),
                datasets: this.getDatasets(data),
            };

            this.graphOptions = this.getGraphOptions();
        }
    }

    @Input()
        type: string;

    @Input()
        dashboardService: DashboardService;

    graphData = {};

    graphOptions = {};

    public barChartPlugins = [ChartDataLabels, addDashboardNumberToVerticalBarChart];

    get params() {
        return this.isMerged
            ? this.manageDashboardService.currentDashboardComparisonConfig.getValue().satisfaction[this.type].histo
            : this.dashboardService.currentConfig.getValue().satisfaction[this.type].histo;
    }

    get isMerged() {
        return !this.dashboardService;
    }

    // eslint-disable-next-line class-methods-use-this
    getGraphOptions() {
        const thisInternal = this;
        const { yaxis } = this.params;
        const options = {
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
            layout: {
                padding: {
                    top: 30 + (thisInternal.isMerged ? 20 : 0),
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#000',
                        source: 'labels',
                        autoSkip: true,
                        maxTicksLimit: 20,
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    min: 0,
                    ticks: {
                        color: '#000',
                        callback: (value: any) => `${value}${yaxis === Yaxis.percent ? '%' : ''}`,
                    },
                },
            },
            plugins: {
                addDashboardNumberToVerticalBarChart: !thisInternal.dashboardService,
                legend: {
                    display: false,
                },
                datalabels: {
                    formatter: (value: any) => `${value}${yaxis === Yaxis.percent ? '%' : ''}`,
                    color: 'black',
                    font: {
                        weight: 'bold',
                    },
                    anchor: 'end',
                    align: 'end',
                },
                tooltip: {
                    callbacks: {
                        title: () => '',
                        label: (ctx) => this.translateService.instant(
                            `translations.analysisDashboard.satisfaction.tooltips.${yaxis === Yaxis.percent ? Yaxis.percent : Yaxis.occur}`,
                            {
                                count: ctx.dataset.data[ctx.dataIndex],
                                value: ctx.label.toString() === this.nrLabel ? this.nrValue : ctx.label.toString().toLowerCase(),
                                type: this.translateService.instant(`translations.analysisDashboard.satisfaction.tooltips.type_${this.type}`),
                            },
                        ),
                    },
                    xAlign: 'center',
                    yAlign: 'bottom',
                    displayColors: false,
                },
            },
        };

        return options;
    }

    get nrLabel() {
        return this.translateService.instant('translations.analysisDashboard.satisfaction.nr_label');
    }

    get nrValue() {
        return this.translateService.instant('translations.analysisDashboard.satisfaction.nr_value');
    }

    getBackgroundColors(rawData) {
        const orders = this.getOrders(rawData);
        return this.getOrderedXaxisLabels(rawData)
            .map((label) => orders[label]);
    }

    getDatasets(rawData) {
        const datasets = [];
        for (let i = 0; i < this.manageDashboardService.dashboardServices.length; i += 1) {
            datasets.push({
                data: [],
                backgroundColor: this.getBackgroundColors(rawData),
                hoverBackgroundColor: this.getBackgroundColors(rawData),
                stack: `${i + 1}`,
            });
        }

        this.getData(rawData).forEach((merged_values) => {
            merged_values.forEach((dashboardValue, dashboardIndex) => {
                datasets[dashboardIndex].data.push(dashboardValue || 0);
            });
        });

        return datasets.filter((dataset) => dataset.data.length > 0);
    }

    getOrders(rawData) {
        return BarChartColorsAndOrder[this.type].scaled ? BarChartColorsAndOrder[this.type][rawData.scale] : BarChartColorsAndOrder[this.type];
    }

    // cette méthode met les données dans l'ordre de BarChartColorsAndOrder
    getData(rawData) {
        return Object.keys(this.getOrders(rawData))
            .map((order) => rawData.histo.values.find((value) => (order === `${value.name}`)) || null)
            .map((value) => (value === null ? null : value.merged_values))
            .filter((value) => value !== null);
    }

    // retourne les labels dans le bon ordre
    getOrderedXaxisLabels(rawData) {
        return Object.keys(this.getOrders(rawData))
            .filter((key) => key !== 'scaled')
            .map((order) => rawData.histo.values.find((value) => (order === `${value.name}`)) || null)
            .map((value) => (value === null ? null : value.name))
            .filter((value) => value !== null);
    }

    openSettings() {
        const modal = this.modalService.open(SatisfactionBarChartSettingsPopupComponent);
        modal.componentInstance.params = this.params;
        modal.componentInstance.type = this.type;
        modal.componentInstance.save.subscribe((params: HistoParams) => {
            if (this.isMerged) {
                this.manageDashboardService.setSatisfactionConfig(this.type, 'histo', params);
            } else {
                this.dashboardService.setSatisfactionConfig(this.type, 'histo', params);
            }
            this.configChanged.emit();
        });
    }
}
