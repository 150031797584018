/* eslint-disable no-lonely-if */
import {
    Component, HostBinding, OnInit, TemplateRef, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import { TranslateService } from '@ngx-translate/core';
import { Field, SatRecoField } from 'app/api/models';
import { ModelsCreationProcess } from 'app/api/models/modelsCreationProcess';
import {
    SetColMessage, SetEtapes,
    SetIndexStopwords,
    SetNewCorpus, SetColScore,
} from '../../../stores/actions/new-corpus/new-corpus.actions';
import BaseEtapeComponent from '../BaseEtapeComponent';
import { ModelsService } from '../../../api/services';

/**
 * Select verbatim
 *
 * @export
 * @class Etape4messageComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape4message',
    templateUrl: './etape4message.component.html',
    styleUrls: ['./etape4message.component.scss'],
    animations: [slideInOutAnimation],
    providers: [],

    encapsulation: ViewEncapsulation.None,
})
export default class Etape4messageComponent extends BaseEtapeComponent implements OnInit {
    @HostBinding('class') class = 'etape4-message';

    columns: Array<Object> = [];

    rows: any = [];

    hoverCol: number;

    selectVerbatimCol: number;

    selectDeltaSatCol: number = -1;

    selectNPSCol: number = -1;

    storeDatas: any;

    modalRef: NgbModalRef;

    analysisLanguage: string = '';

    analysisLanguageList: Array<string> = [];

    choiceOfAssociate: string = '';

    rangeEnable: boolean = false;

    showDeltaSat: boolean = false;

    showNPS: boolean = false;

    enableConfirmModal: boolean = false;

    satColAlreadySelected: boolean = false;

    npsColAlreadySelected: boolean = false;

    public listFields: Array<Field> = [];

    satField: SatRecoField;

    recoField: SatRecoField;

    satFieldRange: string = '';

    public inputNameColDeltaSat: string = '';

    public inputNameColReco: string = '';

    public inputNameVerbatim: string = '';

    currentAnalysisLanguage: string = '';

    // IB
    verbatim_column_max: number;

    noLanguage: boolean = true;

    selectedVerbatimArray: Array<{ name: any, displayName: any, lang: any, mode_id: any }> = [];

    selectedVerbatimCol: Array<number> = [];

    displayName: string = '';

    colsTypes: any;

    disabledDeltaSatCol: Array<number> = [];

    disabledRecoCol: Array<number> = [];

    colsNotString: Array<number> = [];

    colsThatAreAvailableForVerbatimSelection: Array<number> = [];

    // On indique que si nous sommes sur les colonnes de la modale ChooseScore
    onModalChooseScore = false;

    isKeyPressActiveOnMainTable: boolean = true;

    // Ajouts pour inversion date <=> verbatim
    arrayDisabled: Array<number> = [];

    arrayAssociated: Array<number> = [];

    selectDateCol: number;

    filename: string;

    // pour le message tooltip dynamic
    tooltipIcon: string = '';

    tooltipIconDeltaSat: string = '';

    tooltipIconNPS: string = '';

    tooltipContent: string = '';

    tooltipContentDeltaSat: string = '';

    tooltipContentNPS: string = '';

    // VG sept 2021
    modelsResponse = {};

    displayModels: boolean = false;

    retrievedModels: Array <ModelsCreationProcess> = [];

    // IB oct 2021
    checkFormAuto = [];

    displayExplanation: boolean = false;

    modelToDisplay = {
        id: '',
        name: '',
        description: '',
        more: [],
        arrayOfToShow: [],
    };

    toValidateForm: boolean = false;

    modelIdChoosen: number;

    themaIsPresent: boolean = false;

    noModelChoose: boolean = true;

    @ViewChild('selectLanguageAndClass')
    private analysisLanguageModal: TemplateRef<any>;

    @ViewChild('choiceToAssociate')
    private choiceToAssociateModal: TemplateRef<any>;

    @ViewChild('validateNoScore')
    private validateNoScoreModal: TemplateRef<any>;

    @ViewChild('chooseScore')
    private chooseScoreModal: TemplateRef<any>;

    protected indexStopwords: boolean = true;

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private router: Router,
        private modelsService: ModelsService,
        private translate: TranslateService,
    ) {
        super();
        this.verbatim_column_max = this.store.snapshot().settings.vcrm['verbatim-column-max'];
        FavIconX.reset();
        this.analysisLanguageList = this.store.snapshot().settings.vcrm.analysis_lang;

        const state = this.store.snapshot().newcorpus;
        if (this.isEtape1Invalid(state)) {
            this.router.navigate(['new-project']);
        } else if (this.isEtape2Invalid(state)) {
            this.router.navigate(['new-project', 'upload']);
        } else if (this.isEtape3Invalid(state)) {
            this.router.navigate(['new-project', 'date']);
        } else {
            this.storeDatas = state.datas;
        }
    }

    ngOnInit() {
        setTimeout(() => {
            this.store.dispatch(new SetEtapes(3)); // IB inversion data et message
            this.store.dispatch(new SetIndexStopwords(true));
        }, 500);
        this.store.dispatch(new SetColMessage(null));
        this.store.dispatch(new SetColScore([]));
        // Ajout pour inversion date <=> verbatim
        this.filename = this.storeDatas.file.name;

        this.getFirstLine();
    }

    getFirstLine() {
        if (this.storeDatas.fileFirstLines) {
            const colsItems: Array<Object> = [];
            const tableData = <Array<any>> this.storeDatas.fileFirstLines.rows;
            const rows = <Array<any>> this.storeDatas.fileFirstLines.colomns;
            Object.keys(rows).forEach((v, k) => {
                colsItems.push(rows[v]);
                if (rows[v] === this.storeDatas.date) {
                    this.selectDateCol = k;
                }
                if (this.storeDatas.colsTypes[rows[v]] !== 'n') {
                    this.disabledDeltaSatCol.push(k);
                    this.disabledRecoCol.push(k);
                }
                if (this.storeDatas.colsTypes[rows[v]] === 's') {
                    this.colsThatAreAvailableForVerbatimSelection.push(k);
                } else {
                    this.colsNotString.push(k);
                }
            });

            this.arrayDisabled = [...this.colsNotString];
            this.columns = colsItems;
            this.columns = [...this.columns];
            this.colsTypes = this.storeDatas.colsTypes;

            const rowsList = [];
            tableData.forEach((row) => {
                const cellList = [];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                Object.entries(row).forEach(([k, v]) => {
                    cellList.push(v);
                });
                rowsList.push(cellList);
            });

            this.rows = rowsList;
        }
    }

    checkSelectionState() {
    // Quand le nombre max de colonnes sélectionnable est atteint, on désactive toutes les autres colonnes
        if (this.selectedVerbatimCol.length === this.verbatim_column_max) {
            this.columns.forEach((value, i) => {
                if (this.selectedVerbatimCol.indexOf(i) < 0) {
                    this.arrayDisabled.push(i);
                }
            });
        } else {
            // sinon les colonnes désactivées sont toutes celles contenu dans listField ainsi que la colonne de date
            const listFieldsIndex = [];
            this.listFields.forEach((item) => {
                listFieldsIndex.push(this.columns.findIndex((name) => name === item.inputName));
            });
            this.arrayDisabled = [...this.colsNotString];
        }
    }

    removeVerbatim(index) {
        if (index !== this.selectDateCol) {
            const verbatimIndex = this.selectedVerbatimArray.findIndex(
                (i) => i.name === this.columns[index],
            );

            if (verbatimIndex > -1) {
                this.selectedVerbatimArray.splice(verbatimIndex, 1);
                this.inputNameVerbatim = (index > -1) ? this.columns[index] as string : '';
                // On fait une copie de listFields car on va supprimer potentiellement des elements du tableau
                const tempListFields = [...this.listFields];
                // On cherche le verbatim dans tous les éléments de la List
                tempListFields.forEach((item) => {
                    if (item.satField) {
                        // Si le verbatim est bien dans une liste verbatim d'une colonne deltasat
                        const indexOfVerbInListDeltaSat = item.satField.verbatimList.findIndex((name) => name === this.inputNameVerbatim);
                        if (indexOfVerbInListDeltaSat > -1) {
                            item.satField.verbatimList.splice(indexOfVerbInListDeltaSat, 1);
                            if (item.satField.verbatimList.length < 1) { // il n'y avait qu'1 seul verbatim associé à la colonne deltaSat et on vient de le supprimer
                                const indexToEraseSat = this.listFields.findIndex( // on cherche l'index de la colonne deltaSat a effacer
                                    (i) => i.inputName === item.inputName,
                                );
                                this.listFields.splice(indexToEraseSat, 1); // on efface donc la colonne deltasat
                                // cette colonne n'étant plus selectionnée comme deltasat il faut la supprimer du tableau this.disabledRecoCol
                                // on cherche l'index de cette colonne dans le tableau des colonnes
                                const IndexOfColToEnabled = this.columns.findIndex(
                                    (i) => i === item.inputName,
                                );
                                    // on cherche l'index de ce numéro de colonne dans this.disabledRecoCol
                                const IndexOfColInDisRecoCol = this.disabledRecoCol.findIndex(
                                    (i) => i === IndexOfColToEnabled,
                                );
                                if (IndexOfColInDisRecoCol > -1) {
                                    this.disabledRecoCol.splice(IndexOfColInDisRecoCol, 1);
                                }
                            }
                        }
                    }
                    if (item.recoField) {
                        // Si le verbatim est bien dans une liste verbatim d'une colonne reco
                        const indexOfVerbInListNPS = item.recoField.verbatimList.findIndex((name) => name === this.inputNameVerbatim);
                        if (indexOfVerbInListNPS > -1) {
                            item.recoField.verbatimList.splice(indexOfVerbInListNPS, 1);
                            if (item.recoField.verbatimList.length < 1) { // il n'y avait qu'1 seul verbatim associé à la colonne nps et on vient de le supprimer
                                const indexToEraseReco = this.listFields.findIndex( // on cherche l'index de la colonne NPS a effacer
                                    (i) => i.inputName === item.inputName,
                                );
                                this.listFields.splice(indexToEraseReco, 1); // on efface donc la colonne NPS
                                // cette colonne n'étant plus selectionnée comme NPS il faut la supprimer du tableau this.disabledDeltaSatCol
                                // on cherche l'index de cette colonne dans le tableau des colonnes
                                const IndexOfColToEnabled2 = this.columns.findIndex(
                                    (i) => i === item.inputName,
                                );
                                    // on cherche l'index de ce numéro de colonne dans this.disabledDeltaSatCol
                                const IndexOfColInDisDeltaSatCol = this.disabledDeltaSatCol.findIndex(
                                    (i) => i === IndexOfColToEnabled2,
                                );
                                if (IndexOfColInDisDeltaSatCol > -1) {
                                    this.disabledDeltaSatCol.splice(IndexOfColInDisDeltaSatCol, 1);
                                }
                            }
                        }
                    }
                });
            }
            // Supprime l'index de la colonne de verbatim
            this.selectedVerbatimCol = this.selectedVerbatimCol.filter((v) => v !== index);

            this.checkSelectionState();
        }
    }

    getRemainingColumns(): number {
        if (this.selectedVerbatimCol.includes(this.selectDateCol)) {
            return this.verbatim_column_max - (this.selectedVerbatimCol.length - 1);
        }
        return this.verbatim_column_max - this.selectedVerbatimCol.length;
    }

    getTranslatedAnalysislanguage(): string {
        return this.translate.instant(`translations.analysislanguage.${this.analysisLanguage}`);
    }

    /**
   * Fired when user select a verbatim column (on main table)
   */
    selectVerbatim(index: number): void {
        if (this.selectedVerbatimArray.length < this.verbatim_column_max) {
            // si toutes les colonnes ne sont pas encore sélectionnées, on active toutes les colonnes
            this.selectVerbatimCol = index;
            this.inputNameVerbatim = this.columns[this.selectVerbatimCol] as string;
            this.displayName = this.inputNameVerbatim;
            this.openModal(this.analysisLanguageModal);
            this.checkSelectionState();
        }
    }

    /** ******************************
   * ANALYSIS LANGUAGE MODAL
   ******************************* */

    /**
   * Ouvre la modal de sélection de la colonne de verbatim (modification du nom de la colonne et sélection de la langue)
   */
    openModal(template: TemplateRef<any>) {
        this.isKeyPressActiveOnMainTable = false;
        if (this.selectedVerbatimArray.length === 0) {
            this.noLanguage = true;
            this.toValidateForm = false;
            this.analysisLanguage = '';
        } else {
            this.noLanguage = false;
            // Si la langue choisie ne contient pas modèles de classification, on doit permettre de passer à l'étape suivante
            if (this.checkFormAuto.length === 0) {
                this.toValidateForm = true;
            }
        }
        this.modalRef = this.modalService.open(template, {
            modalDialogClass: 'modal-step4',
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
        });
    }

    /**
   * Fired when user click on "Valid" button on analysis language modal
   */
    saveModal() {
        this.modalRef.close();
        this.openModalChoiceToAssociate(this.choiceToAssociateModal);
    }

    /**
   * Fired when user click on "Cancel" button on analysis language modal
   */
    closeModal() {
        this.displayName = '';
        this.isKeyPressActiveOnMainTable = true;
        this.toValidateForm = false;
        // Supprime la colonne sélectionnée dans tableau des verbatim
        this.removeVerbatim(this.selectVerbatimCol);
        this.modalRef.close();
    }

    /**
     *  Fired when user select a language for verbatim into the modale
     */

    onLanguageDropDownChange(e) {
        this.analysisLanguage = e;
        this.retrievedModels = [];
        this.checkFormAuto = [];
        try {
            this.modelsService.getV1Models(this.analysisLanguage).subscribe((modelsResponse) => {
                // recopier tous les objets renvoyés dans un tableau
                if (modelsResponse.models.length > 0) {
                    Object.keys(modelsResponse.models).forEach((entry) => {
                        this.retrievedModels.push(modelsResponse.models[entry]);
                    });
                    this.displayModels = true;
                    this.generateChecklist();
                } else {
                    this.displayModels = false;
                    this.toValidateForm = true; // si le langage choisi ne possède pas de classification il faut pouvoir passer à l'étape suivante
                }
            });
        } catch (error) {
            console.log('erreure asynchrone lors du call get models :', error);
        }
    }

    /** ******************************
     * CLASSIFICATIONS MODELS
     ******************************* */

    /**
     * Ouvre la partie de la selection du modèle de classification (1 seul modèle pour le moment)
     * Génère la liste des modèles à afficher
     */

    generateChecklist() {
        const checkList = [];
        this.displayExplanation = false;
        this.toValidateForm = false;
        this.themaIsPresent = false;
        this.noModelChoose = true;

        checkList.push({
            id: 0,
            select: false,
            name: this.translate.instant('translations.newcorpus.stage4.choiceClassificationModel.noModelChoose'),
            description: this.translate.instant('translations.newcorpus.stage4.choiceClassificationModel.infoNoModel2'),
            type: 'NOMODEL',
            iconcolor: false,
            more: [],
        });

        this.retrievedModels.forEach((item) => {
            checkList.push({
                id: item.mode_id, select: false, name: item.mode_name, type: item.mode_type, description: item.mode_description, iconcolor: false, more: item.cat_fields,
            });
            if (item.mode_type === 'RULES') {
                this.themaIsPresent = true;
            }
        });

        this.checkFormAuto = checkList;
    }

    /**
     *  Fired when user select a classification model
     */

    onChangeModel($event) {
        const id = $event.target.value;
        const isChecked = $event.target.checked;
        this.toValidateForm = isChecked;

        this.checkFormAuto = this.checkFormAuto.map((item) => {
            if (parseInt(item.id, 10) === parseInt(id, 10)) {
                item.select = isChecked;
                this.onIconClick(item.name);
                return item;
            }
            item.select = false;
            return item;
        });
    }

    /**
     *  Fired when user click on the guide icon of a model
     */

    onIconClick(name) {
        const arrayOfToShowTemp = [];
        this.displayExplanation = true;

        this.checkFormAuto.forEach((item) => {
            if (item.name === name) {
                item.iconcolor = true;
                // je crée le tableau de booléen avec le nombre de classes présentes pour gérer les collapses
                if (item.more.length > 0) {
                    item.more.forEach(() => {
                        arrayOfToShowTemp.push(false);
                    });
                }
                this.modelToDisplay = {
                    id: item.id, name: item.name, description: item.description, more: item.more, arrayOfToShow: arrayOfToShowTemp,
                };
            } else {
                item.iconcolor = false;
            }
        });
    }

    /**
     *  Fired when user want to show or hide the explanation of the class
     */

    toShowOrHide(index) {
        this.modelToDisplay.arrayOfToShow[index] = !this.modelToDisplay.arrayOfToShow[index];
    }

    /** ******************************
   * CHOICE TO ASSOCIATE MODAL
   ******************************* */

    /**
   * Open choice associate modal
   */
    openModalChoiceToAssociate(template: TemplateRef<any>) {
        this.choiceOfAssociate = '';
        this.noModelChoose = true;
        if (this.checkFormAuto.length > 0) {
            this.checkFormAuto.forEach((item) => {
                if (item.type === 'NOMODEL' && item.select === false) {
                    this.noModelChoose = false;
                }
            });
        } else { // langue ne contenant pas de model de classification
            this.noModelChoose = true;
        }

        this.modalRef = this.modalService.open(template, {
            modalDialogClass: 'modal-step4',
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
        });
    }

    /**
   * Fired when user click on "Previous" button on choice associte modal
   */
    previousModalChoiceToAssociate() {
        this.modalRef.close();
        this.openModal(this.analysisLanguageModal);
    }

    /**
   * Fired when user click on "Valid or Next" button on choice associate modal
   */
    validModalChoiceToAssociate() {
        this.modalRef.close();
        if (this.choiceOfAssociate === 'noScore') {
            this.openModalRemainingColumns(this.validateNoScoreModal);
        } else {
            this.openModalChooseScore(this.chooseScoreModal);
        }
    }

    /** ******************************
   * REMAINING COLUMNS MODAL
   ******************************* */

    /**
   * Open remaining column modal
   */
    openModalRemainingColumns(template: TemplateRef<any>) {
        this.toValidateForm = false; // on remet à false après avoir tout valider sur 1 colonne de verbatim
        this.modalRef = this.modalService.open(template, {
            modalDialogClass: 'modal-step4',
            backdrop: 'static',
            keyboard: false,
        });
    }

    /**
   * Fired when user click on "OK" button on remaining columns modal (close modal)
   * On close last modal, we save all informations (language, verbatim column, sat/reco if selected)
   */
    closeModalRemainingColumns() {
        this.checkFormAuto.forEach((item) => {
            if (item.select === true) {
                this.modelIdChoosen = item.id;
            }
        });
        const verbatim = {
            name: this.columns[this.selectVerbatimCol],
            displayName: this.displayName,
            lang: this.analysisLanguage,
            mode_id: this.modelIdChoosen,
        };
        this.selectedVerbatimArray.push(verbatim);
        // Sauvegarde la langue lors de la sélection de la 1ère colonne
        if (this.selectedVerbatimArray.length === 1) {
            this.noLanguage = false;
            this.store
                .dispatch(new SetNewCorpus({ language: this.analysisLanguage }))
                .subscribe(() => {
                    this.currentAnalysisLanguage = this.analysisLanguage;
                });
        }
        this.isKeyPressActiveOnMainTable = true;
        this.modalRef.close();
        this.checkFormAuto = this.checkFormAuto.map((item) => {
            item.select = false;
            item.iconcolor = false;
            return item;
        });
        this.modelToDisplay = {
            id: '',
            name: '',
            description: '',
            more: [],
            arrayOfToShow: [],
        };
        this.displayExplanation = false;
    }

    /** ******************************
   * CHOOSE SCORE MODAL
   ******************************* */

    /**
   * Open choose score modal
   */
    openModalChooseScore(template: TemplateRef<any>) {
        this.enableConfirmModal = false;
        this.satColAlreadySelected = false;
        this.npsColAlreadySelected = false;
        this.showNPS = ['allScore', 'nps'].includes(this.choiceOfAssociate);
        this.showDeltaSat = ['allScore', 'deltaSat'].includes(this.choiceOfAssociate);

        // On indique que nous sommes sur les colonnes de la modale ChooseScore
        this.onModalChooseScore = true;

        this.modalRef = this.modalService.open(template, {
            modalDialogClass: 'modal-step4',
            backdrop: 'static',
            size: 'lg',
            keyboard: false,
        });
    }

    /**
   * Méthode déclenchée lors de la sélection d'une colonne de satisfaction
   */
    selectDeltaSat(index: number) {
        this.satColAlreadySelected = false;
        this.satFieldRange = '';
        if (!this.disabledDeltaSatCol.includes(index) && index !== this.selectVerbatimCol) {
            // Pour le mode deltasat et reco, si on avait précédemment une colonne deltaSat de sélectionner
            if (this.choiceOfAssociate === 'allScore' && this.selectDeltaSatCol > -1) {
                // Si cette colonne n'est pas déjà sélectionnée en tant que colonne de satisfaction
                const previousSelectedDeltaSatCol = this.columns[this.selectDeltaSatCol] as string;
                const field = this.listFields.find((item) => item.inputName === previousSelectedDeltaSatCol);
                if (!field || !field.satField) {
                    // Alors on réactive la colonne dans le tableau des reco
                    this.disabledRecoCol = this.disabledRecoCol.filter((i) => i !== this.selectDeltaSatCol);
                }
            }

            // On enregistre la colonne sélectionnée
            this.selectDeltaSatCol = index;
            this.inputNameColDeltaSat = this.columns[this.selectDeltaSatCol] as string;
            // On cherche si la colonne de deltasat est déjà dans la List et son index dans la List
            const indexOfDeltaInList = this.listFields.findIndex((item) => item.inputName === this.inputNameColDeltaSat);
            if (indexOfDeltaInList > -1) {
                // Si oui, on prend la valeur de scale précédement séléctionnée
                const scaleSaved = this.listFields[indexOfDeltaInList].satField.scale;
                this.satFieldRange = scaleSaved;
                this.satColAlreadySelected = true;
            }

            // Pour le mode deltasat et reco
            if (this.choiceOfAssociate === 'allScore') {
                // Si la colonne deltaSat sélectionnée n'est pas déjà désactivée dans le tableau des recos
                if (!this.disabledRecoCol.includes(this.selectDeltaSatCol)) {
                    // On l'a désactive
                    this.disabledRecoCol.push(index);
                }
                // Si on a sélectionné la même colonne de reco que celle sélectionnée dans le deltasat
                if (this.selectNPSCol === this.selectDeltaSatCol) {
                    // on désélectionne la colonne de reco
                    this.selectNPSCol = -1;
                    this.inputNameColReco = '';
                }
            }
            this.isEnableConfirmModal();
        }
    }

    /**
   * Méthode déclenchée lors de la sélection de l'échelle de notation (DeltaSat)
   */
    onChangeRangeScore(e) {
        this.satFieldRange = e.target.value;
        this.isEnableConfirmModal();
    }

    /**
   * Méthode déclenchée lors de la sélection d'une colonne de recommandation
   */
    selectNPS(index) {
        this.npsColAlreadySelected = false;
        if (!this.disabledRecoCol.includes(index) && index !== this.selectVerbatimCol) {
            this.selectNPSCol = index;
            this.inputNameColReco = (this.selectNPSCol) ? this.columns[this.selectNPSCol] as string : '';

            // On cherche si la colonne de reco est déjà dans la List
            this.npsColAlreadySelected = !!this.listFields.find((item) => item.inputName === this.inputNameColReco);
        }

        this.isEnableConfirmModal();
    }

    /**
   * Détermine si le bouton "Valider" de la popin de sélection des colonnes associées est actif ou non
   */
    isEnableConfirmModal() {
        let isMandatoryDeltaSat = false;
        let isMandatoryReco = false;
        if (['allScore', 'deltaSat'].includes(this.choiceOfAssociate)) {
            isMandatoryDeltaSat = this.selectDeltaSatCol > -1 && !!(this.satFieldRange && this.inputNameColDeltaSat);
        }
        if (['allScore', 'nps'].includes(this.choiceOfAssociate)) {
            isMandatoryReco = this.selectNPSCol > -1 && this.inputNameColReco !== '';
        }
        const isChoiceNPS = (this.choiceOfAssociate === 'nps') ? isMandatoryReco : isMandatoryDeltaSat;
        this.enableConfirmModal = (this.choiceOfAssociate === 'allScore') ? isMandatoryDeltaSat && isMandatoryReco : isChoiceNPS;
    }

    /**
   * Fired whe user click on "Valid" button on choose score modal
   */
    onValidModalChooseScore() {
    // Si une colonne deltasat a été sélectionnée
        if (this.selectDeltaSatCol !== -1) {
            // Si l'index de la colonne de deltaSat est déjà dans la list des colonnes associées
            const indexOfDeltaInList = this.listFields.findIndex((item) => item.inputName === this.inputNameColDeltaSat);
            if (indexOfDeltaInList > -1) {
                // on enregistre le verbatim dans le tableau
                this.listFields[indexOfDeltaInList].satField.verbatimList.push(this.inputNameVerbatim);
            } else {
                // Sinon on enregistre la colonne de deltasat
                this.listFields.push({
                    inputName: this.columns[this.selectDeltaSatCol] as string,
                    displayName: this.inputNameColDeltaSat,
                    analyseFilter: true,
                    digitalFilter: true,
                    multi: false,
                    separator: '',
                    toneFilter: true,
                    search: true,
                    satField: {
                        scale: this.satFieldRange,
                        verbatimList: [this.inputNameVerbatim],
                    },
                });
                // Si la colonne de deltaSat n'est pas déjà désactivée dans le tableau des reco
                if (!this.disabledRecoCol.includes(this.selectDeltaSatCol)) {
                    // On la désactive dans le tableau de reco
                    this.disabledRecoCol.push(this.selectDeltaSatCol);
                }
            }
        }

        // Si une colonne nps a été sélectionnée
        if (this.selectNPSCol !== -1) {
            const indexOfNPSInList = this.listFields.findIndex((item) => item.inputName === this.inputNameColReco);
            // Si l'index de la colonne de NPS est déjà dans la list des colonnes associées
            if (indexOfNPSInList > -1) {
                // on enregistre le verbatim dans le tableau
                this.listFields[indexOfNPSInList].recoField.verbatimList.push(this.inputNameVerbatim);
            } else {
                // Sinon on enregistre la colonne de NPS
                this.listFields.push({
                    inputName: this.columns[this.selectNPSCol] as string,
                    displayName: this.inputNameColReco,
                    analyseFilter: true,
                    digitalFilter: true,
                    multi: false,
                    separator: '',
                    toneFilter: true,
                    search: true,
                    recoField: {
                        scale: '0_10',
                        verbatimList: [this.inputNameVerbatim],
                    },
                });
                // Si la colonne de reco n'est pas déjà désactivée dans le tableau des deltaSat
                if (!this.disabledDeltaSatCol.includes(this.selectNPSCol)) {
                    // On la désactive dans le tableau des deltaSat
                    this.disabledDeltaSatCol.push(this.selectNPSCol);
                }
            }
        }

        this.closeModalChooseScore();
        this.openModalRemainingColumns(this.validateNoScoreModal);
    }

    /**
   * Fired when user click on "Previous" button on choose score modal
   */
    onPreviousModalChooseScore() {
    // Si on a les 2 tableaux d'afficher et que l'on avait sélectionné une colonne de sat, alors il faut ré-activer la colonne de sat du tableau de reco
    // car quand on sélectionne une colonne de sat, on la désactive dans le tableau des reco
        if (this.choiceOfAssociate === 'allScore' && this.selectDeltaSatCol) {
            this.disabledRecoCol = this.disabledRecoCol.filter((index) => index !== this.selectDeltaSatCol);
        }
        this.closeModalChooseScore();
        this.openModalChoiceToAssociate(this.choiceToAssociateModal);
    }

    closeModalChooseScore() {
    // On indique que nous ne sommes plus sur les colonnes de la modale ChooseScore
        this.onModalChooseScore = false;
        this.modalRef.close();
        this.showDeltaSat = false;
        this.showNPS = false;
        this.selectNPSCol = -1;
        this.selectDeltaSatCol = -1;
        this.inputNameColDeltaSat = '';
        this.inputNameColReco = '';
        this.satFieldRange = '';
    }

    /**
   * Fired when user click on "Next" button on control bar to go to the next step
   */
    onConfirmEtape4() {
    // Sauvegarde des colonnes de verbatims
        this.store.dispatch(
            new SetColMessage(this.selectedVerbatimArray),
        );
        // Sauvegarde des colonnes de deltaSat/reco
        this.store.dispatch(
            new SetColScore(this.listFields),
        );

        this.modalRef.close();
        this.router.navigate(['/', 'new-project', 'columns']);
    }

    /**
   * Fired when user select or deselect stop word checkbox
   */
    changeIndexStopwords(e) {
        this.indexStopwords = e.target.checked;
        this.store.dispatch(new SetIndexStopwords(this.indexStopwords));
    }

    /**
   * Fired when mouse is over a column
   */
    overTheColumn(index: number) {
    // Si nous sommes dans les colonnes de l'étape de création et non dans la modale chooseScore
        if (!this.onModalChooseScore) {
            // si cette colonne est disabled (donc n'appartient pas au tableau de colonnes compatibles avec les verbatims)
            if (this.arrayDisabled.includes(index)) {
                // Si le nombre max de colonnes sélectionnable est atteint
                if (this.selectedVerbatimCol.length === this.verbatim_column_max) {
                    this.tooltipContent = this.translate.instant('translations.newcorpus.stage4.tooltips.maxVerbatimSelected', { maximum: this.verbatim_column_max });
                    this.tooltipIcon = 'icon-anti-spam';
                } else {
                    this.tooltipContent = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDisabled');
                    this.tooltipIcon = 'icon-anti-spam';
                }
            } else {
                // sinon si cette colonne fait partie du tableau de colonnes de verbatim sélectionnée
                if (this.selectedVerbatimCol.includes(index)) {
                    this.tooltipContent = this.translate.instant('translations.newcorpus.stage4.tooltips.verbatimToDeselect');
                    this.tooltipIcon = 'icon-delete';
                } else {
                    // sinon on affiche le tooltip que l'on peut sélectionner la colonne de verbatim
                    this.tooltipContent = this.translate.instant('translations.newcorpus.stage4.tooltips.columnSelect');
                    this.tooltipIcon = 'icon-add-more';
                }
            }
        } else {
            // sinon nous sommes dans les colonnes de la modale chooseScore
            // Si nous sommes sur le verbatim selectionné
            if (this.selectVerbatimCol === index) {
                this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.verbatimSelected');
                this.tooltipIconDeltaSat = 'icon-anti-spam';
                this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.verbatimSelected');
                this.tooltipIconNPS = 'icon-anti-spam';
            } else if (this.disabledDeltaSatCol.includes(index)) { // la colonne appartient à la colonne disabledDeltaSat, donc non selectionnable
                this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDisabled');
                this.tooltipIconDeltaSat = 'icon-anti-spam';
                if (this.disabledRecoCol.includes(index)) {
                    this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDisabled');
                    this.tooltipIconNPS = 'icon-anti-spam';
                } else {
                    this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.columnNPS');
                    this.tooltipIconNPS = 'icon-Link';
                }
            } else if (this.selectDeltaSatCol === index) { // la colonne est celle selectionnée comme deltasat
                this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDeltaSatSelected');
                this.tooltipIconDeltaSat = 'icon-tick';
                // la colonne selectionnée en tant que deltasat devient non selectionnable dans le tableau NPS
                this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDisabled');
                this.tooltipIconNPS = 'icon-anti-spam';
            } else if (this.selectNPSCol === index) { // la colonne est celle selctionnée comme NPS
                this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.columnNPSSelected');
                this.tooltipIconNPS = 'icon-tick';
                this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDeltaSat');
                this.tooltipIconDeltaSat = 'icon-Link';
            } else if (this.disabledRecoCol.includes(index)) {
                this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDisabled');
                this.tooltipIconNPS = 'icon-anti-spam';
                if (this.disabledDeltaSatCol.includes(index)) {
                    this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDisabled');
                    this.tooltipIconDeltaSat = 'icon-anti-spam';
                } else {
                    this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDeltaSat');
                    this.tooltipIconDeltaSat = 'icon-Link';
                }
            } else { // toutes les autres colonnes sont selectionnable en DeltaSat ou NPS
                this.tooltipContentDeltaSat = this.translate.instant('translations.newcorpus.stage4.tooltips.columnDeltaSat');
                this.tooltipIconDeltaSat = 'icon-Link';
                this.tooltipContentNPS = this.translate.instant('translations.newcorpus.stage4.tooltips.columnNPS');
                this.tooltipIconNPS = 'icon-Link';
            }
        }
    }

    /**
   * Fired when mouse is out a column
   */
    outTheColumn() {
        this.tooltipContent = '';
        this.tooltipContentDeltaSat = '';
        this.tooltipContentNPS = '';
        this.tooltipIcon = '';
        this.tooltipIconDeltaSat = '';
        this.tooltipIconNPS = '';
    }
}
