// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-c {
  padding: 15px;
}

.pb-c,
.py-c {
  padding-bottom: 15px;
}

.pt-c,
.py-c {
  padding-top: 15px;
}

.pe-c,
.px-c {
  padding-right: 15px;
}

.ps-c,
.px-c {
  padding-left: 15px;
}

:host {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.model-name {
  min-height: 30px;
  font-size: 18px;
}

.col-initial-values {
  border-right: 1px solid #ccc;
}

.initial-values-col-container {
  background-color: #eee;
}

.col-modify-value label {
  font-weight: normal;
}

.class-name,
.sub-class-name {
  min-height: 24px;
}

.row-header {
  font-weight: bold;
}
.row-header > div {
  margin-top: 3px;
}

.column-footer {
  height: 51px;
}

.secondary-toolbar {
  min-height: 30px;
}

.separator-before {
  border-top: 1px solid #999;
}

.global-tone .form-check {
  margin-right: 24px;
}

.link {
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 3px;
}
.link i {
  margin-right: 1px;
  top: 2px;
  position: relative;
}
.link span {
  text-decoration: underline;
  font-weight: normal;
}
.link:hover {
  color: #f16e00;
}
.link .disabled {
  cursor: default;
  color: #ccc;
}

.link.disabled {
  cursor: default !important;
  color: #ccc !important;
}

.alert-warning-tone {
  margin: -5px 0 0;
  margin-top: -5px;
  border-top: 2px solid #fc0;
  padding-top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
