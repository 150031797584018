// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.cloud-list-switch .btn {
  width: 30px;
  height: 30px;
}
.cloud-list-switch i::before {
  font-size: 24px;
}

.size-select {
  width: 100px;
}

.sort-by-pertinence {
  font-size: 14px;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.sort-by-pertinence .form-check-input {
  margin: 0 10px;
}

#verbatim-table {
  z-index: 2;
  transition: width ease 0.25s;
}

.words-container {
  height: calc(100% - 80px);
}

::ng-deep .highlight {
  color: #f16e00;
  font-weight: bold;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
