/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpRequest, HttpResponse, HttpHeaders,
} from '@angular/common/http';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import BaseService from '../base-service';
import ApiConfiguration from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';

import { Newslist } from '../models/newslist';
import { Newslanglist } from '../models/newslanglist';
import { Newscategories } from '../models/newscategories';

@Injectable({
    providedIn: 'root',
})
class NewsService extends BaseService {
    static readonly getV1NewsPath = '/v1/news';

    static readonly getV1NewsLangPath = '/v1/news/{lang}';

    static readonly getV1NewscategoryPath = '/v1/newscategory';

    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
   * Get the list of news with their translations
   * @return OK
   */
    getV1NewsResponse(): __Observable<__StrictHttpResponse<Newslist>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/news`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Newslist>),
        );
    }

    /**
   * Get the list of news with their translations
   * @return OK
   */
    getV1News(): __Observable<Newslist> {
        return this.getV1NewsResponse().pipe(
            __map(({ body }) => body as Newslist),
        );
    }

    /**
   * Get the list of news wrote in a defined language
   * @param lang News language
   * @return OK
   */
    getV1NewsLangResponse(lang: string): __Observable<__StrictHttpResponse<Newslanglist>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/news/${lang}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Newslanglist>),
        );
    }

    /**
   * Get the list of news wrote in a defined language
   * @param lang News language
   * @return OK
   */
    getV1NewsLang(lang: string): __Observable<Newslanglist> {
        return this.getV1NewsLangResponse(lang).pipe(
            __map(({ body }) => body as Newslanglist),
        );
    }

    /**
   * Get the list of news categories
   * @return OK
   */
    getV1NewscategoryResponse(): __Observable<__StrictHttpResponse<Newscategories>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/newscategory`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Newscategories>),
        );
    }

    /**
   * Get the list of news categories
   * @return OK
   */
    getV1Newscategory(): __Observable<Newscategories> {
        return this.getV1NewscategoryResponse().pipe(
            __map(({ body }) => body as Newscategories),
        );
    }
}

module NewsService {
}

export default NewsService;
