import { Component } from '@angular/core';

@Component({
    selector: 'filters-content-empty',
    templateUrl: './filters-content-empty.component.html',
    styleUrls: ['./filters-content-empty.component.scss'],
})
export default class FiltersContentEmptyComponent {

}
