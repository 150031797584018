import {
    Attribute, Component, HostBinding, Input,
} from '@angular/core';

@Component({
    selector: 'app-dashboard-number',
    templateUrl: './dashboard-number.component.html',
    styleUrls: ['./dashboard-number.component.scss'],
})
export default class DashboardNumberComponent {
    @Input()
        number: number;

    @Input()
        title: string;

    @Input()
        size: string;

    @HostBinding('attr.class')
    get className(): string {
        return `${this.classNames ? `${this.classNames} ` : ''}${this.size ? `number-${this.size} ` : ''}${this.getNumberClassName()}`;
    }

    @HostBinding('attr.title')
    get attrTitle(): string {
        return this.title;
    }

    constructor(
        @Attribute('class') public classNames: string,
    ) {}

    /**
     * Retourne la classe à mettre sur le carré qui affiche le numéro du dashboard
     */
    public getNumberClassName():string {
        switch (this.number) {
            case 1: return 'one';
            case 2: return 'two';
            case 3: return 'three';
            default: return '';
        }
    }
}
