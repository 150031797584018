import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Dashboardforuser } from 'app/api/models';
import { DashboardSection, DashboardType } from 'app/api/models/dashboardforuser';

@Component({
    selector: 'app-dashboard-list',
    templateUrl: './dashboard-list.component.html',
    styleUrls: ['./dashboard-list.component.scss'],
})
export default class DashboardListComponent {
    @Input()
        perso: Dashboardforuser[];

    @Input()
        shared: Dashboardforuser[];

    @Input()
        others: Dashboardforuser[];

    @Input()
        comparisons: Dashboardforuser[];

    @Input()
        selectedDashboardId: number;

    @Input()
        type: DashboardType;

    @Output()
        selectDashboard: EventEmitter<Dashboardforuser> = new EventEmitter();

    onSelectDashboard(dash: Dashboardforuser, section: DashboardSection) {
        dash.dash_section = section;
        this.selectDashboard.emit(dash);
    }
}
