// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

h1,
h2,
h3 {
  font-size: 16px;
  line-height: 16px;
}

.last-line-before-controls {
  margin-bottom: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
