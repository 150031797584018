export interface DistriParams {
    period?: Period;
    grouped_values?: GroupedValue;
    nb_values_chart?: number;
    top_activated?:boolean;
    legend_distri?: Array<string>,
    grouped_field?:string,
    selectedColumnDisplayName?: string,
}

export enum Period {
    day = 'day',
    week = 'week',
    month = 'month',
}

export enum GroupedValue {
    average = 'average',
    class = 'class',
    thematic = 'thematic',
    column = 'column',
}
