import { HttpClient } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input, OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { VerbatimConfigField } from 'app/api/models/verbatimconfig';
import { CorpusService as CorpusApi, DashboardService as DashboardApi } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseCloudComponent from './BaseCloudComponent';
import ExtendedCloudComponent from './extended-cloud/extended-cloud.component';
import { CloudData } from './word-cloud.interfaces';
import analytics from './words.analytics';

@Component({
    selector: 'app-words',
    templateUrl: './words.component.html',
    styleUrls: ['./words.component.scss'],
})
export default class WordsComponent extends BaseCloudComponent implements OnInit {
    @Output()
        wordSelected: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Input()
        searchEnabled: boolean = false;

    @ViewChild('settingsModal')
    private settingsModal: TemplateRef<any>;

    modalRef: NgbModalRef;

    wordingsAnalytics :any = analytics;

    public useDeltaToneEditValue: boolean;

    set selectedSize(size:number) {
        this.config.cloud_max_size = size;
        this.dashboardService.setVerbatimConfig(VerbatimConfigField.cloud_max_size, size);
    }

    get selectedSize() {
        return this.config.cloud_max_size;
    }

    constructor(
        private ngModalService: NgbModal,
        private store: Store,
        private dashboardServiceInternal: DashboardService,
        private graphService: GraphService,
        translate: TranslateService,
        private http: HttpClient,
        private corpusApi: CorpusApi,
        private dashboardApi: DashboardApi,
        private manageDashboardService: ManageDashboardService,
    ) {
        super(translate);
    }

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    get dashboardService(): DashboardService {
        return this.dashboardServiceInternal;
    }

    get selectedTab(): string {
        return this.config.word_tab;
    }

    set selectedTab(value: string) {
        this.config.word_tab = value;
        this.dashboardService.setVerbatimConfig(VerbatimConfigField.word_tab, value);
    }

    get hasDeltaTone() {
        return this.words.is_deltatone;
    }

    get useDeltaTone(): boolean {
        return this.words.is_deltatone ? this.config.cloud_use_delta_tone : false;
    }

    set useDeltaTone(value: boolean) {
        this.config.cloud_use_delta_tone = value;
        this.dashboardService.setVerbatimConfig(VerbatimConfigField.cloud_use_delta_tone, value);
    }

    get disabled() {
        return !this.words[this.subCategory] || this.words[this.subCategory].length === 0;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard) {
                this.loadWordList();
            }
        });

        this.subs.sink = this.dashboardService.newFiltersIsApplied.subscribe(() => {
            this.loadWordList();
        });

        this.subs.sink = this.dashboardService.dashboardData.subscribe((dashboardData) => {
            if (dashboardData) {
                this.counts = {
                    count: dashboardData.hits.total,
                    filtered: dashboardData.total_filters_only,
                    total: this.store.snapshot().corpus.corp_file_max_lines,
                };
            }
        });
    }

    /**
     * Charge la liste des mots clés en fonction des derniers filtres appliqués
     */
    loadWordList() {
        this.dashboardApi
            .postV1DashboardDashIdKeywords(
                this.dashboardService.currentDashboard.getValue().dash_id,
                {
                    ...this.dashboardService.getApiDates(),
                    ...this.dashboardService.lastAppliedFilters.getValue(),
                },
            )
            .subscribe((keywords) => {
                this.words = keywords.aggregations;
                this.buildWordsList();
            });
    }

    openSettingsModal() {
        this.modalRef = this.ngModalService.open(this.settingsModal);
        this.useDeltaToneEditValue = this.useDeltaTone;
    }

    getTrackCategoryName() {
        return analytics.top_keywords_to_track_cible[this.category];
    }

    /**
     * Fired when user clic on Valid button on Settings word popin
     */
    saveSettings() {
        this.useDeltaTone = this.useDeltaToneEditValue;
        gtmClick({
            track_category: this.wordingsAnalytics.track_category,
            track_name: this.wordingsAnalytics.options[this.category].track_name,
            track_cible: this.wordingsAnalytics.options[this.category].track_cible[this.useDeltaTone ? 'deltatone' : 'nodeltatone'],
        });
        this.buildWordsList();
        this.modalRef.close();
    }

    openExtendedCloud() {
        const modal = this.ngModalService.open(ExtendedCloudComponent, { size: 'full-width' });
        modal.componentInstance.words = this.words;
        modal.componentInstance.counts = this.counts;
        modal.componentInstance.hiddenWords = this.hiddenWords;
        modal.componentInstance.searchEnabled = this.searchEnabled;
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.useDeltaTone = this.useDeltaTone;

        modal.componentInstance.hide.subscribe((word: CloudData) => {
            this.hideWord(word);
        });

        modal.componentInstance.restore.subscribe((words: CloudData[]) => {
            this.restoreWord(words);
        });
    }

    tabSelected(event) {
        this.selectedTab = event;

        if (event === 'cloud') {
            setTimeout(() => {
                this.cloud.redraw();
            });
        }
    }

    exportToCSV() {
        this.dashboardService.downloadKeywords(this.subCategory);
    }

    /**
     * Méthode appelée lors du changement des category/sous-category du select
     */
    saveCategory({ category, subCategory, userInteraction }) {
        // Si c'est l'utilisateur qui a changé le select, on sauvegarde dans les configs
        if (userInteraction) {
            this.dashboardService.setCloudCategory(category, subCategory);
        }
        this.onChangeCategory({ category, subCategory });
    }
}
