import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Dashboardforuser, Dashboardlist } from 'app/api/models';
import { DashboardService as DashboardApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import CorpusService from 'app/utils/services/corpus.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DashboardSection, DashboardType } from 'app/api/models/dashboardforuser';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { TranslateService } from '@ngx-translate/core';
import DashPermissionsService from 'app/utils/services/dash-permissions.service';
import { ResetDashModified, SetDashModified } from 'app/stores/actions/settings/settings.actions';
import { Store } from '@ngxs/store';
import SharedStatus from 'app/api/models/shared-status';
import { getColor } from 'app/utils/models/colors.models';
import { ComparisonMode, DashboardConfig } from 'app/api/models/dashboard-config';
import PluralizePipe from 'app/shared/pipes/pluralize.pipe';
import * as _ from 'lodash';
import { diff } from 'deep-object-diff';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import DashboardService from './corpus-dashboard.service';

@Injectable()
export default class ManageDashboardService extends BaseComponent implements OnDestroy {
    // La liste de TOUS les dashboards correspondant au corpus
    public allDashboards: BehaviorSubject<Dashboardlist> = new BehaviorSubject<Dashboardlist>(null);

    // La liste de tous les dashboards ANALYSES correspondant au corpus
    public allAnalysesDashboards: BehaviorSubject<Dashboardlist> = new BehaviorSubject<Dashboardlist>(null);

    // La liste de toutes les dashboards COMPARAISON correspondant au corpus
    public allComparisonsDashboards: BehaviorSubject<Dashboardlist> = new BehaviorSubject<Dashboardlist>(null);

    // Déclenche un évènement lorsque l'on charge un dashboard par défaut
    public dashboardLoaded = new EventEmitter<void>();

    // Déclenche un évènement lorsque l'on ajoute ou supprimer un dashboard manuellement depuis l'ihm
    public dashboardListChanged = new EventEmitter<void>();

    // Déclenche un évènement lorsque l'on sélectionne un dashboard (simple ou comparaison)
    public dashOrCompSelected = new EventEmitter<Dashboardforuser[]>();

    // Déclenche un évènement lorsque l'on sauvegarde un dashboard
    public dashboardSaved = new EventEmitter<void>();

    // Déclenche un évènement lorsque l'on applique des nouveaux filtres sur un dashboard Analyse
    public applyNewFiltersOnAnalyseDashboard: EventEmitter<number[]> = new EventEmitter<number[]>();

    // Déclenche un évènement lorsque l'on doit charger des composants en lazyload
    public lazyLoadRequestEvent: EventEmitter<string> = new EventEmitter();

    // Déclenche un évènement lorsque l'on change le mode d'affichage d'une section (Cote à cote ou fusionné)
    public comparisonSwitchMode: EventEmitter<{ section: string; value: ComparisonMode }> = new EventEmitter();

    // Déclenche un évènement lorsque l'on fusionne les thématiques de tous les dashboards dans un dashboard
    public comparisonMergedThematics: EventEmitter<DashboardService> = new EventEmitter();

    // Déclenche un évènement lorsque l'on change les configurations des données associées
    public associatedDataSettingsChanged = new EventEmitter<void>();

    // Le type du dashboard courant (analyse ou tonalités)
    public currentDashboardType: DashboardType;

    // La liste des dashboard service (1 si on n'est en mode ANALYSE ou TONE, X sinon)
    public dashboardServices: DashboardService[] = [];

    // Dashboard initial au chargement (permet de faire la différence avec celui en cours et détecter des changements)
    private initialDashboardComparison: Dashboardforuser;

    // Dashboard utiliser pour la comparaison
    public currentDashboardComparison: BehaviorSubject<Dashboardforuser> = new BehaviorSubject<Dashboardforuser>(null);

    // Configuration du dashboard à utiliser pour la comparaison
    public currentDashboardComparisonConfig: BehaviorSubject<DashboardConfig> = new BehaviorSubject<DashboardConfig>(null);

    // pour écouter l'état des panneaux de filtre
    public filtersCollapse: BehaviorSubject<FilterCollapse> = new BehaviorSubject<FilterCollapse>(null);

    // la liste des couleurs par graph
    public graphColors = {};

    pluralizePipe: PluralizePipe = new PluralizePipe();

    // Utiliser pour enregistrer la mise en favori d'un verbatim sur un dashboard initial
    public verbatimFavoriteId: string = '';

    // Détermine si on est sur un dashboard de comparaison ou analyse classique
    get isDashboardComparison() {
        return this.currentDashboard?.getValue()?.dash_type === DashboardType.COMPARISON;
    }

    // Retourne le premier dashboard service. Donc le service correspondant à un dashboard simple,
    // ou le service du premier dashboard de la comparaison si c'est une comparaison
    get firstDashboardService() {
        return (this.dashboardServices.length) ? this.dashboardServices[0] : null;
    }

    // Retourne le dashboard de comparaison ou le dashboard simple si ce n'est pas une comparaison
    get currentDashboard() {
        if (this.currentDashboardComparison.getValue()) {
            return this.currentDashboardComparison;
        }
        return this.firstDashboardService?.currentDashboard;
    }

    // Retourne TOUS les dashboards dans un même tableau
    get allGroupedDashboards(): Dashboardforuser[] {
        if (this.allDashboards.getValue()) {
            return [
                ...this.allDashboards.value.personalDashboards,
                ...this.allDashboards.value.sharedDashboards,
                ...(this.allDashboards.value.otherDashboards ? this.allDashboards.value.otherDashboards : []),
            ];
        }
        return [];
    }

    // Retourne tous les dashboards ANALYSES dans un même tableau
    get allGroupedAnalysesDashboards(): Dashboardforuser[] {
        return this.allGroupedDashboards.filter((dash) => dash.dash_type === DashboardType.ANALYSE);
    }

    // Retourne toutes les dashboards COMPARISON dans un même tableau
    get allGroupedComparisons(): Dashboardforuser[] {
        return this.allGroupedDashboards.filter((dash) => dash.dash_type === DashboardType.COMPARISON);
    }

    get canLaunchLLMExplo() {
        // On peut lancer l'exploration des LLM si :
        // - on est le créateur du dashboard
        // - on n'est pas sur un dashboard de comparaison
        return (this.currentDashboard.getValue().username_creator === this.store.snapshot().user.username || this.currentDashboard.getValue().dash_initial) && !this.isDashboardComparison;
    }

    constructor(
        private dashboardApi: DashboardApi,
        private corpusService: CorpusService,
        private router: Router,
        private store: Store,
        private modalService: NgbModal,
        private translateService: TranslateService,
        private dashPermissions: DashPermissionsService,
    ) {
        super();

        this.subs.sink = this.currentDashboardComparisonConfig.subscribe((config) => {
            if (config !== null) {
                this.checkIfComparisonDashboardHaveChangesNotSaved();
            }
        });
    }

    ngOnDestroy(): void {
        this.allDashboards.next(null);
        this.allAnalysesDashboards.next(null);
        this.allComparisonsDashboards.next(null);
        this.filtersCollapse.next(null);
        this.currentDashboardComparisonConfig.next(null);

        this.allDashboards.unsubscribe();
        this.allAnalysesDashboards.unsubscribe();
        this.allComparisonsDashboards.unsubscribe();
        this.currentDashboardComparison.unsubscribe();
        this.currentDashboardComparisonConfig.unsubscribe();
        this.filtersCollapse.unsubscribe();
        this.currentDashboardComparisonConfig.unsubscribe();

        super.ngOnDestroy();
    }

    /**
     * Vérifie si le filtrage appliqué est différent du filtrage sauvegardé pour savoir s'il faut activer la zone d'enregitrement
     */
    private checkIfComparisonDashboardHaveChangesNotSaved() {
        if (this.currentDashboardComparisonConfig.getValue() === null) {
            return;
        }

        const configChanged = diff(this.currentDashboardComparison.getValue().dash_json_config, this.currentDashboardComparisonConfig.getValue());
        this.store.dispatch(new SetDashModified({ dashboardId: this.currentDashboardComparison.getValue().dash_id, isDashModified: !_.isEmpty(configChanged) }));
    }

    /**
     * Enregistrement des dashboardService dans le manager pour y faire toutes les actions nécessaires
     */
    public addDashboardService(service: DashboardService) {
        this.dashboardServices.push(service);
    }

    /**
     * Supprime un dashboardService du manager
     */
    public removeDashboardService(service: DashboardService) {
        const index = this.dashboardServices.indexOf(service);
        if (index !== -1) {
            this.dashboardServices.splice(index, 1);
        }
    }

    /**
     * Retourne un identifiant pour le dashboard spécifié en paramètre (utilise l'ID et l'index)
     */
    public getDashboardUniqueId(dashboardService: DashboardService): string {
        return dashboardService ? `${dashboardService.currentDashboard.getValue().dash_id}_${this.dashboardServices.indexOf(dashboardService)}` : '';
    }

    /**
     * Méthode appelée après le chargement du corpus pour charger tous les dashboards et sélectionner celui par defaut
     */
    public async loadDashboardsAfterCorpus(type: DashboardType) {
        this.currentDashboardType = type;
        await this.loadAllDashboards();
        // On récupère le dashboard par défaut à charger
        const dashboards = await this.getDefaultDashboards();
        this.dashOrCompSelected.emit(dashboards);
    }

    /**
     * Chargement de tous les dashboards du corpus pour peupler la zone de sélection
     */
    async loadAllDashboards(): Promise<void> {
        const currentCorpusId: number = this.corpusService.currentCorpus.getValue().corp_id;

        // on appel le back pour charger la liste
        try {
            const allDashs = await firstValueFrom(this.dashboardApi.loadDashboardsByType(currentCorpusId, this.currentDashboardType));
            if (allDashs === null) {
                return null;
            }

            this.allDashboards.next(allDashs);
            // On sépare les dashboards analyses et comparaison
            this.allAnalysesDashboards.next({
                personalDashboards: allDashs.personalDashboards.filter((dash) => dash.dash_type === this.currentDashboardType),
                sharedDashboards: allDashs.sharedDashboards.filter((dash) => dash.dash_type === this.currentDashboardType),
                otherDashboards: allDashs.otherDashboards ? allDashs.otherDashboards.filter((dash) => dash.dash_type === this.currentDashboardType) : [],
            });
            // Seulement pour le mode ANALYSE, on récupère les comparaisons (pas lorsque l'on est sur les tonalités)
            if (this.currentDashboardType === DashboardType.ANALYSE) {
                this.allComparisonsDashboards.next({
                    personalDashboards: allDashs.personalDashboards.filter((dash) => dash.dash_type === DashboardType.COMPARISON),
                    sharedDashboards: allDashs.sharedDashboards.filter((dash) => dash.dash_type === DashboardType.COMPARISON),
                    otherDashboards: allDashs.otherDashboards ? allDashs.otherDashboards.filter((dash) => dash.dash_type === DashboardType.COMPARISON) : [],
                });
            }
        } catch (error) {
            if (error.error?.code === 404) {
                this.router.navigate([
                    `/project/${currentCorpusId}/settings/files`,
                ]);
            }
        }
        return Promise.resolve();
    }

    /**
     * Remplacement d'un dashboard existant dans la liste des dashboard (identifié par son id), par une version mise à jour
     * @param updatedDashboard Le dashboard mis à jour (son id doit correspondre à celui qu'il va remplacer)
     */
    saveDashboardIntoDashboardList(updatedDashboard: Dashboardforuser) {
        this.allDashboards.value.personalDashboards = this.allDashboards.value.personalDashboards.map((dashboard) => (dashboard.dash_id === updatedDashboard.dash_id
            ? updatedDashboard
            : dashboard));
        this.allDashboards.value.sharedDashboards = this.allDashboards.value.sharedDashboards.map((dashboard) => (dashboard.dash_id === updatedDashboard.dash_id
            ? updatedDashboard
            : dashboard));

        this.allAnalysesDashboards.value.personalDashboards = this.allAnalysesDashboards.value.personalDashboards.map((dashboard) => (dashboard.dash_id === updatedDashboard.dash_id
            ? updatedDashboard
            : dashboard));
        this.allAnalysesDashboards.value.sharedDashboards = this.allAnalysesDashboards.value.sharedDashboards.map((dashboard) => (dashboard.dash_id === updatedDashboard.dash_id
            ? updatedDashboard
            : dashboard));
    }

    /**
     * Sélection d'un dashboard (classique ou comparaison)
     * @param dashId l'identifiant du dashboard sélectionné
     */
    public async selectDashboard(dashId) {
        this.store.dispatch(new ResetDashModified());
        const dashboard = this.allGroupedDashboards.find((dash) => dash.dash_id === dashId);
        if (dashboard) {
            this.dashPermissions.setPermissions(dashboard);
            // Si on a sélectionné un dashboard de comparaison
            if (dashboard.dash_type === DashboardType.COMPARISON) {
                // On récupère ses dashboards enfants
                const dashboardsChild = await Promise.all(dashboard.dash_childs.map((child) => firstValueFrom(this.dashboardApi.loadDashboard(child))));
                this.initialDashboardComparison = JSON.parse(JSON.stringify(dashboard));
                this.currentDashboardComparison.next(dashboard);
                this.currentDashboardComparisonConfig.next(_.cloneDeep(dashboard.dash_json_config));
                this.dashOrCompSelected.emit(dashboardsChild);
            } else {
                // Sinon on a sélectionné un dashboard classique, on reset la comparaison (si on était dessus)
                this.initialDashboardComparison = undefined;
                this.currentDashboardComparison.next(null);
                this.currentDashboardComparisonConfig.next(null);
                this.dashOrCompSelected.emit([dashboard]);
            }
        }
    }

    /**
     * Sélection du dashboard en favori (ou sinon le dashboard initial)
     */
    public async getDefaultDashboards(): Promise<Dashboardforuser[]> {
        // On récupère le dashboard en favori
        const dashFavori = this.allGroupedDashboards.find((dash) => dash.is_default);
        if (dashFavori) {
            this.dashPermissions.setPermissions(dashFavori);
            // Si le dashboard en favori est un dashboard de comparaison
            if (dashFavori.dash_type === DashboardType.COMPARISON) {
                // On récupère ses dashboards enfants
                const dashboardsChild = await Promise.all(dashFavori.dash_childs.map((child) => firstValueFrom(this.dashboardApi.loadDashboard(child))));
                this.initialDashboardComparison = JSON.parse(JSON.stringify(dashFavori));
                this.currentDashboardComparison.next(dashFavori);
                this.currentDashboardComparisonConfig.next(_.cloneDeep(dashFavori.dash_json_config));
                return dashboardsChild;
            }
            this.initialDashboardComparison = undefined;
            this.currentDashboardComparison.next(null);
            this.currentDashboardComparisonConfig.next(null);
            // Sinon c'est un dashboard classique
            return [dashFavori];
        }

        // Si on n'a pas de dashboard en favori, on prend le dashboard initial (qui est forcément un dashboard classique)
        return [this.allGroupedDashboards.find((dash) => dash.dash_initial)];
    }

    /** ********************************COMPARISON****************************** */
    /**
     * Créer une nouvelle comparaison de dashboard
     * @param selectedDashIds : liste des Ids des dashboards à comparer
     */
    public async createNewComparison(selectedDash: Dashboardforuser[]) {
        const selectedDashboards = [];
        // On créé les dashboards enfants de la comparaison
        selectedDash.forEach((dashboard) => {
            selectedDashboards.push(this.copyDashForComparison(dashboard));
        });
        if (selectedDashboards.length) {
            const newDashComparison: Dashboardforuser = {
                dash_name: '',
                dash_shared: SharedStatus.NONE,
                is_default: false,
                dash_type: DashboardType.COMPARISON,
                dash_childs: selectedDashboards.map((dash) => dash.dash_id),
                dash_json_config: {
                    ..._.cloneDeep(selectedDashboards[0].dash_json_config),

                    comparison: this.dashboardApi.getDefaultComparisonConfig(),
                },
            };
            this.dashPermissions.setPermissions(newDashComparison);
            this.currentDashboardComparison.next(newDashComparison);
            this.currentDashboardComparisonConfig.next(_.cloneDeep(newDashComparison.dash_json_config));
            this.dashOrCompSelected.emit(selectedDashboards);
            this.store.dispatch(new SetDashModified({ dashboardId: null, isDashModified: true }));
        }
    }

    /**
     * Créer une copie d'un dashboard. On nomme (avec les bon paramètre) et on met le type CHILD.
     */
    public copyDashForComparison(dashboard:Dashboardforuser) {
        const dashCopy = _.cloneDeep(dashboard);
        dashCopy.dash_is_cloned = true;
        if (dashCopy.dash_initial) {
            // Ajout de 'Initial'
            dashCopy.dash_name = `Initial ${dashCopy.dash_name}`;
        } else if (dashCopy.dash_section === DashboardSection.OTHER) {
            // Ajout du nom du User
            dashCopy.dash_name = `${dashCopy.username_creator.toUpperCase()} / ${dashCopy.dash_name}`;
        }
        // Ajout de "Copie de"
        dashCopy.dash_name = this.translateService.instant('translations.analysisDashboard.newComparisonModal.copyName', { name: dashCopy.dash_name });
        dashCopy.dash_type = DashboardType.CHILD;

        dashCopy.dash_json_config.thematics.distri.nb_values_chart = -1;
        dashCopy.dash_json_config.thematics.distri.top_activated = false;
        dashCopy.dash_json_config.thematics.histo.nb_values_chart = -1;
        dashCopy.dash_json_config.thematics.histo.top_activated = false;
        dashCopy.dash_json_config.class.distri.nb_values_chart = -1;
        dashCopy.dash_json_config.class.distri.top_activated = false;
        dashCopy.dash_json_config.class.histo.nb_values_chart = -1;
        dashCopy.dash_json_config.class.histo.top_activated = false;

        return dashCopy;
    }

    /**
     * Retourne les dashboards qui ont la même colonne de verbatim que le dashboard passé en paramètre
     */
    public getDashboardsWithSameVerbatim(dashboardRef: Dashboardforuser) {
        // Si on a 1 dashboard de sélectionner dans le 1er select, on filtre la liste des dashboards pour avoir tous les dashboards avec la mm colonne de verbatim
        return dashboardRef !== undefined ? {
            personalDashboards: (this.allDashboards.value.personalDashboards ?? []).filter((dash) => dash.dash_json_params.verbatim_dbname === dashboardRef.dash_json_params.verbatim_dbname),
            sharedDashboards: (this.allDashboards.value.sharedDashboards ?? []).filter((dash) => dash.dash_json_params.verbatim_dbname === dashboardRef.dash_json_params.verbatim_dbname),
            otherDashboards: (this.allDashboards.value.otherDashboards ?? []).filter((dash) => dash.dash_json_params.verbatim_dbname === dashboardRef.dash_json_params.verbatim_dbname),
        } : this.allDashboards.value;
    }

    /** ****************************ACTIONS******************************** */

    /**
     * Pour faire du dashboard courant le dashboard par défaut
     */
    async makeDefault() {
        this.sendTracking('analyse favorite', 'renommer');

        await firstValueFrom(this.dashboardApi.putV1DashboardDashIdDefault(this.currentDashboard.value.dash_id));

        this.allGroupedDashboards.forEach((dash) => {
            dash.is_default = dash.dash_id === this.currentDashboard.value.dash_id;
        });
    }

    /**
     * Enregistre le dashboard courant
     */
    public async save(): Promise<Dashboardforuser> {
        this.sendTracking('enregistrer analyse', 'enregistrer');
        // On reset les modifications
        this.store.dispatch(new ResetDashModified());

        if (this.isDashboardComparison) {
            // On sauvegarde les dashboards existants ou on crée les dashboards qui ont été ajoutés
            const dashChilds = await Promise.all(this.dashboardServices.map(async (dashService) => {
                const currentDashboard = dashService.currentDashboard.getValue();
                // Si le dashboard était déjà présent dans la comparaison, on le sauve
                if (currentDashboard.dash_is_cloned) {
                    // Sinon on enregistre un nouveau dashboard enfant
                    const newDashId = await dashService.saveAs(currentDashboard.dash_name, DashboardType.CHILD);
                    // On met à jour son nouvel ID dans le service
                    dashService.currentDashboard.value.dash_id = newDashId;
                    return newDashId;
                }
                return (await dashService.save()).dash_id;
            }));

            // On supprime les dashboards qui ne sont plus rattaché à ce dashboard
            const dashChildRemoved = this.initialDashboardComparison.dash_childs.map(Number).filter((child) => !dashChilds.map(Number).includes(child));

            await Promise.all(dashChildRemoved.map((dashId) => firstValueFrom(this.dashboardApi.deleteV1DashboardDashId(dashId))));

            // Sauvegarde la comparaison
            const savedDashComp = await firstValueFrom(this.dashboardApi.updateDashboard({
                dashId: this.currentDashboardComparison.getValue().dash_id,
                body: { dash_childs: dashChilds, json_config: this.currentDashboardComparisonConfig.getValue() },
            }));

            // Met à jour les données suite à la sauvegarde
            this.currentDashboardComparison.next(savedDashComp);
            this.currentDashboardComparisonConfig.next(_.cloneDeep(savedDashComp.dash_json_config));
            this.initialDashboardComparison = JSON.parse(JSON.stringify(savedDashComp));
            return savedDashComp;
        }
        const newDashboard = await this.firstDashboardService.save();
        this.dashboardSaved.emit();
        this.saveDashboardIntoDashboardList(newDashboard);
        this.store.dispatch(new SetDashModified({ dashboardId: newDashboard.dash_id, isDashModified: false }));
        return newDashboard;
    }

    /**
     * Enregistre sous
     * @param name : nom du nouveau dashboard ou de la nouvelle comparaison
     */
    public async saveAs(name: string) {
        let newDashId = null;
        this.sendTracking('enregistrer analyse', 'enregistrer sous');
        this.store.dispatch(new ResetDashModified());

        if (this.isDashboardComparison) {
            // Enregistre les X dashboards enfants de la comparaison
            const result = await Promise.all(this.dashboardServices.map((dashService) => dashService.saveAs(dashService.currentDashboard.getValue().dash_name, DashboardType.CHILD)));
            // Enregistre la comparaison
            newDashId = (await firstValueFrom(this.dashboardApi.postV1Dashboard({
                corp_id: this.corpusService.currentCorpus.getValue().corp_id,
                name,
                shared: SharedStatus.NONE,
                type: DashboardType.COMPARISON,
                dash_childs: result,
                json_params: {},
                json_config: this.currentDashboardComparisonConfig.getValue(),
            }))).id;
        } else {
            newDashId = await this.firstDashboardService.saveAs(name);
        }

        if (newDashId) {
            // On recharge la liste des dashboard pour prendre en compte le dashboard nouvellement créée
            await this.loadAllDashboards();
            this.selectDashboard(+newDashId);
            this.store.dispatch(new SetDashModified({ dashboardId: newDashId, isDashModified: false }));
        }

        return newDashId;
    }

    /**
     * Renomme un dashboard
     */
    public async rename(newName) {
        this.sendTracking('renommer analyse', 'renommer');

        const newDashboard = await firstValueFrom(this.dashboardApi.updateDashboard({
            dashId: this.currentDashboard.value.dash_id,
            body: { name: newName },
        }));

        this.saveDashboardIntoDashboardList(newDashboard);
        this.currentDashboard.value.dash_name = newDashboard.dash_name;
    }

    /**
     * Change le partage le dashboard courant
     * @param shared Partage du dashboard courant
     * @param usernames la liste des utilisateurs pour le pargage
     */
    public async share(shared: SharedStatus, usernames: string[]) {
        let trackCible = '';
        switch (shared) {
            case SharedStatus.NONE:
                trackCible = 'analyse non partagée';
                break;
            case SharedStatus.ALL:
                trackCible = 'analyse partagée tous utilisateurs';
                break;
            case SharedStatus.SELECT:
            default:
                trackCible = 'analyse partagée certains utilisateurs';
        }
        this.sendTracking('partage de l\'analyse', trackCible);

        const dashId = this.currentDashboard.value.dash_id;

        await firstValueFrom(this.dashboardApi.updateDashboard({ dashId, body: { shared } }));
        if (usernames.length > 0) {
            await firstValueFrom(this.dashboardApi.putV1DashboardDashIdShare({ dashId, body: { usernames } }));
        }
    }

    /**
     * Supprime un dashboard
     */
    public async delete() {
        this.sendTracking('suppression analyse');
        // On reset les modifications
        this.store.dispatch(new ResetDashModified());

        if (this.isDashboardComparison) {
            await Promise.all(this.dashboardServices.map((dashService) => firstValueFrom(this.dashboardApi.deleteV1DashboardDashId(dashService.currentDashboard.value.dash_id))));
            await firstValueFrom(this.dashboardApi.deleteV1DashboardDashId(this.currentDashboardComparison.getValue().dash_id));
            this.currentDashboardComparison.next(null);
            this.currentDashboardComparisonConfig.next(null);
        } else {
            await firstValueFrom(this.dashboardApi.deleteV1DashboardDashId(this.currentDashboard.value.dash_id));
        }
        await this.loadAllDashboards();
        this.dashOrCompSelected.emit(await this.getDefaultDashboards());
    }

    computeDashboardName() {
        return this.isDashboardComparison
            ? this.dashboardServices.map((dashServ) => dashServ.currentDashboard.getValue().dash_name).join(' VS ')
            : this.currentDashboard.getValue().dash_name;
    }

    /**
     * Envoi un tracking (avec la catégorie déjà définit)
     */
    private sendTracking(trackName: string, trackCible?: string) {
        gtmClick({
            track_category: `${this.currentDashboardType === DashboardType.ANALYSE ? 'analyse' : 'dashboard tonalité'}`,
            track_name: trackName,
            track_cible: `${trackCible ? `${trackCible} ` : ''}${this.isDashboardComparison ? 'comparaison' : 'dashboard'}`,
        });
    }

    /** **************************** UTILITIES ******************************** */

    // les getter ci dessous renvoient des paramètres communs à tous les dashbaord, donc peuvent être appelés sur le premier service de la liste

    getLastAppliedThematics() {
        return this.firstDashboardService?.lastAppliedFilters.getValue().thematics;
    }

    getVerbatimDbName() {
        return this.firstDashboardService?.lastAppliedFilters.getValue().verbatim_dbname;
    }

    getCurrentModel() {
        return this.firstDashboardService?.getCurrentModel();
    }

    getUserChoices() {
        return this.firstDashboardService?.currentConfig.getValue().userChoices;
    }

    getCurrentVerbatimColumns() {
        return this.firstDashboardService?.currentVerbatimColumns;
    }

    public setFilterCollapse(filterCollapse: FilterCollapse) {
        this.filtersCollapse.next(filterCollapse);
    }

    public getDataColor(pScope: string = '', pKey: string = '') {
        const key = `${pKey}`;
        const scope = `${pScope}`;

        // on cherche les couleurs pour un type de graph (scope)
        let colors = this.graphColors[scope.toLowerCase()];
        if (!colors) {
            colors = {};
            colors.index = 0;
            this.graphColors[scope.toLowerCase()] = colors;
        }

        // pour le type de graph, on cherche la couleur d'une courbe (key)
        let color = colors[key.toLowerCase()];
        if (!color) {
            color = getColor(colors.index);
            colors.index += 1;
            colors[key.toLowerCase()] = color;
        }

        return color;
    }

    setComparisonSwitchConfig(section:string, value: ComparisonMode) {
        this.currentDashboardComparisonConfig.getValue().comparison[section] = value;
        this.comparisonSwitchMode.emit({ section, value });
        this.currentDashboardComparisonConfig.next(this.currentDashboardComparisonConfig.getValue());
    }

    public setComparisonDashbardConfig(type, params) {
        this.currentDashboardComparisonConfig.getValue()[type] = params;
        this.currentDashboardComparisonConfig.next(this.currentDashboardComparisonConfig.getValue());
    }

    public setSatisfactionConfig(type, graphtype, params) {
        this.currentDashboardComparisonConfig.getValue().satisfaction[type][graphtype] = params;
        this.currentDashboardComparisonConfig.next(this.currentDashboardComparisonConfig.getValue());
    }

    /**
     * Gestion de la fusion des thématiques entre les différents dashboards.
     * Affiche une popin si il y avait des différences entre les thématiques des différents dashboards
     */
    public manageMergedThematics() {
        if (this.isDashboardComparison) {
            let isDiffBetweenThematics = false;
            // On regroupe les thématiques de tous les dashboards (et on les dé-sélectionne)
            let groupedThematics = this.dashboardServices.flatMap((service) => _.cloneDeep(service.currentFilters.getValue().thematics)).map((t) => { t.selected = false; return t; });
            groupedThematics = [...new Map(groupedThematics.map((item) => [item.them_id, item])).values()];
            // On parcourt les différents dashboards services
            this.dashboardServices.forEach((service) => {
                // on récupère les thématiques du service
                const currentThematics = service.currentFilters.getValue().thematics;
                // On compare le nom des thématiques entre le dashboard et la liste complète
                if (!_.isEmpty(diff(currentThematics.map((t) => t.them_name), groupedThematics.map((t) => t.them_name)))) {
                    isDiffBetweenThematics = true;
                    // On ajoute les thématiques qui ne sont pas déjà présentes dans les filtres de ce dashboard
                    const newThematics = [
                        ...currentThematics,
                        ...groupedThematics.filter((t) => !currentThematics.map((ct) => ct.them_id).includes(t.them_id)),
                    ].sort((a, b) => a.them_name.localeCompare(b.them_name));
                    service.currentDashboard.value.dash_json_params.thematics = newThematics;
                    service.currentFilters.value.thematics = newThematics;
                    this.comparisonMergedThematics.emit(service);
                }
            });
            if (isDiffBetweenThematics) {
                const modal = this.modalService.open(ModalComponent, {});
                modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.comparison.modal.thematicsMerge.title';
                modal.componentInstance.contentToTranslate = 'translations.analysisDashboard.comparison.modal.thematicsMerge.description';
                modal.componentInstance.hasBtnClose = false;
                modal.componentInstance.btnValidVariant = 'primary';
                modal.componentInstance.btnValidToTranslate = 'translations.utils.ok';
            }
        }
    }

    /**
     * Méthode appelée lorsque l'on ajoute une thématique.
     * Applique les changements sur tous les dashboards.
     */
    public addThematics(thematics, currentDashboardService) {
        // Pour tous les dashboards
        this.dashboardServices.forEach((service) => {
            const currentFilters = service.currentFilters.getValue();
            // preserve selected state if exists
            const clonedThematics = thematics.map((thematic) => {
                // on cherche si la thématique reçues est déjà dans la liste des thématiques précédement sélectionnées
                const matchingThematics = currentFilters.thematics.filter((existingThematic) => existingThematic.them_id === thematic.them_id);
                if (matchingThematics.length > 0) {
                    // si elle existe, on copie son état
                    thematic.selected = matchingThematics[0].selected;
                } else {
                    thematic.selected = service === currentDashboardService;
                }
                return _.cloneDeep(thematic);
            });

            service.updateThematicList({
                selected_all: (service === currentDashboardService) ? (thematics.length === 0) : currentFilters.selected_all,
                selected_not: (service === currentDashboardService) ? false : currentFilters.selected_not,
                thematics: clonedThematics,
            });
        });
    }

    /**
     * Méthode appelée lorsque l'on renomme une thématique ajoutée à un dashboard.
     * Applique les changements sur tous les dashboards si la thématique fait partie de la liste
     */
    public renameThematic(renamedThematic) {
        // Pour tous les dashboards
        this.dashboardServices.forEach((service) => {
            const currentFilters = service.currentFilters.getValue();
            // On recherche si le dashboard contient la thématique modifiée
            const thematicToUpdate = currentFilters.thematics.find((t) => t.them_id === renamedThematic.them_id);
            if (thematicToUpdate) {
                // On la met à jour et on notifie du changement
                thematicToUpdate.them_name = renamedThematic.them_name;
                thematicToUpdate.them_definition = renamedThematic.them_definition;
                service.updateThematicList({
                    selected_all: false,
                    selected_not: false,
                    thematics: currentFilters.thematics,
                });
            }
        });
    }

    /**
     * Méthode appelée lorsque l'on supprime une thématique d'un dashboard.
     * Applique les changements sur tous les dashboards si la thématique fait partie de la liste
     */
    public deleteThematic(deletedThematic) {
        // Pour tous les dashboards
        this.dashboardServices.forEach((service) => {
            const currentFilters = service.currentFilters.getValue();
            // On recherche si le dashboard contient la thématique à supprimer
            const thematicToDelete = currentFilters.thematics.find((t) => t.them_id === deletedThematic.them_id);
            if (thematicToDelete) {
                // On la supprime et on notifie du changement
                const thematics = currentFilters.thematics.filter((t) => t.them_id !== deletedThematic.them_id);
                service.updateThematicList({
                    selected_all: thematics.filter((t) => t.selected).length === 0,
                    selected_not: false,
                    thematics,
                });
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    compareTwoFilters(filters1, filters2) {
        const filtersDifferences = {
            'filters-search': false,
            'filters-period': false,
            'filters-associated-data': false,
            'filters-verbatim': false,
            'filters-classifications-auto': false,
            'filters-thematics': false,
            'filters-sentiment': false,
            'filters-opinion-target': false,
        };

        const filters = _.cloneDeep(filters1);
        const filtersToCompare = _.cloneDeep(filters2);
        filters.keywordsearch = filters.keywordsearch || [];
        filtersToCompare.keywordsearch = filtersToCompare.keywordsearch || [];
        filters.filters = filters.filters || [];
        filtersToCompare.filters = filtersToCompare.filters || [];
        filters.freesearch = filters.freesearch || '';
        filtersToCompare.freesearch = filtersToCompare.freesearch || '';
        // Supprime les propriétés qui ne doivent pas faire parties de la comparaison
        filters.keywordsearch.forEach((k) => delete k.animate);
        filtersToCompare.keywordsearch.forEach((k) => delete k.animate);
        filters.thematics = (filters.thematics || []).map((t) => ({ them_id: t.them_id, selected: t.selected }));
        filtersToCompare.thematics = (filtersToCompare.thematics || []).map((t) => ({ them_id: t.them_id, selected: t.selected }));
        // Effectue la comparaison
        const differences:any = diff(filters, filtersToCompare);
        if (!_.isEmpty(differences)) {
            const differencesKeys = Object.keys(differences);
            if (differencesKeys.filter((k) => ['freesearch', 'keywordsearch'].includes(k)).length) {
                filtersDifferences['filters-search'] = true;
            }
            if (differencesKeys.filter((k) => ['date_begin', 'date_end', 'period'].includes(k)).length) {
                filtersDifferences['filters-period'] = true;
            }
            if (differencesKeys.includes('filters_class')) {
                filtersDifferences['filters-classifications-auto'] = true;
            }
            if (differencesKeys.filter((k) => ['selected_all', 'selected_not'].includes(k)).length) {
                filtersDifferences['filters-thematics'] = true;
            }
            // Détermine les différences sur les thématiques
            if (!filters.selected_all && !filtersToCompare.selected_all && !filters.selected_not && !filtersToCompare.selected_not && differencesKeys.includes('thematics')) {
                filtersDifferences['filters-thematics'] = true;
            }
            if (differencesKeys.filter((k) => ['verbatim_length_min', 'verbatim_length_max'].includes(k)).length) {
                filtersDifferences['filters-verbatim'] = true;
            }
            if (differencesKeys.includes('filters')) {
                // Détermine si la section "Tonalités" a des différences
                const sentimentsDiff = diff(filters.filters.find((f) => f.inputName === 'sentiment'), filtersToCompare.filters.find((f) => f.inputName === 'sentiment'));
                if (sentimentsDiff === undefined || !_.isEmpty(sentimentsDiff)) {
                    filtersDifferences['filters-sentiment'] = true;
                }
                // Détermine si la section "Cibles d'opinions" a des différences
                const oteDiff = diff(filters.filters.filter((f) => f.inputName.startsWith('ote')), filtersToCompare.filters.filter((f) => f.inputName.startsWith('ote')));
                if (oteDiff === undefined || !_.isEmpty(oteDiff)) {
                    filtersDifferences['filters-opinion-target'] = true;
                }
                // Détermine si la section "Verbatim" a des différences
                const verbatimDiff = diff(filters.filters.find((f) => f.inputName === 'pres_verbatim'), filtersToCompare.filters.find((f) => f.inputName === 'pres_verbatim'));
                if ((verbatimDiff === undefined || !_.isEmpty(verbatimDiff))) {
                    //  ajoute la section si elle n'a pas déjà été ajoutée
                    // if (!filtersDifferences['filters-verbatim'][index].includes(i + 1)) {
                    filtersDifferences['filters-verbatim'] = true;
                    // }
                }
                // Détermine si la section "Données associées" a des différences
                const associatedData = filters.filters.filter((f) => !['sentiment', 'pres_verbatim'].includes(f.inputName) && !f.inputName.startsWith('ote'));
                const associatedDataCompare = filtersToCompare.filters.filter((f) => !['sentiment', 'pres_verbatim'].includes(f.inputName) && !f.inputName.startsWith('ote'));
                const associatedDataDiff = diff(associatedData, associatedDataCompare);
                if (associatedDataDiff === undefined || !_.isEmpty(associatedDataDiff)) {
                    filtersDifferences['filters-associated-data'] = true;
                }
            }
        }
        return filtersDifferences;
    }

    /**
     * Détermine les différences entre les filtres (ajout des textes et couleurs)
     */
    public manageDifferenceBetweenFilters() {
        // Construit l'objet qui va contenir les différences pour chaque section
        const filtersDifferences = [
            'filters-search',
            'filters-period',
            'filters-associated-data',
            'filters-verbatim',
            'filters-classifications-auto',
            'filters-thematics',
            'filters-sentiment',
            'filters-opinion-target',
        ].reduce((acc, key) => { acc[key] = Array.from(Array(this.dashboardServices.length), () => []); return acc; }, {});

        // Compare les filtres entre eux et sauvegarde les différences
        this.dashboardServices.forEach((service, index) => {
            this.dashboardServices.forEach((serviceToCompare, i) => {
                if (index !== i) {
                    const compareResult = this.compareTwoFilters(service.currentFilters.getValue(), serviceToCompare.currentFilters.getValue());
                    Object.keys(compareResult).forEach((key) => {
                        if (compareResult[key] && !filtersDifferences[key][index].includes(i + 1)) {
                            //  ajoute la section si elle n'a pas déjà été ajoutée
                            filtersDifferences[key][index].push(i + 1);
                        }
                    });
                }
            });
        });

        // Supprime tous les anciens textes et supprime les anciennes classes (si elles existaient)
        document.querySelectorAll('.diff-content').forEach((e) => {
            e.parentElement.parentElement.classList.remove('blue');
            e.parentElement.parentElement.classList.remove('purple');
            e.parentElement.parentElement.classList.remove('filter-diff');
            e.parentNode.removeChild(e);
        });
        // Ajoute les classes et les textes dans les éléments en fonction de leurs différences
        Object.entries(filtersDifferences).forEach(([filterName, diffIndex]: [string, any]) => {
            if (diffIndex.filter((index) => index.length > 0).length) {
                document.querySelectorAll(`app-${filterName}`).forEach((element, i) => {
                    // Si l'élément a du contenu (sinon cela signifie que la section n'est pas affichée)
                    if (element.children.length) {
                        // Détermine quelle couleur on doit mettre sur la section des filtres
                        if (i === 1 && !_.isEmpty(diff(diffIndex[0], diffIndex[i]))) {
                            element.parentElement.classList.add('blue');
                        }
                        if (i === 2) {
                            if (diffIndex[i][0] === 1 && diffIndex[i][1] === 2) {
                                element.parentElement.classList.add('purple');
                            } else if (!_.isEmpty(diff(diffIndex[0], diffIndex[i])) && _.isEmpty(diff(diffIndex[1], diffIndex[i]))) {
                                element.parentElement.classList.add('blue');
                            }
                        }

                        // Ajoute le texte qui indique les différences entre les filtres
                        const diffIndexText = diffIndex[i].join(` ${this.translateService.instant('translations.utils.and')} `);
                        const diffTextNode = document.createElement('div');
                        diffTextNode.textContent = this.pluralizePipe.transform(this.translateService.instant('translations.corpus.filters.diff-text', {
                            indexList: diffIndexText,
                        }), diffIndex[i].length);
                        diffTextNode.classList.add('diff-content');
                        element.prepend(diffTextNode);

                        // Ajoute la classe globale qui permet d'ajouter la bordure
                        if (diffIndex[i].length) {
                            element.parentElement.classList.add('filter-diff');
                        }
                    }
                });
            }
        });
    }
}

export interface FilterCollapse {
    filterName: string;

    isCollapsed: boolean;
}
