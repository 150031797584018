import {
    Component, Input, OnInit, ViewChild,
} from '@angular/core';
import { addCenteredValuesOnHorizontalStackedHistogram } from 'app/utils/chartjs-plugins/add-centered-values-on-horizontal-stacked-histograms';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import DistributionColorsAndOrder from '../../../satisfaction-charts/components/satisfaction-bar-chart/distribution-colors';

@Component({
    selector: 'app-distribution-details-popup-bar-chart',
    templateUrl: './distribution-details-popup-bar-chart.component.html',
})
export default class DistributionDetailsPopupBarChartComponent implements OnInit {
    chartPlugins = [addCenteredValuesOnHorizontalStackedHistogram];

    graphData;

    @Input()
        chartTooltips: ChartTooltipsService;

    @Input()
        type: string;

    @ViewChild('chart') chart;

    @Input()
    set data(data) {
        this.graphData = { labels: [''], datasets: [] };

        this.getOrderedData(data)
            .filter((value) => value.doc_count !== null)
            .forEach((value) => {
                this.graphData.datasets.push({
                    data: [value.doc_count || 0],
                    backgroundColor: [DistributionColorsAndOrder[this.type][value.key]],
                    hoverBackgroundColor: [DistributionColorsAndOrder[this.type][value.key]],
                });
            });
    }

    options = {};

    ngOnInit(): void {
        this.options = this.getOptions();
    }

    getOrderedData(data) {
        // fix temporaire pour mettre les données correctement dans l'ordre
        // A supprimer quand le back renverra des string au lieu des valeurs numériques
        data.values.forEach((value) => {
            value.key = (Number.isNaN(+value.key) ? value.key : `${value.key}_`);
        });

        return Object.keys(DistributionColorsAndOrder[this.type])
            .map((order) => data.values.find((value) => (order === `${value.key}`)) || null)
            .map((value) => (value === null ? null : value))
            .filter((value) => value !== null);
    }

    getMaxValue() {
        let max = 0;
        this.graphData.datasets.forEach((dataset) => {
            dataset.data.forEach((data) => max += data);
        });
        return max;
    }

    getOptions() {
        const thisInternal = this;
        return {
            indexAxis: 'y',
            layout: {
                padding: {
                    left: -8,
                },
            },
            scales: {
                x: {
                    stacked: true,
                    border: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                    distribution: 'series',
                    ticks: {
                        color: '#000',
                        percent: false,
                        min: 0,
                        display: false,
                    },
                    max: this.getMaxValue(),
                },
                y: {
                    stacked: true,
                    border: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#000',
                        display: false,
                    },
                },
            },
            interaction: {
                mode: 'y',
            },

            responsive: true,
            maintainAspectRatio: false,
            animation: false,

            plugins: {
                addCenteredValuesOnHorizontalStackedHistogram: true,
                labels: true,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external(context) {
                        let innerHtml = '';
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title;
                            if (titleLines.length > 0) {
                                innerHtml += '<div class=\'tooltip-title\'>';
                                titleLines.forEach((title) => {
                                    innerHtml += title;
                                });
                                innerHtml += '</div>';
                            }
                            tooltipModel.body.forEach((body, i) => {
                                innerHtml += `<div class='text-truncate'><span class='tooltip-legend' style='background:${tooltipModel.labelColors[i].backgroundColor}'></span>${body.lines[0]}% (${thisInternal.graphData.datasets[i].data[0]})</div>`;
                            });
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        thisInternal.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
                    },
                },
            },

        };
    }
}
