import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CopcConfig } from 'app/api/models/copcconfig';
import { Period } from 'app/api/models/distriparams';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';

@Component({
    selector: 'app-copc-line-chart-settings-popup',
    templateUrl: './copc-line-chart-settings-popup.component.html',
})
export default class CopcLineChartSettingsPopupComponent implements OnInit {
    params: CopcConfig;

    initialParams: CopcConfig;

    graphService: GraphService;

    dashboardService: DashboardService;

    type: string;

    @Output()
    private save: EventEmitter<CopcConfig> = new EventEmitter();

    constructor(public modal: NgbActiveModal) {

    }

    ngOnInit(): void {
        this.initialParams = _.cloneDeep(this.params);
        this.params = _.cloneDeep(this.params);
    }

    get forcedPeriod(): Period {
        return this.graphService.getDefaultPeriod(this.params.period, this.dashboardService);
    }

    set forcedPeriod(period: Period) {
        this.params.period = period;
    }

    validate() {
        this.save.emit(this.params);
        this.modal.close();
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }

    isEnabled(period:string): boolean {
        if (period === Period.day.toString()) {
            return this.graphService.isDayAllowedForGraph(this.dashboardService);
        } if (period === Period.week.toString()) {
            return this.graphService.isWeekAllowedForGraph(this.dashboardService);
        }
        return true;
    }
}
