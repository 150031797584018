import {
    Component,
    Input, OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IaConfigResponse } from 'app/api/models/iaconfig';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';

@Component({
    selector: 'aiconfig-edit-modal',
    templateUrl: './aiconfig-edit-modal.component.html',
    styleUrl: './aiconfig-edit-modal.component.scss',
})
/**
 * Popup de modification d'une configration
 */
export default class AiconfigEditModalComponent extends BaseComponent implements OnInit {
    name: string;

    private initialName: string;

    prompt: string;

    private initialPrompt;

    @Input()
    private configuration: IaConfigResponse;

    @Input()
        aiconfigService: AiConfigService;

    isNameDuplicated: boolean;

    constructor(
        public modal: NgbActiveModal,
    ) {
        super();
    }

    ngOnInit(): void {
        this.name = this.configuration.config_name;
        this.initialName = this.name;
        this.prompt = this.configuration.config_configuration;
        this.initialPrompt = this.prompt;
    }

    get isValid() {
        return !this.isNameDuplicated && this.name.trim().length > 0 && this.prompt.trim().length > 0 && (this.nameChanged || this.configChanged);
    }

    get configChanged() {
        return this.prompt !== this.initialPrompt;
    }

    get nameChanged() {
        return this.name !== this.initialName;
    }

    checkNameDuplicated() {
        const allConfigurations = [
            ...this.aiconfigService.configurations.getValue().personalConfigurations,
            ...this.aiconfigService.configurations.getValue().sharedConfigurations,
            ...this.aiconfigService.configurations.getValue().sharedInCorpusConfigurations,
        ];

        this.isNameDuplicated = allConfigurations.some((configuration) => configuration.config_name === this.name);
    }

    close() {
        this.modal.close();
    }

    async save() {
        if (!this.configChanged) {
            await this.aiconfigService.renameConfiguration(this.configuration, this.name);
            this.close();
        } else {
            await this.aiconfigService.createNewConfiguration(this.name, this.prompt);
            this.close();
        }
    }
}
