// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-input {
  height: 1em;
}

.form-select {
  height: 30px;
  padding: 0 30px 0 10px;
}

.number-select .form-check {
  width: 100px;
}

.icon-info {
  font-size: 20px;
}

.icon-info.disabled {
  color: grey;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
