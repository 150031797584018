/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
import {
    Component, ElementRef, Input, OnInit, ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService as DashboardApi } from 'app/api/services';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { Store } from '@ngxs/store';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'annotations-modal',
    templateUrl: './annotations-modal.component.html',
    styleUrls: ['./annotations-modal.component.scss'],
})
export default class AnnotationsModalComponent implements OnInit {
    constructor(
        public modal: NgbActiveModal,
        private translate: TranslateService,
        private dashboardApi: DashboardApi,
        private store: Store,
    ) { }

    @Input()
        verbatims = [];

    @Input()
        currentIndex : number = 1;

    @Input()
        verbatimDbName: string = '';

    @Input()
        dashboardService: DashboardService;

    @Input()
        manageDashboardService: ManageDashboardService;

    @ViewChild('verbatimTextScroll')
        verbatimTextScroll: ElementRef;

    @ViewChild('initiClassesValuesScroll')
        initiClassesValuesScroll: ElementRef;

    @ViewChild('annotationFormScroll')
        annotationFormScroll: ElementRef;

    @ViewChild('modelName')
        modelName: ElementRef;

    showSaveNotif: boolean = false;

    showSavePreviousNotif: boolean = false;

    needVerbatimRefresh: boolean = false;

    /** les valeurs du verbatims chargé pour comparer avec le verbatim midifié, et savoir s'il faut enregistrer */
    pivotBackup: Pivot;

    /**
     * Le pivot d'origine minifié pour afficher que les info utiles dans la cadre gris
     */
    minifiedPivotOrigine: Pivot;

    /**
     * Changement détecté entre le pivot et le pivot sauvegardé. Indique si le pivot contient des modifications onon enregistrées
     */
    changeDetected: boolean = false;

    /**
     * Est-ce qu'il u a un changement entre le pivot initial et le pivot courant dans la zone spécifique au modèle. Permet d'afficher / masquer le bouton pour retourner aux valeurs initiales.
     */
    changeDetectedClass: boolean = false;

    /**
     * Est-ce que le verbatim a des modification d'annotations enregistrées ?
     */
    hasSavedChanges: boolean = false;

    /**
     * Indique si le picot contient une erreur de validation. par exemple avec disserto, une sous classe sélectionnée sans spécifier la tonalité
     */
    isValid = false;

    nothingSelected = false;

    ngOnInit(): void {
        this.buildPivots();
    }

    get modelNameHeight() {
        return this.modelName ? this.modelName.nativeElement.offsetHeight : '55';
    }

    get verbatimText() {
        return this.currentVerbatim[this.verbatimDbName];
    }

    get isFirst() {
        return this.currentIndex <= 0;
    }

    get isLast() {
        return this.currentIndex >= this.verbatims.length - 1;
    }

    get changeDetectedGlobalTone() {
        return this.currentVerbatim.pivot_annotations
        && this.currentVerbatim.pivot_annotations_origine
        && this.currentVerbatim.pivot_annotations.globalTone !== this.currentVerbatim.pivot_annotations_origine.globalTone;
    }

    scrollTop() {
        if (this.annotationFormScroll) {
            this.annotationFormScroll.nativeElement.scrollTop = 0;
        }
        if (this.initiClassesValuesScroll) {
            this.initiClassesValuesScroll.nativeElement.scrollTop = 0;
        }

        if (this.verbatimTextScroll) {
            this.verbatimTextScroll.nativeElement.scrollTop = 0;
        }
    }

    previous() {
        this.showSaveNotif = false;
        this.showSavePreviousNotif = false;
        if (!this.isFirst) {
            this.scrollTop();
            this.currentIndex -= 1;
            this.buildPivots();
        }
    }

    next() {
        this.showSaveNotif = false;
        this.showSavePreviousNotif = false;
        if (!this.isLast) {
            this.scrollTop();
            this.currentIndex += 1;
            this.buildPivots();
        }
    }

    get currentModel() {
        return this.dashboardService.getCurrentModel();
    }

    hasClasses() {
        return this.currentVerbatim.pivot_annotations && this.currentVerbatim.pivot_annotations.classes;
    }

    hasSentiment() {
        return this.currentVerbatim.pivot_annotations && this.currentVerbatim.pivot_annotations.globalTone;
    }

    async save(move: number = 0) {
        // on construit tout d'abord la partie commune à tous les pivot
        const pivot = this.currentVerbatim.pivot_annotations as Pivot;
        const details = { };
        if (pivot.globalTone) {
            details['sentiment'] = pivot.globalTone;
        }

        // on cherche la méthode à utiliser pour enrichir le pivot en fonction du type de modèle.
        if (this.currentModel) {
            const pivotMethodName = `build${_.capitalize(this.modelTypeForAnnotations)}DetailsFromPivot`;
            if (this[pivotMethodName]) {
                this[pivotMethodName](details);
            }
        }

        // on teste d'abord si on sauvegarde des modifications par rapport aux valeur initiales
        if (this.currentVerbatim.pivot_annotations.globalTone === this.currentVerbatim.pivot_annotations_origine.globalTone && !this.changeDetectedClass) {
            // si non on reset l'annotation

            if (this.hasClasses()) {
                await this.dashboardApi.resetAnnotations((this.dashboardService.getCorpusService().currentCorpus.getValue().corp_id), {
                    vcrm_id: this.currentVerbatim._id,
                    verbatim_dbname: this.verbatimDbName,
                    treatment: 'Classification',
                }).toPromise();
            }

            if (this.hasSentiment()) {
                await this.dashboardApi.resetAnnotations((this.dashboardService.getCorpusService().currentCorpus.getValue().corp_id), {
                    vcrm_id: this.currentVerbatim._id,
                    verbatim_dbname: this.verbatimDbName,
                    treatment: 'Svm',
                }).toPromise();
            }
        } else {
            // si oui on sauvegarde l'annotation
            await this.dashboardApi.insertAnnotations(
                this.dashboardService.getCorpusService().currentCorpus.getValue().corp_id,
                {
                    annotations: [{
                        vcrm_id: this.currentVerbatim._id,
                        verbatim_dbname: this.verbatimDbName,
                        details,
                    }],
                },
            ).toPromise();
        }

        this.needVerbatimRefresh = true;

        if (move === 0) {
            this.pivotBackup = _.cloneDeep(this.currentVerbatim.pivot_annotations);
            this.checkChanges();
            this.showSaveNotif = true;
        } else if (move > 0) {
            this.next();
            this.showSavePreviousNotif = true;
        } else if (move < 0) {
            this.previous();
            this.showSavePreviousNotif = true;
        }
    }

    /**
     * Déselection de toutes les classes et sous classes du pivot
     */
    unselectAll() {
        gtmClick({
            track_category: 'exploitation des verbatim',
            track_name: 'annotation tout désélectionner classes',
        });

        (this.currentVerbatim.pivot_annotations as Pivot).classes.forEach((c) => {
            if (c.selected) {
                c.selected = false;
            }

            if (c.subclasses) {
                c.subclasses.forEach((sc) => {
                    if (sc.selected) {
                        sc.selected = false;
                    }
                    if (sc.tone) {
                        sc.tone = 'NONE';
                    }
                });
            }
        });
        this.checkChanges();
    }

    /**
     * Restauration des valeurs initiales des classification automatique
     */
    classInitialValues() {
        gtmClick({
            track_category: 'exploitation des verbatim',
            track_name: 'annotation valeur initiale des classes',
        });

        this.currentVerbatim.pivot_annotations.classes = _.cloneDeep(this.currentVerbatim.pivot_annotations_origine.classes);
        this.checkChanges();
    }

    /**
     * Resturation de la valeur initiale de la tonalité globale
     */
    globalInitialValue() {
        gtmClick({
            track_category: 'exploitation des verbatim',
            track_name: 'annotation valeur initiale de la tonalité',
        });

        this.currentVerbatim.pivot_annotations.globalTone = this.currentVerbatim.pivot_annotations_origine.globalTone;
        this.checkChanges();
    }

    /**
     * Annulation des modifications en cours non sauvegardées
     */
    cancel() {
        this.currentVerbatim.pivot_annotations = _.cloneDeep(this.pivotBackup);
        this.checkChanges();
    }

    close() {
        if (this.needVerbatimRefresh) {
            // rechargement de la liste des verbatim
            this.dashboardService.newFiltersIsApplied.emit({ keepVerbatimPage: true });

            // rechargement des graph
            this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.emit();

            // mise à jour du filtres sentiment
            this.dashboardService.onGetVerbatimColumns();
        }
        this.modal.close();
    }

    get modelTypeForAnnotations() {
        const currentModel = this.dashboardService.getCurrentModel();
        const type = this.store.snapshot().settings.vcrm['model-types-for-annotations'].find((model) => Object.prototype.hasOwnProperty.call(model, currentModel.mode_type.toLowerCase()));

        return type[currentModel.mode_type.toLowerCase()]?.replace('profile-', '').replace('-hierarchic', 'h');
    }

    /**
     * Conversion du verbatim en format pivot indépendant du modèle
     */
    buildPivot(verbatim) {
        // D'abord la partie commune à tous les modèles
        const pivot: Pivot = {};
        if (verbatim.sentiment) {
            pivot.globalTone = this.getSentimentKey(verbatim.sentiment);
        }

        // ensuite on déterme le nom de la méthode à utiliser pour enrichir le format pivot en fonction du nom du modèle
        if (this.currentModel) {
            const pivotMethodName = `buildPivotFrom${_.capitalize(this.modelTypeForAnnotations)}`;
            if (this[pivotMethodName]) {
                this[pivotMethodName](verbatim, pivot);
            }
        }
        return pivot;
    }

    /**
     * Construction du pivot, du pivot des données d'origine, du pivot minifié, et du pivot de comparaison
     */
    buildPivots() {
        if (!this.currentVerbatim.pivot_annotations) {
            this.currentVerbatim.pivot_annotations = this.buildPivot(this.currentVerbatim);
        }

        const annotationsOrigine = {};

        if (this.hasSentiment()) {
            const sentimentOrigine = this.currentVerbatim._annotations_origine && this.currentVerbatim._annotations_origine.sentiment
                ? this.currentVerbatim._annotations_origine.sentiment
                : this.currentVerbatim.sentiment;
            annotationsOrigine['sentiment'] = sentimentOrigine;
        }

        if (this.hasClasses()) {
            const prefix = this.currentModel.mode_prefix === '' ? '' : `${this.currentModel.mode_prefix}.`;
            const classField = `${prefix}fields_classif`;
            const classesOrigine = this.currentVerbatim._annotations_origine && this.currentVerbatim._annotations_origine[classField]
                ? this.currentVerbatim._annotations_origine[classField]
                : this.currentVerbatim[`${prefix}fields_classif`];

            annotationsOrigine[classField] = classesOrigine;
        }

        const pivotAnnotationsOrigine = this.buildPivot(annotationsOrigine);
        if (!this.currentVerbatim.pivot_annotations_origine) {
            this.currentVerbatim.pivot_annotations_origine = pivotAnnotationsOrigine;
        }

        this.pivotBackup = _.cloneDeep(this.currentVerbatim.pivot_annotations);
        this.minifiedPivotOrigine = this.minifyPivot(this.currentVerbatim.pivot_annotations_origine);
        this.checkChanges();
    }

    /**
     * Création du pivot minifié. C'est le pivot d'origine (valeurs initiales) qu'on nettoie de toutes les classes et sous classes non sélectionnée.
     */
    // eslint-disable-next-line class-methods-use-this
    minifyPivot(pivot: Pivot): Pivot {
        const minifiedPivot: Pivot = _.cloneDeep(pivot);

        if (minifiedPivot.classes) {
            minifiedPivot.classes = minifiedPivot.classes.map((c) => {
                if (c.subclasses) {
                    c.subclasses = c.subclasses.filter((sc) => sc.selected && (!sc.tone || sc.tone !== 'NONE'));
                }
                return c;
            }).filter((c) => ((!c.subclasses || c.subclasses.length > 0) && (!this.isSelectable(c) || c.selected)));
        }

        return minifiedPivot;
    }

    // eslint-disable-next-line class-methods-use-this
    isSelectable(c) {
        return Object.prototype.hasOwnProperty.call(c, 'selected');
    }

    get currentVerbatim() {
        return this.verbatims[this.currentIndex];
    }

    /**
     * On compare ici l'état du pivot avec les valeurs initiales et le dernier pivot sauvegardé pour mettre à jour les fla
     */
    checkChanges() {
        this.changeDetected = !_.isEmpty(diff(this.currentVerbatim.pivot_annotations, this.pivotBackup));
        this.changeDetectedClass = !_.isEmpty(diff(this.currentVerbatim.pivot_annotations.classes, this.currentVerbatim.pivot_annotations_origine.classes));

        if (this.changeDetected) {
            this.showSaveNotif = false;
            this.showSavePreviousNotif = false;
        }

        this.hasSavedChanges = !_.isEmpty(diff(this.currentVerbatim.pivot_annotations_origine, this.pivotBackup));

        // on vérifie si le pivot est valide. Le seul cas où il n'est pas valide, c'est quant une tonalité d'une sous classe n'est pas sélectionné (pour les pivot qui le nécessitnte)
        this.isValid = true;
        if (this.hasClasses()) {
            (this.currentVerbatim.pivot_annotations as Pivot).classes.forEach((c) => {
                if (c.subclasses) {
                    c.subclasses.forEach((sc) => {
                        if (sc.selected && sc.tone && sc.tone === 'NONE') {
                            this.isValid = false;
                        }
                    });
                }
            });

            this.nothingSelected = true;

            // on calcule sur le bouton pour tout déselectionner doit être actif
            (this.currentVerbatim.pivot_annotations as Pivot).classes.forEach((c) => {
                if (c.selected) {
                    this.nothingSelected = false;
                }

                if (c.subclasses) {
                    c.subclasses.forEach((sc) => {
                        if (sc.selected) {
                            this.nothingSelected = false;
                        }
                    });
                }
            });
        }
    }

    /**
     * extraction des données du pivot au format générique
     */
    buildGenericDetailsFromPivot(details) {
        const pivot = this.currentVerbatim.pivot_annotations as Pivot;

        const prefix = this.currentModel.mode_prefix === '' ? '' : `${this.currentModel.mode_prefix}.`;

        pivot.classes.forEach((c) => {
            details[prefix + c.inputName] = c.selected ? '1' : '';
        });
    }

    /**
     * * extraction des données du pivot au format disserto
     */
    buildDissertoDetailsFromPivot(details) {
        const pivot = this.currentVerbatim.pivot_annotations as Pivot;

        const prefix = this.currentModel.mode_prefix === '' ? '' : `${this.currentModel.mode_prefix}.`;

        pivot.classes.forEach((c) => {
            const selectedSubClass = c.subclasses.find((sc) => sc.selected);
            if (selectedSubClass) {
                details[prefix + c.inputName] = selectedSubClass.inputName;
                details[`${prefix + c.inputName}_ton`] = [selectedSubClass.tone.toLowerCase()];
            } else {
                details[prefix + c.inputName] = '';
                details[`${prefix + c.inputName}_ton`] = [];
            }
        });
    }

    /**
     * * extraction des données du pivot au format générique hierarchique
     */
    buildGenerichDetailsFromPivot(details) {
        const pivot = this.currentVerbatim.pivot_annotations as Pivot;

        pivot.classes.forEach((c) => {
            c.subclasses.forEach((sc) => {
                details[sc.inputName] = sc.selected ? '1' : '';
            });
        });
    }

    /**
     * Création du pivot à partir des données au format générique
     */
    buildPivotFromGeneric(verbatim, pivot) {
        const prefix = this.currentModel.mode_prefix === '' ? '' : `${this.currentModel.mode_prefix}.`;
        pivot.classes = this.currentModel.cat_fields.map((field) => ({
            inputName: field.inputName,
            displayName: field.displayName,
            selected: verbatim[`${prefix}fields_classif`][prefix + field.inputName] === 'X',
        }));
    }

    /**
     * Création du pivot à partir des données au format générique hierarchique
     */
    buildPivotFromGenerich(verbatim, pivot) {
        const prefix = this.currentModel.mode_prefix === '' ? '' : `${this.currentModel.mode_prefix}.`;
        pivot.oneSubClassPerClass = false;
        pivot.classes = this.currentModel.cat_fields.map((field) => ({
            inputName: field.inputName,
            displayName: field.displayName,
            subclasses: field.subclass.map((subclass) => ({
                inputName: subclass.inputName,
                displayName: subclass.displayName,
                selected: verbatim[`${prefix}fields_classif`][prefix + field.inputName].includes(subclass.inputName),
            } as Subclass)),
        } as Class));
    }

    /**
     * Création du pivot à partir des données au format disserto
     */
    buildPivotFromDisserto(verbatim, pivot) {
        const prefix = this.currentModel.mode_prefix === '' ? '' : `${this.currentModel.mode_prefix}.`;
        pivot.oneSubClassPerClass = true;
        pivot.classes = this.currentModel.cat_fields.map((field) => ({
            inputName: field.inputName,
            displayName: field.displayName,
            subclasses: field.subclass.map((subclass) => {
                const selected = verbatim[`${prefix}fields_classif`][prefix + field.inputName] === subclass.inputName;

                let tone = verbatim[`${prefix}fields_classif`][`${prefix + field.inputName}_ton`];
                if (tone instanceof Array) {
                    tone = 'NONE';
                }
                return ({
                    inputName: subclass.inputName,
                    displayName: subclass.displayName,
                    tone: selected ? tone.toUpperCase() : 'NONE',
                    selected,
                } as Subclass);
            }),
        } as Class));
    }

    getSentimentKey(translated) {
        let result = '';
        ['neg', 'pos', 'mit', 'neu'].forEach((sentiment) => {
            if (this.translate.instant(`translations.corpus.filters.sentiment.${sentiment}`) === translated) {
                result = sentiment.toUpperCase();
            }
        });
        return result;
    }

    /**
     * Méthode appelé lors de la sélection dune sous classe (ou déselection)
     */
    selectSubClass(cs: Class, sc: Subclass) {
        if (this.currentVerbatim.pivot_annotations.oneSubClassPerClass && !sc.selected) {
            // si on est sur un modèle disserto et qu'on coche une sous classe, on décoche d'abord toutes les autres (1 max en théorie)
            cs.subclasses.forEach((osc) => {
                osc.selected = false;
                if (osc.tone) {
                    osc.tone = 'NONE';
                }
            });
            sc.selected = true;
        } else {
            sc.selected = !sc.selected;

            if (sc.tone && !sc.selected) {
                sc.tone = 'NONE';
            }
        }

        this.checkChanges();
    }
}

export interface Pivot {
    globalTone?: string,
    classes?: Array<Class>,
    oneSubClassPerClass?: boolean,
}

export interface Class {
    inputName: string,
    displayName: string,
    selected?: boolean // used only for generic
    subclasses? : Array<Subclass> // used only for disserto and generic hierarchic
}

export interface Subclass {
    inputName: string,
    displayName: string,
    selected?: boolean,
    tone?: string // used only for disserto
}
