/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpRequest, HttpResponse, HttpHeaders,
} from '@angular/common/http';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import BaseService from '../base-service';
import ApiConfiguration from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';

/**
 * Parameters for getV1AclKibanaKibanaIdUserUsername
 */
export interface GetV1AclKibanaKibanaIdUserUsernameParams {

    /**
     * User login
     */
    username: string;

    /**
     * kibana id
     */
    kibanaId: string;
}

/**
 * Access Control List
 */
@Injectable({
    providedIn: 'root',
})
class AclService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
   * Valid access to kibana dashboard for the user
   * @param params The `GetV1AclKibanaKibanaIdUserUsernameParams` containing the following parameters:
   *
   * - `username`: User login
   *
   * - `kibanaId`: kibana id
   */
    getV1AclKibanaKibanaIdUserUsernameResponse(params: GetV1AclKibanaKibanaIdUserUsernameParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/acl/kibana/${params.kibanaId}/user/${params.username}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Valid access to kibana dashboard for the user
   * @param params The `GetV1AclKibanaKibanaIdUserUsernameParams` containing the following parameters:
   *
   * - `username`: User login
   *
   * - `kibanaId`: kibana id
   */
    getV1AclKibanaKibanaIdUserUsername(params: GetV1AclKibanaKibanaIdUserUsernameParams): __Observable<null> {
        return this.getV1AclKibanaKibanaIdUserUsernameResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Valid access to kibana dashboard for the token user
   * @param kibanaId kibana id
   */
    getV1AclKibanaKibanaIdResponse(kibanaId: string): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/acl/kibana/${kibanaId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Valid access to kibana dashboard for the token user
   * @param kibanaId kibana id
   */
    getV1AclKibanaKibanaId(kibanaId: string): __Observable<null> {
        return this.getV1AclKibanaKibanaIdResponse(kibanaId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Valid delete access to kibana dashboard for the token user
   * @param kibanaId kibana id
   */
    deleteV1AclKibanaKibanaIdResponse(kibanaId: string): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/acl/kibana/${kibanaId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Valid delete access to kibana dashboard for the token user
   * @param kibanaId kibana id
   */
    deleteV1AclKibanaKibanaId(kibanaId: string): __Observable<null> {
        return this.deleteV1AclKibanaKibanaIdResponse(kibanaId).pipe(
            __map(({ body }) => body as null),
        );
    }
}

module AclService {
}

export default AclService;
