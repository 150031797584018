// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  margin: 3px;
  top: 36px;
  bottom: 10px;
}

.legend:hover {
  cursor: pointer;
}

.legend-label:hover {
  cursor: pointer;
}

.legend-item {
  display: inline-flex;
  padding-right: 5px;
  padding-left: 2px;
  margin-bottom: 2px;
  max-width: 100%;
}
.legend-item .form-check-input {
  flex: 0 0 auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
