import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-graph-checkbox-legend',
    templateUrl: './graph-checkbox-legend.component.html',
    styleUrls: ['./graph-checkbox-legend.component.scss'],
})
export default class GraphCheckboxLegendComponent {
    @Output()
        change: EventEmitter<number> = new EventEmitter();

    @Input()
        items = [];

    @Input()
        colors : boolean = true;

    @Input()
        dashboardService: DashboardService;

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    constructor(
        private translateService: TranslateService,
        private manageDashboardService: ManageDashboardService,
    ) {}

    toggle(index: number) {
        this.items[index].selected = !this.items[index].selected;
        this.change.emit(index);
    }

    getTimeChartLegend(item): string {
        return (
            `${item.selected
                ? this.translateService.instant('translations.analysisDashboard.tooltips.hideLinePlot')
                : this.translateService.instant('translations.analysisDashboard.tooltips.showLinePlot')
            } ${
                item.displayName}`
        );
    }
}

export interface LegendItem {
    color: string;
    displayName: string;
    selected: boolean;
    inputName: string;
    disabled?: boolean;
}
