import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import { gtmClick } from 'app/shared/directives/gtm.directive';

@Component({
    selector: 'aiconfig-main-content',
    templateUrl: './aiconfig-main-content.component.html',
    styleUrl: './aiconfig-main-content.component.scss',
})
export default class AiconfigMainContentComponent extends BaseComponent implements OnInit {
    filter = '';

    newFolderName = '';

    newFolder: boolean = false;

    hasPerso: boolean;

    hasShared: boolean;

    hasCorpus: boolean;

    selection: boolean = false;

    constructor(private aiconfigService: AiConfigService) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.aiconfigService.configurations.subscribe((configurations) => {
            if (configurations !== null) {
                this.hasPerso = configurations.personalConfigurations.length > 0;
                this.hasShared = configurations.sharedConfigurations.length > 0;
                this.hasCorpus = configurations.sharedInCorpusConfigurations.length > 0;
            }
        });

        this.subs.sink = this.aiconfigService.addConfigurationToFolder.subscribe(() => {
            this.checkSelectionStateChange();
        });

        this.subs.sink = this.aiconfigService.shareConfigurations.subscribe(() => {
            this.checkSelectionStateChange();
        });
    }

    checkSelectionStateChange() {
        if ((this.aiconfigService.addConfigurationToFolder.getValue() || this.aiconfigService.shareConfigurations.getValue()) !== this.selection) {
            this.selection = this.aiconfigService.addConfigurationToFolder.getValue() || this.aiconfigService.shareConfigurations.getValue();
        }
    }

    get searchPlaceHolder() {
        if (this.aiconfigService.addConfigurationToFolder.getValue()) {
            return 'translations.analysisDashboard.aiconfig.search_to_add';
        }
        return 'translations.analysisDashboard.aiconfig.search';
    }

    get selectOnlyOne() {
        return this.aiconfigService.selectOnlyOne;
    }

    // eslint-disable-next-line class-methods-use-this
    onFreeSearch(search) {
        gtmClick({
            track_category: 'gestion configuration IA',
            track_name: 'rechercher configuration IA',
        });

        this.filter = search;
    }

    enterNewFolderMode() {
        this.aiconfigService.selectedFolder.next(null);
        this.newFolder = true;
    }

    enterShareMode() {
        this.aiconfigService.selectedConfigurations.next([]);
        this.aiconfigService.shareConfigurations.next(true);
        this.aiconfigService.collapsed.next([false, true, true]);
    }

    isCollaspsed(index) {
        return this.aiconfigService.collapsed.getValue()[index];
    }

    get folders() {
        return this.aiconfigService.folders.getValue() || [];
    }

    validateNewFolder(newFolderName) {
        this.aiconfigService.createFolder(newFolderName);
        this.newFolder = false;
    }

    get selectedConfigurations() {
        return this.aiconfigService.selectedConfigurations.getValue() || [];
    }

    switchToAddMode(folder) {
        this.aiconfigService.selectedFolder.next(this.folders.find((f) => f.fold_id === folder.fold_id));
        this.aiconfigService.addConfigurationToFolder.next(true);
        this.aiconfigService.originalSelectedConfigurations.next([...this.aiconfigService.selectedFolder.getValue().list_discovery_config]);
        this.aiconfigService.selectedConfigurations.next([...this.aiconfigService.selectedFolder.getValue().list_discovery_config]);
        this.aiconfigService.collapsed.next([false, false, false]);
    }
}
