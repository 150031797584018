import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import RouterTraceService from 'app/utils/services/router-trace.service';
import AuthGuardService from 'app/utils/services/authguard.service';
import UserComponent from './user.component';

const USERROUTES: Routes = [
    {
        path: 'user',
        component: UserComponent,
        canActivate: [RouterTraceService, AuthGuardService],
        data: {
            meta: {
                title: 'VisualCRM profil',
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(USERROUTES)],
    providers: [AuthGuardService, RouterTraceService],
    exports: [RouterModule],
})
export default class UserRoutingModule {}
