export const addCenteredValuesOnHorizontalStackedHistogram = {
    id: 'addCenteredValuesOnHorizontalStackedHistogram',
    afterDatasetsDraw(chart) {
        const { ctx } = chart;

        ctx.textBaseline = 'middle';

        ctx.font = 'bold  12px \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif';

        // affichage des valeurs dans les barres
        // eslint-disable-next-line @typescript-eslint/dot-notation
        chart['_metasets'].forEach((metaset, i) => {
            const dataset = metaset._dataset;
            const meta = chart.getDatasetMeta(i);

            // affichage des valeurs à l'intérieur des barres
            meta.data.forEach((bar, index) => {
                if (bar.options.backgroundColor === '#CD3C14' || bar.options.backgroundColor === '#999999') {
                    ctx.fillStyle = '#FFF';
                } else {
                    ctx.fillStyle = '#000';
                }

                const text = dataset.data[index];
                const textWidth = ctx.measureText(text).width;
                const barWidth = bar.x - bar.base;

                if (textWidth <= barWidth) {
                    ctx.textAlign = 'center';
                    ctx.fillText(text, (bar.x + bar.base) / 2, bar.y);
                }
            });
        });
    },
};
