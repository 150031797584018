import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedModule from 'app/shared/shared.module';
import ErrorsRoutingModule from './errors-routing.module';
import Error404Component from './error404/error404.component';
import Error500Component from './error500/error500.component';
import Error401Component from './error401/error401.component';

@NgModule({
    imports: [SharedModule, CommonModule, ErrorsRoutingModule],
    declarations: [Error404Component, Error500Component, Error401Component],
})
export default class ErrorsModule {}
