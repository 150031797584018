import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import ApiConfiguration from './api-configuration';

import AclService from './services/acl.service';
import UserService from './services/user.service';
import CorpusService from './services/corpus.service';
import DashboardService from './services/dashboard.service';
import FileService from './services/file.service';
import NewsService from './services/news.service';
import ThematicService from './services/thematic.service';
import SettingsService from './services/settings.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
    imports: [
        HttpClientModule,
    ],
    exports: [
        HttpClientModule,
    ],
    declarations: [],
    providers: [
        ApiConfiguration,
        AclService,
        UserService,
        CorpusService,
        DashboardService,
        FileService,
        NewsService,
        ThematicService,
        SettingsService,
    ],
})
export default class ApiModule { }
