import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export default class QueryValidatorService {
    constructor(
        private http: HttpClient,
    ) { }

    /**
   * Vérification de la validité du format de la requête par le backend
   * @param query la requête à vérifier
   * @param corpus un numéro de corpus unique quelconque
   * @returns
   */
    async checkQuery(query: string): Promise<ValidationResult> {
        try {
            // retourne 200 si la query est valide
            await this.http.post('/api/v1/corpus/query/isvalid', { freesearch: query }).toPromise();
            return { isValid: true, errorDetails: '' };
        } catch (error) {
            // ou une erreur http si la query est invalde
            return { isValid: false, errorDetails: error?.error?.details };
        }
    }
}

export interface ValidationResult {
    isValid: boolean;

    errorDetails: string;
}
