import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GetCorpus } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';
import CorpusService from 'app/utils/services/corpus.service';
import { Observable } from 'rxjs';
import { CorpusService as CorpusApi } from '../../../../api/services';

@Component({
    selector: 'corpus-alerts',
    templateUrl: './corpus-alerts.component.html',
    styleUrls: ['./corpus-alerts.component.scss'],
})
export default class CorpusAlertsComponent extends BaseComponent implements OnInit {
    constructor(
        private corpusService: CorpusService,
        private router: Router,
        private store: Store,
        private corpusApi: CorpusApi,
        private translate: TranslateService,
    ) {
        super();
    }

    public showAlertError: boolean = true;

    public mailHref: string;

    private mailTo!: string;

    private settings$: Observable<SettingsModel>;

    ngOnInit(): void {
        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.mailTo = settings.vcrm.mail_contact.mailto;
        });

        this.generateMailLink();
    }

    /**
     * Launch re-analyse on all files
     */
    reAnalysedAll(): void {
        this.corpusApi.getV1CorpusCorpIdAnalyse(this.corpus.corp_id).subscribe(() => {
            // Force reload page to call getFilesInfos on target page
            this.router
                .navigateByUrl(`/project/${this.corpus.corp_id}/analyses`, {
                    skipLocationChange: true,
                })
                .then(() => {
                    this.router.navigate([
                        `/project/${this.corpus.corp_id}/settings/files`,
                    ]);
                });
        });
    }

    get corpus(): GetCorpus {
        return this.corpusService.currentCorpus.getValue();
    }

    /**
     * Return the mailto: link
     */
    generateMailLink(): void {
        this.mailHref = `mailto:${
            this.mailTo
        }?subject=${
            this.translate.instant('translations.utils.help.mail.subject', {
                name: this.store.snapshot().corpus.corp_name,
            })
        }&body=${
            encodeURIComponent(
                this.translate.instant('translations.utils.help.mail.body', { url: `${window.location.origin}/project/${this.corpus.corp_id}/settings/files` }),
            )}`;
    }
}
