import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { ToGraphTypeParams } from 'app/api/models/tographtypeparams';

@Component({
    selector: 'bar-chart-settings',
    templateUrl: './bar-chart-settings-popup.component.html',
    styleUrls: ['./bar-chart-settings-popup.component.scss'],
})
export default class AssociatedDataBarChartSettingsPopupComponent implements OnInit {
    @Input()
        initParams: ToGraphTypeParams;

    @Input()
        graphDefaultValues;

    @Output() newUserChoicesObjectHisto: EventEmitter<ToGraphTypeParams> = new EventEmitter();

    graphParams: ToGraphTypeParams = {};

    initialParams: ToGraphTypeParams = {};

    dbName: string;

    currentVerbatimColumns;

    constructor(public modalRef: NgbActiveModal) {
    }

    ngOnInit(): void {
        if (this.initParams) {
            this.graphParams = _.cloneDeep(this.initParams);
        } else {
            // valeurs par défaut si les paramètres graphiques n'ont pas encore été créés et sauvgardés
            this.graphParams.graphtype = 'histo';
            this.graphParams.yaxis = 'percent';
            this.graphParams.xaxis_sort = 'occur_desc';
            this.graphParams.nb_values_chart = 10;
        }
        this.initialParams = _.cloneDeep(this.graphParams);
    }

    get graphMaxValues() {
        const actualMaxValues = this.currentVerbatimColumns.getValue()[this.dbName].values.length;
        return actualMaxValues < this.graphDefaultValues ? actualMaxValues : this.graphDefaultValues;
    }

    get hasChanged(): boolean {
        const changes = diff(this.graphParams, this.initialParams);
        return !_.isEmpty(changes);
    }

    setNbValues(value:number) {
        this.graphParams.nb_values_chart = value;
    }

    logAnalytics() {
        gtmClick({
            track_category: 'données associées',
            track_name: 'vue histogramme nbre valeurs affichées',
            track_cible: `${this.graphParams.nb_values_chart}`,
        });
    }

    close() {
        this.modalRef.close(false);
    }

    validate() {
        this.newUserChoicesObjectHisto.emit(this.graphParams);
        this.modalRef.close();
    }
}
