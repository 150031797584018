// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-grid-container {
  display: grid;
  grid-template-columns: 600px auto;
  grid-template-rows: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-areas: "title title" "gdpr-infos ." "import-choice ." "import-component .";
  grid-column-gap: 10px;
  background-color: white;
}

.css-import-choice {
  grid-area: import-choice;
  margin-bottom: 15px;
}

.css-import-title {
  grid-area: title;
}

.css-gdpr-infos {
  grid-area: gdpr-infos;
  margin-bottom: 15px;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
