import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GetCorpus } from 'app/api/models';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'app/base.component';
import { CorpusType } from 'app/api/models/get-corpus';
import { Corpuslist } from '../../api/models/corpuslist';
import { CorpusService as CorpusApi } from '../../api/services';
/**
 * Store corpus list form home and select
 */
@Injectable({
    providedIn: 'root',
})
export default class CorpusService extends BaseComponent implements OnDestroy {
    public corpusList = new BehaviorSubject<Corpuslist>(null);

    public currentCorpus: BehaviorSubject<GetCorpus> = new BehaviorSubject<GetCorpus>(null);

    /**
   *Creates an instance of CorpusBehaviorSubject.
   * @param {CorpusService} corpusService
   * @memberof CorpusBehaviorSubject
   */
    constructor(
        private corpusApi: CorpusApi,
        private router: Router,
    ) {
        super();

        this.subs.sink = this.currentCorpus.subscribe((corpus) => {
            if (corpus === null) {
                return;
            }
            // Si aucun des 2 dashboards n'est accessible, on redirige sur la page gestion -> fichier
            if (!corpus.status_flags.isDashboardAnalyseAvailable && !corpus.status_flags.isDashboardToneAvailable) {
                this.router.navigate([`/project/${corpus.corp_id}/settings/files`]);
            }
        });
    }

    ngOnDestroy(): void {
        this.currentCorpus.next(null);
        this.corpusList.next(null);
        this.currentCorpus.unsubscribe();
        this.corpusList.unsubscribe();
        super.ngOnDestroy();
    }

    hasTones(): boolean {
        return (this.currentCorpus.getValue().corp_fields_not_in_file.find((field) => field.inputName === 'sentiment') || null) !== null;
    }

    isCorpusConversation(): boolean {
        return this.currentCorpus.getValue().corp_type === CorpusType.CONVERSATION;
    }

    loadCorpus(id: number) {
        this.subs.sink = this.corpusApi.getV1CorpusCorpId(id).subscribe({
            next: (corpus) => {
                this.currentCorpus.next(corpus);
            },
        });
    }

    loadCorpusListFromBackend() {
        this.corpusApi.getV1Corpus().subscribe((corpusList) => {
            this.corpusList.next(corpusList);
        });
    }

    fav(id) {
        this.corpusApi.postV1CorpusCorpIdFavorite(id).subscribe(() => {
            const allCorpus = [...this.corpusList.getValue().corpusPrivate, ...this.corpusList.getValue().corpusPrivate];
            allCorpus.find((c) => c.corp_id === id).is_favori = true;
            this.corpusList.next(this.corpusList.getValue());
        });
    }

    unFav(id) {
        this.corpusApi.deleteV1CorpusCorpIdFavorite(id).subscribe(() => {
            const allCorpus = [...this.corpusList.getValue().corpusPrivate, ...this.corpusList.getValue().corpusPrivate];
            allCorpus.find((c) => c.corp_id === id).is_favori = false;
            this.corpusList.next(this.corpusList.getValue());
        });
    }
}
