import {
    Component, Input, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { CloudData } from '../word-cloud.interfaces';

@Component({
    selector: 'app-words-table',
    templateUrl: './words-table.component.html',
})
export default class WordsTableComponent implements OnInit {
    @Input()
        words:CloudData[];

    configKeyWord: any = {};

    table: any;

    @ViewChild('numberRowTpl')
        numberRow: TemplateRef<any>;

    ngOnInit(): void {
        this.configKeyWord = {
            style: {
                maxHeight: 'calc(100vh - 350px)',
                borderTop: 'none',
            },
            noHead: true,
        };
        this.table = [
            {
                prop: 'key',
                wordWrap: true,
            },
            {
                prop: 'doc_count',
                style: {
                    width: '100px',
                    'text-align': 'right',
                    'padding-right': '.625rem',
                },
                cellTemplate: this.numberRow,
            },
        ];
    }
}
