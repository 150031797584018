import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';
import { HelpUrls } from 'app/api/models/helpurls';
import { MailContact } from 'app/api/models/mailcontact';
import { UserService } from 'app/api/services';
import UserState from 'app/stores/state/user/user.state';
import { User } from 'app/api/models';
import { Cgu } from 'app/api/models/cgu';
import { BaseComponent } from 'app/base.component';
import { SetUserCGU } from 'app/stores/actions/user/user.actions';
import { TranslateService } from '@ngx-translate/core';
import TermOfUseComponent from './term-of-use/term-of-use.component';
import PrivacyPolicyComponent from './privacy-policy/privacy-policy.component';
import CookiesComponent from './cookies/cookies.component';
import FooterService from './footer.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export default class FooterComponent extends BaseComponent implements OnInit {
    user$: Observable<User>;

    bsModalRef: NgbModalRef;

    version: string;

    cguModalOpened: boolean = false;

    overlay: boolean = false;

    visible: boolean = true;

    @Input()
        canHide: boolean = true;

    private helpUrls: HelpUrls = {
        plazza: '',
    };

    private mailContact: MailContact = {
        mailto: '',
        object: '',
    };

    get isUserLogged() {
        return Object.keys(this.store.snapshot().user).length !== 0;
    }

    get helpUrl() {
        return `/help${this.translate.currentLang === 'en' ? '-en' : ''}/books`;
    }

    settings$: Observable<SettingsModel>;

    constructor(
        private modalService: NgbModal,
        private http: HttpClient,
        private userService: UserService,
        private footerService: FooterService,
        private store: Store,
        private translate: TranslateService,
    ) { super(); }

    private openCguModal(cgu: Cgu) {
        this.cguModalOpened = true;
        this.bsModalRef = this.modalService.open(TermOfUseComponent, {
            backdrop: 'static',
            keyboard: false,
        });

        this.bsModalRef.result.then(() => {
            this.cguModalOpened = false;
        });

        this.bsModalRef.componentInstance.cgu = cgu;

        // eslint-disable-next-line @typescript-eslint/no-shadow
        this.bsModalRef.componentInstance.accepted.subscribe((cgu: Cgu) => {
            cgu.isValid = false;
            this.userService.postV1UserCgu().subscribe((cguResponse) => {
                this.store.dispatch(new SetUserCGU(cguResponse));
                this.bsModalRef.close();
            });
        });
    }

    ngOnInit(): void {
        this.user$ = this.store.select(UserState);
        this.subs.sink = this.user$.subscribe((state: User) => {
            if (state.username && !state.cgu.isValid && !this.cguModalOpened) {
                this.openCguModal(state.cgu);
            }
        });

        /** get the version from version.txt file to display it into the footer */
        this.http.get('./assets/version.txt', { responseType: 'text' }).subscribe((data) => {
            this.version = data;
        });
        /** ** */

        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.helpUrls = settings.vcrm.help_urls || this.helpUrls;
            this.mailContact = settings.vcrm.mail_contact || this.mailContact;
        });

        // eslint-disable-next-line no-underscore-dangle
        this.footerService.overlay_.subscribe((value) => {
            this.overlay = value;
        });

        // eslint-disable-next-line no-underscore-dangle
        this.footerService.visible_.subscribe((value) => {
            this.visible = value || !this.canHide;
        });
    }

    openCookiesModal(event) {
        if (event) {
            event.preventDefault();
        }
        this.bsModalRef = this.modalService.open(CookiesComponent);
    }

    openTermOfUseModal(event) {
        event.preventDefault();
        this.bsModalRef = this.modalService.open(TermOfUseComponent);
    }

    openPrivacyPolicyModal(event) {
        event.preventDefault();
        this.bsModalRef = this.modalService.open(PrivacyPolicyComponent);
    }
}
