import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Inputnamevalues, DashboardType } from 'app/api/models';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';
import FiltersConversationPopupComponent, { CONVERSATION_COLUMNS, CONVERSATION_TURN_COLUMNS } from './components/filters-conversation-popup/filters-conversation-popup.component';

@Component({
    selector: 'app-filters-conversation',
    templateUrl: './filters-conversation.component.html',
    styleUrls: ['./filters-conversation.component.scss'],
})
export default class FiltersConversationComponent extends BaseVerticalFilterComponent implements OnInit {
    constructor(
        private dashboardService: DashboardService,
        private modalService: NgbModal,
        private corpusService: CorpusService,
        protected manageDashboardService: ManageDashboardService,
    ) {
        super(dashboardService);
    }

    columnNumber: number = -1;

    fields = [];

    // eslint-disable-next-line class-methods-use-this
    get conversationColumns() {
        return [...CONVERSATION_COLUMNS, ...CONVERSATION_TURN_COLUMNS];
    }

    get count() {
        return this.filters ? this.filters.filter((filter) => this.conversationColumns.includes(filter.inputName)).reduce((sum, filter) => sum + this.getFilterCount(filter), 0) : 0;
    }

    // eslint-disable-next-line class-methods-use-this
    getFilterCount(filter) {
        return (filter.values.length) + (filter.gte ? 1 : 0) + (filter.lte ? 1 : 0) + (filter.gt ? 1 : 0) + (filter.lt ? 1 : 0);
    }

    ngOnInit() {
        super.ngOnInit();

        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                this.columnNumber = corpus.corp_fields.filter((field) => (this.dashboardService.currentDashboardType === DashboardType.ANALYSE ? field.analyseFilter : field.toneFilter)).length;
            }
        });

        this.subs.sink = this.dashboardService.currentFilters.subscribe((filters) => {
            if (filters !== null) {
                this.fields = [];

                filters.filters.filter((filter) => this.conversationColumns.includes(filter.inputName)).forEach((filter) => {
                    this.fields.push(filter);
                });
            }
        });
    }

    deleteOnComparison(name: string, comparison) {
        const matchingName: Array<Inputnamevalues> = this.filters.filter(
            (filter) => filter.inputName === name,
        );
        matchingName.forEach((filter) => {
            delete filter[comparison];
        });

        this.clearAfterDelete();
    }

    clearAfterDelete() {
        this.filters = this.filters.filter(
            (filter) => filter.values.length > 0 || filter.gte || filter.lte || filter.lt || filter.gt,
        );
        this.dashboardService.updateFilterList(this.filters);
    }

    getFilterDisplayName(inputName) {
        const field = this.corpusService.currentCorpus.getValue().corp_fields_not_in_file.find((f) => f.inputName === inputName);
        return field ? field.displayName : inputName;
    }

    deleteOneValue(name: string, value: string) {
        const matchingName: Array<Inputnamevalues> = this.filters.filter(
            (filter) => filter.inputName === name,
        );
        matchingName.forEach((filter) => {
            filter.values = filter.values.filter(
                (filterValue) => filterValue !== value,
            );
        });
        this.clearAfterDelete();
    }

    deleteAllByName(name: string) {
        this.filters = this.filters.filter(
            (filter) => filter.inputName !== name,
        );
        this.dashboardService.updateFilterList(this.filters);
    }

    openFiltersModal() {
        const modal = this.modalService.open(FiltersConversationPopupComponent);
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.save.subscribe(() => {
            this.collapsed = false;
        });
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'filtre données conversation',
        });
    }
}
