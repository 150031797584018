/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpRequest, HttpResponse, HttpHeaders,
} from '@angular/common/http';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import BaseService from '../base-service';
import ApiConfiguration from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';

import { Thematicslistshare } from '../models/thematicslistshare';
import { ThematicFilters } from '../models/thematic-_filters';
import { ResultId } from '../models/result-id';
import { Thematic } from '../models/thematic';
import { Thematicupdate } from '../models/thematicupdate';

/**
   * Parameters for patchV1ThematicThemId
   */
interface PatchV1ThematicThemIdParams {

    /**
 * thematic id
 */
    themId: number;

    /**
 * The parameters are optional.
 */
    lang?: Thematicupdate;
}

@Injectable({
    providedIn: 'root',
})
class ThematicService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    public lastUsedlanguageForCreation: string;

    /**
   * Sharing the thematic with all users
   * @param themId thematic id
   */
    patchV1ThematicThemIdShareResponse(themId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'PATCH',
            `${this.rootUrl}/v1/thematic/${themId}/share`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Sharing the thematic with all users
   * @param themId thematic id
   */
    patchV1ThematicThemIdShare(themId: number): __Observable<null> {
        return this.patchV1ThematicThemIdShareResponse(themId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Stop sharing the thematic with all users
   * @param themId thematic id
   */
    patchV1ThematicThemIdUnshareResponse(themId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'PATCH',
            `${this.rootUrl}/v1/thematic/${themId}/unshare`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Stop sharing the thematic with all users
   * @param themId thematic id
   */
    patchV1ThematicThemIdUnshare(themId: number): __Observable<null> {
        return this.patchV1ThematicThemIdUnshareResponse(themId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Get thematics (perso and shared) for current user, filtered by lang if it is requested
   * @param lang Language (FR, EN, ...) or empty for all languages
   * @return OK
   */
    postV1ThematicResponse(lang?: ThematicFilters): __Observable<__StrictHttpResponse<Thematicslistshare>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = lang;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/thematic`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Thematicslistshare>),
        );
    }

    /**
   * Get thematics (perso and shared) for current user, filtered by lang if it is requested
   * @param lang Language (FR, EN, ...) or empty for all languages
   * @return OK
   */
    postV1Thematic(lang?: ThematicFilters): __Observable<Thematicslistshare> {
        return this.postV1ThematicResponse(lang).pipe(
            __map(({ body }) => body as Thematicslistshare),
        );
    }

    /**
   * Get thematics (perso and shared) for current user
   */
    postV1ThematicDownloadResponse(corpId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = { corp_id: corpId };
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/thematic/download`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Get thematics (perso and shared) for current user
   */
    postV1ThematicDownload(corpId: number): __Observable<null> {
        return this.postV1ThematicDownloadResponse(corpId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Add this thematic as a favorite for the curent user
   * @param themId thematic id
   */
    postV1ThematicThemIdFavoriteResponse(themId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/thematic/${themId}/favorite`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Add this thematic as a favorite for the curent user
   * @param themId thematic id
   */
    postV1ThematicThemIdFavorite(themId: number): __Observable<null> {
        return this.postV1ThematicThemIdFavoriteResponse(themId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Delete a favorite thematic
   * @param themId thematic id
   */
    deleteV1ThematicThemIdFavoriteResponse(themId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/thematic/${themId}/favorite`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete a favorite thematic
   * @param themId thematic id
   */
    deleteV1ThematicThemIdFavorite(themId: number): __Observable<null> {
        return this.deleteV1ThematicThemIdFavoriteResponse(themId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Create a thematic
   * @param body Name and definition are required.
   * @return OK
   */
    postV1Thematic_1Response(body: Thematic): __Observable<__StrictHttpResponse<ResultId>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/thematic/`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<ResultId>),
        );
    }

    /**
   * Create a thematic
   * @param body Name and definition are required.
   * @return OK
   */
    postV1Thematic_1(body: Thematic): __Observable<ResultId> {
        return this.postV1Thematic_1Response(body).pipe(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            __map(({ body }) => body as ResultId),
        );
    }

    /**
   * Delete a thematic
   * @param themId thematic id
   */
    deleteV1ThematicThemIdResponse(themId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/thematic/${themId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete a thematic
   * @param themId thematic id
   */
    deleteV1ThematicThemId(themId: number): __Observable<null> {
        return this.deleteV1ThematicThemIdResponse(themId).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Update a thematic
   * @param params The `PatchV1ThematicThemIdParams` containing the following parameters:
   *
   * - `themId`: thematic id
   *
   * - `lang`: The parameters are optional.
   */
    patchV1ThematicThemIdResponse(params: PatchV1ThematicThemIdParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.lang;
        const req = new HttpRequest<any>(
            'PATCH',
            `${this.rootUrl}/v1/thematic/${params.themId}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Update a thematic
   * @param params The `PatchV1ThematicThemIdParams` containing the following parameters:
   *
   * - `themId`: thematic id
   *
   * - `lang`: The parameters are optional.
   */
    patchV1ThematicThemId(params: PatchV1ThematicThemIdParams): __Observable<null> {
        return this.patchV1ThematicThemIdResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }
}

module ThematicService {
}

export default ThematicService;
