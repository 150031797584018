// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-link,
.pagination-disabled {
  min-height: 26px;
  min-width: 26px;
  font-size: 14px;
}

.page-item:not(.active) .page-link {
  cursor: pointer;
}

div {
  font-size: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
