// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group {
  border-top: 1px solid #ccc;
}
.list-group .list-group-item {
  border-width: 0 0 1px;
  display: flex;
  cursor: pointer;
}
.list-group .list-group-item .vcrm-star-favourite {
  font-size: 20px;
}
.list-group .list-group-item:hover {
  background-color: #eee;
}
.list-group .list-group-item.is-selected {
  background-color: #ddd;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
