// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
  flex: 1;
  grid-template-rows: auto 1fr;
}
:host ::ng-deep .newproject-content-step {
  padding: 15px;
  background-color: white;
}
:host ::ng-deep .newproject-content-step .title {
  margin-bottom: 25px;
}
:host ::ng-deep .newproject-actions {
  padding: 15px;
  background: #000;
}
:host ::ng-deep .newproject-actions button {
  margin-left: 15px;
}

.slideinout {
  overflow: auto;
  position: relative;
}

#breadcrumb {
  padding: 10px 15px;
}
#breadcrumb .stepbar-item {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
