// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: #fff;
  padding-left: 15px;
  display: block;
}
:host .nav-link {
  font-size: 14px;
}
:host ::ng-deep .badge {
  position: absolute;
  right: -8px;
  top: 6px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
