// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-grid-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-areas: "title title" "project-users ." "project-admin .";
  background-color: white;
}

.css-project-title {
  grid-area: title;
}

.css-project-users {
  grid-area: project-users;
}

.css-project-admin {
  margin-top: 15px;
  grid-area: project-admin;
}

.css-format-padding {
  padding-top: 5px;
  padding-left: 30px;
}
.css-format-padding li {
  padding-left: 10px;
}
.css-format-padding .css-form-button-padding {
  margin: 10px;
  padding-left: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
