import {
    Component, ElementRef, Input, OnInit,
} from '@angular/core';

import { Store } from '@ngxs/store';

import { DashboardService as DashboardApi } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';

import * as _ from 'lodash';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { BaseComponent } from 'app/base.component';

import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import DashboardTabs from 'app/utils/models/dashboard-tabs';
import { Period } from 'app/api/models/distriparams';
import { AssociatedDataDisplay, ComparisonMode } from 'app/api/models/dashboard-config';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Observable } from 'rxjs';
import { MergedAssociatedData } from 'app/api/models/associateddata';

@Component({
    selector: 'app-associated-datas',
    templateUrl: './associated-datas.component.html',
})
export default class AssociatedDatasComponent extends BaseComponent implements OnInit {
    bsModalRef: NgbModalRef;

    settings$: Observable<SettingsModel>;

    // context variables

    associatedDataResponse; // contains assocated data backend response

    @Input()
        dashboardService: DashboardService;

    comparisonMode;

    config;

    userChoices;

    hasData;

    currentDisplay;

    isMerged: boolean;

    get isLoaded() {
        return !this.elementRef.nativeElement.classList.contains('is-loading');
    }

    constructor(
        private dashboardApi: DashboardApi,
        public graphService: GraphService,
        protected manageDashboardService: ManageDashboardService,
        private elementRef: ElementRef,
        private store: Store,
    ) {
        super();
    }

    ngOnInit() {
        this.settings$ = this.store.select(SettingsState);

        // Ecoute la demande de chargement asynchrone
        this.subs.sink = this.manageDashboardService.lazyLoadRequestEvent.subscribe(async (elementId) => {
            if (elementId === DashboardTabs.ASSOCIATED_DATA && this.elementRef.nativeElement.classList.contains('is-loading')) {
                this.checkAndLoadConfigAndData();
            }
        });

        // Lorsque l'on change de mode d'affichage (pour la comparaison mode Cote à Cote ou Fusionné)
        this.subs.sink = this.manageDashboardService.comparisonSwitchMode.subscribe(({ section }) => {
            // Si la modification d'affichage correspond à la section de ce composant
            if (section === 'associatedData') {
                this.checkAndLoadConfigAndData();
            }
        });

        // Lorsque les configurations des données associées changent
        this.subs.sink = this.manageDashboardService.associatedDataSettingsChanged.subscribe(() => {
            this.updateConfig();
        });
    }

    // Met à jour les configs, les userchoices, ... en fonction du ou des services
    updateConfig() {
        this.isMerged = !this.dashboardService;
        this.comparisonMode = this.dashboardService ? ComparisonMode.side_by_side : ComparisonMode.merged;
        this.config = this.dashboardService ? this.dashboardService.currentConfig.getValue() : this.manageDashboardService.currentDashboardComparisonConfig.getValue();
        this.userChoices = this.config?.userChoices;
        this.currentDisplay = this.config?.associated_data_display;

        this.hasData = this.dashboardService
            ? this.dashboardService.dashboardData.getValue()?.hits.total !== 0
            : this.manageDashboardService.dashboardServices.some((dashboardService) => dashboardService.dashboardData.getValue()?.hits.total !== 0);
    }

    async checkAndLoadConfigAndData() {
        this.updateConfig();
        const comparisonConfig = this.manageDashboardService.currentDashboardComparisonConfig.getValue();

        if (!comparisonConfig || comparisonConfig.comparison.associatedData === this.comparisonMode) {
            this.verifyAndUpdateUserChoice();
            this.associatedDataResponse = await AssociatedDatasComponent.callApiToGetAllGraphicalDatas(
                this.manageDashboardService,
                this.dashboardService,
                this.dashboardApi,
                this.userChoices,
            );
            this.elementRef.nativeElement.classList.remove('is-loading');
        }
    }

    /**
     * Vérifie les configurations de l'objet userChoice et les met à jour si besoin
     */
    verifyAndUpdateUserChoice() {
        this.userChoices.forEach((choice) => {
            if (choice.curve) {
                // Force le changement des périodes des graphiques temporelles selon la sélection des filtres.
                choice.curve_params.period = this.graphService.getDefaultPeriod(choice.curve_params.period as Period, this.dashboardService);
            }
        });

        if (this.isMerged) {
            this.manageDashboardService.currentDashboardComparison.getValue().dash_json_config.userChoices = this.userChoices;
            this.manageDashboardService.setComparisonDashbardConfig('associatedData', this.userChoices);
        } else {
            this.dashboardService.currentDashboard.getValue().dash_json_config.userChoices = this.userChoices;
            this.dashboardService.setUserChoices(this.userChoices);
        }
    }

    /**
     * Method to call the new TOgraph API in order to retrieve all graphical data to draw into graph Objects
     */
    static async callApiToGetAllGraphicalDatas(
        manageDashboardService: ManageDashboardService,
        dashboardService: DashboardService,
        dashboardApi: DashboardApi,
        userChoices,
    ) : Promise<MergedAssociatedData> {
        // Construit l'objet à envoyer au back-end selon la config contenu dans userChoices
        const outputColumns = userChoices.map((item) => _.omit(item, ['bar', 'curve'])).reduce((acc, item) => {
            acc.push({ column: item.db_name, graphtypes: [item.bar_params, item.curve_params].filter((e) => e) });
            return acc;
        }, []);

        const childrenFilters = dashboardService
            ? [_.cloneDeep(dashboardService.lastAppliedFilters.getValue())]
            : manageDashboardService.dashboardServices.map((service) => _.cloneDeep(service.lastAppliedFilters.getValue()));
        const firstDashboard = manageDashboardService.dashboardServices[0].currentDashboard.getValue();

        return firstValueFrom(dashboardApi.getMergedAssociatedData(
            firstDashboard.dash_id,
            'week',
            {
                searchparams: childrenFilters,
                output_columns: outputColumns,
            },
        ));
    }

    async updateGraph(value) {
        const userChoices = {
            db_name: value.dbName,
        };

        ['curve_params', 'bar_params'].forEach((params) => {
            if (value[params]) {
                userChoices[params] = value[params];
            }
        });

        const data = await AssociatedDatasComponent.callApiToGetAllGraphicalDatas(
            this.manageDashboardService,
            this.dashboardService,
            this.dashboardApi,
            [userChoices],
        );
        this.associatedDataResponse.associated_data[value.dbName] = { ...this.associatedDataResponse.associated_data[value.dbName], ...data.associated_data[value.dbName] };
    }

    isVisible(choice, type) {
        if (type === 'bar') {
            return choice.bar && [AssociatedDataDisplay.histo, AssociatedDataDisplay.both].includes(this.currentDisplay);
        } if (type === 'curve') {
            return choice.curve && [AssociatedDataDisplay.distri, AssociatedDataDisplay.both].includes(this.currentDisplay);
        }
        return false;
    }
}
