/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { HttpClient, HttpParameterCodec, HttpParams } from '@angular/common/http';
import ApiConfiguration from './api-configuration';

/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
        return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
        return decodeURIComponent(value);
    }
}
const PARAMETER_CODEC = new ParameterCodec();

/**
 * Base class for API services
 */
export default class BaseService {
    constructor(
        protected config: ApiConfiguration,
        protected http: HttpClient,
    ) {
    }

    private rootUrlInternal: string = '';

    /**
   * Returns the root url for API operations. If not set directly in this
   * service, will fallback to ApiConfiguration.rootUrl.
   */
    get rootUrl(): string {
        return this.rootUrlInternal || this.config.rootUrl;
    }

    /**
   * Sets the root URL for API operations in this service.
   */
    set rootUrl(rootUrl: string) {
        this.rootUrlInternal = rootUrl;
    }

    /**
   * Creates a new `HttpParams` with the correct codec
   */
    protected newParams(): HttpParams {
        return new HttpParams({
            encoder: PARAMETER_CODEC,
        });
    }
}
