import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'app/base.component';
import CorpusService from 'app/utils/services/corpus.service';

@Component({
    selector: 'app-dashboard-menu-bar',
    templateUrl: './dashboard-menu-bar.component.html',
    styleUrls: ['./dashboard-menu-bar.component.scss'],
})
export default class DashboardMenuBarComponent extends BaseComponent implements OnInit {
    @Input()
        hasSettings: boolean = true;

    hasTones:boolean = false;

    constructor(
        private corpusService: CorpusService,
    ) {
        super();
    }

    ngOnInit() {
        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            this.hasTones = corpus.corp_is_tone * 1 !== 0;
        });
    }
}
