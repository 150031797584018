/* eslint-disable class-methods-use-this */
import {
    Component, ElementRef, Input, Renderer2, EventEmitter, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef, OnInit,
} from '@angular/core';
import { ResultCounts, CloudData } from '../word-cloud.interfaces';

@Component({
    selector: 'app-words-cloud',
    templateUrl: './words-cloud.component.html',
    styleUrls: ['./words-cloud.component.scss'],
})
export default class WordsCloudComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input()
        displayedWords: CloudData[];

    @Output()
        wordSelected: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @Output()
        ready: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
        hide: EventEmitter<CloudData> = new EventEmitter<CloudData>();

    @ViewChild('wordContainer')
        wordContainer: ElementRef;

    @Input()
        showHorizontalAxe: boolean = false;

    @Input()
        counts: ResultCounts;

    @Input()
        category: string;

    @Input()
        view :string;

    @Input()
        clickable = true;

    hovered: CloudData = null;

    @Input()
        useDeltaTone: boolean = false;

    @Input()
        canHide = true;

    private horizontalScale: number;

    private horizontalScaled: boolean;

    private containerWidth: number = 0;

    private containerHeight: number = 0;

    // @ts-ignore
    private resizeObserver: ResizeObserver;

    colors = ['#F16E00', '#CD3C14', '#492191', '#527EDB', '#0A6E31', '#000000', '#666666'];

    gradient = [
        [205, 60, 20, 0],
        [241, 110, 0, 0.5],
        [255, 180, 0, 0.6],
        [102, 102, 102, 0.7],
        [50, 200, 50, 1],
    ];

    private MIN_DELTA_TONE: number = -100;

    private MAX_DELTA_TONE: number = 100;

    private WORD_MIN_SPACING: number = 5;

    private horizontalDeltaToneScale: number;

    private minWordDeltaTone: number = this.MIN_DELTA_TONE;

    private maxWordDeltaTone: number = this.MAX_DELTA_TONE;

    constructor(private r2: Renderer2, private elementRef: ElementRef, private changeDetector: ChangeDetectorRef) {

    }

    ngOnInit(): void {
    // @ts-ignore
        this.resizeObserver = new ResizeObserver(() => {
            this.redraw();
            this.changeDetector.detectChanges();
        });

        this.resizeObserver.observe(this.elementRef.nativeElement.querySelector('.word-container'));
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.wordContainer.nativeElement);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.resize();
        }, 0);
    }

    resize() {
        if (this.containerHeight !== this.wordContainer.nativeElement.offsetHeight || this.containerWidth !== this.wordContainer.nativeElement.offsetWidth) {
            this.redraw();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.displayedWords) {
            this.redraw();
        }
    }

    redraw() {
        if (this.displayedWords && this.displayedWords.length > 0 && this.wordContainer) {
            this.containerHeight = this.wordContainer.nativeElement.offsetHeight;
            this.containerWidth = this.wordContainer.nativeElement.offsetWidth;

            this.ready.emit(false);
            this.createDeltaToneIfNeeded();
            this.searchScale();

            this.horizontalScale = 1;
            this.horizontalScaled = false;

            this.searchPositions();
            this.searchColor();
            this.ready.emit(true);
        }
    }

    decimalToHex(decimal, padding): string {
        let hex = Number(decimal).toString(16);

        while (hex.length < padding) {
            hex = `0${hex}`;
        }

        return hex;
    }

    /**
   * Calcul de la propriété css gradient pour la frise
   */
    get cssGradient(): string {
        let result = '';

        this.gradient.forEach((color: number[], index) => {
            result
        += `#${
                    this.decimalToHex(color[0], 2)
                }${this.decimalToHex(color[1], 2)
                }${this.decimalToHex(color[2], 2)
                } ${
                    Math.floor(color[3] * 100)
                }%`;
            if (index < this.gradient.length - 1) {
                result += ',';
            }
        });
        return result.toUpperCase();
    }

    private gradientColor(value) {
        let color1; let color2; let
            value2;
        for (let i = 0; i < this.gradient.length; i += 1) {
            if (value >= this.gradient[i][3] && value <= this.gradient[i + 1][3]) {
                color2 = this.gradient[i];
                color1 = this.gradient[i + 1];
                value2 = (value - this.gradient[i][3]) / (this.gradient[i + 1][3] - this.gradient[i][3]);
                break;
            }
        }

        const rgb = [Math.round(color1[0] * value2 + color2[0] * (1 - value2)),
            Math.round(color1[1] * value2 + color2[1] * (1 - value2)),
            Math.round(color1[2] * value2 + color2[2] * (1 - value2))];
        return rgb;
    }

    private searchColor() {
        this.displayedWords.forEach((word, index) => {
            if (!this.useDeltaTone) {
                word.color = this.colors[index % this.colors.length];
            } else {
                const deltaToneGradientPercent = (word.deltatone - this.MIN_DELTA_TONE) / (this.MAX_DELTA_TONE - this.MIN_DELTA_TONE);
                const rgbValue = this.gradientColor(deltaToneGradientPercent);
                word.color = `rgb(${rgbValue.join()})`;
            }
        });
    }

    hash(value: string): number {
        let result = 0; let
            chr;
        for (let i = 0; i < value.length; i += 1) {
            chr = value.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            result = ((result << 5) - result) + chr;
            // eslint-disable-next-line no-bitwise
            result |= 0; // Convert to 32bit integer
        }
        return result;
    }

    modulo(value: number, modulo: number): number {
        return ((value % modulo) + modulo) % modulo;
    }

    /**
   * S'il n'y a aucun delta tone, on génère les delta tone aléatoirement
   */
    createDeltaToneIfNeeded() {
        if (!this.useDeltaTone) {
            this.displayedWords.forEach((word) => {
                word.deltatone = this.modulo(this.hash(word.key), this.MAX_DELTA_TONE - this.MIN_DELTA_TONE) + this.MIN_DELTA_TONE;
            });
        }
    }

    private searchScale() {
        if (!this.showHorizontalAxe) {
            this.maxWordDeltaTone = -100;
            this.minWordDeltaTone = 100;
            this.displayedWords.forEach((word) => {
                this.minWordDeltaTone = this.minWordDeltaTone < word.deltatone ? this.minWordDeltaTone : word.deltatone;
                this.maxWordDeltaTone = this.maxWordDeltaTone > word.deltatone ? this.maxWordDeltaTone : word.deltatone;
            });
        }

        delete this.horizontalDeltaToneScale;
        this.horizontalDeltaToneScale = this.maxWordDeltaTone === this.minWordDeltaTone ? 1 : (this.containerWidth) / (this.maxWordDeltaTone - this.minWordDeltaTone);
    }

    /**
   *
   * @param verticalScale la mise à l'échelle verticale liée à la hauteur du nuage
   * @param scaled
   */
    private computeWordSize(verticalScale) {
        let maxWordWidth;
        let text;

        const sizer = this.r2.createElement('span');
        this.r2.addClass(sizer, 'sizer');
        this.r2.appendChild(document.body, sizer);
        this.displayedWords.forEach((word) => {
            if (text) {
                this.r2.removeChild(sizer.nativeElement, text);
            }
            text = this.r2.createText(word.key);
            this.r2.appendChild(sizer, text);

            // get calculated word weight
            const weight: number = this.getWeightForWord(word);
            word.fontSize = (10 + 15 * weight) * verticalScale * this.horizontalScale;
            this.r2.setStyle(sizer, 'font-size', `${word.fontSize}px`);

            word.width = sizer.offsetWidth;
            word.height = sizer.offsetHeight;

            maxWordWidth = !maxWordWidth || maxWordWidth < word.width ? word.width : maxWordWidth;
        });
        this.r2.removeChild(document.body, sizer);

        // si le mot le plus grand dépasse la largeur du composant, on recalcule avec un scale supérieur
        if (!this.horizontalScaled && maxWordWidth > this.containerWidth) {
            this.horizontalScale = this.containerWidth / maxWordWidth;
            this.horizontalScaled = true;
            this.computeWordSize(verticalScale);
        }
    }

    /**
   *
   * @param intervals Calcul l'union des intervalles
   */
    private union(intervals: Interval[]): Interval[] {
        const union: Interval[] = [];

        // on tri par hauteurs croissantes
        intervals.sort((interval1, interval2) => interval1.top - interval2.top);

        for (let i = 0; i < intervals.length; i += 1) {
            // le dernier élément est toujours ajouté à l'union, car on fusionne les itervalles sur le deuxième élément
            if (i === intervals.length - 1) {
                union.push(intervals[i]);
            } else if (intervals[i].bottom >= intervals[i + 1].top) {
                // on regarde si l'intervalle courant et l'intervalle suivant se chevauchent
                // si c'est le cas, on fusionne sur le deuxième intervalle, et on n'ajoute pas l'intervalle courant à l'union
                intervals[i + 1].top = intervals[i].top;
                intervals[i + 1].bottom = intervals[i + 1].bottom > intervals[i].bottom ? intervals[i + 1].bottom : intervals[i].bottom;
            } else {
                union.push(intervals[i]);
            }
        }

        return union;
    }

    /**
   * Calcul de la position du mot dans le nuage
   * @param word
   * @param index
   */
    private findWordPosition(word: CloudData, index: number) {
    // la position horizontale est imposée par le delta tone
        // par défaut on positionne le centre du mot à la position du delta tone

        // si tous les mots on le même delta tone, alors on le centre sur l'axe horizontal
        if (this.minWordDeltaTone === this.maxWordDeltaTone) {
            word.left = this.containerWidth / 2 - word.width / 2;
        } else {
            word.left = (word.deltatone - this.minWordDeltaTone) * this.horizontalDeltaToneScale - word.width / 2;
        }

        // mais le mot peut dépasser du composant alors
        // on corrige la position du mot pour le caler à droite ou à gauche si c'est le cas
        if (word.left < 0) {
            word.left = 0;
        } else if (word.left + word.width > this.containerWidth) {
            word.left = this.containerWidth - word.width;
        }

        // on calcule la position du deltatone qui sera utilisée pour afficher la ligne verticale sur la vue étendue.
        // Elle correspond généralement au centre du mot sauf dans le cas où le mot dépassait des limites du container
        word.deltaTonePosition = (word.deltatone - this.minWordDeltaTone) * this.horizontalDeltaToneScale;

        // on initialise la position verticale du mot au centre du composant
        word.top = this.containerHeight / 2 - word.height / 2;
        word.right = word.left + word.width;
        word.bottom = word.top + word.height;

        // on calcule l'union des intervalles, c'est à dire qu'on fusionne les intervalles occupés qui se chevauchent
        let occupiedIntervals: Interval[] = [];

        // on fait la liste des mots qui se superposent verticalement avec le mot courant
        for (let otherIndex = 0; otherIndex < index; otherIndex += 1) {
            const otherWord = this.displayedWords[otherIndex];

            // si les div sont adjacentes horizontalement, on ajoute un intervalle occupé
            if (this.areWordOverlappingHorizontally(word, otherWord)) {
                occupiedIntervals.push({ top: otherWord.top, bottom: otherWord.bottom });
            }
        }

        occupiedIntervals = this.union(occupiedIntervals);

        // ensuite on va chercher une espace libre entre ces mots dans lequel le mot courant contient.
        // on cherche le premier espace au dessus de la ligne médiane et le premier en dessous de la ligne médiane
        let maxBottomPosition = word.bottom;
        let minTopPosition = word.top;
        if (occupiedIntervals.length >= 1) {
            minTopPosition = occupiedIntervals[occupiedIntervals.length - 1].bottom;
            maxBottomPosition = occupiedIntervals[0].top;

            for (let i = 0; i < occupiedIntervals.length - 1; i += 1) {
                if (occupiedIntervals[i + 1].top - occupiedIntervals[i].bottom > word.height) {
                    // il y a assez de place ici pour mettre le mot

                    if (occupiedIntervals[i + 1].top > this.containerHeight / 2) {
                        // si on est au dessus du milieu, on essaye de coller au mot en dessous, sauf si on a déjà une autre position plus proche du milieu
                        const possibleMaxBottomPosition = occupiedIntervals[i + 1].top;
                        maxBottomPosition = maxBottomPosition > possibleMaxBottomPosition ? maxBottomPosition : possibleMaxBottomPosition;
                    } else {
                        // si on est en dessous du milieu, on essaye de coller au mot en dessous
                        const possibleMinTopPosition = occupiedIntervals[i].bottom;
                        minTopPosition = minTopPosition < possibleMinTopPosition ? minTopPosition : possibleMinTopPosition;
                    }
                }
            }

            // enfin entre la position au dessus et au dessous du milieu, on choisit celle la plus proche du milieu
            if (this.containerHeight / 2 - maxBottomPosition < minTopPosition - this.containerHeight / 2) {
                // on sélectionne la possibilité en dessous du milieu
                word.bottom = maxBottomPosition;
                word.top = maxBottomPosition - word.height;
            } else {
                // on sélectionne la possibilité au dessus du milieu
                word.top = minTopPosition;
                word.bottom = minTopPosition + word.height;
            }
        }

        word.fromBottom = this.containerHeight - word.bottom;
    }

    private searchPositions(scaled: boolean = false, verticalScale: number = 1) {
    // le mot avec la position la plus haute, et celui avec la position la plus basse
        let topWord; let
            bottomWord;

        // calcul de la taille de chaque mot
        this.computeWordSize(verticalScale);

        // calcul de la position des mots
        this.displayedWords.forEach((word, index) => {
            // recherche de la position verticale du mot, la position horizontale est imposée par le delta tone
            this.findWordPosition(word, index);

            // on met à jour le mot le plus haut et le mot le plus bas
            topWord = !topWord || word.top < topWord.top ? word : topWord;
            bottomWord = !bottomWord || word.bottom > bottomWord.bottom ? word : bottomWord;
        });

        // hauteur du container
        const componentHeight = this.containerHeight;
        // hauteur du nuage de mot -> écart entre le haut du mot le plus haut et le bas du mot le plus bas
        const cloudHeight = bottomWord.bottom - topWord.top;

        // si le composant n'a pas encore été mis à l'échelle, et que la hauteur du nuage est plus grande que celle du composant, on va faire un scale down sur la taille des mot
        if (!scaled && cloudHeight > componentHeight) {
            // on ajuste l'échelle du nuage de mot et on recalcule tout pour ajuster à la hauteur du composant
            // le pourcentage de réduction est le rapport entre la taille du composant et la taille du nuage
            this.searchPositions(true, componentHeight / cloudHeight);
            return;
        }

        let verticalAdjustement = 0;
        let verticalpositionScale = 1;

        // s'il y a peu de mot on ne ventile pas, on se contente de centrer
        if (this.displayedWords.length > 5) {
            // ventilation des mots verticalement pour prendre toute la hauteur du composant
            // on commence à décaler le nuage pour le caler en haut du container
            verticalAdjustement = -topWord.top;

            verticalpositionScale = 1;

            if (topWord.top !== bottomWord.top) {
                // on décale les mots proprtionnellement pour que le mot le plus bas soit calé en bas du container
                verticalpositionScale = (componentHeight - bottomWord.height) / (bottomWord.top + verticalAdjustement);
            }

            const newBottomWordbottom = (bottomWord.top + verticalAdjustement) * verticalpositionScale + bottomWord.height;
            verticalAdjustement += (this.containerHeight - newBottomWordbottom) / 2;
        } else {
            // centrage
            verticalAdjustement = (this.containerHeight - bottomWord.bottom - topWord.top) / 2;
        }

        this.displayedWords.forEach((word) => {
            word.top = (verticalAdjustement + word.top) * verticalpositionScale;
            word.bottom = word.top + word.height;
            word.fromBottom = componentHeight - word.bottom;
        });
    }

    /**
   * Check if min(weight) > max(weight) otherwise use default
   * @param word the particular word configuration
   */
    private getWeightForWord(word: CloudData): number {
        let weight = 5;
        if (this.displayedWords[0].doc_count > this.displayedWords[this.displayedWords.length - 1].doc_count) {
            // Linearly map the original weight to a discrete scale from 1 to 10
            weight = Math.round(
                ((word.doc_count - this.displayedWords[this.displayedWords.length - 1].doc_count)
            / (this.displayedWords[0].doc_count
              - this.displayedWords[this.displayedWords.length - 1].doc_count))
          * 9.0,
            ) + 1;
        }
        return weight;
    }

    private areWordOverlappingHorizontally(word1: CloudData, word2: CloudData): boolean {
        return !(
            word1.right < word2.left - this.WORD_MIN_SPACING
      || word1.left > word2.right + this.WORD_MIN_SPACING
        );
    }

    over(word: CloudData) {
        this.hovered = word;
    }

    out(word: CloudData) {
        if (this.hovered && this.hovered.key === word.key) {
            this.hovered = null;
        }
    }

    hideWord(hidden: CloudData) {
        this.hide.emit(hidden);
        this.hovered = null;
    }
}

interface Interval {
    top: number;

    bottom: number;
}
