import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'app/api/services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import ExportType from 'app/modules/CommonExportTypeEnum';
import download from 'app/utils/functions/download';

@Component({
    selector: 'app-verbatim-export',
    templateUrl: './verbatim-export.component.html',
    styleUrls: ['./verbatim-export.component.scss'],
})
export default class VerbatimExportComponent implements OnInit {
    @Input()
        title: string;

    @Input()
        modal: BsModalRef;

    modal2: BsModalRef;

    @Input()
        dataReq: any;

    @Input()
        dash_id: number;

    verbatimsPos: string[] = [];

    verbatimsNeg: string[] = [];

    private exportType = ExportType;

    private selectedType: ExportType;

    constructor(
        private dashboard: DashboardService,
        private modalService: BsModalService,
        private http: HttpClient,
    ) {}

    ngOnInit() {
        this.dashboard
            .postV1DashboardDashIdToneVerbatim(this.dataReq)
            .subscribe((verbatims) => {
                this.verbatimsPos = verbatims.pos;
                this.verbatimsNeg = verbatims.neg;
            });
    }

    confirm(m) {
        this.modal2 = this.modalService.show(m, { backdrop: 'static' });
    }

    downloadVerbatim() {
        const req = JSON.parse(JSON.stringify(this.dataReq));
        req.body.count = 10000;
        this.http
            .post(
                `${this.dashboard.rootUrl}/v1/dashboard/${req.dashId}/tone/download/format/${this.selectedType}`,
                req.body,
                { responseType: 'blob', observe: 'response' },
            )
            .subscribe((resp) => {
                const filename = resp.headers.get('content-disposition').split('=')[1];
                download(window.URL.createObjectURL(resp.body), filename);
            });
    }
}
