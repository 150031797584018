/* eslint-disable no-underscore-dangle */
// Ajouts suite à l'inversion entre l'étape message et date, pour réaliser le tableau
import { HttpClient } from '@angular/common/http';
import {
    Component, OnInit, ViewChild, TemplateRef, ElementRef,
} from '@angular/core';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { FileService } from 'app/api/services';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import CorpusTableSelectComponent from 'app/shared/components/corpus-table-select/corpus-table-select.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { Files } from '../../../api/models/files';
import {
    SetEtapes,
    SetColDate,

    SetFileColsTypes, SetFileFirstLines,
} from '../../../stores/actions/new-corpus/new-corpus.actions';
import BaseEtapeComponent from '../BaseEtapeComponent';

/**
 * Select date
 *
 * @export
 * @class Etape3dateComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape3date',
    templateUrl: './etape3date.component.html',
    styleUrls: ['./etape3date.component.scss'],
    animations: [slideInOutAnimation],
})
export default class Etape3dateComponent extends BaseEtapeComponent implements OnInit {
    columns: Array<Object> = [];

    rows: any = [];

    next = false;

    hoverCol: number;

    selectDateCol: number;

    dateInit: Date = null;

    noColDate: boolean;

    formatList: Array<any>;

    modalRef: NgbModalRef;

    selectedFormat: string;

    storeDatas: any;

    selectedVerbatimCol: Array<number> = [];

    datePickerDisplayed: boolean = false;

    formatLoaded: boolean = false;

    colsThatAreDate: Array<number> = [];

    tooltipContent: string = '';

    @ViewChild('template')
    private defaultTabButtonsTpl: TemplateRef<any>;

    @ViewChild(CorpusTableSelectComponent)
    private table: CorpusTableSelectComponent;

    @ViewChild('dateInput')
    private dateInput: ElementRef;

    protected maxSelectableDate = moment().toDate();

    private msurveyDateColumnName = 'StartDate';

    constructor(
        private http: HttpClient,
        private store: Store,
        private fileService: FileService,
        private modalService: NgbModal,
        private router: Router,
        private localeService: BsLocaleService,
        private translate: TranslateService,
    ) {
        super();
        this.next = false;

        const state = this.store.snapshot().newcorpus;
        if (this.isEtape1Invalid(state)) {
            this.router.navigate(['new-project']);
        } else if (this.isEtape2Invalid(state)) {
            this.router.navigate(['new-project', 'upload']);
        } else {
            this.storeDatas = state.datas;

            if (state.datas && state.datas.file?.type === 1 || state.datas.file?.type === 4) {
                if (this.noColDate === undefined) {
                    // Au chargement on envoi un event GA car on sélectionne par défaut, le radio "Utiliser ..."
                    this.noColDate = false;
                    gtmClick({
                        track_category: 'nouveau projet',
                        track_name: 'choix de la date',
                        track_cible: 'utiliser une colonne date',
                    });
                }

                if (!this.dateInit) {
                    this.dateInit = new Date();
                } else {
                    this.dateInit = moment(this.dateInit).toDate();
                }
            }
        }
    }

    loadMsurveyDateFormat() {
        this.fileService
            .getV1FileFilenameFormatFieldnameResponse({ filename: this.storeDatas.file.name, fieldname: 'StartDate' })
            .subscribe((data) => {
                const dateFormat: any = data.body;
                this.formatList = [];
                if (dateFormat._format) {
                    Object.entries(dateFormat._format).forEach(([format, date]) => {
                        const d: any = date;
                        if (d.count > 0) {
                            this.formatList.push({ format, sample: d.sample });
                        }
                    });
                }

                if (this.formatList.length > 0) {
                    // on sélectione automatiquement le format s'il n'y en a qu'un seul
                    this.selectedFormat = this.formatList[0].format;
                    this.next = true;
                }
            });
    }

    getSample(selectedFormat:string) {
        if (this.formatList) {
            const matchingFormat = this.formatList.filter((value) => value.format === selectedFormat);
            if (matchingFormat && matchingFormat.length > 0) {
                return matchingFormat[0].sample;
            }
        }
        return '';
    }

    goNext() {
        if (this.storeDatas && (this.storeDatas.file.type === 1 || this.storeDatas.file.type === 4)) {
            this.store.dispatch(
                new SetColDate({
                    date: this.columns[this.selectDateCol],
                    dateFormat: this.selectedFormat,
                    dateInit: this.dateInit,
                    noColDate: this.noColDate,
                }),
            );
        } else {
            this.store.dispatch(
                new SetColDate({
                    date: this.msurveyDateColumnName,
                    dateFormat: this.selectedFormat,
                    noColDate: false,
                }),
            );
        }

        this.router.navigate(['new-project', 'message']);
    }

    ngOnInit() {
        if (this.storeDatas.file.type === 2 || this.storeDatas.file.type === 3) {
            this.loadMsurveyDateFormat();
        }

        this.localeService.use(this.translate.currentLang);

        setTimeout(() => {
            this.store.dispatch(new SetEtapes(2)); // IB inversion data et message
        }, 500);
        this.store.dispatch(
            new SetColDate({
                date: null,
                dateFormat: null,
                dateInit: null,
                noColDate: false,
            }),
        );
        // --------------------Ajout pour l'inversion date <=> verbatim
        this.http
            .get('/api/v1/file', {
                withCredentials: true,
            })
            .subscribe((files: Files) => {
                if (files.files.length) {
                    this.store.dispatch(
                        new SetFileColsTypes(files.files[0].file_fields_type),
                    );
                    this.getFirstLine(files.files[0].file_name);
                } else {
                    this.router.navigate(['new-project', 'upload']);
                }
            });
    }

    // ------------- Ajout pour l'inversion date <=> verbatim
    getFirstLine(filename) {
        this.store.dispatch(new SetFileFirstLines({}));
        this.http
            .get(
                `/api/v1/file/${encodeURIComponent(filename)}/id/0/start/${encodeURIComponent(this.storeDatas.file.delimiter)}`,
                {
                    withCredentials: true,
                },
            )
            .subscribe((data) => {
                const colsItems: Array<Object> = [];
                const tableData = data[0].content;
                const rows = data[0].header;
                Object.keys(rows).forEach((v, k) => {
                    colsItems.push(rows[v]);

                    if (rows[v] === this.storeDatas.date) {
                        this.selectDateCol = k;
                        this.selectedFormat = this.storeDatas.dateFormat;
                        this.next = true;
                    }

                    if (this.storeDatas.colsTypes[rows[v]] === 'd') {
                        this.colsThatAreDate.push(k);
                    }
                });

                this.columns = colsItems;
                this.columns = [...this.columns];

                const rowsList = [];
                tableData.forEach((row) => {
                    const cellList = [];
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    Object.entries(row).forEach(([k, v]) => {
                        cellList.push(v);
                    });
                    rowsList.push(cellList);
                });

                this.rows = rowsList;
                this.store.dispatch(
                    new SetFileFirstLines({ colomns: rows, rows: tableData }),
                );
            });
    }

    enter(index) {
        this.hoverCol = index;
    }

    leave() {
        this.hoverCol = null;
    }

    dontUseDateCol() {
        this.selectedFormat = null;
        this.table.unselectCol(this.selectDateCol);
        this.selectDateCol = undefined;
        gtmClick({
            track_category: 'nouveau projet',
            track_name: 'choix de la date',
            track_cible: 'ne pas utiliser de colonne date',
        });
    }

    useDateCol() {
        this.selectedFormat = null;
        this.formatList = null;
    }

    selectDate(index) {
        if (this.noColDate) {
            gtmClick({
                track_category: 'nouveau projet',
                track_name: 'choix de la date',
                track_cible: 'utiliser une colonne date',
            });
        }
        this.noColDate = false;
        if (!this.selectedVerbatimCol.includes(index)) {
            this.selectedFormat = null;
            this.selectDateCol = index;
            this.formatLoaded = false;
            this.openModal(this.defaultTabButtonsTpl);

            this.fileService.getV1FileFilenameFormatFieldnameResponse({ filename: this.storeDatas.file.name, fieldname: this.columns[index].toString() })
                .subscribe({
                    next: (data) => {
                        const dateFormat: any = data.body;
                        this.formatList = [];
                        if (dateFormat._format) {
                            Object.entries(dateFormat._format).forEach(([format, date]) => {
                                const d: any = date;
                                if (d.count > 0) {
                                    this.formatList.push({ format, sample: d.sample });
                                }
                            });
                        }

                        if (this.formatList.length === 1) {
                            // on sélectioner automatiquement le format s'il n'y en a qu'un seul
                            this.selectedFormat = this.formatList[0].format;
                            this.next = true;
                            setTimeout(() => {
                                if (this.modalRef) {
                                    this.modalRef.close();
                                }
                            }, 1000);
                        } else {
                            // on n'affiche pas la liste des format s'il n'y a qu'un seul format
                            this.formatLoaded = true;
                        }
                    },
                    error: () => {
                        if (this.modalRef) {
                            this.modalRef.close();
                        }

                        this.formatList = [];

                        this.selectDateCol = null;
                        this.selectedFormat = null;
                        this.next = false;
                    },
                });
        }
    }

    showDatePicker(value: boolean) {
        this.datePickerDisplayed = value;
    }

    openModal(template: TemplateRef<any>) {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.modalRef = this.modalService.open(template, { backdrop: 'static' });
    }

    validDateFormat() {
        this.store.dispatch(
            new SetColDate({
                date: this.columns[this.selectDateCol],
                dateFormat: this.selectedFormat,
                dateInit: this.dateInit,
                noColDate: this.noColDate,
            }),
        );
        this.next = true;

        this.modalRef.close();
    }

    closeModal() {
        this.modalRef.close();
        this.selectDateCol = null;
        this.selectedFormat = null;
    }

    switchToDontUserFromPopup(event) {
        event.preventDefault();
        this.closeModal();
        this.noColDate = true;
        this.dontUseDateCol();
    }

    unfocus() {
        this.dateInput.nativeElement.blur();
    }

    overTheColumn(index) {
        if (this.colsThatAreDate.includes(index)) {
            if (this.selectDateCol === index) {
                this.tooltipContent = this.translate.instant('translations.newcorpus.stage3.tooltips.dateColumnAlreadySelected');
            } else {
                this.tooltipContent = this.translate.instant('translations.newcorpus.stage3.tooltips.columnSelect');
            }
        } else {
            this.tooltipContent = '';
        }
    }
}
