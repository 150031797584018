import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ResetDashModified } from 'app/stores/actions/settings/settings.actions';
import { ResetUser } from 'app/stores/actions/user/user.actions';
import { ResetCorpus } from 'app/stores/actions/corpus/corpus.actions';
import { ResetNewCorpus } from 'app/stores/actions/new-corpus/new-corpus.actions';
import { UserService } from 'app/api/services';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ModalComponent from 'app/shared/components/modal/modal.component';

@Injectable()
export default class HttpStatus implements HttpInterceptor {
    /**
   * Creates an instance of HttpStatus.
   * @param {Router} router
   * @memberof HttpStatus
   */
    constructor(
        private modalService: NgbModal,
        private router: Router,
        private user: UserService,
        private store: Store,
        private permissionsService: NgxPermissionsService,
    ) { }

    /**
   * Toast error of api XHR and redirect 403 to login
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof HttpStatus
   */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                () => {},
                // eslint-disable-next-line consistent-return
                (error) => {
                    // Here we use a javascript proxy in order for the error recipient to be informed when potential new
                    // information is available in the error object (that is when the error object is updated)
                    const proxyHandler: any = {}; // Empty proxy handler that can be customized later by the error recipient
                    // Then we set the proxy handler in the error object, so the error recipient can retrieve it and customize it
                    // in order to subscribe to any further update in the error object.
                    error.proxyHandler = proxyHandler;
                    const errorProxy = new Proxy(error, proxyHandler); // Eventually link the empty handler to a new javascript proxy

                    if (typeof error.error === 'string') {
                        error.error = JSON.parse(error.error);
                    }
                    // La condition ci-dessous doit être définie en dehors du if, sinon on ne peu pas modifier la variable error
                    const errorIsInstanceOfHttpErrorResponse = error instanceof HttpErrorResponse;
                    if (errorIsInstanceOfHttpErrorResponse && error.error instanceof Blob && error.error.type === 'application/json') {
                        // https://github.com/angular/angular/issues/19888
                        // When request of type Blob, the error is also in Blob instead of object of the json data
                        return new Promise<any>((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (e: Event) => {
                                try {
                                    const errmsg = JSON.parse((<any>e.target).result);
                                    reject(new HttpErrorResponse({
                                        error: errmsg,
                                        headers: error.headers,
                                        status: error.status,
                                        statusText: error.statusText,
                                        url: error.url,
                                    }));

                                    // Add the detailed error message received from the backend. If properly configured by the error
                                    // recipient, the handler is triggered when the error object is updated.
                                    errorProxy.error = errmsg;
                                } catch (e1) {
                                    reject(error);
                                }
                            };
                            reader.onerror = () => {
                                reject(error);
                            };
                            reader.readAsText(error.error);
                        });
                    } if ([304, 403].includes(error.status)) {
                        this.store.dispatch(new ResetDashModified()).subscribe(() => {
                            this.user.getV1AuthLogout().subscribe(() => {
                                this.store.dispatch(new ResetUser()).subscribe(() => {
                                    this.store.dispatch(new ResetCorpus()).subscribe(() => {
                                        this.store.dispatch(new ResetNewCorpus()).subscribe(() => {
                                            this.permissionsService.flushPermissions();
                                            this.router.navigate(['/auth/login']);
                                        });
                                    });
                                });
                            });
                        });
                    } else if (error?.error?.error) {
                        // if error contain a specific error code returned by back-end, just log the error and each request must manage error to display appropried message
                        console.error(error);
                    } else if (!this.modalService.hasOpenModals()) {
                        // otherwise error doesn't contain a specific error code, display a tooltip with the message
                        const modal = this.modalService.open(ModalComponent, {});
                        modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                        modal.componentInstance.alertTypeVariant = 'danger';
                    }
                },
            ),
            finalize(() => { }),
        );
    }
}
