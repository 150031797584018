import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit, Component, Input, OnDestroy, TemplateRef, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Thematicslistshare, Thematic } from 'app/api/models';
import { ThematicService } from 'app/api/services';
import ExportType from 'app/modules/CommonExportTypeEnum';
import ThematicEditorComponent, { ThematicEditorMode } from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import FooterService from 'app/shared/components/footer/footer.service';
import ThematicsListComponent from 'app/shared/components/thematics-list/thematics-list.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import download from 'app/utils/functions/download';
import accentless from 'app/utils/functions/accentless';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import CorpusService from 'app/utils/services/corpus.service';

@Component({
    selector: 'corpus-app-thematics',
    templateUrl: './corpus-thematics.component.html',
    styleUrls: ['./corpus-thematics.component.scss'],
})
export default class CorpusThematicsComponent implements AfterViewInit, OnDestroy {
    public actualThemesList: any;

    public lastThemesList: any;

    public placeholder: any;

    public search: string = '';

    public displayColumns: any = [];

    public columns: any = [];

    public perso = true;

    public shared = false;

    public admin = true;

    public persoType = 'theme.perso';

    public sharedType = '!theme.perso';

    public dataSource: any = [];

    public username: string = '';

    public persoColumns: string[] = [
        'is_favorite',
        'them_name',
        'usernameOwner',
        'nb_users',
        'them_lang',
        'them_update_date',
        'share',
        'edit',
        'delete',
        'collapse',
    ];

    public publicColumns: string[] = [
        'is_favorite',
        'them_name',
        'usernameOwner',
        'nb_users',
        'them_lang',
        'them_update_date',
        'share',
        'edit',
        'collapse',
    ];

    lang: string = '';

    them: any;

    index;

    hasShared: boolean = false;

    hasPerso: boolean = false;

    hasCorpus: boolean = false;

    navData: any = null;

    private exportType = ExportType;

    private selectedType: ExportType;

    modalRef: BsModalRef;

    deleteTheme;

    constructor(
        private thematicService: ThematicService,
        private modalService: NgbModal,
        private bsModalService: BsModalService,
        private store: Store,
        private http: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private footerService: FooterService,
        private corpusService: CorpusService,
    ) {
    }

    @Input() thematicsListShare: Thematicslistshare;

    @Input() dashboardThematics: any = [];

    @ViewChild('deleteModal')
        deleteModal: TemplateRef<any>;

    @ViewChild('editRule')
        editRule: TemplateRef<any>;

    @ViewChild('createTheme')
        createTheme: TemplateRef<any>;

    @ViewChild(ThematicsListComponent)
        thematicsList: Component;

    ngAfterViewInit() {
        this.footerService.visible(true);
        this.navData = this.route.snapshot.paramMap.get('referer');
        this.getThematics();
    }

    ngOnDestroy() {
        this.footerService.visible(false);
    }

    private getThematics() {
        this.thematicService.postV1Thematic({ corp_id: this.corpusService.currentCorpus.getValue().corp_id }).subscribe((tl) => {
            tl.personalThematics.forEach((row: any) => {
                row.isCollapsed = true;
                row.perso = true;
                row.checked = false;
                if (_.isEmpty(row.them_definition)) {
                    row.them_definition = [];
                }
                row.search = [row.them_name, row.them_definition.join(' | ')].join(' | ');
            });
            tl.sharedInDashboardThematics.forEach((row: any) => {
                row.isCollapsed = true;
                row.perso = false;
                row.checked = false;
                if (_.isEmpty(row.them_definition)) {
                    row.them_definition = [];
                }
                row.search = [row.them_name, row.them_definition.join(' | ')].join(' | ');
            });
            tl.sharedThematics.forEach((row: any) => {
                row.isCollapsed = true;
                row.perso = false;
                row.checked = false;
                if (_.isEmpty(row.them_definition)) {
                    row.them_definition = [];
                }
                row.search = [row.them_name, row.them_definition.join(' | ')].join(' | ');
            });

            this.thematicsListShare = JSON.parse(JSON.stringify(tl));
            this.username = `${this.store.snapshot().user.givenname} ${this.store.snapshot().user.surname}`;
            this.placeholder = 'translations.analysisDashboard.themes.searchInThemes';

            this.displayColumns = JSON.parse(JSON.stringify(this.thematicsListShare));

            this.hasPerso = this.thematicsListShare.personalThematics.length > 0;
            this.hasShared = this.thematicsListShare.sharedThematics.length > 0;
            this.hasCorpus = this.thematicsListShare.sharedInDashboardThematics.length > 0;
        });
    }

    exit() {
        let url = '/';
        if (this.navData) {
            url = this.navData;
        }

        this.router.navigate([url]);
    }

    onFreeSearch(search) {
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'rechercher thématique',
            track_cible: '',
        });
        if (search.length > 0) {
            const findDisplayColumns = {
                personalThematics: [],
                sharedInDashboardThematics: [],
                sharedThematics: [],
            };
            const findR = new RegExp(accentless(search), 'gimu');

            ['personalThematics', 'sharedThematics', 'sharedInDashboardThematics'].forEach((type) => {
                if (this.thematicsListShare[type].length > 0) {
                    this.thematicsListShare[type].forEach((col) => {
                        if (col.search) {
                            findR.lastIndex = 0;
                            if (findR.test(accentless(col.search))) {
                                findDisplayColumns[type].push(col);
                            }
                        }
                    });
                }
            });

            this.displayColumns = findDisplayColumns;
        } else {
            this.displayColumns = this.thematicsListShare;
        }
    }

    openCreateModal() {
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'création thématique',
        });
        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.mode = ThematicEditorMode.CREATE_ONLY;
        modal.componentInstance.showLanguage = true;
        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            modal.close();
            this.thematicService.postV1Thematic_1(thematic).subscribe(() => {
                this.getThematics();
            });
        });
    }

    openEditModalPerso(t) {
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'modifier thématique',
        });
        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.mode = ThematicEditorMode.UPDATE;
        modal.componentInstance.initValue = {
            name: t.them_name,
            definition: JSON.parse(JSON.stringify(t.them_definition)),
            lang: t.them_lang,
        };

        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            modal.close();

            const params = { themId: t.them_id, lang: { name: thematic.name, definition: thematic.definition } };
            this.thematicService.patchV1ThematicThemId(params).subscribe(() => {
                this.getThematics();
            });
        });
    }

    openEditModalShared(t) {
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'copie thématique partagée',
        });
        const modal = this.modalService.open(ThematicEditorComponent, { modalDialogClass: 'thematic-modal' });
        modal.componentInstance.allowSameValue = true;
        modal.componentInstance.initValue = {
            name: `Copie de ${t.them_name}`,
            definition: JSON.parse(JSON.stringify(t.them_definition)),
            lang: t.them_lang,
        };

        modal.componentInstance.save.subscribe((thematic: Thematic) => {
            thematic.lang = t.them_lang;
            modal.close();
            this.thematicService.postV1Thematic_1(thematic).subscribe(() => {
                this.getThematics();
            });
        });
    }

    openDeleteModal(theme) {
        if (!theme.perso && theme.shared_used) {
            // nothing
        } else {
            this.deleteTheme = theme;
            this.modalService.open(this.deleteModal).result.then(() => {
                this.thematicService
                    .deleteV1ThematicThemId(theme.them_id)
                    .subscribe(() => {
                        this.getThematics();
                    });
            });
        }
    }

    export() {
        let url : string = `${this.thematicService.rootUrl}/v1/thematic/download`;

        if (this.selectedType === ExportType.EXCEL) {
            url += `/${ExportType.EXCEL}`;
        }

        const corpId = this.corpusService.currentCorpus.getValue().corp_id;

        this.http
            .post(url, { corp_id: corpId }, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe((resp) => {
                const filename = resp.headers.get('content-disposition').split('=')[1];
                download(window.URL.createObjectURL(resp.body), filename);
            });
        gtmClick({
            track_category: 'gestion thématique',
            track_name: 'export thématique',
            track_cible: this.selectedType === ExportType.EXCEL ? 'exporter xslx' : 'exporter zip',
        });
        this.selectedType = null;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.bsModalService.show(template, { backdrop: 'static' });
    }
}
