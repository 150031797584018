import {
    AfterViewInit,
    Component,
    Input, OnChanges, OnInit,
    SimpleChanges, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Folder } from 'app/api/models/folder';
import { IaConfigResponse } from 'app/api/models/iaconfig';
import { BaseComponent } from 'app/base.component';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import accentless from 'app/utils/functions/accentless';
import AiconfigEditModalComponent from '../aiconfig-edit-modal/aiconfig-edit-modal.component';
import ConfigurationRow from '../configuration-row';

@Component({
    selector: 'aiconfig-folder-configurations',
    templateUrl: './aiconfig-folder-configurations.component.html',
    styleUrl: './aiconfig-folder-configurations.component.scss',
})
export default class AiconfigFolderConfigurationsComponent extends BaseComponent implements AfterViewInit, OnChanges, OnInit {
    @Input()
        folder: Folder;

    previousConfigurations: Array<IaConfigResponse>;

    filteredConfigurations: Array<ConfigurationRow>;

    shareConfigurations: boolean = false;

    @Input()
    private filter = '';

    public columns: Array<any> = [];

    @Input()
    public scrollable = true;

    @ViewChild('actionsRowTpl') actionsRowTpl: TemplateRef<any>;

    @ViewChild('sharedRowTpl') sharedRowTpl: TemplateRef<any>;

    @ViewChild('ownerRowTpl') ownerRowTpl: TemplateRef<any>;

    @ViewChild('detailRowTpl') detailRowTpl: TemplateRef<any>;

    @ViewChild('selectRowTpl') selectRowTpl: TemplateRef<any>;

    @ViewChild('headerSelectTpl') headerSelectTpl: TemplateRef<any>;

    @ViewChild('selectOneRowTpl') selectOneRowTpl: TemplateRef<any>;

    @ViewChild('nameRowTpl') nameRowTpl: TemplateRef<any>;

    @Input() type: string = '';

    selection = false;

    constructor(private modalService: NgbModal, private translateService: TranslateService, private aiConfigService: AiConfigService) {
        super();
    }

    get configurations() {
        return this.folder.list_discovery_config;
    }

    ngOnInit(): void {
        this.subs.sink = this.aiConfigService.addConfigurationToFolder.subscribe(() => {
            this.checkSelectionStateChange();
        });

        this.subs.sink = this.aiConfigService.shareConfigurations.subscribe((value) => {
            this.checkSelectionStateChange();
            this.shareConfigurations = value;
            this.initColumns();
        });

        this.subs.sink = this.aiConfigService.folders.subscribe(() => {
            this.applyFilters();
        });

        this.subs.sink = this.aiConfigService.closeAllConfigurationDetails.subscribe(() => {
            this.filteredConfigurations.forEach((row: ConfigurationRow) => {
                row.isCollapsed = true;
            });
        });

        this.previousConfigurations = [...this.configurations];
        this.applyFilters();
    }

    checkSelectionStateChange() {
        if ((this.aiConfigService.addConfigurationToFolder.getValue() || this.aiConfigService.shareConfigurations.getValue()) !== this.selection) {
            this.selection = this.aiConfigService.addConfigurationToFolder.getValue() || this.aiConfigService.shareConfigurations.getValue();
        }
    }

    applyFilters() {
        this.filteredConfigurations = [];

        const findR = new RegExp(accentless(this.filter), 'gimu');
        findR.lastIndex = 0;

        this.configurations.forEach((configuration) => {
            if (findR.test(accentless(configuration.search))) {
                this.filteredConfigurations.push(new ConfigurationRow(configuration));
            }
        });
    }

    ngAfterViewInit(): void {
        this.initColumns();
    }

    public get config(): any {
        return {
            hasDetails: true,
            detailTemplate: this.detailRowTpl,
            collapseAllDetailsTooltipToTranslate: 'translations.analysisDashboard.themes.details_tooltip',
            style: {
                maxHeight: this.scrollable ? '400px' : 'unset',
                'overflow-y': this.scrollable ? 'scroll' : 'unset',
            },
        };
    }

    get name() {
        return {
            prop: 'config_name',
            cellTemplate: this.nameRowTpl,
            title: 'translations.utils.name',
            sort: true,
            style: {
                'word-break': 'break-word',
            },

        };
    }

    get user() {
        return {
            prop: 'usernameOwner',
            cellTemplate: this.ownerRowTpl,
            title: 'translations.analysisDashboard.themes.author',
            sort: true,
            style: {
                width: '200px',
            },

        };
    }

    private update = {
        prop: 'config_update_date',
        title: 'translations.analysisDashboard.themes.last_update',
        sort: true,
        style: {
            width: '150px',
        },

    };

    private sharedate = {
        prop: 'sharing_date',
        title: 'translations.analysisDashboard.aiconfig.share_date',
        sort: true,
        style: {
            width: '150px',
        },

    };

    get shared() {
        return {
            title: 'translations.analysisDashboard.aiconfig.config_shared',
            cellTemplate: this.sharedRowTpl,
            prop: 'config_shared',
            sort: true,
            style: {
                width: '50px',
            },

        };
    }

    get actions() {
        return {
            title: '',
            cellTemplate: this.actionsRowTpl,
            style: {
                width: '70px',
            },
        };
    }

    get selectionCol() {
        return {
            headerTemplate: this.headerSelectTpl,
            cellTemplate: this.selectRowTpl,
            style: {
                width: '30px',
            },
        };
    }

    get selectionOneCol() {
        return {
            title: '',
            cellTemplate: this.selectOneRowTpl,
            style: {
                width: '30px',
            },
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            this.applyFilters();
        }
    }

    get selectOnlyOne() {
        return this.aiConfigService.selectOnlyOne;
    }

    isSelected(configuration) {
        return this.aiConfigService.selectedConfigurations.getValue().indexOf(configuration) >= 0;
    }

    isAllSelected() {
        return this.filteredConfigurations.every((row: ConfigurationRow) => this.aiConfigService.selectedConfigurations.getValue().indexOf(row.configuration) >= 0 || !row.perso);
    }

    toggleAll(value) {
        const selectedConfigurations = [...this.aiConfigService.selectedConfigurations.getValue()];
        this.filteredConfigurations.forEach((row: ConfigurationRow) => {
            const index = selectedConfigurations.indexOf(row.configuration);
            if (value && index < 0 && row.perso) {
                selectedConfigurations.push(row.configuration);
            } else if (!value && index >= 0) {
                selectedConfigurations.splice(index, 1);
            }
        });
        this.aiConfigService.selectedConfigurations.next(selectedConfigurations);
    }

    async copy(configuration) {
        await this.aiConfigService.copyIntoFolder(configuration, this.folder);
    }

    initColumns() {
        this.columns = [];

        if (this.selectOnlyOne) {
            this.columns = [this.selectionOneCol];
        }

        this.columns = [...this.columns, this.name, this.user, this.shared, this.sharedate, this.update];

        if (this.shareConfigurations) {
            this.columns = [this.selectionCol, ...this.columns];
        }

        if (!this.selectOnlyOne) {
            this.columns.push(this.actions);
        }
    }

    toggleSelection(configuration) {
        const selectedConfigurations = [...this.aiConfigService.selectedConfigurations.getValue()];
        const index = selectedConfigurations.indexOf(configuration);
        if (index > -1) {
            selectedConfigurations.splice(index, 1);
        } else {
            selectedConfigurations.push(configuration);
        }
        this.aiConfigService.selectedConfigurations.next(selectedConfigurations);
    }

    remove(configuration: IaConfigResponse) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.aiconfig.delete_configuration_modal_title';

        if (configuration.hidden) {
            modal.componentInstance.content = this.translateService.instant(
                'translations.analysisDashboard.aiconfig.delete_hidden_configuration_from_folder_modal_text',
                { name: configuration.config_name },
            );
        } else {
            modal.componentInstance.content = this.translateService.instant('translations.analysisDashboard.aiconfig.delete_configuration_from_folder_modal_text', { name: configuration.config_name });
        }

        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.delete';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s: boolean) => {
            if (s) {
                this.aiConfigService.removeConfigurationFromFolder(this.folder, configuration);

                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer configuration IA du dossier',
                    track_cible: 'supprimer',
                });
            } else {
                gtmClick({
                    track_category: 'gestion configuration IA',
                    track_name: 'supprimer configuration IA du dossier',
                    track_cible: 'annuler',
                });
            }
        });
    }

    edit(configuration) {
        const modal = this.modalService.open(AiconfigEditModalComponent, {});
        modal.componentInstance.configuration = configuration;
        modal.componentInstance.aiconfigService = this.aiConfigService;
    }

    selectOneConfiguration(configuration) {
        this.aiConfigService.selectedConfigurations.next([configuration]);
    }
}
