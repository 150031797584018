// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  inset: 0;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 997;
}
:host .text {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
