import { Inputnamevalues } from './inputnamevalues';
import { SearchField } from './searchparams';

export interface VerbatimConfig {
    pert_is_checked?:boolean;
    pert_is_disabled?:boolean;
    search_enabled?:boolean;
    extended_table?: boolean;
    selected_columns?:Array<SearchField>;
    word_tab?:string;
    cloud_category?:string;
    cloud_sub_category?:string;
    cloud_max_size?:number;
    cloud_use_delta_tone ?: boolean;
    extended_cloud_type?:string;
    extended_cloud_max_size?:number;
    freesearch?:string;
    keywordsearch?:Array<Inputnamevalues>;
}

export enum VerbatimConfigField {
    pert_is_checked = 'pert_is_checked',
    search_enabled = 'search_enabled',
    keywordsearch = 'keywordsearch',
    extended_table = 'extended_table',
    selected_columns = 'selected_columns',
    word_tab = 'word_tab',
    cloud_category = 'cloud_category',
    cloud_sub_category = 'cloud_sub_category',
    cloud_max_size = 'cloud_max_size',
    cloud_use_delta_tone = 'cloud_use_delta_tone',
    extended_cloud_type = 'extended_cloud_type',
    extended_cloud_max_size = 'extended_cloud_max_size',
    freesearch = 'freesearch',
}
