// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .row-loading {
  display: none;
}
::ng-deep .is-loading {
  visibility: hidden;
}
::ng-deep .is-loading .row-title,
::ng-deep .is-loading .row-loading {
  visibility: visible;
}
::ng-deep .is-loading .row-loading {
  display: block;
  margin-top: 75px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  min-height: 350px;
}

:host {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 62px);
}
:host.validation-bar-open {
  height: calc(100vh - 132px);
}
:host .dashboard-content {
  flex-grow: 1;
  position: relative;
  border-left: 1px solid #ccc;
}
:host .dashboard-content .dashboard-content-scroll {
  overflow: auto;
  height: calc(100vh - 165px);
  position: relative;
}
:host .dashboard-content .dashboard-inner-scroll {
  padding: 0 10px 0 15px;
}
:host .dashboard-content.no-data app-no-data {
  background-color: rgba(238, 238, 238, 0.9);
}
:host .dashboard-content.no-data ::ng-deep .dashboard-element-content app-no-data {
  display: none;
}
:host .corpus-analyses-content {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
}
:host .corpus-analyses-content .vertical-filters {
  border-top: 1px solid #ccc;
  background-color: #eee;
}
:host .corpus-analyses-content .filters-toggle ::ng-deep app-filters-toggle:not(:first-child) {
  border-left: 1px solid #ccc;
}
:host .corpus-analyses-content .vertical-filters-content {
  height: calc(100vh - 183px);
  overflow-x: auto;
  flex-wrap: wrap;
}
:host .corpus-analyses-content .vertical-filters-content > div {
  display: flex;
}
:host .corpus-analyses-content .vertical-filters-content ::ng-deep .filter-border:not(:first-child) {
  border-left: 1px solid #ccc;
}
:host .corpus-analyses-content .vertical-filters-content ::ng-deep .filter-border:first-child {
  padding-left: 15px;
}
:host .corpus-analyses-content ::ng-deep .dashboard-element-content {
  position: relative;
}
:host .corpus-analyses-content .analyses-sections,
:host .corpus-analyses-content ::ng-deep .analyses-sections {
  overflow: hidden;
  display: flex;
  gap: 20px;
}
:host .corpus-analyses-content.dashboard-comparison.nb-dashboard-2 ::ng-deep .dashboard-name-item {
  min-width: 850px;
  max-width: 850px;
}
:host .corpus-analyses-content.dashboard-comparison.nb-dashboard-2 ::ng-deep .dashboard-element {
  min-width: 875px;
  max-width: 875px;
}
:host .corpus-analyses-content.dashboard-comparison.nb-dashboard-3 ::ng-deep .dashboard-name-item {
  min-width: 525px;
  max-width: 525px;
}
:host .corpus-analyses-content.dashboard-comparison.nb-dashboard-3 ::ng-deep .dashboard-element {
  min-width: 550px;
  max-width: 550px;
}
:host .corpus-analyses-content.dashboard-comparison.nb-dashboard-3 ::ng-deep .top-section {
  grid-template-columns: repeat(3, 1fr);
}
:host .corpus-analyses-content.dashboard-comparison .vertical-filters .filters-toggle {
  padding-right: 20px;
}
:host .corpus-analyses-content.dashboard-comparison .dashboard-content-scroll {
  height: calc(100vh - 215px);
}
:host .corpus-analyses-content.dashboard-comparison ::ng-deep .analyses-sections .dashboard-element:not(:first-child) h3.collapsable {
  visibility: collapse;
}
:host .corpus-analyses-content.dashboard-comparison ::ng-deep .analyses-sections .dashboard-element:not(:first-child) h3 {
  visibility: hidden;
}
:host .corpus-analyses-content.dashboard-comparison ::ng-deep .analyses-sections .dashboard-element .dashboard-element-content {
  border-right: 2px solid #ccc;
  padding-right: 20px;
}
:host .corpus-analyses-content.dashboard-comparison ::ng-deep .analyses-sections .dashboard-element .dashboard-element-content.verbatims-list .table-content {
  padding-right: 0;
}
:host .corpus-analyses-content.dashboard-comparison ::ng-deep .analyses-sections .dashboard-element .dashboard-element-content app-no-data {
  right: 20px;
}
:host .corpus-analyses-content.dashboard-comparison ::ng-deep .analyses-sections .dashboard-element:last-child .dashboard-element-content {
  border-right-color: transparent;
}
:host .dashboard-loading {
  position: absolute;
  inset: 0;
  background-color: rgb(238, 238, 238);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
:host .dashboard-loading .text {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
