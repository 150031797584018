/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import drawDashboardNumber from './chartjs-plugins-utils';

export const addDashboardNumberToAxis = {
    id: 'addDashboardNumberToAxis',
    beforeLayout(chart) {
        const nbXaxis = Object.values(chart.scales).filter((scale:any) => scale.axis === 'x').length;
        if (nbXaxis > 1) {
            chart.options.layout.padding.left = nbXaxis !== chart._metasets.length ? 60 : 40;
        }
    },
    afterDatasetsDraw(chart) {
        if (chart.config._config.type !== 'line') {
            return;
        }

        const { ctx } = chart;
        const dashboardCountByAxis = {};

        chart._metasets
            .filter((metaset) => metaset.visible)
            .forEach((metaset) => {
                const axisId = metaset.iAxisID;
                const { dashboardNumber } = metaset._dataset;

                if (metaset._dataset && Object.keys(chart.scales).length > 2) {
                    const dashboardCountForAxis = dashboardCountByAxis[axisId] || 0;

                    const { top } = chart.scales[axisId];
                    const left = 20 * dashboardCountForAxis;

                    drawDashboardNumber(ctx, `${dashboardNumber + 1}`, left, top + 17);

                    dashboardCountByAxis[axisId] = dashboardCountForAxis + 1;
                }
            });
    },

};
