import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ResetNewCorpus, SetEtapes } from 'app/stores/actions/new-corpus/new-corpus.actions';
import { formatDate } from '@angular/common';
import SettingsState from 'app/stores/state/settings/settings.state';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import { TranslateService } from '@ngx-translate/core';
import FooterService from 'app/shared/components/footer/footer.service';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import { CorpusService } from '../../../api/services';
import BaseEtapeComponent from '../BaseEtapeComponent';

/**
 * Feedback stage new corpus
 *
 * @export
 * @class Etape7feedbackComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape7feedback',
    templateUrl: './etape7feedback.component.html',
    styleUrls: ['./etape7feedback.component.scss'],
    animations: [slideInOutAnimation],
})
export default class Etape7feedbackComponent extends BaseEtapeComponent implements OnInit, OnDestroy {
    protected newCorpus: any;

    protected currentFile: any = null;

    protected estimated_duration: any;

    protected mailHref!: string;

    private subscriber: any;

    private loop: any;

    private mailTo!: string;

    public isDashboardAvailable: boolean = false;

    public hasAsyncTreatmentInProgress :boolean = false;

    settings$: Observable<SettingsModel>;

    constructor(
        private store: Store,
        private router: Router,
        private corpus: CorpusService,
        private translate: TranslateService,
        private footerService: FooterService,
    ) {
        super();
        this.footerService.visible(true);
        const state = this.store.snapshot().newcorpus;
        if (this.isEtape1Invalid(state)) {
            this.router.navigate(['new-project']);
        } else if (this.isEtape2Invalid(state)) {
            this.router.navigate(['new-project', 'upload']);
        } else if (this.isEtape3Invalid(state)) {
            this.router.navigate(['new-project', 'date']);
        } else if (this.isEtape4Invalid(state)) {
            this.router.navigate(['new-project', 'message']);
        } else if (this.isEtape5Invalid(state)) {
            this.router.navigate(['new-project', 'confirm']);
        } else {
            this.newCorpus = state.datas;
        }
    }

    ngOnInit() {
        this.store.dispatch(new SetEtapes(6));
        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            this.mailTo = settings.vcrm.mail_contact.mailto;
        });
        this.getFilesInfos();
        this.generateMailLink();
    }

    get canAccessData() {
        return this.currentFile && this.currentFile.status_flags ? this.currentFile.status_flags.canAccessData : false;
    }

    /**
   * Get status of current file to update the timeline
   */
    getFilesInfos() {
        this.subscriber = this.corpus.getV1CorpusCorpIdStatus(this.newCorpus.id).subscribe({
            next: (corp) => {
                this.hasAsyncTreatmentInProgress = corp.status_flags.hasAsyncTreatmentInProgress;
                this.isDashboardAvailable = corp.status_flags.isDashboardAnalyseAvailable;
                const [file] = corp.files;

                this.currentFile = file;
                this.currentFile.file_status = Number(this.currentFile.file_status);

                if (!this.currentFile.status_flags.isOnError && this.currentFile.status_flags.isAnalyseInProgress) {
                    if (corp.corp_duration.estimated?.duration && !this.estimated_duration) {
                        // Calculate estimated date
                        const durationDate = new Date();
                        durationDate.setTime(durationDate.getTime() + (corp.corp_duration.estimated.duration * 1000));
                        // If duration is more than 15 mn and duration time not ended on 0 or 5 minutes (ex 14h40 or 14h45)
                        if (corp.corp_duration.estimated.duration > (15 * 60) && (durationDate.getMinutes() % 5 !== 0)) {
                            // we round time on next 5 mn
                            durationDate.setMinutes(Math.ceil(durationDate.getMinutes() / 5) * 5);
                        }
                        this.estimated_duration = formatDate(durationDate, 'HH:mm', 'fr-FR');
                    }
                }
            },
            complete: () => {
                clearTimeout(this.loop);
                // On boucle tant que le corpus est en cours de traitement
                if (this.currentFile.status_flags.isAnalyseInProgress) {
                    this.loop = setTimeout(() => {
                        this.getFilesInfos();
                    }, 5000);
                }
            },
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        clearTimeout(this.loop);
        if (this.subscriber) {
            this.subscriber.unsubscribe();
        }
        this.store.dispatch(new ResetNewCorpus());
    }

    /**
   * Return the mailto: link
   */
    generateMailLink(): void {
        this.mailHref = `mailto:${this.mailTo
        }?subject=${this.translate.instant('translations.utils.help.mail.subject', { name: this.newCorpus.name })
        }&body=${encodeURIComponent(this.translate.instant('translations.utils.help.mail.body', { url: `${window.location.origin}/project/${this.newCorpus.id}/settings/files` }))}`;
    }
}
