import {
    Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { News, Newscategory } from 'app/api/models';
import { NewsType } from 'app/api/models/news';
import { BaseComponent } from 'app/base.component';
import NewsDisplayerComponent from 'app/shared/components/news-displayer/news-displayer.component';
import { SetNews, SetNewsCategories } from 'app/stores/actions/news/news.actions';

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.scss'],
})
export default class NewsListComponent extends BaseComponent implements OnInit, OnDestroy {
    // Common variables
    categoryId$;

    category$: Newscategory;

    news: News[] = [];

    filteredNews: News[] = [];

    // Filters variables
    isCommonNewsDisplay: boolean = true;

    isImportantNewsDisplay: boolean = true;

    isMostImportantNewsDisplay: boolean = true;

    // Pagination variables
    itemPerPage: number = 5;

    @ViewChild('newsDisplayer')
        newsDisplayer: NewsDisplayerComponent;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private actions$: Actions,
    ) {
        super();
    }

    /** ******************** */
    /* LIFECYCLE FUNCTIONS */
    /** ******************** */

    ngOnInit() {
        this.setNews();
        this.setCategory();

        // Attente de la catégorie désignée
        this.subs.sink = this.route.paramMap.subscribe(
            (params) => {
                if (params.get('id')) {
                    this.categoryId$ = parseInt(params.get('id'), 10);
                } else {
                    this.categoryId$ = undefined;
                }
                this.setCategory();
            },
        );

        // Attente du chargement des données dans le store
        this.subs.sink = this.actions$
            .pipe(ofActionSuccessful(SetNews))
            .subscribe(() => {
                this.setNews();
            });

        // Attente du chargement des données dans le store
        this.subs.sink = this.actions$
            .pipe(ofActionSuccessful(SetNewsCategories))
            .subscribe(() => {
                this.setCategory();
            });
    }

    /** ***************** */
    /* COMMON FUNCTIONS */
    /** ***************** */

    pageChanged(event): void {
        this.newsDisplayer.goToPage(event);
    }

    setCategory() {
        if (this.categoryId$ !== undefined) {
            this.category$ = this.store.snapshot().news.newsCategories.find((cat) => cat.cate_id === this.categoryId$);
        } else {
            this.category$ = undefined;
        }
        this.applyFilter();
    }

    setNews() {
        this.news = this.store.snapshot().news.newsList;
        this.applyFilter();
    }

    toggleCommonNewsDisplay() {
        this.isCommonNewsDisplay = !this.isCommonNewsDisplay;
        this.applyFilter();
    }

    toggleImportantNewsDisplay() {
        this.isImportantNewsDisplay = !this.isImportantNewsDisplay;
        this.applyFilter();
    }

    toggleMostImportantNewsDisplay() {
        this.isMostImportantNewsDisplay = !this.isMostImportantNewsDisplay;
        this.applyFilter();
    }

    /** **************** */
    /* NEWS TREATMENTS */
    /** **************** */

    /**
   * Apply filters on news to display
   */
    applyFilter() {
        this.filteredNews = this.news.filter((news) => {
            let condition = true;

            // Headline filter
            condition = condition && (news.isHeadline !== true);

            // Category filter
            if (this.category$ !== undefined) {
                condition = condition && (news.category === this.category$.cate_id);
            }

            // Type filter
            if (!this.isCommonNewsDisplay) {
                condition = condition && (news.type !== NewsType.GENERAL);
            }
            if (!this.isImportantNewsDisplay) {
                condition = condition && (news.type !== NewsType.IMPORTANT);
            }
            if (!this.isMostImportantNewsDisplay) {
                condition = condition && (news.type !== NewsType.FAULT_REPORT);
            }

            // Condition validation
            if (condition) {
                return news;
            }
            return null;
        });
    }

    sortNewsAscentDate() {
        this.filteredNews.sort((newsA, newsB) => (new Date(newsA.date)).getTime() - (new Date(newsB.date)).getTime());
    }

    sortNewsDescentDate() {
        this.filteredNews.sort((newsA, newsB) => (new Date(newsB.date)).getTime() - (new Date(newsA.date)).getTime());
    }
}
