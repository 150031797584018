import { AfterViewInit, Directive, ElementRef } from '@angular/core';

export const percentColors = [
    { value: 101, color: '#32c832', className: 'percent-color-101' },
    { value: 80, color: '#666', className: 'percent-color-80' },
    { value: 40, color: '#fc0', className: 'percent-color-40' },
    { value: 20, color: '#ff7900', className: 'percent-color-20' },
    { value: 0, color: '#cd3c14', className: 'percent-color-0' },
];

@Directive({
    selector: '[percentColor]',
})
export default class PercentColorDirective implements AfterViewInit {
    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        let result: string = 'no-color';

        percentColors.forEach((color) => {
            if (+this.elementRef.nativeElement.textContent.replace('%', '') < color.value) {
                result = color.className;
            }
        });

        this.elementRef.nativeElement.classList.add(result);
    }
}
