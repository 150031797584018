// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-comparison {
  border-left: 1px solid #ccc;
}

.search {
  width: 55%;
  margin: auto;
}

.search-clear {
  position: absolute;
  top: 2px;
  right: 0;
  height: 30px;
  width: 40px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.dashboard-scroll {
  overflow: auto;
  min-height: 400px;
  max-height: 400px;
  padding-right: 20px;
}

.vcrm-ethics {
  line-height: 14px;
  font-size: 20px;
  top: 1px;
  position: relative;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
