import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Thematic } from 'app/api/models';
import { ThematicService } from 'app/api/services';
import QueryValidatorService from 'app/utils/services/query-validator.service';

export enum ThematicEditorMode {
    CREATE_AND_VALIDATE = 'create_and_validate',
    CREATE_ONLY = 'create_only',
    UPDATE = 'update',
}

@Component({
    selector: 'thematic-editor',
    templateUrl: './thematic-editor.component.html',
    styleUrls: ['./thematic-editor.component.scss'],
})
export default class ThematicEditorComponent implements OnInit {
    @Input()
        initValue: Thematic = { name: '', definition: [''], lang: '' };

    @Input()
        allowSameValue = false;

    @Input()
        showLanguage = false;

    @Input()
        gtmTrackCategory:string = 'gestion thématique';

    @Input()
        gtmTrackName: string = 'enregistrer thématique';

    @Input()
        mode: ThematicEditorMode = ThematicEditorMode.CREATE_AND_VALIDATE;

    showValidFeedback: boolean = false;

    newValue: Thematic;

    value: string = '';

    isValid = true;

    errorDetails: string = '';

    isCollapsed = true;

    languages;

    get title() {
        switch (this.mode) {
            case ThematicEditorMode.UPDATE:
                return 'translations.analysisDashboard.themes.edit';
            case ThematicEditorMode.CREATE_ONLY:
                return 'translations.analysisDashboard.themes.create_only';
            default:
                return 'translations.analysisDashboard.themes.create';
        }
    }

    get btnLabelValid() {
        switch (this.mode) {
            case ThematicEditorMode.UPDATE:
            case ThematicEditorMode.CREATE_ONLY:
                return 'translations.utils.save';
            default:
                return 'translations.utils.confirm';
        }
    }

    @Output()
        save = new EventEmitter<Thematic>();

    constructor(
        private queryValidatorService: QueryValidatorService,
        public modal: NgbActiveModal,
        private store: Store,
        private thematicService: ThematicService,
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.isValid = true;
        this.errorDetails = '';

        this.newValue = {
            name: this.initValue.name,
            definition: [this.initValue.definition[0]],
            lang: this.initValue.lang,
        };

        this.languages = this.store.snapshot().settings.vcrm.analysis_lang;

        if (!this.newValue.lang || this.newValue.lang.trim() === '') {
            this.newValue.lang = this.thematicService.lastUsedlanguageForCreation;
            if (!this.newValue.lang && this.languages.length > 0) {
                const [lang] = this.languages;
                this.newValue.lang = lang;
                this.thematicService.lastUsedlanguageForCreation = this.newValue.lang;
            }
        }

        // A l'ouverture de la popin, si on a une règle on vérifie l'expression directement
        if (this.newValue.definition[0] !== '') {
            await this.checkThemeEntryBoolean();
            // si l'expression n'est pas valide, on affiche le message d'erreur
            if (!this.isValid) {
                this.showValidFeedback = this.isValid;
            }
        }
    }

    get canSave(): boolean {
        const isNotEmpty = this.newValue.name.trim() !== '' && this.newValue.definition[0].trim() !== '';

        const hasChanged = this.initValue.name !== this.newValue.name
            || this.initValue.definition[0] !== this.newValue.definition[0];
        return isNotEmpty && (this.allowSameValue || hasChanged) && this.isValid && this.isThematicNameValid;
    }

    get isThematicNameValid(): boolean {
        return this.newValue.name.search(/\[|\]|>|</g) < 0;
    }

    async addThemeEntry(value) {
        let newValue = value.replace(/["+\\/^~{}[\]]/g, '');
        newValue = newValue.replace(/[-.!]/g, ' ');
        newValue = newValue.trim();

        if (newValue) {
            if (this.newValue.definition[0].trim().length === 0) {
                this.newValue.definition[0] += `"${newValue}"`;
            } else {
                this.newValue.definition[0] += ` OR "${newValue}"`;
            }
            this.value = '';
        }

        this.check();
    }

    async checkThemeEntryBoolean() {
        const validation = await this.queryValidatorService.checkQuery(this.newValue.definition[0]);
        this.isValid = validation.isValid;
        this.errorDetails = validation.errorDetails;
    }

    async check() {
        await this.checkThemeEntryBoolean();
        this.showValidFeedback = this.isValid;
    }

    cancel() {
        this.modal.close();
    }

    async validate() {
        await this.checkThemeEntryBoolean();
        if (this.isValid) {
            this.thematicService.lastUsedlanguageForCreation = this.newValue.lang;
            this.save.emit(this.newValue);
        }
    }
}
