// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 30px;
  width: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
:host.one {
  color: #fff;
  background-color: #000;
}
:host.two {
  background-color: #ccc;
}
:host.three {
  color: #fff;
  background-color: #666;
}
:host.number-sm {
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
