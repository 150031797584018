import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import SharedModule from 'app/shared/shared.module';
import AuthGuardService from 'app/utils/services/authguard.service';
import GdprGuardService from 'app/utils/services/gdprguard.service';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import NewsDetailsComponent from './news-details/news-details.component';
import NewsListComponent from './news-list/news-list.component';
import NewsRoutingModule from './news-routing.module';
import NewsComponent from './news.component';
import NewsModalComponent from './news-modal/news-modal.component';

@NgModule({
    declarations: [
        NewsComponent,
        NewsListComponent,
        NewsDetailsComponent,
        NewsModalComponent,
    ],
    imports: [
        CommonModule,
        NewsRoutingModule,
        TranslateModule.forChild(),
        SharedModule,
        // ng-boosted modules
        NgbDropdownModule,
        NgbPaginationModule,
    ],
    providers: [
        AuthGuardService,
        GdprGuardService,
    ],
    exports: [
        NewsModalComponent,
    ],
})
export default class NewsModule { }
