import { Injectable } from '@angular/core';

/**
 * Global configuration for Api services
 */
@Injectable({
    providedIn: 'root',
})
export default class ApiConfiguration {
    rootUrl: string = '/api';
}
