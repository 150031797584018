import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export default class FooterService {
    public overlay_: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public visible_: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public overlay(value: boolean) {
        // eslint-disable-next-line no-underscore-dangle
        this.overlay_.next(value);
    }

    public visible(value: boolean) {
        // eslint-disable-next-line no-underscore-dangle
        this.visible_.next(value);
    }
}
