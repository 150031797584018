enum ExportType {
    DEFAULT = 'default', // "DEFAULT" is the ZIP export format
    EXCEL = 'excel',
    ORIGINAL = 'original',
    ENRICH = 'enrich',
    ENRICH_EXCEL = 'enrichexcel',
    PNG = 'png',
    CSV = 'csv',
}

export default ExportType;
