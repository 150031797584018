import { Component, OnInit } from '@angular/core';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import CorpusService from 'app/utils/services/corpus.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CorpusService as CorpusApi } from 'app/api/services';
import * as _ from 'lodash';
import { Inputnamevalues } from 'app/api/models';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import FiltersAssociatedDataPopupComponent from '../filters-associated-data/components/filters-associated-data-popup/filters-associated-data-popup.component';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';

function getTonalite(fieldName:string): string {
    if (fieldName.endsWith('pos')) {
        return 'sentiment-positive';
    } if (fieldName.endsWith('neg')) {
        return 'sentiment-negative';
    }
    return 'sentiment-neutral';
}

@Component({
    selector: 'app-filters-opinion-target',
    templateUrl: './filters-opinion-target.component.html',
})
export default class FiltersOpinionTargetComponent extends BaseVerticalFilterComponent implements OnInit {
    constructor(
        private dashboardService: DashboardService,
        private corpusService: CorpusService,
        private modalService: NgbModal,
        private corpusApi: CorpusApi,
    ) {
        super(dashboardService);
    }

    filterActive: boolean = false;

    fieldName: string = 'ote';

    field: any = null;

    fieldPos: any = null;

    fieldNeg: any = null;

    fields : SematicProcessField[] = [];

    ngOnInit(): void {
        super.ngOnInit();

        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                this.field = corpus.corp_fields_not_in_file.find((field) => field.inputName === this.fieldName) || null;
                this.fieldPos = corpus.corp_fields_not_in_file.find((field) => field.inputName === `${this.fieldName}pos`) || null;
                this.fieldNeg = corpus.corp_fields_not_in_file.find((field) => field.inputName === `${this.fieldName}neg` || null);
            }
        });

        this.subs.sink = this.dashboardService.currentFilters.subscribe(
            (filters) => {
                if (filters !== null) {
                    this.fields = this.getOpinionTargetFields(filters);
                }
            },
        );
    }

    get count() {
        return this.filters
            ? this.filters
                .filter((filter) => filter.inputName.startsWith(this.fieldName))
                .reduce((sum, filter) => sum + filter.values.length, 0)
            : 0;
    }

    openFiltersModal() {
        const modal = this.modalService.open(
            FiltersAssociatedDataPopupComponent,
        );
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.corpusApi = this.corpusApi;
        modal.componentInstance.treatmentField = this.fieldName;
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.filter.selectOpinion';
        modal.componentInstance.subtitleToTranslate = 'translations.analysisDashboard.filter.target';
        modal.componentInstance.onSave.subscribe(() => {
            this.collapsed = false;
        });
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'gestion cibles d\'opinion',
        });
    }

    getOpinionTargetFields(filters): SematicProcessField[] {
        const result : SematicProcessField[] = [];

        filters.filters.filter((filter) => filter.inputName.startsWith(this.fieldName)).forEach(
            (filter) => {
                filter.values.forEach((value) => {
                    result.push({
                        fieldName: filter.inputName,
                        value,
                        tonalite: getTonalite(filter.inputName),
                    });
                });
            },
        );

        return _.sortBy(result, ['value', 'tonalite']);
    }

    deleteOneValue(target: SematicProcessField) {
        const matchingName: Array<Inputnamevalues> = this.filters.filter(
            (filter) => filter.inputName === target.fieldName,
        );
        matchingName.forEach((filter) => {
            filter.values = filter.values.filter(
                (filterValue) => filterValue !== target.value,
            );
        });
        this.filters = this.filters.filter(
            (filter) => filter.values.length > 0,
        );
        this.dashboardService.updateFilterList(this.filters);
    }
}

interface SematicProcessField {fieldName: string, value:string, tonalite}
