/* eslint-disable max-classes-per-file */
/**
 * Current corpus
 *
 * @export
 * @class SetCorpus
 */
export class SetCorpus {
    static readonly type = '[CORPUS] set corpus';

    constructor(public payload: any) {}
}

/**
 * clean current corpus
 *
 * @export
 * @class ResetCorpus
 */
export class ResetCorpus {
    static readonly type = '[CORPUS] reset';
}
