import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../api/services';

/**
 * Reset password request
 *
 * @export
 * @class ResetpasswordComponent
 */
@Component({
    selector: 'app-resetpassword',
    templateUrl: './resetpassword.component.html',
})
export default class ResetpasswordComponent {
    public isLoading: boolean = false;

    public login: string;

    public confirm = false;

    public error: boolean = false;

    @ViewChild('resetForm')
        form: NgForm;

    constructor(private user: UserService) {
    }

    /**
   * Called when user submit form to send access
   */
    onSubmit(e) {
        e.preventDefault();
        if (this.form.valid) {
            this.error = false;
            this.isLoading = true;
            this.user.getV1UserUsernameOrEmailLostpassword(this.login).subscribe({
                next: () => {
                    this.confirm = true;
                },
                error: () => {
                    this.error = true;
                    this.isLoading = false;
                },
            });
        } else {
            this.error = true;
        }
    }
}
