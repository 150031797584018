import {
    animate, state, style, transition, trigger,
} from '@angular/animations';
import {
    Component, Input, Output, EventEmitter, OnChanges, SimpleChanges,
} from '@angular/core';
import analytics from '../words.analytics';
import { CloudData } from '../word-cloud.interfaces';

@Component({
    selector: 'app-hidden-words',
    templateUrl: './hidden-words.component.html',
    styleUrls: ['./hidden-words.component.scss'],
    animations: [
        trigger('buttonState', [
            state('disabled', style({
                'background-color': 'white',
                color: '#ddd',
            })),
            state('intermediate', style({
                'background-color': 'black',
                color: 'white',
            })),
            state('enabled', style({
                'background-color': 'white',
                color: 'black',
            })),
            transition('* => disabled', animate('0ms ease-in')),
            transition('* <=> *', animate('100ms ease-in')),
        ]),

    ],
})
export default class HiddenWordsComponent implements OnChanges {
    @Output()
        restore : EventEmitter<CloudData[]> = new EventEmitter<CloudData[]>();

    buttonState: string = 'enabled';

    @Input()
        hiddenWords: CloudData[] = [];

    @Input()
        category: string;

    @Input()
        view: string = 'reduced';

    wordingsAnalytics :any = analytics;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hiddenWords) {
            if (changes.hiddenWords.previousValue && changes.hiddenWords.currentValue.length > changes.hiddenWords.previousValue.length) {
                this.buttonState = 'intermediate';
            } else if (changes.hiddenWords.currentValue.length === 0) {
                this.buttonState = 'disabled';
            }
        }
    }

    buttonStateAnimationDone(event: any) {
        if (event.toState === 'intermediate') {
            this.buttonState = 'enabled';
        }
    }
}
