// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.help {
  overflow: auto;
  max-height: 360px;
  background-color: rgb(238, 238, 238);
  padding: 20px;
  font-size: 14px;
}

h4 {
  font-size: 20px;
}

.form-group label {
  font-size: 14px;
}

input,
select,
textarea {
  font-weight: normal;
}

select {
  padding-top: 4px;
}

.ok-feedback {
  margin-top: 0.8125rem;
  font-weight: 700;
  display: flex;
  width: 100%;
  font-size: 0.875rem;
}

.ok-feedback::before {
  display: inline-block;
  flex-shrink: 0;
  align-self: center;
  width: 0.9375rem;
  height: 0.9375rem;
  margin-right: 0.3125rem;
  content: "";
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 125 125'%3e%3cpath fill='%2332c832' d='M62.5 0a62.5 62.5 0 100 125 62.5 62.5 0 000-125zm28 29.4c3.3 0 6 2.6 6 5.9a5.9 5.9 0 01-1.3 3.7L57.7 86a5.8 5.8 0 01-9.1 0L29.8 62.5c-.8-1-1.2-2.3-1.2-3.7a5.9 5.9 0 011.7-4.1l2.3-2.4a5.8 5.8 0 014.2-1.7 5.8 5.8 0 013.8 1.4L52 64.7 86.6 31a5.8 5.8 0 014-1.6z'/%3e%3c/svg%3e") no-repeat;
}

.form-group {
  margin-bottom: 0.5rem;
}

.clear-input {
  position: absolute;
  top: 35px;
  right: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
