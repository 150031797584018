// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-grid-container {
  display: grid;
  grid-template-columns: 600px auto;
  grid-template-rows: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-areas: "title title" "project-structure project-structure" "project-form project-form";
  background-color: white;
}

.css-format-padding {
  padding-top: 5px;
  padding-left: 10px;
}
.css-format-padding li {
  padding-left: 10px;
}
.css-format-padding .css-form-button-padding {
  margin: 10px;
  padding-left: 30px;
}
.css-format-padding .form-input {
  width: 300px;
}

.css-format-link {
  font-weight: bold;
  color: #f16e00;
}

.css-import-format-tutos {
  font-weight: bold;
  padding: 10px;
}

.columns-params {
  padding-left: 30px;
}

.col-import-file {
  min-width: 500px;
  padding: 0 20px;
  flex-grow: 1;
}

.css-project-form {
  grid-area: project-form;
  max-width: 900px;
}

.css-project-structure {
  grid-area: project-structure;
}

.section-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
