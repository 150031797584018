import { NGXLogger } from 'ngx-logger';
import {
    State, StateContext, Action, Store,
} from '@ngxs/store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
    LogOutUser, SetUser, ResetUser, SetUserCGU,
} from '../../actions/user/user.actions';
import { User } from '../../../api/models/user';
import { UserService } from '../../../api/services';

/**
 * RGXS store for user profil
 *
 * @export
 * @class UserState
 */
@Injectable()
@State<User>({
    name: 'user',
    defaults: {},
})
export default class UserState {
    constructor(
        private router: Router,
        private userService: UserService,
        private store: Store,
        private logger: NGXLogger,
    ) {}

    @Action(SetUser)
    setName({ setState }: StateContext<User>, { payload }: SetUser) {
        this.logger.info('Set user profil', payload);
        const userState = payload;
        setState({
            ...userState,
        });
    }

    @Action(SetUserCGU)
    SetUserCGU({ getState, setState }: StateContext<User>, { payload }: SetUserCGU) {
        this.logger.info('Set user cgu', payload);
        const state = getState();
        state.cgu = payload;
        setState({
            ...state,
        });
    }

    @Action(ResetUser)
    ResetUser({ setState }: StateContext<User>) {
        this.logger.info('Clean user profil');
        const userState = {};
        setState({
            ...userState,
        });
    }

    @Action(LogOutUser)
    LogOutUser() {
        this.logger.info('Logout user');
        this.userService.getV1AuthLogout().subscribe(() => {
            this.store.dispatch(new ResetUser());
            this.router.navigate(['auth', 'login']);
        });
    }
}
