// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host td {
  vertical-align: top;
  /* stylelint-disable-next-line selector-class-pattern */
}
:host td.origin, :host td.score, :host td.associated {
  padding-top: 19px;
}
:host td .icon-Link {
  font-size: 15px;
  line-height: 15px;
  height: 15px;
  width: 15px;
  margin-right: 0;
}

.empty-table {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 35px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
