// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-type-no-unknown */
app-vertical-filters {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #eee;
}

.dashboard-content {
  flex-grow: 1;
  overflow: auto;
  height: calc(100vh - 126px);
}

.moments {
  flex: 1;
  align-items: center;
  justify-content: space-around;
  display: flex;
  padding: 0 15px;
  border-right: 1px solid #fff;
  flex-direction: column;
  font-weight: bold;
  text-align: center;
}

.header-left {
  display: flex;
  width: 440px;
  position: relative;
  flex-direction: row;
}
.header-left .moments {
  background: rgba(255, 255, 255, 0.8);
}

.pad40 {
  display: flex;
  width: 40px;
}

.name {
  flex: 3;
  align-items: center;
  display: flex;
  padding: 0 15px;
  border-right: 1px solid #fff;
}

canvas.pie {
  position: relative;
  z-index: 2;
  height: 170px;
  width: 170px;
  margin: 5px;
}

chart.bar {
  background-image: linear-gradient(#d1d1d1 1px, transparent 1px);
  background-size: 100% 25%;
  box-shadow: inset 0 -1px 0 0 #d1d1d1;
}

.tone-body {
  padding: 20px 15px;
  font-size: 14px;
  background: #fff;
}
.tone-body .tone-header {
  position: relative;
  display: flex;
  flex: 1;
  min-width: 0;
  margin-bottom: -15px;
}
.tone-body .tone-header .tone-header-left {
  display: flex;
  width: 440px;
}
.tone-body .tone-header .tone-header-left .verbatim-count {
  position: relative;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  line-height: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.tone-body .tone-header .tone-header-left .verbatim-count .big {
  font-size: 28px;
  color: #666;
  line-height: 28px;
}
.tone-body .tone-header .tone-header-left .verbatim-count .verbatim-text {
  font-size: 16px;
  font-weight: bold;
  white-space: initial;
}
.tone-body .tone-header .tone-header-left .verbatim-count::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 0 12.5px 15px;
  border-color: transparent transparent #ddd;
}
.tone-body .tone-header::before {
  border-bottom: 4px solid #ddd;
  content: "";
  display: block;
  position: absolute;
  bottom: 26px;
  right: 0;
  left: 0;
}
.tone-body .tone-header .tone-donut-container {
  display: flex;
  padding: 0;
  flex: 1;
}
.tone-body .tone-header .tone-donut-pos,
.tone-body .tone-header .tone-donut-neg {
  display: flex;
  width: 60px;
  position: relative;
  height: 100%;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
}
.tone-body .tone-header .tone-donut-pos::before,
.tone-body .tone-header .tone-donut-neg::before {
  display: block;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 14px;
  position: absolute;
}
.tone-body .tone-header .tone-donut-pos {
  color: #32c832;
}
.tone-body .tone-header .tone-donut-pos::before {
  content: "+";
}
.tone-body .tone-header .tone-donut-neg {
  color: #cd3c14;
}
.tone-body .tone-header .tone-donut-neg::before {
  content: "-";
}
.tone-body .tone-header .tone-donut {
  position: relative;
  width: 180px;
  display: flex;
  height: 180px;
}
.tone-body .tone-header .tone-donut::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 4px solid #ddd;
  border-bottom-color: transparent;
  box-shadow: inset 0 0 0 40px #fff;
}
.tone-body .tone-header .tone-donut ngx-charts-pie-chart {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
}
.tone-body .tone-header .tone-donut .tone-donut-info {
  z-index: 5;
  position: absolute;
  font-size: 10px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tone-body .tone-header .tone-donut .tone-donut-info b {
  text-transform: uppercase;
}
.tone-body .tone-header .tone-donut .tone-donut-warning {
  z-index: 3;
  position: relative;
  font-size: 30px;
  color: #000;
}
.tone-body .tone-header .tone-verbatim-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 0;
  padding-left: 0;
}
.tone-body .tone-header .tone-header-right {
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 33px;
}
.tone-body .tone-header .tone-header-right .tone-verbatim-graph-general {
  flex: 1;
  position: relative;
  min-width: 0;
  display: flex;
}
.tone-body .tone-header .tone-header-right .tone-select-type-input {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.tone-body .tone-header .tone-header-right .tone-select-type-input .graph-mode .dropdown-toggle {
  min-width: 300px;
  max-width: 300px;
  justify-content: space-between;
}
.tone-body .tone-header .tone-header-right .tone-select-type-input .graph-mode .dropdown-menu {
  min-width: 300px;
}
.tone-body .tone-graph-header,
.tone-body .graph-row {
  display: flex;
}
.tone-body .tone-graph-header .header-left,
.tone-body .tone-graph-header .graph-info,
.tone-body .graph-row .header-left,
.tone-body .graph-row .graph-info {
  width: 440px;
}
.tone-body .tone-graph-header .header-right,
.tone-body .graph-row .header-right {
  display: flex;
  width: calc(100% - 440px);
  position: relative;
  background: rgba(255, 255, 255, 0.8);
}
.tone-body .tone-graph-header .header-right .header-row,
.tone-body .graph-row .header-right .header-row {
  padding: 0;
  display: flex;
  flex: 1 100%;
  overflow: hidden;
}
.tone-body .tone-graph-header .header-right .header-row .header-col,
.tone-body .graph-row .header-right .header-row .header-col {
  flex: 1 10;
  margin: 0;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.tone-body .tone-graph-header .header-right .header-row .header-col span,
.tone-body .graph-row .header-right .header-row .header-col span {
  text-transform: uppercase;
}
.tone-body .tone-graph-header .header-right .header-row .header-col i,
.tone-body .graph-row .header-right .header-row .header-col i {
  font-size: 30px;
}
.tone-body .tone-graph-header .header-right .header-row .header-warning,
.tone-body .graph-row .header-right .header-row .header-warning {
  z-index: 3;
  color: #ffd200;
}
.tone-body .graph-row {
  background: #efefef;
  box-shadow: 0 0 0 1px #ddd;
  flex-direction: column;
}
.tone-body .graph-row .content {
  height: 100px;
  display: flex;
}
.tone-body .graph-row .content .graph-info {
  display: flex;
  width: 440px;
}
.tone-body .graph-row .content .graph-info .collapse-btn {
  height: 100%;
  background: #ddd;
  width: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.tone-body .graph-row .content .graph-info .collapse-btn .vcrm-form-chevron-right {
  transform: rotateZ(0deg);
  transition: transform 0.2s ease-in-out;
  /* stylelint-disable-next-line max-nesting-depth */
}
.tone-body .graph-row .content .graph-info .collapse-btn .vcrm-form-chevron-right.rotate {
  transform: rotateZ(90deg);
}
.tone-body .graph-row .content .graph-info .collapse-btn:hover {
  cursor: pointer;
  color: white;
  background: #ff7900;
}
.tone-body .graph-row .content .graph-info:hover .collapse-btn {
  cursor: pointer;
  color: white;
  background: #ff7900;
}
.tone-body .graph-row .graph-container {
  position: relative;
  width: calc(100% - 440px);
}
.tone-body .graph-row .collapse {
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
}
.tone-body .graph-row .collapse.show {
  height: auto;
}
.tone-body .graph-row .subcontent {
  display: flex;
  position: relative;
  height: 100px;
  background: white;
  box-shadow: 0 1px 0 0 #ddd inset;
}
.tone-body .graph-row .subcontent .subinfo {
  display: flex;
  width: 440px;
}
.tone-body .graph-row .subcontent .subinfo .name,
.tone-body .graph-row .subcontent .subinfo .moments {
  border-right-color: #ddd;
}
.tone-body .graph-row .subcontent .subgraph {
  width: calc(100% - 440px);
  position: relative;
}
.tone-body .tone-graph-header {
  position: sticky;
  top: 0;
  z-index: 5;
  margin-bottom: 20px;
}

.no-data {
  position: relative;
  overflow: hidden;
}
.no-data .no-data-content {
  position: absolute;
  inset: 0;
  background-color: rgba(238, 238, 238, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.no-data .no-data-content .text {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
