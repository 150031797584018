import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    LOCAL_STORAGE_ENGINE, NgxsStoragePluginModule, SESSION_STORAGE_ENGINE,
} from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoggerModule } from 'ngx-logger';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import ApiModule from './api/api.module';
import AppComponent from './app.component';
import AuthModule from './modules/auth/auth.module';
import CorpusModule from './modules/corpus/corpus.module';
import ErrorsModule from './modules/errors/errors.module';
import GdprModule from './modules/gdpr/gdpr.module';
import HomeModule from './modules/home/home.module';
import NewProjectModule from './modules/new-project/new-project.module';
import NewsModule from './modules/news/news.module';
import ThematicsModule from './modules/thematics/thematics.module';
import ModalComponent from './shared/components/modal/modal.component';
import SharedModule from './shared/shared.module';
import CorpusState from './stores/state/corpus/corpus.state';
import NewCorpusState from './stores/state/new-corpus/new-corpus.state';
import NewsState from './stores/state/news/news.state';
import SettingsState from './stores/state/settings/settings.state';
import UserState from './stores/state/user/user.state';
import Credential from './utils/classes/credential';
import HttpStatus from './utils/classes/http-status';
import CorpusService from './utils/services/corpus.service';
import RouterTraceService from './utils/services/router-trace.service';
import UserModule from './modules/user/user.module';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');

/**
 * Get translate from API
 *
 * @export
 * @param {HttpClient} http
 * @returns
 */
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ModalComponent,
    ],
    imports: [
        LoggerModule.forRoot({
            level: environment.logLevel,
            disableConsoleLogging: false,
            enableSourceMaps: true,
        }),
        NgxsModule.forRoot([UserState, SettingsState, NewCorpusState, CorpusState, NewsState]),
        NgxsStoragePluginModule.forRoot({
            key: [{
                key: 'user',
                engine: SESSION_STORAGE_ENGINE,
            }, {
                key: 'newcorpus',
                engine: LOCAL_STORAGE_ENGINE,
            }],
        }),
        ApiModule,
        AuthModule,
        RouterModule.forRoot([
            {
                path: '',
                component: AppComponent,
                canActivate: [RouterTraceService],
            },
        ], {}),
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgHttpLoaderModule.forRoot(),
        GdprModule,
        HomeModule,
        NewProjectModule,
        CorpusModule,
        ThematicsModule,
        NewsModule,
        UserModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        SharedModule,
        ErrorsModule,
    ],
    exports: [ThematicsModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Credential,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpStatus,
            multi: true,
        },
        CorpusService,
        RouterTraceService,
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
