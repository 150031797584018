// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
}

.word-text {
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  bottom: 0;
  position: absolute;
}

.word-text-over {
  border-bottom: 2px solid;
}

* .word-and-tooltip {
  position: absolute;
  padding: 0;
}

.word-tooltip-container {
  position: absolute;
  padding: 10px 0;
  cursor: unset;
}

.word-tooltip {
  color: black;
  background-color: white;
  border: 2px solid #ddd;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
}

.arrow {
  position: absolute;
  top: calc(100% - 12px);
}
.arrow::before, .arrow::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  width: 0;
  height: 0;
  transform: translateX(-50%);
}
.arrow::before {
  border: 10px solid transparent;
  border-top-color: #ddd;
}
.arrow::after {
  border: 8px solid transparent;
  border-top-color: white;
}

.word-tootip-hidden {
  left: -1000px !important;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
}

.word-tooltip div {
  white-space: nowrap;
}

.pos {
  color: #32c832;
}

.mit {
  color: #ffb400;
}

.neu {
  color: #666;
}

.neg {
  color: #cd3c14;
}

.word-tooltip-tooltip {
  white-space: normal !important;
  z-index: 99999;
  width: 300px;
}
.word-tooltip-tooltip ul {
  padding-left: 20px;
  margin-bottom: 0;
}
.word-tooltip-tooltip ul li {
  padding-bottom: 5px;
}
.word-tooltip-tooltip ul li::marker {
  color: black;
}

.overlay {
  opacity: 0.2;
}

.clickable {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
