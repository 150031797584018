import {
    Input, ViewChild, Directive, OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import accentless from 'app/utils/functions/accentless';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { VerbatimConfig } from 'app/api/models/verbatimconfig';
import download from 'app/utils/functions/download';
import PluralizePipe from 'app/shared/pipes/pluralize.pipe';
import { CloudData, ResultCounts } from './word-cloud.interfaces';
import WordsCloudComponent from './words-cloud/words-cloud.component';

@Directive()
export default abstract class BaseCloudComponent extends BaseComponent implements OnInit {
    @Input()
        words: any = [];

    cloudWords: CloudData[];

    @Input()
        hiddenWords: CloudData[] = [];

    config: VerbatimConfig;

    @Input()
        availableSizes = [10, 25, 50];

    @Input()
        counts: ResultCounts;

    @ViewChild('tagcloud')
        cloud: WordsCloudComponent;

    @Input()
        searchEnabled: boolean = false;

    translate: TranslateService;

    pluralizePipe: PluralizePipe = new PluralizePipe();

    constructor(
        translate: TranslateService,
    ) {
        super();
        this.translate = translate;
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard !== null) {
                this.config = { ...this.dashboardService.currentConfig.getValue().verbatim };
            }
        });
    }

    abstract get dashboardService(): DashboardService;

    get category() {
        return this.config.cloud_category;
    }

    set category(value) {
        this.config.cloud_category = value;
    }

    get subCategory() {
        return this.config.cloud_sub_category;
    }

    set subCategory(value) {
        this.config.cloud_sub_category = value;
    }

    onChangeCategory({ category, subCategory }) {
        this.category = category;
        this.subCategory = subCategory;
        this.buildWordsList();
    }

    buildWordsList() {
        if (this.subCategory && Object.prototype.hasOwnProperty.call(this.words, this.subCategory)) {
            this.hiddenWords = this.words[this.subCategory].filter((word) => word.hidden);
            this.cloudWords = this.words[this.subCategory].filter((word) => !word.hidden).slice(0, this.selectedSize).map((word) => <CloudData>{
                key: word.key, subCategory: this.subCategory, doc_count: word.doc_count, deltatone: word.deltatone, top_sentiment: word.top_sentiment,
            });
        }
    }

    findWordByKey(key: string) {
        const matchingWords = this.words[this.subCategory].filter((word) => word.key === key);
        return matchingWords.length > 0 ? matchingWords[0] : null;
    }

    hideWord(hidden: CloudData) {
        // eslint-disable-next-line no-param-reassign
        hidden = this.findWordByKey(hidden.key);
        if (hidden) {
            hidden.hidden = true;
        }

        this.buildWordsList();
    }

    restoreWord(words: CloudData[]) {
        words.forEach((word) => {
            // eslint-disable-next-line no-param-reassign
            word = this.findWordByKey(word.key);
            if (word) {
                word.hidden = false;
            }
        });

        this.buildWordsList();
    }

    exportChart() {
        const canvas = this.buildCanvas();
        download(canvas.toDataURL(), `${accentless(this.translate.instant(this.category)).replace(/\s+/g, '_').toLowerCase()}.png`, '_blank');
    }

    // eslint-disable-next-line class-methods-use-this
    getLines(ctx, text, maxWidth): string[] {
        const words = text.split(' ');
        const lines: string[] = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i += 1) {
            const word = words[i];
            const { width } = ctx.measureText(`${currentLine} ${word}`);
            if (width < maxWidth) {
                currentLine += ` ${word}`;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);
        return lines;
    }

    EXPORT_LINE_HEIGHT: number = 20;

    EXPORT_VERTICAL_SPACING: number = 20;

    EXPORT_MARGIN: number = 20;

    EXPORT_FRISE_HEIGHT: number = 30;

    /**
   * The next offset
   */
    drawHeaderInfo(canvas, currentOffset: number): number {
        const context = canvas.getContext('2d');
        const fontFamily = window.getComputedStyle(document.body)['font-family'];
        context.font = `16px ${fontFamily}`;
        context.textBaseline = 'middle';

        const text = `${this.selectedSize
        } ${this.translate.instant(`translations.wordComponent.labels.${this.subCategory}_category`).toLowerCase()
        } ${this.translate.instant(`translations.analysisDashboard.verbatim.${this.category}`)
        }. ${this.translate.instant('translations.analysisDashboard.cloud.count_generate')
        } ${this.searchEnabled ? this.pluralizePipe.transform(this.translate.instant('translations.analysisDashboard.cloud.count_find', this.counts), this.counts.count) : ''
        } ${this.pluralizePipe.transform(this.translate.instant('translations.analysisDashboard.cloud.count_filtered', this.counts), this.counts.filtered)
        } / ${this.pluralizePipe.transform(this.translate.instant('translations.analysisDashboard.cloud.count_total', this.counts), this.counts.total)}`;

        const lines: string[] = this.getLines(context, text, canvas.width - 2 * this.EXPORT_MARGIN);

        context.fillStyle = '#000000';
        context.textAlign = 'left';

        lines.forEach((line: string, index: number) => {
            context.fillText(line, this.EXPORT_MARGIN, currentOffset);
            if (index < lines.length - 1) {
                // eslint-disable-next-line no-param-reassign
                currentOffset += this.EXPORT_LINE_HEIGHT;
            }
        });

        return currentOffset;
    }

    abstract get selectedSize();

    // eslint-disable-next-line class-methods-use-this
    drawBackground(canvas) {
        const context = canvas.getContext('2d');
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
    }

    drawCloud(canvas, currentOffset: number): number {
    // nuage de mots
        const context = canvas.getContext('2d');
        const fontFamily = window.getComputedStyle(document.body)['font-family'];
        context.textBaseline = 'top';

        for (let i = 0; i < this.cloudWords.length; i += 1) {
            context.font = `${this.cloudWords[i].fontSize}px ${fontFamily}`;
            context.fillStyle = this.cloudWords[i].color;
            context.fillText(this.cloudWords[i].key, this.cloudWords[i].left + this.EXPORT_MARGIN, this.cloudWords[i].top + currentOffset);
        }

        return currentOffset + this.cloud.wordContainer.nativeElement.offsetHeight;
    }

    drawFrise(canvas, currentOffset: number): number {
        const context = canvas.getContext('2d');
        // frise
        if (this.cloud.showHorizontalAxe && this.cloud.useDeltaTone) {
            // Create gradient
            const grd = context.createLinearGradient(0, 0, canvas.width, this.EXPORT_FRISE_HEIGHT);
            this.cloud.gradient.forEach((color) => {
                grd.addColorStop(color[3], `rgb(${color[0]},${color[1]},${color[2]})`);
            });

            // Fill with gradient
            context.fillStyle = grd;
            context.fillRect(this.EXPORT_MARGIN, currentOffset, this.cloud.wordContainer.nativeElement.offsetWidth, this.EXPORT_FRISE_HEIGHT);

            const fontFamily = window.getComputedStyle(document.body)['font-family'];
            context.font = `16px ${fontFamily}`;
            context.textBaseline = 'middle';

            context.fillStyle = '#FFFFFF';
            context.textAlign = 'left';
            context.fillText(_.capitalize(this.translate.instant('translations.analysisDashboard.cloud.neg')), 10 + this.EXPORT_MARGIN, currentOffset + this.EXPORT_FRISE_HEIGHT / 2);

            context.fillStyle = '#FFFFFF';
            context.textAlign = 'center';
            context.fillText(_.capitalize(this.translate.instant('translations.analysisDashboard.cloud.neu')), 10 + canvas.width / 2, currentOffset + this.EXPORT_FRISE_HEIGHT / 2);

            context.fillStyle = '#000000';
            context.textAlign = 'right';
            context.fillText(_.capitalize(this.translate.instant('translations.analysisDashboard.cloud.pos')), canvas.width - 10 - this.EXPORT_MARGIN, currentOffset + this.EXPORT_FRISE_HEIGHT / 2);
        }
        return currentOffset + this.EXPORT_FRISE_HEIGHT;
    }

    drawVerticalSpacing(currentOffset: number): number {
        return currentOffset + this.EXPORT_VERTICAL_SPACING;
    }

    drawVerticalMargin(currentOffset: number = 0): number {
        return currentOffset + this.EXPORT_MARGIN;
    }

    // eslint-disable-next-line class-methods-use-this
    cropCanvas(sourceCanvas, newHeight) {
        const destCanvas = document.createElement('canvas');
        destCanvas.width = sourceCanvas.width;
        destCanvas.height = newHeight;
        destCanvas.getContext('2d').drawImage(
            sourceCanvas,
            0,
            0,
            destCanvas.width,
            newHeight, // source rect with content to crop
            0,
            0,
            destCanvas.width,
            newHeight,
        ); // newCanvas, same size as source rect
        return destCanvas;
    }

    /**
     * @param tagCloud Inspiré de la librairie html2canvas qui ne semble pas gérer les div en position absolute correctement. J'ai donc du refaire une mini impémentation très spécifique
     */
    buildCanvas() {
        let canvas = document.createElement('canvas');
        canvas.width = this.cloud.wordContainer.nativeElement.offsetWidth + 2 * this.EXPORT_MARGIN;
        canvas.height = 9000;

        this.drawBackground(canvas);
        let currentOffset = this.drawVerticalMargin();
        currentOffset = this.drawHeaderInfo(canvas, currentOffset);
        currentOffset = this.drawVerticalSpacing(currentOffset);
        currentOffset = this.drawCloud(canvas, currentOffset);
        if (this.cloud.showHorizontalAxe && this.cloud.useDeltaTone) {
            currentOffset = this.drawVerticalSpacing(currentOffset);
            currentOffset = this.drawFrise(canvas, currentOffset);
        }
        currentOffset = this.drawVerticalMargin(currentOffset);

        canvas = this.cropCanvas(canvas, currentOffset);

        return canvas;
    }
}
