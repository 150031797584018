import SharedModule from 'app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import GdprRoutingModule from './gdpr-routing.module';
import GdprComponent from './gdpr/gdpr.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        GdprRoutingModule,
        TranslateModule.forChild(),
    ],
    declarations: [GdprComponent],
})
export default class GdprModule {}
