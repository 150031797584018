import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CopcConfig } from 'app/api/models/copcconfig';
import { CopcIndicators } from 'app/api/models/copcdata';
import { Period } from 'app/api/models/distriparams';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { getColor } from 'app/utils/models/colors.models';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import CopcLineChartSettingsPopupComponent from '../copc-line-chart-settings-popup/copc-line-chart-settings-popup.component';

@Component({
    selector: 'app-copc-line-chart',
    templateUrl: './copc-line-chart.component.html',
    styleUrls: ['./copc-line-chart.component.scss'],
})
export default class CopcLineChartComponent implements OnChanges {
    constructor(
        private graphService: GraphService,
        private dashboardService: DashboardService,
        private translateService: TranslateService,
        private modalService: NgbModal,
        private chartTooltips: ChartTooltipsService,
    ) {

    }

    chartData = { labels: [], datasets: [] };

    options = {};

    @Input()
    private type: string;

    @Input()
    private data: CopcIndicators;

    @Output()
        configChanged: EventEmitter<void> = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.updateData(changes.data.currentValue);
        }
    }

    updateData(data: CopcIndicators) {
        if (!data) {
            return;
        }

        let dateBeginGoal: number;

        const below = data?.goal?.sign === 'below' ? '#B8EBD6' : '#FFB4E6';
        const above = data?.goal?.sign === 'below' ? '#FFB4E6' : '#B8EBD6';

        if (data?.goal?.datebegin) {
            dateBeginGoal = moment(data.goal.datebegin, 'YYYY-MM-DD').unix() * 1000;
        }

        const dataBefore = [];
        const dataAfter = [];

        const goalDataSet = {
            disableTooltip: true,
            borderColor: 'rgb(255, 121, 0)',
            pointBackgroundColor: 'rgb(255, 121, 0)',
            borderWidth: 3,
            pointRadius: 0,
            pointHoverRadius: 0,
            data: [],
        };

        data.distribution.forEach((value) => {
            const x = this.timeToLabel(value.key);
            let y = value[data.default] !== null ? Math.round(value[data.default] * 10) / 10 : null;
            y = (Object.is(y, -0)) ? 0 : y;
            const point = { x, y };

            if (dateBeginGoal && (dateBeginGoal >= +value.key || this.timeToLabel(dateBeginGoal, true) === this.timeToLabel(value.key, true))) {
                dataBefore.push(point);

                if (data?.goal?.goalprev) {
                    goalDataSet.data.push({ x, y: data.goal.goalprev });
                }
            }

            if (!dateBeginGoal || dateBeginGoal <= +value.key || this.timeToLabel(dateBeginGoal, true) === this.timeToLabel(value.key, true)) {
                if (dataAfter.length === 0 && dataBefore.length !== 0) {
                    dataBefore.push(point);
                    goalDataSet.data.push({ x, y: data.goal.goalprev });
                }

                dataAfter.push(point);

                if (data?.goal?.value) {
                    goalDataSet.data.push({ x, y: data.goal.value });
                }
            }
        });

        const datasets = [];

        if (dataAfter.length > 0) {
            const dataset = {
                // label: `${displayName}`,
                borderColor: getColor(0),
                pointBackgroundColor: getColor(0),
                pointRadius: 5,
                data: dataAfter,
                tension: 0.4,
                fill: undefined,

            };

            if (data?.goal && !(data?.goal instanceof Array)) {
                dataset.fill = { above, below, target: { value: data?.goal.value } };
            } else {
                dataset.fill = false;
            }

            datasets.push(dataset);
        }

        if (dataBefore.length > 0) {
            const dataset = {
                // label: `${displayName}`,
                borderColor: getColor(0),
                pointBackgroundColor: getColor(0),
                pointRadius: 5,
                data: dataBefore,
                tension: 0.4,
                fill: {
                    above, below, target: { value: data?.goal.goalprev },
                },
            };

            datasets.push(dataset);
        }

        datasets.push(goalDataSet);

        this.chartData = {
            labels: this.getGraphLabels(data),
            datasets,
        };

        this.options = this.getGraphOptions();
    }

    /**
     * Calcul du range optimal
     */
    // eslint-disable-next-line class-methods-use-this
    calcRange(minRange, maxRange, minData, maxData, zoomP) {
        const toAdd = _.max([0, zoomP * (maxRange - minRange) - (maxData - minData)]);
        let maxY; let
            minY;
        if (toAdd === 0) {
            maxY = maxData;
        } else {
            maxY = _.min([maxRange, maxData + 0.5 * toAdd]);
            minY = _.max([minRange, minData - 0.5 * toAdd]);

            if (maxY === maxRange) {
                minY = maxRange - zoomP * (maxRange - minRange);
            } else if (minY === minRange) {
                maxY = minRange + zoomP * (maxRange - minRange);
            }
        }

        return { minY: _.round(minY, 1), maxY: _.round(maxY, 1) };
    }

    getGraphOptions() {
        const thisInternal = this;

        // récupération de tous les points du graphiques (y compris ceux de la couble cible)
        const allValues = _.unionBy(_.flatten(this.chartData.datasets.map((dataset) => dataset.data)), (p) => p.y).map((p) => +p.y);

        // calcul de la valeur min et max des points du graphique
        const minData = _.min(allValues);
        const maxData = _.max(allValues);

        // calcul du range min et max (1 -> 10 ou 0 -> 100)
        const minRange = this.data?.default === 'rate' ? 1 : 0;
        const maxRange = this.data?.default === 'rate' ? 10 : 100;

        // calcul du range optimal
        const range = this.calcRange(minRange, maxRange, minData, maxData, 0.3);

        const options = {
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        source: 'labels',
                        color: '#000',
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    min: range.minY,
                    max: range.maxY,
                    ticks: {
                        beginAtZero: true,
                        color: '#000',
                        callback(value) {
                            return value;
                        },
                    },
                },
            },
            clip: false,
            responsive: true,
            maintainAspectRatio: true,
            animation: false,
            aspectRatio: 2,
            plugins: {
                legend: {
                    display: false,
                },
                filler: {
                    propagate: true,
                },
                tooltip: {
                    enabled: false,
                    external(context) {
                        let innerHtml = '';
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.dataPoints) {
                            const datapoint = tooltipModel.dataPoints[0];
                            if (datapoint.dataset.disableTooltip) {
                                return null;
                            }
                            // eslint-disable-next-line max-len
                            innerHtml += `<div class='text-truncate'><span class='tooltip-legend' style='background-color:${datapoint.dataset.pointBackgroundColor}'></span>${thisInternal.translateService.instant(`translations.analysisDashboard.satisfaction.copc.${thisInternal.type}`)}: ${datapoint.dataset.data[datapoint.dataIndex].y}${thisInternal.data.default === 'percent' ? '%' : ''}</div>`;
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        thisInternal.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
                    },
                },
            },

        };

        if (this.data?.default === 'percent') {
            options.scales.y.ticks.callback = (value) => `${value}%`;
        }

        return options;
    }

    getGraphLabels(data) {
        const labels = [];

        data.distribution.forEach((value) => {
            labels.push(this.timeToLabel(value.key));
        });

        return labels;
    }

    timeToLabel(key, comparableWeek: boolean = false): string {
        const period = this.graphService.getDefaultPeriod(this.dashboardService.currentConfig.getValue().copc.period, this.dashboardService);
        const format = (comparableWeek && period === Period.week) ? 'WW' : this.graphService.getPeriodFormat(period);
        return moment.unix(parseInt(key, 10) / 1000).format(format);
    }

    exportGraphImage(chart) {
        this.graphService.exportChartToPng(chart, this.translateService.instant('translations.analysisDashboard.satisfaction.copc.evolution', { type: this.translateService.instant(`translations.analysisDashboard.satisfaction.copc.${this.type}`) }));
    }

    openSettings() {
        const modal = this.modalService.open(CopcLineChartSettingsPopupComponent);
        modal.componentInstance.params = this.dashboardService.currentConfig.getValue().copc;
        modal.componentInstance.type = this.type;
        modal.componentInstance.graphService = this.graphService;
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.save.subscribe((params: CopcConfig) => {
            this.dashboardService.setCopcConfig(params);
            this.configChanged.emit();
        });
    }
}
