import {
    ChangeDetectorRef, Component, Input, OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataCounted } from 'app/api/models';
import { Yaxis } from 'app/api/models/histoparams';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { DistributionDetails } from '../model';

@Component({
    selector: 'app-distribution-details-merged-popup',
    templateUrl: './distribution-details-merged-popup.component.html',
    styleUrls: ['./distribution-details-merged-popup.component.scss'],
})
export default class DistributionDetailsmergedPopupComponent {
    constructor(
        public modal: NgbActiveModal,
    ) { }

    data: DistributionDetails;

    graphService: GraphService;

    chartTooltips: ChartTooltipsService;

    types = [
        {
            key: 'deltasat',
            avg: 'sat_avg',
            distrib: 'sat_distrib',
        },
        {
            key: 'nps',
            avg: 'nps_avg',
            distrib: 'nps_distrib',
        },
        {
            key: 'deltatone',
            avg: 'sentiment_avg',
            distrib: 'sentiment',
        },
    ];

    @Input()
        unit: Yaxis;

    @Input()
        configToValues;

    get modalTitle() {
        return [Yaxis.occur].includes(this.unit) ? 'count' : 'percent';
    }

    getModalTitleValue(value) {
        return Math.round(parseFloat(value[this.configToValues[this.unit]]) * 10) / 10;
    }

    getBarChartData(value, type) {
        return {
            average: value[type.avg],
            values: value[type.distrib],
        };
    }

    hasGraphData(type) {
        return this.data.values.some((value) => value[type.distrib]);
    }

    hasAverageData(type) {
        return this.data.values.some((value) => value[type.avg]);
    }
}
