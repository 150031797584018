import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService as DashboardApi } from 'app/api/services';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComparisonMode, DashboardConfig, LineChartParamsConfig } from 'app/api/models/dashboard-config';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { ChartData } from 'chart.js';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { addDashboardNumberToAxis } from 'app/utils/chartjs-plugins/add-dashboard-number-to-axis';
import { addDashboardNumberToLineChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-line-chart';
import LineChartSettingsPopupComponent from '../graphs-generic-components/line-chart-settings-popup/line-chart-settings-popup.component';
import { DataCountPeriodMerged } from '../../../../../api/models/datacountperiod';
import { settingsPopupAnalytics } from '../distribution-graphs/distribution-graphs.component';
import AbstractLineChartComponent from '../graphs-generic-components/abstract-line-chart-component';

@Component({
    selector: 'app-global-charts',
    templateUrl: './global-charts.component.html',
})
export default class GlobalChartsComponent extends AbstractLineChartComponent implements OnInit {
    @Input()
        dashboardService: DashboardService;

    params: LineChartParamsConfig;

    chartData: ChartData;

    options;

    plugins = [addDashboardNumberToLineChart, addDashboardNumberToAxis];

    get hasClassification() {
        return !!this.manageDashboardService.getCurrentModel();
    }

    get hasData() {
        if (this.dashboardService) {
            return this.dashboardService.dashboardData.getValue()?.hits.total !== 0;
        }
        return this.manageDashboardService.dashboardServices.some((dashboardService) => dashboardService.dashboardData.getValue()?.hits.total !== 0);
    }

    get currentDashboardConfig(): DashboardConfig {
        return (this.dashboardService ? this.dashboardService.currentConfig.getValue() : this.manageDashboardService.currentDashboardComparisonConfig.getValue());
    }

    get comparisonMode() {
        return this.dashboardService ? ComparisonMode.side_by_side : ComparisonMode.merged;
    }

    get isMerged() {
        return !this.dashboardService;
    }

    get isComponentVisible() {
        return !this.hasClassification;
    }

    constructor(
        private dashboardApi: DashboardApi,
        private modalService: NgbModal,
        private translate: TranslateService,
        public graphService: GraphService,
        public chartTooltips: ChartTooltipsService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super(chartTooltips, translate);
    }

    ngOnInit() {
        // Lorsque les dashboards sont chargés
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(() => {
            this.checkAndLoadData();
        });
        // Lorsque l'on ajoute un dashboard ou que l'on en supprime un
        this.subs.sink = this.manageDashboardService.dashboardListChanged.subscribe(() => {
            this.checkAndLoadData();
        });
        // Lorsque les filtres sont mis à jour
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe(() => {
            this.checkAndLoadData();
        });
        // Lorsque l'on change de mode d'affichage (pour la comparaison mode Cote à Cote ou Fusionné)
        this.subs.sink = this.manageDashboardService.comparisonSwitchMode.subscribe(({ section }) => {
            if (section === 'evoTemporel') {
                this.checkAndLoadData();
            }
        });
    }

    getGraphService() {
        return this.graphService;
    }

    getDashboardService() {
        return this.dashboardService;
    }

    /**
     * Vérifie d'abord puis charge les données de ce composant si c'est ok
     */
    checkAndLoadData() {
        const comparisonConfig = this.manageDashboardService.currentDashboardComparisonConfig.getValue();
        // Si on N'est PAS en mode comparaison
        // OU
        // si la config de ce composant correspond à la config de la comparaison affichée
        if (this.isComponentVisible && (!comparisonConfig || comparisonConfig.comparison.evoTemporel === this.comparisonMode)) {
            this.loadData();
        }
    }

    /**
     * Effectue l'appel à l'API pour obtenir les données
     */
    loadData() {
        this.params = this.currentDashboardConfig.evoTemp;

        const firstDashboardId = this.manageDashboardService.dashboardServices[0].currentDashboard.getValue().dash_id;
        const childrenFilters = this.dashboardService
            ? [_.cloneDeep(this.dashboardService.lastAppliedFilters.getValue())]
            : this.manageDashboardService.dashboardServices.map((service) => _.cloneDeep(service.lastAppliedFilters.getValue()));

        this.dashboardApi.getDistributionMergePeriod(
            firstDashboardId,
            this.graphService.getDefaultPeriod(this.params.period, this.dashboardService),
            { searchparams: childrenFilters },
        ).subscribe((data) => {
            this.generateChart(data);
        });
    }

    /**
     * Construit le graphique (data, options)
     */
    generateChart(data: DataCountPeriodMerged) {
        if (data.merged_values === null) {
            return;
        }

        this.distribution2Axis({ values: [{ merged_values: data.merged_values }] });
        this.options = this.generateChartOptions();

        this.chartData = {
            labels: this.labels,
            datasets: this.generateChartDataset(data),
        };
    }

    /**
     * Construit les options du graphique
     */
    generateChartOptions() {
        const thisInternal = this;
        const options = {
            scales: {
                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#000',
                        callback: (value: number) => ((value % 1) === 0 ? value : null),
                    },
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            spanGaps: false,
            animation: false,
            layout: {
                padding: {
                    top: 20,
                },
            },
            plugins: {
                addDashboardNumberToLineChart: this.isMerged,
                addDashboardNumberToAxis: this.isMerged,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.customTooltips(),
                },
            },
        };

        for (let dashboardNumber = 0; dashboardNumber < this.manageDashboardService.dashboardServices.length; dashboardNumber += 1) {
            const axis = this.axis[`dashboard_${dashboardNumber}`];
            if (axis && !options.scales[axis]) {
                options.scales[axis] = {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: true,
                        source: 'labels',
                        color: '#000',
                        callback(value) {
                            return thisInternal.labels[value][`dashboard_${dashboardNumber}`];
                        },
                    },
                };
            }
        }
        return options;
    }

    customTooltips() {
        const thisInt = this;
        // eslint-disable-next-line func-names

        return function (context) {
            return (thisInt.isMerged ? thisInt.customTooltipsMerged : thisInt.customTooltipsSideBySyde)(context, thisInt);
        };
    }

    /**
     * Construit les doonées du graphiques
     */
    generateChartDataset(data: DataCountPeriodMerged) {
        const datasets = [];

        data.merged_values.forEach((dashboardValue, dashboardNumber) => {
            let color = this.manageDashboardService.getDataColor('global-chart');
            if (this.isMerged) {
                if (dashboardNumber === 0) {
                    color = '#000';
                } else if (dashboardNumber === 1) {
                    color = '#ccc';
                } else if (dashboardNumber === 2) {
                    color = '#666';
                }
            }

            const dataset = {
                dashboardNumber,
                xAxisID: this.axis[`dashboard_${dashboardNumber}`],
                label: '',
                borderColor: color,
                pointBackgroundColor: color,
                pointRadius: 5,
                fill: false,
                data: dashboardValue.distribution.map((value) => value.doc_count * 1),
                tension: 0.4,
            };

            datasets.push(dataset);
        });
        return datasets.filter((dataset) => dataset.xAxisID);
    }

    /**
     * Méthode appelée lors de l'ouverture de la popin de configuration du graphique
     */
    openSettings() {
        const modal = this.modalService.open(LineChartSettingsPopupComponent);
        modal.componentInstance.initialParams = this.params;
        modal.componentInstance.gtmTrackCategory = settingsPopupAnalytics.global.track_category;
        modal.componentInstance.partialGtmTrackName = settingsPopupAnalytics.global.partial_track_name;
        modal.componentInstance.graphService = this.graphService;
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.save.subscribe((params: LineChartParamsConfig) => {
            this.params = _.cloneDeep(params);

            if (!this.dashboardService) {
                this.manageDashboardService.setComparisonDashbardConfig('evoTemp', this.params);
            } else {
                this.dashboardService.setEvoTempConfig(this.params);
            }

            this.loadData();
        });
    }

    /**
     * Méthode appelée lors du click sur le bouton "Exporter"
     */
    exportGrpahImage(t): void {
        gtmClick({
            track_category: settingsPopupAnalytics.global.track_category,
            track_name: `vue temporelle ${settingsPopupAnalytics.global.partial_track_name} export png`,
        });
        this.graphService.exportCanvasToPng(t, this.translate.instant('translations.analysisDashboard.charts.time'));
    }

    getLineChartConfig() {
        return this.params;
    }

    // eslint-disable-next-line class-methods-use-this
    isPointClickable(): boolean {
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    getPointValue(data: any): string {
        return data;
    }
}
