// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.dashboard-name-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 15px;
  padding: 10px 0;
  border-bottom: 2px solid #ccc;
}
.dashboard-name-item .form-control {
  margin-right: 5px;
}
.dashboard-name-item .form-control.two-dash {
  max-width: 660px;
}
.dashboard-name-item .form-control.three-dash {
  max-width: 380px;
}
.dashboard-name-item .vcrm-trash::before {
  font-size: 20px;
  line-height: 16px;
}
.dashboard-name-item .dropdown-dashboards {
  margin-left: auto;
}
.dashboard-name-item .dropdown-dashboards .icon-add-more::before {
  line-height: 12px;
  font-size: 22px;
  top: 3px;
  position: relative;
}
.dashboard-name-item .dropdown-dashboards .dropdown-toggle::after {
  display: none;
}
.dashboard-name-item .dropdown-dashboards .dropdown-menu {
  width: 495px;
  padding: 10px;
  max-height: 380px;
  overflow: auto;
}

.versus {
  font-weight: bold;
  font-size: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
