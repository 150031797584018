import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ResetDashModified, SetIsDiscoveryInProgress } from '../../stores/actions/settings/settings.actions';
/**
 * Check if user can quit new corpus proccess
 *
 * @export
 * @class QuitDbAnalysisService
 */
@Injectable()
export default class QuitDbAnalysisService {
    private modalRef: NgbModalRef;

    /**
   *Creates an instance of QuitDbAnalysisService.
   * @param {Store} store
   * @memberof QuitDbAnalysisService
   */
    constructor(
        private store: Store,
        private translate: TranslateService,
        private modalService: NgbModal,
    ) {}

    /**
   * Check db analysis is modified
   *
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof QuitDbAnalysisService
   */
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        // Si le dashboard n'a pas été modifié ET que la découverte (via le chatbot) n'a pas été commencé, on peut quitter la page
        if (!this.store.snapshot().settings.dashboardsModifications?.some((dashboard) => dashboard.isDashModified) && !this.store.snapshot().settings.isDiscoveryInProgress) {
            return true;
        }

        // Sinon on affiche une modal pour prévénir
        this.modalRef = this.modalService.open(ModalComponent, {
            backdrop: 'static',
            keyboard: false,
        });

        this.modalRef.componentInstance.btnHeaderClose = false;
        this.modalRef.componentInstance.titleToTranslate = 'translations.utils.warning';
        const modalNoSave = this.translate.instant('translations.analysisDashboard.modals.nosave');
        this.modalRef.componentInstance.content = `<p>${modalNoSave} ${this.translate.instant('translations.analysisDashboard.modals.nosaveinfo')}</p>`;
        this.modalRef.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        this.modalRef.componentInstance.btnValidToTranslate = 'translations.utils.nosave';
        this.modalRef.componentInstance.btnValidVariant = 'danger';

        this.modalRef.componentInstance.status.subscribe((s) => {
            if (s) {
                this.store.dispatch(new ResetDashModified());
                this.store.dispatch(new SetIsDiscoveryInProgress(false));
            }
            this.modalRef.close();
        });

        return this.modalRef.componentInstance.status;
    }
}
