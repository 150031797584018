import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseComponent } from 'app/base.component';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import { SetIsChatbotBtnHover } from 'app/stores/actions/settings/settings.actions';

@Component({
    selector: 'app-toggle-chat-dinootoo',
    templateUrl: './toggle-chat-dinootoo.component.html',
    styleUrls: ['./toggle-chat-dinootoo.component.scss'],
})
export default class ToggleChatDinootooComponent extends BaseComponent {
    public isChatButtonHover: boolean;

    get showToggleBtn() {
        return !this.chatService.isChatDisplay;
    }

    constructor(
        private store: Store,
        public chatService: ChatService,
    ) {
        super();
        // Détermine si on doit afficher le texte du button de chat ou non
        this.isChatButtonHover = this.store.snapshot().settings.isChatbotBtnHover;
    }

    /**
     * Evènement lorsque l'on clique sur le bouton "Explorer avec Dinootoo" pour ouvrir le chat bot
     */
    public async onOpenChat() {
        if (this.isChatButtonHover) {
            // Enregistre le fait que l'on ne doit plus à l'avenir affiché le texte du bouton (seulement à la 1ère ouverture du chat)
            this.isChatButtonHover = false;
            this.store.dispatch(new SetIsChatbotBtnHover(this.isChatButtonHover));
        }
        this.chatService.isChatDisplay = true;
        this.chatService.openChatDinootoo.emit();
    }
}
