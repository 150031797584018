/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import {
    HttpClient, HttpHeaders, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable as __Observable } from 'rxjs';
import { filter as __filter, map as __map } from 'rxjs/operators';
import ApiConfiguration from '../api-configuration';
import BaseService from '../base-service';
import { Filecontent } from '../models/filecontent';
import { Files } from '../models/files';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';

/**
 * Files management
 */
@Injectable({
    providedIn: 'root',
})
class FileService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
   * Upload a datafile for a corpus on the VisualCRM server
   */
    postV1FileUploadResponse(): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/file/upload`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Upload a datafile for a corpus on the VisualCRM server
   */
    postV1FileUpload(): __Observable<null> {
        return this.postV1FileUploadResponse().pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Upload a datafile for a corpus on the VisualCRM server
   * @param file undefined
   */
    postV1FileUploadSmallResponse(file: Blob): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __headers.append('Content-Type', 'multipart/form-data');
        const __formData = new FormData();
        __body = __formData;
        if (file !== null && typeof file !== 'undefined') { __formData.append('file', file as string | Blob); }
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/file/upload/small`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Upload a datafile for a corpus on the VisualCRM server
   * @param file undefined
   */
    postV1FileUploadSmall(file: Blob): __Observable<null> {
        return this.postV1FileUploadSmallResponse(file).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Get the list of uploaded files available for treatment for the current user
   * @return OK
   */
    getV1FileResponse(): __Observable<__StrictHttpResponse<Files>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/file`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Files>),
        );
    }

    /**
   * Get the list of uploaded files available for treatment for the current user
   * @return OK
   */
    getV1File(): __Observable<Files> {
        return this.getV1FileResponse().pipe(
            __map(({ body }) => body as Files),
        );
    }

    /**
   * Delete all uploaded files available for treatment for the current user
   */
    deleteV1FileResponse(): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/file`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete all uploaded files available for treatment for the current user
   */
    deleteV1File(): __Observable<null> {
        return this.deleteV1FileResponse().pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Delete a file and associated data
   * @param params The `FileService.DeleteV1FileFilenameIdFileidParams` containing the following parameters:
   *
   * - `filename`: File name
   *
   * - `fileid`: File id
   */
    deleteV1FileFilenameIdFileidResponse(params: FileService.DeleteV1FileFilenameIdFileidParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'DELETE',
            `${this.rootUrl}/v1/file/${encodeURIComponent(params.filename)}/id/${params.fileid}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Delete a file and associated data
   * @param params The `FileService.DeleteV1FileFilenameIdFileidParams` containing the following parameters:
   *
   * - `filename`: File name
   *
   * - `fileid`: File id
   */
    deleteV1FileFilenameIdFileid(params: FileService.DeleteV1FileFilenameIdFileidParams): __Observable<null> {
        return this.deleteV1FileFilenameIdFileidResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Get the first lines of a file
   * @param params The `FileService.GetV1FileFilenameIdFileidStartDelimiterParams` containing the following parameters:
   *
   * - `filename`: File name
   *
   * - `fileid`: File id
   *
   * - `delimiter`: File delimiter
   *
   * @return OK
   */
    getV1FileFilenameIdFileidStartDelimiterResponse(params: FileService.GetV1FileFilenameIdFileidStartDelimiterParams): __Observable<__StrictHttpResponse<Filecontent>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/file/${encodeURIComponent(params.filename)}/id/${params.fileid}/start/${params.delimiter}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Filecontent>),
        );
    }

    /**
   * Get the first lines of a file
   * @param params The `FileService.GetV1FileFilenameIdFileidStartDelimiterParams` containing the following parameters:
   *
   * - `filename`: File name
   *
   * - `fileid`: File id
   *
   * - `delimiter`: File delimiter
   *
   * @return OK
   */
    getV1FileFilenameIdFileidStartDelimiter(params: FileService.GetV1FileFilenameIdFileidStartDelimiterParams): __Observable<Filecontent> {
        return this.getV1FileFilenameIdFileidStartDelimiterResponse(params).pipe(
            __map(({ body }) => body as Filecontent),
        );
    }

    /**
   * Get date format for the field date of a file
   * @param params The `FileService.GetV1FileFilenameFormatFieldnameParams` containing the following parameters:
   *
   * - `filename`: File name
   *
   * - `fieldname`: Field name
   */
    getV1FileFilenameFormatFieldnameResponse(params: FileService.GetV1FileFilenameFormatFieldnameParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/file/${encodeURIComponent(params.filename)}/format/${encodeURIComponent(params.fieldname)}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Get date format for the field date of a file
   * @param params The `FileService.GetV1FileFilenameFormatFieldnameParams` containing the following parameters:
   *
   * - `filename`: File name
   *
   * - `fieldname`: Field name
   */
    getV1FileFilenameFormatFieldname(params: FileService.GetV1FileFilenameFormatFieldnameParams): __Observable<null> {
        return this.getV1FileFilenameFormatFieldnameResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Download a data file included in a corpus from the VisualCRM server (original or enrich file)
   * @param params The `FileService.GetV1FileFileIdDownloadTypeParams` containing the following parameters:
   *
   * - `type`: type of file : original or enrich
   *
   * - `fileId`: file id
   */
    getV1FileFileIdDownloadTypeResponse(params: FileService.GetV1FileFileIdDownloadTypeParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/file/${params.fileId}/download/${params.type}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Download a data file included in a corpus from the VisualCRM server (original or enrich file)
   * @param params The `FileService.GetV1FileFileIdDownloadTypeParams` containing the following parameters:
   *
   * - `type`: type of file : original or enrich
   *
   * - `fileId`: file id
   */
    getV1FileFileIdDownloadType(params: FileService.GetV1FileFileIdDownloadTypeParams): __Observable<null> {
        return this.getV1FileFileIdDownloadTypeResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Ask for a new analysis of a file which is in the state ANALYSE_NEEDED
   * @param fileId file id
   */
    getV1FileFileIdAnalyseResponse(fileId: number): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/file/${fileId}/analyse`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Ask for a new analysis of a file which is in the state ANALYSE_NEEDED
   * @param fileId file id
   */
    getV1FileFileIdAnalyse(fileId: number): __Observable<null> {
        return this.getV1FileFileIdAnalyseResponse(fileId).pipe(
            __map(({ body }) => body as null),
        );
    }
}

module FileService {

    /**
   * Parameters for deleteV1FileFilenameIdFileid
   */
    export interface DeleteV1FileFilenameIdFileidParams {

        /**
     * File name
     */
        filename: string;

        /**
     * File id
     */
        fileid: number;
    }

    /**
   * Parameters for getV1FileFilenameIdFileidStartDelimiter
   */
    export interface GetV1FileFilenameIdFileidStartDelimiterParams {

        /**
     * File name
     */
        filename: string;

        /**
     * File id
     */
        fileid: number;

        /**
     * File delimiter
     */
        delimiter: string;
    }

    /**
   * Parameters for getV1FileFilenameFormatFieldname
   */
    export interface GetV1FileFilenameFormatFieldnameParams {

        /**
     * File name
     */
        filename: string;

        /**
     * Field name
     */
        fieldname: string;
    }

    /**
   * Parameters for getV1FileFileIdDownloadType
   */
    export interface GetV1FileFileIdDownloadTypeParams {

        /**
     * type of file : original or enrich
     */
        type: 'original' | 'enrich';

        /**
     * file id
     */
        fileId: number;
    }
}

export default FileService;
