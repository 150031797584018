/* eslint-disable import/no-import-module-exports */
import {
    AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { GetCorpus } from 'app/api/models';
import { CorpusService, FileService } from 'app/api/services';
import CorpusState from 'app/stores/state/corpus/corpus.state';
import * as Resumable from 'libs/resumable';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PatchV1CorpusCorpIdParams } from 'app/api/services/corpus.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'app-csv',
    templateUrl: './csv.component.html',
    styleUrls: ['./csv.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})

export default class CsvComponent implements OnInit, OnDestroy, AfterViewInit {
    private r: any;

    public fileName = '';

    public percent;

    private id: number;

    delimiter;

    dateFormat;

    dateFormatSample;

    encoding;

    isUpload = false;

    selectedFile: any;

    selectedType: 'original' | 'enrich' = null;

    corpusState$: Observable<GetCorpus>;

    allowedExtensions;
    
    @ViewChild('gdprInfoModal')
    private gdprModal: TemplateRef<any>;

    @ViewChild('has_date', { static: true }) primaryBlock: TemplateRef<any> | null = null;

    @ViewChild('no_date', { static: true }) secondaryBlock: TemplateRef<any> | null = null;

    @Input() fileUploadError: boolean = false;

    @Input() uploadEnable: boolean = false;

    @Input() corpusType: number;

    @Output() updateFileError: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() csvFileIsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    modalRef: BsModalRef;

    protected error = null;

    gdrpText = '';

    // IB
    fileExtension: any;

    openParams: boolean;

    retrievedCorpus: any;

    showRules: Boolean = false; // mecanisme pour afficher-cacher les details sur un fichier

    showFileStructure: Boolean = false; // mecanisme pour afficher-cacher la structure du fichier

    displayFilePicker: Boolean = false; // mecanisme pour afficher-cacher le filepicker

    has_a_date_columns: Boolean = false; // tmp var pour tester le retour api

    constructor(
        private corpus: CorpusService,
        private file: FileService,
        private store: Store,
        private modalService: BsModalService,
        private translate: TranslateService,
        private permissionsService: NgxPermissionsService,
    ) {
        this.corpusState$ = this.store.select(CorpusState);
        this.corpusState$.pipe(first()).subscribe((state) => {
            this.id = state.corp_id;
            this.delimiter = state.files[0].file_delimiter;
            this.dateFormat = state.corp_date_format;
            this.dateFormatSample = state.corp_date_format_sample;
            this.encoding = state.files[0].file_encoding;
        });

        this.gdrpText = this.store.snapshot().settings.vcrm[
            `gdpr_maintext_${this.translate.currentLang.toLowerCase()}`
        ];
    }

    ngOnInit() {
    /* recuperer les infos du corpus associé au projet pour afficher les details des colonnes dans l ecran de gestion du fichier  */
        if (this.corpusType === 1) {
            this.allowedExtensions = this.store.snapshot().settings.vcrm.allowed_extensions;
        }
        else if (this.corpusType === 4) {
            this.allowedExtensions = this.store.snapshot().settings.vcrm.conversation_extensions;
        }
        this.retrievedCorpus = this.store.snapshot().corpus;
        if (this.retrievedCorpus.has_column_date) {
            this.has_a_date_columns = true;
        } else {
            this.has_a_date_columns = false;
        }
    }

    get canUploadFile() {
        return this.permissionsService.getPermissions().corpusAdmin;
    }

    ngAfterViewInit() {
        this.r = new Resumable({
            target: `${environment.api}/api/v1/file/upload`,
            query: {
                delimiter: this.delimiter,
                type: this.corpusType,
            },
            withCredentials: true,
            method: 'multipart',
            testChunks: true,
            simultaneousUploads: 1,
            maxFiles: 1,
        });
        if (document.getElementById('fileImport')) {
            this.r.assignBrowse(document.getElementById('fileImport'), false);
        }

        this.r.on('progress', () => {
            this.percent = Math.round(this.r.progress() * 100);
            FavIconX.config({
                shape: 'doughnut',
                borderWidth: 0,
                fillColor: '#f16e00',
            }).setValue(this.percent);
            this.isUpload = true;
        });

        this.r.on('fileAdded', (file) => {
            FavIconX.reset();
            this.fileName = file.fileName;
            this.percent = 0;
        });

        this.r.on('fileError', (e, message) => {
            if (typeof message === 'string') {
                // eslint-disable-next-line no-param-reassign
                message = JSON.parse(message);
            }
            FavIconX.fail();
            this.updateFileError.emit(true);
            this.isUpload = false;
            this.error = message;
            this.error.msgToTranslate = `translations.httpErrors.${message?.error || 'title'}`;
        });

        this.r.on('fileSuccess', async () => {
            this.r.cancel();
            FavIconX.reset();
            this.isUpload = false;
            const params: PatchV1CorpusCorpIdParams = {
                corpId: this.id,
                body: { filename: this.fileName },
            };
            this.fileName = '';
            this.corpus.patchV1CorpusCorpId(params).subscribe({
                next: () => {
                    this.updateFileError.emit(false);
                    this.csvFileIsUpdated.emit();
                },
                error: (err) => {
                    this.updateFileError.emit(true);
                    this.error = err.error;
                    this.error.msgToTranslate = `translations.httpErrors.${this.error?.error || 'title'}`;
                },
            });
        });
    }

    onFileChange(evt) {
        this.updateFileError.emit(false);
        this.displayFilePicker = true;
        this.openParams = false;
        this.fileName = '';
        this.error = null;
        const target: DataTransfer = <DataTransfer>evt.target;
        if (target.files && target.files.length === 1) {
            this.fileName = target.files[0].name;
            this.fileExtension = this.fileName.split('.').pop(); // IB
            if (this.allowedExtensions.includes(this.fileExtension.toLowerCase())) {
                if (this.fileExtension === 'csv') {
                    this.openParams = true;
                }
            } else {
                this.updateFileError.emit(true);
                this.isUpload = false;
                this.error = {
                    msgToTranslate: 'translations.newcorpus.stage2.fileExtensionError',
                };
            }
        }
    }

    ngOnDestroy(): void {
        if (this.r) {
            this.r.cancel();
        }
    }

    toggleRules() {
    /* switcher la valeur du booleen showRules pour afficher ou cacher le ng-template qui affiche les regles d'import */
        this.showRules = !this.showRules;
    }

    toggleStructure() {
    /* switcher la valeur du booleen showStructure pour afficher ou cacher le ng-template qui présente la structure actelle des fichiers du projet */
        this.showFileStructure = !this.showFileStructure;
    }

    addFile() {
        this.file.deleteV1File().subscribe(() => {
            this.r.opts.query.delimiter = this.delimiter;
            this.r.opts.query.type = this.corpusType;
            this.r.upload();
        });
    }

    openGdprModal(e) {
        e.preventDefault();
        this.modalRef = this.modalService.show(this.gdprModal, {
            backdrop: 'static',
        });
    }

    closeModal() {
        this.modalRef.hide();
    }
}
