/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import {
    State, Action, StateContext, Selector,
} from '@ngxs/store';
import { SetNews, SetNewsCategories } from 'app/stores/actions/news/news.actions';
import { NewsStateModel } from 'app/stores/models/news/news-state.model';

/**
 * NGXS Store for news
 *
 * @export
 * @class NewsState
 */
@Injectable()
@State<NewsStateModel>({
    name: 'news',
    defaults: {
        newsList: [],
        newsCategories: [],
    },
})
export default class NewsState {
    @Selector()
    static newsList(state: NewsStateModel) {
        return state.newsList;
    }

    @Selector()
    static newsCategories(state: NewsStateModel) {
        return state.newsCategories;
    }

    @Action(SetNews)
    SetNews(
        { getState, setState }: StateContext<NewsStateModel>,
        { payload }: SetNews,
    ) {
        const state = getState();
        state.newsList = [...payload];
        setState(state);
    }

    @Action(SetNewsCategories)
    SetNewsCategories(
        { getState, setState }: StateContext<NewsStateModel>,
        { payload }: SetNewsCategories,
    ) {
        const state = getState();
        state.newsCategories = [...payload];
        setState(state);
    }
}
