/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import drawDashboardNumber from './chartjs-plugins-utils';

export const addDashboardNumberToVerticalBarChart = {
    id: 'addDashboardNumberToVerticalBarChart',
    afterDatasetsDraw(chart, args, options) {
        if (chart.config._config.type !== 'bar') {
            return;
        }

        const spaceForLabels = options.spaceForLabels === undefined || options.spaceForLabels;

        const { ctx } = chart;

        ctx.textBaseline = 'middle';

        ctx.font = 'bold  12px \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif';

        // affichage des valeurs dans les barres
        // eslint-disable-next-line @typescript-eslint/dot-notation
        chart._metasets.forEach((metaset, i) => {
            const dataset = metaset._dataset;
            const meta = chart.getDatasetMeta(i);

            // affichage des valeurs à l'intérieur des barres
            meta.data.forEach((bar, index) => {
                ctx.textAlign = 'left';
                drawDashboardNumber(ctx, dataset.stack, bar.x - 13, bar.y - 15 - 20 * spaceForLabels);
            });
        });
    },

};
