/* eslint-disable max-classes-per-file */
/**
 * Set news
 *
 * @export
 * @class SetNews
 */
export class SetNews {
    static readonly type = '[NEWS] set news';

    constructor(public payload: any[]) {}
}

export class SetNewsCategories {
    static readonly type = '[NEWS] set news categories';

    constructor(public payload: any[]) {}
}
