import { Component, Input } from '@angular/core';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import FiltersTitleComponent from '../../../common/filters-title/filters-title.component';

@Component({
    selector: 'filters-thematics-menu',
    templateUrl: './filters-thematics-menu.component.html',
    styleUrls: ['./filters-thematics-menu.component.scss'],
})
export default class FiltersThematicsMenuComponent extends FiltersTitleComponent {
    @Input()
        selectedThematics;

    constructor(
        public dashboardService: DashboardService,
        public manageDashboardService: ManageDashboardService,
    ) {
        super(dashboardService, manageDashboardService);
    }
}
