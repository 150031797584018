import { HttpClient } from '@angular/common/http';
import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Files, GetCorpus } from 'app/api/models';
import { CorpusService } from 'app/api/services';
import CorpusState from 'app/stores/state/corpus/corpus.state';
import MsurveyService from 'app/utils/services/msurvey.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-msurvey-url',
    templateUrl: './msurvey-url.component.html',
    styleUrls: ['./msurvey-url.component.scss'],
})
export default class MsurveyUrlComponent {
    public msurveyKey;

    public display_url: boolean = false;

    public corpusId;

    public msurveyId;

    public corpusType;

    public msurveyUrl;

    msurveyUpdateErrorMessage : string;

    @Input()
        fileUploadError: boolean = false;

    @Input()
        uploadEnable: boolean = false;

    corpusState$: Observable<GetCorpus>;

    @Output() urlIsUpdated: EventEmitter<void> = new EventEmitter<void>();

    @Output() updateFileError: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private msurveyService: MsurveyService,
        private corpusService: CorpusService,
        private http: HttpClient,
        private store: Store,
    ) {
        this.corpusState$ = this.store.select(CorpusState);
        this.corpusState$.subscribe((state) => {
            this.msurveyId = state.files[0].file_json_config.surveyId;
            this.msurveyKey = state.files[0].file_json_config.key;
            this.corpusId = state.corp_id;
            // account vs url c'est dans le corpusType
            this.corpusType = state.corp_type;
        });
    }

    async updateProject(e) {
        this.updateFileError.emit(false);
        e.preventDefault();

        try {
            // on supprimer le fichier existant
            await this.msurveyService.deleteExistingFile().toPromise();

            // on demande le téléchargement du nouveau fichier
            await this.msurveyService.updateFromURL(this.msurveyId, this.msurveyKey).toPromise();

            // on récupère le nom du fichier
            const files = await this.http.get('/api/v1/file', { withCredentials: true }).toPromise();
            const fileName = (files as Files).files[0].file_name;

            // on injecte le nouveau fichier dans le corpus
            await this.corpusService.patchV1CorpusCorpId({ corpId: this.corpusId, body: { filename: fileName } }).toPromise();

            this.urlIsUpdated.emit();
        } catch (error) {
            this.updateFileError.emit(true);
            this.msurveyUpdateErrorMessage = `translations.httpErrors.${error?.error?.error}`;
        }
    }
}
