// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  border-bottom-color: #ccc;
  display: block;
}
:host .nav-underline {
  padding: 0 15px 0 60px;
}
:host .nav-link {
  font-size: 14px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
