/* eslint-disable import/no-import-module-exports */
import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import FooterService from 'app/shared/components/footer/footer.service';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { BaseComponent } from 'app/base.component';

/**
 * New corpus global component
 *
 * @export
 * @class NewProjectComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-new-project',
    templateUrl: './new-project.component.html',
    styleUrls: ['./new-project.component.scss'],
    moduleId: module.id.toString(),
    animations: [fadeInAnimation],
})
export default class NewProjectComponent extends BaseComponent {
    projectEtapes;

    constructor(
        private store: Store,
        public router: Router,
        private footerService: FooterService,
    ) {
        super();
        this.projectEtapes = this.store.snapshot().newcorpus.etapes;
        this.footerService.visible(false);
    }
}
