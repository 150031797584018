/* eslint-disable @typescript-eslint/no-useless-constructor */
import {
    Component, ElementRef, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from '../../corpus-graph.service';
import ManageDashboardService from '../../corpus-manage-dashboard.service';
import VerticalFiltersComponent from '../../components/vertical-filters/vertical-filters.component';

@Component({
    selector: 'app-corpus-dashboard',
    templateUrl: './corpus-dashboard.component.html',
    styleUrls: ['./corpus-dashboard.component.scss'],
    animations: [fadeInAnimation],
    providers: [DashboardService, GraphService],
})
export default class CorpusDashboardComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('verticalFilters') public verticalFilters: VerticalFiltersComponent;

    @ViewChild('filterToggle') public filterToggle: ElementRef<HTMLElement>;

    hasDashboard = false;

    get dashboardIndex() {
        return this.manageDashboardService.dashboardServices.indexOf(this.dashboardService);
    }

    constructor(
        public dashboardRef: ElementRef,
        private dashboardService: DashboardService,
        protected manageDashboardService: ManageDashboardService,
    ) {
        super();

        // Enregistre le service dans le manager de dashboard
        this.manageDashboardService.addDashboardService(dashboardService);
    }

    getDashboardService() {
        return this.dashboardService;
    }

    ngOnInit(): void {
        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            if (dashboard) {
                this.hasDashboard = true;
            }
        });

        // Si on est en mode comparaison, on écoute le changement des filtres pour appliquer les bordures
        if (this.manageDashboardService.isDashboardComparison) {
            this.subs.sink = this.dashboardService.currentFilters.subscribe((filters) => {
                if (filters) {
                    this.manageDashboardService.manageDifferenceBetweenFilters();
                }
            });
        }
    }

    ngOnDestroy(): void {
        // Supprime le service dans le manager de dashboard lors de sa suppression
        this.manageDashboardService.removeDashboardService(this.dashboardService);
        super.ngOnDestroy();
    }
}
