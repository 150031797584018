// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse-btn {
  width: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.collapse-btn .vcrm-form-chevron-right {
  transform: rotateZ(0deg);
  transition: transform 0.2s ease-in-out;
  /* stylelint-disable-next-line max-nesting-depth */
}
.collapse-btn .vcrm-form-chevron-right.rotate {
  transform: rotateZ(90deg);
}

.folder-collapse {
  height: 40px;
}
.folder-collapse .folder-info {
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
