import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { BaseComponent } from 'app/base.component';
import { ResetDashModified } from 'app/stores/actions/settings/settings.actions';
import * as _ from 'lodash';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import download from 'app/utils/functions/download';
import { DashboardType } from 'app/api/models/dashboardforuser';
import { CorpusService as CorpusApi } from 'app/api/services';
import CorpusService from 'app/utils/services/corpus.service';
import {
    Dashboardforuser,
    Dashboardlist,
    GetCorpus,
    Listusername,
    Usersincorpus,
} from 'app/api/models';
import SharedStatus from 'app/api/models/shared-status';
import ManageDashboardService from '../../corpus-manage-dashboard.service';
import SelectDashboardModalComponent from './select-dashboard-modal/select-dashboard-modal.component';
import NewComparisonModalComponent from './new-comparison-modal/new-comparison-modal.component';

@Component({
    selector: 'app-dashboards-settings',
    templateUrl: './dashboards-settings.component.html',
    styleUrls: ['./dashboards-settings.component.scss'],
})
export default class DashboardsSettingsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    // la liste de tous les dashboards
    dashs: Dashboardlist = { personalDashboards: [], sharedDashboards: [], otherDashboards: [] };

    // la liste de tous les dashboards
    comparisons: Dashboardlist = { personalDashboards: [], sharedDashboards: [], otherDashboards: [] };

    // le dashboard sélectionné
    currentDashboard: Dashboardforuser = {
        dash_initial: true,
        is_default: false,
    };

    sharedInitialState: any;

    shared = SharedStatus.NONE;

    saveName: string;

    saveNameInitialValue: string;

    userNotFoundError: boolean = false;

    activeSave: boolean = true;

    isPPTExportRequested: boolean = false;

    exportName: string;

    usersSelected: Listusername = { usernames: [] };

    userSelected: string = '';

    userSelectedObject: TypeaheadMatch = null;

    usersAvaible: Array<Usersincorpus>;

    usersAvaibleForThisUserDB: Array<Usersincorpus> = [];

    usersShared: Array<Usersincorpus> = [];

    isPptFlashReportReady: boolean = true;

    isPptFlashReportInProgress = false;

    @ViewChild('delUserTpl')
        delUserTpl: TemplateRef<any>;

    @ViewChild('userTpl')
        userTpl: TemplateRef<any>;

    @ViewChild('confirm')
        confirm: TemplateRef<any>;

    columns: Array<any>;

    config = {
        style: {
            maxHeight: '200px',
        },
    };

    modalCallback: Function;

    modalRef: NgbModalRef;

    downloadSpecificReport = '';

    get isDownloadSpecificPPTReportAvailable() {
        if (!this.manageDashboardService.firstDashboardService) {
            return false;
        }
        const filters = this.manageDashboardService.firstDashboardService.lastAppliedFilters.getValue();
        return (filters.thematics.length > 0 && filters.selected_all) || (!!this.manageDashboardService.getCurrentModel() && filters.filters_class.length === 0);
    }

    constructor(
        private store: Store,
        private http: HttpClient,
        private corpusApi: CorpusApi,
        private modalService: NgbModal,
        private corpusService: CorpusService,
        protected manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        // migration to angular 9
        this.columns = [
            { title: '', prop: 'surname', cellTemplate: this.userTpl },
            {
                title: '',
                cellTemplate: this.delUserTpl,
                headerClass: 'text-right',
                cellClass: 'text-right',
                maxWidth: 86,
                style: { 'text-align': 'right' },
            },
        ];
    }

    get corpus(): GetCorpus {
        return this.corpusService.currentCorpus.getValue();
    }

    /**
     * Le bouton de sauvegarde simple est désactivé dans le cas d'un projet multi verbatim, quand l'utilisateur change le verbatim utilisé, pour forcer la création d'une nouveau projet
     * https://octane.rd.francetelecom.fr/ui/?p=1001/15012#/entity-navigation?entityType=work_item&id=476412
     */
    get isSaveDisabled() {
        if (!this.manageDashboardService.firstDashboardService) {
            return true;
        }
        if (this.manageDashboardService.firstDashboardService.lastAppliedFilters.value === null) {
            return false;
        }

        return (
            this.manageDashboardService.firstDashboardService.savedFilters.value.verbatim_dbname
            !== this.manageDashboardService.firstDashboardService.lastAppliedFilters.value.verbatim_dbname
        );
    }

    // Détermine si un dashboard est en cours de modification
    get dashboardIsModified() {
        return this.store.snapshot().settings.dashboardsModifications?.some((dashboard) => dashboard.isDashModified);
    }

    // Détermine si on doit afficher "(non renregistré)" et si les favoris sont désactivés ou non
    get isUnsaved() {
        // 2 cas possibles :
        // - Si on est sur un dashboard initial (dashboard simple) ET qu'il y a des modifications
        // - Si on est sur une comparaison de dashboard sans nom
        return (this.dashboardIsModified && this.currentDashboard.dash_initial) || (this.currentDashboard.dash_type === DashboardType.COMPARISON && this.currentDashboard.dash_name === '');
    }

    /**
     * Est-ce que le bouton "Enregistrer et exporter" doit être désactiver dans la popup de
     * téléchargement du power point ?
     *
     * Si le nom du dashboard est vide -> oui
     * Si le verbatim sélectionné a changé -> le dashboard doit être enregistré avec un nouveau nom, donc il faut changer le nom
     */
    get isSaveAndExportButtonDisabled(): boolean {
        return (this.saveName.trim().length === 0 || (this.isSaveDisabled && this.saveName === this.saveNameInitialValue));
    }

    // Regarde si au moins 1 dashboard à des données ou si personne n'en a
    get dashboardHasData() {
        return this.manageDashboardService.dashboardServices.reduce((acc, service) => acc || service.dashboardData.getValue()?.hits.total !== 0, false);
    }

    ngOnInit(): void {
        this.usersAvaible = this.store.snapshot().corpus.users;

        // Récupère la liste des dashboards (analyses et comparaisons)
        this.subs.sink = this.manageDashboardService.allDashboards.subscribe((dashboards: Dashboardlist) => {
            if (dashboards) {
                this.dashs = this.manageDashboardService.allAnalysesDashboards.getValue();
                this.comparisons = this.manageDashboardService.allComparisonsDashboards.getValue();
            }
        });

        // Lorsque l'on charge un dashboard (classique ou comparaison)
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(async () => {
            // Récupère le dashboard courant
            this.subs.sink = this.manageDashboardService.currentDashboard.subscribe((dashboard) => {
                if (dashboard !== null) {
                    this.currentDashboard = dashboard;
                    this.shared = this.currentDashboard.dash_shared;
                    this.isPptFlashReportReady = true;
                    this.pptExportPersonalDashboardIfRequested();
                }
            });
            // Détermine si il y a eu des modifications de filtres
            this.manageDashboardService.dashboardServices.forEach((service) => {
                this.subs.sink = service.hasFilterChangesNotApplied.subscribe((value) => {
                    this.activeSave = !value;
                });
            });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    defaultSort(datas) {
        if (!datas) {
            return [];
        }
        return JSON.parse(
            JSON.stringify(
                _.uniq(
                    datas.sort((rowA, rowB) => {
                        const a = `${rowA.surname} ${rowA.givenname}`;
                        const b = `${rowB.surname} ${rowB.givenname}`;

                        if (
                            a.toString().toUpperCase()
                            < b.toString().toUpperCase()
                        ) {
                            return -1;
                        }
                        if (
                            a.toString().toUpperCase()
                            > b.toString().toUpperCase()
                        ) {
                            return 1;
                        }
                        return 0;
                    }),
                ),
            ),
        );
    }

    /**
     * Demande un enregistrement du dashboard avant d'exporter le rapport
     */
    saveBeforeExport(template: TemplateRef<any>, isSpecificReport = '') {
        this.downloadSpecificReport = isSpecificReport;
        this.openModal(template);
    }

    /**
     * Ouvre une modal
     */
    openModal(template: TemplateRef<any>) {
        this.saveName = '';

        if (!this.manageDashboardService.currentDashboard.getValue().dash_id) {
            // si c'est un dashboard non sauvegardé (saveas), on calcule le nom du dashboard
            this.saveName = this.manageDashboardService.computeDashboardName();
        } else {
            // dans tout les autres cas (en particulier pour un edit), on prend le nom réel du dashboard
            this.saveName = this.manageDashboardService.currentDashboard.getValue().dash_name;
        }

        this.saveNameInitialValue = this.saveName;

        this.modalRef = this.modalService.open(template, {
            backdrop: 'static',
        });
    }

    /**
     * Vérifie si le dashboard est modifié ou non.
     * Si oui, ouvre une modal de confirmation de sauvegarde des données non enregistrées.
     * Si non, exécute le traitement directement
     */
    openModalConfirm(callback: Function | TemplateRef<any>) {
        this.modalCallback = typeof callback === 'function' ? callback : (() => { this.openModal(callback); });
        // Si le dashboard est modifié OU si on a commencé une découverte, on affiche la popin pour demander la sauvegarde des modifs
        if (this.dashboardIsModified || this.store.snapshot().settings.isDiscoveryInProgress) {
            this.modalRef = this.modalService.open(this.confirm, { backdrop: 'static' });
        } else {
            // Si il n'y a pas de modifs, on exécute les paramètres directement
            this.modalCallback();
        }
    }

    /**
     * Ouvre la popin pour sélectionner un dashboard (classique) ou une analyse comparative de dashboard
     */
    openModalSelectDashboard() {
        const modal = this.modalService.open(SelectDashboardModalComponent, { modalDialogClass: 'select-dashboard-modal' });
        modal.componentInstance.manageDashboardService = this.manageDashboardService;
        modal.componentInstance.selectDashboard.subscribe((dash: Dashboardforuser) => {
            this.openModalConfirm(() => { this.manageDashboardService.selectDashboard(dash.dash_id); });
        });
        modal.componentInstance.clickNewComparison.subscribe(() => {
            this.openModalNewComparison();
        });
    }

    /**
     * Ouvre la popin pour créer une comparaison de 2 ou 3 dashboard
     */
    openModalNewComparison() {
        const modal = this.modalService.open(NewComparisonModalComponent, { modalDialogClass: 'new-comparison-modal' });
        modal.componentInstance.manageDashboardService = this.manageDashboardService;
        modal.componentInstance.openModalSelectDashboard.subscribe(() => {
            this.openModalSelectDashboard();
        });
        modal.componentInstance.newComparison.subscribe(async (selectedDash: Dashboardforuser[]) => {
            this.openModalConfirm(() => { this.manageDashboardService.createNewComparison(selectedDash); });
        });
    }

    onConfirmNoSave() {
        this.store.dispatch(new ResetDashModified());
        this.modalRef.close('confirm');
        setTimeout(() => {
            if (this.modalCallback) {
                this.modalCallback();
            }
        }, 500);
    }

    openModalShare(template: TemplateRef<any>) {
        const callback = () => {
            this.usersShared = this.defaultSort(this.currentDashboard.sharers) || [];
            const usersAvaibleForThisUserDB = [];
            this.usersAvaible.forEach((user) => {
                if (this.currentDashboard.username_creator !== user.username) {
                    if (_.isEmpty(this.usersShared)) {
                        usersAvaibleForThisUserDB.push(user);
                    } else {
                        let toAdd = true;
                        this.usersShared.forEach((u) => {
                            if (u.username === user.username) {
                                toAdd = false;
                            }
                        });
                        if (toAdd) {
                            usersAvaibleForThisUserDB.push(user);
                        }
                    }
                }
            });

            this.usersAvaibleForThisUserDB = this.defaultSort(
                JSON.parse(JSON.stringify(usersAvaibleForThisUserDB)),
            );
            if (this.manageDashboardService.currentDashboardType === DashboardType.ANALYSE) {
                gtmClick({
                    track_category: 'analyse',
                    track_name: 'partage de l\'analyse',
                    track_cible: this.manageDashboardService.isDashboardComparison ? 'comparaison' : 'dashboard',
                });
            } else {
                gtmClick({
                    track_category: 'dashboard tonalité',
                    track_name: 'partage de projet',
                });
            }

            this.modalRef = this.modalService.open(template, {
                backdrop: 'static',
            });
        };
        this.openModalConfirm(callback);
    }

    // A l'annulation de partage, on remet la configuration de partage du dashbaord
    resetShareModal() {
        this.shared = this.currentDashboard.dash_shared;
    }

    // Sélectionne un utilisateur dans la liste déroulante lors de la recherche d'un utilisateur
    selectUser(user) {
        this.userNotFoundError = false;
        this.userSelectedObject = user;
        this.userSelected = `${user.item.surname} ${user.item.givenname}`;
    }

    // Ajoute un utilisateur dans le tableau de partage
    addUserToShared() {
        if (this.userSelected !== '' && this.userSelectedObject !== null) {
            this.userNotFoundError = false;
            this.usersShared.push(this.userSelectedObject.item);
            this.usersAvaibleForThisUserDB = this.usersAvaibleForThisUserDB.filter((user) => {
                if (user.username !== this.userSelectedObject.item.username) {
                    return user;
                }
                return null;
            });
            this.userSelected = '';
            this.userSelectedObject = null;
            this.usersAvaibleForThisUserDB = this.defaultSort(
                this.usersAvaibleForThisUserDB,
            );
            this.usersShared = this.defaultSort(this.usersShared);
            gtmClick({
                track_category: 'analyse',
                track_name: 'partage avec ajout utilisateur',
                track_cible: this.manageDashboardService.isDashboardComparison ? 'comparaison' : 'dashboard',
            });
        } else if (
            this.userSelected !== ''
            && this.userSelectedObject === null
        ) {
            this.userNotFoundError = true;
        }
    }

    // Supprime un utilisateur du tableau de partage
    removeToTable(user) {
        this.usersAvaibleForThisUserDB.push(user);
        this.usersShared = this.usersShared.filter(
            (u) => u.username !== user.username,
        );
        this.usersAvaibleForThisUserDB = this.defaultSort(
            this.usersAvaibleForThisUserDB,
        );
        this.usersShared = this.defaultSort(this.usersShared);
    }

    async saveSharedDb() {
        if (this.shared === SharedStatus.SELECT && this.usersShared.length === 0) {
            this.shared = SharedStatus.NONE;
        }

        const usernamesList = [];

        if (this.shared === SharedStatus.SELECT) {
            this.usersShared.forEach((user) => {
                usernamesList.push(user.username);
            });
        } else {
            this.usersShared = [];
        }

        try {
            await this.manageDashboardService.share(this.shared, usernamesList);
            this.currentDashboard.dash_shared = this.shared;
            this.currentDashboard.sharers = this.usersShared;
            this.manageDashboardService.loadAllDashboards();
        } catch (e) {
            this.shared = this.currentDashboard.dash_shared;
        }
    }

    async saveAsDbAndExport(name: string) {
        this.exportName = name;
        this.isPPTExportRequested = true;
        // Initial dashboard : it is not possible to update an initial dashboard or We are in a personal dashboard
        if (this.currentDashboard.dash_initial || this.saveName !== this.saveNameInitialValue || this.isUnsaved) {
            this.saveAsDb(name);
        } else {
            await this.saveDb();
            this.modalRef.close();
            this.pptExportPersonalDashboardIfRequested();
        }
    }

    // Enregistre les modifications du dashboard courant
    async saveDb() {
        const newDashboard = await this.manageDashboardService.save();
        if (newDashboard) {
            this.currentDashboard = newDashboard;
        }
    }

    // Enregistre avec un nouveau nom
    saveAsDb(name: string) {
        this.manageDashboardService.saveAs(name);
        this.modalRef.close();
    }

    // Change le nom du dashboard
    async changeName(name) {
        try {
            await this.manageDashboardService.rename(name);
            this.currentDashboard.dash_name = name;
        } catch (e) {
            // nothing
        }
    }

    // Met le dashboard courant en favori
    async makeDefault() {
        if (!this.currentDashboard.is_default) {
            try {
                await this.manageDashboardService.makeDefault();
                this.currentDashboard.is_default = true;
            } catch (e) {
                // nothing
            }
        }
    }

    // Supprime le dashboard courant
    deleteDb() {
        this.manageDashboardService.delete();
        this.modalRef.close();
    }

    pptExportPersonalDashboardIfRequested() {
        if (
            this.saveName === this.exportName
            && this.isPPTExportRequested
            && !this.currentDashboard.dash_initial
        ) {
            this.isPPTExportRequested = false;
            this.downloadPPTFlashReportFromBackend(
                this.currentDashboard.dash_id,
                this.downloadSpecificReport,
            );
        }
    }

    /**
     * Generate (backend side) and then trigger in the browser the download of a PowerPoint PPTX "flash report" for a
     * particular dashboard id
     * @param dash_id dashboard id
     * @param type type de rapport à télécharger (normal or specific)
     * @return nothing
     */
    downloadPPTFlashReportFromBackend(dash_id: number, isSpecificRapport = ''): void {
        // s'il y a déjà un export ppt en cours, on ne fait rien
        if (this.isPptFlashReportInProgress) {
            return;
        }

        const url = `${this.corpusApi.rootUrl}/v1/dashboard/${dash_id}/flashreport${isSpecificRapport}`;
        this.isPptFlashReportInProgress = true;
        this.http
            .get(url, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (data) => {
                    this.isPptFlashReportInProgress = false;
                    download(URL.createObjectURL(data.body), data.headers.get('content-disposition').split('=')[1], '_blank');
                },
                error: (errorObject) => {
                    this.isPptFlashReportInProgress = false;

                    // Javascript proxy handler customization. Is trigggered when errorObject is updated with new information.
                    errorObject.proxyHandler.set = function (
                        target,
                        key,
                        value,
                    ) {
                        if (value.error === '401015') {
                            this.manageDashboardService.currentDashboardService.displayServerError(value);
                        } else {
                            const modal = this.modalService.open(ModalComponent, {});
                            modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                            modal.componentInstance.contentToTranslate = `translations.httpErrors.${errorObject?.error?.error || 'title'}`;
                            modal.componentInstance.alertTypeVariant = 'danger';
                            this.isPptFlashReportReady = false;
                        }
                    }.bind(this);
                },
            });
    }
}
