import { Injectable } from '@angular/core';
import {
    Router, CanActivate, ActivatedRouteSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { NGXLogger } from 'ngx-logger';
import { User } from 'app/api/models';
import * as _ from 'lodash';

@Injectable()
export default class AuthActivateService implements CanActivate {
    constructor(
        private router: Router,
        private store: Store,
        private logger: NGXLogger,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        this.logger.info('Check user canActivate route');
        const userProfil: User = this.store.snapshot().user;
        if (!_.isEmpty(userProfil)) {
            this.logger.info('User is logged');

            const { externalUrl } = route.queryParams;
            if (externalUrl) {
                this.router.navigate(['/'], { queryParams: { externalUrl, filename: route.queryParams.filename || 'unknown' } });
            } else {
                this.router.navigate(['/']);
            }

            return false;
        }
        this.logger.info('User is not logged');
        return true;
    }
}
