// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 800px;
  grid-area: import-component;
}

.css-import-format-tutos {
  grid-area: import-format-tutos;
  margin-top: 20px;
}
.css-import-format-tutos ul > li {
  padding-bottom: 10px;
}

.css-import-file-picker {
  grid-area: import-file-picker;
  padding-top: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
