import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DistriParams, GroupedValue, Period } from 'app/api/models/distriparams';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';

@Component({
    selector: 'app-satisfaction-line-chart-settings-popup',
    templateUrl: './satisfaction-line-chart-settings-popup.component.html',
    styleUrls: ['./satisfaction-line-chart-settings-popup.component.scss'],
})
export default class SatisfactionLineChartSettingsPopupComponent extends BaseComponent implements OnInit {
    constructor(
        public modal: NgbActiveModal,
    ) {
        super();
    }

    type:string;

    isMerged: boolean;

    params: DistriParams;

    initialParams: DistriParams;

    graphService: GraphService;

    dashboardService: DashboardService;

    manageDashboardService: ManageDashboardService;

    columns = [];

    selectedColumn;

    nbMaxValuesChart: number = 50;

    @Output()
    private save: EventEmitter<DistriParams> = new EventEmitter();

    ngOnInit(): void {
        this.initialParams = _.cloneDeep(this.params);
        this.params = _.cloneDeep(this.params);

        this.subs.sink = this.manageDashboardService.getCurrentVerbatimColumns().subscribe((columns) => {
            Object.keys(columns).forEach((column) => {
                if (!columns[column].isTreatment) {
                    this.columns.push(
                        {
                            id: column,
                            value: columns[column].displayName,
                            count: columns[column].values.length,
                        },
                    );
                }
            });
        });

        if (!this.params.grouped_field && this.columns.length > 0) {
            this.selectedColumn = this.columns[0];
            this.onChangeColumn(this.selectedColumn, false);
        } else {
            this.selectedColumn = this.columns.find((column) => column.id === this.params.grouped_field);
        }

        this.changerMaxValuesChart();
    }

    onChangeColumn(column, changeCurrentValue: boolean = true) {
        this.params.grouped_field = column.id;
        this.params.selectedColumnDisplayName = column.value;
        this.nbMaxValuesChart = column.count > 50 ? 50 : column.count;

        if (changeCurrentValue || this.params.nb_values_chart > this.nbMaxValuesChart) {
            this.params.nb_values_chart = this.nbMaxValuesChart < 12 ? this.nbMaxValuesChart : 12;
        }
    }

    validate() {
        this.save.emit(this.params);
        this.modal.close();
    }

    isEnabled(period:string): boolean {
        if (period === 'day') {
            return this.graphService.isDayAllowedForGraph(this.dashboardService);
        } if (period === 'week') {
            return this.graphService.isWeekAllowedForGraph(this.dashboardService);
        }
        return true;
    }

    get forcedPeriod(): Period {
        return this.graphService.getDefaultPeriod(this.params.period, this.dashboardService);
    }

    set forcedPeriod(period: Period) {
        this.params.period = period;
    }

    get forcedGroup(): string {
        return this.isAllowed(this.params.grouped_values) ? this.params.grouped_values : GroupedValue.average;
    }

    set forcedGroup(value: string) {
        this.params.grouped_values = GroupedValue[value];
        this.changerMaxValuesChart(true);
    }

    changerMaxValuesChart(changeCurrentValue: boolean = false) {
        if (this.params.grouped_values === GroupedValue.column) {
            this.onChangeColumn(this.selectedColumn, changeCurrentValue);
        } else {
            if (this.params.grouped_values === GroupedValue.class) {
                this.nbMaxValuesChart = this.manageDashboardService.getCurrentModel()?.cat_fields.length + 1; // +1 for Hors_Class
            } else if (this.params.grouped_values === GroupedValue.thematic) {
                this.nbMaxValuesChart = this.manageDashboardService.getLastAppliedThematics()?.length + 1; // +1 for Hors_thematics
            } else {
                this.nbMaxValuesChart = 50;
            }

            if (this.nbMaxValuesChart > 50) {
                this.nbMaxValuesChart = 50;
            }

            if (changeCurrentValue || this.params.nb_values_chart > this.nbMaxValuesChart) {
                this.params.nb_values_chart = this.nbMaxValuesChart;
            }
        }
        return true;
    }

    isAllowed(group: GroupedValue) {
        if (group === GroupedValue.thematic) {
            return this.hasThematics;
        } if (group === GroupedValue.class) {
            return this.hasClasses;
        } if (group === GroupedValue.column) {
            return this.columns.length > 0;
        }
        return true;
    }

    isVisible(group: GroupedValue) {
        return this.isAllowed(group) || ![GroupedValue.class, GroupedValue.column].includes(group);
    }

    get hasThematics():boolean {
        return this.manageDashboardService.getLastAppliedThematics().length > 0;
    }

    get hasClasses(): boolean {
        return this.manageDashboardService.getCurrentModel() !== null && this.manageDashboardService.getCurrentModel() !== undefined;
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }

    launchGaEventNbValues(): void {
        const wordingMapping = {
            deltasat: 'satisfaction',
            nps: 'recommandation',
            deltatone: 'tonalité',
        };

        gtmClick({
            track_category: 'satisfaction',
            track_name: `vue temporelle ${this.type} nbre valeurs ${wordingMapping[this.type]} affichées`,
            track_cible: `${this.params.nb_values_chart}`,
        });
    }
}
