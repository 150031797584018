// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep #chartjs-tooltip {
  z-index: 1060;
}

:host {
  font-size: 14px;
  font-weight: bold;
}

.bar-chart {
  height: 50px;
}

.graph-dashboard-number {
  margin-top: 6px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
