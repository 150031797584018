import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    Columnsused,
    Dashboardforuser,
    Inputnamevalues,
    Models,
} from 'app/api/models';
import { KeywordSearch } from 'app/api/models/keywordsdata';
import { SearchField, SearchFieldType } from 'app/api/models/searchparams';
import { DashboardService as DashboardApi, CorpusService as CorpusApi } from 'app/api/services';
import { BaseComponent } from 'app/base.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { SetDashModified } from 'app/stores/actions/settings/settings.actions';
import { AssociatedDataDisplay, DashboardConfig, LlmProfileConfig } from 'app/api/models/dashboard-config';
import CorpusService from 'app/utils/services/corpus.service';
import QueryValidatorService from 'app/utils/services/query-validator.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Choice } from 'app/api/models/choice';
import { DashboardType } from 'app/api/models/dashboardforuser';
import { CopcConfig } from 'app/api/models/copcconfig';
import SharedStatus from 'app/api/models/shared-status';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { XaxisSort, Yaxis } from 'app/api/models/histoparams';

@Injectable()
export default class DashboardService extends BaseComponent implements OnDestroy {
    /**
     * le dashboard dernier dashboard sélectionné
     */
    public currentDashboard: BehaviorSubject<Dashboardforuser> = new BehaviorSubject<Dashboardforuser>(null);

    /**
     * Les filtres sauvegardés
     */
    public savedFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Les filtres en cours d'édition
     */
    public currentFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public currentConfig: BehaviorSubject<DashboardConfig> = new BehaviorSubject<DashboardConfig>(null);

    /**
     * Les derniers filtres appliqués
     */
    public lastAppliedFilters: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Les données de verbatim correspondant à lastAppliedFilters
     */
    public dashboardData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // La réponse de la requête sur les données associées
    public dashboardAssociatedData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Les columns disponibles selon un verbatim donnée
     */
    public currentVerbatimColumns: BehaviorSubject<Columnsused> = new BehaviorSubject<Columnsused>(null);

    /**
     * Est-ce qu'il y a eu des changements de filtres nont appliqués ?
     */
    public hasFilterChangesNotApplied: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Déclenche un évènement lorsque l'on applique de nouveaux filtres
     */
    public newFiltersIsApplied = new EventEmitter<NewFiltersIsAppliedParams>();

    /**
     * Le nombre total de filtres appliqués
     */
    public filtersCount : BehaviorSubject<number> = new BehaviorSubject<number>(0);

    public collapses = {};

    get currentDashboardType() {
        if (this.currentDashboard.value) {
            return [DashboardType.ANALYSE, DashboardType.COMPARISON, DashboardType.CHILD].includes(this.currentDashboard.value.dash_type) ? DashboardType.ANALYSE : DashboardType.TONE;
        }
        return DashboardType.ANALYSE;
    }

    // Détermine si un dashboard est en cours de modification
    get dashboardIsModified() {
        const dashModified = this.store.snapshot().settings.dashboardsModifications?.some((dashboard) => dashboard.isDashModified);
        if (dashModified) {
            return this.hasFiltersNotSaved();
        }
        return false;
    }

    /**
     * Les paramètres de filtrage qui ne sont pas sauvegardés dans le dashboard
     * Il ne doivent en effet pas être pris en compte dans la vérification pour savoir s'il faut afficher le bouton appliquer
     */
    private searchParamsIgnoredForApply = [
        'sort_by_date',
        'page',
        'search_fields',
        'thematic_not',
    ];

    private searchParamsIgnoredForSearch = [
        'sort_by_date',
        'page',
        'search_fields',
        'thematic_not',
    ];

    public onThematicsChanged = new EventEmitter<void>();

    public onSearchInvalid = new EventEmitter<string>();

    constructor(
        private dashboardApi: DashboardApi,
        private corpusService: CorpusService,
        private store: Store,
        private queryValidatorService: QueryValidatorService,
        private corpusApi: CorpusApi,
        private modalService: NgbModal,
    ) {
        super();

        // lorsque le filtrage change
        this.subs.sink = this.currentFilters.subscribe((params) => {
            if (params != null) {
                // on vérifie s'il faut afficher le bouton "Appliquer"
                this.checkIfFiltersHaveChangesNotApplied(params);
                // on vérifier s'il faut activer le bouton d'enregistrement du dashboard
                this.checkIfDashboardHaveChangesNotSaved();
            }
        });

        this.subs.sink = this.currentConfig.subscribe((config) => {
            if (config !== null) {
                this.checkIfDashboardHaveChangesNotSaved();
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.currentDashboard.next(null);
        this.savedFilters.next(null);
        this.currentFilters.next(null);
        this.currentConfig.next(null);
        this.lastAppliedFilters.next(null);
        this.dashboardData.next(null);
        this.currentVerbatimColumns.next(null);

        this.currentDashboard.unsubscribe();
        this.savedFilters.unsubscribe();
        this.currentFilters.unsubscribe();
        this.currentConfig.unsubscribe();
        this.lastAppliedFilters.unsubscribe();
        this.dashboardData.unsubscribe();
        this.currentVerbatimColumns.unsubscribe();
        this.hasFilterChangesNotApplied.unsubscribe();
        this.filtersCount.unsubscribe();

        super.ngOnDestroy();
    }

    /**
     * Création d'un copie le dashboard courant avec un nouveau nom. le dashboard courant reste inchangé. Le nouveau dashboard créé sera sélectionné
     * @param newName nouveau nom du dashboard
     */
    public async saveAs(newName: string, type?: DashboardType): Promise<number> {
        this.currentDashboard.value.dash_json_params.selected_all = this.currentFilters.value.selected_all;
        this.currentDashboard.value.dash_json_params.selected_not = this.currentFilters.value.selected_not;

        try {
            return (await firstValueFrom(this.dashboardApi.postV1Dashboard({
                dash_parent_id: this.currentDashboard.getValue().dash_id,
                corp_id: this.currentDashboard.value.dash_fk_corp_id,
                name: newName,
                shared: SharedStatus.NONE,
                json_params: _.cloneDeep(
                    this.currentDashboard.value.dash_json_params,
                ), // on fait un cloneDeep car le paramètre period est migré d'une structure à l'autre
                json_config: _.cloneDeep(
                    this.currentConfig.getValue(),
                ), // on fait un cloneDeep car le paramètre period est migré d'une structure à l'autre
                type: type || this.currentDashboardType,
            }))).id;
        } catch (err) {
            this.displayServerError(err.error);
            return undefined;
        }
    }

    /**
     * Mise à jour du dashboard courant
     */
    public async save(): Promise<Dashboardforuser | null> {
        this.currentDashboard.value.dash_json_params.selected_all = this.currentFilters.value.selected_all;
        this.currentDashboard.value.dash_json_params.selected_not = this.currentFilters.value.selected_not;
        this.currentDashboard.value.dash_json_config = _.cloneDeep(this.currentConfig.getValue());

        try {
            return await firstValueFrom(this.dashboardApi.updateDashboard({
                dashId: this.currentDashboard.value.dash_id,
                body: {
                    name: this.currentDashboard.value.dash_name,
                    json_params: _.cloneDeep(
                        this.currentDashboard.value.dash_json_params,
                    ),
                    json_config: _.cloneDeep(
                        this.currentDashboard.value.dash_json_config,
                    ),
                },
            })).then((value) => {
                this.savedFilters.next(_.cloneDeep(this.lastAppliedFilters.getValue()));
                return value;
            });
        } catch (error) {
            this.displayServerError(error?.error);
            return null;
        }
    }

    /**
     * Sélection du dashboard passé en paramètre
     * @param dashboard le dashboard à sélectionner
     */
    public selectDashboardObject(dashboard: Dashboardforuser) {
        // conversion du dashboard en filtres. On met à jour le dernier filtrage sauvegardé, et le filtrage courant
        this.savedFilters.next(this.dashboard2Filters(dashboard));
        this.currentConfig.next(_.cloneDeep(dashboard.dash_json_config));
        this.currentFilters.next(this.dashboard2Filters(dashboard));
        this.lastAppliedFilters.next(_.cloneDeep(this.currentFilters.value));
        this.currentDashboard.next(_.cloneDeep(dashboard));

        this.hasFilterChangesNotApplied.next(false);
        this.store.dispatch(new SetDashModified({ dashboardId: dashboard.dash_id, isDashModified: false }));

        // Récupère les colonnes de verbatim
        this.onGetVerbatimColumns();
    }

    /**
     * Recherche des verbatim en fonction du filtrage courant (qui contient aussi les paramètres d'exploration des verbatim en plus du filtrage)
     */
    public loadDashboardData() {
        const { page } = this.currentFilters.getValue();
        if (this.currentDashboardType === DashboardType.ANALYSE) {
            this.dashboardApi
                .postV1DashboardDashIdData({
                    dashId: this.currentDashboard.value.dash_id,
                    body: {
                        ...this.lastAppliedFilters.getValue(),
                        ...this.getApiDates(this.lastAppliedFilters.getValue()),
                        page,
                    },
                    page,
                })
                .subscribe(
                    (data) => {
                        this.dashboardData.next(data);
                    },
                    (error) => {
                        this.displayServerError(error?.error);
                    },
                );
        } else if (this.currentDashboardType === DashboardType.TONE) {
            this.dashboardApi
                .postV1DashboardDashIdTone({
                    dashId: this.currentDashboard.value.dash_id,
                    body: {
                        ...this.lastAppliedFilters.getValue(),
                        ...this.getApiDates(this.lastAppliedFilters.getValue()),
                    },
                })
                .subscribe(
                    (data) => {
                        this.dashboardData.next(data);
                    },
                    (error) => {
                        this.displayServerError(error?.error);
                    },
                );
        }
    }

    /**
     * Affiche une popin d'erreur lorsque l'on a une erreur serveur
     */
    displayServerError(error) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
        if (error?.details && error?.details !== '') {
            modal.componentInstance.content = error?.details;
        } else {
            modal.componentInstance.contentToTranslate = `translations.httpErrors.${error?.error || 'title'}`;
        }
        modal.componentInstance.alertTypeVariant = 'warning';
    }

    /**
     * En fonction des paramètre de période, l'intervalle de ercherche peut être modifié
     * @param dateRangeConfig
     * @param period
     * @returns
     */
    getApiDates(filters = this.currentFilters.getValue()) {
        let startDate; let
            endDate;
        const { period } = filters;

        if (period === 998) {
            // période complète -> on fonrce la date de début et de fin du corpus
            startDate = moment(
                this.corpusService.currentCorpus.value.corp_min_date,
                'YYYY-MM-DD',
            );
            endDate = moment(
                this.corpusService.currentCorpus.value.corp_max_date,
                'YYYY-MM-DD',
            );
        } else if (period === 999) {
            // période personnalisée -> les date paramétrées
            startDate = filters.date_begin;
            endDate = filters.date_end;
        } else {
            startDate = moment(
                this.corpusService.currentCorpus.value.corp_max_date,
                'YYYY-MM-DD',
            )
                .subtract(period - 1, 'months')
                .startOf('month')
                .toDate();
            endDate = moment(
                this.corpusService.currentCorpus.value.corp_max_date,
                'YYYY-MM-DD',
            );
        }

        return {
            date_begin: moment(startDate).format('YYYY-MM-DD'),
            date_end: moment(endDate).format('YYYY-MM-DD'),
        };
    }

    /**
     * Récupère les colonnes disponibles pour le verbatim sélectionné du corpus en cours.
     */
    async onGetVerbatimColumns() {
        await this.corpusApi.getV1CorpusCorpIdColumns(
            this.currentDashboardType,
            this.corpusService.currentCorpus.getValue().corp_id,
            this.currentFilters.value.verbatim_dbname,
        ).subscribe((cols) => {
            this.currentVerbatimColumns.next(cols);
        });
    }

    /**
     * Vérifie la syntaxe de la recherche libre
     */
    async freeSearchValidator(): Promise<boolean> {
        if (this.currentFilters.value.freesearch !== '') {
            const validation = await this.queryValidatorService.checkQuery(this.currentFilters.value.freesearch);
            if (!validation.isValid) {
                this.onSearchInvalid.emit(validation.errorDetails);
            }
            return validation.isValid;
        }
        return true;
    }

    /**
     * Vérifie si le filtrage courant a changé mais n'a pas été appliqué pour l'affichage du bouton appliquer
     */
    async applyNewFilters() {
        const isSearchValid = await this.freeSearchValidator();
        if (isSearchValid) {
            // on test si la freesearch a changé pour logguer un évènement google analytics
            if (this.currentFilters.getValue().freesearch !== this.lastAppliedFilters.getValue().freesearch) {
                gtmClick({
                    track_category: 'exploitation des verbatim',
                    track_name: 'recherche verbatim',
                });
            }
            // Récupère les colonnes disponibles pour le verbatim sélectionné si la colonne de verbatim a changée
            if (this.lastAppliedFilters.getValue() && this.currentFilters.getValue().verbatim_dbname !== this.lastAppliedFilters.getValue().verbatim_dbname) {
                await this.onGetVerbatimColumns();
            }

            gtmClick({
                track_category: 'filtrage sur verbatim',
                track_name: 'appliquer filtres',
            });
            // On sauvegarde les valeurs des filtres pour tester les différences par la suite
            this.lastAppliedFilters.next(_.cloneDeep(this.currentFilters.value));

            if (this.currentDashboardType === DashboardType.ANALYSE) {
                this.setPertinenceSort(!this.isPertinenceDisabled());
            }
            if (this.currentConfig.getValue().verbatim?.pert_is_checked) {
                this.currentFilters.getValue().search_fields.forEach((c) => {
                    if (c.type !== SearchFieldType.SORT_ANNO && c.type !== SearchFieldType.SORT_FAVO) {
                        delete c.sort;
                    }
                });
            }

            this.checkIfDashboardHaveChangesNotSaved();
            // Envoi de l'évènement que les nouveaux filtres sont bien appliqués
            this.newFiltersIsApplied.emit();
            // On envoi l'évèment pour dire qu'il n'y a plus de différence entre les filtres sélectionnés et les filtres appliqués
            this.hasFilterChangesNotApplied.next(false);
        }
    }

    /**
     * Vérifie si le filtrage courant a changé mais n'a pas été appliqué pour l'affichage du bouton appliquer
     */
    public checkIfFiltersHaveChangesNotApplied(params):boolean {
        if (this.lastAppliedFilters.value === null) {
            return false;
        }
        // les filtres ont changés, on les compare avec les derniers filtres appliqués pour savoir si ils ont changés et peuvent être appliqués
        const changes = diff(params, this.lastAppliedFilters.value);
        // on exclue de la différence les filtres qui ne dont pas parties des filtres sauvegardés
        this.searchParamsIgnoredForApply.forEach((key) => {
            delete changes[key];
        });

        this.hasFilterChangesNotApplied.next(!_.isEmpty(changes));
        return !_.isEmpty(changes);
    }

    /**
     * Vérifie si le filtrage appliqué est différent du filtrage sauvegardé pour savoir s'il faut activer la zone d'enregitrement
     */
    private checkIfDashboardHaveChangesNotSaved() {
        if (this.currentDashboard.getValue() === null || this.savedFilters.value === null || this.lastAppliedFilters.value === null || this.currentConfig.getValue() === null) {
            return;
        }

        const hasFiltersNotSaved = this.hasFiltersNotSaved();
        const hasConfigNotSaved = this.hasConfigurationNotSaved();

        this.store.dispatch(new SetDashModified({ dashboardId: this.currentDashboard.getValue().dash_id, isDashModified: (hasFiltersNotSaved || hasConfigNotSaved) }));
    }

    public hasFiltersNotSaved() : boolean {
        // les filtres ont été appliqués, on les compare avec les derniers filtres appliqués pour savoir si ils ont changés et peuvent être appliqués
        const paramsChanges = diff(this.savedFilters.value, this.lastAppliedFilters.value);

        // on exclue de la différence les filtres qui ne dont pas parties des filtres sauvegardés
        this.searchParamsIgnoredForSearch.forEach((key) => {
            delete paramsChanges[key];
        });

        return !_.isEmpty(paramsChanges);
    }

    public hasConfigurationNotSaved(): boolean {
        const configChanged = diff(this.currentDashboard.getValue().dash_json_config, this.currentConfig.getValue());
        return !_.isEmpty(configChanged);
    }

    /**
     *  Méthode générique pour mettre à jour les filtres
     */
    setFilter(filters: any) {
        // update dashbard params
        Object.keys(filters).forEach((f) => {
            this.currentDashboard.value.dash_json_params[f] = JSON.parse(JSON.stringify(filters[f]));
        });
        this.currentFilters.next({ ...this.currentFilters.value, ...filters });
    }

    /**
     * Mise à jour du filtrage sur classification auto
     * @param classes le filtre sur les classification auto
     */
    public updateFilterClasses(classes: Array<Inputnamevalues>) {
        this.setFilter({ filters_class: classes });
    }

    /**
     * Mise à jour de la liste des thématiques
     * @param filters la nouvelle liste des thématiques
     */
    public updateThematicList(filters) {
        // update search params
        this.setFilter({
            thematics: filters.thematics.sort((a, b) => a.them_name.localeCompare(b.them_name)),
            selected_all: filters.selected_all,
            selected_not: filters.selected_not,
            thematic_not: this.getThematicNotFlag(filters.selected_all, filters.selected_not),
        });
        this.onThematicsChanged.emit();
    }

    /**
     * Mise à jour de la free search
     * @param search
     */
    public setFreeSearch(search: string) {
        this.setFilter({ freesearch: search });
    }

    public setKeywordsSearch(keywords: Array<KeywordSearch>) {
        this.setFilter({ keywordsearch: keywords });
    }

    /**
     * Mise à jour du filtre sur les données associées
     * @param filters le filtre sur les données associées
     */
    public updateFilterList(filters: Array<Inputnamevalues>) {
        this.setFilter({ filters });
    }

    /**
     * Mise à jour du filtrage sur les dates
     * @param datebegin la nouvelle date de début
     * @param dateEnd la nouvelle date de fin
     * @param period la nouvelle période
     */
    public updateDates(datebegin: Date, dateEnd: Date, period: number) {
        // update search params
        this.setFilter({
            date_begin: moment(datebegin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            date_end: moment(dateEnd, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            period,
        });
    }

    public updateMinLength(minLength:number) {
        if (minLength === -1) {
            delete this.currentFilters.value.verbatim_length_min;
            delete this.currentDashboard.value.dash_json_params.verbatim_length_min;
            this.currentFilters.next(this.currentFilters.value);
        } else {
            this.setFilter({ verbatim_length_min: minLength });
        }
    }

    public updateMaxLength(maxLength:number) {
        if (maxLength === -1) {
            delete this.currentFilters.value.verbatim_length_max;
            delete this.currentDashboard.value.dash_json_params.verbatim_length_max;
            this.currentFilters.next(this.currentFilters.value);
        } else {
            this.setFilter({ verbatim_length_max: maxLength });
        }
    }

    /**
     * @returns le modèle correspondant à la colonne de verbatim sélectionnée sur le dashboard courant
     */
    public getCurrentModel(): Models {
        if (!this.lastAppliedFilters.getValue()) {
            return null;
        }
        const verbatimDbName = this.lastAppliedFilters.getValue().verbatim_dbname;
        return this.corpusService.currentCorpus.getValue().models[verbatimDbName];
    }

    /**
     * Mise à jour de la colonne de verbatim
     * @param verbatimDbName
     */
    public updateVerbatimDbName(verbatimDbName: string) {
        const newFilters:any = { verbatim_dbname: verbatimDbName };

        // modèle correspondant à la colonne de verbatim avant changement
        const previousModel: string = this.getCurrentModel()?.model ?? '';
        // nouveau model
        const currentModel: string = this.corpusService.currentCorpus.getValue().models[verbatimDbName]?.model ?? '';

        // si le modèle a changé (ce n'est pas forcément le cas), on efface les filtres sur la classification automatique (car liés au modèle)
        if (previousModel !== currentModel) {
            newFilters.filters_class = [];

            // on efface également les cibles d'opinion
            newFilters.filters = this.currentFilters.getValue().filters.filter((filter) => !filter.inputName.startsWith('ote'));
        }

        // Apply new filters
        this.setFilter(newFilters);
    }

    /**
     * Mise à jour de la page sélectionnée dans le tableau des verbatims
     * @param page
     */
    public setPage(page: number) {
        this.currentFilters.value.page = page;
    }

    public setUserChoices(userChoices: Array<Choice>) {
        this.currentConfig.getValue().userChoices = userChoices;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setAssociatedDataDisplay(value: AssociatedDataDisplay) {
        this.currentConfig.getValue().associated_data_display = value;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setVerbatimConfig(field, value) {
        this.currentConfig.getValue().verbatim[field] = value;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setCloudCategory(category, subCategory) {
        this.currentConfig.getValue().verbatim.cloud_category = category;
        this.currentConfig.getValue().verbatim.cloud_sub_category = subCategory;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setSatisfactionConfig(type, graphtype, params) {
        this.currentConfig.getValue().satisfaction[type][graphtype] = params;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setCopcConfig(config: CopcConfig) {
        this.currentConfig.getValue().copc = config;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setHeatmapConfig(params) {
        this.currentConfig.getValue().heatmap = params;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setDistributionConfig(type, params) {
        this.currentConfig.getValue()[type] = params;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setEvoTempConfig(params) {
        this.currentConfig.getValue().evoTemp = params;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setSelectedColumns(columns: Array<SearchField>) {
        this.lastAppliedFilters.getValue().search_fields = _.cloneDeep(columns);
        this.currentDashboard.getValue().dash_json_params.search_fields = _.cloneDeep(columns);

        const sortFavo = columns.find((column) => column.DBName === 'sort_favo')?.checked || false;
        const sortAnno = columns.find((column) => column.DBName === 'sort_anno')?.checked || false;
        this.currentConfig.getValue().sort_favo = sortFavo;
        this.currentConfig.getValue().sort_anno = sortAnno;

        this.currentConfig.getValue().verbatim.selected_columns = columns;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setPertinenceSort(pertIsChecked) {
        this.currentConfig.getValue().verbatim.pert_is_checked = pertIsChecked;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public setFiltersSimilarity(isChecked) {
        this.currentConfig.getValue().filters.similarity_is_checked = isChecked;
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public getLlmProfileConfig(id: number) : LlmProfileConfig {
        const defaultConfig = {
            id,
            nb_values_chart: 10,
            yaxis: Yaxis.percent,
            xaxis_sort: XaxisSort.occur_desc,
            hidden_profile: false,
            hidden_themes_cards: [],
            hidden_themes_graph: [],
            selected_theme_details: null,
        };

        let currentConfig = this.currentConfig.getValue().llmProfileConfig.find((c) => c.id === id);

        if (!currentConfig) {
            currentConfig = {};
            this.currentConfig.getValue().llmProfileConfig.push(currentConfig);
        }

        this.dashboardApi.populateDefaultFields(currentConfig, defaultConfig);

        let savedConfig = this.currentDashboard.getValue().dash_json_config.llmProfileConfig.find((c) => c.id === id);
        if (!savedConfig) {
            savedConfig = {};
            this.currentDashboard.getValue().dash_json_config.llmProfileConfig.push(savedConfig);
        }
        this.dashboardApi.populateDefaultFields(savedConfig, defaultConfig);

        return currentConfig;
    }

    public setLlmProfileConfig(id: number, config: LlmProfileConfig) {
        const index = this.currentConfig.getValue().llmProfileConfig.findIndex((c) => c.id === id);

        if (index >= 0) {
            this.currentConfig.getValue().llmProfileConfig[index] = config;
        } else {
            this.currentConfig.getValue().llmProfileConfig.push(config);
        }
        this.currentConfig.next(this.currentConfig.getValue());
    }

    public isPertinenceDisabled() {
        // si il n'y a pas de recherche textuelle et qu'aucune thématique n'est sélectionné, le tri par pertinence est désactivé
        return this.currentFilters.getValue().freesearch === '' && this.currentFilters.getValue().thematics.filter((t) => t.selected).length === 0;
    }

    /**
     * Conversion d'un dashboard en filtres
     */
    dashboard2Filters(
        dashboard: Dashboardforuser,
        page: number = 1,
    ) {
        const filters = _.cloneDeep({
            date_begin: dashboard.dash_json_params.date_begin,
            date_end: dashboard.dash_json_params.date_end,
            period: dashboard.dash_json_params.period * 1,
            filters: dashboard.dash_json_params.filters,
            filters_class: dashboard.dash_json_params.filters_class,
            verbatim_dbname: dashboard.dash_json_params.verbatim_dbname,
            thematics: dashboard.dash_json_params.thematics,
            selected_all: dashboard.dash_json_params.selected_all,
            selected_not: dashboard.dash_json_params.selected_not,
            thematic_not: this.getThematicNotFlag(dashboard.dash_json_params.selected_all, dashboard.dash_json_params.selected_not),
            search_fields: dashboard.dash_json_config.verbatim.selected_columns,
            // sort_by_date: sortByDate,
            freesearch: dashboard.dash_json_params.freesearch || '',
            keywordsearch: dashboard.dash_json_params.keywordsearch,
            page,
        });
        if (Object.prototype.hasOwnProperty.call(dashboard.dash_json_params, 'verbatim_length_max')) {
            filters.verbatim_length_max = dashboard.dash_json_params.verbatim_length_max;
        }
        if (Object.prototype.hasOwnProperty.call(dashboard.dash_json_params, 'verbatim_length_min')) {
            filters.verbatim_length_min = dashboard.dash_json_params.verbatim_length_min;
        }
        return filters;
    }

    /**
     * Cette méthide calcule la valeur du flag thematic not qui existe dans les api de recherche, mais pas sur le dashboard
     */
    // eslint-disable-next-line class-methods-use-this
    getThematicNotFlag(selected_all:boolean, selected_not:boolean): boolean {
        return (!selected_all && selected_not);
    }

    /**
     * Changemet de la valeur d'un compteur de filtre sur un filtre unitaire pour mise à jour du compteur global
     * @param previousValue ancienne valeur du compteur du filtre
     * @param currentValue nouvelle valeur du compteur du filtre
     */
    countChange(previousValue:number, currentValue:number) {
        const total = this.filtersCount.getValue()
            - (previousValue || 0)
            + currentValue;
        this.filtersCount.next(total);
    }

    downloadKeywords(keywordType: string) {
        const dashId :number = this.currentDashboard.getValue().dash_id;
        const cleanKeywordType = keywordType.replace('top_', '');
        this.dashboardApi.downloadKeywords(dashId, cleanKeywordType, this.lastAppliedFilters.getValue());
    }

    getCorpusService() {
        return this.corpusService;
    }

    getCollapse(key: string): boolean {
        return this.collapses[key];
    }

    toggleCollapse(key:string, value: boolean) {
        this.collapses[key] = value;
    }
}

export interface NewFiltersIsAppliedParams {
    keepVerbatimPage?:boolean;
}
