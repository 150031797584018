import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import {
    Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { User } from '../../api/models/user';

/**
 * Check if user is logged
 *
 * @export
 * @class AuthGuardService
 * @implements {CanActivate}
 */
@Injectable()
export default class AuthGuardService implements CanActivate {
    /**
   *Creates an instance of AuthGuardService.
   * @param {Router} router
   * @param {Store} store
   * @memberof AuthGuardService
   */
    constructor(
        private router: Router,
        private store: Store,
        private logger: NGXLogger,
    ) {}

    /**
   * Check if user is logged
   *
   * @returns {boolean}
   * @memberof AuthGuardService
   */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const userProfil: User = this.store.snapshot().user;
        if (_.isEmpty(userProfil)) {
            this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        return true;
    }
}
