// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-dinootoo {
  position: absolute;
  z-index: 996;
  top: 0;
  bottom: 0;
  right: 20px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2), 0 0 14px 2px rgba(0, 0, 0, 0.12), 0 0 5px -3px rgba(0, 0, 0, 0.2);
}
.chat-dinootoo .chat-container {
  display: flex;
  width: 700px;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.chat-dinootoo ::ng-deep .chat-body {
  padding-bottom: 80px;
  overflow: auto;
  max-width: 100%;
}
.chat-dinootoo ::ng-deep .chat-body.space-for-prompt-action {
  padding-bottom: 150px;
}
.chat-dinootoo ::ng-deep .chat-item {
  display: flex;
  padding: 10px 10px 0;
  gap: 5px;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
}
.chat-dinootoo ::ng-deep .chat-item .message-icon.vcrm-avatar {
  align-self: flex-end;
}
.chat-dinootoo ::ng-deep .chat-item .message-icon::before {
  font-size: 25px;
}
.chat-dinootoo ::ng-deep .chat-item .message {
  padding: 10px;
  line-height: 24px;
  flex-grow: 1;
  overflow: hidden;
}
.chat-dinootoo ::ng-deep .chat-item .message.intro {
  border-radius: 20px;
  border: 1px solid var(--grey-ods-color-grey-300, #ddd);
}
.chat-dinootoo ::ng-deep .chat-item .message.question {
  border-radius: 20px 20px 0 0;
  border: 1px solid var(--ods-ref-color-gray-500, #999);
}
.chat-dinootoo ::ng-deep .chat-item .message.question ::ng-deep {
  /* stylelint-disable-next-line max-nesting-depth */
  /* stylelint-disable-next-line max-nesting-depth */
}
.chat-dinootoo ::ng-deep .chat-item .message.question ::ng-deep * {
  word-wrap: break-word;
}
.chat-dinootoo ::ng-deep .chat-item .message.question ::ng-deep p:last-child {
  margin-bottom: 0;
}
.chat-dinootoo ::ng-deep .chat-item .message.answer {
  border-radius: 0 0 20px 20px;
  border: 1px solid var(--grey-ods-color-grey-400, #ccc);
}
.chat-dinootoo ::ng-deep .chat-item .message.answer.loading {
  margin-right: 30px;
  text-align: center;
}
.chat-dinootoo ::ng-deep .chat-item .btn-action i::before {
  font-size: 22px;
  color: #666;
}
.chat-dinootoo ::ng-deep .chat-item.chat-loading {
  margin-bottom: 100px;
}
.chat-dinootoo ::ng-deep .info-tokens {
  color: #999;
  font-size: 12px;
  padding-left: 52px;
  margin-bottom: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
