import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { ThematicService } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ThematicEditorComponent from 'app/modules/thematics/components/thematic-editor/thematic-editor.component';
import ThemesListModalComponent from 'app/modules/corpus/corpus-analyses/components/themes-list-modal/themes-list-modal.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import ManageDashboardService from '../../corpus-manage-dashboard.service';

@Component({
    selector: 'app-thematics-dropdown',
    templateUrl: './thematics-dropdown.component.html',
    styleUrls: ['./thematics-dropdown.component.scss'],
})
export default class ThematicsDropdownComponent implements OnInit {
    @Input()
        selectedThematics = { selected_all: false, selected_not: false, thematics: [] };

    @Input()
        btnToggleClass: string = 'btn btn-icon';

    @Input()
        gtmTrackCategory: string = '';

    @Input()
        dashboardService: DashboardService;

    lang:string = '';

    constructor(
        private modalService: NgbModal,
        private store: Store,
        private thematicService: ThematicService,
        private corpusService: CorpusService,
        private manageDashboardService: ManageDashboardService,
    ) {
    }

    ngOnInit(): void {
        this.lang = this.store.snapshot().corpus.corp_lang.toUpperCase();
    }

    onSettings(create:boolean) {
        if (this.gtmTrackCategory) {
            gtmClick({
                track_category: this.gtmTrackCategory,
                track_name: `${create ? 'création' : 'sélection'} thématique`,
            });
        }
        if (create) {
            this.openCreateThematicModal();
        } else {
            this.openSelectThematicsModal();
        }
    }

    openSelectThematicsModal() {
        this.thematicService.postV1Thematic({
            lang: this.lang,
            corp_id: this.corpusService.currentCorpus.getValue().corp_id,
        })
            .subscribe((tl) => {
                const allThematics = JSON.parse(JSON.stringify(tl));
                const modal = this.modalService.open(ThemesListModalComponent, {
                    modalDialogClass: 'thematic-modal',
                    backdrop: 'static',
                });

                modal.componentInstance.dashboardService = this.dashboardService;
                modal.componentInstance.allThematics = allThematics;
                modal.componentInstance.selectedThematics = this.selectedThematics.thematics;
                modal.componentInstance.addThemes.subscribe((thematics) => {
                    modal.close();
                    this.manageDashboardService.addThematics(thematics, this.dashboardService);
                });
            });
    }

    openCreateThematicModal() {
        const modal = this.modalService.open(ThematicEditorComponent, { backdrop: 'static', modalDialogClass: 'thematic-modal' });
        modal.componentInstance.gtmTrackCategory = 'filtrage sur verbatim';
        modal.componentInstance.save.subscribe((thematic) => {
            modal.close();
            thematic.lang = this.lang.toUpperCase();
            this.thematicService.postV1Thematic_1(thematic).subscribe((thId) => {
                const newThematicsList = [{
                    them_name: thematic.name,
                    them_type: 'rule',
                    them_definition: thematic.definition,
                    lang: thematic.lang,
                    selected: true,
                    them_id: thId.id,
                    perso: true,
                    type_sharing: '0',
                }, ...(this.dashboardService.currentFilters.getValue().thematics || [])];
                this.manageDashboardService.addThematics(newThematicsList, this.dashboardService);
            });
        });
    }
}
