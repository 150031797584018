import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserService } from 'app/api/services';
import { SetLang } from 'app/stores/actions/settings/settings.actions';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/api/models';
import UserState from 'app/stores/state/user/user.state';
import * as _ from 'lodash';
import { SetUser } from 'app/stores/actions/user/user.actions';
import { BaseComponent } from 'app/base.component';

@Injectable({
    providedIn: 'root',
})
export default class AppTranslateService extends BaseComponent {
    user$: Observable<User>;

    constructor(
        private store: Store,
        private userService: UserService,
        private translate: TranslateService,
    ) {
        super();

        this.translate.addLangs(['en', 'fr']);

        const browserLang = translate.getBrowserLang();

        this.translate.setDefaultLang(
            browserLang.match(/en|fr/) ? browserLang.toLowerCase() : 'en',
        );

        this.user$ = this.store.select(UserState);
        this.subs.sink = this.user$.subscribe((state: User) => {
            if (!_.isEmpty(state)) {
                this.translate.use(state.preferred_language.toLowerCase());
            }
        });
    }

    initChangeLangue(lang) {
        const { user } = this.store.snapshot();
        if (user.username) {
            this.userService
                .patchV1UserUsername({
                    username: user.username,
                    body: { preferred_language: lang.toUpperCase() },
                })
                .subscribe(() => {
                    // Set lang in store
                    this.store.dispatch(new SetLang(lang));
                    this.translate.use(lang);
                    // Update user lang in store
                    user.preferred_language = lang;
                    this.store.dispatch(new SetUser(user));
                });
        } else {
            this.store.dispatch(new SetLang(lang));
            this.translate.use(lang);
        }
    }
}
