import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import GdprGuardService from 'app/utils/services/gdprguard.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import SharedModule from 'app/shared/shared.module';
import AuthGuardService from 'app/utils/services/authguard.service';
import QuitNewCorpusService from 'app/utils/services/quit-new-corpus.service';
import RouterTraceService from 'app/utils/services/router-trace.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import Etape1nameComponent from './etape1name/etape1name.component';
import Etape2uploadComponent from './etape2upload/etape2upload.component';
import Etape4messageComponent from './etape4message/etape4message.component';
import Etape3dateComponent from './etape3date/etape3date.component';
import Etape5filtersComponent from './etape5filters/etape5filters.component';
import Etape6confirmComponent from './etape6confirm/etape6confirm.component';
import Etape7feedbackComponent from './etape7feedback/etape7feedback.component';
import NewProjectComponent from './new-project.component';
import Etape2uploadCsvxlsComponent from './etape2upload/etape2upload-csvxls/etape2upload-csvxls.component';
import Etape2uploadMsurveyAccountComponent from './etape2upload/etape2upload-msurvey-account/etape2upload-msurvey-account.component';
import Etape2uploadMsurveyUrlComponent from './etape2upload/etape2upload-msurvey-url/etape2upload-msurvey-url.component';

const NEWPROJECT: Routes = [
    {
        path: 'new-project',
        component: NewProjectComponent,
        canActivate: [
            RouterTraceService,
            AuthGuardService,
            GdprGuardService,
        ],
        data: {
            meta: {
                title: 'VisualCRM New project',
            },
        },
        canDeactivate: [QuitNewCorpusService],
        children: [
            {
                path: '',
                component: Etape1nameComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Name',
                    },
                },
            },
            {
                path: 'upload',
                component: Etape2uploadComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Upload',
                    },
                },
            },
            {
                path: 'message',
                component: Etape4messageComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Message',
                    },
                },
            },
            {
                path: 'date',
                component: Etape3dateComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Date',
                    },
                },
            },
            {
                path: 'columns',
                component: Etape5filtersComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Filters',
                    },
                },
            },
            {
                path: 'confirm',
                component: Etape6confirmComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Confirm',
                    },
                },
            },
            {
                path: 'feedback',
                component: Etape7feedbackComponent,
                canActivate: [RouterTraceService],
                data: {
                    meta: {
                        title: 'VisualCRM New project - Feedback',
                    },
                },
            },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        TooltipModule,
        RouterModule.forChild(NEWPROJECT),
        FormsModule,
        ReactiveFormsModule,
        CollapseModule,
        TranslateModule.forChild(),
        SharedModule,
        BsDatepickerModule.forRoot(),
        NgbAlertModule,
        NgbDropdownModule,
    ],
    declarations: [
        NewProjectComponent,
        Etape1nameComponent,
        Etape2uploadComponent,
        Etape4messageComponent,
        Etape3dateComponent,
        Etape5filtersComponent,
        Etape6confirmComponent,
        Etape7feedbackComponent,
        Etape2uploadCsvxlsComponent,
        Etape2uploadMsurveyAccountComponent,
        Etape2uploadMsurveyUrlComponent,
    ],
    providers: [
        RouterTraceService,
        QuitNewCorpusService,
        AuthGuardService,
        GdprGuardService,
    ],
})
export default class NewProjectModule {}
