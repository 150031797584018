// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etape4-message {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.etape4-message .css-grid-container {
  display: grid;
  grid-template-columns: 800px auto;
  grid-template-rows: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-areas: "title title" "import-choice ." "remaining-info remaining-info";
  background-color: white;
}
.etape4-message .css-import-choice {
  grid-area: import-choice;
  margin-bottom: 15px;
}
.etape4-message .css-project-title {
  grid-area: title;
}
.etape4-message .css-project-remaining {
  grid-area: remaining-info;
  margin-bottom: 0;
}
.etape4-message .info-text {
  font-size: 14px;
}
.etape4-message .stop-word {
  padding: 15px 20px;
}

.model {
  font-size: 16px;
  font-weight: normal;
}

.name {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.name:hover {
  color: #f16e00;
}

.explanation {
  font-size: 14px;
}

.pad {
  padding-top: 10px;
}

.my-bg {
  background-color: rgba(238, 238, 238, 0.9333333333);
}

.modal-dialog.modal-lg {
  min-width: 1340px;
  min-height: 580px;
}

.my-marg {
  margin-right: 10px;
}

.my-marg-left {
  margin-left: 25px;
}

.my-size {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  font-size: 30px;
}

.my-btn {
  max-width: 30px;
  max-height: 30px;
}
.my-btn:hover {
  color: #f16e00;
}

.modal-step4 .modal-body {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.modal-step4 .modal-footer {
  padding-top: 0 !important;
}
.modal-step4 .modal-content {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.modal-verbatim-language {
  min-height: 450px;
}
.modal-verbatim-language .select-language .dropdown-toggle {
  min-width: 250px;
  justify-content: left;
}
.modal-verbatim-language .select-language .dropdown-toggle::after {
  position: absolute;
  right: 10px;
}
.modal-verbatim-language .select-language .dropdown-menu {
  min-width: 560px;
}
.modal-verbatim-language .select-language .dropdown-item {
  width: 33.3333%;
  display: inline-block;
}
.modal-verbatim-language .row-models .col-infos-models,
.modal-verbatim-language .row-models .col-models {
  max-height: 300px;
  overflow: auto;
}

.choice-modal-body {
  overflow-y: hidden !important;
}
.choice-modal-body .col-infos {
  background-color: #eee;
  font-size: 0.875rem;
}
.choice-modal-body .col-infos .row-sat-infos,
.choice-modal-body .col-infos .row-reco-infos {
  padding: 15px;
}
.choice-modal-body .col-infos .row-sat-infos {
  border-bottom: 1px solid #ccc;
}
.choice-modal-body .col-infos .img-asso {
  min-width: 40px;
}
.choice-modal-body .col-infos .infos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.modal-dialog .modal-content .modal-body.score-modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.form-check-input {
  min-width: 16px;
}

.category {
  border-bottom: 1px solid #ccc;
}

.score-modal-body {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.score-modal-body .delta-sat-table,
.score-modal-body .reco-table {
  min-height: 160px;
}
.score-modal-body .row {
  flex-grow: 1;
}
.score-modal-body .col-table {
  display: flex;
  flex-direction: column;
}
.score-modal-body .col-range {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}
.score-modal-body .col-range.disable {
  color: #ccc;
}
.score-modal-body .row-reco {
  padding-top: 20px;
  margin-top: 25px;
  border-top: 1px solid #ccc;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
