import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

@Component({
    selector: 'app-number-selector',
    templateUrl: './number-selector.component.html',
    styleUrls: ['./number-selector.component.scss'],
})
export default class NumberSelectorComponent implements OnInit {
    @Output()
    private selected: EventEmitter<number> = new EventEmitter();

    @Output()
    private validated: EventEmitter<number> = new EventEmitter();

    @Input() disabled: boolean = false;

    @Input() value: number = 0;

    @Input() min: number = null;

    @Input() max: number = null;

    @Input() id: string;

    timeout;

    ngOnInit(): void {
        this.checkBounds();
    }

    add(delta:number) {
        this.update(this.value * 1 + delta);
        this.validate();
    }

    update(value: number) {
        clearTimeout(this.timeout);
        this.value = value * 1;
        this.checkBounds();
    }

    validate() {
        if (this.min !== null && this.value < this.min) {
            this.value = this.min * 1;
            this.selected.emit(this.value);
        }
        this.validated.emit(this.value);
    }

    checkBounds() {
        if (this.min !== null && this.value < this.min) {
            this.delayValue(this.min, 1500);
        } else if (this.max !== null && this.value > this.max) {
            this.delayValue(this.max);
        } else {
            this.selected.emit(this.value);
        }
    }

    delayValue(value, delay = 0) {
        this.timeout = setTimeout(() => {
            this.value = value * 1;
            this.selected.emit(this.value);
        }, delay);
    }
}
