import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';

import { googleAnalyticsHeadScripts } from './libs/ga';

// enable prodMode
if (environment.production) {
  enableProdMode();
}

/**
 * init app
 *
 */
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

googleAnalyticsHeadScripts();

// enable hmr
if (environment.hmr) {
  if (module['hot']) {
    // launch hmr app
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  // launch app
  bootstrap();
}
