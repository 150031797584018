import drawDashboardNumber from './chartjs-plugins-utils';

export const addDashboardNumberToLineChart = {
    id: 'addDashboardNumberToLineChart',
    afterDatasetsDraw(chart) {
        if (chart.config._config.type !== 'line') {
            return;
        }

        const { ctx } = chart;
        chart._metasets.filter((metaset) => !metaset._dataset.hidden).forEach((metaset) => {
            const firstData = metaset.data[0];

            if (firstData && Object.prototype.hasOwnProperty.call(metaset._dataset, 'dashboardNumber')) {
                const { dashboardNumber } = metaset._dataset;
                drawDashboardNumber(ctx, `${dashboardNumber + 1}`, firstData.x - 10, firstData.y);
            }
        });
    },

};
