import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { Field } from 'app/api/models';

/**
 * Display scores info of corpus
 *
 * @export
 * @class ScoreTableComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-score-table',
    templateUrl: './score-table.component.html',
    styleUrls: ['./score-table.component.scss'],
})
export default class ScoreTableComponent implements OnChanges {
    @Input()
    public fields: Array<Field> = [];

    @Input()
    public propScoreField: string;

    @Input()
    public emptyTableMsg: string;

    @Output()
    public inputChange = new EventEmitter<any>();

    public listFields: Array<Field> = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fields) {
            this.listFields = [];
            this.fields.forEach((field) => {
                // On recherche uniquement les fields qui ont la propriété demandée (satField ou recoField)
                if (field[this.propScoreField]) {
                    this.listFields.push(field);
                }
            });
        }
    }

    /**
   * Emit newValue when display name change
   */
    changeProp(field:Field, newValue:string) {
        this.inputChange.emit({
            DBName: field.DBName,
            newValue,
        });
    }
}
