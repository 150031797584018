/* eslint-disable import/no-import-module-exports */
/* eslint-disable no-underscore-dangle */
import {
    Component, OnDestroy, OnInit, TemplateRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DashboardType, Datatone } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { ResetDashModified } from 'app/stores/actions/settings/settings.actions';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import CorpusService from 'app/utils/services/corpus.service';
import GraphService from '../corpus-graph.service';
import ManageDashboardService from '../corpus-manage-dashboard.service';

/**
 * Corpus tones component
 *
 * @export
 * @class CorpusTonesComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus-tones',
    templateUrl: './corpus-tones.component.html',
    styleUrls: ['./corpus-tones.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
    providers: [DashboardService, GraphService],
})
export default class CorpusTonesComponent extends BaseComponent implements OnInit, OnDestroy {
    public labels: any = [];

    // boolean for data consolidation
    public isConsolidated: boolean = true;

    public graphMode: any[] = [
        {
            value: true,
            text: 'translations.tones.percentage',
        },
        {
            value: false,
            text: 'translations.tones.absolute',
        },
    ];

    get selectedGraphMode() {
        return this.graphMode.find((mode) => mode.value === this.percentView);
    }

    public percentView = true;

    public doughnutDatas: Object;

    public doughnutOptions: Object;

    public chartPlugins = [ChartjsPluginStacked100];

    bsModalRef: BsModalRef;

    data: Datatone;

    posPercent: number;

    negPercent: number;

    barData: any;

    barOptions: any = {};

    beginDate: string;

    endDate: string;

    etapes: any[];

    search: any;

    exportModalTitle: string;

    modalDataReq: any;

    noDashboard = true;

    activeBtnApplyDisabled: boolean = true;

    dashboardLoaded: boolean;

    smallLabels: any[];

    savedName: any;

    maxNumber: number = 0;

    items;

    public dashboardHasNoData = false;

    constructor(
        private corpusService: CorpusService,
        private chartTooltips: ChartTooltipsService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private dashboardService: DashboardService,
        private store: Store,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
        this.manageDashboardService.addDashboardService(dashboardService);

        const that = this;
        this.barOptions = {
            layout: {
                padding: {
                    top: 5,
                },
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            indexAxis: 'y',

            plugins: {
                stacked100: { enable: this.percentView },
                labels: false,
                legend: {
                    display: false,
                },
                tooltip: {
                    external(context) {
                        const tooltipModel = context.tooltip;
                        const title = tooltipModel.title ? moment(tooltipModel.title[0]).format('MMMM YYYY') : null;
                        const body = [];
                        const index = tooltipModel.dataPoints ? tooltipModel.dataPoints[0].dataIndex : null;
                        if (tooltipModel.body && index >= 0) {
                            if (context.chart.config.data.verbatims && context.chart.config.data.verbatims.length > 0) {
                                body.push(`${that.barData.verbatims[index]} verbatim`);
                            }

                            // on ajoute la valeur du poids si elle n'est pas présente (moins d'un mois)

                            if (context.chart.config.data.datasets[0].weight) {
                                const label = that.translate.instant('translations.tones.weightRelative');
                                const value = context.chart.config.data.datasets[0].weight[index];
                                if (that.percentView) {
                                    body.push(`<span style="color: #FFF; font-weight: bold">${label}: ${value}% (${value})</span>`);
                                } else {
                                    body.push(`<span style="color: ${label}; font-weight: bold">${label}: ${value}</span>`);
                                }
                            }

                            tooltipModel.body.forEach((b) => {
                                if (b.lines) {
                                    b.lines.forEach((l) => {
                                        const lineColor = context.chart.config.data.datasets.find((dataset) => l.startsWith(dataset.label)).tooltipsColor;
                                        body.push(`<span style="color: ${lineColor}; font-weight: bold">${l}</span>`);
                                    });
                                }
                            });
                        }
                        const text = body.reverse().join('<br>');
                        const html = `
                <h4>${title}${context.chart.config.data.title}</h4>
                <div>${text}</div>
              `;
                        chartTooltips.draw(tooltipModel, context.chart, html);
                    },
                    position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    enabled: false,
                },
            },

            scales: {
                y: {
                    ticks: {
                        display: false,
                        min: 0,
                        max: this.maxNumber,
                        color: '#000',
                    },
                    border: {
                        display: false,
                    },
                    grid: {
                        display: true,
                        tickMarkLength: 0,
                        drawOnChartArea: true,
                    },
                    stacked: true,
                },
                x: {
                    stacked: true,
                    ticks: {
                        display: false,
                        max: this.maxNumber,
                        min: 0,
                        color: '#000',
                    },
                    border: {
                        display: false,
                    },
                    grid: {
                        drawOnChartArea: false,
                        tickMarkLength: 0,
                        drawTicks: true,
                    },
                },
            },

            interaction: {
                mode: 'x',
            },

            onHover(event, items, chart) {
                chart.canvas.style.cursor = (items.length > 0 ? 'pointer' : 'default');
            },

        };
    }

    doSelect($event) {
        gtmClick({
            track_category: 'dashboard tonalité',
            track_name: 'pourcentage valeurs absolues',
            track_cible: 'évènement au niveau du sélecteur',
        });

        this.percentView = $event;
        this.refreshOptions();
        this.draw(this.data);
    }

    refreshOptions() {
        this.barOptions.plugins.stacked100.enable = this.percentView;
        this.barOptions = { ...this.barOptions };
        this.barOptions.indexAxis = Object.keys(this.data.general.period).length === 1 ? 'y' : 'x';
    }

    ngOnInit(): void {
        document.body.classList.add('overflow-hidden');
        this.store.dispatch(new ResetDashModified());

        // lorsqu'un corpus est chargé, on charge tous les dashboards associés
        this.subs.sink = this.corpusService.currentCorpus.subscribe(async (corpus) => {
            if (corpus !== null) {
                this.manageDashboardService.loadDashboardsAfterCorpus(DashboardType.TONE);
            }
        });

        // lorsque l'on sélectionne un dashboard
        this.subs.sink = this.manageDashboardService.dashOrCompSelected.subscribe((dashboards) => {
            this.dashboardService.selectDashboardObject(dashboards[0]);
            this.manageDashboardService.dashboardLoaded.emit();
        });

        this.subs.sink = this.dashboardService.dashboardData.subscribe((data) => {
            if (data !== null) {
                this.dashboardHasNoData = data.general.count_verbatim === 0;
                this.data = data;
                this.refreshOptions();
                this.draw(data);
            }
        });

        this.subs.sink = this.dashboardService.currentDashboard.subscribe((dashboard) => {
            this.noDashboard = dashboard === null;
            if (dashboard) {
                this.dashboardService.loadDashboardData();
            }
        });

        this.subs.sink = this.dashboardService.newFiltersIsApplied.subscribe(() => {
            this.dashboardService.loadDashboardData();
        });

        this.subs.sink = this.dashboardService.hasFilterChangesNotApplied.subscribe((value) => {
            this.activeBtnApplyDisabled = !value;
        });

        const that = this;

        this.doughnutOptions = {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            layout: {
                padding: {
                    left: 0, right: 0, top: 0, bottom: 0,
                },
            },

            cutout: 60,
            plugins: {
                labels: false,
                legend: { display: false },
                tooltip: {

                    external(context) {
                        const tooltipModel = context.tooltip;
                        let body = '';
                        if (tooltipModel.body) {
                            body = tooltipModel.body[0].lines.map((l) => `${l}<br>`);
                        }

                        that.chartTooltips.draw(tooltipModel, context.chart, body);
                    },
                    enabled: false,
                },
            },

        };

        window.addEventListener('resize', () => {
            setTimeout(() => {
                this.flexFont();
            }, 1000);
        });
    }

    flexFont() {
        const divs: any = document.getElementsByClassName('header-col');
        if (divs.length > 0 && Object.prototype.hasOwnProperty.call(divs[0], 'clientWidth')) {
            let relFontsize = Math.round(Math.round(divs[0].clientWidth) * 0.3);
            if (relFontsize > 14) {
                relFontsize = 14;
            }

            if (relFontsize < 10) {
                relFontsize = 10;
                this.labels = [...this.smallLabels];
            } else {
                this.labels = [...this.savedName];
            }

            for (let i = 0; i < divs.length; i += 1) {
                divs[i].style.fontSize = `${relFontsize}px`;
            }
        }
    }

    ngOnDestroy(): void {
        document.body.classList.remove('overflow-hidden');
        this.store.dispatch(new ResetDashModified());
        this.manageDashboardService.removeDashboardService(this.dashboardService);
        super.ngOnDestroy();
    }

    private draw(data: Datatone) {
        const total = data.general.pos + data.general.neg;
        this.posPercent = Math.round((data.general.pos * 100) / total) || 0;
        this.negPercent = Math.round((data.general.neg * 100) / total) || 0;
        this.doughnutDatas = {
            labels: [this.translate.instant('translations.tones.negative'), this.translate.instant('translations.tones.positive')],
            datasets: [
                {
                    data: [data.general.neg, data.general.pos],
                    backgroundColor: ['#CD3C14', '#32C832'],
                    maxBarThickness: 30,
                    hoverBackgroundColor: ['#CD3C14', '#32C832'],
                },
            ],
        };
        const labels = [];
        const mouthLabels = [];
        const smallMouthLabels = [];
        const posDatas = [];
        const negDatas = [];
        const verbatimDatas = [];

        moment.locale(this.translate.currentLang.toLowerCase());
        Object.keys(data.general.period).forEach((k) => {
            labels.push(k);
            let name = moment(k).format('MMM');
            let smallName = moment(k).format('MMM')[0];
            if (moment(k).format('MM') === '12') {
                name = moment(k).format('MMM[<br>]YYYY');
                smallName += moment(k).format('YY');
            }
            if (!data.general.period[k].consolidated) {
                this.isConsolidated = false;
                const tooltipText = `${moment(k).format('MMMM YYYY')} ${this.translate.instant('translations.analysisDashboard.tooltips.nonconsolidated')}`;
                mouthLabels.push({
                    name,
                    tooltips: tooltipText,
                    consolidated: data.general.period[k].consolidated,
                });
                smallMouthLabels.push({
                    name: smallName,
                    tooltips: tooltipText,
                });
            } else {
                mouthLabels.push({
                    name,
                    tooltips: moment(k).format('MMMM YYYY'),
                    consolidated: data.general.period[k].consolidated,
                });
                smallMouthLabels.push({
                    name: smallName,
                    tooltips: moment(k).format('MMMM YYYY'),
                });
            }

            const max = data.general.period[k].pos + data.general.period[k].neg;
            if (max > this.maxNumber) {
                this.maxNumber = max;
            }
            posDatas.push(data.general.period[k].pos);
            negDatas.push(data.general.period[k].neg);
            verbatimDatas.push(data.general.period[k].count_verbatim);
        });
        this.barOptions.scales.y.ticks.max = this.maxNumber;
        this.barOptions.scales.x.ticks.max = this.maxNumber;

        this.beginDate = moment(this.dashboardService.lastAppliedFilters.getValue().date_begin).format('DD MMM YYYY');
        this.endDate = moment(this.dashboardService.lastAppliedFilters.getValue().date_end).format('DD MMM YYYY');

        this.labels = mouthLabels;
        this.savedName = mouthLabels;
        this.smallLabels = smallMouthLabels;
        this.barData = {
            labels,
            title: '',
            verbatims: verbatimDatas,
            datasets: [
                {
                    label: this.translate.instant('translations.tones.negative'),
                    backgroundColor: '#CD3C14',
                    hoverBackgroundColor: '#CD3C14',
                    tooltipsColor: '#CD3C14',
                    borderColor: '#CD3C14',
                    data: negDatas,
                    maxBarThickness: 40,
                },
                {
                    label: this.translate.instant('translations.tones.positive'),
                    backgroundColor: '#32C832',
                    hoverBackgroundColor: '#32C832',
                    tooltipsColor: '#32C832',
                    borderColor: '#32C832',
                    data: posDatas,
                    maxBarThickness: 40,
                },
            ],
        };
        const etapes = [];
        const dataDetailsKeys = Object.keys(data.details_class);
        dataDetailsKeys.forEach((k) => {
            const etapePosDatas = [];
            const etapeNegDatas = [];
            const etapeWeightDatas = [];
            const periodeDataDetailsKeys = Object.keys(data.details_class[k].period);
            let etapeMaxNumber = 0;
            periodeDataDetailsKeys.forEach((key) => {
                etapePosDatas.push(data.details_class[k].period[key].pos);
                etapeNegDatas.push(data.details_class[k].period[key].neg);
                etapeWeightDatas.push(data.details_class[k].period[key].weight);
                const max = data.details_class[k].period[key].pos + data.details_class[k].period[key].neg;
                if (max > etapeMaxNumber) {
                    etapeMaxNumber = max;
                }
            });
            const etapeBarData = {
                labels,
                title: ` > ${data.details_class[k].display_name}`,
                datasets: [
                    {
                        label: this.translate.instant('translations.tones.negative'),
                        backgroundColor: '#CD3C14',
                        hoverBackgroundColor: '#CD3C14',
                        tooltipsColor: '#CD3C14',
                        borderColor: '#CD3C14',
                        data: etapeNegDatas,
                        stack: 'data',
                        maxBarThickness: 40,
                        weight: etapeWeightDatas,
                    },
                    {
                        label: this.translate.instant('translations.tones.positive'),
                        backgroundColor: '#32C832',
                        hoverBackgroundColor: '#32C832',
                        tooltipsColor: '#32C832',
                        borderColor: '#32C832',
                        data: etapePosDatas,
                        stack: 'data',
                        maxBarThickness: 40,
                        weight: etapeWeightDatas,
                    },
                ],
            };
            const sub = [];
            Object.keys(data.details_class[k].details_subclass).forEach((sc) => {
                const elem = data.details_class[k].details_subclass[sc];
                const period = Object.keys(elem.period);
                const label = [];
                const neg = [];
                const pos = [];
                const weight = [];
                let subMaxNumber = 0;
                period.forEach((per) => {
                    label.push(per);
                    pos.push(elem.period[per].pos);
                    neg.push(elem.period[per].neg);
                    weight.push(elem.period[per].weight);
                    const max = elem.period[per].pos + elem.period[per].neg;
                    if (max > subMaxNumber) {
                        subMaxNumber = max;
                    }
                });
                const subOption = _.cloneDeep(this.barOptions, true);
                subOption.scales.y.ticks.max = subMaxNumber;
                sub.push({
                    subclass_ident: elem.ident,
                    labels: label,
                    title: ` > ${data.details_class[k].display_name} > ${elem.display_name}`,
                    subtitle: elem.display_name,
                    neg: elem.neg,
                    pos: elem.pos,
                    negPercent: Math.round((elem.neg * 100) / (elem.neg + elem.pos) || 0),
                    posPercent: Math.round((elem.pos * 100) / (elem.neg + elem.pos) || 0),
                    weight: elem.weight,
                    subOption,
                    datasets: [
                        {
                            label: this.translate.instant('translations.tones.negative'),
                            backgroundColor: '#CD3C14',
                            hoverBackgroundColor: '#CD3C14',
                            tooltipsColor: '#CD3C14',
                            borderColor: '#CD3C14',
                            data: neg,
                            stack: 'data',
                            maxBarThickness: 40,
                            weight,
                        },
                        {
                            label: this.translate.instant('translations.tones.positive'),
                            backgroundColor: '#32C832',
                            hoverBackgroundColor: '#32C832',
                            tooltipsColor: '#32C832',
                            borderColor: '#32C832',
                            data: pos,
                            stack: 'data',
                            maxBarThickness: 40,
                            weight,
                        },
                    ],
                });
            });
            const option = _.cloneDeep(this.barOptions, true);
            option.scales.y.ticks.max = etapeMaxNumber;
            option.plugins.stacked100.enable = this.percentView;
            const totalEtape = data.details_class[k].pos + data.details_class[k].neg;
            const posPercent = Math.round((data.details_class[k].pos * 100) / totalEtape) || 0;
            const negPercent = Math.round((data.details_class[k].neg * 100) / totalEtape) || 0;
            const etape = {
                name: data.details_class[k].display_name,
                pos: data.details_class[k].pos,
                neg: data.details_class[k].neg,
                posPercent,
                negPercent,
                weight: data.details_class[k].weight,
                isCollapsed: true,
                class_ident: data.details_class[k].ident,
                chart: etapeBarData,
                sub,
                option,
            };
            etapes.push(etape);
        });
        this.etapes = etapes;
        setTimeout(() => {
            this.flexFont();
        }, 1000);
        setTimeout(() => {
            this.dashboardLoaded = true;
        });
    }

    openExportVerbatimModal(items, exportVerbatim: TemplateRef<any>, title: string = '', classnb: number = 0, subclass: number = 0) {
        if (!_.isEmpty(items) && items.active.length > 0) {
            const { index } = items.active[0];
            const label = this.barData.labels[index];
            this.modalDataReq = {
                dashId: this.dashboardService.currentDashboard.value.dash_id,
                body: this.dashboardService.lastAppliedFilters.getValue(),
            };
            this.modalDataReq.body.date_end = this.dashboardService.lastAppliedFilters.getValue().date_end;
            this.modalDataReq.body.date_begin = this.dashboardService.lastAppliedFilters.getValue().date_begin;
            this.modalDataReq.body.count = 2;
            this.modalDataReq.body.class_ident = classnb;
            this.modalDataReq.body.subclass_ident = subclass;
            this.exportModalTitle = moment(label).format('MMMM YYYY') + title;
            this.bsModalRef = this.modalService.show(exportVerbatim, {
                backdrop: 'static',
            });
        }
    }

    apply() {
        this.dashboardService.applyNewFilters();
    }

    cancel() {
        this.dashboardService.setFilter(this.dashboardService.lastAppliedFilters.getValue());
    }
}
