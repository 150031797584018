import { Pipe, PipeTransform } from '@angular/core';

/**
 * Format bytes
 *
 * @export
 * @class FileSizePipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'fileSize' })
export default class FileSizePipe implements PipeTransform {
    private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    transform(bytes: number = 0, precision: number = 2): string {
        if (Number.isNaN(parseFloat(String(bytes))) || !Number.isFinite(bytes) || bytes === 0) {
            return '';
        }

        let unit = 0;

        while (bytes >= 1024) {
            // eslint-disable-next-line no-param-reassign
            bytes /= 1024;
            unit += 1;
        }

        return `${bytes.toFixed(+precision)} ${this.units[unit]}`;
    }
}
