import {
    Component, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DistriParams, GroupedValue } from 'app/api/models/distriparams';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { addDashboardNumberToLineChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-line-chart';
import { addDashboardNumberToAxis } from 'app/utils/chartjs-plugins/add-dashboard-number-to-axis';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { LegendItem } from '../../../graphs-generic-components/graph-checkbox-legend/graph-checkbox-legend.component';
import SatisfactionLineChartSettingsPopupComponent from '../satisfaction-line-chart-settings-popup/satisfaction-line-chart-settings-popup.component';
import AbstractLineChartComponent from '../../../graphs-generic-components/abstract-line-chart-component';

@Component({
    selector: 'app-satisfaction-line-chart',
    templateUrl: './satisfaction-line-chart.component.html',
})
export default class SatisfactionLineChartComponent extends AbstractLineChartComponent {
    constructor(
        private modalService: NgbModal,
        translateService: TranslateService,
        private graphService: GraphService,
        private store: Store,
        private manageDashboardService: ManageDashboardService,
        chartTooltips: ChartTooltipsService,
    ) {
        super(chartTooltips, translateService);
    }

    @Input()
        type: string;

    @Output()
        configChanged: EventEmitter<void> = new EventEmitter<void>();

    chartData = { labels: [], datasets: [] };

    legendItems: Array<LegendItem> = [];

    @ViewChild('chart')
        chart: BaseChartDirective;

    plugins = [addDashboardNumberToLineChart, addDashboardNumberToAxis];

    options = {};

    selectedMergedItem;

    // cet input n'a de valeur que sur les composants non fusionnés
    @Input()
        dashboardService: DashboardService;

    get isMerged() {
        return !this.dashboardService;
    }

    getGraphService() {
        return this.graphService;
    }

    getDashboardService() {
        return this.dashboardService;
    }

    @Input()
    set data(data) {
        if (data) {
            this.legendItems = [];

            this.distribution2Axis(data.distribution);
            this.options = this.getGraphOptions();

            this.chartData = {
                labels: this.labels,
                datasets: this.getDatasets(data),
            };
        }
    }

    getGraphOptions() {
        const thisInternal = this;

        const options = {
            responsive: true,
            maintainAspectRatio: true,
            spanGaps: false,
            animation: false,
            layout: {
                padding: {
                    top: 20,
                },
            },
            plugins: {
                addDashboardNumberToLineChart: thisInternal.isMerged,
                addDashboardNumberToAxis: thisInternal.isMerged,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.customTooltips(),
                },
            },
            scales: {
                y: {
                    grid: {
                        display: false,
                    },
                    suggestedMin: 0,
                    ticks: {
                        color: '#000',
                        callback(value) {
                            return `${value}%`;
                        },
                    },

                },
            },

        };

        for (let dashboardNumber = 0; dashboardNumber < this.manageDashboardService.dashboardServices.length; dashboardNumber += 1) {
            const axis = this.axis[`dashboard_${dashboardNumber}`];
            if (axis && !options.scales[axis]) {
                options.scales[axis] = {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        source: 'labels',
                        color: '#000',
                        callback(value) {
                            return thisInternal.labels[value][`dashboard_${dashboardNumber}`];
                        },
                    },
                };
            }
        }

        return options;
    }

    getLineChartConfig() {
        if (this.isMerged) {
            return this.manageDashboardService.currentDashboardComparisonConfig.getValue()?.satisfaction[this.type].distri;
        }
        return this.dashboardService.currentConfig.getValue().satisfaction[this.type].distri;
    }

    get group() {
        if (this.getLineChartConfig()?.grouped_values === GroupedValue.thematic && this.manageDashboardService.getLastAppliedThematics()?.length === 0) {
            return GroupedValue.average;
        }

        if (this.getLineChartConfig()?.grouped_values === GroupedValue.class && this.manageDashboardService.getCurrentModel() === undefined) {
            return GroupedValue.average;
        }

        return this.getLineChartConfig()?.grouped_values;
    }

    getDatasets(rawData) {
        const datasets = [];

        const config = this.getLineChartConfig();

        if (!config.legend_distri_merged || !rawData.distribution.values.find((value) => value.name === config.legend_distri_merged)) {
            this.selectedMergedItem = rawData.distribution.values[0]?.name;
        } else {
            this.selectedMergedItem = config.legend_distri_merged;
        }

        rawData.distribution.values.forEach((list) => {
            const displayName = this.getDisplayName(this.group, list.name);
            const selectedBars = this.getLineChartConfig().legend_distri;

            list.merged_values.forEach((dashboardValue, dashboardNumber) => {
                if (dashboardValue) {
                    let color = this.manageDashboardService.getDataColor('satisfaction-line-chart', displayName);
                    if (this.isMerged) {
                        if (dashboardNumber === 0) {
                            color = '#000';
                        } else if (dashboardNumber === 1) {
                            color = '#ccc';
                        } else if (dashboardNumber === 2) {
                            color = '#666';
                        }
                    }

                    const hidden = this.isMerged ? (list.name !== 'average' && list.name !== this.selectedMergedItem) : selectedBars.includes(list.name);

                    const dataset = {
                        dashboardNumber,
                        xAxisID: this.axis[`dashboard_${dashboardNumber}`],
                        label: `${displayName}`,
                        borderColor: color,
                        pointBackgroundColor: color,
                        pointRadius: 5,
                        fill: false,
                        data: dashboardValue.distribution.map((value, dataIndex) => {
                            const x = dataIndex;
                            let y = value.doc_count !== null ? Math.round(value.doc_count * 10) / 10 : null;
                            y = (Object.is(y, -0)) ? 0 : y;
                            return { x, y };
                        }),
                        tension: 0.4,
                        hidden,
                    };

                    datasets.push(dataset);

                    if (!this.legendItems.find((item) => item.inputName === list.name)) {
                        this.legendItems.push({
                            color,
                            displayName,
                            inputName: list.name,
                            selected: !selectedBars.includes(list.name),
                            disabled: dataset.data.every((data) => data.y === null),
                        });
                    }
                }
            });
        });
        return datasets.filter((dataset) => dataset.xAxisID);
    }

    getThematicDisplayName(inputName: string) {
        if (inputName === 'all') {
            return this.translateService.instant('translations.analysisDashboard.themes.all');
        }
        if (inputName === 'not') {
            return this.translateService.instant('translations.analysisDashboard.themes.not');
        }
        return inputName;
    }

    getClassDisplayName(inputName: string) {
        if (inputName === '__hors_classe__') {
            return this.translateService.instant('translations.analysisDashboard.class.__hors_classe__');
        }
        if (inputName === 'all') {
            return this.translateService.instant('translations.analysisDashboard.themes.all');
        }
        const verbatimDbName = this.manageDashboardService.getVerbatimDbName();
        return this.store.snapshot().corpus.models[verbatimDbName].cat_fields.find((field) => field.inputName === inputName).displayName;
    }

    getColumnDisplayName(inputName: string) {
        if (inputName === '') {
            return this.translateService.instant('translations.utils.empty');
        }
        return inputName;
    }

    getDisplayName(type: string, inputName: string) {
        const methodName = `get${_.capitalize(type)}DisplayName`;
        if (this[methodName]) {
            return this[methodName](inputName);
        }
        return inputName;
    }

    // eslint-disable-next-line class-methods-use-this
    getNameField(type: GroupedValue) {
        if (type === GroupedValue.thematic) {
            return 'them_name';
        }
        if (type === GroupedValue.average) {
            return 'avg_name';
        }
        if (type === GroupedValue.column) {
            return 'filter_name';
        }
        return `${type}_name`;
    }

    openSettings() {
        const modal = this.modalService.open(SatisfactionLineChartSettingsPopupComponent);
        modal.componentInstance.params = this.getLineChartConfig();
        modal.componentInstance.graphService = this.graphService;
        modal.componentInstance.dashboardService = this.dashboardService;
        modal.componentInstance.manageDashboardService = this.manageDashboardService;

        modal.componentInstance.type = this.type;
        modal.componentInstance.isMerged = this.isMerged;
        modal.componentInstance.save.subscribe((params: DistriParams) => {
            if (this.isMerged) {
                this.manageDashboardService.setSatisfactionConfig(this.type, 'distri', params);
            } else {
                this.dashboardService.setSatisfactionConfig(this.type, 'distri', params);
            }

            this.configChanged.emit();
        });
    }

    toggle(index: number) {
        if (!this.legendItems[index]) {
            return;
        }

        if (this.isMerged) {
            this.chartData.datasets.forEach((dataset) => {
                dataset.hidden = (dataset.label !== `${this.legendItems[index].displayName}`);
            });
            this.chart.chart.update();

            this.getLineChartConfig().legend_distri_merged = this.legendItems[index].inputName;
            this.selectedMergedItem = this.legendItems[index].inputName;
        } else {
            const dataset = this.chartData.datasets[index];
            dataset.hidden = !dataset.hidden;
            this.chart.update();

            const { inputName } = this.legendItems[index];

            if (dataset.hidden) {
                this.getLineChartConfig().legend_distri.push(inputName);
            } else {
                this.getLineChartConfig().legend_distri = this.getLineChartConfig().legend_distri.filter((legend) => legend !== inputName);
            }

            this.getLineChartConfig().legend_distri.sort();
        }

        if (this.isMerged) {
            this.manageDashboardService.setSatisfactionConfig(this.type, 'distri', this.getLineChartConfig());
        } else {
            this.dashboardService.setSatisfactionConfig(this.type, 'distri', this.getLineChartConfig());
        }
    }

    customTooltips() {
        const thisInt = this;
        // eslint-disable-next-line func-names
        return function (context) {
            return (thisInt.isMerged ? thisInt.customTooltipsMerged : thisInt.customTooltipsSideBySyde)(context, thisInt);
        };
    }

    exportGraphImage(chart) {
        this.graphService.exportChartToPng(chart, this.translateService.instant(`translations.analysisDashboard.satisfaction.${this.type}.evolution`));
    }

    // eslint-disable-next-line class-methods-use-this
    isPointClickable(): boolean {
        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    getPointValue(data) {
        return `${data.y}%`;
    }
}
