import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pluralize string pipe
 *
 * @export
 * @class PluralizePipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'pluralize',
})
export default class PluralizePipe implements PipeTransform {
    // eslint-disable-next-line class-methods-use-this
    transform(string: string, number: number): string {
        return string.replace(/%s/g, ((number > 1) ? 's' : ''));
    }
}
