// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .btn-toggle {
  padding: 0;
  height: 50px;
  width: 60px;
  border: 0 none;
  border-right: 1px solid #ccc;
}
:host .btn-toggle .vcrm-menu {
  font-size: 35px;
}
:host .btn-toggle.btn-open {
  background-color: #fff;
}
:host .nav-menu {
  position: fixed;
  background-color: #fff;
  top: 110px;
  bottom: 0;
  transition: all 0.4s ease;
  overflow: auto;
  z-index: 999;
  width: 300px;
  left: -300px;
}
:host .nav-menu.is-open {
  left: 0;
}
:host .nav-menu::before {
  content: "";
  margin-left: 60px;
  width: 240px;
  border-top: 1px solid #ccc;
}
:host .overlay-menu {
  position: fixed;
  inset: 110px 0 0;
  z-index: 998;
  background-color: #000;
  opacity: 0.5;
}
:host .nav-link {
  position: relative;
  cursor: pointer;
  padding: 0;
  height: 45px;
  color: #000;
  padding-left: 4px;
}
:host .nav-link .icon {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
}
:host .nav-link:hover:not(.active) {
  background-color: #eee;
  color: #000;
}
:host .nav-link.active {
  background-color: #ddd;
  padding-left: 0;
  border-left: 4px solid #ff7900;
}
:host .nav-link .title {
  align-self: center;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
