// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollview {
  overflow: auto;
}

.open-details > td {
  background-color: #ddd !important;
}

.detail > td {
  background-color: #ddd !important;
  padding: 3px 5px;
}
.detail > td > div {
  background-color: #fff;
  padding: 10px;
}

.content-icon {
  padding-left: 0 !important;
  padding-right: 12px !important;
}

.table {
  margin: 0 -1px;
}
.table th,
.table td {
  white-space: nowrap;
}
.table thead th {
  padding: 0;
}
.table thead th .header-content {
  min-height: 44px;
  padding: 0.625rem;
}
.table thead {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}
.table thead tr {
  border-bottom-width: 0;
}
.table thead tr th .header-content {
  border-bottom: 2px solid #ccc;
  display: block;
  margin: 0 -1px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table thead tr th .btn-header {
  border-color: transparent;
  padding-bottom: 0;
  padding-top: 0;
}
.table thead tr th.sortable {
  cursor: pointer;
  position: relative;
}
.table thead tr th.sortable .header-content {
  padding-right: 24px;
}
.table thead tr th.sortable .vcrm-form-triangle-up,
.table thead tr th.sortable .vcrm-form-triangle-down {
  position: absolute;
  right: 10px;
}

/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
::ng-deep .pagination .page-item:not(.active, .disabled) .page-link {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
