import {
    Component, ElementRef, OnInit, ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Corpuslist } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import CorpusService from 'app/utils/services/corpus.service';

@Component({
    selector: 'app-corpus-select',
    templateUrl: './corpus-select.component.html',
    styleUrls: ['./corpus-select.component.scss'],
})
export default class CorpusSelectComponent extends BaseComponent implements OnInit {
    public currentUrl: string = '';

    private corpusList: Corpuslist;

    private favoriteCorpus: Corpuslist = {
        corpusPrivate: [],
        corpusPublic: [],
    };

    public searchInput: string = '';

    @ViewChild('searchInputEl')
    public searchInputEl: ElementRef;

    /**
     * Retourne la liste des corpus à afficher dans le dropdown
     */
    get displayCorpusList(): Corpuslist {
        if (this.searchInput === '') {
            return this.favoriteCorpus;
        }
        return {
            corpusPrivate: this.corpusList.corpusPrivate.filter((corp) => this.searchInputRegExp.test(corp.corp_name)) || [],
            corpusPublic: this.corpusList.corpusPublic.filter((corp) => this.searchInputRegExp.test(corp.corp_name)) || [],
        };
    }

    get searchInputRegExp(): RegExp {
        return new RegExp(this.searchInput.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'ig');
    }

    constructor(
        private corpusService: CorpusService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.corpusService.corpusList.subscribe((corpusList) => {
            if (corpusList !== null) {
                this.corpusList = corpusList;
                this.favoriteCorpus.corpusPrivate = corpusList.corpusPrivate.filter((corp) => corp.is_favori);
                this.favoriteCorpus.corpusPublic = corpusList.corpusPublic.filter((corp) => corp.is_favori);
            }
        });

        this.subs.sink = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = this.router.url;
            }
        });
    }

    /**
     * Evenement sur l'ouverture et la fermeture du dropdown.
     * Efface la recherche et enlève le focus (sur la fermeture)
     */
    onToggleDropdown(isOpen: boolean): void {
        this.searchInput = '';
        if (!isOpen) {
            this.searchInputEl.nativeElement.blur();
        }
    }

    /**
     * Retourne le nom du corpus avec la partie recherchée en gras
     */
    getCorpNameText(corpName: string): string {
        if (this.searchInput && this.searchInputRegExp.test(corpName)) {
            return corpName.replace(this.searchInputRegExp, `<b>${this.searchInput}</b>`);
        }
        return corpName;
    }
}
