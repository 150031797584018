export const colors = [
    '#4BB4E6',
    '#B5E8F7',
    '#085EBD',
    '#50BE87',
    '#B8EBD6',
    '#0A6E31',
    '#FFB4E6',
    '#FFE8F7',
    '#FF8AD4',
    '#A885D8',
    '#D9C2F0',
    '#492191',
    '#FFD200',
    '#FFF6B6',
    '#FFB400',
];

export function getColor(index) {
    return colors[index % colors.length];
}
