/* eslint-disable import/no-import-module-exports */
import {
    AfterViewInit, Component, TemplateRef, ViewChild,
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import CorpusService from 'app/utils/services/corpus.service';

/**
 * Home component
 *
 * @export
 * @class HomeComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    moduleId: module.id.toString(),
    animations: [fadeInAnimation],
})
export default class HomeComponent implements AfterViewInit {
    public rowsPrivate: Array<any> = [];

    public rowsPublic: Array<any> = [];

    public columns: Array<any>;

    public config: any;

    privateIsOpen = true;

    publicIsOpen = true;

    isLoaded = false;

    @ViewChild('favRowTpl')
        favRowTpl: TemplateRef<any>;

    @ViewChild('favColTpl')
        favColTpl: TemplateRef<any>;

    @ViewChild('colTpl')
        colTpl: TemplateRef<any>;

    @ViewChild('nameRowTpl')
        nameRowTpl: TemplateRef<any>;

    @ViewChild('kibanaRowTpl')
        kibanaRowTpl: TemplateRef<any>;

    @ViewChild('kibanaColTpl')
        kibanaColTpl: TemplateRef<any>;

    @ViewChild('languageRowTpl')
        languageRowTpl: TemplateRef<any>;

    @ViewChild('roleRowTpl')
        roleRowTpl: TemplateRef<any>;

    constructor(
        private corpusService: CorpusService,
        private permissionsService: NgxPermissionsService,
    ) {}

    ngAfterViewInit() {
        this.config = {
            style: {
                maxHeight: '400px',
                overflow: 'scroll',
            },
        };

        const colFav = {
            prop: 'is_favori',
            cellTemplate: this.favRowTpl,
            headerTemplate: this.favColTpl,
            title: 'F',
            sort: true,
            style: {
                'max-width': '50px',
                width: '50px',
            },
        };

        const colName = {
            prop: 'corp_name',
            title: 'translations.home.name',
            cellTemplate: this.nameRowTpl,
            headerTemplate: this.colTpl,
            sort: true,
            style: {
                'max-width': '300px',
                width: '300px',
            },
        };

        const colKibana = {
            prop: 'url_kibana_dashboard_main',
            title: 'translations.analysisDashboard.kibana',
            cellTemplate: this.kibanaRowTpl,
            headerTemplate: this.kibanaColTpl,
            sort: true,
            style: {
                'max-width': '160px',
                width: '160px',
            },
        };

        const colDate = {
            prop: 'dateLastUpload',
            title: 'translations.home.updated',
            headerTemplate: this.colTpl,
            sort: true,
            style: {
                'max-width': '160px',
                width: '160px',
            },
        };

        const colUser = {
            prop: 'usernameOwner',
            title: 'Admin',
            sort: true,
            style: {
                'max-width': '90px',
                width: '90px',
            },
        };

        const colLang = {
            prop: 'corp_lang',
            title: 'translations.home.language',
            headerTemplate: this.colTpl,
            cellTemplate: this.languageRowTpl,
            sort: true,
            style: {
                'max-width': '100px',
                width: '100px',
            },
        };

        const colRole = {
            prop: 'role_id',
            title: 'translations.home.role',
            sort: true,
            cellTemplate: this.roleRowTpl,
            style: {
                'max-width': '110px',
                width: '110px',
            },
        };

        this.columns = [
            colFav,
            colName,
            colKibana,
            colDate,
            this.permissionsService.getPermission('administrators') ? colUser : colRole,
            colLang,
        ];

        this.corpusService.corpusList.subscribe((corpus) => {
            if (corpus !== null) {
                this.rowsPrivate = corpus.corpusPrivate;
                this.rowsPublic = corpus.corpusPublic;
                this.isLoaded = true;
            }
        });

        this.corpusService.loadCorpusListFromBackend();
    }

    fav(id) {
        this.corpusService.fav(id);
    }

    unFav(id) {
        this.corpusService.unFav(id);
    }
}
