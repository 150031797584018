/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import {
    HttpClient, HttpRequest, HttpResponse, HttpHeaders,
} from '@angular/common/http';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import BaseService from '../base-service';
import ApiConfiguration from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';

import { Settings } from '../models/settings';
import { Translations } from '../models/translations';

/**
 * Settings management
 */
@Injectable({
    providedIn: 'root',
})
class SettingsService extends BaseService {
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
   * Get the global settings for the application
   * @return OK
   */
    getV1UtilsSettingsResponse(): __Observable<__StrictHttpResponse<Settings>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/utils/settings`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Settings>),
        );
    }

    /**
   * Get the global settings for the application
   * @return OK
   */
    getV1UtilsSettings(): __Observable<Settings> {
        return this.getV1UtilsSettingsResponse().pipe(
            __map(({ body }) => body as Settings),
        );
    }

    /**
   * Get the wording for the frontend
   * @param lang Language
   * @return OK
   */
    getV1UtilsTranslationLangResponse(lang: string): __Observable<__StrictHttpResponse<Translations>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/utils/translation/${lang.toLowerCase()}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Translations>),
        );
    }

    /**
   * Get the wording for the frontend
   * @param lang Language
   * @return OK
   */
    getV1UtilsTranslationLang(lang: string): __Observable<Translations> {
        return this.getV1UtilsTranslationLangResponse(lang).pipe(
            __map(({ body }) => body as Translations),
        );
    }
}

module SettingsService {
}

export default SettingsService;
