import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
    ChatMode, Conversation, Message, OpenAIApiError, OpenAIErrorCode, PromptError, Role,
} from 'app/api/models/conversation';
import { DashboardService as DashboardApi } from 'app/api/services';
import { ExplorationLLM } from 'app/api/models/llm';
import { BaseComponent } from 'app/base.component';
import { IaConfigResponse } from 'app/api/models/iaconfig';

@Injectable()
export default class ChatService extends BaseComponent {
    // La conversation de Dinootoo Chat
    public conversation: BehaviorSubject<Conversation> = new BehaviorSubject<Conversation>(null);

    // la configuration de Dinootoo Chat
    public interview: BehaviorSubject<Conversation> = new BehaviorSubject<Conversation>(null);

    // Contient le message de résumé proposé par le LLM pour la découverte
    public interviewIaConfig: BehaviorSubject<IaConfigResponse> = new BehaviorSubject<IaConfigResponse>(null);

    // Contient le mode du chat (Conversation ou Découverte)
    public chatMode: BehaviorSubject<ChatMode> = new BehaviorSubject<ChatMode>(ChatMode.CONVERSATION);

    // Déclenche un évènement lorsque l'on ouvre le chat
    public openChatDinootoo: EventEmitter<void> = new EventEmitter<void>();

    // Déclenche un évènement lorsque l'on démarre une nouvelle conversation ou configuration
    public startNewChatDinootoo: EventEmitter<void> = new EventEmitter<void>();

    // Déclenche un évènement lorsque l'on veut scroller tout en bas du chat
    public scrollToBottom: EventEmitter<void> = new EventEmitter<void>();

    // Déclenche un évènement lorsque l'on envoie une question dans le chat
    public sendQuestionChatDinootoo: EventEmitter<string> = new EventEmitter<string>();

    // Déclenche un évènement lorsque l'on créé une nouvelle découverte dans le chat
    public launchDiscovery: EventEmitter<ExplorationLLM> = new EventEmitter<ExplorationLLM>();

    // Détermine si le chat est ouvert ou non
    public isChatDisplay = false;

    // Détermine si l'on utiliser une configuration pré-existante dans la découverte
    public useExistingConfiguration = false;

    // Détermine si on affiche un loader d'attente de réponse ou non
    public isLoadingAnswer = false;

    // Détermine si on affiche le bouton pour lancer la découverte
    public showLaunchDiscoveryBtn = false;

    // Détermine si on affiche le bouton pour valider une configuration (arrête le mode discution avec le LLM)
    public showValidDiscoveryBtn = false;

    constructor(
        private dashboardApi: DashboardApi,
    ) {
        super();
    }

    /**
     * Gestion des erreurs remontées par OpenAI
     */
    // eslint-disable-next-line class-methods-use-this
    public manageOpenAiError(error: OpenAIApiError): Message {
        let promptError = PromptError.UNKNOWN;
        switch (error.code) {
            case OpenAIErrorCode.CONTENT_FILTER:
                promptError = PromptError.CONTENT_FILTER;
                break;
            case OpenAIErrorCode.CONTEXT_LENGTH:
                promptError = PromptError.CONTEXT_LENGTH;
                break;
            default:
                promptError = PromptError.UNKNOWN;
        }

        return {
            role: Role.ASSISTANT,
            content: '',
            error: promptError,
        };
    }

    /**
     * Méthode pour envoyer un message au LLM pour le mode "DECOUVERTE IA"
     * Retourne la réponse du LLM ou une erreur
     */
    public async sendMessages(dashId: number, conversation: Conversation): Promise<Message> {
        this.isLoadingAnswer = true;

        let responseMessage = null;
        try {
            const chatReponse = await firstValueFrom(
                this.dashboardApi[(this.chatMode.getValue() === ChatMode.CONVERSATION ? 'postChatbotConversation' : 'postChatbotInterview')](dashId, conversation),
            );

            // Si on a reçu une erreur dans la réponse
            if (chatReponse.gpt_error?.error) {
                responseMessage = this.manageOpenAiError(chatReponse.gpt_error.error);
            } else {
                responseMessage = {
                    role: Role.ASSISTANT,
                    content: chatReponse.answer,
                };
                if (this.chatMode.getValue() === ChatMode.DISCOVERY && chatReponse.validConfig !== 0) {
                    // On change le status de la conversation de la découverte pour afficher le résumé
                    this.showValidDiscoveryBtn = true;
                }
            }
        } catch (e) {
            responseMessage = {
                role: Role.ASSISTANT,
                content: '',
                error: PromptError.API_ERROR,
            };
        } finally {
            this.isLoadingAnswer = false;
        }
        return responseMessage;
    }
}
