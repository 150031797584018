import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
    selector: 'app-verbatim-pagination',
    templateUrl: './verbatim-pagination.component.html',
    styleUrls: ['./verbatim-pagination.component.scss'],
})
export default class VerbatimPaginationComponent {
    @Input()
        currentPage: number = 1;

    @Input()
        pageCount: number = 1;

    @Input()
        maxPageSize: number;

    @Input()
        pageSize: number;

    @Input()
        total: number;

    @Output()
        changepage: EventEmitter<number> = new EventEmitter<number>();
}
