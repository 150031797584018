import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IaConfigResponse } from 'app/api/models/iaconfig';
import AiConfigService from 'app/modules/corpus/corpus-aiconfig-service';
import ModalComponent from 'app/shared/components/modal/modal.component';

@Component({
    selector: 'aiconfig-choose-configuration-modal',
    templateUrl: './aiconfig-choose-configuration-modal.component.html',
    styleUrl: './aiconfig-choose-configuration-modal.component.scss',
    providers: [AiConfigService],
})
/**
 * Popup de choix d'une configuration.
 */
export default class AiconfigChooseConfigurationModalComponent implements OnInit {
    constructor(private aiconfigService: AiConfigService, public modal: NgbActiveModal, private modalService: NgbModal) {}

    selectedConfiguration: IaConfigResponse = null;

    selected: EventEmitter<IaConfigResponse> = new EventEmitter<IaConfigResponse>();

    async ngOnInit() {
        this.aiconfigService.selectedConfigurations.subscribe((values) => {
            if (values === null || values.length === 0) {
                this.selectedConfiguration = null;
            } else {
                // eslint-disable-next-line prefer-destructuring
                this.selectedConfiguration = values[0];
            }
        });

        this.aiconfigService.selectOnlyOne = true;
        this.aiconfigService.load();
    }

    close() {
        this.modal.close();
    }

    validate() {
        this.selected.emit(this.selectedConfiguration);
        this.modal.close();
    }
}
