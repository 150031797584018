// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1 1 auto;
  height: 1px;
}

.scrolltable {
  background: #fff;
  display: flex;
  flex: 1 0;
  overflow: hidden;
  position: relative;
}

.scrollview {
  flex: 1 0;
  overflow: auto;
}

.table th,
.table td {
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
.table thead th,
.table tbody td {
  padding: 0;
}
.table thead th .header-content,
.table thead th .td-content,
.table tbody td .header-content,
.table tbody td .td-content {
  min-height: 40px;
  padding: 0.625rem;
}
.table thead th .td-content,
.table tbody td .td-content {
  max-width: 700px;
}
.table thead {
  background-color: #fff;
  position: sticky;
  top: 0;
}
.table thead tr {
  border-bottom-width: 0;
}
.table thead tr th {
  padding: 0;
  white-space: nowrap;
}
.table thead tr th .header-content {
  border-bottom: 2px solid #ccc;
  display: block;
  margin: 0 -1px;
}
.table .hover-col {
  background-color: #eee;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.table .select-col {
  background-color: #f16e00;
  color: #000;
  cursor: pointer;
}
.table thead tr th.associate-col .header-content {
  border: 2px solid #f16e00;
  border-bottom-color: #ccc;
  color: #ddd;
}
.table tbody .associate-col {
  border-left: 2px solid #f16e00;
  border-right: 2px solid #f16e00;
  color: #ddd;
  cursor: pointer;
}
.table tbody tr:last-child .associate-col {
  border-bottom: 2px solid #f16e00;
}
.table .disable-col {
  color: #ddd;
  cursor: default;
}
.table .tooltip-icon {
  font-size: 14px;
  height: auto;
  width: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
