import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import SharedModule from 'app/shared/shared.module';
import AuthGuardService from 'app/utils/services/authguard.service';
import BannerComponent from './banner/banner.component';
import HomeRoutingModule from './home-routing.module';
import HomeComponent from './home.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        HomeRoutingModule,
        TranslateModule.forChild(),
    ],
    declarations: [HomeComponent, BannerComponent],
    providers: [AuthGuardService],
    exports: [HomeComponent],
})
export default class HomeModule {}
