// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .discovery-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #666;
  margin-bottom: 10px;
}
:host .discovery-title .form-control {
  width: 500px;
  padding-right: 45px;
}
:host .theme {
  position: relative;
}
:host .theme.selected {
  opacity: 0.3;
}
:host .theme.selected::before {
  content: " ";
  position: absolute;
  inset: 0;
  z-index: 1;
}
:host .graph-col {
  height: 200px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
