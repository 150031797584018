// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o-footer {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  display: flex;
  flex-wrap: nowrap;
  z-index: 1049;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.o-footer-body {
  width: 100%;
}

.link {
  color: rgb(241, 110, 0);
  cursor: pointer;
  display: inline-block;
  opacity: 0.8;
  padding: 0.2em;
  text-decoration: underline;
  white-space: nowrap;
}

.button {
  background-color: rgb(241, 110, 0);
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 700;
  height: 100%;
  margin: 0;
  padding: 0.8em 1.8em;
  text-align: center;
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
