import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import Error404Component from './error404/error404.component';
import Error500Component from './error500/error500.component';
import Error401Component from './error401/error401.component';

const ERRORSROUTES: Routes = [
    {
        path: 'error/404',
        component: Error404Component,
        data: {
            meta: {
                title: 'VisualCRM 404',
            },
        },
    },
    {
        path: 'error/500',
        component: Error500Component,
        data: {
            meta: {
                title: 'VisualCRM 500',
            },
        },
    },
    {
        path: 'error/401',
        component: Error401Component,
        data: {
            meta: {
                title: 'VisualCRM 401',
            },
        },
    },
    { path: '**', redirectTo: 'error/404', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(ERRORSROUTES)],
    exports: [RouterModule],
})
export default class ErrorsRoutingModule {}
