import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    DashboardSection, DashboardType, Dashboardforuser, Dashboardlist,
} from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';

@Component({
    selector: 'app-select-dashboard-modal',
    templateUrl: './select-dashboard-modal.component.html',
    styleUrls: ['./select-dashboard-modal.component.scss'],
})
export default class SelectDashboardModalComponent extends BaseComponent {
    @Input()
        manageDashboardService: ManageDashboardService;

    @Output()
        clickNewComparison: EventEmitter<void> = new EventEmitter();

    @Output()
        selectDashboard: EventEmitter<Dashboardforuser> = new EventEmitter();

    searchDashboard: string = '';

    get analyseDashboards():Dashboardlist {
        return this.manageDashboardService.allAnalysesDashboards.getValue();
    }

    get personalDashboards() {
        return (this.analyseDashboards.personalDashboards ?? []).filter((dash) => dash.dash_name.toLocaleLowerCase().includes(this.searchDashboard.toLowerCase()));
    }

    get sharedDashboards() {
        return (this.analyseDashboards.sharedDashboards ?? []).filter(
            (dash) => `${dash.dash_initial ? 'Initial ' : ''}${dash.dash_name}`.toLocaleLowerCase().includes(this.searchDashboard.toLowerCase()),
        );
    }

    get otherDashboards() {
        return (this.analyseDashboards.otherDashboards ?? []).filter((dash) => `${dash.username_creator} / ${dash.dash_name}`.toLocaleLowerCase().includes(this.searchDashboard.toLowerCase()));
    }

    get comparisonDashboards():Dashboardlist {
        return this.manageDashboardService.allComparisonsDashboards.getValue();
    }

    get hasComparisonDashboard() {
        return this.manageDashboardService.allGroupedComparisons.length;
    }

    get personalComparisons() {
        return (this.comparisonDashboards.personalDashboards ?? []).filter((dash) => dash.dash_name.toLocaleLowerCase().includes(this.searchDashboard.toLowerCase()));
    }

    get sharedComparisons() {
        return (this.comparisonDashboards.sharedDashboards ?? []).filter((dash) => dash.dash_name.toLocaleLowerCase().includes(this.searchDashboard.toLowerCase()));
    }

    get otherComparisons() {
        return (this.comparisonDashboards.otherDashboards ?? []).filter((dash) => `${dash.username_creator} / ${dash.dash_name}`.toLocaleLowerCase().includes(this.searchDashboard.toLowerCase()));
    }

    get selectedDashboardId() {
        return this.manageDashboardService.currentDashboard.getValue()?.dash_id;
    }

    get isDashboardTone() {
        return this.manageDashboardService.currentDashboardType === DashboardType.TONE;
    }

    constructor(
        protected modal: NgbActiveModal,
        protected corpusService: CorpusService,
    ) {
        super();
    }

    onClickNewComparison() {
        gtmClick({
            track_category: 'dashboard comparaison',
            track_name: 'nouveau dashboard comparaison',
        });
        this.modal.close();
        this.clickNewComparison.emit();
    }

    onSelectDashboard(dash: Dashboardforuser) {
        let trackCible = `dashboard ${this.manageDashboardService.isDashboardComparison ? 'comparaison ' : ''}`;
        switch (dash.dash_section) {
            case DashboardSection.PERSO: trackCible += 'personnel'; break;
            case DashboardSection.SHARED: trackCible += 'partagé'; break;
            case DashboardSection.OTHER: trackCible += 'autre utilisateur'; break;
            default:
        }
        gtmClick({
            track_category: 'analyse',
            track_name: 'type de dashboard',
            track_cible: trackCible,
        });
        this.modal.close();
        this.selectDashboard.emit(dash);
    }
}
