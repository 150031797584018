import { formatNumber } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import FileStatus from 'app/utils/models/file-status';

/**
 * Display a timeline to show several step on file traitement
 */
@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export default class TimelineComponent implements OnChanges {
    protected fileState = [{
        status: '',
        text: 'translations.timeline.fileState.download',
        nbFiles: 'file_nb_lines_without_header',
        fileStatus: FileStatus.FILE_UPLOADED,
    }, {
        status: '',
        text: 'translations.timeline.fileState.integrated',
        fileStatus: FileStatus.FILE_IN_CORPUS,
    }, {
        status: '',
        text: 'translations.timeline.fileState.started',
        fileStatus: FileStatus.FILE_ANALYSE_STARTED,
    }, {
        status: '',
        text: 'translations.timeline.fileState.prepared',
        fileStatus: FileStatus.FILE_PREPARED,
    }, {
        status: '',
        text: 'translations.timeline.fileState.preAnalyse',
        fileStatus: FileStatus.FILE_PREANALYSED,
    }, {
        status: '',
        text: 'translations.timeline.fileState.analyse',
        fileStatus: FileStatus.FILE_ANALYSED,
    }, {
        status: '',
        text: 'translations.timeline.fileState.indexation',
        fileStatus: FileStatus.FILE_INDEXED,
    }, {
        status: '',
        text: 'translations.timeline.fileState.indexationAsync',
        fileStatus: FileStatus.FILE_INDEXED_ASYNC,
    }, {
        status: '',
        text: 'translations.timeline.fileState.email',
        fileStatus: FileStatus.FILE_MAIL,
    }, {
        status: '',
        text: 'translations.timeline.fileState.endedNoAnalysedLines',
        nbFiles: 'file_nb_analysed_lines',
        fileStatus: FileStatus.FILE_ANALYSE_ENDED,
    }];

    @Input()
    private file: any;

    /**
   * Watch every change on file to update timeline
   */
    public ngOnChanges() {
        if (this.file) {
            // Re-init fileState status list
            this.fileState.forEach((state) => {
                state.status = '';
            });

            // Change file status to number if it is not
            this.file.file_status = Number(this.file.file_status);

            this.file.file_nb_lines_without_header = (this.file.file_nb_lines * 1) - 1;
            this.file.file_nb_lines_text = formatNumber(this.file.file_nb_lines_without_header, 'fr', '1.0-0');

            // If state of current file is more or egal to FILE_ANALYSE_ENDED
            if (!this.file.status_flags.isOnError && !this.file.status_flags.isAnalyseInProgress) {
                // We change the text of the last timeline state to display nb lines analysed
                this.file.file_nb_analysed_lines_text = formatNumber(this.file.file_nb_analysed_lines, 'fr', '1.0-0');
                this.fileState[this.fileState.length - 1].text = 'translations.timeline.fileState.ended';
                // All state are in success
                this.fileState.forEach((state) => { state.status = 'success'; });
            } else {
                this.fileState[this.fileState.length - 1].text = 'translations.timeline.fileState.endedNoAnalysedLines';
                const fileStateIndex = this.fileState.findIndex((state) => this.file.file_status_next === state.fileStatus);
                this.fileState[fileStateIndex].status = (this.file.file_error) ? 'danger' : 'inprogress';
                this.fileState.forEach((state, index) => {
                    // If step is before file_status, all previous step if on success
                    if (index < fileStateIndex) {
                        state.status = 'success';
                    }
                });
            }
        }
    }
}
