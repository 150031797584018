/* eslint-disable class-methods-use-this */
import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    SetCorpus,
    ResetCorpus,
} from '../../actions/corpus/corpus.actions';
import { GetCorpus } from '../../../api/models';

/**
 * RGXS Store for current corpus selected
 *
 * @export
 * @class CorpusState
 */
@Injectable()
@State<GetCorpus>({
    name: 'corpus',
})
export default class CorpusState {
    @Action(SetCorpus)
    setName({ setState }: StateContext<GetCorpus>, { payload }: SetCorpus) {
        const state = payload;
        setState({
            ...state,
        });
    }

    @Action(ResetCorpus)
    ResetCorpus({ setState }: StateContext<GetCorpus>) {
        const state = {};
        setState({
            ...state,
        });
    }
}
