import { Component } from '@angular/core';

/**
 * 404 page
 *
 * @export
 * @class Error404Component
 * @implements {OnInit}
 */
@Component({
    selector: 'app-error404',
    templateUrl: './error404.component.html',
})
export default class Error404Component {
}
