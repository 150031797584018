import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

/**
 * Check user gdpr valid for this route
 *
 * @export
 * @class GdprGuardService
 * @implements {CanActivate}
 */
@Injectable()
export default class RouterTraceService implements CanActivate {
    /**
   * Logger trace route.
   * @param {Router} router
   * @param {Store} store
   * @memberof RouterTraceService
   */
    constructor(private logger: NGXLogger) {}

    /**
   * Logger trace route
   *
   * @returns {boolean}
   * @memberof GdprGuardService
   */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        this.logger.info('Navigate to', state.url);
        return true;
    }
}
