// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .chat-item .message-icon.vcrm-avatar {
  align-self: flex-start;
}
:host .chat-item .message-icon.vcrm-ai-generative {
  align-self: flex-end;
}
:host .chat-item .message.question {
  border-radius: 0 0 20px 20px;
}
:host .chat-item .message.answer {
  border-radius: 20px 20px 0 0;
}
:host .chat-item .message.answer.loading {
  margin-right: 30px;
  text-align: center;
}
:host .chat-item .message.answer ::ng-deep {
  /* stylelint-disable-next-line max-nesting-depth */
  /* stylelint-disable-next-line max-nesting-depth */
}
:host .chat-item .message.answer ::ng-deep * {
  word-wrap: break-word;
}
:host .chat-item .message.answer ::ng-deep p:last-child {
  margin-bottom: 0;
}
:host .chat-item .message.summary {
  border-radius: 20px;
  border: 1px solid var(--grey-ods-color-grey-300, #ddd);
}
:host .chat-item .message.summary.edit {
  border-color: #f16e00;
  border-width: 2px;
}
:host .chat-item .btn-clear {
  position: absolute;
  right: 0;
  top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
