import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import SharedModule from 'app/shared/shared.module';
import AuthRoutingModule from './auth-routing.module';
import LoginComponent from './login/login.component';
import LogoutComponent from './logout/logout.component';
import AuthComponent from './auth.component';
import ResetpasswordComponent from './resetpassword/resetpassword.component';
import RequestaccessComponent from './requestaccess/requestaccess.component';
import ResetpasswordformComponent from './resetpasswordform/resetpasswordform.component';

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        AuthRoutingModule,
        FormsModule,
        RouterModule,
        TranslateModule.forChild(),
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        AuthComponent,
        ResetpasswordComponent,
        RequestaccessComponent,
        ResetpasswordformComponent,
    ],
    providers: [],
    exports: [],
})
export default class AuthModule {}
