import {
    Component, Input, OnChanges, SimpleChanges, ViewChild,
} from '@angular/core';
import { CopcData, CopcIndicators } from 'app/api/models/copcdata';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import { ChartData, ChartOptions } from 'chart.js';
import { LegendItem } from 'app/modules/corpus/corpus-analyses/components/graphs-generic-components/graph-checkbox-legend/graph-checkbox-legend.component';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import { TranslateService } from '@ngx-translate/core';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { getColor } from 'app/utils/models/colors.models';

@Component({
    selector: 'app-copc-radar-chart',
    templateUrl: './copc-radar-chart.component.html',
})
export default class CopcRadarChartComponent implements OnChanges {
    @Input()
        data: CopcData;

    @Input()
        order: string[];

    @ViewChild('chart')
        chart: BaseChartDirective;

    chartOptions: ChartOptions;

    chartData: ChartData;

    hasGoal = false;

    legendItems: Array<LegendItem>;

    constructor(
        private translateService: TranslateService,
        private chartTooltips: ChartTooltipsService,
    ) {
        this.legendItems = [{
            color: getColor(0),
            displayName: this.translateService.instant('translations.analysisDashboard.satisfaction.copc.radar.result'),
            inputName: this.translateService.instant('translations.analysisDashboard.satisfaction.copc.radar.result'),
            selected: true,
        }, {
            color: '#ff7900',
            displayName: this.translateService.instant('translations.analysisDashboard.satisfaction.copc.radar.goal'),
            inputName: this.translateService.instant('translations.analysisDashboard.satisfaction.copc.radar.goal'),
            selected: true,
            disabled: true,
        }];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.generateChartData();
        }
    }

    // Génère les données du graphique radar en fonction des données reçues de l'API
    generateChartData() {
        const graphData: ChartData = {
            labels: [],
            datasets: [{
                label: this.translateService.instant('translations.analysisDashboard.satisfaction.copc.radar.result'),
                data: [],
                clip: false,
                fill: false,
                borderColor: getColor(0),
                pointBackgroundColor: getColor(0),
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: getColor(0),
            }, {
                label: this.translateService.instant('translations.analysisDashboard.satisfaction.copc.radar.goal'),
                data: [],
                clip: false,
                fill: false,
                borderColor: '#ff7900',
                pointBackgroundColor: '#ff7900',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#ff7900',
                pointBorderWidth: 1,
                borderWidth: 2,
            }],
        };
        this.order.forEach((order) => {
            if (this.data[order].onradar) {
                const data = this.data[order] as unknown as CopcIndicators;
                const formattedData = Math.round(data.global * 10) / 10;
                // On multiplie par 10 la valeur du CES uniquement et seulement dans le graph (pas dans les labels)
                const transformedData = formattedData * (order === 'ces' ? 10 : 1);
                const labels = [`${order.toUpperCase()}: ${formattedData}${data.default === 'percent' ? '%' : ''}`];
                if (data.goal.value) {
                    labels.push(`obj. ${data.goal.value}${data.default === 'percent' ? '%' : ''}`);
                }
                graphData.labels.push(labels);
                graphData.datasets[0].data.push(transformedData);
                // on met des valeurs null lorsqu'il n'y a pas de point pour ne pas les afficher
                const formattedGoalData = data.goal?.value ? (order === 'ces' ? 10 : 1) * (Math.round(data.goal.value * 10) / 10) : null;
                graphData.datasets[1].data.push(formattedGoalData);
                if (data.goal.value && !this.hasGoal) {
                    this.hasGoal = true;
                }
            }
        });
        this.legendItems[1].disabled = !this.hasGoal;
        this.chartOptions = this.getOptions();
        this.chartData = graphData;
    }

    // Retourne les options du graphique
    getOptions() {
        const thisInternal = this;
        return {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 2,
            animation: {
                duration: 0,
            },
            layout: {
                padding: 5,
            },
            elements: {
                point: {
                    radius: 5,
                    hoverRadius: 5,
                },
            },
            scales: {
                r: {
                    max: 100,
                    ticks: {
                        color: '#000',
                        font: {
                            size: 12,
                        },
                        callback: (value) => (([50, 100].includes(value)) ? value : ''),
                        stepSize: 25,
                    },
                    pointLabels: {
                        color: '#000',
                        font: {
                            size: 14,
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external(context) {
                        let innerHtml = '';
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.dataPoints) {
                            tooltipModel.dataPoints.forEach((datapoint) => {
                                const label = datapoint.label[0].split(':')[0];
                                let value = datapoint.dataset.data[datapoint.dataIndex];
                                // On divise par 10 pour le CES uniquement
                                value /= (label.toLowerCase() === 'ces') ? 10 : 1;
                                // eslint-disable-next-line max-len
                                innerHtml += `<div class='text-truncate'><span class='tooltip-legend' style='background-color:${datapoint.dataset.pointHoverBackgroundColor}'></span>${label}: ${value}${thisInternal.data[label.toLowerCase()].default === 'percent' ? '%' : ''}</div>`;
                            });
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        thisInternal.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
                    },
                },
            },
        };
    }

    /**
     * Affiche ou masque les legendes cliquées
     */
    toggle(index: number) {
        const dataset = this.chartData.datasets[index];
        dataset.hidden = !dataset.hidden;

        gtmClick({
            track_category: 'satisfaction',
            track_name: 'vue radar masquer ou afficher courbes',
            track_cible: `${dataset.hidden ? 'masquer' : 'afficher'} ${this.legendItems[index].displayName}`,
        });

        this.chart.chart.update();
    }
}
