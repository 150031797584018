import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import { Store } from '@ngxs/store';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import {
    SetEtapes,
    SetFilters,
} from '../../../stores/actions/new-corpus/new-corpus.actions';
import BaseEtapeComponent from '../BaseEtapeComponent';

/**
 * Select filters
 *
 * @export
 * @class Etape5filtersComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape5filters',
    templateUrl: './etape5filters.component.html',
    styleUrls: ['./etape5filters.component.scss'],
    animations: [slideInOutAnimation],
})
export default class Etape5filtersComponent extends BaseEtapeComponent implements OnInit {
    columns: Array<string> = [];

    rows: any = [];

    next = false;

    selectVerbatimCol: number;

    arrayDisabled: Array<number> = [];

    filename: string;

    formatList: Array<String>;

    modalRef: NgbModalRef;

    selectedFormat: string;

    storeDatas: any;

    selectedFilters: Array<Object> = [];

    selectedFiltersCols: Array<number> = [];

    isEnterPressable: boolean = true;

    modalIndex: number;

    filterData;

    filters: Array<any> = [];

    isCollapsed = true;

    colsTypes: any;

    isNumeric: boolean = false;

    toShow: boolean = true;

    // pour le message tooltip dynamic
    tooltipContent: string = '';

    @ViewChild('template')
    private defaultTabButtonsTpl: TemplateRef<any>;

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private translate: TranslateService,
        private router: Router,
    ) {
        super();

        const state = this.store.snapshot().newcorpus;
        if (this.isEtape1Invalid(state)) {
            this.router.navigate(['new-project']);
        } else if (this.isEtape2Invalid(state)) {
            this.router.navigate(['new-project', 'upload']);
        } else if (this.isEtape3Invalid(state)) {
            this.router.navigate(['new-project', 'date']);
        } else if (this.isEtape4Invalid(state)) {
            this.router.navigate(['new-project', 'message']);
        } else {
            this.storeDatas = state.datas;
        }
    }

    ngOnInit() {
        setTimeout(() => {
            this.store.dispatch(new SetEtapes(4));
        }, 500);
        this.store.dispatch(new SetFilters([]));
        this.filename = this.storeDatas.file.name;
        this.colsTypes = this.storeDatas.colsTypes;
        this.getFirstLine();
    }

    toShowOrHide() {
        if (this.toShow === true) {
            this.toShow = false;
        } else this.toShow = true;
    }

    getFirstLine() {
        const colsItems: Array<string> = [];
        const tableData = <Array<any>> this.storeDatas.fileFirstLines.rows;
        const rows = <Array<any>> this.storeDatas.fileFirstLines.colomns;
        Object.keys(rows).forEach((v, k) => {
            colsItems.push(rows[v]);
            // Sauvegarde les index des colonnes des verbatims (pour les désactivées)
            this.storeDatas.verbatim.forEach((item) => {
                if (rows[v] === item.name) {
                    this.arrayDisabled.push(k);
                }
            });
            // Sauvegarde l'index de la colonne de date (pour la désactivée)
            if (rows[v] === this.storeDatas.date) {
                this.arrayDisabled.push(k);
            }
            // Sauvegarde les index des colonnes de sat/reco (pour les désactivées)
            this.storeDatas.colScore.forEach((item) => {
                if (rows[v] === item.inputName) {
                    this.arrayDisabled.push(k);
                }
            });
        });

        this.columns = colsItems;
        this.columns = [...this.columns];
        this.storeDatas.filters.forEach((filter) => {
            const index = this.columns.indexOf(filter.inputName);
            this.selectedFiltersCols.push(index);
            this.selectedFilters.push({
                index,
                columns: filter.inputName,
            });
        });

        const rowsList = [];
        tableData.forEach((row) => {
            const cellList = [];
            Object.entries(row).forEach(([, v]) => {
                cellList.push(v);
            });
            rowsList.push(cellList);
        });

        this.rows = rowsList;
    }

    selectFiltre(index) {
        this.filterData = new UntypedFormGroup({
            analysis: new UntypedFormControl(true),
            colName: new UntypedFormControl(''),
            multiple: new UntypedFormControl(false),
            multipleDelim: new UntypedFormControl(''),
            numeric: new UntypedFormControl(false),
        });

        if (!this.arrayDisabled.includes(index)) {
            if (!this.selectedFiltersCols.includes(index)) {
                this.selectedFilters.push({
                    index,
                    columns: this.columns[index],
                });
                this.selectedFiltersCols.push(index);
            }
            this.openModal(this.defaultTabButtonsTpl, index);
        }
    }

    openModal(template: TemplateRef<any>, index) {
        this.isCollapsed = true;
        this.modalIndex = index;
        if (this.colsTypes[this.columns[index]] === 'n') {
            this.isNumeric = true;
        } else {
            this.isNumeric = false;
        }
        this.modalRef = this.modalService.open(template, { backdrop: 'static' });
    }

    closeModal() {
        this.modalIndex = null;
        this.modalRef.close();
    }

    cancel(index) {
        this.selectedFiltersCols = this.selectedFiltersCols.filter(
            (v) => v !== index,
        );
        this.isEnterPressable = true;
        this.closeModal();
    }

    removeFilter(index) {
        const filtersIndex = this.filters.findIndex(
            (i) => i.inputName === this.columns[index],
        );

        if (filtersIndex > -1) {
            this.filters.splice(filtersIndex, 1);
            this.store.dispatch(new SetFilters(this.filters)).subscribe(() => {});
        }
        this.closeModal();
    }

    onSubmit(filter) {
        const filterData: any = {};
        filterData.inputName = this.columns[this.modalIndex];
        if (!_.isEmpty(filter.colName)) {
            filterData.displayName = filter.colName;
        } else {
            filterData.displayName = filterData.inputName;
        }
        filterData.search = true;
        filterData.analyseFilter = filter.analysis;
        filterData.toneFilter = true;
        filterData.separator = filter.multipleDelim;
        filterData.multi = filter.multipleDelim !== '';
        filterData.digitalFilter = filter.numeric;

        const filtersIndex = this.filters.findIndex(
            (i) => i.inputName === filterData.inputName,
        );

        if (filtersIndex > -1) {
            this.filters[filtersIndex] = filterData;
        } else {
            this.filters.push(filterData);
        }

        this.isEnterPressable = true;

        this.store.dispatch(new SetFilters(this.filters)).subscribe(() => {
            this.closeModal();
        });
    }

    onEnterpress() {
        if (this.isEnterPressable) {
            this.isEnterPressable = false;
        }
    }

    onEscapepress() {
        if (!this.isEnterPressable) {
            this.isEnterPressable = true;
        }
    }

    overTheColumn(index) {
        if (this.arrayDisabled.includes(index)) { // cette colonne est disabled
            this.tooltipContent = this.translate.instant('translations.newcorpus.stage5.tooltips.columnDisabled');
        } else if (this.selectedFiltersCols.includes(index)) { // cette colonne fait partie du tableau de colonnes de datas sélectionnée
            this.tooltipContent = this.translate.instant('translations.newcorpus.stage5.tooltips.filterToDeSelected');
        } else {
            this.tooltipContent = this.translate.instant('translations.newcorpus.stage5.tooltips.columnSelect');
        }
    }

    outTheColumn() {
        this.tooltipContent = '';
    }
}
