import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-alert-error',
    templateUrl: './alert-error.component.html',
})

export default class AlertErrorComponent {
    @Input()
    public mailHref: string;

    @Input()
    public isUploadFileDisabled: boolean;
}
