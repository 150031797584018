// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-delete {
  background-color: transparent;
  border-radius: 8px;
  padding: 2px;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}
.icon-delete:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.details {
  background-color: #fff;
  padding: 10px;
}

.badges {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  /* Adjust as needed */
}

.category {
  display: contents;
}

.label-width {
  grid-column: 1;
  display: flex;
  align-items: center;
  min-height: 30px;
}

.content-width {
  grid-column: 2;
  display: flex;
  align-items: center;
}

.tag {
  word-break: break-all;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
