import {
    Component, Input, OnInit,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { ComparisonConfig, ComparisonMode } from 'app/api/models/dashboard-config';

const analyticsComparisonModeSwitch = {
    class: 'classification auto',
    thematics: 'thématiques',
    satisfaction: 'satisfaction',
    evoTemporel: 'évolution temporelle',
    associatedData: 'données associées',
};

@Component({
    selector: 'app-comparison-mode-switch',
    templateUrl: './comparison-mode-switch.component.html',
})
export default class ComparisonModeSwitchComponent extends BaseComponent implements OnInit {
    @Input()
        section: string = '';

    config: ComparisonConfig;

    get selectedMode(): ComparisonMode {
        return this.config ? this.config[this.section] : ComparisonMode.side_by_side;
    }

    set selectedMode(value: ComparisonMode) {
        this.config[this.section] = value;
        this.manageDashboardService.setComparisonSwitchConfig(this.section, value);
    }

    get trackName() {
        return `${analyticsComparisonModeSwitch[this.section]} mode côte à côte ou fusionné`;
    }

    constructor(
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.manageDashboardService.currentDashboardComparisonConfig.subscribe((config) => {
            this.config = config?.comparison;
        });
    }
}
