/* eslint-disable import/no-import-module-exports */
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import ModalComponent from 'app/shared/components/modal/modal.component';
import { Field } from 'app/api/models';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import CorpusService from 'app/utils/services/corpus.service';
import FixedValidationBarService from '../fixed-validation-bar/fixed-validation-bar.service';
import { CorpusService as CorpusApi } from '../../../../api/services';
import { SetCorpus } from '../../../../stores/actions/corpus/corpus.actions';

/**
 * Corpus verbatim settings
 *
 * @export
 * @class CorpusSettingsVerbatimComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus-settings-verbatim',
    templateUrl: './corpus-settings-verbatim.component.html',
    styleUrls: ['./corpus-settings-verbatim.component.scss'],
    animations: [fadeInAnimation],
    moduleId: module.id.toString(),
})
export default class CorpusSettingsVerbatimComponent implements OnInit {
    public corpus: any;

    public isDisabled: boolean = true;

    private initFields: any;

    public listVerbatim: Array<any> = [];

    public listFields: Array<Field> = [];

    constructor(
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private store: Store,
        private modalService: NgbModal,
        private fixedValidationBarService: FixedValidationBarService,
    ) { }

    ngOnInit() {
        this.update();

        this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus === null) {
                return;
            }

            this.store.dispatch(new SetCorpus(corpus));
            const corpusData = JSON.parse(JSON.stringify(corpus));

            this.initFields = {
                corp_verbatim_fields: corpus.corp_verbatim_fields,
                corp_fields: corpus.corp_fields,
            };

            this.corpus = corpusData;
            this.listVerbatim = corpusData.corp_verbatim_fields;
            this.listFields = corpusData.corp_fields;
            // Le back-end nous envoi une échelle de notation 0_5 ou 0_10
            // mais lorsque l'on utilise l'échelle 0_5 dans VCRM on affiche une échelle de 1 à 5
            this.listFields.forEach((field) => {
                if (field.satField && field.satField.scale === '0_5') {
                    field.satField.scale = '1_5';
                }
                // Change les DBName des tableaux verbatimList de satField et recoField par leur name (inputName) respectif
                field.satField?.verbatimList.forEach((DBName, index) => {
                    field.satField.verbatimList[index] = corpus.corp_verbatim_fields.find((f) => f.DBName === DBName)?.name;
                });
                field.recoField?.verbatimList.forEach((DBName, index) => {
                    field.recoField.verbatimList[index] = corpus.corp_verbatim_fields.find((f) => f.DBName === DBName)?.name;
                });
            });
            this.isDisabled = true;
        });
    }

    update() {
        this.corpusService.loadCorpus(this.store.snapshot().settings.currentCorpus);
    }

    /**
   * Call when user change column name of verbatim table
   * Update name and determine if update bar is visible or not
   */
    changePropVerbatim(i:number, newValue:string) {
        this.listVerbatim[i].displayName = newValue;
        this.isDisabled = (newValue.trim() === '' || this.initFields.corp_verbatim_fields[i].displayName === newValue);
    }

    /**
   * Call when user change column name of deltaSat or Reco table
   * Update name and determine if update bar is visible or not
   */
    changePropScore(DBName:string, newValue:string) {
        this.listFields.forEach((score:Field) => {
            if (score.DBName === DBName) {
                score.displayName = newValue;
            }
        });
        const initialField = this.initFields.corp_fields.find((score:Field) => score.DBName === DBName);
        this.isDisabled = (newValue.trim() === '' || initialField.displayName === newValue);
    }

    cancel() {
        this.update();
    }

    save() {
        this.corpusApi
            .patchV1CorpusCorpId({
                corpId: this.corpus.corp_id,
                body: {
                    verbatim_fields: this.listVerbatim,
                    fields: this.listFields,
                },
            })
            .subscribe({
                next: () => {
                    this.fixedValidationBarService.showConformationMessage();
                    this.update();
                },
                error: (err) => {
                    const modal = this.modalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                    modal.componentInstance.content = err?.error?.message;
                    modal.componentInstance.alertTypeVariant = 'danger';
                },
            });
        this.isDisabled = true;
    }
}
