// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .chat-prompt {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 700px;
  padding: 20px 10px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) -31.25%, #FFF 72.5%);
}
:host .chat-prompt .discovery-actions {
  display: flex;
  align-items: center;
  padding-right: 45px;
  padding-bottom: 20px;
  justify-content: space-between;
}
:host .chat-prompt .chat-prompt-message {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
:host .chat-prompt .chat-textarea {
  resize: none;
  max-height: 150px;
  padding-right: 80px;
  position: relative;
  z-index: 0;
}
:host .chat-prompt .btn-clear {
  position: absolute;
  right: 0;
  top: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
