import {
    Component, OnInit, Input, Output, EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { News } from 'app/api/models';
import { DomSanitizer } from '@angular/platform-browser';
import { NewsType } from 'app/api/models/news';

@Component({
    selector: 'app-news-item',
    templateUrl: './news-item.component.html',
    styleUrls: ['./news-item.component.scss'],
})
export default class NewsItemComponent implements OnInit {
    @Input() news: News;

    @Input() redirectTooltip: string = '';

    @Input() displayMoreLink: boolean = true;

    @Output() redirectEvent = new EventEmitter<any>();

    lang: string = '';

    isClearlyUnread: boolean = false;

    isOpen: boolean = false;

    isHover: boolean = false;

    NewsType = NewsType;

    constructor(
        private domSanitizer: DomSanitizer,
        public translate: TranslateService,
    ) { }

    ngOnInit() {
        if (this.translate.currentLang === undefined) {
            this.lang = this.translate.defaultLang.toLowerCase();
        } else {
            this.lang = this.translate.currentLang.toLowerCase();
        }

        this.translate.onLangChange.subscribe(() => {
            this.lang = this.translate.currentLang.toLowerCase();
        });
    }

    get title() {
        if (this.lang !== '') {
            return this.news.translations[this.lang].title;
        }
        return '';
    }

    get description() {
        if (this.lang !== '') {
            return this.domSanitizer.bypassSecurityTrustHtml(
                this.news.translations[this.lang].description.replace(/\r\n/g, '<br />'),
            );
        }
        return '';
    }

    get content() {
        if (this.lang !== '') {
            return this.domSanitizer.bypassSecurityTrustHtml(
                this.news.translations[this.lang].content,
            );
        }
        return '';
    }

    onHover() {
        this.isHover = true;
    }

    onMouseLeave() {
        this.isHover = false;
    }

    onRedirect() {
        this.redirectEvent.emit(this.news.id);
    }
}
