import { DashboardConfig } from 'app/api/models/dashboard-config';
import { Dashboardparams } from './dashboardparams';
import { Usershort } from './usershort';
import SharedStatus from './shared-status';

export enum DashboardSection {
    PERSO = 'perso',
    COMPARISON = 'comparison',
    SHARED = 'shared',
    OTHER = 'other',
}

export enum DashboardType {
    ANALYSE = 'analyse',
    TONE = 'tone',
    COMPARISON = 'comparison',
    CHILD = 'child',
}

export interface Dashboardforuser {
    dash_json_config?: DashboardConfig;
    dash_id?: number;
    dash_fk_user_id?: number;
    dash_fk_corp_id?: number;
    dash_json_params?: Dashboardparams;
    dash_shared?: SharedStatus;
    dash_initial?: boolean;
    dash_name?: string;
    is_default?: boolean;
    username_creator?: string;
    page?:number;

    /**
   * Update Date Time in RFC3339 format
   */
    dash_creation_date?: string;

    /**
   * Update Date Time in RFC3339 format
   */
    dash_update_date?: string;
    sharers?: Array<Usershort>;
    dash_kibana_url?: string;
    dash_section?: DashboardSection;
    dash_type?: DashboardType;
    dash_childs?: number[];
    dash_is_cloned?:boolean;
}
