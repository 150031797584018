import { Component, Input, OnInit } from '@angular/core';
import { SatisfactionParams, SatisfactionMergeParams } from 'app/api/models/satisfactionparams';
import * as _ from 'lodash';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { DashboardService as DashboardApi } from 'app/api/services';
import { GroupedValue } from 'app/api/models/distriparams';
import { GetCorpus } from 'app/api/models';
import CorpusService from 'app/utils/services/corpus.service';
import { ComparisonMode, DashboardConfig } from 'app/api/models/dashboard-config';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-satisfaction-charts',
    templateUrl: './satisfaction-charts.component.html',
    styleUrls: ['./satisfaction-charts.component.scss'],
})
export default class SatisfactionChartsComponent extends BaseComponent implements OnInit {
    constructor(
        private corpusService: CorpusService,
        private graphService: GraphService,
        private dashboardApi: DashboardApi,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    // cet input n'a de valeur que sur les composants non fusionnés
    @Input()
        dashboardService: DashboardService;

    private satisfactionData = null;

    types = ['deltasat', 'nps', 'deltatone'];

    private corpus: GetCorpus = null;

    @Input()
        dashboardIndex = null;

    params;

    get hasData() {
        return this.dashboardService?.dashboardData.getValue()?.hits.total !== 0;
    }

    /**
     * Vérifie d'abord puis charge les données de ce composant si c'est ok
     */
    checkAndLoadConfigAndData() {
        const comparisonConfig = this.manageDashboardService.currentDashboardComparisonConfig.getValue();
        // Si on N'est PAS en mode comparaison
        // OU
        // si la config de ce composant correspond à la config de la comparaison affichée
        if (!comparisonConfig || comparisonConfig.comparison.satisfaction === this.comparisonMode) {
            this.loadConfig();
            this.loadSatisfactionData();
        }
    }

    ngOnInit(): void {
        // Lorsque les dashboards sont chargés
        this.subs.sink = this.manageDashboardService.dashboardLoaded.subscribe(() => {
            this.checkAndLoadConfigAndData();
        });
        this.subs.sink = this.manageDashboardService.dashboardListChanged.subscribe(() => {
            this.checkAndLoadConfigAndData();
        });
        // Lorsque les filtres sont mis à jour
        this.subs.sink = this.manageDashboardService.applyNewFiltersOnAnalyseDashboard.subscribe(() => {
            this.checkAndLoadConfigAndData();
        });
        // Lorsque l'on change de mode d'affichage (pour la comparaison mode Cote à Cote ou Fusionné)
        this.subs.sink = this.manageDashboardService.comparisonSwitchMode.subscribe(({ section }) => {
            // Si la modification d'affichage correspond à la section de ce composant
            if (section === 'satisfaction') {
                this.checkAndLoadConfigAndData();
            }
        });

        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            this.corpus = corpus;
        });
    }

    getData(type: string) {
        if (this.satisfactionData === null || !this.satisfactionData[type]) {
            return null;
        }
        return this.satisfactionData[type];
    }

    get hasCopc(): boolean {
        return this.corpus?.has_copc || false;
    }

    get comparisonMode() {
        return this.dashboardService ? ComparisonMode.side_by_side : ComparisonMode.merged;
    }

    loadConfig() {
        const config: DashboardConfig = this.dashboardService
            ? this.dashboardService.currentConfig.getValue()
            : this.manageDashboardService.currentDashboardComparisonConfig.getValue();

        this.params = _.cloneDeep(config.satisfaction);
    }

    async loadSatisfactionData() {
        if (this.comparisonMode === ComparisonMode.side_by_side) {
            this.loadSatisfactionSideBySideData();
        } else {
            this.loadSatisfactionMergedData();
        }
    }

    public loadSatisfactionSideBySideData() {
        const params: SatisfactionMergeParams = {
            searchparams: [this.dashboardService.lastAppliedFilters.getValue()],
            output_columns: _.cloneDeep(this.dashboardService.currentConfig.getValue().satisfaction),
        };

        this.prepareOutputColumnsParam(params);

        this.dashboardApi.getSatisafactionMergedData(
            this.dashboardService.currentDashboard.getValue().dash_id,
            params,
        ).subscribe((data) => {
            this.satisfactionData = data;
        });
    }

    public loadSatisfactionMergedData() {
        const searchparams = this.manageDashboardService.dashboardServices.map((service) => _.cloneDeep(service.lastAppliedFilters.getValue()));
        const firstDashboard = this.manageDashboardService.dashboardServices[0].currentDashboard.getValue();

        const params: SatisfactionMergeParams = {
            searchparams,
            output_columns: _.cloneDeep(this.manageDashboardService.currentDashboardComparisonConfig.getValue().satisfaction),
        };

        this.prepareOutputColumnsParam(params);

        // dans le cas d'un dashboard fusionné, on met le nombre de courbes max à une très grande valeur
        // pour demander de charger toutes les courbes. Si on laisse le paramètre vide, cela ne charge que 12 courbes
        // on pourrait aussi chercher le nombre exact de courbes à demander, mais comme ca le code est plus simple
        ['deltasat', 'nps', 'deltatone'].forEach((type) => {
            params.output_columns[type].distri.nb_values_chart = 50;
        });

        this.dashboardApi.getSatisafactionMergedData(
            firstDashboard.dash_id,
            params,
        ).subscribe((data) => {
            this.satisfactionData = data;
        });
    }

    private prepareOutputColumnsParam(params: SatisfactionParams) {
        ['deltasat', 'nps', 'deltatone'].forEach((type) => {
            const period = this.graphService.getDefaultPeriod(params.output_columns[type].distri.period, this.dashboardService);
            params.output_columns[type].distri.period = period;

            // si thématique est sélectionnée, et qu'il n'y a pas de thématique, on bascule sur moyenne globale
            if (params.output_columns[type].distri.grouped_values === GroupedValue.thematic && this.manageDashboardService.dashboardServices[0].lastAppliedFilters.getValue().thematics.length <= 0) {
                params.output_columns[type].distri.grouped_values = GroupedValue.average;
            }
            // si les classes sont sélectionnée, et qu'il n'y a pas de classification auto, on bascule aussi sur moyenne glbale
            if (params.output_columns[type].distri.grouped_values === GroupedValue.class && this.manageDashboardService.dashboardServices[0].getCurrentModel() === undefined) {
                params.output_columns[type].distri.grouped_values = GroupedValue.average;
            }

            // si on ne regroupe pas par colonne de donnée associée, on supprime les paraméètres spécifiques aux données associées
            if (params.output_columns[type].distri.grouped_values !== GroupedValue.column) {
                delete params.output_columns[type].distri.grouped_field;
                delete params.output_columns[type].distri.selectedColumnDisplayName;
            }

            // si moyenne globale est sélectionné, on supprime grouped_values, et le top
            if (params.output_columns[type].distri.grouped_values === GroupedValue.average) {
                delete params.output_columns[type].distri.grouped_values;
                delete params.output_columns[type].distri.nb_values_chart;
            }

            if (!params.output_columns[type].distri.top_activated || !this.dashboardService) {
                delete params.output_columns[type].distri.nb_values_chart;
            } else if (params.output_columns[type].distri.nb_values_chart > 50) {
                params.output_columns[type].distri.nb_values_chart = 50;
            }
            delete params.output_columns[type].distri.top_activated;
        });
    }
}
