import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// eslint-disable-next-line max-len
import FiltersClassificationsAutoPopupModelInfoComponent from 'app/modules/corpus/components/vertical-filters/components/filters-classifications-auto/component/filters-classifications-auto-popup-model-info/filters-classifications-auto-popup-model-info.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-classification-auto-info',
    templateUrl: './classification-auto-info.component.html',
})
export default class ClassificationAutoInfoComponent {
    constructor(
        public manageDashboardService: ManageDashboardService,
        private modalService: NgbModal,
    ) {
    }

    /**
     * Affiche les informations du modèle sélectionné
     */
    showModelInfo() {
        const modal = this.modalService.open(FiltersClassificationsAutoPopupModelInfoComponent, { scrollable: true });
        modal.componentInstance.model = this.manageDashboardService.getCurrentModel();
    }
}
