// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-words {
  min-width: 250px;
  z-index: 1001;
  font-size: 12px;
  padding: 10px;
}

.hidden-words .btn-outline-secondary {
  border-color: black;
}

.hidden-word-instructions,
.hidden-word-item {
  display: block;
}

.hidden-word-item {
  white-space: nowrap;
  text-decoration: underline;
}

.hidden-word-restore-all {
  font-weight: bold;
}

.dropdown-button {
  float: right;
  margin: 0.625rem;
}

.float-right {
  float: right;
}

.dropdown-toggle::after {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
