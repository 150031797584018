import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Meta } from '@angular/platform-browser';
import { ResetCorpus } from '../../../stores/actions/corpus/corpus.actions';
import { UserService } from '../../../api/services';
import { ResetUser } from '../../../stores/actions/user/user.actions';
import { ResetNewCorpus } from '../../../stores/actions/new-corpus/new-corpus.actions';

/**
 * Logout user
 *
 * @export
 * @class LogoutComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
})
export default class LogoutComponent implements OnInit {
    constructor(
        private route: Router,
        private metaService: Meta,
        private user: UserService,
        private store: Store,
        private permissionsService: NgxPermissionsService,
    ) {}

    ngOnInit() {
        this.user.getV1AuthLogout().subscribe(() => {
            this.store.dispatch(new ResetUser()).subscribe(() => {
                this.store.dispatch(new ResetCorpus()).subscribe(() => {
                    this.store.dispatch(new ResetNewCorpus()).subscribe(() => {
                        this.permissionsService.flushPermissions();
                        if (this.metaService.getTag('name= "gassi"')) {
                            this.route.navigate(['/auth/logout']);
                            // console.log('******gassi logout');
                        } else this.route.navigate(['/auth/login']);
                    });
                });
            });
        });
    }
}
