import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-distribution-details-popup-pill',
    templateUrl: './distribution-details-popup-pill.component.html',
    styleUrls: [],
})
export class DistributionDetailsPopupPillComponent {
    @Input()
        average: number;
}
