import {
    Component, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { SetNews } from 'app/stores/actions/news/news.actions';
import { NewsStateModel } from 'app/stores/models/news/news-state.model';
import NewsState from 'app/stores/state/news/news.state';
import { NGXLogger } from 'ngx-logger';
import { Observable, firstValueFrom } from 'rxjs';
import * as _ from 'lodash';
import NewsService from 'app/api/services/news.service';
import { News } from 'app/api/models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'app/base.component';
import { UserService } from 'app/api/services';
import UserState from 'app/stores/state/user/user.state';
import { User } from 'app/stores/models/auth/user.model';
import NewsModalService from './news-modal.service';

@Component({
    selector: 'app-news-modal',
    templateUrl: './news-modal.component.html',
})
export default class NewsModalComponent extends BaseComponent implements OnInit {
    newsState$: Observable<NewsStateModel>;

    userState$: Observable<User>;

    // News variables
    public news: News[] = [];

    public displayNews = true;

    private prevDisplayNews = true;

    public currentLanguage: string;

    anotherModalRef: NgbModalRef;

    private userNewsListId: Array<number>;

    @ViewChild('newsModal')
        newsModal: TemplateRef<any>;

    @ViewChild('newsModalDisplayer')
        newsModalDisplayer: TemplateRef<any>;

    /**
     * Return news headline to display in the news modal
     */
    get headlineNews() {
        return this.news.filter((item) => item.isHeadline && item.isRecent && this.userNewsListId.includes(item.id));
    }

    /**
     * Return news filtered to display in the news modal
     */
    get filteredNews() {
        return this.news.filter((item) => !item.isHeadline && item.isRecent && this.userNewsListId.includes(item.id));
    }

    constructor(
        private logger: NGXLogger,
        private newsService: NewsService,
        private userService: UserService,
        private store: Store,
        private modalService: NgbModal,
        private newsModalService: NewsModalService,
    ) {
        super();
    }

    ngOnInit(): void {
        // Loading News State
        this.newsState$ = this.store.select(NewsState);
        this.subs.sink = this.newsState$.subscribe((state) => {
            this.logger.info('Get news state');
            if (_.isEmpty(state.newsList)) {
                this.newsService.getV1News().subscribe((result) => {
                    this.store.dispatch(new SetNews(result.newslist));
                    this.news = this.store.snapshot().news.newsList;
                });
            } else {
                this.news = this.store.snapshot().news.newsList;
            }
        });

        // Loading News State
        this.userState$ = this.store.select(UserState);
        this.subs.sink = this.userState$.subscribe((state: User) => {
            if (state.newslist) {
                this.userNewsListId = state.newslist.map((news) => news.id);
            }
        });

        this.subs.sink = this.newsModalService.open.subscribe(() => {
            this.openNewsModal();
        });
    }

    // News Modal
    openNewsModal() {
        this.prevDisplayNews = this.displayNews;
        this.anotherModalRef = this.modalService.open(this.newsModal, { backdrop: 'static' });
    }

    async closeModal() {
        if (this.prevDisplayNews !== this.displayNews) {
            if (this.displayNews) {
                await firstValueFrom(this.userService.postV1UserNewsViewedReset());
            } else {
                await firstValueFrom(this.userService.postV1UserNewsViewed(this.filteredNews.map((news) => `${news.id}`)));
            }
        }
        this.anotherModalRef.close();
    }
}
