/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { CorpusDataDownload, Hits } from 'app/api/models';
import { Fieldname } from 'app/api/models/fieldname';
import { Method } from 'app/api/models/graphparams';
import { HeatmapDetailsConfiguration } from 'app/api/models/heatmap';
import { SearchFieldType, SearchSortOrder } from 'app/api/models/searchparams';
import { CorpusService as CorpusApi, DashboardService as DashboardApi } from 'app/api/services';
import ExportType from 'app/modules/CommonExportTypeEnum';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ModalComponent from 'app/shared/components/modal/modal.component';
import download from 'app/utils/functions/download';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import BaseCloudComponent from '../../../words/BaseCloudComponent';
import analytics from '../../../words/words.analytics';

@Component({
    selector: 'heatmap-details-popup',
    templateUrl: './heatmap-details-popup.component.html',
    styleUrls: ['./heatmap-details-popup.component.scss'],
})
export default class HeatmapDetailsPopupComponent extends BaseCloudComponent implements OnInit, AfterViewInit {
    @Input()
    private item;

    @ViewChild('verbatimtable')
    private verbatimColumn: ElementRef<HTMLInputElement>;

    dashboardService: DashboardService;

    heatmapDetailsConfiguration: HeatmapDetailsConfiguration;

    private currentPage:number = 0;

    public pageCount: number = 0;

    public tableData : any;

    public maxPageSize: number = 100;

    verbatims: Hits = [] as Hits;

    public _method: string;
    
    columns = [];

    modalRef: BsModalRef;

    exportType = ExportType;

    private selectedType: ExportType;

    private totalFilter;

    @ViewChild('classesTpl', { read: TemplateRef }) classesTpl;

    private tableConfig = {
        style: {
            maxHeight: '100%',
            borderTop: 'none',
        },
        autoScroll: true,
        externalSort: true,
    };

    constructor(
        public modal: NgbActiveModal,
        private dashboardApi: DashboardApi,
        translate: TranslateService,
        private store: Store,
        private modalService: BsModalService,
        private ngModalService: NgbModal,
        private http: HttpClient,
        private corpus: CorpusApi,
    ) {
        super(translate);
    }

    ngAfterViewInit(): void {
        const maxHeight = this.verbatimColumn.nativeElement.offsetHeight - 135 + (this.pageCount < 2 ? 70 : 0);
        this.tableConfig.style.maxHeight = `${maxHeight}px`;
        this.initTables();
    }

    get verbatimFieldName() {
        const verbatimFields: Array<Fieldname> = this.dashboardService.getCorpusService().currentCorpus.getValue().corp_verbatim_fields;
        const verbatimDbName : string = this.dashboardService.lastAppliedFilters.getValue().verbatim_dbname;
        const field = verbatimFields.find((f) => f.DBName === verbatimDbName);
        return (field) ? field.displayName : '';
    }

    set method(method: string) {
        this._method = method;
    }

    get method() {
        return this.translate.instant(`translations.analysisDashboard.heatmap.${this._method}`);
    }

    get gtmTrackMethod() {
        return this._method === Method.occur ? 'cooccurrence' : 'corrélation';
    }

    get gtmTrackSelectedType() {
        return this.selectedType === 'default' ? 'zip' : 'xslx';
    }

    getTrackSubCategoryName() {
        return analytics.top_keywords_to_track_cible[this.subCategory];
    }

    getTrackCategoryName() {
        return analytics.top_keywords_to_track_cible[this.category];
    }

    get category(): string {
        return this.heatmapDetailsConfiguration.category;
    }

    set category(value: string) {
        this.heatmapDetailsConfiguration.category = value;
    }

    get subCategory(): string {
        return this.heatmapDetailsConfiguration.subCategory;
    }

    set subCategory(value: string) {
        this.heatmapDetailsConfiguration.subCategory = value;
    }

    get selectedTab() {
        return this.heatmapDetailsConfiguration.selectedTab;
    }

    set selectedTab(value:string) {
        this.heatmapDetailsConfiguration.selectedTab = value;
    }

    set selectedSize(size:number) {
        this.heatmapDetailsConfiguration.selectedSize = size;
    }

    get selectedSize() {
        return this.heatmapDetailsConfiguration.selectedSize;
    }

    get isZoomIn() {
        return this.heatmapDetailsConfiguration.isZoomIn;
    }

    set isZoomIn(value: boolean) {
        this.heatmapDetailsConfiguration.isZoomIn = value;
    }

    get pertinenceChecked() {
        return this.heatmapDetailsConfiguration.pertinenceIsChecked;
    }

    set pertinenceChecked(value:boolean) {
        this.heatmapDetailsConfiguration.pertinenceIsChecked = value;
    }

    get pertinenceDisabled() : boolean {
        return this.dashboardService.isPertinenceDisabled();
    }

    get selectedColmuns() {
        return this.heatmapDetailsConfiguration.selectedColumns;
    }

    ngOnInit() {
        super.ngOnInit();

        this.counts = {
            count: 0,
            filtered: 0,
            total: this.store.snapshot().corpus.corp_file_max_lines,
        };

        this.subs.sink = this.dashboardService.dashboardData.subscribe((data) => {
            if (data !== null) {
                this.totalFilter = data.hits.total;
            }
        });

        this.currentPage = 1;
        this.initTables();
        this.loadVerbatims();
        this.loadWordList();
    }

    public changePage(page: number) {
        this.currentPage = page;
        this.loadVerbatims();
    }

    get isSortAsc() {
        return this.sortedColumn?.sort === SearchSortOrder.ASC;
    }

    get sortedColumn() {
        return this.selectedColmuns.find((column) => column.sort);
    }

    get currentFilters() {
        // eslint-disable-next-line no-underscore-dangle
        const filters = _.cloneDeep(this.dashboardService.currentFilters.getValue());

        filters.search_fields.forEach((field) => {
            if (!this.pertinenceChecked && field.DBName === this.sortedColumn?.DBName) {
                field.sort = this.sortedColumn.sort;
            } else {
                delete field.sort;
            }
        });
        return filters;
    }

    // eslint-disable-next-line class-methods-use-this
    /**
     * Ajout du filtre filters_and, spécifique à la heatmap à ajouter à toutes les requête effectuées dans la popup pour filtrer selon la ligne et la colonne sélectionnée
     */
    buildAndSubFilter(key:string, value:string) {
        // structure spécifique au classes
        if (key.startsWith('class')) {
            return {
                type: 'filters_class',
                condition: [{
                    inputName: value,
                }],

            };

            // structure spécifique aux tonalités
        } if (key.startsWith('thematic')) {
            const filter = {
                type: 'thematics',
                condition: {
                    thematic_not: false,
                    thematics: [],
                }
                ,
            };

            // dans le cas où l'on clique sur "Hors thématique", on ajoute tous les ids de thématique avec le thematic_not à true
            if (value === 'Out of thematics') {
                filter.condition.thematic_not = true;
                filter.condition.thematics = this.dashboardService.lastAppliedFilters.getValue().thematics.map((thematic) => ({ them_id: thematic.them_id, selected: thematic.selected }));
            } else {
                filter.condition.thematics.push({ them_id: value, selected: true });
            }
            return filter;
        }
        return {
            // cas générique pour tous les autres filtres
            type: 'filters',
            condition: [{
                inputName: key,
                values: [value],
            }],

        };
    }

    /**
     * Création du and_filters pour l'axe des abscisse et celui des ordonnés
     */
    get buildAndFilter() {
        return {
            filters_and: [
                this.buildAndSubFilter(this.item.xInputName, this.item.xValueInputName),
                this.buildAndSubFilter(this.item.yInputName, this.item.yValueInputName),
            ],
        };
    }

    /**
     * Chargement de la liste des verbatims. On n'utilise pas la méthode du service pour ne pas déclencher tous les notifications.
     */
    loadVerbatims() {
        this.dashboardApi
            .postV1DashboardDashIdData({
                dashId: this.dashboardService.currentDashboard.getValue().dash_id,
                body: {
                    ...this.currentFilters,
                    // ci dessous on ajoute le filtre spécifique à la heatmap
                    ...this.buildAndFilter,
                    ...this.dashboardService.getApiDates(),
                    page: this.currentPage,
                },
                page: this.currentPage,
            })
            .subscribe((data) => {
                this.onDataLoaded(data);
            });
    }

    get disabled() {
        return !this.words[this.subCategory] || this.words[this.subCategory].length === 0;
    }

    /**
     * Lorsque la liste des verbatim est chargée
     */
    onDataLoaded(data) {
        this.tableData = data;

        const verbatims = [];

        // mise à jour des compteurs
        this.counts = {
            count: data.hits.total,
            filtered: this.totalFilter,
            total: this.store.snapshot().corpus.corp_file_max_lines,
        };

        this.pageCount = data.hits.total_pages || 0;

        if (Object.prototype.hasOwnProperty.call(data, 'hits')
            && Object.prototype.hasOwnProperty.call(data.hits, 'total_pages')) {
            this.pageCount = data.hits.total_pages;
        }

        // highlights des mots de la freesearch, des keywords et thématiques
        let stringsToHighligth: string[] = (this.dashboardService.lastAppliedFilters.getValue().freesearch.match(/"[^"]+"|[\S^"]+/gi) || []).map((value) => value.replace(/"/g, ''));

        stringsToHighligth = stringsToHighligth.filter((value) => (value.trim().startsWith('@') || value.trim().startsWith('#')));

        this.dashboardService.lastAppliedFilters.getValue().keywordsearch.forEach((keyword) => {
            stringsToHighligth.push(keyword.values[0]);
        });

        const regex: RegExp = stringsToHighligth.length === 0 ? null : new RegExp(`(${stringsToHighligth.join('|')})`, 'gi');

        if (Object.prototype.hasOwnProperty.call(data.hits, 'hits')) {
            data.hits.hits.forEach((verbatim) => {
                // eslint-disable-next-line no-underscore-dangle
                const verbatimLine = verbatim._source;
                this.store.snapshot().corpus.corp_verbatim_fields.forEach((verbatimField) => {
                    const key = verbatimField.DBName;
                    if (verbatimLine[key]) {
                        if (verbatim.highlight && verbatim.highlight[key]) {
                            const [firstHighlightVerbatim] = verbatim.highlight[key];
                            verbatimLine[key] = firstHighlightVerbatim;
                        }

                        if (regex !== null) {
                            verbatimLine[key] = verbatimLine[key].replace(regex, '<span class="highlight">$1</span>');
                        }
                    }
                });
                verbatims.push(verbatimLine);
            });
        }
        this.verbatims = [...verbatims] as Hits;
    }

    getColumns() {
        const columns = [];

        columns.push({
            prop: this.dashboardService.lastAppliedFilters.getValue().verbatim_dbname,
            sort: false,
            titleNoTranslate: this.verbatimFieldName,
            wordWrap: true,
            style: {
                'min-width': '700px',
                'max-width': '700px',
            },
        });

        // On ajoute toutes les colonnes sélectionnées
        this.selectedColmuns.filter((column) => column.checked).forEach((column) => {
            if (column.type === SearchFieldType.CLASS) {
                // eslint-disable-next-line @typescript-eslint/dot-notation
                const model = Object.values(this.store.snapshot().corpus.models).find((value) => value['mode_id'] === column.mode_id);
                // eslint-disable-next-line @typescript-eslint/dot-notation
                const prefix = model['mode_prefix'];
                const prop = prefix && prefix !== '' ? `${prefix}.motifs_display` : 'motifs_display';
                if (model) {
                    columns.push({
                        // eslint-disable-next-line @typescript-eslint/dot-notation
                        prop,
                        sort: false,
                        title: 'translations.analysisDashboard.verbatim.choiceColumns.classes',
                        wordWrap: true,
                        style: {
                            'max-width': '700px',
                        },
                        cellTemplate: this.classesTpl,
                    });
                }
            } else {
                columns.push({
                    prop: column.DBName,
                    sort: this.pertinenceDisabled || !this.pertinenceChecked,
                    titleNoTranslate: column.displayName,
                    wordWrap: true,
                    style: {
                        'max-width': '700px',
                    },
                });
            }
        });

        return columns;
    }

    initTables() {
        if (!this.currentPage) {
            this.currentPage = 1;
        }

        this.columns = this.getColumns();
    }

    /**
     * Chargement des keywords
     */
    loadWordList() {
        this.dashboardApi
            .postV1DashboardDashIdKeywords(
                this.dashboardService.currentDashboard.getValue().dash_id,
                {
                    ...this.dashboardService.getApiDates(),
                    ...this.dashboardService.lastAppliedFilters.getValue(),
                    // ci dessous le filtre spécifique à la heatmap
                    ...this.buildAndFilter,
                },
            )
            .subscribe((keywords) => {
                this.words = keywords.aggregations;
                this.buildWordsList();
            });
    }

    get useDeltaTone() {
        return this.dashboardService.currentConfig.getValue().verbatim.cloud_use_delta_tone;
    }

    tabSelected(event) {
        this.selectedTab = event;

        if (event === 'cloud') {
            setTimeout(() => {
                this.cloud.redraw();
            });
        }
    }

    exportToCSV() {
        this.dashboardService.downloadKeywords(this.subCategory);
    }

    onSortCol(column): void {
        this.selectedColmuns.forEach((f) => {
            if (f.DBName === column.prop) {
                f.sort = column.order ? SearchSortOrder.ASC : SearchSortOrder.DESC;
            } else {
                delete f.sort;
            }
        });

        this.columns = this.getColumns();
        this.loadVerbatims();
    }

    onChangePertinence() {
        this.columns = this.getColumns();
        this.loadVerbatims();
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    }

    /**
     * Téléchargement synchrone d'un fichier avec un nombre d'enregistrement limité à 10000
     */
    downloadFile() {
        const downloadReq = this.initDownloadData();

        if (this.selectedType === ExportType.EXCEL) {
            downloadReq.format = ExportType.EXCEL;
        }

        this.http.post(`${this.corpus.rootUrl}/v1/dashboard/${this.currentDash.dash_id}/data/download`, downloadReq, { responseType: 'blob', observe: 'response' }).subscribe((resp) => {
            const filename = resp.headers.get('content-disposition').split('=')[1];
            download(window.URL.createObjectURL(resp.body), filename);
            this.selectedType = null;
        });
    }

    /**
   * Initialise les paramètres à envoyer pour les requêtes de download
   */
    initDownloadData():CorpusDataDownload {
        const downloadReq: CorpusDataDownload = {};
        downloadReq.searchparams = {
            ...JSON.parse(JSON.stringify(this.dashboardService.lastAppliedFilters.value)),
            // ci dessous le filtre spécifique à la heatmap
            ...this.buildAndFilter,
        };
        // Si le bouton "tri par pertinence" est coché, on désactive tous les tris de la recherche
        if (this.pertinenceChecked) {
            downloadReq.searchparams.search_fields.forEach((c) => {
                if (c.type !== SearchFieldType.SORT_ANNO && c.type !== SearchFieldType.SORT_FAVO) {
                    delete c.sort;
                }
            });
        }
        downloadReq.columns = [];

        const columns = this.store.snapshot().corpus.corp_fields;
        columns.forEach((col) => {
            downloadReq.columns.push(col.inputName);
        });
        return downloadReq;
    }

    get currentDash() {
        return this.dashboardService.currentDashboard.getValue();
    }

    /**
     * Téléchargeent asynchrone d'un fichier avec nombre d'enregistrement > 10000
     */
    downloadAllFile() {
        const downloadReq = this.initDownloadData();

        if (this.selectedType === ExportType.EXCEL) {
            downloadReq.format = ExportType.EXCEL;
        }

        this.http.post(`${this.corpus.rootUrl}/v1/dashboard/${this.currentDash.dash_id}/data/download/all`, downloadReq, { responseType: 'blob', observe: 'response' })
            .subscribe({
                next: () => {
                    const modal = this.ngModalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.verbatim.exportallSuccess';
                    modal.componentInstance.alertTypeVariant = 'info';
                    this.selectedType = null;
                },
                error: () => {
                    const modal = this.ngModalService.open(ModalComponent, {});
                    modal.componentInstance.titleToTranslate = 'translations.httpErrors.title';
                    modal.componentInstance.alertTypeVariant = 'danger';
                },

            });
    }

    // eslint-disable-next-line class-methods-use-this
    classToString(value) {
        return value instanceof Array ? value.join('; ') : value;
    }
}
