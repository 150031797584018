/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import drawDashboardNumber from './chartjs-plugins-utils';

export const addDashboardNumberToHorizontalBarChart = {
    id: 'addDashboardNumberToHorizontalBarChart',
    afterDatasetsDraw(chart, args, options) {
        if (chart.config._config.type !== 'bar') {
            return;
        }

        const { ctx } = chart;

        ctx.textBaseline = 'middle';

        ctx.font = 'bold  12px \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif';
        const unit = chart.config.options.scales.x.ticks.percent ? '%' : '';

        const lastBars = {};

        // affichage des valeurs dans les barres
        // eslint-disable-next-line @typescript-eslint/dot-notation
        chart['_metasets'].forEach((metaset, i) => {
            const dataset = metaset._dataset;
            const meta = chart.getDatasetMeta(i);

            // affichage des valeurs à l'intérieur des barres
            meta.data.forEach((bar, index) => {
                if (options.tones) {
                    if (!lastBars[bar.y] || lastBars[bar.y].maxX < bar.x) {
                        lastBars[bar.y] = {
                            maxX: bar.x,
                            maxY: bar.y,
                            total: dataset.data[index].total,
                            dashboard: dataset.stack,
                        };
                    }
                } else {
                    const text = (dataset.data[index].value || 0) + unit;
                    const textWidth = ctx.measureText(text).width;
                    ctx.textAlign = 'left';

                    let x = bar.x + 10 + textWidth;

                    if (x > ctx.canvas.clientWidth) {
                        x = bar.x - 25;
                    }

                    drawDashboardNumber(ctx, dataset.stack, x, bar.y);
                }
            });
        });

        Object.values(lastBars).forEach((value: any) => {
            const text = value.total + unit;
            const textWidth = ctx.measureText(text).width;
            drawDashboardNumber(ctx, value.dashboard, value.maxX + 10 + textWidth, value.maxY);
        });
    },

};
