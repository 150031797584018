import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResetPassword } from '../../../api/models/reset-password';
import { UserService } from '../../../api/services';

/**
 * Reset password form
 *
 * @export
 * @class ResetpasswordformComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-resetpasswordform',
    templateUrl: './resetpasswordform.component.html',
})
export default class ResetpasswordformComponent {
    public username;

    public errorMsg;

    public isLoading = false;

    public isValide: any = {
        pwLength: false,
        maj: false,
        min: false,
        number: false,
        special: false,
        confirm: false,
    };

    public valide = false;

    public passwordValid;

    public confirmValid;

    public password;

    public passwordConfirm;

    public success = false;

    private token;

    constructor(
        private route: ActivatedRoute,
        private user: UserService,
    ) {
        this.username = this.route.snapshot.paramMap.get('username');
        this.token = this.route.snapshot.paramMap.get('token');
    }

    onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;
        const params: ResetPassword = {
            username: this.username,
            token: this.token,
            new_password: this.password,
        };
        this.user.postV1UserResetpassword(params).subscribe({
            next: () => {
                this.success = true;
            },
            error: (error) => {
                this.errorMsg = `translations.httpErrors.${error?.error?.error ? error.error.error : 'title'}`;
            },
        }).add(() => {
            this.isLoading = false;
        });
    }

    checkPassword() {
        this.errorMsg = null;
        this.isValide.confirm = this.passwordConfirm === this.password;
        this.confirmValid = this.isValide.confirm;
        this.valide = Object.entries(this.isValide).filter((check) => check[1] === false)
            .length === 0;
    }

    isValidPassword() {
        this.errorMsg = null;
        const majR = /[A-Z]/;
        const minR = /[a-z]/;
        const numR = /\d/;
        const speR = /[^a-zA-Z0-9]/g;

        this.isValide.pwLength = this.password.length >= 10 && this.password.length < 32;
        this.isValide.maj = majR.test(this.password);
        this.isValide.min = minR.test(this.password);
        this.isValide.number = numR.test(this.password);
        this.isValide.special = speR.test(this.password);
        this.passwordValid = Object.entries(this.isValide).filter(
            (check) => check[1] === false && check[0] !== 'confirm',
        ).length === 0;
        this.checkPassword();

        this.isValide = { ...this.isValide };
    }
}
