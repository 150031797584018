/**
 * Defines the attributes for a single Cloud element
 */
export interface CloudData {

    /**
     * Set the text string for the Cloud element
     */
    key: string;

    /**
     * Set the subCategory of the Cloud element
     */
    subCategory?: string;

    /**
     * set the weight for the element.
     *
     * The `weight` property defines the relative importance of the word (such as the number of occurrencies, etc.).
     * The range of values is arbitrary, and they will be linearly mapped to a discrete scale from 1 to 10.
     * In fact passing just one word to the array has the effect that this is relative to other elements.
     * As there aren't any other elements in that case it's result is that the element becomes a container
     * with the class `w5` - right in the middle of the discret scale.
     * The given value for `weight` is not directly mapped to the CSS-class.
     * For example you can use also a value like `123` or `34` - it will always be mapped to a scale from 1 to 10
     * relativly to the other array elements.
     * If you don't want that the tag cloud is calculating the values manually,
     * set the `strict` property to `true` and use integer values `1` to `10` within the `weight` property.
     */
    doc_count?:number;

    /**
     * Specifies a valid CSS color string for colorizing the element.
     * This will override probably your CSS setttings
     */
    color?: string;

    /**
     * Define a tooltip text
     */
    tooltip?: string;

    /**
     * the position from the left
     */
    left?: number;
    /**
     * the position from the top
     */
    top?: number;

    right?:number;

    bottom?:number;

    deltatone?:number;

    width?:number;

    height?:number;

    fontSize?:number;

    deltaTonePosition?:number;

    fromBottom?:number;

    top_sentiment?:Array<Sentiment>;

    hidden ?:boolean;
}

export enum SentimentKey {
    POS = 'POS',
    NEU = 'NEU',
    MIT = 'MIT',
    NEG = 'NEG',
}

export interface Sentiment {
    key: SentimentKey;

    doc_count:number;

    percent: number;
}

export interface ResultCounts {
    count: number;

    filtered: number;

    total: number;
}
