/**
 * File status list
 */
enum FileStatus {
    FILE_MISSING = 0, // Le fichier est absent du disque
    FILE_UPLOADED = 1, // Le fichier a été téléchargé mais non lié à un corpus
    FILE_IN_CORPUS = 2, // le fichier est associé à un corpus mais non analysé
    FILE_ANALYSE_STARTED = 3, // L'analyse du fichier a démarrée
    FILE_PREPARED = 4, // Le fichier a été préparé
    FILE_PREANALYSED = 5, // Le fichier a été pre-analysé
    FILE_ANALYSED = 6, // Le fichier a été analysé
    FILE_INDEXED = 7, // Le fichier a été indexé
    FILE_INDEXED_ASYNC = 11, // Le fichier est en analyse asynchrone
    FILE_MAIL = 8, // Mail de compte-rendu envoyé
    FILE_ANALYSE_ENDED = 9, // L'analyse du fichier est terminée
    FILE_ANALYSE_NEEDED = 10, // L'analyse du fichier doit être refaite (suite à une modif des paramètres du corpus)
}

export default FileStatus;
