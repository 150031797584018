// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css-grid-container {
  display: grid;
  grid-template-columns: 900px auto;
  grid-template-rows: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-areas: "title title" "project-desc ." "project-form .";
  background-color: white;
  grid-row-gap: 0 !important;
}

.css-project-title {
  grid-area: title;
}

.css-project-description {
  grid-area: project-desc;
}

.css-project-form {
  grid-area: project-form;
}

.no-col-date-prefix,
.no-col-date-suffix {
  font-weight: normal !important;
}

.no-col-date-prefix {
  margin-left: 25px;
}

.no-col-date-picker {
  display: unset !important;
  margin-left: 10px;
  margin-right: 10px;
}

.dont-use-radio {
  display: inline-block;
  margin-bottom: 20px;
}

/* enable absolute positioning */
.inner-addon {
  position: relative;
  display: unset !important;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  pointer-events: none;
  font-size: 20px;
}

.calendar-displayed {
  color: #f16e00;
}

/* align icon */
.left-addon .glyphicon {
  left: 18px;
  top: 0;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}

.form-control {
  border-color: black;
}
.form-control:focus {
  border-color: #ccc;
}
.form-control[bsdatepicker] {
  width: 150px;
}

.spinner-cell {
  height: 80px;
}
.spinner-cell i {
  font-size: 48px;
  font-weight: bold;
}

.alert-warning {
  border-color: transparent;
  padding: 0;
}

.msurvey-message {
  flex: 1 1 auto;
  max-width: 900px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
