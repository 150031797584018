/* eslint-disable @typescript-eslint/no-useless-constructor */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import {
    HttpClient, HttpHeaders, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable as __Observable } from 'rxjs';
import { filter as __filter, map as __map } from 'rxjs/operators';
import ApiConfiguration from '../api-configuration';
import BaseService from '../base-service';
import { Cgu } from '../models/cgu';
import { Gdpr } from '../models/gdpr';
import { Jeton } from '../models/jeton';
import { Login } from '../models/login';
import { ResetPassword } from '../models/reset-password';
import { User } from '../models/user';
import { Userexist } from '../models/userexist';
import { Userprofileupdate } from '../models/userprofileupdate';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';

/**
 * Parameters for patchV1UserUsername
 */
export interface PatchV1UserUsernameParams {
    /**
     * User login
     */
    username: string;
    body: Userprofileupdate;
}

/**
 * User management and login, logout
 */
@Injectable({
    providedIn: 'root',
})
class UserService extends BaseService {
    static readonly postV1AuthLoginPath = '/v1/auth/login';

    static readonly getV1AuthLogoutPath = '/v1/auth/logout';

    static readonly getV1UserEmailExistPath = '/v1/user/{email}/exist';

    static readonly getV1UserUsernameOrEmailLostpasswordPath = '/v1/user/{usernameOrEmail}/lostpassword';

    static readonly postV1UserResetpasswordPath = '/v1/user/resetpassword';

    static readonly getV1UserEmailRequestaccountPath = '/v1/user/{email}/requestaccount';

    static readonly getV1UserGdprPath = '/v1/user/gdpr';

    static readonly getV1UserCguPath = '/v1/user/cgu';

    static readonly postV1UserCguPath = '/v1/user/cgu';

    static readonly putV1UserGdprPath = '/v1/user/gdpr';

    static readonly postV1UserGdprPath = '/v1/user/gdpr';

    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    /**
   * This command allow to be authenticated on the API and to get a JWT Token.
   * @param body undefined
   * @return Jeton JWT
   */
    postV1AuthLoginResponse(body: Login): __Observable<__StrictHttpResponse<Jeton>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/auth/login`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Jeton>),
        );
    }

    /**
   * This command allow to be authenticated on the API and to get a JWT Token.
   * @param body undefined
   * @return Jeton JWT
   */
    postV1AuthLogin(body: Login): __Observable<Jeton> {
        return this.postV1AuthLoginResponse(body).pipe(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            __map(({ body }) => body as Jeton),
        );
    }

    /**
   * This command allow to be authenticated on the API with META and to get a JWT Token.
   * @param body undefined
   * @return Jeton JWT
   */
    postV1AuthLoginMetaResponse(Loginwithmeta): __Observable<__StrictHttpResponse<Jeton>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        // __body = body;
        // __headers = __headers
        //     .set('ftusermail', Loginwithmeta.ftusermail)
        //     .set('sm_authtype', Loginwithmeta.sm_authtype)
        //     .set('sm_universalid', Loginwithmeta.sm_universalid)
        // // .set('ftusergivenname', Loginwithmeta.ftusergivenname)
        //     .set('ftapplicationroles', Loginwithmeta.ftapplicationroles)
        //     .set('ftusertelephonenumber', Loginwithmeta.ftusertelephonenumber)
        //     .set('ftusersn', Loginwithmeta.ftusersn);
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/auth/login`,
            // `/auth/login`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Jeton>),
        );
    }

    /**
   * This command allow to be authenticated on the API with META and to get a JWT Token.
   * @param body undefined
   * @return Jeton JWT
   */
    postV1AuthLoginMeta(Loginwithmeta): __Observable<Jeton> {
        return this.postV1AuthLoginMetaResponse(Loginwithmeta).pipe(
            __map(({ body }) => body as Jeton),
        );
    }

    /**
   * This command allow to be disconnected. The JWT token is then deleted.
   */
    getV1AuthLogoutResponse(): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/auth/logout`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * This command allow to be disconnected. The JWT token is then deleted.
   */
    getV1AuthLogout(): __Observable<null> {
        return this.getV1AuthLogoutResponse().pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Get the user data
   * @param username User login
   * @return OK
   */
    getV1UserUsernameResponse(username: string): __Observable<__StrictHttpResponse<User>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/user/${username}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<User>),
        );
    }

    /**
   * Get the user data
   * @param username User login
   * @return OK
   */
    getV1UserUsername(username: string): __Observable<User> {
        return this.getV1UserUsernameResponse(username).pipe(
            __map(({ body }) => body as User),
        );
    }

    /**
   * Update the user parameters
   * @param params The `PatchV1UserUsernameParams` containing the following parameters:
   *
   * - `username`: User login
   *
   * - `body`:
   */
    patchV1UserUsernameResponse(params: PatchV1UserUsernameParams): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        const req = new HttpRequest<any>(
            'PATCH',
            `${this.rootUrl}/v1/user/${params.username}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Update the user parameters
   * @param params The `PatchV1UserUsernameParams` containing the following parameters:
   *
   * - `username`: User login
   *
   * - `body`:
   */
    patchV1UserUsername(params: PatchV1UserUsernameParams): __Observable<null> {
        return this.patchV1UserUsernameResponse(params).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * Get the existence of the user
   * @param email User email
   * @return Username of the user
   */
    getV1UserEmailExistResponse(email: string): __Observable<__StrictHttpResponse<Userexist>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/user/${email}/exist`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Userexist>),
        );
    }

    /**
   * Get the existence of the user
   * @param email User email
   * @return Username of the user
   */
    getV1UserEmailExist(email: string): __Observable<Userexist> {
        return this.getV1UserEmailExistResponse(email).pipe(
            __map(({ body }) => body as Userexist),
        );
    }

    /**
   * Ask for a new password
   * @param usernameOrEmail User login or user email
   */
    getV1UserUsernameOrEmailLostpasswordResponse(usernameOrEmail: string): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/user/${usernameOrEmail}/lostpassword`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Ask for a new password
   * @param usernameOrEmail User login or user email
   */
    getV1UserUsernameOrEmailLostpassword(usernameOrEmail: string): __Observable<null> {
        return this.getV1UserUsernameOrEmailLostpasswordResponse(usernameOrEmail).pipe(
            __map(({ body }) => body as null),
        );
    }

    /**
   * The user can change hist password if the token is valid
   * @param body undefined
   */
    postV1UserResetpasswordResponse(body: ResetPassword): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/user/resetpassword`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * The user can change hist password if the token is valid
   * @param body undefined
   */
    postV1UserResetpassword(body: ResetPassword): __Observable<null> {
        return this.postV1UserResetpasswordResponse(body).pipe(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            __map(({ body }) => body as null),
        );
    }

    /**
   * Allow a user to request an access on visual CRM
   * @param email User email address
   */
    getV1UserEmailRequestaccountResponse(email: string): __Observable<__StrictHttpResponse<null>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;

        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/user/${email}/requestaccount`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<null>),
        );
    }

    /**
   * Allow a user to request an access on visual CRM
   * @param email User email address
   */
    getV1UserEmailRequestaccount(email: string): __Observable<null> {
        return this.getV1UserEmailRequestaccountResponse(email).pipe(
            __map(({ body }) => body as null),
        );
    }

    getV1UserGdpr(): __Observable<Gdpr> {
        return this.getV1UserGdprResponse().pipe(
            __map(({ body }) => body as Gdpr),
        );
    }

    /**
   * Get the date when the user accepted the gdpr and if it is valid
   * @return OK
   */
    getV1UserGdprResponse(): __Observable<__StrictHttpResponse<Gdpr>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'GET',
            `${this.rootUrl}/v1/user/gdpr`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Gdpr>),
        );
    }

    getV1UserCgu(): __Observable<Cgu> {
        return this.http.get(`${this.rootUrl}/v1/user/cgu`);
    }

    postV1UserCgu(): __Observable<any> {
        return this.http.post(`${this.rootUrl}/v1/user/cgu`, {});
    }

    /**
   * Modify the date
   * @return The date was updated
   */
    putV1UserGdprResponse(): __Observable<__StrictHttpResponse<Gdpr>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'PUT',
            `${this.rootUrl}/v1/user/gdpr`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Gdpr>),
        );
    }

    /**
   * Modify the date
   * @return The date was updated
   */
    putV1UserGdpr(): __Observable<Gdpr> {
        return this.putV1UserGdprResponse().pipe(
            __map(({ body }) => body as Gdpr),
        );
    }

    /**
   * Insert the date
   * @return OK
   */
    postV1UserGdprResponse(): __Observable<__StrictHttpResponse<Gdpr>> {
        const __params = this.newParams();
        const __headers = new HttpHeaders();
        const __body: any = null;
        const req = new HttpRequest<any>(
            'POST',
            `${this.rootUrl}/v1/user/gdpr`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            },
        );

        return this.http.request<any>(req).pipe(
            __filter((_r) => _r instanceof HttpResponse),
            __map((_r) => _r as __StrictHttpResponse<Gdpr>),
        );
    }

    /**
   * Insert the date
   * @return OK
   */
    postV1UserGdpr(): __Observable<Gdpr> {
        return this.postV1UserGdprResponse().pipe(
            __map(({ body }) => body as Gdpr),
        );
    }

    /**
     * Met à jour la liste des news vue par l'utilisateur
     */
    postV1UserNewsViewed(newsIds: string[]): __Observable<void> {
        return this.http.post<void>(`${this.rootUrl}/v1/user/news/viewed`, { news_id: newsIds });
    }

    /**
     * Remet à jour la liste des news vue par l'utilisateur
     */
    postV1UserNewsViewedReset(): __Observable<void> {
        return this.http.post<void>(`${this.rootUrl}/v1/user/news/viewed/reset`, null);
    }
}

module UserService {
}

export default UserService;
