/* eslint-disable max-classes-per-file */
import { Login } from '../../../api/models';

/**
 * Add user profil to store
 *
 * @export
 * @class SetUser
 */
export class SetUser {
    static readonly type = '[USER] set user profil';

    constructor(public payload: any) {}
}

/**
 * Add user cgu to store
 *
 * @export
 * @class SetUser
 */
export class SetUserCGU {
    static readonly type = '[USER] set user cgu';

    constructor(public payload: any) {}
}

/**
 * Get user profil
 *
 * @export
 * @class GetUser
 */
export class GetUser {
    static readonly type = '[USER] get user profil';
}

/**
 * Login user
 *
 * @export
 * @class AuthUser
 */
export class AuthUser {
    static readonly type = '[USER] Auth user by login mdp';

    constructor(public payload: Login) {}
}

/**
 * logout user
 *
 * @export
 * @class LogOutUser
 */
export class LogOutUser {
    static readonly type = '[USER] Logout';
}

/**
 * remove user profil from store
 *
 * @export
 * @class ResetUser
 */
export class ResetUser {
    static readonly type = '[USER] reset';
}
