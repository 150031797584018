import {
    Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, TemplateRef, OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { MSurvey, MSurveysAccount } from 'app/api/models/msurvey';
import { AddMSurveyFile } from 'app/stores/actions/new-corpus/new-corpus.actions';
import { Observable, Subscription } from 'rxjs';
import MsurveyService from 'app/utils/services/msurvey.service';

@Component({
    selector: 'app-etape2upload-msurvey-account',
    templateUrl: './etape2upload-msurvey-account.component.html',
    styleUrls: ['./etape2upload-msurvey-account.component.scss'],
})
export default class Etape2uploadMsurveyAccountComponent implements OnInit, AfterViewInit, OnDestroy {
    public loginFormValues: MSurveysAccount;

    public showMsurveyError: boolean = false;

    public msurveyList: Array<MSurvey>;

    public msurveyListFiltered: Array<MSurvey>;

    public filterTable: string = '';

    public tableColumns: Array<any>;

    public tableConfig = {
        autoScroll: true,
        pagination: true,
        itemPerPage: 10,
    };

    public error;

    public warningEmpty: boolean = false;

    private selectedSurvey: MSurvey;

    modalRef: NgbModalRef;

    @ViewChild('idTpl')
        idTpl: TemplateRef<any>;

    @ViewChild('idColTpl')
        idColTpl: TemplateRef<any>;

    @ViewChild('updateTpl')
        updateTpl: TemplateRef<any>;

    @ViewChild('stateTpl')
        stateTpl: TemplateRef<any>;

    @ViewChild('typeTpl')
        typeTpl: TemplateRef<any>;

    subscription: Subscription;

    @Input() nextButtonObservable: Observable<void>;

    @Output() nextButtonActivation = new EventEmitter<boolean>();

    @Output() changeCorpusTypeToMsurveyUrl = new EventEmitter<any>();

    @ViewChild('nextStepModal')
    private nextStepModal: TemplateRef<any>;

    constructor(
        private msurvey: MsurveyService,
        private router: Router,
        private store: Store,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.subscription = this.nextButtonObservable.subscribe(() => {
            // the next parent button as been clicked
            this.openNextStepModal();
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.tableColumns = [
            {
                prop: 'id',
                title: 'translations.newcorpus.stage2.msurveyAccount.table.id',
                cellTemplate: this.idTpl,
                headerTemplate: this.idColTpl,
                sort: true,
                style: {
                    'max-width': '600px',
                    width: '600px',
                    'text-overflow': 'ellipsis',
                    overflow: 'hidden',
                },
            },
            {
                prop: 'latestModificationTime',
                title: 'translations.newcorpus.stage2.msurveyAccount.table.update',
                cellTemplate: this.updateTpl,
                sort: true,
            },
            {
                prop: 'enabled',
                title: 'translations.newcorpus.stage2.msurveyAccount.table.state',
                cellTemplate: this.stateTpl,
                sort: true,
            },
            {
                prop: 'meetingMode',
                title: 'translations.newcorpus.stage2.msurveyAccount.table.type',
                cellTemplate: this.typeTpl,
                sort: true,
            },
        ];
    }

    enableParentNextButton() {
        this.nextButtonActivation.emit(true);
    }

    disableParentNextButton() {
        this.nextButtonActivation.emit(false);
    }

    /**
   * Fired when user change search input on table
   */
    onTableSearchChange() {
    // Return all rows matched with search (insensitive research)
        this.msurveyListFiltered = this.msurveyList.filter((msurvey) => msurvey.id.toLowerCase().includes(this.filterTable.toLowerCase()));
    }

    /**
   * Fired when user click on a row table
   * @param row
   */
    onClickTableRow(rows) {
    // reset error msg if it is displayed
        this.error = null;
        // Enable or disable next button
        if (rows.length > 0) {
            const [firstRow] = rows;
            this.selectedSurvey = firstRow;
            this.enableParentNextButton();
        } else {
            this.disableParentNextButton();
        }
    }

    /**
   * Fired when MSurvey form is submitted
   * @param values : msurvey form values
   */
    onLoginMsurvey(values) {
        this.loginFormValues = values;
        // Retreive all MSurvey for this account
        this.msurvey.getSurveys(this.loginFormValues).subscribe({
            next: (data) => {
                this.msurveyList = data.surveys;
                this.msurveyListFiltered = this.msurveyList;
            },
            error: () => {
                this.showMsurveyError = true;
            },
        });
    }

    /**
   * Fired when user click on next button to go to stage 3
   */
    async selectMsurveyID() {
        this.error = null;
        try {
            // Add a msurvey file
            await this.store.dispatch(new AddMSurveyFile({
                id: this.selectedSurvey.id,
                accountInfo: this.loginFormValues,
                corpusType: 2,
            })).toPromise();
            // go to next step
            this.router.navigate(['new-project', 'date']);
        } catch (error) {
            this.error = error?.error;
            this.warningEmpty = (this.error?.error === '400066');
            if (this.error) {
                this.error.message = this.error?.error ? `translations.httpErrors.${this.error.error}` : 'translations.newcorpus.stage2.msurveyAccount.serverError.upload.generic';
            }
        }
    }

    openNextStepModal() {
        this.disableParentNextButton();
        this.modalRef = this.modalService.open(this.nextStepModal, {
            backdrop: 'static',
        });
    }

    cancelNextStepModal() {
        this.modalRef.close();
        this.enableParentNextButton();
    }

    validateNextStepModal() {
        this.disableParentNextButton();
        this.modalRef.close();
        this.selectMsurveyID();
    }
}
