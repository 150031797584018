import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-distribution-tops-settings-popup',
    templateUrl: './distribution-tops-settings-popup.component.html',
})
export default class DistributionTopExpressionSettingsPopupComponent implements OnInit {
    maxValue: number;

    initialMaxValue: number;

    @Output()
    private save: EventEmitter<number> = new EventEmitter();

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {
        this.maxValue = this.initialMaxValue;
    }

    validate() {
        this.save.emit(this.maxValue);
        this.modal.close();
    }

    get hasChanged(): boolean {
        return this.maxValue !== this.initialMaxValue;
    }
}
