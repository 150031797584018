import { Component } from '@angular/core';

@Component({
    selector: 'llm-choose-configuration-modal',
    templateUrl: './llm-choose-configuration-modal.component.html',
    styleUrl: './llm-choose-configuration-modal.component.scss',
})
export default class LlmChooseConfigurationModalComponent {

}
