// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background: white;
  flex: 1;
  overflow: auto;
}

.project-tables {
  margin-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
