import { Component, OnInit } from '@angular/core';
import slideInOutAnimation from 'app/utils/_animations/slide-in-out.animation';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SetName, SetEtapes } from '../../../stores/actions/new-corpus/new-corpus.actions';

/**
 * corpus name and desc
 *
 * @export
 * @class Etape1nameComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-etape1name',
    templateUrl: './etape1name.component.html',
    styleUrls: ['./etape1name.component.scss'],
    animations: [slideInOutAnimation],
})
export default class Etape1nameComponent implements OnInit {
    public name: string;

    public desc: string;

    public displayValidation = false;

    constructor(
        private route: Router,
        private store: Store,
    ) {}

    ngOnInit() {
        setTimeout(() => {
            this.store.dispatch(new SetEtapes(0));
        }, 500);
        this.store.dispatch(new SetName({ name: '', desc: '' }));
    }

    next() {
        this.store.dispatch(new SetName({ name: this.name, desc: this.desc }));
        this.route.navigate(['new-project', 'upload']);
    }

    onInputBlur() {
        this.name = this.name.trim();
        this.displayValidation = true;
    }
}
