import {
    Component, ElementRef, ViewChild,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import ChatService from 'app/modules/corpus/corpus-chat.service';
import { DashboardService as DashboardApi } from 'app/api/services';
import { firstValueFrom } from 'rxjs';
import MarkdownIt from 'markdown-it';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import {
    ChatMode, Conversation, ConversationStatus, Role,
} from 'app/api/models/conversation';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-chat-dinootoo-prompt',
    templateUrl: './chat-dinootoo-prompt.component.html',
    styleUrls: ['./chat-dinootoo-prompt.component.scss'],
})
export default class ChatDinootooComponent extends BaseComponent {
    public md: MarkdownIt;

    public question: string = '';

    public errorQuestion: { show: boolean; error: string } = { show: false, error: '' };

    @ViewChild('textareaRef')
    private textareaRef: ElementRef<HTMLTextAreaElement>;

    get isConversationMode() {
        return this.chatService.chatMode.getValue() === ChatMode.CONVERSATION;
    }

    // Détermine si le prompt est complètement désactivé ou non (oui si la découverte est autre que DIALOG ou que l'on est sur un dashboard initial)
    get isPromptDisabled() {
        if (this.chatService.useExistingConfiguration && !this.isConversationMode) {
            return true;
        }

        return this.chatService.chatMode.getValue() === ChatMode.DISCOVERY
            && (this.chatService.interview.getValue()?.status !== ConversationStatus.DIALOG
            || this.manageDashboardService.currentDashboard?.getValue()?.dash_initial);
    }

    // Détermine si on est en mode "Découverte IA" ou non
    get isDiscoveryMode() {
        return this.chatService.chatMode.getValue() === ChatMode.DISCOVERY;
    }

    constructor(
        public chatService: ChatService,
        private dashboardApi: DashboardApi,
        private translate: TranslateService,
        protected manageDashboardService: ManageDashboardService,
    ) {
        super();

        // Init MarkdownIt
        this.md = new MarkdownIt({
            html: false, // prevent html in markdown to prevent XSS
        });
        this.md.disable('image');
    }

    /**
     * Evènement lorsque l'on clique sur la croix du champ de saisie de la question
     * Efface la question
     */
    public onClearQuestion() {
        this.question = '';
        this.errorQuestion.show = false;
    }

    /**
     * Methode qui met à jour la hauteur du textearea en fonction du texte saisi
     */
    public onQuestionChange() {
        this.errorQuestion.show = false;
        if (this.textareaRef.nativeElement) {
            this.textareaRef.nativeElement.style.height = 'inherit';
            this.textareaRef.nativeElement.style.height = `${this.question.trim() === '' ? 0 : this.textareaRef.nativeElement.scrollHeight}px`;
            this.textareaRef.nativeElement.style.overflow = `${this.textareaRef.nativeElement.scrollHeight > 200 ? 'auto' : 'hidden'}`;
        }
    }

    /**
     * Evènement lorsque l'on écrit un caractère dans le champ Question
     */
    public onQuestionKeyUp(e: KeyboardEvent) {
        // Si on fait Enter sans le SHIFT, on soumet la question (sinon on passe à la ligne dans le textarea)
        if (e.key === 'Enter' && !e.shiftKey && !this.chatService.isLoadingAnswer) {
            e.preventDefault();
            e.stopPropagation();
            this.onSendQuestion();
        }
    }

    /**
     * Evènement lorsque l'utilisateur pose une question
     * Envoi la requête à l'API
     */
    public async onSendQuestion() {
        if (this.question.trim() === '') {
            this.errorQuestion.error = 'translations.analysisDashboard.chatDinootoo.chatbot.prompt.error.empty';
            this.errorQuestion.show = true;
        } else {
            this.chatService.sendQuestionChatDinootoo.emit(this.question);
            this.question = '';
            this.onQuestionChange();
        }
    }

    /**
     * Termine la discussion avec le LLM pour obtenir le résumé de la découverte
     */
    public async onValidDiscovery() {
        this.errorQuestion.show = false;
        this.chatService.isLoadingAnswer = true;
        this.chatService.scrollToBottom.emit();

        // On ajoute un message pour obtenir le résumé et on filtre sur les messages USER et ASSISTANT uniquement
        const interviewToSend = JSON.parse(JSON.stringify(this.chatService.interview.getValue())) as Conversation;
        interviewToSend.messages.push({
            role: Role.USER,
            content: this.translate.instant('translations.analysisDashboard.chatDinootoo.chatbot.discovery.promptToGetSummary'),
            request_summary: true,
        });
        interviewToSend.messages = interviewToSend.messages.filter((m) => [Role.USER, Role.ASSISTANT].includes(m.role));

        // On récupère le résumé et on l'ajoute à la conversation
        const summaryMessage = await this.chatService.sendMessages(this.manageDashboardService.firstDashboardService.currentDashboard.getValue().dash_id, interviewToSend);
        const newInterview = JSON.parse(JSON.stringify(this.chatService.interview.getValue()));
        newInterview.messages.push({
            role: Role.SUMMARY,
            content: this.md.render(summaryMessage.content),
        });
        this.chatService.interview.next(newInterview);
        this.chatService.showValidDiscoveryBtn = false;
        this.chatService.isLoadingAnswer = false;
        this.chatService.scrollToBottom.emit();
    }

    /**
     * Lance une découverte avec les configurations spécifiées suite à la discution
     */
    public async onLaunchDiscovery() {
        this.chatService.showLaunchDiscoveryBtn = false;
        try {
            const newDiscovery = await firstValueFrom(this.dashboardApi.postDiscovery(
                this.manageDashboardService.currentDashboard.getValue().dash_id,
                this.chatService.interviewIaConfig.getValue().config_id,
            ));
            this.chatService.launchDiscovery.emit(newDiscovery);
        } catch (e) {
            // Lance un évènement avec aucune donnée
            this.chatService.launchDiscovery.emit();
        }
        this.chatService.interview.next({ ...this.chatService.interview.getValue(), status: ConversationStatus.ENDED });
    }
}
