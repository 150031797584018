/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import {
    State, Action, StateContext,
} from '@ngxs/store';
import {
    SetLang,
    SetCurrentCorpus,
    SetVcrmSettings,
    SetGdprRedirect,
    SetDashModified,
    SetThematicsModified,
    ResetDashModified,
    SetIsChatbotBtnHover,
    SetIsDiscoveryInProgress,
} from '../../actions/settings/settings.actions';
import { SettingsModel } from '../../models/settings/settings.model';

/**
 * RGXS Store for app settings
 *
 * @export
 * @class SettingsState
 */
@Injectable()
@State<SettingsModel>({
    name: 'settings',
    defaults: {
        currentLanguage: 'en',
        vcrm: {
            title_homepage_en: '',
            title_homepage_fr: '',
            text_homepage_en: '',
            text_homepage_fr: '',
            //   alert_message_en: '',
            //    alert_message_fr: '',
        },
        dashboardsModifications: [{
            dashboardId: 0,
            isDashModified: false,
        }],
        isChatbotBtnHover: true,
        isDiscoveryInProgress: false,
    },
})
export default class SettingsState {
    @Action(SetLang)
    setName(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetLang,
    ) {
        const state = getState();
        state.currentLanguage = payload;
        setState({
            ...state,
        });
    }

    @Action(SetCurrentCorpus)
    SetCurrentCorpus(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetCurrentCorpus,
    ) {
        const state = getState();
        state.currentCorpus = payload;
        setState({
            ...state,
        });
    }

    @Action(SetVcrmSettings)
    SetVcrmSettings(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetVcrmSettings,
    ) {
        const state = getState();
        state.vcrm = payload;
        setState({
            ...state,
        });
    }

    @Action(SetGdprRedirect)
    SetGdprRedirect(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetGdprRedirect,
    ) {
        const state = getState();
        state.gdprRedirect = payload;
        setState({
            ...state,
        });
    }

    @Action(ResetDashModified)
    ResetDashModified(
        { getState, setState }: StateContext<SettingsModel>,
    ) {
        const state = getState();
        state.dashboardsModifications = [];

        setState({
            ...state,
        });
    }

    @Action(SetDashModified)
    SetDashModified(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetDashModified,
    ) {
        const state = getState();
        if (!state.dashboardsModifications) {
            state.dashboardsModifications = [];
        }

        const dashboardState = state.dashboardsModifications.find((dashboard) => dashboard.dashboardId === payload.dashboardId);
        if (!dashboardState) {
            state.dashboardsModifications.push(payload);
        } else {
            dashboardState.isDashModified = payload.isDashModified;
        }

        setState({
            ...state,
        });
    }

    @Action(SetThematicsModified)
    SetThematicsModified(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetThematicsModified,
    ) {
        const state = getState();
        state.thematicsModified = payload;
        setState({
            ...state,
        });
    }

    @Action(SetIsChatbotBtnHover)
    SetIsChatbotBtnHover(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetIsChatbotBtnHover,
    ) {
        const state = getState();
        state.isChatbotBtnHover = payload;
        setState({
            ...state,
        });
    }

    @Action(SetIsDiscoveryInProgress)
    SetIsDiscoveryInProgress(
        { getState, setState }: StateContext<SettingsModel>,
        { payload }: SetIsDiscoveryInProgress,
    ) {
        const state = getState();
        state.isDiscoveryInProgress = payload;
        setState({
            ...state,
        });
    }
}
