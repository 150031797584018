// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news .news-content {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: box;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.news .news-date {
  white-space: nowrap;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
