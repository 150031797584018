/* eslint-disable class-methods-use-this */
import { Component, HostBinding } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Inputnamevalues, Inputnamevalues2levels } from 'app/api/models';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';
import FiltersClassificationsAutoPopupComplexComponent from './component/filters-classifications-auto-popup-complex/filters-classifications-auto-popup-complex.component';
import FiltersClassificationsAutoPopupHierarchiqueComponent from './component/filters-classifications-auto-popup-hierarchique/filters-classifications-auto-popup-hierarchique.component';
import FiltersClassificationsAutoPopupModelInfoComponent from './component/filters-classifications-auto-popup-model-info/filters-classifications-auto-popup-model-info.component';
import FiltersClassificationsAutoPopupSimpleComponent from './component/filters-classifications-auto-popup-simple/filters-classifications-auto-popup-simple.component';

@Component({
    selector: 'app-filters-classifications-auto',
    templateUrl: './filters-classifications-auto.component.html',
    styleUrls: ['./filters-classifications-auto.component.scss'],
})
export default class FiltersClassificationsAutoComponent
    extends BaseVerticalFilterComponent {
    previousModel: string = '';

    private modelTypesArray: Array<string> = ['ICSIBOOSTH', 'RULESH', 'EXTERNALH'];

    constructor(
        private dashboardService: DashboardService,
        private modalService: NgbModal,
        private corpusService: CorpusService,
        private translateService: TranslateService,
    ) {
        super(dashboardService);
    }

    @HostBinding('style.display')
    get display() {
        return this.noModel ? 'none' : '';
    }

    get count() {
        if (this.level === 1) {
            return this.classes.length;
        }
        return this.classes.reduce((sum, c) => sum + (c.subclass ? c.subclass.reduce((sum2, sc) => sum2 + sc.values.length, 0) : 1), 0);
    }

    get verbatimDBName(): string {
        return this.searchParams ? this.searchParams.verbatim_dbname : '';
    }

    get classes() {
        if (!this.searchParams) {
            return [];
        }
        return this.searchParams.filters_class;
    }

    get noModel(): boolean {
        return !this.corpusService.currentCorpus.getValue().at_least_one_classification
        || this.corpusService.currentCorpus.getValue().models.length === 0
        || !this.corpusService.currentCorpus.getValue().models[this.verbatimDBName];
    }

    get allmodels() {
        return this.corpusService.currentCorpus.getValue().models;
    }

    getSelectedModels() {
        // TODO: ici il faudra itérer sur les modèles du verbatim quand le multi modèle sera implémenté
        return this.classes.length > 0 ? [this.allmodels[this.verbatimDBName].model] : [];
    }

    getClassesForModel() {
        // TODO: ici le paramètre model n'est pas encore utilisé car le multi model n'est pas encore implémenté
        return this.classes;
    }

    get level():number {
        return this.allmodels[this.verbatimDBName] ? this.allmodels[this.verbatimDBName].cat_nb_levels : 1;
    }

    get noTone():boolean {
        return this.modelTypesArray.includes(this.corpusService.currentCorpus.getValue().models[this.verbatimDBName].mode_type);
    }

    getClassDisplayName(className:string): string {
        if (className === '__hors_classe__') {
            return this.translateService.instant('translations.analysisDashboard.class.__hors_classe__');
        }

        const field = this.allmodels[this.verbatimDBName].cat_fields.find((f) => f.inputName === className);
        return field ? field.displayName : className;
    }

    getMotifDisplayName(className:string, motifName:string, value:string):string {
        const field = this.allmodels[this.verbatimDBName].cat_fields.find((f) => f.inputName === className);
        if (!field) {
            return motifName;
        }
        const subClass = field.subclass.find((sc) => sc.inputName === motifName);
        return `${this.getClassDisplayName(className)} ${subClass ? subClass.displayName : motifName}${this.getTonaliteSuffix(value)}`;
    }

    getMotifNoToneDisplayName(className:string, motifName:string):string {
        const field = this.allmodels[this.verbatimDBName].cat_fields.find((f) => f.inputName === className);
        if (!field) {
            return motifName;
        }
        const subClass = field.subclass.find((sc) => sc.inputName === motifName);
        return `${this.getClassDisplayName(className)} ${subClass ? subClass.displayName : motifName}`;
    }

    getTonaliteSuffix(value:string) {
        if (value === 'pos') {
            return ' (+)';
        } if (value === 'neg') {
            return ' (-)';
        }
        return '';
    }

    getTonaliteColorClass(value:string) {
        if (value === 'pos') {
            return 'sentiment-positive';
        } if (value === 'neg') {
            return 'sentiment-negative';
        }
        return 'sentiment-neutral';
    }

    openSettingsModal() {
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'ajouter une classe',
        });
        if (this.level === 1) {
            const modal = this.modalService.open(FiltersClassificationsAutoPopupSimpleComponent);
            modal.componentInstance.dashboardService = this.dashboardService;
            modal.componentInstance.onSave.subscribe(() => {
                this.collapsed = false;
            });
        } else if (this.noTone) {
            const modal = this.modalService.open(FiltersClassificationsAutoPopupHierarchiqueComponent);
            modal.componentInstance.dashboardService = this.dashboardService;
            modal.componentInstance.onSave.subscribe(() => {
                this.collapsed = false;
            });
        } else {
            const modal = this.modalService.open(FiltersClassificationsAutoPopupComplexComponent);
            modal.componentInstance.dashboardService = this.dashboardService;
            modal.componentInstance.onSave.subscribe(() => {
                this.collapsed = false;
            });
        }
    }

    updateFilterClasses(filterClasses) {
        this.dashboardService.updateFilterClasses(filterClasses);
        this.collapsed = false;
    }

    deleteAllByModelName() {
        // TODO: implémenter multi modèle ici
        this.updateFilterClasses([]);
    }

    showInfo() {
        // TODO: implémenter multi modèle ici
        const modal = this.modalService.open(FiltersClassificationsAutoPopupModelInfoComponent, { scrollable: true });
        modal.componentInstance.model = this.allmodels[this.verbatimDBName];
    }

    deleteByClass(className: string) {
        const classes = this.classes.filter((c) => c.inputName !== className);
        this.updateFilterClasses(classes);
    }

    deleteMotif(className, motifName, value) {
        const currentClass :Inputnamevalues2levels = this.classes.find((c) => c.inputName === className);
        if (currentClass) {
            const subClass : Inputnamevalues = currentClass.subclass.find((sc) => sc.inputName === motifName);
            if (subClass) {
                subClass.values = subClass.values.filter((v) => v !== value);
            }

            currentClass.subclass = currentClass.subclass.filter((sc) => sc.values.length > 0);
        }
        const classes = this.classes.filter((c) => c.subclass.length > 0);
        this.updateFilterClasses(classes);
    }

    deleteMotifNoTone(className, motifName) {
        const currentClass :Inputnamevalues2levels = this.classes.find((c) => c.inputName === className);
        if (currentClass) {
            const subClass : Inputnamevalues = currentClass.subclass.find((sc) => sc.inputName === motifName);
            if (subClass) {
                subClass.values = subClass.values.filter((v) => v !== '');
            }

            currentClass.subclass = currentClass.subclass.filter((sc) => sc.values.length > 0);
        }
        const classes = this.classes.filter((c) => c.subclass.length > 0);
        this.updateFilterClasses(classes);
    }
}
