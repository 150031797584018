import {
    Component, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Yaxis } from 'app/api/models/histoparams';
import { MergedDistributionData } from 'app/api/models/paramsformerge';
import { BaseComponent } from 'app/base.component';
import ExportType from 'app/modules/CommonExportTypeEnum';
import ModalGraphExportComponent from 'app/modules/corpus/components/modal-graph-export/modal-graph-export.component';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import { BarChartParamsConfig, GraphParamsConfig } from 'app/api/models/dashboard-config';
import ChartTooltipsService from 'app/utils/services/chart-tooltips.service';
import CorpusService from 'app/utils/services/corpus.service';
import { addDashboardNumberToHorizontalBarChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-horizontal-bar-chart';
import { addValuesToHorizontalBarCharts } from 'app/utils/chartjs-plugins/add-values-to-horizontal-bar-charts';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { LegendItem } from '../../../graphs-generic-components/graph-checkbox-legend/graph-checkbox-legend.component';
import HorizontalBarChartSettingsPopupComponent from '../../../graphs-generic-components/horizontal-bar-chart-settings-popup/horizontal-bar-chart-settings-popup.component';
import { GraphConf, settingsPopupAnalytics } from '../../distribution-graphs.component';

@Component({
    selector: 'app-distribution-bar-chart',
    templateUrl: './distribution-bar-chart.component.html',
    styleUrls: [],
})
export default class DistributionBarChartComponent extends BaseComponent {
    constructor(
        private graphService: GraphService,
        private modalService: NgbModal,
        private chartTooltips: ChartTooltipsService,
        private translateService: TranslateService,
        private corpusService: CorpusService,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    plugins = [addValuesToHorizontalBarCharts, addDashboardNumberToHorizontalBarChart];

    /** toutes les données du graph */
    allChartData = {
        labels: [],
        datasets: [{
            data: [], backgroundColor: [], hoverBackgroundColor: [], stack: '',
        }],
    };

    /** les données du graph filtrée par barStates et affichées dans le dashboard */
    filteredChartData = {
        labels: [],
        datasets: [{
            data: [], backgroundColor: [], hoverBackgroundColor: [], stack: '',
        }],
    };

    /** un tableau de booléen qui indique quelles données du back on affiche dans le graphique. Cela correspond à l'état des boulettes de la légende */
    barStates = [];

    @Input()
        conf: GraphConf;

    @Input()
        showDashNumber: boolean = true;

    @Output()
        configChanged: EventEmitter<GraphParamsConfig> = new EventEmitter();

    @ViewChild('chart')
        chart: BaseChartDirective;

    @Input()
        dashboardService: DashboardService;

    bsModalRef: NgbModalRef;

    legendItems: Array<LegendItem> = [];

    options = {};

    tonalites = [
        { key: 'NEG', color: '#CD3C14' },
        { key: 'MIT', color: '#FFCC00' },
        { key: 'NEU', color: '#999999' },
        { key: 'POS', color: '#32C832' },
    ];

    get currentConfig() {
        return this.dashboardService
            ? this.dashboardService.currentConfig
            : this.manageDashboardService.currentDashboardComparisonConfig;
    }

    get params() {
        return this.currentConfig.getValue()[this.conf.type];
    }

    @Input()
    set data(data: MergedDistributionData) {
        if (this.hasTone()) {
            this.options = this.getGraphOptionsWithTones(data);
        } else {
            this.options = this.getGraphOptionsWithoutTones();
        }

        if (!data || !this.params) {
            return;
        }

        this.allChartData = { labels: [], datasets: [] };
        this.filteredChartData = { labels: [], datasets: [] };

        this.barStates = [];

        if (data.histogram.values) {
            if (this.hasTone()) {
                this.setDataWithTone(data);
            } else {
                this.setDataWithoutTone(data);
            }
        }
        this.filterChartData();
    }

    hasSentiment() {
        const sentimentField = this.corpusService.currentCorpus.getValue().corp_fields_not_in_file.find((field) => field.inputName === 'sentiment') || null;
        return sentimentField !== null;
    }

    hasTone() {
        return this.hasSentiment() && this.params.histo.tone;
    }

    getColor(displayName): string {
        return this.manageDashboardService.getDataColor(this.conf.type, displayName);
    }

    getDataType() {
        return this.translateService.instant(`translations.analysisDashboard.charts.settings_top_type_${this.conf.type}`).toLowerCase();
    }

    getGraphOptionsWithTones(data) {
        const thisInternal = this;

        const options = {
            indexAxis: 'y',
            layout: {
                padding: {
                    right: 50 + (thisInternal.showDashNumber ? 30 : 0),
                },
            },
            parsing: {
                xAxisKey: 'value',
                yAxisKey: 'id',
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        color: '#DDD',
                    },
                    ticks: {
                        percent: false,
                        min: 0,
                        color: '#000',
                    },
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#000',
                        callback(val) {
                            return thisInternal.graphService.labelEllipsisCallback(this.getLabelForValue(val));
                        },
                    },
                },
            },
            interaction: {
                mode: 'y',
            },

            legend: {
                display: false,
            },
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1,
            animation: false,
            plugins: {
                addValuesToHorizontalBarCharts: { tones: true },
                addDashboardNumberToHorizontalBarChart: thisInternal.showDashNumber ? { tones: true } : false,
                labels: true,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external(context) {
                        const tooltipModel = context.tooltip;
                        const getRealValue = (index) => {
                            const datapoint = tooltipModel.dataPoints[index];

                            if (thisInternal.params?.histo.yaxis !== Yaxis.percent) {
                                return datapoint.value === 'NaN' ? 0 : datapoint.formattedValue;
                            }

                            const title = datapoint.label;

                            const value = data.histogram.values.find((v) => thisInternal.conf.display_name(v.name) === title);

                            return value ? `${value.merged_values[datapoint.dataset.stack - 1].sentiment[thisInternal.tonalites[index].key] * 1}%` : '';
                        };

                        const getLabel = (index) => {
                            const label = thisInternal.translateService.instant(`translations.analysisDashboard.cloud.${thisInternal.tonalites[index].key.toLowerCase()}`);
                            return `${label.charAt(0).toUpperCase() + label.slice(1) + (thisInternal.translateService.currentLang === 'fr' ? ' ' : '')}:`;
                        };

                        const getTotal = (dashboardNumber, dataName) => {
                            const index = thisInternal.filteredChartData.labels.indexOf(dataName);
                            return thisInternal.filteredChartData.datasets.filter((dataset) => dataset.stack === dashboardNumber)[3].data[index].total;
                        };

                        const getEncodedString = (string) => string.replace(/[\u00A0-\u9999<>&]/gim, (i) => `&#${i.charCodeAt(0)};`);

                        let innerHtml = '';
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title;
                            if (titleLines.length > 0) {
                                innerHtml += '<div class="tooltip-title">';
                                const dashboardNumber = tooltipModel.dataPoints[0].dataset.stack;
                                titleLines.forEach((title) => {
                                    innerHtml += getEncodedString(thisInternal.translateService.instant(`translations.analysisDashboard.charts.tooltip_horizontal_chart_title_${thisInternal.params.histo.yaxis}`, { count: getTotal(dashboardNumber, title) }));
                                    innerHtml += '<br>';
                                    innerHtml += getEncodedString(thisInternal.translateService.instant('translations.analysisDashboard.charts.tooltip_horizontal_chart_title_suffix', { dataName: title }));
                                });
                                innerHtml += '</div>';
                            }
                            tooltipModel.body.forEach((body, i) => {
                                innerHtml += `<div class="text-truncate"><span class="tooltip-legend" style="background:${tooltipModel.labelColors[i].backgroundColor}"></span>${`${getLabel(i)} ${getRealValue(i)}`
                                }</div>`;
                            });
                        }
                        // eslint-disable-next-line no-underscore-dangle
                        thisInternal.chartTooltips.draw(tooltipModel, context.chart, innerHtml);
                    },
                },
            },
        };

        if (thisInternal.params.histo.yaxis === Yaxis.percent) {
            options.scales.x.ticks = {
                ...options.scales.x.ticks,
                percent: true,
                ...{
                    callback(value) {
                        return value + (thisInternal.params.histo.yaxis === Yaxis.percent ? '%' : '');
                    },
                },
            };
        }

        return options;
    }

    getGraphOptionsWithoutTones() {
        const yaxis = this.params?.histo.yaxis;
        const thisInternal = this;
        const options = {
            indexAxis: 'y',
            parsing: {
                xAxisKey: 'value',
                yAxisKey: 'id',
            },

            scales: {
                x: {
                    grid: {
                        color: '#DDD',
                    },
                    distribution: 'series',
                    ticks: {
                        percent: false,
                        min: 0,
                        source: 'labels',
                        color: '#000',
                    },
                },
                y: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#000',
                        callback(val) {
                            return thisInternal.graphService.labelEllipsisCallback(this.getLabelForValue(val));
                        },
                    },
                },
            },
            legend: {
                display: false,
            },
            layout: {
                padding: {
                    right: 50 + (thisInternal.showDashNumber ? 30 : 0),
                },
            },
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 1,
            animation: false,
            plugins: {
                labels: true,
                addValuesToHorizontalBarCharts: { tones: false },
                addDashboardNumberToHorizontalBarChart: this.showDashNumber ? { tones: false } : false,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.graphService.customTooltips(yaxis === Yaxis.percent ? '%' : ''),

                },
            },
        };

        if (yaxis === Yaxis.percent) {
            options.scales.x.ticks = {
                ...options.scales.x.ticks,
                percent: true,
                ...{
                    callback(value) {
                        return value + (yaxis === Yaxis.percent ? '%' : '');
                    },
                },
            };
        }

        return options;
    }

    setDataWithoutTone(data) {
        for (let i = 0; i < this.manageDashboardService.dashboardServices.length; i += 1) {
            this.allChartData.datasets.push({
                data: [], backgroundColor: [], hoverBackgroundColor: [], stack: '',
            });
        }
        data.histogram.values.forEach((value) => {
            const displayName = this.conf.display_name(value.name);

            const color = this.getColor(displayName);

            value.merged_values.forEach((mergedValue, dashboardIndex) => {
                if (this.allChartData.datasets[dashboardIndex]) {
                    if (!this.allChartData.labels.includes(displayName)) {
                        this.allChartData.labels.push(displayName);
                    }
                    this.allChartData.datasets[dashboardIndex].backgroundColor.push(color);
                    this.allChartData.datasets[dashboardIndex].hoverBackgroundColor.push(color);
                    this.allChartData.datasets[dashboardIndex].stack = `${dashboardIndex + 1}`;
                    this.allChartData.datasets[dashboardIndex].data.push({
                        id: displayName,
                        value: mergedValue ? mergedValue.total : 0,
                    });
                }
            });

            this.addLegendItemIfNeeded(value, displayName);
        });

        this.allChartData.datasets = this.allChartData.datasets.filter((dataset) => dataset.stack !== '');

        this.cleanLegendItems(data);
    }

    private addLegendItemIfNeeded(value: any, displayName: string) {
        const selectedBars = this.params.legend_histo;
        if (!this.legendItems.find((item) => item.inputName === value.name)) {
            this.legendItems.push({
                color: this.getColor(displayName),
                inputName: value.name,
                displayName,
                selected: !selectedBars.includes(value.name),
            });
        }
    }

    setDataWithTone(data: MergedDistributionData) {
        for (let i = 0; i < this.manageDashboardService.dashboardServices.length; i += 1) {
            this.tonalites.forEach(() => {
                this.allChartData.datasets.push({
                    data: [], backgroundColor: [], hoverBackgroundColor: [], stack: '',
                });
            });
        }

        data.histogram.values.forEach((value) => {
            const displayName = this.conf.display_name(value.name);

            value.merged_values.forEach((mergedValue, dashboardIndex) => {
                const count = mergedValue?.total || 0;
                if (!this.allChartData.labels.includes(displayName)) {
                    this.allChartData.labels.push(displayName);
                }

                if (mergedValue?.sentiment) {
                    this.tonalites.forEach((tonalite, index) => {
                        let tonaliteValue = mergedValue?.sentiment[tonalite.key] || 0;
                        if (this.params.histo.yaxis === Yaxis.percent) {
                            tonaliteValue = (tonaliteValue * count) / 100;
                        }

                        const dataSetIndex = this.tonalites.length * dashboardIndex + index;
                        if (this.allChartData.datasets[dataSetIndex]) {
                            this.allChartData.datasets[dataSetIndex].backgroundColor.push(tonalite.color);
                            this.allChartData.datasets[dataSetIndex].stack = `${dashboardIndex + 1}`;
                            this.allChartData.datasets[dataSetIndex].hoverBackgroundColor.push(tonalite.color);
                            this.allChartData.datasets[dataSetIndex].data.push({
                                id: displayName,
                                value: tonaliteValue,
                                raw: mergedValue?.sentiment[tonalite.key] || 0,
                                total: count,
                            });
                        }
                    });
                }

                this.addLegendItemIfNeeded(value, displayName);
            });
        });

        this.allChartData.datasets = this.allChartData.datasets.filter((dataset) => dataset.stack !== '');

        this.cleanLegendItems(data);
    }

    private cleanLegendItems(data: MergedDistributionData) {
        this.legendItems = this.legendItems.filter((item) => data.histogram.values.find((value) => value.name === item.inputName));

        this.legendItems = this.allChartData.labels.map((label) => this.legendItems.find((legend) => legend.displayName === label));

        const selectedBars = this.params.legend_histo;
        this.legendItems.forEach((item) => {
            this.barStates.push(!selectedBars.includes(item.inputName));
        });
    }

    filterChartData() {
        this.filteredChartData.labels = this.filterArray(this.allChartData.labels);
        this.filteredChartData.datasets = this.allChartData.datasets.map((dataset) => ({
            data: this.filterArray(dataset.data),
            stack: dataset.stack,
            backgroundColor: this.filterArray(dataset.backgroundColor),
            hoverBackgroundColor: this.filterArray(dataset.hoverBackgroundColor),
        }));
    }

    filterArray(array) {
        return array.filter((value, index) => this.barStates[index]);
    }

    toggle(index) {
        this.toggleLegend(index);
        this.filterChartData();
        this.chart.chart.data = { ...this.filteredChartData };
        this.chart.chart.update();
    }

    toggleLegend(index) {
        if (!this.legendItems[index]) {
            return;
        }

        this.barStates[index] = !this.barStates[index];

        const { inputName } = this.legendItems[index];

        if (this.barStates[index]) {
            this.params.legend_histo = this.params.legend_histo.filter((legend) => legend !== inputName);
        } else {
            this.params.legend_histo.push(inputName);
        }

        this.params.legend_histo.sort();

        if (this.dashboardService) {
            this.dashboardService.setDistributionConfig(this.conf.type, this.params);
        } else {
            this.manageDashboardService.setComparisonDashbardConfig(this.conf.type, this.params);
        }
    }

    /**
     * Evenement lors du clic sur le bouton 'Exporter' du graphique en bar horizontale.
     * Ouvre la popin d'export et selon la sélection de l'utilisateur, exporte au bon format (PNG ou CSV)
     */
    onExportBarChart() {
        this.bsModalRef = this.modalService.open(ModalGraphExportComponent, {});
        this.bsModalRef.componentInstance.titleToTranslate = `translations.analysisDashboard.charts.exportGraphModal.title.${this.conf.type}`;
        this.bsModalRef.componentInstance.type = this.conf.type;
        this.bsModalRef.componentInstance.export.subscribe((type: ExportType) => {
            gtmClick({
                track_category: settingsPopupAnalytics[this.conf.type].track_category,
                track_name: `vue histogramme ${settingsPopupAnalytics[this.conf.type].partial_track_name} export`,
                track_cible: type,
            });

            const graphTitle = this.translateService.instant(this.conf.distribution_title, { model: (this.manageDashboardService.getCurrentModel()?.model ?? '') });

            if (type === ExportType.PNG) {
                this.graphService.exportChartToPng(this.chart, graphTitle);
            } else if (type === ExportType.CSV) {
                if (this.hasTone()) {
                    this.graphService.exportDataToCsv(this.dataTableArray, ['dataLabel', 'neg', 'mit', 'neu', 'pos', 'dataCountVal'], graphTitle);
                } else {
                    this.graphService.exportDataToCsv(this.dataTableArray, ['dataLabel', 'dataCountVal'], graphTitle);
                }
            }
        });
    }

    get dataTableArray() {
        return this.filteredChartData.labels.map((value, index) => {
            if (this.hasTone()) {
                return {
                    dataLabel: value,
                    neg: this.filteredChartData.datasets[0].data[index].raw,
                    mit: this.filteredChartData.datasets[1].data[index].raw,
                    neu: this.filteredChartData.datasets[2].data[index].raw,
                    pos: this.filteredChartData.datasets[3].data[index].raw,
                    dataCountVal: this.filteredChartData.datasets[3].data[index].total,
                    dataColor: this.filteredChartData.datasets[0].data[index].backgroundColor,
                };
            }
            return {
                dataLabel: value,
                dataCountVal: this.filteredChartData.datasets[0].data[index].value,
                dataColor: this.filteredChartData.datasets[0].data[index].backgroundColor,
            };
        });
    }

    openSettings() {
        const modal = this.modalService.open(HorizontalBarChartSettingsPopupComponent);
        modal.componentInstance.initialParams = this.params.histo;
        modal.componentInstance.graphService = this.graphService;
        modal.componentInstance.corpusService = this.corpusService;
        modal.componentInstance.conf = this.conf;
        modal.componentInstance.gtmTrackCategory = settingsPopupAnalytics[this.conf.type].track_category;
        modal.componentInstance.partialGtmTrackName = settingsPopupAnalytics[this.conf.type].partial_track_name;
        modal.componentInstance.save.subscribe((params: BarChartParamsConfig) => {
            this.params.histo = params;
            this.configChanged.emit(this.params);
        });
    }
}
