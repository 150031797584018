import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-graph-radio-legend',
    templateUrl: './graph-radio-legend.component.html',
    styleUrls: ['./graph-radio-legend.component.scss'],
})
export default class GraphRadioLegendComponent {
    constructor(private translateService: TranslateService) { }

    private static next_unique_id: number = 0;

    @Output()
        change: EventEmitter<number> = new EventEmitter();

    @Input()
        items = [];

    @Input()
        colors: boolean = true;

    @Input()
        selectedItem: string;

    uniqueId: number = GraphRadioLegendComponent.next_unique_id++;

    toggle(index: number) {
        this.change.emit(index);
    }
}

export interface LegendItem {
    color: string;
    displayName: string;
    selected: boolean;
    inputName: string;
    disabled?: boolean;
}
