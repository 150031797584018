import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import ModalComponent from 'app/shared/components/modal/modal.component';
import FooterService from 'app/shared/components/footer/footer.service';

/**
 * Check if user can quit new corpus proccess
 *
 * @export
 * @class QuitNewCorpusService
 */
@Injectable()
export default class QuitNewCorpusService {
    private modalRef: NgbModalRef;

    /**
   *Creates an instance of QuitNewCorpusService.
   * @param {Store} store
   * @memberof QuitNewCorpusService
   */
    constructor(
        private store: Store,
        private modalService: NgbModal,
        private footerService: FooterService,
    ) {}

    /**
   * Check state is finish
   *
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof QuitNewCorpusService
   */
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.store.snapshot().newcorpus.finish) {
            return true;
        }

        this.modalRef = this.modalService.open(ModalComponent, {
            backdrop: 'static',
            keyboard: false,
        });

        this.modalRef.componentInstance.btnHeaderClose = false;
        this.modalRef.componentInstance.titleToTranslate = 'translations.newcorpus.confirm.title';
        this.modalRef.componentInstance.contentToTranslate = 'translations.newcorpus.confirm.content';
        this.modalRef.componentInstance.btnCloseToTranslate = 'translations.newcorpus.confirm.closeBtn';
        this.modalRef.componentInstance.btnValidToTranslate = 'translations.newcorpus.confirm.validBtn';
        this.modalRef.componentInstance.btnValidVariant = 'danger';

        this.modalRef.componentInstance.status.subscribe((s) => {
            if (s) {
                this.footerService.visible(true);
            }
        });

        return this.modalRef.componentInstance.status;
    }
}
