// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.capsule {
  margin: 0 10px 0 0;
  padding: 5px 5px 5px 8px;
  border-radius: 12px;
  display: inline-flex;
  border: 1px solid transparent;
}
.capsule:hover {
  border: 1px solid #ccc;
}
.capsule:hover i {
  background-color: black;
  color: white;
}

.container {
  display: inline-block;
}

.icon-delete {
  background-color: transparent;
  border-radius: 8px;
  padding: 2px;
  font-size: 12px;
  font-weight: normal;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
