import {
    trigger, animate, transition, style,
} from '@angular/animations';

/**
 * Slide in out animation for route in new corpus
 */
const slideInOutAnimation = trigger('slideInOutAnimation', [
    transition(':enter', [
        style({
            position: 'absolute',
            left: 0,
            right: 0,
            transform: 'translateX(-100%)',
            opacity: 0,
        }),

        animate(
            '.5s ease-in-out',
            style({
                transform: 'translateX(0)',
                opacity: 1,
            }),
        ),

        style({
            position: 'relative',
        }),
    ]),

    transition(':leave', [
        style({
            position: 'absolute',
            left: 0,
            right: 0,
        }),
        animate(
            '.5s ease-in-out',
            style({
                transform: 'translateX(-100%)',
                opacity: 0,
            }),
        ),
    ]),
]);

export default slideInOutAnimation;
