import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'app/base.component';
import { GraphParamsConfig, KeywordExpression } from 'app/api/models/dashboard-config';
import { DashboardService as DashboardApi } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { MergedMatrixTopData } from 'app/api/models/paramsformerge';
import * as _ from 'lodash';
import DistributionTopKeywordExpressionSettingsPopupComponent from './distribution-tops-settings-popup/distribution-tops-settings-popup.component';
import { GraphConf } from '../../../distribution-graphs.component';

const analyticsDistributionTop = {
    class: {
        track_category: 'classification automatique',
        partial_track_name: 'tops mots-clés et expressions classification',
    },
    thematics: {
        track_category: 'thématiques',
        partial_track_name: 'tops mots-clés et expressions thématique',
    },
};

@Component({
    selector: 'app-distribution-tops',
    templateUrl: './distribution-tops.component.html',
    styleUrls: ['./distribution-tops.component.scss'],
})
export default class DistributionTopsComponent extends BaseComponent implements OnChanges {
    @Input()
        dashboardService: DashboardService;

    @Input()
        conf: GraphConf;

    @Input()
        params: GraphParamsConfig;

    @Input()
        data: MergedMatrixTopData[];

    @Output()
        configChanged: EventEmitter<GraphParamsConfig> = new EventEmitter();

    // Retourne les données en fonction de la config
    get displayData() {
        return this.data.map((data) => ({
            label: data.label,
            data: ((this.params.top_keyword_expression.top === KeywordExpression.KEYWORD) ? data.keywords : data.expressions).merged_values
                .map((values) => (values ?? []).slice(0, this.params.top_keyword_expression.max_value)),
        }));
    }

    get dashboardId(): string {
        return this.manageDashboardService.getDashboardUniqueId(this.dashboardService);
    }

    constructor(
        private modalService: NgbModal,
        private dashboardApi: DashboardApi,
        private manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params && !changes.params.currentValue.top_keyword_expression) {
            this.params.top_keyword_expression = { top: KeywordExpression.KEYWORD, max_value: 4 };
        }
    }

    /**
     * Méthode appelée lors du changement sur le bouton radio Mots-clés / Expressions
     */
    onChangeTops() {
        gtmClick({
            track_category: analyticsDistributionTop[this.conf.type].track_category,
            track_name: `${analyticsDistributionTop[this.conf.type].partial_track_name} afficher`,
            track_cible: this.params.top_keyword_expression.top === KeywordExpression.KEYWORD ? 'mots-clés' : 'expressions',
        });
        this.configChanged.emit(this.params);
    }

    /**
     * Ouvre la modal de configuration de la section Top mots-clés / expressions
     */
    openSettings() {
        gtmClick({
            track_category: analyticsDistributionTop[this.conf.type].track_category,
            track_name: `${analyticsDistributionTop[this.conf.type].partial_track_name} nbre max de termes`,
        });
        const modal = this.modalService.open(DistributionTopKeywordExpressionSettingsPopupComponent);
        modal.componentInstance.initialMaxValue = this.params.top_keyword_expression.max_value;
        modal.componentInstance.save.subscribe((newValue: number) => {
            this.params.top_keyword_expression.max_value = newValue;
            this.configChanged.emit(this.params);
        });
    }

    /**
     * Lance le téléchargement du fichier CSV
     */
    exportToCSV() {
        gtmClick({
            track_category: analyticsDistributionTop[this.conf.type].track_category,
            track_name: `${analyticsDistributionTop[this.conf.type].partial_track_name} export csv`,
        });

        const firstDashboard = this.manageDashboardService.dashboardServices[0].currentDashboard.getValue();

        if (this.dashboardService) {
            this.dashboardApi.downloadMergedDistributionTops(
                firstDashboard.dash_id,
                this.conf.type,
                { searchparams: [_.cloneDeep(this.dashboardService.lastAppliedFilters.getValue())], nbkeywords: this.params.top_keyword_expression.max_value },
            );
        } else {
            this.dashboardApi.downloadMergedDistributionTops(
                firstDashboard.dash_id,
                this.conf.type,
                {
                    searchparams: this.manageDashboardService.dashboardServices.map((service) => _.cloneDeep(service.lastAppliedFilters.getValue())),
                    nbkeywords: this.params.top_keyword_expression.max_value,
                },
            );
        }
    }
}
