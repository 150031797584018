import {
    Component, Input, OnInit, AfterViewInit,
    ViewChild,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ConversationAddTag, VerbatimConversation, VerbatimConversationItem, VerbatimTags,
} from 'app/api/models/verbatim-conversation';
import { BaseComponent } from 'app/base.component';
import { getColor } from 'app/utils/models/colors.models';
import { firstValueFrom } from 'rxjs';
import { CorpusService as CorpusApi } from 'app/api/services';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import ModalComponent from 'app/shared/components/modal/modal.component';

enum ConversationTabs {
    CHAPTERS = 'chapters',
    TAGS = 'tags',
    SPEAKERS = 'speakers',
}

enum SpeakerDirection {
    LEFT = 'left',
    RIGHT = 'right',
    NOTHING = '',
}

enum ErrorTag {
    ALREADY_EXIST = 'alreadyExist',
    NO_VERBATIM_SELECTED = 'noVerbatim',
}

interface VerbatimConversationSpeakers {
    name: string;
    direction: SpeakerDirection;
    color: string;
}

@Component({
    selector: 'conversation-modal',
    templateUrl: './conversation-modal.component.html',
    styleUrl: './conversation-modal.component.scss',
})
export default class ConversationModalComponent extends BaseComponent implements OnInit, AfterViewInit {
    @Input()
        corpusId: number;

    @Input()
        verbatimDbName: string;

    @Input()
        selectedConvId: string;

    @Input()
        selectedTurnId: string;

    @Input()
        verbatimConversation: VerbatimConversation;

    @Input()
        verbatimTags: VerbatimTags;

    @ViewChild('conversationElement')
        conversationElement: ElementRef<HTMLDivElement>;

    @ViewChild('scrollContainer') scrollContainer: ElementRef;

    protected conversationTabsEnum = ConversationTabs;

    protected selectedTabs = ConversationTabs.CHAPTERS;

    protected isToneChecked = false;

    protected chaptersList:string[] = [];

    protected searchChapter = '';

    protected selectedChapters:string[] = [];

    protected tagsList:string[] = [];

    protected searchTags = '';

    protected showAddTag = false;

    protected addTag = '';

    protected errorTag:ErrorTag;

    protected showUseTags = false;

    protected selectedTags: string[] = [];

    protected checkedTags:string[] = [];

    protected editedTagIndex = -1;

    protected editedTag = '';

    protected speakersList:VerbatimConversationSpeakers[] = [];

    get tabs() {
        return Object.keys(this.conversationTabsEnum).map((c) => c.toLowerCase());
    }

    get filteredChapters() {
        return this.chaptersList.filter((chapter) => chapter.toLowerCase().includes(this.searchChapter.toLocaleLowerCase()));
    }

    get filteredTags() {
        return this.tagsList.filter((tag) => tag.toLowerCase().includes(this.searchTags.toLocaleLowerCase()));
    }

    constructor(
        public modalRef: NgbActiveModal,
        private corpus: CorpusApi,
        private modalService: NgbModal,
        private renderer: Renderer2,
    ) {
        super();
    }

    /**
     * Retourne la direction (gauche/droite ou rien) d'un locuteur selon la configuration de la conversation
     */
    getSpeakerDirection(speaker:string): SpeakerDirection {
        if (this.verbatimConversation.configuration.speakers.left.includes(speaker)) {
            return SpeakerDirection.LEFT;
        }
        if (this.verbatimConversation.configuration.speakers.right.includes(speaker)) {
            return SpeakerDirection.RIGHT;
        }
        return SpeakerDirection.NOTHING;
    }

    /**
     * Retourne la configuration d'un speaker (en fonction de son nom)
     */
    getSpeakerConfig(speaker: string) {
        return this.speakersList.find((s) => s.name === speaker);
    }

    async ngOnInit(): Promise<void> {
        this.chaptersList = this.verbatimConversation.conversation.reduce((acc, conv) => ([...new Set([...acc, conv.chapter])]), []);
        this.tagsList = this.verbatimTags.tags;
        this.speakersList = this.verbatimConversation.conversation.reduce((acc, conv, index) => (
            acc.find((s) => s.name === conv.speaker) ? acc : [...acc, { name: conv.speaker, direction: this.getSpeakerDirection(conv.speaker), color: getColor(index) }]
        ), []);
        this.isToneChecked = this.verbatimConversation.configuration.tone;
    }

    ngAfterViewInit(): void {
        // Au chargement on scrolle sur l'élément sélectionné dans le tableau des verbatims
        setTimeout(() => {
            const element = document.getElementById(this.selectedTurnId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 500);
    }

    // eslint-disable-next-line class-methods-use-this
    scrollToChapter(chapterId:string) {
        const targetElement = document.getElementById(this.toValidId(chapterId));
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    /**
     * Lorsque l'on coche ou décoche l'affichage des tonalités
     */
    async onUpdateTone() {
        gtmClick({ track_category: 'exploitation des verbatim', track_name: 'mode conversation avec tonalité', track_cible: this.isToneChecked ? 'avec tonalité' : 'sans tonalité' });
        await this.updateConversationConfig();
    }

    /**
     * Détermine si une conversation doit être désactivée ou non
     */
    isConversationDisable(conversation: VerbatimConversationItem) {
        return (this.selectedChapters.length && !this.selectedChapters.includes(conversation.chapter)) || (this.selectedTags.length && !this.selectedTags.some((t) => conversation.tag.includes(t)));
    }

    /**
     * Evènement lorsque l'on sélectionne un chapitre (ajoute un disable sur toutes les chapitres non sélectionnés)
     */
    onToggleChapters(chapter: string) {
        if (this.selectedChapters.includes(chapter)) {
            this.selectedChapters.splice(this.selectedChapters.findIndex((c) => c === chapter), 1);
        } else {
            gtmClick({ track_category: 'exploitation des verbatim', track_name: 'mode conversation chapitre choix du chapitre' });
            this.selectedChapters.push(chapter);
            this.scrollToChapter(chapter);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    toValidId(input: string): string {
        let validId = input.replace(/[^a-zA-Z0-9-_:.]/g, '_');
        if (!/^[a-zA-Z_]/.test(validId)) {
            validId = `_${validId}`;
        }
        return validId;
    }

    // Ajoute un tag sur toutes les conversations sélectionnées
    addTagOnCheckedConversation(tag: string) {
        const allCheckedConv = this.conversationElement.nativeElement.querySelectorAll('input:checked');
        if (allCheckedConv.length) {
            allCheckedConv.forEach((el: HTMLInputElement) => {
                const ids = el.value;
                const tagConv = this.verbatimConversation.conversation.find((c) => c['vcrm-id'] === ids).tag;
                if (!tagConv.includes(tag)) {
                    tagConv.push(tag);
                }
            });
            return Array.from(allCheckedConv).map((el: HTMLInputElement) => el.value).map((value) => value);
        }
        return [];
    }

    /**
     * Evènement lorsque l'on ajoute un tag
     */
    async onAddTag() {
        const isTagAlreadyExist = this.tagsList.find((t) => t === this.addTag);
        if (isTagAlreadyExist) {
            this.errorTag = ErrorTag.ALREADY_EXIST;
            return;
        }

        // Ajoute le nouveau tag sur les conversations sélectionnées
        const vcrmIds = this.addTagOnCheckedConversation(this.addTag);
        if (!vcrmIds.length) {
            this.errorTag = ErrorTag.NO_VERBATIM_SELECTED;
            return;
        }

        // Envoi la requête pour mettre à jour les tours de paroles où des tags ont été ajoutés
        const body = vcrmIds.reduce((acc:ConversationAddTag[], id) => (
            [...acc, ({ vcrm_id: id, verbatim_dbname: this.verbatimDbName, names: this.verbatimConversation.conversation.find((c) => c['vcrm-id'] === id).tag })]
        ), []);
        await firstValueFrom(this.corpus.postV1ConversationTags(this.corpusId, { tags: body }));
        this.tagsList.push(this.addTag);

        // reset l'ajout de tag
        this.addTag = '';
        this.showAddTag = false;
    }

    /**
     * Evènement lorsque l'on sélectionne un tag (ajoute un disable sur toutes les conversations qui ne contiennent pas un tag sélectionné)
     */
    onToggleTag(tag: string) {
        if (this.selectedTags.find((t) => t === tag)) {
            this.selectedTags.splice(this.selectedTags.findIndex((t) => t === tag), 1);
        } else {
            gtmClick({ track_category: 'exploitation des verbatim', track_name: 'mode conversation tags choix du tag' });
            this.selectedTags.push(tag);
        }
    }

    /**
     * Evènement lorsque l'on modifie un tag (modifie le tag ainsi que dans toutes les conversations)
     */
    async onEditTag(tagIndex: number) {
        const isTagAlreadyExist = this.tagsList.find((t) => t === this.editedTag);
        if (isTagAlreadyExist) {
            this.errorTag = ErrorTag.ALREADY_EXIST;
            return;
        }
        const oldTag = this.tagsList[tagIndex];
        // Modifie le nom du tag dans toutes les conversations
        this.verbatimConversation.conversation.forEach((conv) => {
            if (conv.tag.includes(oldTag)) {
                conv.tag = conv.tag.map((t) => (t === oldTag ? this.editedTag : t));
            }
        });

        // Envoi la requête pour mettre à jour les tours de paroles où le tag modifié est utilisé
        await firstValueFrom(this.corpus.postV1ConversationTags(this.corpusId, {
            tags: this.verbatimConversation.conversation.filter((conv) => conv.tag.includes(this.editedTag)).map((conv) => (
                { vcrm_id: conv['vcrm-id'], verbatim_dbname: this.verbatimDbName, names: conv.tag }
            )),
        }));

        this.tagsList[tagIndex] = this.editedTag;
        // Reset l'édition de tag
        this.editedTag = '';
        this.editedTagIndex = -1;
    }

    /**
     * Evènement lorsque l'on supprime un tag. Ouvre une modale de confirmation.
     */
    onRemoveTag(tag: string) {
        const modal = this.modalService.open(ModalComponent, {});
        modal.componentInstance.titleToTranslate = 'translations.analysisDashboard.verbatim.conversation.tabs.tags.modalDelete.title';
        modal.componentInstance.contentToTranslate = 'translations.analysisDashboard.verbatim.conversation.tabs.tags.modalDelete.content';
        modal.componentInstance.btnCloseToTranslate = 'translations.utils.cancel';
        modal.componentInstance.btnValidToTranslate = 'translations.utils.confirm';
        modal.componentInstance.btnValidVariant = 'primary';
        modal.componentInstance.status.subscribe(async (s) => {
            if (s) {
                await this.removeTag(tag);
            }
        });
    }

    /**
     * Supprime un tag dans la liste des tags (supprime le tag et dans toutes les conversations)
     */
    async removeTag(tag: string) {
        this.selectedTags.splice(this.selectedTags.findIndex((t) => t === tag), 1);
        this.tagsList.splice(this.tagsList.findIndex((t) => t === tag), 1);
        // Envoi la requête pour mettre à jour les tours de paroles où le tag supprimé est utilisé
        await firstValueFrom(this.corpus.postV1ConversationTags(this.corpusId, {
            tags: this.verbatimConversation.conversation.filter((conv) => conv.tag.includes(tag)).map((conv) => (
                { vcrm_id: conv['vcrm-id'], verbatim_dbname: this.verbatimDbName, names: conv.tag.filter((t) => t !== tag) }
            )),
        }));
        // Supprime le tag dans les conversations
        this.verbatimConversation.conversation.forEach((conv) => {
            if (conv.tag.includes(tag)) {
                conv.tag.splice(conv.tag.findIndex((t) => t === tag), 1);
            }
        });
    }

    /**
     * Supprime un tag dans une conversation
     */
    async onRemoveTagFromConversation(conv: VerbatimConversationItem, tag: string) {
        // Envoi la requête pour mettre à jour le tour de parole où le tag a été supprimé
        await firstValueFrom(this.corpus.postV1ConversationTags(this.corpusId, {
            tags: [{ vcrm_id: conv['vcrm-id'], verbatim_dbname: this.verbatimDbName, names: conv.tag.filter((t) => t !== tag) }],
        }));
        this.verbatimConversation.conversation.find((c) => c['vcrm-id'] === conv['vcrm-id']).tag.splice(conv.tag.findIndex((t) => t === tag), 1);
        // Vérifie s'il reste des tags avec ce nom sinon supprime de la liste et désectionne
        if (!this.verbatimConversation.conversation.filter((c) => c.tag.includes(tag)).length) {
            this.tagsList.splice(this.tagsList.findIndex((t) => t === tag), 1);
            this.selectedTags.splice(this.selectedTags.findIndex((t) => t === tag), 1);
        }
    }

    /**
     * Détermine si le tag est sélectionné ou non
     */
    isTagChecked(tag: string) {
        return this.checkedTags.find((t) => t === tag);
    }

    /**
     * Evènement lorsque l'on sélectionne un tag dans la liste (en mode Utilisation de tag)
     */
    onToggleCheckedTags(tag: string) {
        if (this.isTagChecked(tag)) {
            this.checkedTags.splice(this.checkedTags.findIndex((t) => t === tag), 1);
        } else {
            gtmClick({ track_category: 'exploitation des verbatim', track_name: 'mode conversation tags utilisation du tag', track_cible: 'sélectionner' });
            this.checkedTags.push(tag);
        }
    }

    /**
     * Evènement lorsque l'on valide l'ajout d'un ou plusieurs tags sur une ou plusieurs conversations
     */
    onValidCheckedTags() {
        // Ajoute les tags sélectionnées sur toutes les conversations sélectionnées
        this.checkedTags.forEach(async (tagName) => {
            // Ajoute le nouveau tag sur les conversations sélectionnées
            const vcrmIds = this.addTagOnCheckedConversation(tagName);
            // Envoi la requête pour mettre à jour les tours de paroles où des tags ont été ajoutés
            const body = vcrmIds.reduce((acc:ConversationAddTag[], id) => (
                [...acc, ({ vcrm_id: id, verbatim_dbname: this.verbatimDbName, names: this.verbatimConversation.conversation.find((c) => c['vcrm-id'] === id).tag })]
            ), []);
            await firstValueFrom(this.corpus.postV1ConversationTags(this.corpusId, { tags: body }));
        });
        // Reset l'utilisation des tags
        this.showUseTags = false;
        this.checkedTags = [];
    }

    /**
     * Met à jour la configuration de la conversation
     */
    async updateConversationConfig() {
        const speakerConfig = this.speakersList.reduce((acc, speaker) => {
            if (speaker.direction === SpeakerDirection.RIGHT) {
                acc.right.push(speaker.name);
            } else if (speaker.direction === SpeakerDirection.LEFT) {
                acc.left.push(speaker.name);
            }
            return acc;
        }, {
            left: [],
            right: [],
        });
        const conversationConfig = {
            speakers: speakerConfig,
            tone: this.isToneChecked,
        };
        await firstValueFrom(this.corpus.postV1ConversationConfiguration(this.corpusId, this.verbatimDbName, this.selectedConvId, conversationConfig));
    }

    /**
     * Evènement lorsque l'on modifie la direction d'un speaker
     */
    async onChangeSpeakerDirection(direction: SpeakerDirection) {
        let trackCible = '';
        switch (direction) {
            case SpeakerDirection.LEFT:
                trackCible = 'à gauche';
                break;
            case SpeakerDirection.RIGHT:
                trackCible = 'à droite';
                break;
            default:
        }
        if (trackCible) {
            gtmClick({ track_category: 'exploitation des verbatim', track_name: 'mode conversation locuteurs position', track_cible: trackCible });
        }
        await this.updateConversationConfig();
    }
}
