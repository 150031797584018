import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ApiConfiguration from 'app/api/api-configuration';
import BaseService from 'app/api/base-service';
import { MSurveys, MSurveysAccount } from 'app/api/models/msurvey';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export default class MsurveyService extends BaseService {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(
        config: ApiConfiguration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    createFromURL(url: string): Observable<Object> {
        return this.http.post(`${this.rootUrl}/v1/survey/upload/url/`, { surveyapiurl: url });
    }

    // VG pas certain de conserver ...
    updateFromURL(surveyId: string, key: string): Observable<Object> {
        return this.http.get(`${this.rootUrl}/v1/survey/upload/${encodeURIComponent(surveyId)}/key/${encodeURIComponent(key)}`, { withCredentials: true });
    }

    deleteExistingFile(): Observable<Object> {
        return this.http.delete(`${this.rootUrl}/v1/file`, { withCredentials: true });
    }

    /**
   * Create a survey file on backend with Msurvey Id and user account
   */
    createFromId(msurveyId: string, accountInfo: MSurveysAccount): Observable<void> {
        return this.http.post<void>(`${this.rootUrl}/v1/survey/upload/${msurveyId}`, accountInfo);
    }

    /**
   * Retreive all surveys from an account (with username and password)
   */
    getSurveys(accountInfo: MSurveysAccount): Observable<MSurveys> {
        return this.http.post<MSurveys>(`${this.rootUrl}/v1/surveys`, accountInfo);
    }
}
