/* eslint-disable no-underscore-dangle */
import drawDashboardNumber from './chartjs-plugins-utils';

const addDashboardNumberToBubbleChart = {
    id: 'addDashboardNumberToBubbleChart',
    afterDatasetsDraw(chart) {
        if (chart.config._config.type !== 'bubble') {
            return;
        }

        const { ctx } = chart;
        chart.$datalabels._labels.forEach((label) => {
            const nbLabel = label._model?.lines.filter((e) => e).length;
            if (nbLabel) {
                let { x } = label._el;
                if (label._model.align === 'left') {
                    x -= (label._model.size.width + label._model.offset + 30);
                } else {
                    x += label._model.size.width + label._model.offset + 5;
                }
                const lineSize = label._model.size.height / nbLabel;
                label._model.lines.forEach((line, i) => {
                    drawDashboardNumber(ctx, `${label.$context.values[i].dashboardNumber}`, x, (label._el.y + label._rects.frame.y + 10) + (i * lineSize));
                });
            }
        });
    },
};

export default addDashboardNumberToBubbleChart;
