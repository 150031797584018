/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../environments/environment';

@Injectable()
export default class Credential implements HttpInterceptor {
    /**
   * Creates an instance of Credential.
   * @param {NGXLogger} logger
   * @memberof Credential
   */
    constructor(private logger: NGXLogger) {}

    /**
   * And withCredentials: true to XHR and change api url from environment.api
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof Credential
   */
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const parser = document.createElement('a');
        parser.href = request.url;

        if (request.url.includes('/api/')) {
            // eslint-disable-next-line no-param-reassign
            request = request.clone({
                withCredentials: true,
                url: environment.api + parser.pathname + parser.search,
            });
        }
        return next.handle(request);
    }
}
