import { Corpusforuser } from './corpusforuser';
import { Valuefrequency } from './valuefrequency';

export enum LLMStatus {
    IN_PROGRESS = 1,
    DONE = 2,
    ERROR = 3,
}

export interface ThemeLLM {
    name: string,
    description: string,
    nb_verbatim: number,
    percent_verbatim: string,
    insights_pos: string[],
    insights_neg: string[],
    verbatim_sample: string[],
    rule: string,
    verbatim: string[],
    keywords: Valuefrequency[],
    selected?: boolean,
}

interface InfoStatusDashLLM {
    date_update_dashboard: string,
}

interface InfoStatusFileLLM {
    file_status: string,
    file_analyse_end: string,
}

interface InfoStatusLLM {
    dashboard: InfoStatusDashLLM,
    file: InfoStatusFileLLM,
    corpus: Corpusforuser,
}

export interface ExplorationLLM {
    dash_id: string,
    llm_id: number,
    llm_discovery_config_id: number,
    llm_discovery_config_name: string,
    llm_discovery_config_desc: string,
    llm_status: LLMStatus,
    llm_name: string,
    llm_creation_date: string,
    llm_update_date: string,
    username_creator: string,
    themes?: ThemeLLM[],
    info_status?: InfoStatusLLM,
    llm_info_dash_json_params: string,
    llm_info_dash_files: string,
    llm_nb_verbatim?: number,
    llm_info_dash_nb_verbatim?: number,
}

export interface Discoveries {
    discoveries: ExplorationLLM[],
}

export interface DiscoveryConfiguration {
    config_id: number,
    config_name: string,
    config_job: string,
    config_role: string,
    config_mode_poc: number,
    config_context: string,
}
