import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
    selector: 'clearable-input',
    templateUrl: './clearable-input.component.html',
    styleUrl: './clearable-input.component.scss',
})
export default class ClearableInputComponent {
    @Input() placeholder = '';

    @Input() value = '';

    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() disabled = false;

    isFocused = false;

    onInput(event: Event) {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.valueChange.emit(this.value);
    }

    onClearValue() {
        this.value = '';
        this.valueChange.emit(this.value);
    }
}

