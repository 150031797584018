import {
    Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { OutputTypeGraph } from 'app/api/models/associateddata';
import { Yaxis } from 'app/api/models/histoparams';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import BaseChartDirective from 'app/shared/directives/base-chart.directive';
import SettingsState from 'app/stores/state/settings/settings.state';
import { addDashboardNumberToVerticalBarChart } from 'app/utils/chartjs-plugins/add-dashboard-number-to-vertical-bar-chart';
import * as _ from 'lodash';
import AssociatedDataBarChartSettingsPopupComponent from '../bar-chart-settings-popup/bar-chart-settings-popup.component';
import ExtendedViewPopupComponent from '../extended-view-popup/extended-view-popup.component';

@Component({
    selector: 'associated-data-bar-chart',
    templateUrl: './associated-data-bar-chart.component.html',
})
export default class AssociatedDataBarChartComponent extends BaseComponent implements OnInit {
    bsModalRef: NgbModalRef;

    @Input()
        dbName;

    @Input()
        dashboardService: DashboardService;

    @Input()
        graphService: GraphService;

    @Input()
        manageDashboardService: ManageDashboardService;

    @Input()
        showToolbar = true;

    @Output()
    public configChanged = new EventEmitter<{ type: string, dbName: string, bar_params: OutputTypeGraph }>();

    @ViewChild('chart')
        chart: BaseChartDirective;

    graphDefaultValues;

    graphMaxValues;

    graphData;

    graphOptions;

    labels = [];

    title;

    params;

    public barChartPlugins = [addDashboardNumberToVerticalBarChart];

    constructor(
        private modalService: NgbModal,
        private store: Store,
    ) {
        super();
    }

    @Input()
    set data(data) {
        if (data) {
            this.loadConfig();
            this.title = data.displayName;
            this.graphData = {
                datasets: this.getDatasets(data),
                labels: this.labels,
            };

            this.graphOptions = this.getOptions();
        }
    }

    ngOnInit(): void {
        this.subs.sink = this.store.select(SettingsState).subscribe((settings) => {
            this.graphDefaultValues = settings.vcrm['graph-values-default'];
            this.graphMaxValues = settings.vcrm['graph-values-max'];
        });
    }

    loadConfig() {
        this.params = _.cloneDeep(this.getUserChoices().find((choice) => choice.db_name === this.dbName).bar_params);
    }

    get isMerged() {
        return !this.dashboardService;
    }

    openBarChartsSettingsModal(dbName: string) {
        const thisInternal = this;
        this.bsModalRef = this.modalService.open(AssociatedDataBarChartSettingsPopupComponent, {});
        this.bsModalRef.componentInstance.initParams = this.params;
        this.bsModalRef.componentInstance.graphDefaultValues = this.graphDefaultValues;
        this.bsModalRef.componentInstance.currentVerbatimColumns = this.currentVerbatimColumns;
        this.bsModalRef.componentInstance.dbName = dbName;

        this.bsModalRef.componentInstance.newUserChoicesObjectHisto.subscribe((receivedEntry) => {
            const userChoices = _.cloneDeep(this.getUserChoices());

            const index = userChoices.findIndex((element) => element.db_name === dbName);
            userChoices[index].bar_params = receivedEntry;

            if (thisInternal.isMerged) {
                this.manageDashboardService.setComparisonDashbardConfig('userChoices', userChoices);
            } else {
                this.dashboardService.setUserChoices(userChoices);
            }

            this.configChanged.emit({
                type: 'histo',
                dbName,
                bar_params: receivedEntry,
            });
        });
    }

    /**
  * Methode qui contruit les graphiques histogrammes. Génère les données et les options du graphique
  */
    getDatasets(columns) {
        if (!columns) {
            return [];
        }

        this.title = columns.displayName;

        const datasets = [];
        for (let i = 0; i < this.manageDashboardService.dashboardServices.length; i += 1) {
            datasets.push({
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: [],
                stack: `${i + 1}`,
            });
        }

        this.labels = [];

        const scope = `associated-data-${this.dbName}`;

        // nom de la donnée associée

        columns.histogram.values.forEach((value) => {
            const valueName = value.name;

            value.merged_values.forEach((dashboardValue, dashboardNumber) => {
                const color = this.manageDashboardService.getDataColor(scope, valueName);
                if (this.labels.indexOf(valueName) < 0) {
                    this.labels.push(valueName);
                }
                if (datasets[dashboardNumber]) {
                    datasets[dashboardNumber].data.push(dashboardValue !== null ? Math.round(dashboardValue * 10) / 10 : null);
                    datasets[dashboardNumber].backgroundColor.push(color);
                    datasets[dashboardNumber].hoverBackgroundColor.push(color);
                }
            });
        });

        return datasets.filter((dataset) => dataset.data.length > 0);
    }

    openExtendedViewModal() {
        this.bsModalRef = this.modalService.open(ExtendedViewPopupComponent, { size: 'full-width' });

        this.bsModalRef.componentInstance.graphService = this.graphService;
        this.bsModalRef.componentInstance.manageDashboardService = this.manageDashboardService;
        this.bsModalRef.componentInstance.dashboardService = this.dashboardService;

        this.bsModalRef.componentInstance.dbName = this.dbName;
        this.bsModalRef.componentInstance.type = 'bar';
        this.bsModalRef.componentInstance.params = this.params;
        this.bsModalRef.componentInstance.title = this.title;
        this.bsModalRef.componentInstance.isMerged = this.isMerged;
        this.bsModalRef.componentInstance.maxNbValuesChart = this.maxNbValuesChart;
        this.bsModalRef.componentInstance.partialTrackName = 'histogramme';
    }

    getUserChoices() {
        const config = this.dashboardService
            ? this.dashboardService.currentConfig.getValue()
            : this.manageDashboardService.currentDashboardComparisonConfig.getValue();

        return config.userChoices;
    }

    get maxNbValuesChart() {
        const actualMaxValues = this.currentVerbatimColumns.getValue()[this.dbName].values.length;
        return actualMaxValues < this.graphMaxValues ? actualMaxValues : this.graphMaxValues;
    }

    /**
     * Structures variables used to dynamicaly generte a Chart JS object made of data from API
     *
     * */
    // To design Charts JS BARS
    getOptions() {
        const { yaxis } = this.params;
        const thisInternal = this;
        return {
            img: false,
            animation: false,
            responsive: true,
            ratio: 2,
            maintainAspectRatio: thisInternal.showToolbar,
            plugins: {
                addDashboardNumberToVerticalBarChart: this.isMerged ? { spaceForLabels: false } : false,
                labels: false,
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    external: this.graphService.customTooltips(yaxis === Yaxis.percent ? '%' : ''),
                },
            },
            layout: {
                padding: {
                    top: this.isMerged ? 25 : 0,
                },
            },
            scales: {
                x: {
                    ticks: {
                        autoSkip: false,
                        callback(val) {
                            return thisInternal.graphService.labelEllipsisCallback(this.getLabelForValue(val));
                        },
                        color: '#000',
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    ticks: {
                        min: 0,
                        color: '#000',
                        callback: (value) => (value + (yaxis === Yaxis.percent ? '%' : '')),
                    },
                    grid: {
                        display: false,
                    },
                },
            },
        };
    }

    get currentVerbatimColumns() {
        return this.manageDashboardService.getCurrentVerbatimColumns();
    }

    get hasMaxValuesAlert(): boolean {
        if (this.currentVerbatimColumns?.getValue() && this.currentVerbatimColumns?.getValue()[this.dbName]) {
            const maxMaxValues = this.currentVerbatimColumns.getValue()[this.dbName].values.length;
            return this.maxNbChart < maxMaxValues;
        }
        return false;
    }

    get maxNbChart() {
        return this.params.nb_values_chart;
    }
}
