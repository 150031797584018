// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow {
  font-size: 13px;
  color: white;
  background-color: black;
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

:host {
  background-color: #eee;
  color: black;
  width: 59px;
  height: 60px;
}
:host:hover {
  background-color: #eee;
}
:host:hover .arrow {
  background-color: white;
  color: black;
  border: black 2px solid;
}
:host.open {
  width: 345px;
}
:host.open .title {
  font-size: 24px;
  margin-top: 12px;
}
:host.open .align {
  margin-top: auto;
  margin-bottom: auto;
}
:host.open .d-flex {
  flex-direction: row;
}
:host.open .other-text {
  margin-left: 15px;
}
:host.open .arrow {
  margin-right: 15px;
}
:host.closed .arrow {
  margin-top: 15px;
  margin-bottom: 16px;
}
:host.closed .title {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 15px;
}
:host.closed .align {
  margin-left: auto;
  margin-right: auto;
}
:host.closed .d-flex {
  flex-direction: column;
}

.count {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.count.disabled {
  color: #999;
}

.badge-filter-count {
  font-size: 12px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
