import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { BaseComponent } from 'app/base.component';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';

@Component({
    selector: 'app-filters-title',
    templateUrl: './filters-title.component.html',
    styleUrls: ['./filters-title.component.scss'],
})
export default class FiltersTitleComponent extends BaseComponent implements OnChanges, OnInit {
    constructor(
        public dashboardService: DashboardService,
        public manageDashboardService: ManageDashboardService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subs.sink = this.manageDashboardService.filtersCollapse.subscribe((filterCollapse) => {
            if (filterCollapse?.filterName === this.title) {
                this.internalCollapsed = filterCollapse.isCollapsed;
                this.onToggle.emit(this.internalCollapsed);
            }
        });
    }

    @Input()
        title: string;

    @Input()
        count:number;

    @Input()
    set collapsed(value: boolean) {
        this.internalCollapsed = value;
        this.manageDashboardService.setFilterCollapse({ filterName: this.title, isCollapsed: value });
    }

    get collapsed(): boolean {
        return this.internalCollapsed;
    }

    internalCollapsed: boolean = true;

    @Input()
        hasSettings: boolean = false;

    @Input()
        emptyPlaceholder: boolean = false;

    @Output()
        onToggle : EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
        onSettings : EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
        addDisabled: boolean = false;

    @ViewChild('settingsButton')
        settingsButton: ElementRef;

    toggle() {
        this.collapsed = !this.collapsed;
        this.onToggle.emit(this.collapsed);
    }

    settings() {
        this.settingsButton.nativeElement.blur();
        this.onSettings.emit(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.count) {
            this.dashboardService.countChange(changes.count.previousValue, changes.count.currentValue);
        }
    }
}
