import {
    Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Dashboardforuser, Dashboardlist } from 'app/api/models';
import { BaseComponent } from 'app/base.component';
import ManageDashboardService from 'app/modules/corpus/corpus-manage-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';

@Component({
    selector: 'app-new-comparison-modal',
    templateUrl: './new-comparison-modal.component.html',
    styleUrls: ['./new-comparison-modal.component.scss'],
})
export default class NewComparisonModalComponent extends BaseComponent implements OnInit {
    @Input()
        manageDashboardService: ManageDashboardService;

    @Output()
        newComparison: EventEmitter<Dashboardforuser[]> = new EventEmitter();

    @Output()
        openModalSelectDashboard: EventEmitter<void> = new EventEmitter();

    protected selectedDashboards: Dashboardforuser[] = new Array<Dashboardforuser>(3);

    get allDashboards():Dashboardlist {
        return this.manageDashboardService.allAnalysesDashboards.getValue();
    }

    get dashboardsWithSameVerbatim(): Dashboardlist {
        // Si on a 1 dashboard de sélectionner dans le 1er select, on filtre la liste des dashboards pour avoir tous les dashboards avec la mm colonne de verbatim
        return this.manageDashboardService.getDashboardsWithSameVerbatim(this.selectedDashboards[0]);
    }

    constructor(
        protected modal: NgbActiveModal,
    ) {
        super();
    }

    ngOnInit() {
        // Si on n'est pas en mode comparaison de dashboard (donc en mode Analyse), on présélectionne le dashboard courant dans le 1er select
        if (!this.manageDashboardService.isDashboardComparison) {
            this.selectedDashboards.splice(0, 1, this.manageDashboardService.allGroupedDashboards.find((dash) => dash.dash_id === this.manageDashboardService.currentDashboard.getValue().dash_id));
        }
        // Sinon on mode Analyse comparaison, on affiche "choisir" dans le 1er select (comportement par défaut)
    }

    // Evènement lorsque l'on valide la création d'une nouvelle comparaison
    onClickNewComparison() {
        const selectDashboard = this.selectedDashboards.filter((dash) => dash);
        gtmClick({
            track_category: 'dashboard comparaison',
            track_name: 'création dashboard comparaison',
            track_cible: `${selectDashboard.length} dashboards`,
        });
        this.modal.close();
        this.newComparison.emit(selectDashboard);
    }

    // Evènement lorsque l'on clique sur le bouton pour ouvrir la modal de sélection des dashboards/comparaisons
    onClickOpenModalSelectDashboard() {
        this.modal.close();
        this.openModalSelectDashboard.emit();
    }

    /**
     * Evènement lorsque l'on sélectionne un dashboard dans une des 3 listes
     * @param dash : le dashboard sélectionné
     * @param index : le numéro de la liste
     */
    onSelectDashboard(dash: Dashboardforuser, index: number) {
        // Lorsque l'on change le dashboard de la 1ere liste, et que les colonnes de verbatim ne sont pas les même, alors on reset les autres sélections
        if (index === 0 && this.selectedDashboards[0] !== undefined && this.selectedDashboards[0].dash_json_params.verbatim_dbname !== dash.dash_json_params.verbatim_dbname) {
            this.selectedDashboards[1] = undefined;
            this.selectedDashboards[2] = undefined;
        }
        this.selectedDashboards.splice(index, 1, dash);
    }
}
