import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Inputnamevalues2levels } from 'app/api/models';
import { CorpusService as CorpusApi } from 'app/api/services';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import CorpusClassModel from 'app/utils/models/corpus-class.model';
import { diff } from 'deep-object-diff';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import CorpusService from 'app/utils/services/corpus.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import accentless from '../../../../../../../../utils/functions/accentless';

@Component({
    selector: 'filters-classifications-auto-popup-hierarchique',
    templateUrl: './filters-classifications-auto-popup-hierarchique.component.html',
    styleUrls: ['./filters-classifications-auto-popup-hierarchique.component.scss'],
})
export default class FiltersClassificationsAutoPopupHierarchiqueComponent implements OnInit {
    @Output()
    private onSave: EventEmitter<boolean> = new EventEmitter();

    modelNameReference: string = '';

    private confirmButtonDisabled: boolean = true;

    private suppressButtonDisabled: boolean = true;

    public cats: Array<CorpusClassModel> = [];

    private catsInitialState: Array<CorpusClassModel> = [];

    public collapses: Object = {};

    public allSubSelected: Object = {};

    public numSubSelected: Object = {};

    public showClassCharts: boolean = true;

    public horsClassSelected: boolean = false;

    public horsClassSelectedInitialValue: boolean;

    public allSelected: boolean = false;

    public horsClassCount: any = 0;

    public numCheckable: number = 0;

    public numChecked: number = 0;

    private filtersList: Array<Inputnamevalues2levels> = [];

    private dashboardService: DashboardService;

    displayColumns: Array<CorpusClassModel> = [];

    constructor(
        public bsModalRef: BsModalRef,
        private store: Store,
        private corpusApi: CorpusApi,
        private corpusService: CorpusService,
        private currentModal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.confirmButtonDisabled = true;

        const cats = _.cloneDeep(this.models[this.verbatimDBName].cat_fields);

        this.corpusApi
            .getV1CorpusCorpIdClassData(this.store.snapshot().corpus.corp_id, this.verbatimDBName)
            .subscribe((data) => {
                const selected = this.dashboardService.currentFilters.getValue().filters_class;
                this.numCheckable = 0;
                this.numChecked = 0;
                cats.forEach((cat) => {
                    let numSubChecked = 0;
                    this.numSubSelected[cat.inputName] = 0;
                    cat.nbsubclass = cat.subclass.length;
                    this.numCheckable += cat.nbsubclass;
                    cat.subclass.forEach((motif) => {
                        motif.self = false;
                        selected.forEach((c) => {
                            if (c.inputName === '__hors_classe__') {
                                this.horsClassSelected = true;
                                return;
                            }
                            if (c.subclass) {
                                c.subclass.forEach((m) => m.values.forEach(() => {
                                    if (
                                        c.inputName === cat.inputName
                      && m.inputName === motif.inputName
                                    ) {
                                        motif.self = true;
                                        this.numChecked += 1;
                                        numSubChecked += 1;
                                    }
                                }));
                            }
                        });
                        data.class_count.forEach((d) => {
                            d.values.forEach((v) => {
                                if (d.inputName === cat.inputName && v.key === motif.inputName) {
                                    motif.count = v.doc_count;
                                }
                            });

                            if (d.inputName === '__hors_classe__') {
                                const count: any = d.values[0];
                                this.horsClassCount = count.response;
                            }
                        });
                    });
                    this.numSubSelected[cat.inputName] = numSubChecked;
                    this.allSubSelected[cat.inputName] = (numSubChecked === cat.nbsubclass);
                });

                this.allSelected = (this.numChecked === this.numCheckable);

                this.cats = cats;
                this.displayColumns = this.cats;
                this.catsInitialState = JSON.parse(JSON.stringify(cats));
                this.suppressActiveOrNot();
                this.horsClassSelectedInitialValue = this.horsClassSelected;
            });
    }

    get models() {
        return this.corpusService.currentCorpus.getValue().models;
    }

    get modelName() {
        const { models } = this.corpusService.currentCorpus.getValue();
        return models[this.verbatimDBName].model;
    }

    get verbatimDBName() {
        return this.dashboardService.currentFilters.value.verbatim_dbname;
    }

    toggleCollapse(input) {
        if (this.collapses[input] === undefined) {
            this.collapses[input] = false;
        }
        this.collapses[input] = !this.collapses[input];
    }

    selectAll(e) {
        this.allSelected = !this.allSelected;
        this.cats.forEach((cat) => {
            this.allSubSelected[cat.inputName] = e.currentTarget.checked;
            cat.subclass.forEach((motif) => {
                motif.self = e.currentTarget.checked;
            });
        });
        this.updateList();
    }

    selectClass(e, input) {
        this.allSubSelected[input] = !this.allSubSelected[input];
        if (this.allSubSelected[input]) {
            this.cats.forEach((cat) => {
                if (cat.inputName === input) {
                    cat.subclass.forEach((motif) => {
                        motif.self = true;
                    });
                }
            });
        } else {
            this.cats.forEach((cat) => {
                if (cat.inputName === input) {
                    cat.subclass.forEach((motif) => {
                        motif.self = false;
                    });
                }
            });
            this.allSelected = false;
        }
        this.updateList();
    }

    selectMotif(e, input, c) {
        if (this.allSelected) {
            this.allSelected = false;
        }
        this.cats.forEach((cat) => {
            if (cat.inputName === c) {
                cat.subclass.forEach((motif) => {
                    if (motif.inputName === input) {
                        motif.self = e.currentTarget.checked;
                    }
                });
            }
        });
        this.updateList();
    }

    selectHorsClass() {
        this.horsClassSelected = !this.horsClassSelected;
        this.updateList();
    }

    private updateList() {
        this.filtersList = [];
        this.numChecked = 0;
        this.cats.forEach((cat) => {
            const motifList = [];
            this.numSubSelected[cat.inputName] = 0;
            cat.subclass.forEach((motif) => {
                if (motif.self) {
                    const motifValues = [];
                    this.numChecked += 1;
                    motifValues.push('');
                    motifList.push({
                        inputName: motif.inputName,
                        values: motifValues,
                    });
                }
            });
            this.numSubSelected[cat.inputName] = motifList.length;
            if (motifList.length > 0) {
                this.filtersList.push({
                    inputName: cat.inputName,
                    subclass: motifList,
                });
                this.allSubSelected[cat.inputName] = (motifList.length === cat.nbsubclass);
            }
        });
        this.allSelected = (this.numChecked === this.numCheckable);
        if (this.horsClassSelected) {
            this.filtersList.push({
                inputName: '__hors_classe__',
            });
        }
        this.suppressActiveOrNot();
        this.confirmButtonDisabled = _.isEmpty(diff(this.cats, this.catsInitialState)) && this.horsClassSelected === this.horsClassSelectedInitialValue;
    }

    suppressActiveOrNot() {
        this.suppressButtonDisabled = true;
        this.cats.forEach((cat) => {
            cat.subclass.forEach((motif) => {
                if (motif.self) {
                    this.suppressButtonDisabled = false;
                }
            });
        });
        if (this.horsClassSelected) {
            this.suppressButtonDisabled = false;
        }
    }

    /**
     * Methode appelé lorsque l'on utilise le champ de recherche d'une classification.
     * Permet d'afficher uniquement les classes qui contiennent la recherche demandée
     */
    onFreeSearch(search) {
        this.displayColumns = [];
        const findR = new RegExp(accentless(search), 'gimu');
        this.cats.forEach((col) => {
            if (col.displayName && findR.test(accentless(col.displayName))) {
                this.displayColumns.push(col);
                this.collapses[col.inputName] = false;
            } else if (col.subclass.length > 0) {
                const subClassList = [];
                col.subclass.forEach((sub) => {
                    if (sub.displayName && findR.test(accentless(sub.displayName))) {
                        subClassList.push(sub);
                    }
                });
                if (subClassList.length > 0) {
                    this.displayColumns.push({
                        displayName: col.displayName,
                        inputName: col.inputName,
                        subclass: subClassList,
                    });
                    this.collapses[col.inputName] = false;
                }
            }
        });
    }

    suppressAll() {
        this.cats.forEach((cat) => {
            this.allSubSelected[cat.inputName] = false;
            cat.subclass.forEach((motif) => {
                motif.self = false;
            });
        });
        this.horsClassSelected = false;
        this.allSelected = false;
        this.updateList();
    }

    close() {
        this.currentModal.close();
    }

    save() {
        this.updateList();
        this.dashboardService.updateFilterClasses(this.filtersList);
        this.onSave.emit(true);
        this.close();
    }
}
