import { Component, OnInit } from '@angular/core';
import DashboardService from 'app/modules/corpus/corpus-dashboard.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import CorpusService from 'app/utils/services/corpus.service';
import * as moment from 'moment';
import BaseVerticalFilterComponent from '../common/base-vertical-filter.component';

@Component({
    selector: 'app-filters-period',
    templateUrl: './filters-period.component.html',
    styleUrls: ['./filters-period.component.scss'],
})
export default class FiltersPeriodComponent extends BaseVerticalFilterComponent implements OnInit {
    constructor(
        private dashboardService: DashboardService,
        private corpusService: CorpusService,
    ) {
        super(dashboardService);
    }

    isDisabled = true;

    period: number;

    firstDate: Date = null;

    lastDate: Date = null;

    minCorpusDate: Date;

    minSelectableDate: Date;

    maxCorpusDate: Date;

    maxSelectableDate: Date;

    disableChangeModeOnFirstValueChange: boolean = false;

    disableChangeModeOnLastValueChange: boolean = false;

    disableNexDoSelectHandler: boolean = false;

    allPeriods = [

        {
            text: 'translations.corpus.filters.period.select.1month',
            value: 1,
        },
        {
            text: 'translations.corpus.filters.period.select.6month',
            value: 6,
        },
        {
            text: 'translations.corpus.filters.period.select.12month',
            value: 12,
        },
        {
            text: 'translations.corpus.filters.period.select.18month',
            value: 18,
        },
        {
            text: 'translations.corpus.filters.period.select.custom',
            value: 999,
        },
        {
            text: 'translations.corpus.filters.period.select.full',
            value: 998,
        },
    ];

    ngOnInit(): void {
        this.subs.sink = this.corpusService.currentCorpus.subscribe((corpus) => {
            if (corpus !== null) {
                this.minCorpusDate = moment(corpus.corp_min_date, 'YYYY-MM-DD').toDate();
                this.maxCorpusDate = moment(corpus.corp_max_date, 'YYYY-MM-DD').toDate();
            }
        });

        this.subs.sink = this.dashboardService.currentFilters.subscribe((params) => {
            if (params !== null) {
                const previousFirstDate = this.firstDate;
                const previousLastDate = this.lastDate;

                this.firstDate = moment(params.date_begin).toDate();
                this.lastDate = moment(params.date_end).toDate();
                this.period = params.period ? params.period : 6;

                if (!previousFirstDate || this.firstDate.getTime() !== previousFirstDate.getTime()) {
                    this.disableChangeModeOnFirstValueChange = true;
                }

                if (!previousLastDate || this.lastDate.getTime() !== previousLastDate.getTime()) {
                    this.disableChangeModeOnLastValueChange = true;
                }

                this.changeMode(this.period);

                if (this.firstDate !== null && this.lastDate !== null) {
                    this.isDisabled = false;
                }
            }
        });
    }

    onPeriodChange() {
        if (this.disableNexDoSelectHandler) {
            this.disableNexDoSelectHandler = false;
            return;
        }
        gtmClick({
            track_category: 'filtrage sur verbatim',
            track_name: 'date prédéfinie',
        });
        this.changeMode(this.period);
        this.notifyChange();
    }

    notifyChange() {
        this.dashboardService.updateDates(
            this.firstDate,
            this.lastDate,
            this.period * 1,
        );
    }

    changeMode(value: number): void {
        this.period = value;

        if (value === 998) {
            // période complète
            this.minSelectableDate = this.minCorpusDate;
            this.firstDate = moment(this.minCorpusDate).toDate();

            this.maxSelectableDate = this.maxCorpusDate;
            this.lastDate = moment(this.maxCorpusDate).toDate();
        } else if (value === 999) {
            // période personnalisée
            this.minSelectableDate = this.minCorpusDate;
            this.maxSelectableDate = this.maxCorpusDate;
        } else {
            // période en nombre de mois

            // Date minimum sélectionnable = date minimum du corpus
            this.minSelectableDate = this.minCorpusDate;
            // Date de début de période = date maximum du corpus – N mois, ramené au premier jour du moi.
            const periodStartDate = moment(this.maxCorpusDate).subtract(this.period - 1, 'months').startOf('month').toDate();
            // Exemple : si j’ai des données du 15 juin au 19 septembre, et une période « Les 6 dernier mois ». 19 septembre – 6 mois = 19 avril, ramené au début du mois  1er avril
            // Date affichée « période du »= La date maximum entre la date minimum sélectionnable et la date de début de période
            this.firstDate = periodStartDate.getTime() > this.minSelectableDate.getTime() ? periodStartDate : this.minSelectableDate;
            // Date maximum sélectionnable = date maximum du corpus
            this.maxSelectableDate = this.maxCorpusDate;
            // Date affichée « au » = date maximum du corpus
            this.lastDate = this.maxCorpusDate;
        }

    // this.dashboardConfigService.setPeriod(this.period);
    }

    onFirstValueChange(date: Date): void {
        this.isDisabled = (date === null);

        const previousFirstDate = this.firstDate;

        if (date > this.lastDate) {
            this.firstDate = this.lastDate;
        } else if (date < this.firstDate) {
            this.firstDate = this.minSelectableDate;
        } else {
            this.firstDate = date;
        }

        if (this.disableChangeModeOnFirstValueChange) {
            this.disableChangeModeOnFirstValueChange = false;
        }

        // Le test ci-dessous est effectué en tronquant les millisecondes, car les dates peuvent être exprimées avec des
        // millisecondes positionnées ou pas (à zéro) sans que cela soit significatif. Il vaut donc mieux effectuer le test
        // en tronquant les millisecondes
        if (Math.floor(this.firstDate.getTime() / 1000) !== Math.floor(previousFirstDate.getTime() / 1000)) {
            // Dans le date-picker, si la date est modifiée, on positionne la select-box sur "Période personnalisée"
            this.changeMode(999);
        }

        this.notifyChange();
    }

    onLastValueChange(date: Date): void {
        const previousLastDate = this.lastDate;

        if (date < this.firstDate && this.lastDate !== null) {
            this.lastDate = this.firstDate;
        } else if (date > this.lastDate) {
            this.lastDate = this.maxSelectableDate;
        } else {
            this.lastDate = date;
        }

        if (this.disableChangeModeOnLastValueChange) {
            this.disableChangeModeOnLastValueChange = false;
        }

        // Le test ci-dessous est effectué en tronquant les millisecondes, car les dates peuvent être exprimées avec des
        // millisecondes positionnées ou pas (à zéro) sans que cela soit significatif. Il vaut donc mieux effectuer le test
        // en tronquant les millisecondes
        if (Math.floor(this.lastDate.getTime() / 1000) !== Math.floor(previousLastDate.getTime() / 1000)) {
            // Dans le date-picker, si la date est modifiée, on positionne la select-box sur "Période personnalisée"
            this.changeMode(999);
        }

        this.notifyChange();
    }

    get count() {
        return (this.period === 998 ? 0 : 1);
    }
}
