/* eslint-disable import/no-import-module-exports */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BaseComponent } from 'app/base.component';
import FooterService from 'app/shared/components/footer/footer.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { NgxPermissionsService } from 'ngx-permissions';
import CorpusService from 'app/utils/services/corpus.service';
import fadeInAnimation from 'app/utils/_animations/fade-in.animation';
import { SetCorpus } from '../../stores/actions/corpus/corpus.actions';
import { SetCurrentCorpus } from '../../stores/actions/settings/settings.actions';
import ManageDashboardService from './corpus-manage-dashboard.service';

/**
 * Global corpus component
 *
 * @export
 * @class CorpusComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-corpus',
    templateUrl: './corpus.component.html',
    moduleId: module.id.toString(),
    animations: [fadeInAnimation],
    providers: [ManageDashboardService],
})
export default class CorpusComponent extends BaseComponent implements OnInit, OnDestroy {
    public hasTones = false;

    public corpusLoad: boolean = false;

    private id: number;

    public isError = false;

    public errorMsg: string;

    isLoaded = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private spinner: SpinnerVisibilityService,
        private corpusService: CorpusService,
        private permissionsService: NgxPermissionsService,
        private footerService: FooterService,
    ) {
        super();
    }

    ngOnInit() {
        this.footerService.visible(false);

        this.subs.sink = this.route.params.subscribe((paramsId) => {
            this.id = paramsId.id;
            this.store.dispatch(new SetCurrentCorpus(this.id));
            this.spinner.show();
            this.corpusService.loadCorpus(this.id);
        });

        this.subs.sink = this.corpusService.currentCorpus.subscribe(async (corpus) => {
            if (corpus === null) {
                return;
            }

            this.isError = false;
            this.store.dispatch(new SetCorpus(corpus));

            this.corpusLoad = true;
            this.spinner.hide();
            this.permissionsService.removePermission('corpusAdmin');
            this.hasTones = corpus.corp_is_tone * 1 !== 0;
            corpus.users.forEach((user) => {
                const roleId = user.role_id * 1;
                if (
                    this.store
                        .selectSnapshot((state) => state.user.roles)
                        .includes('administrators')
            || (user.username
              === this.store.selectSnapshot((state) => state.user.username)
              && roleId === 1)
                ) {
                    this.permissionsService.addPermission('corpusAdmin');
                }
            });
            if (this.route.children.length === 0) {
                this.router.navigate(['analyses'], { relativeTo: this.route });
            }

            this.isLoaded = true;
        });

        // Charge la liste des projects pour le composant dashboard-project
        this.corpusService.loadCorpusListFromBackend();
    }

    ngOnDestroy() {
        const chartjsTooltip = document.getElementById('chartjs-tooltip');
        if (chartjsTooltip) {
            chartjsTooltip.remove();
        }
        this.corpusService.currentCorpus.next(null);
        this.footerService.visible(true);

        super.ngOnDestroy();
    }
}
