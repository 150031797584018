// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line selector-pseudo-element-no-unknown */
}
:host ::ng-deep app-news {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}
:host ::ng-deep app-corpus-table-select {
  min-height: 240px;
  padding-left: 20px;
  padding-right: 20px;
}
:host ::ng-deep router-outlet {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
