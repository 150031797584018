// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  cursor: pointer;
}
:host ::ng-deep .btn-icon {
  border-color: transparent;
}
:host ::ng-deep .btn-icon:hover {
  border-color: #ccc;
}

.filter-title {
  height: 40px;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.1px;
  font-weight: bold;
  color: #000;
}
.filter-title:hover {
  color: #f16e00;
}
.filter-title .badge-filter-count {
  font-size: 12px;
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  padding: 0 4px;
}

.filter-title > div {
  margin-top: auto;
  margin-bottom: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
