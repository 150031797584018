// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .highlight {
  color: #f16e00;
  font-weight: bold;
}

.pagination-disabled {
  border-width: 0 !important;
  cursor: default !important;
}

.pagination {
  margin: 0;
}

.verbatims-list {
  background-color: #fff;
  padding-bottom: 30px;
}

input[type=radio]:disabled + label {
  opacity: 0.4;
  filter: alpha(opacity=40); /* For IE8 and earlier */
}

#search-section {
  z-index: 2;
  transition: width ease 0.25s;
}
#search-section ::ng-deep .table td {
  padding-top: 6px;
  padding-bottom: 6px;
}

#search-section.col-8 {
  border-right: 1px solid #bbb;
}

.search-input {
  padding-right: 40px;
  height: 30px;
  font-size: 14px;
}

.search-clear {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 40px;
}

.table-content {
  background-color: #fff;
  padding-right: 10px;
}
.table-content .table-actions .sort-by-pertinence {
  font-size: 14px;
  padding-left: 0;
  display: flex;
  align-items: center;
}
.table-content .table-actions .sort-by-pertinence .form-check-input {
  margin: 0 10px;
}
.table-content .table-actions .sort-by-pertinence .form-label {
  margin: 0;
}
.table-content.zoom-in {
  width: 152%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
