import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import RouterTraceService from 'app/utils/services/router-trace.service';
import AuthActivateService from 'app/utils/services/auth-activate.service';
import AuthGuardService from 'app/utils/services/authguard.service';
import AuthComponent from './auth.component';
import LoginComponent from './login/login.component';
import LogoutComponent from './logout/logout.component';
import ResetpasswordComponent from './resetpassword/resetpassword.component';
import RequestaccessComponent from './requestaccess/requestaccess.component';
import ResetpasswordformComponent from './resetpasswordform/resetpasswordform.component';

const AUTH_ROUTES: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
        canActivate: [RouterTraceService],
        children: [
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [RouterTraceService, AuthActivateService],
            },
            {
                path: 'logout',
                component: LogoutComponent,
                canActivate: [RouterTraceService, AuthGuardService],
            },
            {
                path: 'resetpassword',
                component: ResetpasswordComponent,
                canActivate: [RouterTraceService, AuthActivateService],
            },
            {
                path: 'resetpassword/username/:username/token/:token',
                component: ResetpasswordformComponent,
                canActivate: [RouterTraceService, AuthActivateService],
            },
            {
                path: 'requestaccess',
                component: RequestaccessComponent,
                canActivate: [RouterTraceService, AuthActivateService],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(AUTH_ROUTES)],
    exports: [RouterModule],
    providers: [RouterTraceService, AuthActivateService, AuthGuardService],
})
export default class AuthRoutingModule {}
