/**
 * File status list
 */
enum DashboardTabs {
    EXPLORE_VERBATIM = 'exploreVerbatim',
    CLASSIFICATION_AUTO = 'classificationAuto',
    EVOLUTION_TEMPORELLE = 'evoTemporelle',
    THEMATICS_DATA = 'thematicsData',
    SATISFACTION = 'satisfaction',
    LABLLM = 'labllm',
    HEATMAP = 'heatmap',
    ASSOCIATED_DATA = 'associatedData',
}

export default DashboardTabs;
