// '','top_keywords_multi','','top_keywords_tweet'
const analytics = {
    track_category: 'nuage de mots',
    group: {
        reduced: {
            top_keywords_mono: {
                track_name: 'sélecteur nuage de mots',
            },
            top_keywords_multi: {
                track_name: "sélecteur nuage d'expressions",
            },
            top_keywords_hashtag: {
                track_name: 'sélecteur nuage de hashtags',
            },
            top_keywords_tweet: {
                track_name: 'sélecteur nuage de mentions',
            },
            top_keywords_ec: {
                track_name: 'sélecteur nuage de mots et expressions',
            },
            top_ote: {
                track_name: "sélecteur nuage de cibles d'opinion",
            },
        },
        extended: {
            top_keywords_mono: {
                track_name: 'vue étendue sélecteur nuage de mots',
            },
            top_keywords_multi: {
                track_name: "vue étendue sélecteur nuage d'expressions",
            },
            top_keywords_hashtag: {
                track_name: 'vue étendue sélecteur nuage de hashtags',
            },
            top_keywords_tweet: {
                track_name: 'vue étendue sélecteur nuage de mentions',
            },
            top_keywords_ec: {
                track_name: 'vue étendue sélecteur nuage de mots et expressions',
            },
            top_ote: {
                track_name: "vue étendue sélecteur nuage de cibles d'opnion",
            },
        },
    },
    tab: {
        top_keywords_mono: {
            track_name: 'affichage nuage ou liste de mots',
            track_cible: {
                cloud: 'nuage de mots',
                list: 'liste de mots',
            },
        },
        top_keywords_multi: {
            track_name: "affichage nuage ou liste d'expressions",
            track_cible: {
                cloud: "nuage d'expressions",
                list: "liste d'expressions",
            },
        },
        top_keywords_tweet: {
            track_name: 'affichage nuage ou liste des mentions',
            track_cible: {
                cloud: 'nuage des mentions',
                list: 'liste des mentions',
            },
        },
        top_keywords_hashtag: {
            track_name: 'affichage nuage ou liste des hashtags',
            track_cible: {
                cloud: 'nuage des hashtags',
                list: 'liste des hashtags',
            },
        },
        top_keywords_ec: {
            track_name: 'affichage nuage ou liste de mots et expressions',
            track_cible: {
                cloud: 'nuage de mots et expressions',
                list: 'liste de mots et expressions',
            },
        },
        top_ote: {
            track_name: "affichage nuage ou liste des cibles d'opinion",
            track_cible: {
                cloud: "nuage des cibles d'opinion",
                list: "liste des cibles d'opinion",
            },
        },
    },
    hide: {
        reduced: {
            top_keywords_mono: {
                track_name: 'masque temporaire de ce terme des mots',
            },
            top_keywords_multi: {
                track_name: 'masque temporaire de ce terme des expressions',
            },
            top_keywords_tweet: {
                track_name: 'masque temporaire de ce terme des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'masque temporaire de ce terme des hashtags',
            },
            top_keywords_ec: {
                track_name: 'masque temporaire de ce terme des mots et expressions',
            },
            top_ote: {
                track_name: "masque temporaire de ce terme des cibles d'opinion",
            },
        },
        extended: {
            top_keywords_mono: {
                track_name: 'vue étendue masque temporaire de ce terme des mots',
            },
            top_keywords_multi: {
                track_name: 'vue étendue masque temporaire de ce terme des expressions',
            },
            top_keywords_tweet: {
                track_name: 'vue étendue masque temporaire de ce terme des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'vue étendue masque temporaire de ce terme des hashtags',
            },
            top_keywords_ec: {
                track_name: 'vue étendue masque temporaire de ce terme des mots et expressions',
            },
            top_ote: {
                track_name: "vue étendue masque temporaire de ce terme des cibles d'opinion",
            },
        },
    },
    hiddens: {
        reduced: {
            top_keywords_mono: {
                track_name: 'termes masqués des mots',
            },
            top_keywords_multi: {
                track_name: 'termes masqués des expressions',
            },
            top_keywords_tweet: {
                track_name: 'termes masqués des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'termes masqués des hashtags',
            },
            top_keywords_ec: {
                track_name: 'termes masqués des mots et expressions',
            },
            top_ote: {
                track_name: "termes masqués des cibles d'opinion",
            },
        },
        extended: {
            top_keywords_mono: {
                track_name: 'vue étendue termes masqués des mots',
            },
            top_keywords_multi: {
                track_name: 'vue étendue termes masqués des expressions',
            },
            top_keywords_tweet: {
                track_name: 'vue étendue termes masqués des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'vue étendue termes masqués des hashtags',
            },
            top_keywords_ec: {
                track_name: 'vue étendue termes masqués des mots et expressions',
            },
            top_ote: {
                track_name: "vue étendue termes masqués des cibles d'opinion",
            },
        },
    },
    extended: {
        top_keywords_mono: {
            track_name: 'affichage vue étendue des mots',
        },
        top_keywords_multi: {
            track_name: 'affichage vue étendue des expressions',
        },
        top_keywords_tweet: {
            track_name: 'affichage vue étendue des mentions',
        },
        top_keywords_hashtag: {
            track_name: 'affichage vue étendue des hashtags',
        },
        top_keywords_ec: {
            track_name: 'affichage vue étendue des mots et expressions',
        },
        top_ote: {
            track_name: "affichage vue étendue des cibles d'opinion",
        },
    },
    export: {
        reduced: {
            top_keywords_mono: {
                track_name: 'export nuage de mots',
            },
            top_keywords_multi: {
                track_name: 'export nuage des expressions',
            },
            top_keywords_tweet: {
                track_name: 'export nuage des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'export nuage des hashtags',
            },
            top_keywords_ec: {
                track_name: 'export nuage de mots et expressions',
            },
            top_ote: {
                track_name: "export nuage des cibles d'opinion",
            },
        },
        extended: {
            top_keywords_mono: {
                track_name: 'vue étendue export nuage de mots',
            },
            top_keywords_multi: {
                track_name: 'vue étendue export nuage des expressions',
            },
            top_keywords_tweet: {
                track_name: 'vue étendue export nuage des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'vue étendue export nuage des hashtags',
            },
            top_keywords_ec: {
                track_name: 'vue étendue export nuage de mots et expressions',
            },
            top_ote: {
                track_name: "vue étendue export nuage des cibles d'opinion",
            },
        },
    },
    cloudSize: {
        reduced: {
            top_keywords_mono: {
                track_name: 'nombre maximum de termes de mots',
            },
            top_keywords_multi: {
                track_name: 'nombre maximum de termes des expressions',
            },
            top_keywords_tweet: {
                track_name: 'nombre maximum de termes des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'nombre maximum de termes des hashtags',
            },
            top_keywords_ec: {
                track_name: 'nombre maximum de termes de mots et expressions',
            },
            top_ote: {
                track_name: "nombre maximum de termes des cibles d'opinion",
            },
        },
        extended: {
            top_keywords_mono: {
                track_name: 'vue étendue nombre maximum de termes de mots',
            },
            top_keywords_multi: {
                track_name: 'vue étendue nombre maximum de termes des expressions',
            },
            top_keywords_tweet: {
                track_name: 'vue étendue nombre maximum de termes des mentions',
            },
            top_keywords_hashtag: {
                track_name: 'vue étendue nombre maximum de termes des hashtags',
            },
            top_keywords_ec: {
                track_name: 'vue étendue nombre maximum de termes de mots et expressions',
            },
            top_ote: {
                track_name: "vue étendue nombre maximum de termes des cibles d'opinion",
            },
        },
    },
    options: {
        top_keywords_mono: {
            track_name: 'option des mots dans le nuage',
            track_cible: {
                deltatone: 'représenter la tonalité',
                nodeltatone: 'ne pas représenter la tonalité',
            },
        },
        top_keywords_multi: {
            track_name: 'option des expressions dans le nuage',
            track_cible: {
                deltatone: 'représenter la tonalité',
                nodeltatone: 'ne pas représenter la tonalité',
            },
        },
        top_keywords_tweet: {
            track_name: 'option des mentions dans le nuage',
            track_cible: {
                deltatone: 'représenter la tonalité',
                nodeltatone: 'ne pas représenter la tonalité',
            },
        },
        top_keywords_hashtag: {
            track_name: 'option des hashtags dans le nuage',
            track_cible: {
                deltatone: 'représenter la tonalité',
                nodeltatone: 'ne pas représenter la tonalité',
            },
        },
        top_keywords_ec: {
            track_name: 'option des mots et expressions dans le nuage',
            track_cible: {
                deltatone: 'représenter la tonalité',
                nodeltatone: 'ne pas représenter la tonalité',
            },
        },
        top_ote: {
            track_name: "option des cibles d'opinion dans le nuage",
            track_cible: {
                deltatone: 'représenter la tonalité',
                nodeltatone: 'ne pas représenter la tonalité',
            },
        },
    },
    top_keywords_to_track_cible: {
        top_keywords_mono: 'mots',
        top_keywords_mono_noun: 'noms',
        top_keywords_mono_verb: 'verbes',
        top_keywords_mono_adj: 'adjectifs',
        top_keywords_mono_num: 'nombres',
        top_keywords_multi: 'expressions',
        top_keywords_multi_noun: 'groupes nominaux',
        top_keywords_multi_verb: 'groupes verbaux',
        top_keywords_multi_adj: 'groupes adjectivaux',
        top_keywords_hashtag: 'hashtags',
        top_keywords_tweet: 'mentions',
        top_keywords_ec: 'mots et expressions',
        top_keywords_ec_noun: 'noms',
        top_keywords_ec_verb: 'verbes',
        top_keywords_ec_adj: 'adjectifs',
        top_ote: "cibles d'opinion",
    },
};

export default analytics;
