// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-area: import-component;
}
:host .msurvey-nodata {
  width: 800px;
}
:host .msurvey-table {
  width: 1025px;
}
:host .msurvey-table .text-orange-bold {
  color: #f16e00;
  font-weight: bold;
}
:host .msurvey-table .icon-meeting {
  font-size: 25px;
  vertical-align: bottom;
  top: 1px;
  position: relative;
}
:host .msurvey-table::ng-deep .table-hover td {
  cursor: pointer;
}
:host .msurvey-table::ng-deep .pagination {
  justify-content: start !important;
}
:host .search {
  width: 420px;
  position: relative;
}
:host .search input {
  height: 28px;
  border-color: #ccc;
  padding-right: 30px;
}
:host .search .vcrm-form-cross {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  line-height: 28px;
  font-size: 20px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
