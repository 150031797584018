import { Thematics } from './thematics';
import { Datebegin } from './datebegin';
import { Dateend } from './dateend';
import { Inputnamevalues } from './inputnamevalues';
import { Inputnamevalues2levels } from './inputnamevalues-2levels';
import { Freesearch } from './freesearch';

export enum SearchSortOrder {
    ASC = 'asc',
    DESC = 'desc',
}
export enum SearchFieldType {
    DATE = 'date',
    COLUMN = 'column',
    TREATMENT = 'treatment',
    CLASS = 'class',
    SORT_ANNO = 'sort_anno',
    SORT_FAVO = 'sort_favo',
}
export interface Searchparams {
    date_begin?: Datebegin;
    date_end?: Dateend;
    filters?: Array<Inputnamevalues>;
    filters_class?: Array<Inputnamevalues2levels>;
    verbatim_dbname?: string;
    thematic_not?: boolean;
    freesearch?: Freesearch;
    keywordsearch?: Array<Inputnamevalues>;
    page?:number;
    thematics?: Array<Thematics>;
    search_fields: Array<SearchField>;
    verbatim_length_min?: number;
    verbatim_length_max?:number;
    period?: number;
    selected_all?: boolean;
    selected_not?: boolean;
}
export interface SearchField {
    displayName: string;
    DBName: string;
    sort?: SearchSortOrder;
    type: SearchFieldType;
    checked?: boolean;
    mode_id?: string;
}
