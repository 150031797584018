import {
    Component, EventEmitter, OnInit, Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import GraphService from 'app/modules/corpus/corpus-graph.service';
import { gtmClick } from 'app/shared/directives/gtm.directive';
import { BarChartParamsConfig } from 'app/api/models/dashboard-config';
import CorpusService from 'app/utils/services/corpus.service';
import { diff } from 'deep-object-diff';
import * as _ from 'lodash';
import { GraphConf } from '../../distribution-graphs/distribution-graphs.component';

@Component({
    selector: 'app-horizontal-bar-chart-settings-popup',
    templateUrl: './horizontal-bar-chart-settings-popup.component.html',
})
export default class HorizontalBarChartSettingsPopupComponent implements OnInit {
    @Output()
    private save: EventEmitter<BarChartParamsConfig> = new EventEmitter();

    params: BarChartParamsConfig;

    initialParams: BarChartParamsConfig;

    conf: GraphConf;

    graphService: GraphService;

    corpusService: CorpusService;

    gtmTrackCategory: string;

    partialGtmTrackName: string;

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {
        this.params = _.cloneDeep(this.initialParams);
    }

    hasTones() {
        return this.corpusService.hasTones();
    }

    get tone() {
        return this.hasTones() && this.params.tone;
    }

    set tone(value: boolean) {
        this.params.tone = value;
    }

    has(property) {
        return Object.prototype.hasOwnProperty.call(this.params, property);
    }

    get hasChanged(): boolean {
        const changes = diff(this.params, this.initialParams);
        return !_.isEmpty(changes);
    }

    validate() {
        if (!this.initialParams.top_activated && this.params.top_activated && this.initialParams.nb_values_chart === this.params.nb_values_chart) {
            this.launchGaEventNbValues();
        }
        this.save.emit(this.params);
        this.modal.close();
    }

    launchGaEventNbValues(): void {
        gtmClick({
            track_category: this.gtmTrackCategory,
            track_name: `vue histogramme ${this.partialGtmTrackName} nbre de classes affichées`,
            track_cible: `${this.params.nb_values_chart}`,
        });
    }
}
