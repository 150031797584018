/* eslint-disable quote-props */
const DistributionColorsAndOrder = {

    deltasat: {
        scaled: true,
        '-100_': '#CD3C14',
        '0_': '#FFCC00',
        '100_': '#32C832',
        '0_5': {
            0: '#CD3C14',
            1: '#CD3C14',
            2: '#FFB400',
            3: '#FFD200',
            4: '#999999',
            5: '#32C832',
            '': '#B5E8F7',
        },
        '0_10': {
            0: '#CD3C14',
            1: '#CD3C14',
            2: '#CD3C14',
            3: '#FFB400',
            4: '#FFCC00',
            5: '#FFD200',
            6: '#FFD200',
            7: '#CCCCCC',
            8: '#999999',
            9: '#0A6E31',
            10: '#32C832',
            '': '#B5E8F7',
        },
    },
    deltatone: {
        scaled: false,
        Negative: '#CD3C14',
        Négative: '#CD3C14',
        Négatif: '#CD3C14',
        NEG: '#CD3C14',
        Mixed: '#FFCC00',
        Mitigé: '#FFCC00',
        Mitigée: '#FFCC00',
        MIT: '#FFCC00',
        Neutral: '#999999',
        Neutre: '#999999',
        NEU: '#999999',
        Positive: '#32C832',
        Positif: '#32C832',
        POS: '#32C832',
        '': '#B5E8F7',
    },
    nps: {
        scaled: false,
        '-100_': '#CD3C14',
        Detractors: '#CD3C14',
        Détracteurs: '#CD3C14',
        Detracteurs: '#CD3C14',
        Passifs: '#FFCC00',
        Passives: '#FFCC00',
        '0_': '#FFCC00',
        Promoters: '#32C832',
        Promoteurs: '#32C832',
        '100_': '#32C832',
        '': '#B5E8F7',
    },

};

export default DistributionColorsAndOrder;
