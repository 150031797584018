// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog {
  color: red !important;
}

:host {
  height: 100% !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
  overflow-y: auto;
}
:host .modal-body {
  position: relative;
}
:host .modal-body .modal-dialog {
  flex: 0 1 auto;
}
:host .no-padding .modal-body {
  padding: 0 !important;
}
:host .data-modale-header-container {
  text-align: justify;
  border: solid 2px #000;
  align-items: baseline;
}

.modal-choice {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 0;
  overflow-y: auto;
}

.form-row-padding {
  padding-top: 10px !important;
  padding-bottom: 3px !important;
}

.data-table {
  width: 100%;
  padding: 5px;
}

.data-label-column {
  width: 60%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.data-histo-column {
  width: 20%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.data-histo-column > td {
  font-size: 2em;
}

data-curves-column {
  width: 20%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.data-table-head {
  padding-top: 5px;
  padding-bottom: 5px;
}

.data-table-head > th {
  font-weight: unset;
  font-size: 1em;
}

.under-title {
  text-align: center;
  border-bottom: #ccc solid 1px;
}

.gray-line {
  border-bottom: #ccc solid 2px;
}

.data-label-title {
  text-align: right;
  padding-right: 10px;
}

.data-item {
  border-bottom: 1px solid rgb(116, 116, 116);
  font-weight: 700 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
