// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-project-container {
  background-color: #fff;
  padding: 20px 30px;
}

.desc-text {
  max-width: 400px;
}

.new-project {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url("/assets/images/vcrm_bg.png") no-repeat right center;
}
.new-project > div {
  background-color: rgba(255, 255, 255, 0.6);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
