import {
    Component, EventEmitter, Input, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-graph-legend',
    templateUrl: './graph-legend.component.html',
    styleUrls: ['./graph-legend.component.scss'],
})
export default class GraphLegendComponent {
    constructor(private translateService: TranslateService) {}

    @Output()
        change: EventEmitter<number> = new EventEmitter();

    @Input()
        items = [];

    @Input()
        columns: boolean = false;

    toggle(index: number) {
        this.items[index].selected = !this.items[index].selected;
        this.change.emit(index);
    }

    getTimeChartLegend(item): string {
        return (
            `${item.selected
                ? this.translateService.instant('translations.analysisDashboard.tooltips.hideLinePlot')
                : this.translateService.instant('translations.analysisDashboard.tooltips.showLinePlot')
            } ${
                item.displayName}`
        );
    }
}
