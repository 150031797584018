import {
    Component, Input, Output, EventEmitter, OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import CorpusService from '../../../api/services/corpus.service';
import { ColumnPaginationparams } from '../../../api/models/columnPaginationparams';
import { Valuefrequency } from '../../../api/models/valuefrequency';
import { Column } from '../../../api/models/column';

@Component({
    selector: 'app-mypagination',
    templateUrl: './mypagination.component.html',
    styleUrls: ['./mypagination.component.css'],
})
export default class MypaginationComponent implements OnInit {
    @Input() columns: Array<any> = [];

    @Input() verbatimDBName: string = '';

    @Input() displayColumns: Array<any> = [];

    @Input() totalItems: number = 100;

    @Input() field: string = '';

    @Input() index: number = 0;

    @Input() currentPageArray: Array<number> = [];

    @Input() isResultsOfSearch: boolean = false;

    @Input() noMore: boolean = false;

    @Input() searchModel: string = '';

    @Input() ArrayOfBoolean: Array<Boolean> = [];

    @Input() dashboardType: string = '';

    @Input() cleanSearchBoolean: boolean = false;

    @Output() newPageDatasdisplayColumns = new EventEmitter<any>();

    @Output() newCurrentPage = new EventEmitter<any>();

    @Output() newArrayOfBoolean = new EventEmitter<any>();

    @Output() noMoreOnsearch = new EventEmitter<any>();

    public noPage: number = 1;

    public disabledPrevious = false;

    public disabledNext = false;

    public findDisplayValues: Array<Valuefrequency> = [];

    public makeColumns: Column;

    public limitByPage: number = 100;

    dataPage: ColumnPaginationparams = {
        freesearch: '',
        field: '',
        page: 1,
        limit: this.limitByPage,
        dashboard_type: this.dashboardType,
    };

    constructor(
        private corpusService: CorpusService,
        private store: Store,
    ) { }

    ngOnInit() {
        if (this.cleanSearchBoolean) {
            this.searchModel = '';
            this.isResultsOfSearch = false;
            this.cleanSearchBoolean = false; // une fois la recherche effacée car la modale a été fermée précédement, on le remet à false
        }
        this.noPage = this.currentPageArray[this.index];
        if (this.noPage === 1) {
            this.disabledPrevious = true;
            this.noMore = false;
        } else this.disabledPrevious = false;

        if (this.noMore) {
            this.disabledNext = true;
        } else this.disabledNext = false;
    }

    onNext() {
        this.noPage = this.currentPageArray[this.index] + 1;
        this.currentPageArray.splice(this.index, 1, this.noPage);

        if (!this.isResultsOfSearch) {
            this.dataPage = {
                field: this.field,
                page: this.noPage,
                limit: this.limitByPage,
            };

            this.corpusService
                .postV1CorpusCorpIdColumnsAnalysePagination({
                    corpId: this.store.snapshot().corpus.corp_id,
                    verbatimDbName: this.verbatimDBName,
                    body: this.dataPage,
                })
                .subscribe((cols) => {
                    const columns = cols as object;
                    Object.keys(columns).forEach((col) => {
                        const values = columns[col].values.map((v) => v.key);
                        columns[col].search = [
                            columns[col].displayName,
                            values.join(' | '),
                        ].join(' | ');
                        this.displayColumns.splice(this.index, 1, columns[col]);
                        this.ArrayOfBoolean.splice(this.index, 1, true);
                    });
                    const calcMore = this.displayColumns[this.index].distinct_values_count / this.noPage;
                    if (calcMore < 101) {
                        this.noMoreOnsearch.emit(true);
                    } else {
                        this.noMoreOnsearch.emit(false);
                    }
                    this.newPageDatasdisplayColumns.emit(this.displayColumns);
                    this.newArrayOfBoolean.emit(this.ArrayOfBoolean);
                    this.newCurrentPage.emit(this.currentPageArray);
                });
        } else if (this.searchModel.length > 0) {
            // si la colonne que l'on explore est le resultat d'une recherche
            this.dataPage = {
                freesearch: this.searchModel,
                field: this.field,
                page: this.noPage,
                limit: this.limitByPage,
                dashboard_type: this.dashboardType,
            };
            this.findDisplayValues = [];
            this.makeColumns = {};
            this.makeColumns.values = [];

            this.corpusService
                .postV1CorpusCorpIdColumnsSearchPagination({
                    corpId: this.store.snapshot().corpus.corp_id,
                    verbatimDbName: this.verbatimDBName,
                    body: this.dataPage,
                })
                .subscribe((data) => {
                    const index = Object.keys(data.search_results)[0];
                    data.search_results[index].values.forEach((val) => {
                        this.findDisplayValues.push(val);
                    });
                    if (this.findDisplayValues.length > 0) { // there are values that match search
                        this.makeColumns.inputName = data.search_results[index].inputName;
                        this.makeColumns.displayName = data.search_results[index].displayName;
                        this.findDisplayValues.forEach((item) => {
                            this.makeColumns.values.push(item);
                        });
                        if (!data.search_results[index].more) { // Si false il n'y a pas plus de 100 résultats, donc il faut disabled le bouton next
                            this.noMoreOnsearch.emit(true);
                        } else this.noMoreOnsearch.emit(false);
                        // findDisplayColumns.push(this.makeColumns);
                        this.displayColumns.splice(this.index, 1, this.makeColumns);
                        this.newPageDatasdisplayColumns.emit(this.displayColumns);
                        // this.newArrayOfBoolean.emit(this.ArrayOfBoolean);
                        this.newCurrentPage.emit(this.currentPageArray);
                        this.makeColumns = {};
                        this.makeColumns.values = [];
                        this.findDisplayValues = [];
                    }
                });
        }
    }

    onPrevious() {
        this.noPage = this.currentPageArray[this.index] - 1;
        this.currentPageArray.splice(this.index, 1, this.noPage);
        this.noMoreOnsearch.emit(false); // puisqu'on vient de cliquer sur previous, on est sur que next ne doit plus être disabled

        if (!this.isResultsOfSearch) {
            this.dataPage = {
                field: this.field,
                page: this.noPage,
                limit: this.limitByPage,
            };

            this.corpusService
                .postV1CorpusCorpIdColumnsAnalysePagination({
                    corpId: this.store.snapshot().corpus.corp_id,
                    verbatimDbName: this.verbatimDBName,
                    body: this.dataPage,
                })
                .subscribe((cols) => {
                    const columns = cols as object;
                    Object.keys(columns).forEach((col) => {
                        const values = columns[col].values.map((v) => v.key);
                        columns[col].search = [
                            columns[col].displayName,
                            values.join(' | '),
                        ].join(' | ');
                        this.displayColumns.splice(this.index, 1, columns[col]);
                        this.ArrayOfBoolean.splice(this.index, 1, true);
                    });
                    this.newPageDatasdisplayColumns.emit(this.displayColumns);
                    this.newArrayOfBoolean.emit(this.ArrayOfBoolean);
                    this.newCurrentPage.emit(this.currentPageArray);
                });
        } else if (this.searchModel.length > 0) {
            // si la colonne que l'on explore est le resultat d'une recherche
            this.dataPage = {
                freesearch: this.searchModel,
                field: this.field,
                page: this.noPage,
                limit: this.limitByPage,
                dashboard_type: this.dashboardType,
            };
            this.findDisplayValues = [];
            this.makeColumns = {};
            this.makeColumns.values = [];

            this.corpusService
                .postV1CorpusCorpIdColumnsSearchPagination({
                    corpId: this.store.snapshot().corpus.corp_id,
                    verbatimDbName: this.verbatimDBName,
                    body: this.dataPage,
                })
                .subscribe((data) => {
                    const index = Object.keys(data.search_results)[0];
                    data.search_results[index].values.forEach((val) => {
                        this.findDisplayValues.push(val);
                    });
                    if (this.findDisplayValues.length > 0) { // there are values that match search
                        this.makeColumns.inputName = data.search_results[index].inputName;
                        this.makeColumns.displayName = data.search_results[index].displayName;
                        this.findDisplayValues.forEach((item) => {
                            this.makeColumns.values.push(item);
                        });
                        if (!data.search_results[index].more) { // Si false il n'y a pas plus de 100 résultats, donc il faut disabled le bouton next
                            // this.noMore();
                            this.noMoreOnsearch.emit(true);
                        } else this.noMoreOnsearch.emit(false);
                        this.displayColumns.splice(this.index, 1, this.makeColumns);
                        this.newPageDatasdisplayColumns.emit(this.displayColumns);
                        this.newCurrentPage.emit(this.currentPageArray);
                        this.makeColumns = {};
                        this.makeColumns.values = [];
                        this.findDisplayValues = [];
                    }
                });
        }
    }
}
