// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-dashboard-list > .btn {
  min-width: 635px;
  max-width: 635px;
  justify-content: space-between;
}
.select-dashboard-list.show > .btn {
  background-color: #000;
  color: #fff;
  border-color: #ccc;
}

.list-group .list-group-item {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.list-group .list-group-item:hover {
  background-color: #eee;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
