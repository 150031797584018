import {
    Component, HostListener, OnDestroy, OnInit,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { User } from 'app/api/models';
import { HelpUrls } from 'app/api/models/helpurls';
import { MailContact } from 'app/api/models/mailcontact';
import { Settings } from 'app/api/models/settings';
import NewsModalService from 'app/modules/news/news-modal/news-modal.service';
import AppTranslateService from 'app/shared/services/translate.service';
import { SettingsModel } from 'app/stores/models/settings/settings.model';
import SettingsState from 'app/stores/state/settings/settings.state';
import UserState from 'app/stores/state/user/user.state';
import { Observable } from 'rxjs';
import { BaseComponent } from 'app/base.component';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export default class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
    public isMenuCollapsed = true;

    public currentLanguage: string;

    public isLogged = false;

    public user: User;

    public vcrmSettings: Settings = {};

    public adminUrl = `${environment.api}/auth/login`;

    public isNavHeaderLittle:boolean = false;

    public helpUrls: HelpUrls = {
        plazza: '',
        newsletterSubscribe: '',
    };

    get helpUrl() {
        return `/help${this.currentLanguage === 'en' ? '-en' : ''}/books`;
    }

    public mailContact: MailContact = {
        mailto: '',
        object: '',
    };

    user$: Observable<User>;

    settings$: Observable<SettingsModel>;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.updateHeaderHeight();
    }

    constructor(
        private newsModalService: NewsModalService,
        private appTranslateService: AppTranslateService,
        private translate: TranslateService,
        private router: Router,
        private store: Store,
    ) {
        super();
        this.vcrmSettings.url_help = undefined;
    }

    ngOnInit(): void {
        this.settings$ = this.store.select(SettingsState);
        this.subs.sink = this.settings$.subscribe((settings) => {
            if (settings.vcrm.help_urls) {
                this.helpUrls = settings.vcrm.help_urls;
            }
            if (settings.vcrm.mail_contact) {
                this.mailContact = settings.vcrm.mail_contact;
            }
            this.vcrmSettings = settings.vcrm;
        });

        this.user$ = this.store.select(UserState);
        this.subs.sink = this.user$.subscribe((state: User) => {
            this.user = state;
            this.isLogged = !!(state.username);
        });

        this.currentLanguage = (this.translate.currentLang || this.translate.defaultLang).toLowerCase();
        // Manage language changes
        this.subs.sink = this.translate.onLangChange.subscribe(() => {
            this.currentLanguage = this.translate.currentLang;
        });

        // Close menu on route change
        this.subs.sink = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.isMenuCollapsed = true;
            }
            if (event instanceof NavigationEnd) {
                this.updateHeaderHeight();
            }
        });
    }

    openNewsModal() {
        this.isMenuCollapsed = true;
        this.newsModalService.openModal();
    }

    onLanguageChange(lang) {
        this.isMenuCollapsed = true;
        this.appTranslateService.initChangeLangue(lang);
    }

    updateHeaderHeight() {
        if (this.router.url === '/') {
            this.isNavHeaderLittle = window.scrollY > 0;
        } else {
            this.isNavHeaderLittle = true;
        }
    }
}
