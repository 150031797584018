// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.usersTable {
  max-height: 310px;
  overflow-y: scroll;
}
.usersTable .btn-sm {
  height: 30px;
  width: 30px;
}
.usersTable .userItem {
  border-bottom: 1px lightgrey solid;
  width: 363px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
