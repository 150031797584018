import {
    Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, OnDestroy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMSurveyFile } from 'app/stores/actions/new-corpus/new-corpus.actions';

@Component({
    selector: 'app-etape2upload-msurvey-url',
    templateUrl: './etape2upload-msurvey-url.component.html',
    styleUrls: ['./etape2upload-msurvey-url.component.scss'],
})
export default class Etape2uploadMsurveyUrlComponent implements OnInit, OnDestroy {
    @Input() nextButtonObservable: Observable<void>;

    @Output() nextButtonActivation = new EventEmitter<boolean>();

    url: string = '';

    previousUrl: string = '';

    errorMessage: string;

    errorMessageKey;

    invalidUrl: boolean = false;

    warningEmpty: boolean = false;

    modalRef: NgbModalRef;

    subscription: Subscription;

    @ViewChild('nextStepModal')
    private nextStepModal: TemplateRef<any>;

    constructor(
        private router: Router,
        private store: Store,
        private translate: TranslateService,
        private modalService: NgbModal,
    ) { }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription = this.nextButtonObservable.subscribe(() => {
            // the next parent button as been clicked

            // check if the url is valid
            if (!this.isUrlValid()) {
                this.invalidUrl = true;
                this.previousUrl = this.url;
                this.disableParentNextButton();
                this.errorMessageKey = 'translations.httpErrors.400064';
                this.translateMessage();
            } else {
                this.invalidUrl = false;
                this.openNextStepModal();
            }
        });
    }

    translateMessage() {
        this.errorMessage = this.translate.instant(this.errorMessageKey);
    }

    enableParentNextButton() {
        this.nextButtonActivation.emit(true);
    }

    disableParentNextButton() {
        this.nextButtonActivation.emit(false);
    }

    /**
   * Fired when user click on next button to go to stage 3
   */
    async selectMsurveyURL() {
        try {
            // Add a msurvey from URL
            await this.store.dispatch(new AddMSurveyFile({
                url: this.url,
                corpusType: 3,
            })).toPromise();
            // go to next step
            this.router.navigate(['new-project', 'date']);
        } catch (error) {
            if (error?.error?.error === '400066') {
                this.warningEmpty = true;
                this.previousUrl = '';
            } else {
                this.invalidUrl = true;
            }
            this.errorMessageKey = `translations.httpErrors.${error?.error?.error}`;
            this.translateMessage();

            if (this.errorMessage === this.errorMessageKey) {
                this.errorMessage = error?.error?.message;
            }
        }
    }

    onCloseWarningAlert() {
        this.warningEmpty = false;
        this.previousUrl = '';
        this.nextButtonActivation.emit(!this.isNextDisabled());
    }

    urlchanged() {
        this.warningEmpty = false;
        this.invalidUrl = false;
        this.nextButtonActivation.emit(!this.isNextDisabled());
    }

    isNextDisabled() {
        return this.previousUrl === this.url;
    }

    openNextStepModal() {
        this.disableParentNextButton();

        this.modalRef = this.modalService.open(this.nextStepModal, {
            backdrop: 'static',
        });
    }

    cancelNextStepModal() {
        this.modalRef.close();
        this.enableParentNextButton();
    }

    validateNextStepModal() {
        this.previousUrl = this.url;
        this.disableParentNextButton();
        this.modalRef.close();
        this.selectMsurveyURL();
    }

    isUrlValid(): boolean {
        try {
            const url = new URL(this.url);
            const key = url.searchParams.get('key');
            const surveyId = url.searchParams.get('surveyId');
            return url.protocol === 'https:' && url.host === 'admin.msurvey.orange.com' && url.pathname === '/api/v1/results' && key !== null && key !== '' && surveyId !== null && surveyId !== '';
        } catch (e) {
            return false;
        }
    }
}
